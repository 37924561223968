import { Row } from 'antd';
import styled from 'styled-components';
import { HORIZONTAL_PADDING } from '../../../../../../../helpers/constants';

export const HeaderDiv = styled.div`
    z-index: 1000;
    overflow: hidden;
    height: ${({ theme }) => theme.layoutStyles.headerHeight};
    background: ${({ theme }) => theme.layoutStyles.headerColor};
    padding: ${({ theme }) => theme.layoutStyles.headerPaddingVertical} ${HORIZONTAL_PADDING};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    -moz-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        border: none !important;
        color: black !important;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border: none !important;
        color: black !important;
    }

    .ant-menu-horizontal {
        border: none;
        color: black !important;
    }

    .dispose {
        display: none;
        color: black !important;
    }

    .guest-icon-styles {
        :hover {
            cursor: pointer;
        }
    }

    .ant-menu {
        align-items: middle !important;
        justify-content: flex-end !important;
    }
`;

export const StyledDiv = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`;

export const StyledRow = styled(Row)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
`;
