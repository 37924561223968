import React from 'react';
import { Row } from 'antd';
import { PrivacyPolicyPageStyles } from './styles';
import PrivacyPolicySection from './PrivacyPolicySection';

const PrivacyPolicy = () => (
    <PrivacyPolicyPageStyles>
        <Row>
            <PrivacyPolicySection />
        </Row>
    </PrivacyPolicyPageStyles>
);

export default PrivacyPolicy;
