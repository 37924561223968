/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import { Col, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../components/Typography/styles';
import { CartWrapper, TextWrapper, DividerStyle, TextStyle, CustomRow, TextThrough, CustomButton, OverlayDiv } from './styles';
import { CustomCheckbox, OdemeButton, CustomButton as AButton } from '../../Checkout/TabsPane/PaymentPane/LastRow/styles';
import { CustomDisabledButton } from '../../Checkout/TabsPane/ReceivePane/styles';
import Spinner from '../../../../../components/Spinner';
import { fetchBasket } from '../../../../../../services/Redux/Shared/order/api';
import openNotification from '../../../../../components/Notification';
import { getAddressActive } from '../../../../../../services/Redux/Shared/profile/api';
import { BottomContent } from '../../../Favourites/RightTop/CartSection/styles';
import { OnBilgi, Mesafeli } from '../../Checkout/TabsPane/PaymentPane/LastRow/Info';

const OrderSummary = (props) => {
    const history = useHistory();
    const [disable, setDisable] = useState(true);
    const [termsVisible, setTermsVisible] = useState(false);
    const [conditionVisible, setConditionVisible] = useState(false);
    const {
        timeInt,
        packet,
        newCard,
        threeD,
        setThreeD,
        checkout,
        setCheckout,
        tabKey,
        setTabKey,
        check1,
        setCheck1,
        check2,
        setCheck2,
        updateSummary,
        order,
    } = props;
    const selector = useSelector;
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('user'));

    const activeAddress = selector((state) => state.profileStore.activeAddress)?.address || '';
    useEffect(() => {
        dispatch(fetchBasket());
    }, [updateSummary]);

    useEffect(() => {
        dispatch(getAddressActive());
    }, [dispatch]);

    let tutar = 0;

    const basket = selector((state) => state.customerOrderStore.basketList);
    let totalCost = 0;

    if (basket) {
        basket.map((item) => {
            item.meals.map((meal) => {
                totalCost += meal.price;
                return 0;
            });
            return 0;
        });

        tutar = basket[0].package_setting.min_discounted_order_price * basket.length;

        localStorage.setItem('totalPrice', tutar);
    }

    const redirectTo = () => {
        history.push('/profilim/adreslerim');
    };

    // Changes made in here are replacing the commented lines with the remaining data.
    const addressInfo = selector((state) => state.profileStore.activeAddress);
    const devamEt = () => {
        // const addressInfo = JSON.parse(localStorage.getItem('addressInfo'));
        // console.log(addressInfo);
        // if (addressInfo?.fatura)
        if (addressInfo?.address) {
            if (!checkout) {
                setCheckout(true);
            } else {
                if (tabKey === '1') {
                    setTabKey('2');
                }
                if (tabKey === '2') {
                    setTabKey('3');
                }
            }
        } else {
            openNotification(
                'Fatura Adres Yok',
                'Fatura adresiniz olana kadar sipariş veremezsiniz. Fatura adresi eklemek için tıklayınız',
                redirectTo,
                true
            );
        }
    };

    const overLayOn = () => {
        document.getElementsByClassName('overlay')[0].style.display = 'block';
    };

    return (
        <CartWrapper>
            {(termsVisible || conditionVisible) && (
                <Modal
                    style={{ top: 50, bottom: 100 }}
                    scrollableBody
                    width="50%"
                    bodyStyle={{ height: 750, overflow: 'auto' }}
                    visible={termsVisible || conditionVisible}
                    closable={false}
                    okText="Tamam"
                    cancelText="Kapat"
                    onOk={() => {
                        if (termsVisible) {
                            setTermsVisible(!termsVisible);
                        } else if (conditionVisible) {
                            setConditionVisible(!conditionVisible);
                        }
                    }}
                    onCancel={() => {
                        if (termsVisible) {
                            setTermsVisible(!termsVisible);
                        } else if (conditionVisible) {
                            setConditionVisible(!conditionVisible);
                        }
                    }}>
                    {termsVisible && <OnBilgi />}
                    {conditionVisible && <Mesafeli />}
                </Modal>
            )}
            <OverlayDiv className="overlay" />
            <TextWrapper>
                <Text size="description" weight="light" color="black" onClick={overLayOn}>
                    Sipariş Özeti
                </Text>
            </TextWrapper>
            <BottomContent style={{ padding: '0 28px' }}>
                {user && (
                    <TextStyle>
                        <Text weight="semi-bold" color="black">
                            Konumun:
                        </Text>
                    </TextStyle>
                )}
                <TextStyle>
                    <Text weight="300" size="light" color="#707070" style={{ textDecoration: 'underline', width: '70%' }}>
                        {activeAddress}
                    </Text>
                </TextStyle>
                <DividerStyle />
                <CustomRow>
                    <Text weight="semi-bold">Ödeme</Text>
                </CustomRow>
                {basket ? (
                    <>
                        <CustomRow align="middle">
                            <Col span={12}>
                                <Text weight="light" color="#707070">
                                    Sepet Tutari
                                </Text>
                            </Col>
                            <Col style={{ textAlign: 'center' }} span={6}>
                                <TextThrough color="#BCBCBC" weight="400">
                                    {totalCost} TL
                                </TextThrough>
                            </Col>
                            <Col span={6}>
                                <Text color="green" weight="400">
                                    {basket[0].package_setting.min_discounted_order_price * basket.length} TL
                                </Text>
                            </Col>
                        </CustomRow>
                        <DividerStyle />
                        {tabKey === '3' ? (
                            <CustomRow>
                                <CustomRow align="middle" className="normal-row" justify="space-between">
                                    <Col>
                                        <Text weight="light">Sipariş Tutarı</Text>
                                    </Col>
                                    <Col span={8}>
                                        <Text semi-bold="semi-bold" size="18px" color="green">
                                            {tutar.toLocaleString('tr-TR')} TL
                                        </Text>
                                    </Col>
                                </CustomRow>
                                <CustomRow className="normal-row checkbox">
                                    <Col span={3}>
                                        <CustomCheckbox checked={check1} onChange={() => setCheck1(!check1)} />
                                    </Col>
                                    <Col span={20}>
                                        <Text size="11px" weight="semi-bold" onClick={() => setTermsVisible(!termsVisible)}>
                                            <u style={{ cursor: 'pointer' }}>Ön Bilgilendirme Koşullar</u>ı’nı okudum, onaylıyorum.
                                        </Text>
                                    </Col>
                                </CustomRow>
                                <CustomRow className="normal-row checkbox">
                                    <Col span={3}>
                                        <CustomCheckbox checked={check2} onChange={() => setCheck2(!check2)} />
                                    </Col>
                                    <Col span={20}>
                                        <Text size="11px" weight="semi-bold" onClick={() => setConditionVisible(!conditionVisible)}>
                                            <u style={{ cursor: 'pointer' }}>Mesafeli Satış Sözleşmesi</u>’ni okudum, onaylıyorum.
                                        </Text>
                                    </Col>
                                </CustomRow>
                                {newCard && tabKey === '3' && (
                                    <CustomRow>
                                        <CustomCheckbox checked={threeD} onChange={() => setThreeD(!threeD)}>
                                            <Text size="11px" weight="semi-bold">
                                                3D ile güvenli ödeme
                                            </Text>
                                        </CustomCheckbox>
                                    </CustomRow>
                                )}
                            </CustomRow>
                        ) : (
                            <></>
                        )}
                        <CustomRow className="margin">
                            <Text weight="semi-bold">Ödenecek Toplam Tutar</Text>
                        </CustomRow>
                        <CustomRow className="total-row" align="middle" justify="center">
                            <Text color="green" weight="bold" size="28px">
                                {tutar.toLocaleString('tr-TR')} TL
                            </Text>
                        </CustomRow>
                        <CustomRow className="button-row">
                            {tabKey !== '3' ? (
                                <>
                                    {tabKey === '1' && (
                                        <CustomButton type="secondary" onClick={() => devamEt()}>
                                            <Text weight="semi-bold" color="white">
                                                Devam Et
                                            </Text>
                                        </CustomButton>
                                    )}
                                    {tabKey === '2' && (
                                        <>
                                            {packet || timeInt ? (
                                                <CustomButton type="secondary" onClick={() => devamEt()}>
                                                    <Text weight="semi-bold" color="white">
                                                        Devam Et
                                                    </Text>
                                                </CustomButton>
                                            ) : (
                                                <CustomDisabledButton type="secondary" disabled style={{ width: '100%' }}>
                                                    <Text weight="semi-bold" color="white">
                                                        Devam Et
                                                    </Text>
                                                </CustomDisabledButton>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {!check1 || !check2 ? (
                                        <AButton style={{ width: '100%' }} type="secondary" disabled={disable} onClick={order}>
                                            <Text weight="semi-bold" color="white">
                                                Ödeme Yap
                                            </Text>
                                        </AButton>
                                    ) : (
                                        <OdemeButton type="secondary" onClick={order}>
                                            <Text weight="semi-bold" color="white">
                                                Ödeme Yap
                                            </Text>
                                        </OdemeButton>
                                    )}
                                </>
                            )}
                        </CustomRow>
                    </>
                ) : (
                    <CustomRow>
                        <Spinner />
                    </CustomRow>
                )}
            </BottomContent>
        </CartWrapper>
    );
};

export default OrderSummary;
