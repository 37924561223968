/* eslint-disable no-unused-vars */
import * as actions from './actions';

// Delivery Info

export const getPackageInfo = (id) => async (dispatch, getState, service) => {
    dispatch(actions.getPackageInfoAction());
    await service({
        method: 'GET',
        path: `store/package_settings/${id}/`,
        sendToken: true,
    })
        .then((info) => {
            dispatch(actions.getPackageInfoSuccessAction(info));
        })
        .catch((error) => {
            dispatch(actions.getPackageInfoFailAction());
        });
};

export const getDraftInfo = () => async (dispatch, getState, service) => {
    dispatch(actions.getDraftInfoAction());
    await service({
        method: 'GET',
        path: `store/package_settings/packageSettingsDraftRequests/`,
        sendToken: true,
    })
        .then((info) => {
            dispatch(actions.getDraftInfoSuccessAction(info));
        })
        .catch((error) => {
            dispatch(actions.getDraftInfoFailAction());
        });
};

export const updatePackageInfo = (newInfo, id) => async (dispatch, getState, service) => {
    try {
        dispatch(actions.updatePackageInfoAction());
        await service({
            method: 'PATCH',
            path: `store/package_settings/${id}/`,
            sendToken: true,
            body: newInfo,
        });
        dispatch(actions.updatePackageInfoSuccessAction());
    } catch (err) {
        dispatch(actions.updatePackageInfoFailAction(err));
    }
};

export const updatePackageAmount = (newInfo, id) => async (dispatch, getState, service) => {
    try {
        dispatch(actions.updatePackageAmountAction());
        await service({
            method: 'PATCH',
            path: `store/package_settings/${id}/send_approve/`,
            sendToken: true,
            body: newInfo,
        });
        dispatch(actions.updatePackageAmountSuccessAction());
    } catch (err) {
        dispatch(actions.updatePackageAmountFailAction());
    }
};

export const getDeliveryInfo = (id) => async (dispatch, getState, service) => {
    dispatch(actions.getDeliveryInfoAction());
    await service({
        method: 'GET',
        path: `store/packaging/`,
        sendToken: true,
    })
        .then((info) => {
            dispatch(actions.getDeliveryInfoSuccessAction(info));
        })
        .catch((error) => {
            dispatch(actions.getDeliveryInfoFailAction());
        });
};
