/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    mealList: null,
    mealsError: null,
};

const getMealsReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    mealList: action.payload,
});

const getMealsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    mealsError: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MEALS:
            return getMealsReducer(state, action);
        case actionTypes.GET_MEALS_SUCCESS:
            return getMealsSuccessReducer(state, action);
        case actionTypes.GET_MEALS_FAIL:
            return getMealsFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
