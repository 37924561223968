/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { message } from 'antd';
import { Text } from '../../../../../../components/Typography/styles';
import { CustomRow } from './styles';
import { ReactComponent as PacketIcon } from '../../../../../../../Assets/Shared/CartPage/complete.svg';
import { ReactComponent as Dongu } from '../../../../../../../Assets/Shared/CartPage/dongu-logo.svg';

const Loading = ({ setIsPaymentDone, setOrderDetail }) => {
    const history = useHistory();

    const [mainMessage, setMainMessage] = useState(' Siparişiniz alınıyor…');

    const token = window.localStorage.getItem('token');

    const { createOrderResp } = useSelector((state) => state.customerOrderStore);

    const getInquery = (ip, conversationId, count) => {
        axios
            .post(
                'https://dongu.api.westerops.com/en/iyzico/payment/inquiry',
                {
                    ip,
                    conversationId,
                },
                {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    setTimeout(() => {
                        setOrderDetail(response.data);
                        setIsPaymentDone(true);
                    }, 3000);
                }
            })
            .catch((error) => {
                // request during one minutes if status 404
                // if status 500 then user cancel the threeD payment

                if (count <= 6 && error.response.status === 404) {
                    setTimeout(() => {
                        getInquery(ip, conversationId, count + 1);
                    }, 5000);
                } else if (error.response.status === 500) {
                    const { errorMessage } = error.response.data;
                    message.error(`Ödemeniz alınamadı! ${errorMessage || ''}`);
                    setTimeout(() => {
                        history.push('/restoran-bul');
                    }, 1000);
                } else {
                    setMainMessage('Yönlendiriliyorsunuz...');
                    setTimeout(() => {
                        history.push('/restoran-bul');
                    }, 4000);
                }
            });
    };

    useEffect(() => {
        if (createOrderResp) {
            const ref = createOrderResp.ref_code;
            const { conversationId } = createOrderResp;

            if (ref === undefined || ref === null) {
                getInquery('', conversationId, 0);
            } else {
                getInquery('', ref, 0);
            }
        }
    }, [createOrderResp]);

    return (
        <CustomRow className="main-row">
            <CustomRow justify="center" className="heading-row">
                <CustomRow justify="center">
                    <Dongu />
                </CustomRow>
                <CustomRow justify="center">
                    <PacketIcon />
                </CustomRow>
            </CustomRow>
            <CustomRow justify="center" className="haydi-row">
                <Text size="24px" weight="bold" color="red">
                    {mainMessage}
                </Text>
            </CustomRow>
            <CustomRow className="button-row" />
            <CustomRow className="button-row" />
        </CustomRow>
    );
};

export default Loading;
