import React from 'react';
import { Wrapper, FoodsWrapper, FoodWrapper, TextContainer, CustomCheckbox } from './styles';
import { Text } from '../../../../../../components/Typography/styles';

const NewBoxItem = ({ mealsToRender, meals, setMeals, foodPrices, setFoodPrices }) => {
    const addToBox = (id) => {
        setMeals([...meals, id]);
        const { price } = mealsToRender.find((val) => val.id === id);
        setFoodPrices(foodPrices + price);
    };

    const deleteFromBox = (id) => {
        setMeals(meals.filter((val) => val !== id));
        const { price } = mealsToRender.find((val) => val.id === id);
        setFoodPrices(foodPrices - price);
    };

    return (
        <Wrapper span={24}>
            <FoodsWrapper>
                {mealsToRender
                    .map(({ id, name, price }) => (
                        <FoodWrapper align="middle" justify="space-between">
                            <TextContainer>
                                <Text family="secondary" weight="medium" size="description">
                                    {name}
                                </Text>
                                <Text family="secondary" size="description">
                                    {price} TL
                                </Text>
                            </TextContainer>
                            <CustomCheckbox checked={meals.includes(id)} onClick={() => (meals.includes(id) ? deleteFromBox(id) : addToBox(id))} />
                        </FoodWrapper>
                    ))
                    .sort()}
            </FoodsWrapper>
        </Wrapper>
    );
};

export default NewBoxItem;
