import Checkbox from 'antd/lib/checkbox/Checkbox';
import styled from 'styled-components';

export const CheckboxStyle = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent;
        border-color: #d1d0d0 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: #8fd89f !important;
    }
`;
