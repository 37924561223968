import styled, { css } from 'styled-components';
import { Button } from 'antd';
import { ButtonTheme } from './constants';

// TYPES: primary - secondary - tertiary - deafult - link

const PrimaryButtonStyles = css`
    background-color: ${ButtonTheme.Color.primary} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        transition: 0.2s;
    }
`;

const SecondaryButtonStyles = css`
    background-color: ${ButtonTheme.Color.secondary};
    color: #ffffff;
    border-color: ${ButtonTheme.Color.secondary};

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverSecondary};
        transition: 0.2s;
    }
`;

const SecondaryButtonReverse = css`
    background-color: transparent;
    color: ${ButtonTheme.Color.secondary};
    border: 2px solid;
    border-color: ${ButtonTheme.Color.secondary} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverSecondary};
        transition: 0.2s;
    }

    &.ant-btn:hover span,
    &.ant-btn:focus span {
        color: white !important;
    }
`;

const BorderlessButtonReverse = css`
    background-color: transparent !important;
    color: ${ButtonTheme.Color.secondary};
    border: 0;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverSecondary};
        transition: 0.2s;
    }

    &.ant-btn:hover span,
    &.ant-btn:focus span {
        color: white !important;
    }
`;

const TertiaryButtonStyles = css`
    background-color: transparent;
    background-color: ${ButtonTheme.Color.tertiary} !important;
    border: 2px solid;
    border-color: ${ButtonTheme.Color.tertiary}!important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverTertiary} !important;
        transition: 0.2s;
    }
`;

const TertiaryButtonReverse = css`
    background-color: transparent;
    color: ${ButtonTheme.Color.tertiary};
    border: 2px solid;
    border-color: ${ButtonTheme.Color.tertiary} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.tertiary};
        transition: 0.2s;
    }
    &&.ant-btn:hover .ant-typography,
    &&.ant-btn:focus .ant-typography {
        color: #ffffff !important;
    }
    &&.ant-btn:hover span,
    &&.ant-btn:focus span {
        color: #ffffff !important;
    }
`;

const BorderedButtonStyles = css`
    background-color: transparent !important;
    border-color: ${ButtonTheme.Color.secondary};
    border: 2px solid;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverBordered};
        transition: 0.2s;
    }
`;

const LinkButtonStyles = css`
    background-color: transparent;
    color: ${ButtonTheme.Color.primary};
    border-color: ${ButtonTheme.Color.secondary} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverSecondary};
        border-color: ${ButtonTheme.Color.secondary} !important;
        transition: 0.2s;
    }
`;

const QuaternaryButtonStyles = css`
    background-color: ${ButtonTheme.Color.quaternary} !important;
    color: ${ButtonTheme.Radius.primary};
    border-color: ${ButtonTheme.Color.quaternary};

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.hoverTertiary} !important;
        border-color: ${ButtonTheme.Color.hoverBordered} !important;
        transition: 0.2s;
    }
`;

const QuaternaryReverseButtonStyles = css`
    background-color: transparent !important;
    color: ${ButtonTheme.Color.quaternary} !important;
    border-color: ${ButtonTheme.Color.quaternary} !important;

    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: ${ButtonTheme.Color.quaternary} !important;
        border-color: ${ButtonTheme.Color.quaternary} !important;
        color: white !important;
        transition: 0.2s;
    }
`;

export default styled(Button)`
    &&& {
        border-radius: ${ButtonTheme.Radius.primary};
        border-color: ${({ theme, borderColor }) => borderColor || theme.borderColor} !important;
        box-shadow: ${({ type }) => type !== 'link' && ButtonTheme.Shadow.primary};
        ${({ type }) => {
            switch (type) {
                case 'primary':
                    return PrimaryButtonStyles;
                case 'secondary':
                    return SecondaryButtonStyles;
                case 'secondaryReverse':
                    return SecondaryButtonReverse;
                case 'borderless':
                    return BorderlessButtonReverse;
                case 'tertiary':
                    return TertiaryButtonStyles;
                case 'tertiaryReverse':
                    return TertiaryButtonReverse;
                case 'quaternary':
                    return QuaternaryButtonStyles;
                case 'quaternaryReverse':
                    return QuaternaryReverseButtonStyles;
                case 'border':
                    return BorderedButtonStyles;
                case 'link':
                    return LinkButtonStyles;
                default:
                    break;
            }
        }}
    }
`;
