import { Label, FoodLabelStyles } from './styles';
import { Text } from '../../../../../../components/Typography/styles';

const FoodLabel = ({ name }) => (
    <FoodLabelStyles>
        <Label>
            <Text family="secondary">{name}</Text>
        </Label>
    </FoodLabelStyles>
);

export default FoodLabel;
