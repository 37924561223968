import React from 'react';
import { Text } from '../../../../components/Typography/styles';
import { Wrapper } from './styles';

export default function DividerStyle({ name }) {
    return (
        <Wrapper>
            <Text style={{ fontSize: 22, fontWeight: '500' }}>{name}</Text>
        </Wrapper>
    );
}
