/* eslint-disable sonarjs/no-identical-functions */
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Text } from '../../../../components/Typography/styles';

export const CardWrapper = styled(Row)`
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #ffffff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 54%;
    justify-content: space-between;

    @media (max-width: 576px) {
        height: 50%;
    }
`;

export const ContentWrapper = styled(Row)`
    margin-bottom: 32px;
    height: 20rem;
    cursor: pointer;
    margin-bottom: 1rem;

    @media (max-width: 576px) {
        height: 16rem;
    }
`;

// not certain
export const Wrapper = styled(Row)`
    /* width: 250px; */
    height: 303px;

    margin-bottom: 30px;
`;

export const DownContent = styled(Col)`
    width: 334px;
    /* width: 100%; */

    background-color: white;
    border-radius: 8px;
`;

export const UpTextWrapper = styled(Row)`
    align-items: center;
    background-color: #f38262;
    border-radius: 8px;
    width: 22%;
    height: 18%;
    margin-left: 3%;
    margin-top: 3%;
    justify-content: center;
`;

export const UpTextStyle = styled(Text)``;

export const IconWrapper = styled(Row)`
    margin-right: 3%;
    margin-top: 3%;
`;

export const ShopNameStyle = styled(Text)`
    color: #424242;

    margin-left: 3%;

    @media (max-width: 576px) {
        margin-top: 0.7rem;
        margin-left: 1.5rem;
    }
`;

export const MiddleTextStyle = styled(Row)`
    justify-content: space-between;

    padding: 0.1% 1.5%;
    border-bottom: 2px solid #f5f9fc;
`;

export const LogoStyle = styled(Col)`
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #ffffff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    height: 45px;
    width: 75px;
    margin-top: -5%;
    margin-left: 3%;
`;

export const RatingStyle = styled(Col)`
    margin-left: 10px;
`;

export const RatingTextStyle = styled(Text)`
    margin-left: 10px;
`;

export const LocationTextStyle = styled(Text)`
    margin-left: -20%;
`;

export const BottomSectionStyle = styled(Row)`
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    margin-left: 13px;
    margin-right: 13px;
`;

export const BottomContainerStyle = styled(Row)`
    border: 4px solid #f5f9fc;

    align-items: center;
    background-color: #f5f9fc;
`;

export const BottomTextStyle = styled(Text)`
    margin-left: 10px;
`;

export const DiscountTextStyle = styled(Row)`
    align-items: center;
`;
export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    width: 69px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 10px;
`;
