/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomRow } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { ReactComponent as PacketIcon } from '../../../../../../../Assets/Shared/CartPage/packet-sm.svg';
import { ReactComponent as Clock } from '../../../../../../../Assets/Shared/CartPage/clock.svg';
import SummaryRow from './SummaryRow';

const OrderDetails = ({ resp, isPaymentDone }) => {
    const selector = useSelector;
    const [hours, setHours] = useState(0);
    const [secs, setSecs] = useState(0);
    const [mins, setMins] = useState(0);
    const storeData = selector((state) => state?.storeStore?.storeData);

    useEffect(() => {
        const interval = setInterval(async () => {
            let temp = '';
            if (resp?.delivery_type === '1' && storeData) {
                const today = new Date();
                temp = await today.toLocaleDateString('en-CA').concat(' ', storeData?.package_settings?.delivery_time_end);
                temp = await temp.concat(':00');
            } else {
                temp = await resp?.courier_time?.end_date;
            }
            if (temp) {
                const timeLeft = new Date(temp).getTime() - new Date().getTime();
                const h = await Math.floor(timeLeft / 1000 / 60 / 60);
                const m = await Math.floor((timeLeft / 1000 / 60 / 60 - h) * 60);
                const s = await Math.floor(((timeLeft / 1000 / 60 / 60 - h) * 60 - m) * 60);
                await setHours(h);
                await setMins(m);
                await setSecs(s);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <CustomRow>
            <CustomRow align="middle" justify="center" className="margin-t-35">
                <PacketIcon />
            </CustomRow>
            <CustomRow justify="center" className="margin-t-20">
                <Text color="red" size="18px">
                    {isPaymentDone ? 'Siparişiniz Alındı' : 'Siparişiniz Alınıyor...'}
                </Text>
            </CustomRow>
            <CustomRow justify="center" className="margin-t-35" align="middle">
                <Text weight="semi-bold">Sipariş Numarası: </Text>
                &nbsp;&nbsp;&nbsp;
                <Text size="24px" weight="semi-bold" color="green">
                    {resp?.ref_code}
                </Text>
            </CustomRow>
            <CustomRow justify="center" className="margin-t-20">
                <Text weight="light" size="14px" color="#707070">
                    * Siparişinizi teslim alırken bu numarayı restorana belirtiniz.
                </Text>
            </CustomRow>
            <CustomRow className="time-left-row" justify="center" align="middle">
                <CustomRow justify="center" align="middle" className="timer">
                    <Clock />
                    &nbsp;&nbsp;
                    <Text weight="semi-bold">Teslim saatine kalan süre</Text>
                    &nbsp;&nbsp;
                    <Text weight="light" size="18px">
                        {hours < 10 ? <>0{hours}</> : <>{hours}</>}:{mins < 10 ? <>0{mins}</> : <>{mins}</>}:{secs < 10 ? <>0{secs}</> : <>{secs}</>}
                    </Text>
                </CustomRow>
            </CustomRow>
            <SummaryRow resp={resp} />
        </CustomRow>
    );
};

export default OrderDetails;
