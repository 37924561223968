import React from 'react';
import { useLocation } from 'react-router-dom';
import OrdersPage from './Orders';
import AddressPage from './Address';

function Index() {
    const location = useLocation();
    return (
        <>
            {location.pathname === '/profilim/gecmis-siparislerim' && <OrdersPage />}
            {location.pathname === '/profilim/adreslerim' && <AddressPage />}
        </>
    );
}

export default Index;
