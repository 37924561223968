import { Row } from 'antd';

import styled from 'styled-components';
import { Text } from '../../../components/Typography/styles';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../helpers/constants';

export default styled.div`
    background-color: #f69f8e;
    padding-top: ${VERTICAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    justify-content: center;
    align-items: center;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-repeat: no-repeat;
    .ant-menu-horizontal {
        line-height: 46px;
        border: 0;
        box-shadow: none;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        color: #f38262;
        border-bottom: 2px solid #fff;
    }
    .ant-menu ant-menu-light ant-menu-root ant-menu-horizontal {
        display: none !important;
    }
`;

export const Container = styled.div`
    width: 100%;
    border-radius: 18px;
    background-color: white;
    height: 100%;
`;
export const TitleContainer = styled.div`
    margin-bottom: 5%;
`;

export const ContainerHeader = styled(Row)`
    padding-top: 2%;
    padding-bottom: 1%;

    border-bottom: 4px solid #f2f2fc;
`;

export const ContainerContent = styled(Row)`
    padding-left: 3%;
    padding-right: 3%;

    padding-top: 2%;
    padding-bottom: 3%;
`;

export const SearchBarStyle = styled(Row)`
    display: flex;
    border-radius: 4px;
    border: solid 2px #f2f2fc;
    align-items: center;
    padding-left: 1%;
    width: 100%;
    margin-bottom: 3%;
`;

export const TextStyle = styled(Text)`
    color: ${({ isSelected }) => (isSelected ? '#F38262' : '#000')} !important;
    position: relative;
    align-items: center;

    & hr {
        display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')} !important;
        border: none;
        border-bottom: 2px solid #e5876a;
        width: 200px;
        left: 18%;
        bottom: -60%;
        align-self: center;
        position: absolute;
    }
`;
