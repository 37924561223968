/* eslint-disable sonarjs/cognitive-complexity */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Input, message, Upload } from 'antd';
import { FoodItemStyles, Content, Footer, ContentRight, ContentLeft, AddLabelBtn, FoodnameLabel, CustomBtn, CancelButton } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import AddTagModal from '../FoodItem/AddTagModal';
import { postBoxMeal } from '../../../../../../../services/Redux/Dashboard/boxes/api';
import emptyFile from '../../../../../../../Assets/Dashboard/empty-file-img.svg';

const NewFoodItem = ({ id, catId, name, description, labels, price, photo, cancelNewFoodItem }) => {
    const dispatch = useDispatch();
    const [isTagModalVisible, setIsTagModalVisible] = useState(false);
    const [itemName, setItemName] = useState(name);
    const [itemPrice, setItemPrice] = useState(price);
    const [itemDescription, setItemDescription] = useState(description);
    const [image, setImage] = useState(photo !== null && photo !== undefined ? photo : emptyFile);
    const [imageFile, setImageFile] = useState();

    const showTagModal = () => {
        setIsTagModalVisible(true);
    };

    const handleTagCancel = () => {
        setIsTagModalVisible(false);
    };

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = (info, setter, fileSetter) => {
        getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
        fileSetter(info.file.originFileObj);
    };

    const saveEdit = async () => {
        if (!itemName) return message.warn('Lütfen yemek adını ekleyin');
        if (!itemPrice) return message.warn('Lütfen fiyatı ekleyin');
        const formData = new FormData();
        labels.map((element) => formData.append('tag', element.id));
        if (imageFile) {
            formData.append('photo', imageFile);
        }
        formData.append('name', itemName);
        formData.append('price', itemPrice);
        formData.append('description', itemDescription);
        formData.append('category', catId);
        await dispatch(postBoxMeal(formData));
        cancelNewFoodItem(id);
    };

    return (
        <FoodItemStyles span={24}>
            <Content align="middle" justify="space-between">
                <ContentLeft xl={4} lg={3} md={4} sm={3}>
                    <Upload
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                            handleChange(info, setImage, setImageFile);
                        }}
                        showUploadList={false}>
                        <img src={image} alt="avatar" style={{ width: '100%', cursor: 'pointer' }} />
                    </Upload>
                </ContentLeft>
                <ContentRight xl={19} lg={20} md={20} sm={19}>
                    <Row align="middle" justify="space-between">
                        <Col xl={12} lg={13} md={14} sm={14}>
                            <Input value={itemName} placeholder="name" onChange={(event) => setItemName(event.target.value)} />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={4}>
                            <Input
                                value={itemPrice}
                                placeholder="TL"
                                onChange={(event) => {
                                    const value = event.target.value.replace(/\D/g, '');
                                    setItemPrice(value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '10px' }}>
                        <Input value={itemDescription} onChange={(event) => setItemDescription(event.target.value)} placeholder="description" />
                    </Row>
                </ContentRight>
            </Content>
            <Footer gutter={[32, 0]}>
                <Col span={14}>
                    <Row align="middle">
                        {labels &&
                            labels.length > 0 &&
                            labels.map((item) => (
                                <FoodnameLabel>
                                    <Text size="regular" family="secondary" color="smoke" weight="light">
                                        {item.name}
                                    </Text>
                                </FoodnameLabel>
                            ))}
                        <AddLabelBtn onClick={() => showTagModal()}>
                            <Text weight="bold" size="noble-regular" color="yellow">
                                +
                            </Text>
                        </AddLabelBtn>
                        {labels && labels.length > 0 ? (
                            <Text family="secondary" size="description" />
                        ) : (
                            <Text family="secondary" size="regular">
                                Etiket seç
                            </Text>
                        )}
                    </Row>
                </Col>
                <Col span={5}>
                    <CancelButton type="secondary" onClick={() => cancelNewFoodItem(id)}>
                        <Text weight="semi-bold" color="red">
                            İptal Et
                        </Text>
                    </CancelButton>
                </Col>
                <Col span={5}>
                    <CustomBtn type="secondary" onClick={() => saveEdit()}>
                        <Text weight="semi-bold" color="white">
                            Onaya Gönder
                        </Text>
                    </CustomBtn>
                </Col>
            </Footer>
            <AddTagModal label={labels} dismiss={handleTagCancel} onCancel={handleTagCancel} visible={isTagModalVisible} />
        </FoodItemStyles>
    );
};

export default NewFoodItem;
