import styled from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled(Row)`
    width: 100%;
    justify-content: space-between;
    border-bottom: 4px solid #f2f2ff;
    margin-bottom: 32px;
    margin-top: 32px;
`;
