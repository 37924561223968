import { Row, Col, Progress } from 'antd';
import styled from 'styled-components';
import { between } from 'polished';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';

export const BadgesCardStyles = styled(Card)`
    .ant-card-body {
        min-height: 180px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 40px;
        padding-bottom: 33px;
    }
`;
export const CustomProgress = styled(Progress)`
    .ant-progress-text {
        font-size: 16px;
        font-family: Montserrat;
        color: #424242;
    }
`;
export const Content = styled(Col)`
    min-height: 215px;

    display: flex;
    flex-direction: column;
    align-items: middle;
    justify-content: space-between;
    .ant-progress {
        display: flex;
    }
`;

export const BadgeStyles = styled(Col)`
    background: #f5f9fc;
    padding: 16px 10px;
    min-height: 250px;
`;

export const ShareSection = styled(Row)`
    background: #f5f9fc;
    min-height: 200px;
    padding-top: 30px;
    padding-bottom: 35px;
    border-radius: 20px;
    margin-top: 24px;
`;

export const CustomButton = styled(Button)`
    width: 152px;
    height: 48px;
`;

export const ButtonWrapper = styled(Row)`
    margin-top: 30px;
    padding-left: ${between('30px', '100px')};
    padding-right: ${between('30px', '100px')};
`;
