import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';
import Card from '../../../../components/Card';
import Button from '../../../../components/Button';

export const PacketCountCardStyles = styled(Card)`
    min-height: 320px;
    .ant-card-body {
        padding: 0px;
    }
    .ant-btn-border {
        border-color: ${({ theme }) => theme.secondaryColor} !important;
    }
    .disabled-btn {
        border-color: ${({ theme }) => theme.borderColor} !important;
    }
    /* ${({ theme }) => theme.borderColor} !important */
`;

export const Content = styled(Col)`
    padding: ${between('10px', '24px')} ${between('10px', '40px')};
    min-height: 300px;
`;

export const Counter = styled.div`
    min-width: 212px;
`;

export const ButtonGroup = styled.div`
    height: 67px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #f5f9fc;
    padding: 7px;
    border-radius: 5px;
`;

export const CustomButton = styled(Button)`
    width: 57px;
    height: 60px;
    background-color: #e0e9f0;
    border: none;

    :hover {
        background-color: #c9d4dd;
        border: none;
    }
    :focus {
        background-color: #c9d4dd;
        border: none;
    }
`;

export const Description = styled(Row)`
    margin-top: 30px;
    text-align: center;
`;
