import React from 'react';
import { AboutStyles, AboutWrapper, AboutTitle } from './styles';
import { Title, Paragraph } from '../../../../components/Typography/styles';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import bg from '../../../../../Assets/Shared/AboutUs/aboutUs.svg';

const AboutSection = () => {
    const { xs, sm } = useMediaQuery();

    return (
        <AboutStyles backgroundImage={sm ? '' : bg}>
            <AboutWrapper style={{ width: sm ? '100%' : '70%' }}>
                <AboutTitle style={{ paddingTop: xs ? '25%' : '10%' }}>
                    <Title style={{ textAlign: sm && 'center' }} weight="bold" color="#F38262" size="mediumTitle">
                        Gizlilik Politikası
                    </Title>
                </AboutTitle>
                <Paragraph style={{ textAlign: sm && 'center' }} color="#000" weight="light" size="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed bibendum sem. Vestibulum in molestie turpis. Vestibulum porta
                    ante nunc, ac tempus tortor consectetur semper. Aliquam erat volutpat. Etiam feugiat dapibus massa, eget semper lacus lacinia in.
                    Praesent tristique condimentum metus ac porta. Quisque aliquet sagittis orci quis aliquet. Integer est elit, tincidunt eu
                    venenatis ac, sollicitudin in purus. Donec consequat feugiat elit eget ullamcorper. Pellentesque porta varius tristique. Morbi
                    ornare enim urna, eget eleifend mi lobortis sit amet. <br /> <br />
                    Morbi et placerat dui. Morbi eget tristique mauris. Quisque condimentum nibh nulla, eu hendrerit lorem eleifend id. Pellentesque
                    quis tincidunt purus, eu varius nibh. Curabitur vestibulum justo in orci interdum, ac dapibus quam iaculis. Nullam quis lorem
                    finibus, interdum augue non, rutrum tellus. In laoreet ex vitae enim volutpat volutpat. Suspendisse volutpat, magna vel varius
                    pharetra, mauris sapien venenatis turpis, at tempus felis orci ac nibh. Mauris ut urna vel metus dictum molestie. Cras fermentum,
                    quam eu gravida cursus, eros velit vulputate mi, sit amet ultricies nibh purus in nunc. <br /> <br />
                    Morbi pulvinar nisi ut justo molestie, in ultrices turpis volutpat. Etiam rhoncus massa accumsan elit dictum, non porttitor ipsum
                    feugiat. Nam efficitur magna bibendum fermentum suscipit. Duis ligula dolor, viverra congue malesuada quis, sollicitudin sit amet
                    tellus. Donec placerat libero nisi, a fringilla magna ultrices eu. Donec rutrum nunc non nibh euismod vulputate. Maecenas commodo
                    tristique eros, nec luctus metus rutrum in. Ut pellentesque volutpat ligula. Integer maximus rhoncus nibh a feugiat. Donec quis
                    leo ut ligula interdum bibendum quis non turpis. <br /> <br />
                    Sed in felis pretium nisi sodales semper non vel libero. Duis urna odio, commodo laoreet erat quis, consequat ultricies tortor.
                    Mauris quis erat metus. Ut luctus sem sit amet faucibus ullamcorper. Quisque et dolor placerat metus faucibus aliquet. Proin
                    venenatis ligula leo, eu varius felis accumsan et. Pellentesque ut posuere lectus. Sed consequat aliquam ornare. Sed vestibulum
                    sagittis ante, a condimentum magna lacinia id. Nulla at convallis sapien. Integer ultricies bibendum libero quis volutpat. Mauris
                    non mauris vehicula, lacinia velit at, pellentesque lectus. Aenean dictum mauris vel felis fringilla vehicula. Duis dignissim
                    eleifend magna vel mollis. Mauris ut sodales quam. Ut egestas feugiat dignissim. <br /> <br />
                </Paragraph>
            </AboutWrapper>
        </AboutStyles>
    );
};

export default AboutSection;
