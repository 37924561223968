import { Col, Row, Button as Btn } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../components/Button/index';

export const DividerStyle = styled(Row)`
    background-color: #f2f2fc;
    width: 100%;
    height: 4px;
    margin-bottom: 1.5%;
    margin-top: 10px;

    &.light {
        margin-top: 10px;
        height: 2px;
        margin-left: 30px;
        width: 96%;
    }
`;

export const ItemRow = styled(Row)`
    display: flex;
    margin-left: 4rem;
    @media (max-width: 600px) {
        flex-direction: column;
        margin-left: 2rem;
        margin-top: 1rem;
    }
`;

export const InformationStyles = styled.div`
    margin-top: 30px;
`;

export const CustomButton = styled(Button)`
    width: 200px;
    transition: 0.5s;
`;

export const HiddenRow = styled.div`
    height: 0px;
    transition: height 0.5s;
    overflow: hidden;
    width: 98%;
`;

export const CloseButton = styled(Btn)`
    display: none;
`;

export const ButtonCol = styled(Col)`
    text-align: right;
`;
