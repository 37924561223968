import styled from 'styled-components';
import { Row, Col } from 'antd';

export default styled.div``;

export const Content = styled(Row)`
    display: flex;
    margin-bottom: 32px;
`;

export const Item = styled(Col)`
    display: flex;
    flex-direction: column;
`;

export const RightSide = styled(Col)`
    display: flex;
    flex-direction: ${({ size }) => (size.md || size.lg ? 'row' : 'column')};
    margin-left: ${({ size }) => (size.md || size.lg ? '0' : '32px')};
    width: 100%;
    height: 100%;
`;
