/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FoodSafetyStyles, { Content, Item, Text, SaveButton, CardTitle, Divider, Suggestion, Switch } from './styles';
import { updatePackageInfo, getPackageInfo } from '../../../../../../services/Redux/Dashboard/package/api';

const FoodSafetyAlert = ({ extraSuggestion, isOpen, id }) => {
    const dispatch = useDispatch();
    const [openBuffet, setOpenBuffet] = useState(isOpen);
    const [suggestion, setSuggestion] = useState(extraSuggestion);

    const update = async (open_buffet, extra_suggestion) => {
        await dispatch(updatePackageInfo({ open_buffet, extra_suggestion }, id));
        dispatch(getPackageInfo(id));
    };

    return (
        <FoodSafetyStyles title={<CardTitle>Gıda Güvenliği Uyarısı</CardTitle>}>
            <Content justify="start">
                <Text weight="semi-bold">Açık büfe yemek gıda güvenliği:</Text>
                <Text color="smoke" family="secondary" style={{ marginTop: '16px' }}>
                    Bu öğe açık büfeden yiyecek içeriyorsa, lütfen aşağıdaki seçeneği etkinleştirerek bize bildirin.
                </Text>
                <Item style={{ marginTop: '25px' }}>
                    <Item span={22}>
                        <Text weight="semi-bold">Bu işletme açık büfe yemek satıyor</Text>
                    </Item>
                    <Item>
                        <Switch checked={openBuffet} onClick={() => setOpenBuffet(!openBuffet)} />
                    </Item>
                </Item>

                <Divider />
                <Text weight="semi-bold">Ek öneriler:</Text>
                <Text color="smoke" family="secondary" style={{ marginTop: '20px' }}>
                    İşletmeniz, yiyeceklerinizi nasıl kullanacağınıza ve depolayacağınıza ilişkin öneriler tanımladıysa, bunları aşağıdaki alanda
                    paylaşabilirsiniz.
                </Text>
                <Suggestion placeholder="Açıklama girin" value={suggestion} onChange={(event) => setSuggestion(event.target.value)} />
                <Item md={{ span: 10, offset: 14 }} xl={{ span: 10, offset: 14 }}>
                    <SaveButton type="secondary" onClick={() => update(openBuffet, suggestion)}>
                        Kaydet
                    </SaveButton>
                </Item>
            </Content>
        </FoodSafetyStyles>
    );
};

export default FoodSafetyAlert;
