import styled from 'styled-components';
import { Col, Row } from 'antd';
import Card from '../../../../components/Card';

export const CustomCard = styled(Card)`
    min-width: 100%;

    .ant-card-body {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
`;
export const CardWrapper = styled(Col)`
    :hover {
        cursor: pointer;
    }
`;

export const CategoriesWrapper = styled(Row)``;
export const Content = styled(Col)`
    width: 100%;
    height: 240px;
`;
export const Img = styled.img``;
