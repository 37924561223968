import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    userData: null,
    userError: null,
    userUpdateList: null,
    userUpdateError: null,
    changePasswordResp: null,
    changePasswordError: null,
    resetResp: null,
    resetError: null,
    SetResp: null,
    SetError: null,
    postAddressList: null,
    postAddressError: null,
    addressList: null,
    addressError: null,
    activeAddress: null,
    activeAddressError: null,
    postActiveAddress: null,
    postActiveAddressError: null,
    addressUpdateResponse: null,
    addressUpdateError: null,
    deleteResponse: null,
    deleteError: null,
    orderHistory: null,
    orderHistoryError: null,
    deleteOrderError: null,
    deleteOrder: null,
    completeOrder: null,
};

const getUserReducer = (state) => ({
    ...state,
    loading: true,
});

const getUserSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    userData: action.payload,
});

const getUserFailReducer = (state, action) => ({
    ...state,
    loading: false,
    userError: action.payload,
});

const patchUserReducer = (state) => ({
    ...state,
    loading: true,
});

const patchUserSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    userUpdateList: action.payload,
});

const patchUserFailReducer = (state, action) => ({
    ...state,
    loading: false,
    userUpdateError: action.payload,
});

const patchPasswordReducer = (state) => ({
    ...state,
    loading: true,
});

const patchPasswordSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    changePasswordResp: action.payload,
});

const patchPasswordFailReducer = (state, action) => ({
    ...state,
    loading: false,
    changePasswordError: action.payload,
});

const postPasswordResetReducer = (state) => ({
    ...state,
    loading: true,
});

const postPasswordResetSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    resetResp: action.payload,
});

const postPasswordResetFailReducer = (state, action) => ({
    ...state,
    loading: false,
    resetError: action.payload,
});

const postPasswordSetReducer = (state) => ({
    ...state,
    loading: true,
});

const postPasswordSetSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    SetResp: action.payload,
});

const postPasswordSetFailReducer = (state, action) => ({
    ...state,
    loading: false,
    SetError: action.payload,
});

const postAddressReducer = (state) => ({
    ...state,
    loading: true,
});

const postAddressSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    postAddressList: action.payload,
});

const postAddressFailReducer = (state, action) => ({
    ...state,
    loading: false,
    postAddressError: action.payload,
});

// GET ADDRESS

const getAddressReducer = (state) => ({
    ...state,
    loading: true,
});

const getAddressSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    addressList: action.payload,
});

const getAddressFailReducer = (state, action) => ({
    ...state,
    loading: true,
    addressError: action.payload,
});

const getAddressActiveReducer = (state) => ({
    ...state,
    loading: true,
});

const getAddressActiveSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    activeAddress: action.payload,
});

const getAddressActiveFailReducer = (state, action) => ({
    ...state,
    loading: true,
    activeAddressError: action.payload,
});

const postAddressActiveReducer = (state) => ({
    ...state,
    loading: true,
});

const postAddressActiveSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    postActiveAddress: action.payload,
    activeAddress: action.payload,
});

const postAddressActiveFailReducer = (state, action) => ({
    ...state,
    loading: false,
    postActiveAddressError: action.payload,
});

const putAddressReducer = (state) => ({
    ...state,
    loading: true,
});

const putAddressSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    addressUpdateResponse: action.payload,
});

const putAddressFailReducer = (state, action) => ({
    ...state,
    loading: false,
    addressUpdateError: action.payload,
});

const deleteAddressReducer = (state) => ({
    ...state,
    loading: true,
});

const deleteAddressSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    deleteResponse: action.payload,
});

const deleteAddressFailReducer = (state, action) => ({
    ...state,
    loading: false,
    deleteError: action.payload,
});

// GET ORDERS

const getOrderHistoryReducer = (state) => ({
    ...state,
    loading: true,
});

const getOrderHistorySuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    orderHistory: action.payload,
});

const getOrderHistoryFailReducer = (state, action) => ({
    ...state,
    loading: false,
    orderHistoryError: action.payload,
});

// DELETE ORDER

const deleteOrderReducer = (state) => ({
    ...state,
    loading: true,
});

const deleteOrderSuccessReducer = (state, action) => ({
    ...state,
    deleteOrder: action.payload,
    loading: false,
});

const deleteOrderFailReducer = (state, action) => ({
    ...state,
    loading: false,
    deleteOrderError: action.payload,
});

// COMPLETE ORDER

const completeOrderReducer = (state) => ({
    ...state,
    loading: true,
});

const completeOrderSuccessReducer = (state, action) => ({
    ...state,
    completeOrder: action.payload,
    loading: false,
});

const completeOrderFailReducer = (state, action) => ({
    ...state,
    loading: false,
    completeOrderError: action.payload,
});

// RESET DELETE ORDER ERROR

const resetDeleteOrderError = (state) => ({
    ...state,
    loading: false,
    deleteOrderError: null,
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (action.type) {
        case actionTypes.GET_USER:
            return getUserReducer(state);
        case actionTypes.GET_USER_SUCCESS:
            return getUserSuccessReducer(state, action);
        case actionTypes.GET_USER_FAIL:
            return getUserFailReducer(state, action);
        case actionTypes.PATCH_USER:
            return patchUserReducer(state);
        case actionTypes.PATCH_USER_SUCCESS:
            return patchUserSuccessReducer(state, action);
        case actionTypes.PATCH_USER_FAIL:
            return patchUserFailReducer(state, action);
        case actionTypes.PATCH_PASSWORD:
            return patchPasswordReducer(state);
        case actionTypes.PATCH_PASSWORD_SUCCESS:
            return patchPasswordSuccessReducer(state, action);
        case actionTypes.PATCH_PASSWORD_FAIL:
            return patchPasswordFailReducer(state, action);
        case actionTypes.POST_PASSWORD_RESET:
            return postPasswordResetReducer(state);
        case actionTypes.POST_PASSWORD_RESET_SUCCESS:
            return postPasswordResetSuccessReducer(state, action);
        case actionTypes.POST_PASSWORD_RESET_FAIL:
            return postPasswordResetFailReducer(state, action);
        case actionTypes.POST_PASSWORD_SET:
            return postPasswordSetReducer(state);
        case actionTypes.POST_PASSWORD_SET_SUCCESS:
            return postPasswordSetSuccessReducer(state, action);
        case actionTypes.POST_PASSWORD_SET_FAIL:
            return postPasswordSetFailReducer(state, action);
        case actionTypes.POST_ADDRESS:
            return postAddressReducer(state);
        case actionTypes.POST_ADDRESS_SUCCESS:
            return postAddressSuccessReducer(state, action);
        case actionTypes.POST_ADDRESS_FAIL:
            return postAddressFailReducer(state, action);
        case actionTypes.GET_ADDRESS:
            return getAddressReducer(state);
        case actionTypes.GET_ADDRESS_SUCCESS:
            return getAddressSuccessReducer(state, action);
        case actionTypes.GET_ADDRESS_FAIL:
            return getAddressFailReducer(state, action);
        case actionTypes.GET_ADDRESS_ACTIVE:
            return getAddressActiveReducer(state);
        case actionTypes.GET_ADDRESS_ACTIVE_SUCCESS:
            return getAddressActiveSuccessReducer(state, action);
        case actionTypes.GET_ADDRESS_ACTIVE_FAIL:
            return getAddressActiveFailReducer(state, action);
        case actionTypes.POST_ADDRESS_ACTIVE:
            return postAddressActiveReducer(state);
        case actionTypes.POST_ADDRESS_ACTIVE_SUCCESS:
            return postAddressActiveSuccessReducer(state, action);
        case actionTypes.POST_ADDRESS_ACTIVE_FAIL:
            return postAddressActiveFailReducer(state, action);
        case actionTypes.PUT_ADDRESS:
            return putAddressReducer(state);
        case actionTypes.PUT_ADDRESS_SUCCESS:
            return putAddressSuccessReducer(state, action);
        case actionTypes.PUT_ADDRESS_FAIL:
            return putAddressFailReducer(state, action);
        case actionTypes.DELETE_ADDRESS:
            return deleteAddressReducer(state);
        case actionTypes.DELETE_ADDRESS_SUCCESS:
            return deleteAddressSuccessReducer(state, action);
        case actionTypes.DELETE_ADDRESS_FAIL:
            return deleteAddressFailReducer(state, action);
        case actionTypes.GET_ORDER_HISTORY:
            return getOrderHistoryReducer(state);
        case actionTypes.GET_ORDER_HISTORY_SUCCESS:
            return getOrderHistorySuccessReducer(state, action);
        case actionTypes.GET_ORDER_HISTORY_FAIL:
            return getOrderHistoryFailReducer(state, action);
        case actionTypes.DELETE_ORDER:
            return deleteOrderReducer(state);
        case actionTypes.DELETE_ORDER_SUCCESS:
            return deleteOrderSuccessReducer(state, action);
        case actionTypes.DELETE_ORDER_FAIL:
            return deleteOrderFailReducer(state, action);
        case actionTypes.COMPLETE_ORDER:
            return completeOrderReducer(state);
        case actionTypes.COMPLETE_ORDER_SUCCESS:
            return completeOrderSuccessReducer(state, action);
        case actionTypes.COMPLETE_ORDER_FAIL:
            return completeOrderFailReducer(state, action);
        case actionTypes.RESET_DELETE_ORDER_ERROR:
            return resetDeleteOrderError(state);
        default:
            return state;
    }
};

export default reducer;
