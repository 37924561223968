import React from 'react';
import { ButtonStyle, Wrapper } from './styles';

export default function ButtonSection(props) {
    const { resetFilter, saveHandler } = props;

    const handleSaveClick = () => {
        saveHandler();
    };

    return (
        <Wrapper>
            <ButtonStyle style={{ fontWeight: '500', border: 'border: 2px solid #8fd89f' }} onClick={resetFilter}>
                Temizle
            </ButtonStyle>
            <ButtonStyle style={{ backgroundColor: '#8FD89F', color: 'white', fontWeight: '500' }} onClick={handleSaveClick}>
                Kaydet
            </ButtonStyle>
        </Wrapper>
    );
}
