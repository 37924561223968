import styled from 'styled-components';
import { Col } from 'antd';

export const TextWrapper = styled(Col)`
    border-bottom: 4px solid #f2f2fc;
    padding-top: 5%;
    padding-bottom: 7%;
    text-align: center;
    margin-bottom: 7%;
    width: 100%;
`;
