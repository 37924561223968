import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    storesList: null,
    storesError: null,
    storeData: null,
    storeError: null,
    boxList: null,
    boxError: null,
    timePatch: null,
    timePatchError: null,
};

// GET STORES
const getStoresReducer = (state) => ({
    ...state,
    loading: true,
});

const getStoresSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    storesList: action.payload,
});

const getStoresFailReducer = (state, action) => ({
    ...state,
    loading: true,
    storesError: action.payload,
});

// GET STORE

const getStoreReducer = (state) => ({
    ...state,
    storeData: null,
    loading: true,
});

const getStoreSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    storeData: action.payload,
});

const getStoreFailReducer = (state, action) => ({
    ...state,
    loading: true,
    storeError: action.payload,
});

// SEARCH STORE

const searchStoreReducer = (state) => ({
    ...state,
    loading: true,
});

const searchStoreSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    searchData: action.payload,
});

const searchStoreFailReducer = (state, action) => ({
    ...state,
    loading: true,
    searchError: action.payload,
});

// GET STORE BOXES

const getStoreBoxesReducer = (state) => ({
    ...state,
    loading: true,
});

const getStoreBoxesSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    boxList: action.payload,
});

const getStoreBoxesFailReducer = (state, action) => ({
    ...state,
    loading: true,
    boxError: action.payload,
});

// GET STORE FAV

const getStoreFavReducer = (state) => ({
    ...state,
    loading: true,
});

const getStoreFavSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    favList: action.payload,
});

const getStoreFavFailReducer = (state, action) => ({
    ...state,
    loading: true,
    favError: action.payload,
});

// PUT STORE FAV

const putStoreFavReducer = (state) => ({
    ...state,
    loading: true,
});

const putStoreFavSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    favorite: action.payload,
});

const putStoreFavFailReducer = (state, action) => ({
    ...state,
    loading: false,
    favoriteError: action.payload,
});

// DELETE STORE FAV

const deleteStoreFavReducer = (state) => ({
    ...state,
    loading: true,
});

const deleteStoreFavSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    unfavorite: action.payload,
});

const deleteStoreFavFailReducer = (state, action) => ({
    ...state,
    loading: false,
    unfavoriteError: action.payload,
});

// GET STORE TIME

const getStoreTimeReducer = (state) => ({
    ...state,
    loading: true,
});

const getStoreTimeSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    timeData: action.payload,
});

const getStoreTimeFailReducer = (state, action) => ({
    ...state,
    loading: true,
    timeError: action.payload,
});

// PATCH STORE TIME

const patchStoreTimeReducer = (state) => ({
    ...state,
    loading: true,
});

const patchStoreTimeSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    timePatch: action.payload,
});

const patchStoreTimeFailReducer = (state, action) => ({
    ...state,
    loading: true,
    timePatchError: action.payload,
});

// POST STORE REVIEW

const postStoreReviewReducer = (state) => ({
    ...state,
    loading: true,
});

const postStoreReviewSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const postStoreReviewFailReducer = (state, action) => ({
    ...state,
    loading: false,
    storeReviewError: action.payload,
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (action.type) {
        case actionTypes.GET_STORES:
            return getStoresReducer(state);
        case actionTypes.GET_STORES_SUCCESS:
            return getStoresSuccessReducer(state, action);
        case actionTypes.GET_STORES_FAIL:
            return getStoresFailReducer(state, action);
        case actionTypes.GET_STORE:
            return getStoreReducer(state);
        case actionTypes.GET_STORE_SUCCESS:
            return getStoreSuccessReducer(state, action);
        case actionTypes.GET_STORE_FAIL:
            return getStoreFailReducer(state, action);
        case actionTypes.SEARCH_STORE:
            return searchStoreReducer(state);
        case actionTypes.SEARCH_STORE_SUCCESS:
            return searchStoreSuccessReducer(state, action);
        case actionTypes.SEARCH_STORE_FAIL:
            return searchStoreFailReducer(state, action);
        case actionTypes.GET_STORE_BOXES:
            return getStoreBoxesReducer(state);
        case actionTypes.GET_STORE_BOXES_SUCCESS:
            return getStoreBoxesSuccessReducer(state, action);
        case actionTypes.GET_STORE_BOXES_FAIL:
            return getStoreBoxesFailReducer(state, action);
        case actionTypes.GET_STORE_FAV:
            return getStoreFavReducer(state);
        case actionTypes.GET_STORE_FAV_SUCCESS:
            return getStoreFavSuccessReducer(state, action);
        case actionTypes.GET_STORE_FAV_FAIL:
            return getStoreFavFailReducer(state, action);
        case actionTypes.PUT_STORE_FAV:
            return putStoreFavReducer(state);
        case actionTypes.PUT_STORE_FAV_SUCCESS:
            return putStoreFavSuccessReducer(state, action);
        case actionTypes.PUT_STORE_FAV_FAIL:
            return putStoreFavFailReducer(state, action);
        case actionTypes.DELETE_STORE_FAV:
            return deleteStoreFavReducer(state);
        case actionTypes.DELETE_STORE_FAV_SUCCESS:
            return deleteStoreFavSuccessReducer(state, action);
        case actionTypes.DELETE_STORE_FAV_FAIL:
            return deleteStoreFavFailReducer(state, action);
        case actionTypes.GET_STORE_TIME:
            return getStoreTimeReducer(state);
        case actionTypes.GET_STORE_TIME_SUCCESS:
            return getStoreTimeSuccessReducer(state, action);
        case actionTypes.GET_STORE_TIME_FAIL:
            return getStoreTimeFailReducer(state, action);
        case actionTypes.PATCH_STORE_TIME:
            return patchStoreTimeReducer(state);
        case actionTypes.PATCH_STORE_TIME_SUCCESS:
            return patchStoreTimeSuccessReducer(state, action);
        case actionTypes.PATCH_STORE_TIME_FAIL:
            return patchStoreTimeFailReducer(state, action);
        case actionTypes.POST_STORE_REVIEW:
            return postStoreReviewReducer(state);
        case actionTypes.POST_STORE_REVIEW_SUCCESS:
            return postStoreReviewSuccessReducer(state);
        case actionTypes.POST_STORE_REVIEW_FAIL:
            return postStoreReviewFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
