import React from 'react';
import { Col, Row } from 'antd';
import { between } from 'polished';
import { useHistory } from 'react-router';
import { QuestionSectionStyles, QuestionWrapper } from './styles';
import dotGroup from '../../../../../Assets/Shared/ForBusiness/dotGroup.svg';
import { Text, Title } from '../../../../components/Typography/styles';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const QuestionSection = () => {
    const history = useHistory();
    const { sm } = useMediaQuery();
    const data = [
        {
            title: 'İşletmeler Döngü’ye Neden Katılmalı?',
            content: (
                <Text>
                    Arz fazlasına dönüşeceğini öngördüğün ürünleri Döngü'ye sokarak, potansiyel zararını kara dönüştürebilirsin. İşletmende karlılığı
                    artırmanın yanısıra, gıda israfını önleyerek gezegeni korumak için önemli bir adım atmış olacaksın. Döngü sayesinde kurumsal ve
                    sosyal imajını da güçlendirmiş olacaksın
                    <br />
                    <br /> Döngü ile müşteri ağını kolaylıkla büyütebilirsin. Bununla birlikte, siparişini teslim almak üzere işletmeni ziyaret eden
                    alıcılara, diğer ürünlerini tanıtma şansı da bulabilirsin.
                    <br />
                    <br /> Döngü uygulaması; ürün satışlarınla birlikte, israf olmasını önlediğin gıda miktarını ve böylece salınımını engellediğin
                    CO² hacmini; aylık ve yıllık istatistikler üzerinden rahatlıkla izleme imkanı sunuyor. İşletmen için ne kadar sürdürülebilir bir
                    gıda politikası uyguladığını bu şekilde, yakından takip edebileceksin.
                </Text>
            ),
        },
        {
            title: 'Döngü iş ortaklığı ne kadar zaman alır?',
            content: <Text>Formu doldurmanı takip eden 24 saat içerisinde, Döngü başvurunu değerlendirerek sana dönüş yapacağız.</Text>,
        },
        {
            title: 'Hangi İşletmeler Kayıt Olabilir?',
            content: (
                <Text>
                    Restoranlar, cafeler, pastaneler, oteller, marketler, manavlar kısacası gıda hizmet sektöründe faaliyet gösteren işletmelerin
                    tamamı, kalite standartlarını karşıladıkları takdirde, Döngü’nün bir parçası olabilir.
                </Text>
            ),
        },
    ];
    const Question = ({ title, content }) => (
        <Row>
            <QuestionWrapper>
                <Row>
                    <Col style={{ marginRight: between('5px', '25px') }}>
                        <div style={{ width: '15px', height: '15px', backgroundColor: '#F69F8E', borderRadius: '30px' }} />
                    </Col>
                    <Col span={21}>
                        <Title color="green" size="title" weight="bold">
                            {title}
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Text style={{ marginLeft: 26 }} size="description" weight="regular">
                        {content}
                    </Text>
                    {sm ? (
                        <>
                            <br />
                            <br />
                        </>
                    ) : null}
                </Row>
            </QuestionWrapper>
        </Row>
    );

    return (
        <QuestionSectionStyles span={24} backgroundImage={dotGroup}>
            <Row style={{ marginTop: 20 }} justify="space-between">
                <Col span={19}>
                    <Title style={{ width: sm ? '100%' : '92%', marginBottom: between('30px', '50px') }} color="red" size="mediumTitle" weight="bold">
                        Bize sormak istediğin sorular var mı? Tüm sorularını cevaplamaya hazırız.
                    </Title>
                    {data.map((item, index) => (
                        <Question key={index} title={item.title} content={item.content} />
                    ))}
                </Col>
                <Col span={5}>
                    <Row justify="end">{/* <img src={dotGroup} alt="" width="50%" /> */}</Row>
                </Col>
            </Row>
            <Row>
                <Text
                    style={{ marginTop: between('15px', '25px'), marginBottom: between('30px', '50px'), width: '85%' }}
                    weight="medium"
                    size="title">
                    Diğer Sorular için{' '}
                    <Text
                        style={{ textDecoration: 'underline' }}
                        onClick={() => history.push('sikca-sorulan-sorular')}
                        size="title"
                        weight="bold"
                        color="red">
                        "Sık Sorulan Sorular"
                    </Text>
                </Text>
            </Row>
        </QuestionSectionStyles>
    );
};

export default QuestionSection;
