import { Col, Row, Select } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../components/Button';

export const CustomButton = styled(Button)`
    min-width: 100%;
    height: 40px !important;
`;

export const PacketStyles = styled(Row)`
    border: 2px solid ${({ theme }) => theme.borderColor};
`;
export const Wrapper = styled(Col)``;
export const WrapperTop = styled(Col)`
    padding: 12px;
`;
export const Header = styled(Row)``;
export const Content = styled(Row)`
    padding-top: 12px;
`;
export const Footer = styled(Row)`
    border-top: 2px solid ${({ theme }) => theme.borderColor};
    padding: 12px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 24px;
`;

export const AddFoodBtn = styled(Button)`
    border: 2px solid ${({ theme }) => theme.borderColor};
    height: 48px !important;
    width: 40px !important;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    :hover {
        border-color: ${({ theme }) => theme.quaternaryColor};
    }
`;

export const CustomSelect = styled(Select)`
    width: 188px !important;

    .ant-select-selection-item {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 500;
    }

    .ant-select-selector {
        border-width: 2px !important;
        height: 48px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .ant-select-selection-placeholder {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 500;
    }
    .ant-select-selector:hover + .ant-select-arrow {
        color: ${({ theme }) => theme.primaryColor};
    }
    .ant-select-arrow {
        padding-right: 24px;
    }

    .ant-select-selection-item {
        color: #8fd89f;
    }
`;
