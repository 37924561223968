import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { ReactComponent as TabArrowSVG } from '../../../../../Assets/Shared/Profile/tab-button-arrow.svg';

export const CustomRow = styled(Row)`
    position: relative;
    width: 100%;

    &.tab-btn-row {
        margin-right: 20px;
        cursor: pointer;
        height: 56px;
        transition: 0.2s;

        :hover {
            background-color: #f2f2fc;
        }

        :active {
            background-color: #f2f2fc;
        }

        :focus {
            background-color: #f2f2fc;
        }
    }
`;

export const CustomCol = styled(Col)`
    margin-top: 0px;
`;

export const TabArrow = styled(TabArrowSVG)`
    position: absolute;
    right: 15px;
    transform: translateY(-50%);
    top: 50%;
`;

export const CustomLink = styled(NavLink)`
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 35px;
    padding-right: 30px;
    width: 100%;

    &.active {
        background-color: #f2f2fc;
    }
`;
