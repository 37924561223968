/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import Spinner from '../../../../../../components/Spinner';
import { Text } from '../../../../../../components/Typography/styles';
import { ReactComponent as Dustbin } from '../../../../../../../Assets/Shared/CartPage/dustbin.svg';
import { CustomRow, Table, Td, CustomCol } from './styles';
import { fetchBasket, postBasketRemove, postBasketRemoveAll } from '../../../../../../../services/Redux/Shared/order/api';

const PacketCard = (props) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const basket = selector((state) => state.customerOrderStore.basketList);
    const { updateSummary } = props;
    const { setUpdateSummary } = props;

    useEffect(() => {
        dispatch(fetchBasket());
    }, [dispatch, updateSummary]);

    const mealPrices = [];
    let startDate = null;
    let endDate = null;

    if (basket) {
        basket.map((box) => {
            let temp = 0;
            box.meals.map((meal) => {
                temp += meal.price;
                return 0;
            });
            mealPrices.push(temp);
            if (box.sold) {
                const data = {
                    box_id: box.id,
                };
                dispatch(postBasketRemove(data));
                window.location.reload();
            }
            return 0;
        });

        startDate = new Date(basket[0].sale_day.start_date);
        endDate = new Date(basket[0].sale_day.end_date);
    }

    const today = new Date();
    today.setHours(endDate.getHours());
    today.setMinutes(endDate.getMinutes());

    const time = new Date().getTime();
    const remaining = today.getTime() - time;
    let minutes = Math.floor(remaining / 60000);
    const hour = Math.floor(minutes / 60);
    minutes -= hour * 60;

    const [seconds, setSeconds] = useState(0);
    const [hours, setHours] = useState(hour);
    const [mins, setMins] = useState(minutes);
    const [secs, setSecs] = useState(60 - new Date().getSeconds());
    const [isActive, setIsActive] = useState(true);

    const removeAllFromBasket = () => {
        if (basket) {
            const arr = [];
            basket.map((element) => arr.push(element.id));
            const data = {
                all_box_ids: arr,
            };
            dispatch(postBasketRemoveAll(data));
            window.location.reload();
        }
        setUpdateSummary(!updateSummary);
    };
    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds((item) => item + 1);
                setHours(hour);
                setMins(minutes);
                setSecs(59 - new Date().getSeconds());
                if (hours <= 0 && mins <= 0 && secs <= 0) {
                    removeAllFromBasket();
                }
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    const removeBoxFromBasket = (boxId, index) => {
        const data = {
            box_id: boxId,
        };
        basket.splice(index, 1);
        dispatch(postBasketRemove(data));
        setUpdateSummary(!updateSummary);
    };

    return (
        <>
            {basket ? (
                <CustomRow>
                    <CustomRow className="top-row">
                        <Text weight="semi-bold">Sepettekiler</Text>
                        <Text weight="light">&nbsp;(paket {basket.length})</Text>
                    </CustomRow>
                    <Table>
                        {basket.map((item, index) => (
                            <tr>
                                <Td>
                                    <CustomRow align="middle" className="packet-row">
                                        <Col span={13}>
                                            <CustomRow align="middle">
                                                <Text>{item.text_name}</Text>
                                            </CustomRow>
                                            {item.text_name !== '' ? (
                                                <CustomRow>
                                                    <Text color="#707070" weight="light" size="14px">
                                                        {item.meals.map((meal, count) => {
                                                            if (count === item.meals.length - 1) {
                                                                return <span>{meal.name}</span>;
                                                            }
                                                            return <span>{meal.name}, &nbsp;</span>;
                                                        })}
                                                    </Text>
                                                </CustomRow>
                                            ) : (
                                                <></>
                                            )}
                                        </Col>
                                        <CustomCol className="right-col" span={11}>
                                            <CustomRow>
                                                <Col span={11}>
                                                    <Text weight="light" color="#707070">
                                                        {hours < 10 ? <>0{hours}</> : hours}:{mins < 10 ? <>0{mins}</> : mins}:
                                                        {secs < 10 ? <>0{secs}</> : secs}
                                                    </Text>
                                                </Col>
                                                <Col span={11}>
                                                    <CustomRow>
                                                        <CustomCol span={10}>
                                                            <Text style={{ textDecoration: 'line-through' }} weight="semi-bold" color="#bcbcbc">
                                                                {item.text_name === 'Surprise Box'
                                                                    ? item.package_setting.min_order_price
                                                                    : mealPrices[index]}
                                                                TL
                                                            </Text>
                                                        </CustomCol>
                                                        <CustomCol className="min-price" span={11}>
                                                            <Text weight="bold" color="green">
                                                                {item.package_setting.min_discounted_order_price} TL
                                                            </Text>
                                                        </CustomCol>
                                                    </CustomRow>
                                                </Col>
                                                <Col span={2}>
                                                    <Row justify="end">
                                                        <Dustbin onClick={() => removeBoxFromBasket(item.id, index)} />
                                                    </Row>
                                                </Col>
                                            </CustomRow>
                                        </CustomCol>
                                    </CustomRow>
                                </Td>
                            </tr>
                        ))}
                    </Table>
                </CustomRow>
            ) : (
                <CustomRow>
                    <Spinner />
                </CustomRow>
            )}
        </>
    );
};

export default PacketCard;
