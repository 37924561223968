/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-param-reassign */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Space } from 'antd';
import { CustomRow, CustomRadio, CustomButton } from './styles';
import { ReactComponent as Dustbin } from '../../../../../../../Assets/Shared/CartPage/dustbin.svg';
import { Text } from '../../../../../../components/Typography/styles';
import { postAddressActive, deleteAddress } from '../../../../../../../services/Redux/Shared/profile/api';
import ConfirmationModal from '../../OrdersPane/ConfirmationModal';

const defaultDeleteAddressModal = {
    handler: null,
    show: false,
};

const RegisteredAddress = (props) => {
    const { setNewAddress, setForm, addresses, setFormInfo, activeAddress, setDeleteAddress, deleteAddress3 } = props;
    const registeredAddresses = addresses.results.filter((item) => item.type !== 2);
    const [deleteAddressModal, setDeleteAddressModal] = useState(defaultDeleteAddressModal);
    const [radioValue, setRadioValue] = useState(activeAddress?.id || null);
    const dispatch = useDispatch();

    const onRadioChange = (value, id) => {
        setRadioValue(value);
        const data = {
            active_address: id,
        };
        dispatch(postAddressActive(data));
    };
    const openEditing = () => {
        setFormInfo(activeAddress);
        setForm(true);
    };

    const onNewAddress = () => {
        setNewAddress(true);
        setForm(false);
    };

    const handleDeleteAddress = (e, id, index) => {
        e.stopPropagation();
        dispatch(deleteAddress(id));
        registeredAddresses.slice(index, 1);
        setTimeout(() => {
            setDeleteAddress(deleteAddress3 + 1);
        }, 1000);
    };

    const handleShowDeleteAddressModal = (e, id, index) => {
        e.stopPropagation();
        setDeleteAddressModal({
            show: true,
            handler: () => {
                handleDeleteAddress(e, id, index);
                setDeleteAddressModal({ ...defaultDeleteAddressModal });
            },
        });
    };

    useEffect(() => {
        if (activeAddress) setRadioValue(activeAddress.id);
    }, [activeAddress]);

    return (
        <>
            <CustomRow>
                <CustomRow className="heading-row">
                    <Text weight="semi-bold" size="16px">
                        Kayıtlı Adreslerim
                    </Text>
                </CustomRow>
                <CustomRow className="address-info">
                    <Col span={16}>
                        {registeredAddresses.length > 0 ? (
                            <CustomRadio.Group
                                style={{ color: 'green' }}
                                size="large"
                                onChange={(e) => onRadioChange(e.target.value, e.target.number)}
                                value={radioValue}>
                                <Space direction="vertical">
                                    {registeredAddresses.map((item, index) => (
                                        <CustomRadio value={item.id} number={item.id} className="radio-wrapper">
                                            <CustomRow className="radio-row">
                                                <Col span={22} style={{ display: 'inline-block' }}>
                                                    <CustomRow>
                                                        <Text weight="light" color="#707070">
                                                            {item.name}
                                                        </Text>
                                                    </CustomRow>
                                                    <CustomRow>
                                                        <Text size="14px">{item.province}</Text>
                                                    </CustomRow>
                                                    <CustomRow className="item-row">
                                                        <Text color="#707070" weight="light" size="11px" style={{ whiteSpace: 'normal' }}>
                                                            {item.address}
                                                        </Text>
                                                    </CustomRow>
                                                    <CustomRow className="item-row">
                                                        <Text color="#707070" weight="light" size="11px">
                                                            {item.phone_number}
                                                        </Text>
                                                    </CustomRow>
                                                    <CustomRow className="item-row">
                                                        <Text color="#707070" weight="light" size="11px">
                                                            {item.description}
                                                        </Text>
                                                    </CustomRow>
                                                </Col>
                                                <Col style={{ display: 'inline-block' }}>
                                                    <Button
                                                        style={{ border: 0 }}
                                                        icon={<Dustbin />}
                                                        type="default"
                                                        onClick={(e) => handleShowDeleteAddressModal(e, item.id, index)}
                                                    />
                                                </Col>
                                            </CustomRow>
                                        </CustomRadio>
                                    ))}
                                </Space>
                            </CustomRadio.Group>
                        ) : (
                            <CustomRow>
                                <Text>Kayıtlı bir adresiniz bulunmamaktadır</Text>
                            </CustomRow>
                        )}
                    </Col>
                    <Col>
                        {registeredAddresses.length > 0 ? (
                            <CustomRow className="duzenle-row">
                                <CustomButton type="tertiaryReverse" onClick={openEditing}>
                                    Düzenle
                                </CustomButton>
                            </CustomRow>
                        ) : (
                            <></>
                        )}
                        <CustomRow className="yeni-row">
                            <CustomButton type="secondary" onClick={onNewAddress}>
                                Yeni Adres Ekle
                            </CustomButton>
                        </CustomRow>
                    </Col>
                </CustomRow>
            </CustomRow>
            <ConfirmationModal
                text="Adresi silmek istediğinizden emin misiniz?"
                noIcon
                dismiss={() => setDeleteAddressModal({ ...defaultDeleteAddressModal })}
                onCancel={() => setDeleteAddressModal({ ...defaultDeleteAddressModal })}
                onAccept={() => {
                    setDeleteAddressModal({ ...defaultDeleteAddressModal });
                    deleteAddressModal.handler();
                }}
                visible={deleteAddressModal.show}
            />
        </>
    );
};

export default RegisteredAddress;
