import styled from 'styled-components';
import { Row, Input, DatePicker } from 'antd';
import Button from '../../../../../../components/Button';

export const CustomRow = styled(Row)`
    width: 100%;

    &.card-body {
        margin-left: 30px;
        padding-right: 60px;
    }

    &.label-row {
        margin-top: 20px;
        margin-bottom: 15px;

        &.two {
            margin-top: 40px;
        }
    }

    &.email-row {
        overflow-x: auto;
        overflow-y: hidden;
    }

    &.telefon-row {
        margin-top: 105px;
        margin-bottom: 15px;
    }

    &.btn-row {
        margin-top: 35px;
    }
`;

export const CustomButton = styled(Button)`
    width: 212px;
    height: 48px;
`;

export const CustomInput = styled(Input)`
    width: 240px;
    height: 48px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;

    &:disabled {
        color: gray;
    }
`;

export const CustomPicker = styled(DatePicker)`
    border: 2px solid #f2f2fc;
    border-radius: 4px;
    height: 48px;
    width: 240px;
`;
