/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderDiv, StyledDiv, IconCol, IconRow, StyledHeaderDiv, StyledInnerDiv } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import BackGreenArrow from '../../../../../../../Assets/Shared/RestaurantDetailPage/BackGreenArrow.svg';
import Spinner from '../../../../../../components/Spinner';

function Index() {
    window.scrollTo(0, 0);
    const history = useHistory();
    const selector = useSelector;
    const { storeData } = selector((state) => state?.storeStore || {});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (storeData) {
            setLoading(false);
        }
    }, [storeData]);

    if (loading) return <Spinner />;
    return (
        <>
            <HeaderDiv>
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                    <Col span={2}>
                        <img src={BackGreenArrow} alt="back" onClick={() => history.goBack()} />
                    </Col>
                    <Col span={22} align="center">
                        <Text weight="light" style={{ textAlign: 'center', fontSize: '18px' }}>
                            Gıda Kategorileri
                        </Text>
                    </Col>
                </Row>
            </HeaderDiv>
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    paddingTop: 100,
                    minHeight: '100vh',
                    backgroundColor: '#F5F9FC',
                }}>
                <div
                    style={{
                        width: '100%',
                        padding: '10px 32px 10px 32px',
                    }}>
                    <StyledDiv>
                        <Row style={{ minWidth: '100%' }}>
                            {storeData?.categories.map((item) => (
                                <IconCol key={item?.name?.id} span={6}>
                                    <IconRow style={{ background: item?.name?.color }} justify="center" align="middle">
                                        <img src={item?.name?.photo} alt="img" style={{ width: '50%', margin: 'auto' }} />
                                    </IconRow>
                                    <Text weight="light" style={{ textAlign: 'center', fontSize: '14px' }}>
                                        {item?.name?.name}
                                    </Text>
                                </IconCol>
                            ))}
                        </Row>
                    </StyledDiv>
                </div>
                <div
                    style={{
                        width: '100%',
                        padding: '10px 0',
                    }}>
                    <StyledDiv>
                        <Row style={{ paddingLeft: '32px' }}>
                            <StyledHeaderDiv>
                                <Text weight="medium" size="description">
                                    Açık büfe yemek gıda güvenliği
                                </Text>
                            </StyledHeaderDiv>
                        </Row>
                        <Row>
                            <StyledInnerDiv>
                                <Text weight="light" size="small">
                                    Bu öğe açık büfeden yiyecek içeriyor.
                                </Text>
                            </StyledInnerDiv>
                        </Row>
                    </StyledDiv>
                    <StyledDiv>
                        <Row style={{ paddingLeft: '32px' }}>
                            <StyledHeaderDiv>
                                <Text weight="medium" size="description">
                                    Ek Öneriler
                                </Text>
                            </StyledHeaderDiv>
                        </Row>
                        <Row>
                            <StyledInnerDiv>
                                <Text weight="light" size="small">
                                    İşletmeniz, yiyeceklerinizi nasıl kullanacağınıza ve depolayacağınıza ilişkin öneriler tanımladıysa, bunları
                                    aşağıdaki alanda paylaşabilirsiniz.
                                </Text>
                            </StyledInnerDiv>
                        </Row>
                    </StyledDiv>
                </div>
            </div>
        </>
    );
}

export default Index;
