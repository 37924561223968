import React, { useState } from 'react';
import { Col } from 'antd';
import { CustomRow, CustomSwitch } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const GeneralSettings = () => {
    let nots = localStorage.getItem('notifications');
    if (nots === null) {
        nots = true;
        localStorage.setItem('notifications', nots);
    }

    if (nots === 'false') {
        nots = false;
    }

    if (nots === 'true') {
        nots = true;
    }

    const [notifications, setNotifications] = useState(nots);

    const onChangeNots = () => {
        setNotifications(!notifications);
        localStorage.setItem('notifications', !notifications);
    };

    return (
        <CustomRow className="main-row">
            <CustomRow>
                <Text weight="semi-bold">İletişim İzinleri</Text>
            </CustomRow>
            <CustomRow className="title-text">
                <Text weight="light" color="#707070">
                    Bana özel veya benim için geçerli kampanyalar vb. ile ilgili aşağıdaki seçeceğim yöntemler ile bilgi almak istiyorum.
                </Text>
                <Text weight="light" color="#707070">
                    Bu kapsamda sınırlı olmak üzere kişisel verilerimin işlenmesini ve ileti gönderimi için iş ortaklarına aktarılmasını kabul
                    ediyorum.
                </Text>
            </CustomRow>
            <CustomRow className="settings-row">
                <Col span={13} className="settings-col">
                    {/* <CustomRow justify="space-between" align="middle">
                        <Col>
                            <Text color="#707070" weight="light">
                                SMS
                            </Text>
                        </Col>
                        <Col>
                            <CustomSwitch />
                        </Col>
                    </CustomRow> */}
                    {/* <CustomRow className="settings-row" justify="space-between" align="middle">
                        <Col>
                            <Text color="#707070" weight="light">
                                E-posta
                            </Text>
                        </Col>
                        <Col>
                            <CustomSwitch />
                        </Col>
                    </CustomRow> */}
                    {/* <CustomRow className="settings-row" justify="space-between" align="top" wrap={false}>
                        <Col span={21}>
                            <CustomRow>
                                <Text color="#707070" weight="light">
                                    Telefonla Arama
                                </Text>
                            </CustomRow>
                            <CustomRow>
                                <Text color="#707070" weight="light" size="11px">
                                    Siparişlerimle ilgili iptal/değişiklik gibi durumlar için telefonla aranmak istiyorum
                                </Text>
                            </CustomRow>
                        </Col>
                        <Col>
                            <CustomSwitch />
                        </Col>
                    </CustomRow> */}
                    <CustomRow className="settings-row">
                        <Text weight="semi-bold">Bildirimler</Text>
                    </CustomRow>
                    <CustomRow className="bildirim-row" justify="space-between" align="middle">
                        <Col>
                            <Text color="#707070" weight="light">
                                Bildirimlere izin ver
                            </Text>
                        </Col>
                        <Col>
                            <CustomSwitch checked={notifications} onClick={() => onChangeNots()} />
                        </Col>
                    </CustomRow>
                    <CustomRow className="settings-row">
                        <Text weight="semi-bold">Konum Servisleri</Text>
                    </CustomRow>
                    <CustomRow className="bildirim-row" justify="space-between" align="middle">
                        <Col>
                            <Text color="#707070" weight="light">
                                Konumumu algılamasına izin ver
                            </Text>
                        </Col>
                        <Col>
                            <CustomSwitch />
                        </Col>
                    </CustomRow>
                </Col>
            </CustomRow>
        </CustomRow>
    );
};

export default GeneralSettings;
