import styled from 'styled-components';
import { Col } from 'antd';
import { between } from 'polished';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../helpers/constants';

export const SupportPageStyles = styled(Col)`
    width: 100%;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    padding-top: ${between('120px', '140px', '320px', '1920px')};

    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-repeat: no-repeat;
    background-size: 80%;
    background-position-x: 80%;
    background-position-y: 30%;
    background-color: #fff;
`;

export const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AboutTitle = styled.div``;
