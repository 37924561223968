/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'antd';
import { CustomRow } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import Onemli from '../../../../../../../../Assets/Shared/CartPage/onemli.png';
import { fetchStoreTime } from '../../../../../../../../services/Redux/Shared/store/api';
import Spinner from '../../../../../../../components/Spinner/index';

const SelectionRow = (props) => {
    const today = new Date();
    const { packet } = props;
    const { setTimeInt, timeInt } = props;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const selector = useSelector;
    const dispatch = useDispatch();

    const basket = selector((state) => state.customerOrderStore.basketList);
    const startDate = new Date(basket[0].sale_day.start_date);
    const endDate = new Date(basket[0].sale_day.end_date);

    useEffect(() => {
        const info = new FormData();
        info.append('store', basket[0].store.id);
        dispatch(fetchStoreTime(info));
    }, []);

    const checkTime = (item) => {
        if (new Date(item.start_date) < today) {
            return false;
        }
        return true;
    };

    const times = selector((state) => state.storeStore.timeData);
    if (times) {
        times.sort((a, b) => a.id - b.id);
    }
    if (packet) {
        return (
            <CustomRow className="main-row">
                <CustomRow>
                    <Col span={12}>
                        <CustomRow>
                            <Text weight="semi-bold">Bugün - {today.toLocaleDateString('tr-TR', options)}</Text>
                        </CustomRow>
                        <CustomRow align="middle" justify="center" className="time-row">
                            <Text color="#424242" size="14px" weight="light">
                                &nbsp;
                                {startDate.toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })} -&nbsp;
                                {endDate.toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })}
                            </Text>
                        </CustomRow>
                    </Col>
                </CustomRow>
            </CustomRow>
        );
    }
    return (
        <>
            {times ? (
                <CustomRow className="main-row">
                    <CustomRow justify="space-between">
                        <CustomRow>
                            <Text weight="semi-bold">Bugün - {today.toLocaleDateString('tr-TR', options)}</Text>
                        </CustomRow>
                        {times.map((item, index) => (
                            <>
                                <Col span={12}>
                                    <CustomRow justify={index % 2 === 1 ? 'end' : 'start'}>
                                        {item.is_available && checkTime(item) && (
                                            <Col span={23} onClick={() => setTimeInt(item.id)}>
                                                <CustomRow
                                                    align="middle"
                                                    justify="center"
                                                    className={`time-row ${item.id === timeInt ? 'selected' : 'unselected'}`}>
                                                    <Text color="#424242" size="14px" weight="light">
                                                        &nbsp;
                                                        {new Date(item.start_date).toLocaleTimeString('tr-TR', {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}
                                                        &nbsp;-&nbsp;
                                                        {new Date(item.end_date).toLocaleTimeString('tr-TR', {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}
                                                    </Text>
                                                </CustomRow>
                                            </Col>
                                        )}
                                        {(!item.is_available || !checkTime(item)) && (
                                            <Col span={23}>
                                                <CustomRow align="middle" justify="center" className="time-row unavailable">
                                                    <Text color="#424242" size="14px" weight="light">
                                                        &nbsp;
                                                        {new Date(item.start_date).toLocaleTimeString('tr-TR', {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}
                                                        -&nbsp;
                                                        {new Date(item.end_date).toLocaleTimeString('tr-TR', {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })}
                                                    </Text>
                                                </CustomRow>
                                            </Col>
                                        )}
                                    </CustomRow>
                                </Col>
                            </>
                        ))}
                    </CustomRow>
                    {!packet && (
                        <CustomRow className="onemli-row" align="middle">
                            <img src={Onemli} alt="sup" />
                            <Text size="14px">&nbsp;&nbsp;&nbsp;&nbsp;Siparişinizi size iletmiş olduğumuz sipariş numarası ile teslim alınız.</Text>
                        </CustomRow>
                    )}
                </CustomRow>
            ) : (
                <Spinner />
            )}
        </>
    );
};

export default SelectionRow;
