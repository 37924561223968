import styled from 'styled-components';
import { Row, Divider, Button as AButton } from 'antd';
import Button from '../../../../../../components/Button';

export const CustomRow = styled(Row)`
    width: 100%;

    &.removeSmall {
        @media (min-width: 1025px) {
            display: none;
        }
    }

    &.button-row {
        margin-top: 20px;
    }
`;

export const CustomDivider = styled(Divider)`
    border-top: 2px solid #f2f2fc;
`;

export const CustomButton = styled(Button)`
    width: 240px;
    height: 48px;
`;

export const CustomDisabledButton = styled(AButton)`
    width: 240px;
    height: 48px;
    background: #c7c7c7 !important;
    border-radius: 5px;
`;
