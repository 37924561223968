/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Provider from './Provider/index';
import { tryAuth } from '../../../../../services/Redux/auth/api';
import LoginCardStyles, { Title, Text, Form, ContinueButton, ForgotPasswordButton, SocialDiv } from './styles';
import FloatingLabel from '../../../../components/TextInput/textInput';
import ForgotPasswordModal from '../../ProfilePage/Content/InformationPane/PasswordCard/ForgotPasswordModal';
import { ReactComponent as CloseBtn } from '../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';
import CustomErrorMessage from '../../../../components/CustomErrorMessage';

const LoginCard = ({ history }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [warn, setWarn] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [errorMessage, setErrorMessage] = useState('');

    // Fixed the following code for routing problem
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
        if (user.stores) history.push('/dashboard');
        else history.push('/restoran-bul');
    }

    const dispatch = useDispatch();

    const changeBorderColor = () => {
        setWarn(true);
    };

    const login = (values) => {
        const { email, password } = values;
        setErrorMessage('');
        dispatch(tryAuth(email.toLowerCase(), password, changeBorderColor))
            .then(() => {
                // Also this line for the same routing problem
                if (user) {
                    if (user.stores) history.push('/dashboard');
                    else history.push('/restoran-bul');
                }
            })
            .catch((error) => {
                if (typeof error === 'string') {
                    setErrorMessage(error);
                }
            });
    };

    return (
        <LoginCardStyles>
            <Title size="title">Giriş Yap</Title>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={login}>
                {({ email, password, handleChange }) => (
                    <Form onFinish={login}>
                        <Form.Item style={{ marginTop: 50 }} name="email" rules={[{ required: true, message: 'Lütfen e-posta adresinizi giriniz' }]}>
                            <FloatingLabel id="email" name="email" placeholder="E-mail Adresi" type="email" value={email} onChange={handleChange} />
                        </Form.Item>
                        {errorMessage === 'Invalid email' && <CustomErrorMessage message={errorMessage} />}
                        <Form.Item name="password" rules={[{ required: true, message: 'Lütfen şifrenizi giriniz' }]}>
                            <FloatingLabel
                                id="password"
                                name="password"
                                placeholder="Şifre"
                                type="password"
                                value={password}
                                onChange={handleChange}
                                style={{ borderColor: warn ? 'red' : 'smoke' }}
                            />
                        </Form.Item>
                        {errorMessage === 'Geçersiz şifre ve üye adi !' && <CustomErrorMessage message={errorMessage} />}
                        <ContinueButton htmlType="submit">
                            <Text color="white" weight="bold">
                                Giriş Yap
                            </Text>
                        </ContinueButton>
                    </Form>
                )}
            </Formik>
            <ForgotPasswordButton type="link" onClick={() => setIsModalVisible(true)}>
                <Text style={{ marginLeft: -12, color: '#F38262', border: 'none' }}>Parolanızı mı unuttunuz?</Text>
            </ForgotPasswordButton>
            <ForgotPasswordModal dismiss={handleCancel} closeIcon={<CloseBtn />} onCancel={handleCancel} visible={isModalVisible} />

            <SocialDiv>
                <Provider provider="google" />
                <Provider provider="facebook" />
            </SocialDiv>
        </LoginCardStyles>
    );
};

export default withRouter(LoginCard);
