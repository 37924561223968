import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';
import { Title } from '../../../../../components/Typography/styles';
import button from '../../../../../components/Button/index';
import { theme as Theme } from '../../../../../themes/theme';

export default styled(Row)`
    width: 100%;
    height: 75px;
    border-radius: 30px 30px 0 0;
    background: ${({ theme }) => theme.lightColor};
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
`;

export const Content = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Date = styled(Title)`
    margin: 0 !important;
`;

export const LeftPart = styled(Title)`
    display: ${(props) => (props.show ? 'block' : 'none')};
    margin: 0 !important;
`;

export const NavButton = styled.img`
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
`;

export const ButtonContainer = styled.div`
    width: 90% !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 15px;
    background: ${({ theme }) => theme.contentBackground};
    height: 60px;
    width: 100%;
`;

export const Button = styled(button)`
    color: ${(props) => (props.ghost ? 'black' : Theme.inactiveColor)} !important;
    background: ${(props) => (props.ghost ? Theme.quinaryColor : 'transparent')};
    font-weight: 600;
    font-size: ${between('8px', '14px')};

    height: 40px !important;
    width: 46%;
    border: none;
    box-shadow: none !important;

    &:hover {
        color: #000 !important;
        background: #bcbcbc;
    }
`;
