import React from 'react';
import { Col } from 'antd';
import Card from '../../../../components/Card';
import alertIcon from '../../../../../Assets/Dashboard/alert.png';
import notificationIcon from '../../../../../Assets/Dashboard/notification.png';
import clockIcon from '../../../../../Assets/Dashboard/notify-clock.png';
import questionIcon from '../../../../../Assets/Dashboard/question.png';
import { Wrapper, Content } from './styles';

const NotifyCard = ({ type, children, style }) => (
    <Card style={style} type={type}>
        <Wrapper justify="start">
            <Col span={4}>
                {type === 'tips' && <img src={questionIcon} alt="" />}
                {type === 'notification' && <img src={notificationIcon} alt="" />}
                {type === 'alert' && <img src={alertIcon} alt="" />}
                {type === 'timer' && <img src={clockIcon} alt="" />}
            </Col>
            <Content span={20}>{children}</Content>
        </Wrapper>
    </Card>
);

export default NotifyCard;
