/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FavouritesStyles, Wrapper, Header, Content, AddFoodBtn } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import FoodLabel from './FoodLabel';
import { fetchFavouriteMeals } from '../../../../../../services/Redux/Dashboard/favourites/api';
import FavouriteModal from '../../components/FavouriteModal';

const Favourites = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [favouriteToRender, setFavouriteToRender] = useState();

    const selector = useSelector;
    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        dispatch(fetchFavouriteMeals());
    }, []);

    const { favouriteMeals } = selector((state) => state.favMealsStore);

    useEffect(() => {
        setFavouriteToRender(favouriteMeals);
    }, [favouriteMeals]);

    return (
        <FavouritesStyles>
            <Wrapper span={24}>
                <Header align="middle" justify="start">
                    <Text weight="semi-bold">Sık Kullanılanlar</Text>
                </Header>
                <Content
                    align="middle"
                    justify="start"
                    gutter={[
                        { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                        { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                    ]}>
                    {favouriteToRender ? (
                        favouriteToRender.map(
                            ({ id, name, description, price, photo, favorite }) =>
                                favorite && (
                                    <Col>
                                        <FoodLabel
                                            foodId={id}
                                            name={name}
                                            price={price}
                                            photo={photo}
                                            description={description}
                                            favourite={favorite}
                                        />
                                    </Col>
                                )
                        )
                    ) : (
                        <div>nodata</div>
                    )}
                    <Col>
                        <AddFoodBtn onClick={() => showModal()}>
                            <Text weight="bold" size="noble-regular" color="yellow">
                                +
                            </Text>
                        </AddFoodBtn>
                    </Col>
                </Content>
            </Wrapper>
            <FavouriteModal
                selec
                visible={isModalVisible}
                onCancel={handleCancel}
                favouriteToRender={favouriteToRender}
                setFavouriteToRender={setFavouriteToRender}
                isModalVisible={isModalVisible}
            />
        </FavouritesStyles>
    );
};

export default Favourites;
