import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { cafeIcons } from '../../../../../Assets/Shared/mapMarkerIcons';
import 'leaflet/dist/leaflet.css';
import './style.css';

const Maps = ({ storeData, user }) => {
    const position = [user?.latitude || 41.08, user?.longitude || 29.05];

    return (
        <>
            <MapContainer className="leaflet-container" center={position} zoom={12} maxZoom={30} minZoom={2}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token={accessToken}"
                    accessToken="i0sWc78lMuGghhd8kl1ZmmvDbl8hfdBQFZZ2FJzSYdjVcA8neQSJ7IgFuzM30ZX6"
                    maxNativeZoom={26}
                />
                {storeData ? (
                    storeData?.map((item, index) => (
                        <>
                            <Marker key={index} position={[item?.latitude, item?.longitude]} icon={cafeIcons}>
                                <Popup>
                                    <p style={{ textAlign: 'center', color: 'orange', fontSize: '1rem', fontWeight: 'bold' }}>
                                        {`${item?.calendar[0]?.box_count} paket` || item?.store?.cancel_count}
                                    </p>
                                    <p>
                                        <img style={{ width: '100%' }} src={item?.background || item?.store?.background} alt="background" />
                                    </p>
                                    <p>
                                        <strong>{item?.name}</strong> <br /> {item?.address || item?.store?.address}
                                    </p>
                                </Popup>
                            </Marker>
                        </>
                    ))
                ) : (
                    <Marker position={position} icon={cafeIcons} />
                )}
            </MapContainer>
        </>
    );
};

export default Maps;
