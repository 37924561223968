import { Spin, Row } from 'antd';
import Countdown from 'react-countdown';
import { NotifyCardStyles } from './styles';
import { Text } from '../../../../components/Typography/styles';

const TimerCard = ({ startDate }) => {
    const renderer = ({ hours, minutes, seconds, completed }) => {
        let h;
        let m;
        let s;

        if (hours < 10) {
            h = 0;
        }
        if (minutes < 10) {
            m = 0;
        }
        if (seconds < 10) {
            s = 0;
        }
        if (completed) {
            return (
                <Text size="smallTitle" weight="light">
                    Hazır paket tanımlayabilirsiniz.
                </Text>
            );
        }
        return (
            <span>
                {h}
                {hours} : {m}
                {minutes} : {s}
                {seconds}
            </span>
        );
    };

    return (
        <NotifyCardStyles
            style={{ marginTop: '28px' }}
            type="timer"
            // eslint-disable-next-line react/no-children-prop
            children={
                <Row className="space-between">
                    <Text weight="semi-bold" style={{ widht: '100%' }}>
                        Sürpriz paketin Tanımlı pakete geçmesine kalan süre
                    </Text>
                    <Text size="title" weight="light" style={{ width: '100%', marginTop: '28px' }}>
                        {startDate ? <Countdown date={startDate} renderer={renderer} /> : <Spin />}
                    </Text>
                </Row>
            }
        />
    );
};

export default TimerCard;
