export const CREATE_CARD_REGISTER_START = 'CREATE_CARD_REGISTER_START';
export const CREATE_CARD_REGISTER_SUCCESS = 'CREATE_CARD_REGISTER_SUCCESS';
export const CREATE_CARD_REGISTER_FAIL = 'CREATE_CARD_REGISTER_FAIL';

// CARD LIST

export const GET_CARD_LIST_START = 'GET_CARD_LIST_START';
export const GET_CARD_LIST_SUCCESS = 'GET_CARD_LIST_SUCCESS';
export const GET_CARD_LIST_FAIL = 'GET_CARD_LIST_FAIL';

// CARD DELETE

export const DELETE_CARD_START = 'DELETE_CARD_START';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAIL = 'DELETE_CARD_FAIL';

// GET IP

export const GET_IP_START = 'GET_IP_START';
export const GET_IP_SUCCESS = 'GET_IP_SUCCESS';
export const GET_IP_FAIL = 'GET_IP_FAIL';

// PAYMENT INQUIRY

export const GET_PAYMENT_INQUIRY_START = 'GET_PAYMENT_INQUIRY_START';
export const GET_PAYMENT_INQUIRY_SUCCESS = 'GET_PAYMENT_INQUIRY_SUCCESS';
export const GET_PAYMENT_INQUIRY_FAIL = 'GET_PAYMENT_INQUIRY_FAIL';
