import React from 'react';
import LoginPageStyles, { Content, Item } from './styles';
import LoginCard from './LoginCard';
import bg from '../../../../Assets/Shared/LoginPage/loginBackground.svg';

const LoginPage = () => (
    <LoginPageStyles backgroundImage={bg}>
        <Content justify="center">
            <Item xs={22}>
                <LoginCard />
            </Item>
        </Content>
    </LoginPageStyles>
);

export default LoginPage;
