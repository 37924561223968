/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Spinner from '../../../../../../components/Spinner';
import { HeaderDiv, StyledRow, StyledDiv } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import BackGreenArrow from '../../../../../../../Assets/Shared/RestaurantDetailPage/BackGreenArrow.svg';

function Index({ setShow }) {
    const history = useHistory();
    window.scrollTo(0, 0);
    const selector = useSelector;
    const storeData = selector((state) => state?.storeStore?.storeData);

    const today = new Date();
    const days = [new Date(), new Date(), new Date(), new Date(), new Date(), new Date()];
    days[0].setDate(today?.getDate() + 1);
    days[1].setDate(today?.getDate() + 2);
    days[2].setDate(today?.getDate() + 3);
    days[3].setDate(today?.getDate() + 4);
    days[4].setDate(today?.getDate() + 5);
    days[5].setDate(today?.getDate() + 6);
    const dateItem = [null, null, null, null, null, null, null];
    const startDate = ['', '', '', '', '', '', ''];
    const endDate = ['', '', '', '', '', '', ''];
    storeData.calendar.map((item) => {
        if (new Date(item.start_date).toDateString() === today.toDateString()) {
            dateItem[0] = item;
            startDate[0] = new Date(item.start_date);
            endDate[0] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[0].toDateString()) {
            dateItem[1] = item;
            startDate[1] = new Date(item.start_date);
            endDate[1] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[1].toDateString()) {
            dateItem[2] = item;
            startDate[2] = new Date(item.start_date);
            endDate[2] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[2].toDateString()) {
            dateItem[3] = item;
            startDate[3] = new Date(item.start_date);
            endDate[3] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[3].toDateString()) {
            dateItem[4] = item;
            startDate[4] = new Date(item.start_date);
            endDate[4] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[4].toDateString()) {
            dateItem[5] = item;
            startDate[5] = new Date(item.start_date);
            endDate[5] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[5].toDateString()) {
            dateItem[6] = item;
            startDate[6] = new Date(item.start_date);
            endDate[6] = new Date(item.end_date);
        }
        return 0;
    });

    const weekdays = ['Pazar', 'Pazartesi', 'Salı', 'Çarçamba', 'Perşembe', 'Cuma', 'Cumartesi'];
    const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
    const loop = [0, 1, 2, 3, 4, 5, 6];

    if (!storeData) return <Spinner />;
    return (
        <>
            <HeaderDiv>
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                    <Col span={2}>
                        <img src={BackGreenArrow} alt="back" onClick={() => history.goBack()} />
                    </Col>
                    <Col span={22} align="center">
                        <Text weight="light" style={{ textAlign: 'center', fontSize: '18px' }}>
                            Çalışma Saatleri
                        </Text>
                    </Col>
                </Row>
            </HeaderDiv>
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    marginTop: 80,
                    paddingTop: 27,
                    minHeight: '100vh',
                    backgroundColor: '#F5F9FC',
                }}>
                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#FFFFFF',
                        padding: '10px 32px 10px 32px',
                    }}>
                    {loop.map((id) => (
                        <StyledDiv key={id}>
                            <Row>
                                {startDate[id] !== '' ? (
                                    <Text weight="light" size="small">
                                        {startDate[id]?.getDate()} &nbsp;
                                        {months[startDate[id]?.getMonth()]} &nbsp;
                                        {startDate[id]?.getFullYear()}
                                    </Text>
                                ) : (
                                    <Text weight="light" size="small">
                                        {days[id - 1]?.getDate()} &nbsp;
                                        {months[startDate[id - 1]?.getMonth()]} &nbsp;
                                        {startDate[id - 1]?.getFullYear()}
                                    </Text>
                                )}
                            </Row>
                            <StyledRow>
                                <Text weight="medium" size="description">
                                    {startDate[id] !== '' ? weekdays[startDate[id]?.getDay()] : weekdays[days[id - 1]?.getDay()]}
                                </Text>
                                <Text weight="medium" size="description">
                                    {dateItem[id].is_active && dateItem[id].box_count > 0 ? (
                                        <>
                                            {startDate[id].toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })} -
                                            {endDate[id].toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })}
                                        </>
                                    ) : (
                                        'Kapalı'
                                    )}
                                </Text>
                            </StyledRow>
                        </StyledDiv>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Index;
