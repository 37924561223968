import * as actionTypes from './actionTypes';

// GET
export const getFavouritesStartAction = () => ({
    type: actionTypes.GET_FAVOURITE_MEALS,
});

export const getFavouritesSuccessAction = (favourites) => ({
    type: actionTypes.GET_FAVOURITE_MEALS_SUCCESS,
    payload: favourites,
});

export const getFavouritesFailAction = (error) => ({
    type: actionTypes.GET_FAVOURITE_MEALS_FAIL,
    payload: error,
});

// DELETE
export const deleteFavouritesStartAction = (id) => ({
    type: actionTypes.DELETE_FAVOURITE_MEALS,
    payload: id,
});

export const deleteFavouritesSuccessAction = (successMessage) => ({
    type: actionTypes.DELETE_FAVOURITE_MEALS_SUCCESS,
    payload: successMessage,
});

export const deleteFavouritesFailAction = (error) => ({
    type: actionTypes.DELETE_FAVOURITE_MEALS_FAIL,
    payload: error,
});
