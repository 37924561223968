/* eslint-disable sonarjs/no-identical-functions */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    monthlyOrders: null,
    selctedDateOrders: null,
    closestSaleDate: '',
    alertCardText: '',
    submitSuccesful: '',
};

const getMonthlyOrdersReducer = (state) => ({
    ...state,
    loading: true,
});

const getMonthlyOrdersSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    monthlyOrders: action.payload.results,
});

const getMonthlyOrdersFailReducer = (state) => ({
    ...state,
    loading: false,
});

const updateDailyPlanReducer = (state) => ({
    ...state,
    loading: true,
});
const updateDailyPlanSuccessReducer = (state) => ({
    ...state,
    loading: false,
    submitSuccesful: true,
});
const updateDailyPlanFailReducer = (state) => ({
    ...state,
    loading: false,
    submitSuccesful: 'false',
});

const setSelectedDateOrders = (state, action) => ({
    ...state,
    selectedDateOrders: action.payload,
});

const getAlertCardText = (state, action) => ({
    ...state,
    alertCardText: action.payload,
});

const getClosestSaleDate = (state, action) => ({
    ...state,
    closestSaleDate: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MONTHLY_ORDERS:
            return getMonthlyOrdersReducer(state);
        case actionTypes.GET_MONTHLY_ORDERS_SUCCESS:
            return getMonthlyOrdersSuccessReducer(state, action);
        case actionTypes.GET_MONTHLY_ORDERS_FAIL:
            return getMonthlyOrdersFailReducer(state);
        case actionTypes.UPDATE_DAILY_PLAN:
            return updateDailyPlanReducer(state);
        case actionTypes.UPDATE_DAILY_PLAN_SUCCESS:
            return updateDailyPlanSuccessReducer(state);
        case actionTypes.UPDATE_DAILY_PLAN_FAIL:
            return updateDailyPlanFailReducer(state);
        case actionTypes.SET_SELECTED_DATE_ORDERS:
            return setSelectedDateOrders(state, action);
        case actionTypes.GET_ALERT_TEXT:
            return getAlertCardText(state, action);
        case actionTypes.GET_CLOSEST_SALE_DATE:
            return getClosestSaleDate(state, action);
        default:
            return state;
    }
};

export default reducer;
