import React from 'react';
import { Col, Row } from 'antd';
import { SupportPageStyles, AboutWrapper, AboutTitle } from './styles';
import useMediaQuery from '../../../../helpers/Hooks/useMediaQuery';
import { Text, Title } from '../../../components/Typography/styles';
import phone from '../../../../Assets/Shared/Layout/phone.svg';
import mail from '../../../../Assets/Shared/Layout/mail.svg';
import konum from '../../../../Assets/Shared/Layout/konum.svg';
import map from '../../../../Assets/Shared/Layout/map.png';
import bg from '../../../../Assets/Shared/AboutUs/aboutUs.svg';

const Support = () => {
    const { sm } = useMediaQuery();
    return (
        <SupportPageStyles backgroundImage={bg}>
            <AboutWrapper style={{ width: '80%' }}>
                <AboutTitle style={{ marginBottom: '6%' }}>
                    <Title style={{ textAlign: sm && 'center' }} weight="bold" color="#F38262" size="mediumTitle">
                        Bize Ulaşın
                    </Title>
                </AboutTitle>
                <Row style={{ marginBottom: '40px' }}>
                    <img src={mail} alt="" width="30px" style={{ marginRight: '32px' }} />
                    <Text>destek@dongu.com</Text>
                </Row>
                <Row style={{ marginBottom: '40px' }}>
                    <img src={phone} alt="" width="30px" style={{ marginRight: '32px' }} />
                    <Text>+90 533 386 41 31</Text>
                </Row>
                <Row wrap={false} style={{ width: '80%', marginBottom: '5%' }}>
                    <Col>
                        <img src={konum} alt="" width="30px" style={{ marginRight: '32px' }} />
                    </Col>
                    <Col flex="auto">
                        <Text>Bebek, Çatalhöyük Sokaği No:10/1, 34342 Beşiktaş/İstanbul</Text>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <img src={map} alt="" width="90%" height="200px" style={{ objectFit: 'cover', border: 'solid 1px #f2f2fc' }} />
                </Row>
            </AboutWrapper>
        </SupportPageStyles>
    );
};

export default Support;
