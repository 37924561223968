import styled from 'styled-components';
import { Row, Col, Divider, Rate } from 'antd';
import Button from '../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;

    &.card-row {
        border: 2px solid #f2f2fc;
    }

    &.top-row {
        padding: 25px 20px;

        @media (max-width: 1075px) {
            align-items: flex-start;
        }
    }

    &.padding-top {
        margin-top: 14px;
    }

    &.price-row {
        background: #f2f2fc;
        border-radius: 4px;
        width: 70px;
        height: 36px;
    }

    &.share-row {
        justify-content: center;

        @media (min-width: 1075px) {
            justify-content: end;
        }
    }

    &.restaurant-row {
        flex-direction: column;

        @media (min-width: 1280px) {
            align-items: center;
            flex-direction: row;
        }
    }
`;

export const CustomCol = styled(Col)`
    &.star-col {
        max-width: 100%;
        @media (min-width: 1024px) {
            max-width: 16.66666667%;
        }
    }

    &.img-col {
    }

    &.img-col > img {
        border: 2px solid #f2f2fb;
        border-radius: 7px;
        object-fit: contain;
    }
`;

export const InnerRow = styled(CustomRow)`
    flex-direction: column;

    @media (min-width: 1075px) {
        flex-direction: row;
    }
`;

export const CustomDivider = styled(Divider)`
    border: 1px solid #f2f2fc;
    margin-top: 0px;
    margin-bottom: 0px;
`;

export const Rating = styled(Rate)`
    display: flex !important;

    li.ant-rate-star {
        height: 20px !important;
        margin: 0 !important;
        margin-left: 2px !important;
        margin-top: 0.5rem !important;

        @media (min-width: 1280px) {
            margin-top: 0 !important;
            margin-left: 5px !important;
        }

        div {
            height: 20px;
        }
    }
`;

export const CustomButton = styled(Button)`
    width: 100px;
    height: 48px;
`;

export const HiddenRow = styled.div`
    height: ${({ active }) => (active ? '450px' : '0')};
    transition: height 0.5s;
    overflow: hidden;
    width: 100%;
`;

export const CustomForm = styled.form`
    width: 100%;
`;
