/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { Form, Col, Dropdown, Menu, Row, Checkbox } from 'antd';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Container } from '../LocationMap/styles';
import { CustomRow, CustomInput, FormItem, DropdownButton, CustomButton, CustomSelect, CustomOption, CustomCol } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { ReactComponent as DropdownArrow } from '../../../../../../../Assets/Shared/Profile/dropdown-arrow.svg';
import IlceMenu from './DropdownMenu';
import { postAddress, putAddress } from '../../../../../../../services/Redux/Shared/profile/api';

const Title = ({ text }) => (
    <Text weight="light" size="14px">
        {text}
    </Text>
);

const reqiredText = 'Bu alan boş bırakılamaz';
const addressTypes = ['Ev Adresi', 'Ofis Adresi', 'Fatura Adresi'];

const AddressForm = (props) => {
    const { formInfo, latitude, longitude, setForm, newInfo, deleteAddress3, setDeleteAddress, setNewAddress } = props;
    const dispatch = useDispatch();
    const [showTCKN, setShowTCKN] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [updatedAddress, setUpdatedAddress] = useState(formInfo?.address || newInfo?.formatted_address || '');
    const [updatedLong, setUpdatedLong] = useState(formInfo?.longitude || longitude || 0);
    const [updatedLat, setUpdatedLat] = useState(formInfo?.latitude || latitude || 0);

    const handleAddressClick = () => {
        setVisible2(false);
    };

    const handleAddressChange = (flag) => {
        setVisible2(flag);
    };

    const handleMenuClick = () => {
        setVisible(false);
    };

    const handleMenuChange = (flag) => {
        setVisible(flag);
    };

    const addAddress = (values) => {
        const { type, city, addAsPaymentAddress, bina, daire } = values;
        const data = {
            ...values,
            address: updatedAddress,
            type: addressTypes.indexOf(type),
            city: `${city[0].toLocaleUpperCase() + city.split('').slice(1).join('')}`,
            daire: `Bina no:${bina} Daire no: ${daire}`,
            latitude: updatedLat,
            longitude: updatedLong
        };

        if (!formInfo) {
            dispatch(postAddress(data));
            addAsPaymentAddress && dispatch(postAddress({ ...data, type: 2 }));
        } else {
            dispatch(putAddress(data, formInfo.id));
            addAsPaymentAddress && dispatch(postAddress({ ...data, type: 2 }));
        }
        setForm(false);
        setNewAddress(false);
        setTimeout(() => {
            setDeleteAddress(deleteAddress3 + 1);
        }, 1000);
    };

    const AddressValidationSchema = Yup.object().shape({
        tckn_vkn: showTCKN ? Yup.string().min(11, 'T.C. Kimlik No. 11 haneli olmalidır').required(reqiredText) : Yup.string(),
        name: Yup.string().required(reqiredText),
        phone_number: Yup.string().min(10, 'Lütfen telefon numaranızı başında sıfır olmadan, 10 haneli olacak şekilde giriniz').required(reqiredText),
        description: Yup.string().required(reqiredText),
    });

    const AddressTypeMenu = (addressProps) => {
        const { setFieldValue } = addressProps;
        const handleItemClick = (i) => {
            const addressType = addressTypes[i];
            if (addressType === 'Fatura Adresi') {
                setShowTCKN(true);
            } else setShowTCKN(false);

            setFieldValue('type', addressTypes[i]);
        };
        return (
            <Menu onClick={handleMenuClick}>
                {addressTypes.map((type, i) => (
                    <Menu.Item key={type} onClick={() => handleItemClick(i)}>
                        <Text size="14px">{addressTypes[i]}</Text>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                setUpdatedAddress(results[0].formatted_address);
                return getLatLng(results[0]);
            })
            .then((latLng) => {
                setUpdatedLat(latLng.lat);
                setUpdatedLong(latLng.lng);
            })
            .catch((error) => console.error('Error', error));
    };

    const CustomError = ({ children }) => (
        <Text size="smallRegular" color="red">
            {children || ''}
        </Text>
    );

    return (
        <CustomRow className="main-row">
            <CustomCol className="left-col">
                <CustomRow className="title-row">
                    <Text weight="semi-bold">Adres</Text>
                </CustomRow>
                <Formik
                    initialValues={{
                        type: formInfo ? addressTypes[formInfo.type] : 'Ev Adresi',
                        province: formInfo?.province || newInfo?.address_components[3]?.long_name || 'İlçe Seçin',
                        tckn_vkn: formInfo?.tckn_vkn || '',
                        name: formInfo?.name || '',
                        city: formInfo?.city || newInfo?.address_components[4].long_name || '',
                        address: formInfo?.address || newInfo?.formatted_address || '',
                        daire: '',
                        bina: '',
                        country: 'Turkey',
                        phone_number: formInfo?.phone_number || '',
                        description: formInfo?.description || '',
                        latitude: formInfo?.latitude || latitude || 0,
                        longitude: formInfo?.longitude || longitude || 0,
                        addAsPaymentAddress: false,
                    }}
                    validationSchema={AddressValidationSchema}
                    onSubmit={addAddress}>
                    {({ values, handleChange, setFieldValue, handleSubmit }) => (
                        <Form layout="vertical" size="large" onFinish={handleSubmit}>
                            <CustomRow wrap={false}>
                                <CustomCol span={16}>
                                    <CustomRow>
                                        <Col span={11}>
                                            <FormItem label={<Title text="Adres Tipi" />}>
                                                <Dropdown
                                                    overlay={<AddressTypeMenu setFieldValue={setFieldValue} />}
                                                    overlayStyle={{ border: '2px solid #F2F2FC' }}
                                                    trigger={['click']}
                                                    onVisibleChange={handleMenuChange}
                                                    visible={visible}>
                                                    <DropdownButton>
                                                        <Row align="middle" justify="space-between">
                                                            <Text size="14px">{values.type}</Text>
                                                            <DropdownArrow />
                                                        </Row>
                                                    </DropdownButton>
                                                </Dropdown>
                                            </FormItem>
                                        </Col>
                                        <Col span={2} />
                                        <Col span={11}>
                                            {showTCKN && (
                                                <FormItem label={<Title text="VKN/TCKN" />}>
                                                    <CustomInput
                                                        placeholder="Kimlik Numara"
                                                        name="tckn_vkn"
                                                        value={values.tckn_vkn}
                                                        onChange={(e) => /^\d*$/.test(e.target.value) && handleChange(e)}
                                                        maxLength={11}
                                                    />
                                                    <ErrorMessage component={CustomError} name="tckn_vkn" />
                                                </FormItem>
                                            )}
                                        </Col>
                                    </CustomRow>
                                    <CustomRow>
                                        <Col span={11}>
                                            <FormItem label={<Title text="Adres İsmi" />}>
                                                <CustomInput placeholder="Adres İsmi" name="name" value={values.name} onChange={handleChange} />
                                                <ErrorMessage component={CustomError} name="name" />
                                            </FormItem>
                                        </Col>
                                    </CustomRow>
                                    <CustomRow>
                                        <Col span={11}>
                                            <FormItem label={<Title text="Şehir" />}>
                                                <CustomSelect
                                                    name="city"
                                                    value={values.city}
                                                    onChange={(value) => setFieldValue('city', value)}
                                                    suffixIcon={<DropdownArrow />}>
                                                    <CustomOption className="here" value="istanbul">
                                                        <Text size="14px" weight="400">
                                                            İstanbul
                                                        </Text>
                                                    </CustomOption>
                                                    <CustomOption value="ankara">
                                                        <Text size="14px" weight="400">
                                                            Ankara
                                                        </Text>
                                                    </CustomOption>
                                                    <CustomOption value="izmir">
                                                        <Text size="14px" weight="400">
                                                            İzmir
                                                        </Text>
                                                    </CustomOption>
                                                </CustomSelect>
                                            </FormItem>
                                        </Col>
                                        <Col span={2} />
                                        <Col span={11}>
                                            <FormItem label={<Title text="İlçe" />}>
                                                <IlceMenu
                                                    setFieldValue={setFieldValue}
                                                    city={values.city}
                                                    handleAddressClick={handleAddressClick}
                                                    handleAddressChange={handleAddressChange}
                                                    visible2={visible2}
                                                    province={values.province}
                                                />
                                            </FormItem>
                                        </Col>
                                    </CustomRow>
                                    <CustomRow>
                                        <Col span={24}>
                                            <FormItem label={<Title text="Adres" />}>
                                                <PlacesAutocomplete value={updatedAddress} onChange={setUpdatedAddress} onSelect={handleSelect}>
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <Container className="map" style={{margin: 0, left: 0, top: 0, width: '100%'}}>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Mahalle, cadde, sokak ara',
                                                                    className: 'location-search-input',
                                                                    style: {
                                                                        height: 48,
                                                                        border: '2px solid #f2f2fc',
                                                                        paddingLeft: 15,
                                                                        width: '100%',
                                                                    },
                                                                })}
                                                            />
                                                            {suggestions?.length ? (
                                                                <div
                                                                    className="autocomplete-dropdown-container"
                                                                    style={{
                                                                        padding: '10px 0px',
                                                                        borderRadius: 10,
                                                                        border: '2px solid #f2f2fc',
                                                                        backgroundColor: 'white',
                                                                        zIndex: 11,
                                                                        position: 'relative',
                                                                    }}>
                                                                    {suggestions.map((suggestion) => {
                                                                        const className = suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                        // inline style for demonstration purpose
                                                                        const style = suggestion.active
                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                        return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                    className,
                                                                                    style: {
                                                                                        padding: '2px 12px',
                                                                                        zIndex: 11,
                                                                                        position: 'relative',
                                                                                    },
                                                                                })}>
                                                                                <Text size="16px" weight="light">
                                                                                    {suggestion.description}
                                                                                </Text>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : null}
                                                        </Container>
                                                    )}
                                                </PlacesAutocomplete>
                                                {/* <CustomInput placeholder="Adres" name="address" value={values.address} onChange={handleChange} /> */}
                                                <ErrorMessage component={CustomError} name="address" />
                                            </FormItem>
                                        </Col>
                                    </CustomRow>
                                    <CustomRow>
                                        <Col span={6}>
                                            <FormItem label={<Title text="Bina No" />}>
                                                <CustomInput placeholder="Bina No" name="bina" value={values.bina} onChange={handleChange} />
                                            </FormItem>
                                        </Col>
                                        <Col span={2} />
                                        <Col span={6}>
                                            <FormItem label={<Title text="Daire No" />}>
                                                <CustomInput placeholder="Daire No" name="daire" value={values.daire} onChange={handleChange} />
                                            </FormItem>
                                        </Col>
                                        <Col span={2} />
                                        <Col span={8}>
                                            <FormItem label={<Title text="Telefon No." />}>
                                                <CustomInput
                                                    placeholder="Telefon No."
                                                    name="phone_number"
                                                    value={values.phone_number}
                                                    onChange={(e) => /^\d*$/.test(e.target.value) && handleChange(e)}
                                                    maxLength={10}
                                                />
                                                <ErrorMessage component={CustomError} name="phone_number" />
                                            </FormItem>
                                        </Col>
                                    </CustomRow>
                                    <CustomRow>
                                        <Col span={24}>
                                            <FormItem label={<Title text="Adres Açıklaması" />}>
                                                <CustomInput
                                                    placeholder="Adres Açıklaması"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                />
                                                <ErrorMessage component={CustomError} name="description" />
                                            </FormItem>
                                        </Col>
                                    </CustomRow>
                                </CustomCol>
                                <CustomCol className="right-col" span={8}>
                                    <CustomButton htmlType="submit" type="secondary">
                                        <Text weight="semi-bold" color="white">
                                            Kaydet
                                        </Text>
                                    </CustomButton>
                                </CustomCol>
                            </CustomRow>
                            {values.type !== addressTypes[2] && (
                                <div>
                                    <Checkbox
                                        name="addAsPaymentAddress"
                                        value={values.addAsPaymentAddress}
                                        onChange={handleChange}
                                        onClick={() => setShowTCKN(!showTCKN)}
                                    />{' '}
                                    Fatura adresim olarak da kaydedilsin (TCKN'de girmeniz gerekmektedir.)
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </CustomCol>
        </CustomRow>
    );
};

export default AddressForm;
