import React from 'react';
import { Row } from 'antd';
import { LandingPageStyles } from './styles';
import BannerSection from './BannerSection';
import HowToUseSection from './HowToUseSection';
import InfoSection from './InfoSection';
import FoodWasteSection from './FoodWasteSection';
import BusinessSection from './BusinessSection';

const LandingPage = () => (
    <LandingPageStyles span={24}>
        <Row style={{ backgroundColor: 'white' }}>
            <BannerSection />
        </Row>
        <Row>
            <HowToUseSection />
        </Row>
        <Row>
            <FoodWasteSection />
        </Row>
        <Row>
            <InfoSection />
        </Row>
        <Row>
            <BusinessSection />
        </Row>
    </LandingPageStyles>
);

export default LandingPage;
