import React from 'react';
import { Col } from 'antd';
import { CustomRow, Image } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import AppleBtn from '../../../../../../Assets/Shared/Settings/apple-btn.png';
import AndroidBtn from '../../../../../../Assets/Shared/Settings/android-btn.png';
import Logo from '../../../../../../Assets/Shared/Settings/logo.png';
import { ReactComponent as Facebook } from '../../../../../../Assets/Shared/Settings/facebook.svg';
import { ReactComponent as Twitter } from '../../../../../../Assets/Shared/Settings/twitter.svg';
import { ReactComponent as Instagram } from '../../../../../../Assets/Shared/Settings/instagram.svg';
import { ReactComponent as Youtube } from '../../../../../../Assets/Shared/Settings/youtube.svg';
import { ReactComponent as LinkedIn } from '../../../../../../Assets/Shared/Settings/linkedIn.svg';

const Application = () => (
    <CustomRow className="main-row">
        <CustomRow>
            <Text weight="semi-bold">Döngü</Text>
        </CustomRow>
        <CustomRow className="title-text-row">
            <Text weight="light" size="14px" color="#707070" style={{ lineHeight: 2 }}>
                DÖNGÜ, gıda israfını azaltarak doğal kaynakların korunmasına katkıda bulunmayı amaçlayan bir uygulamadır. Farklı kategorilerde
                birbirinden lezzetli gıda ürünleri, DÖNGÜ’de tüketici ile buluşur. DÖNGÜ’nün bütçe dostu fiyatlarıyla, her sipariş doğa için
                atılmışbir adıma dönüşür.
            </Text>
        </CustomRow>
        <CustomRow className="margin-40">
            <Text weight="semi-bold">Mobil Uygulama</Text>
        </CustomRow>
        <CustomRow className="buttons-row" align="middle">
            <Col>
                <a href="https://apps.apple.com/tr/app/d%C3%B6ng%C3%BC/id1581616839" target="_blank" rel="noreferrer">
                    <Image src={AppleBtn} alt="he" width="160px" />
                </a>
            </Col>
            <Col>
                <a href="https://play.google.com/store/apps/details?id=com.westerops.dongu_mobile" target="_blank" rel="noreferrer">
                    <Image className="android" src={AndroidBtn} alt="he" width="160px" />
                </a>
            </Col>
            <Col>
                <a href="https://onyuz.dongu.com/" target="_blank" rel="noreferrer">
                    <Image className="logo" src={Logo} alt="he" width="96px" />
                </a>
            </Col>
            <Col>
                <Text weight="light" size="11px" color="#707070">
                    Sürüm: 1.0.1
                </Text>
            </Col>
        </CustomRow>
        <CustomRow className="margin-40">
            <Text weight="semi-bold">Sosyal Medya Hesapları</Text>
        </CustomRow>
        <CustomRow className="buttons-row buttons-row--social">
            <Col span={2}>
                <a href="https://www.facebook.com/dongu.tr" target="_blank" rel="noreferrer">
                    <Facebook />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://twitter.com/dongutr" target="_blank" rel="noreferrer">
                    <Twitter />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://www.instagram.com/dongu.tr/" target="_blank" rel="noreferrer">
                    <Instagram />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
                    <Youtube />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://www.linkedin.com/company/dongutr/" target="_blank" rel="noreferrer">
                    <LinkedIn />
                </a>
            </Col>
        </CustomRow>
    </CustomRow>
);

export default Application;
