/* eslint-disable no-unused-vars */
import { Col, Row } from 'antd';
import React from 'react';
import { CheckboxStyle } from './styles';

const FormIcon = ({ icon, text, isCheked = false }) => (
    <Col style={{ alignItems: 'center', display: 'row', alignContent: 'center', marginLeft: '10px', marginBottom: '32px', marginRight: 21 }}>
        <Row>
            <img
                style={{
                    width: '62px',
                    height: '62px',
                }}
                src={icon}
                alt="img"
            />
        </Row>
        <h1 style={{ fontSize: 11, textAlign: 'center', marginTop: 6 }}>{text}</h1>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CheckboxStyle style={{ borderRadius: 5 }} value={text} />
        </Row>
    </Col>
);

export default FormIcon;
