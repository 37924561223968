import styled from 'styled-components';
import { Row, Col } from 'antd';

export default styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    border-top: 1px solid ${({ theme }) => theme.borderColor};
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;

export const Content = styled(Row)``;

export const Item = styled(Col)`
    display: flex;
    align-items: center;
`;
