// GET

export const GET_FAVOURITE_MEALS = 'GET_FAVOURITE_MEALS';
export const GET_FAVOURITE_MEALS_SUCCESS = 'GET_FAVOURITE_MEALS_SUCCESS';
export const GET_FAVOURITE_MEALS_FAIL = 'GET_FAVOURITE_MEALS_FAIL';

// DELETE

export const DELETE_FAVOURITE_MEALS = 'GET_FAVOURITE_MEALS';
export const DELETE_FAVOURITE_MEALS_SUCCESS = 'GET_FAVOURITE_MEALS_SUCCESS';
export const DELETE_FAVOURITE_MEALS_FAIL = 'GET_FAVOURITE_MEALS_FAIL';
