import { Row, Col } from 'antd';
import styled from 'styled-components';

export const MenuSettingsPageStyles = styled(Row)`
    .responsive-margin {
        margin-top: 25px;
        margin-left: 0px; //between*/
    }
`;

export const LeftSide = styled(Col)``;
export const RightSide = styled(Col)`
    margin-left: 24px;
    @media (max-width: 1200px) {
        margin-top: 25px;
        margin-left: 0px;
    }
`;
