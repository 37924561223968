import { notification } from 'antd';
import * as actions from './actions';

export const createStore = ({ name, email, phoneNumber }) => (dispatch, getState, service) => {
    dispatch(actions.createStoreStartAction());
    return service({
        method: 'POST',
        path: 'store/store_candidate/',
        body: {
            name,
            email,
            phone_number: +phoneNumber,
        },
    })
        .then((userData) => {
            dispatch(actions.createStoreSuccessAction(userData));
            setTimeout(() => {
                dispatch(actions.createStoreFailAction());
            }, 1000);
        })
        .catch((error) => {
            if (error?.name)
                return notification.error({
                    message: 'Hata oluştu.',
                    description: 'Daha önce bu işletme adı ile bir başvuru yapılmıştır.',
                });
            if (error?.email)
                return notification.error({
                    message: 'Hata oluştu.',
                    description: 'Daha önce bu email ile bir başvuru yapılmıştır.',
                });

            dispatch(actions.createStoreFailAction());
            const err = error;
            return Promise.reject(err);
        });
};
