import styled from 'styled-components';
import { Col, Row } from 'antd';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const ServicesCostStyles = styled(Row)`
    padding-right: ${HORIZONTAL_PADDING};
    padding-left: ${HORIZONTAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    padding-top: ${VERTICAL_PADDING};
`;

export const ServicesCostWrapper = styled(Col)``;

export const ServicesCostTitle = styled.div``;

export const ServicesImage = styled.img``;

export const ImageWrapper = styled(Col)`
    display: flex;
`;
