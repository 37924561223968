import styled from 'styled-components';
import { Row, Col } from 'antd';

export default styled.div``;

export const Content = styled(Row)`
    display: flex;
`;

export const Item = styled(Col)`
    display: flex;
    flex-direction: column;
`;

export const ContentMain = styled(Row)`
    height: 100%;
    display: flex;
    flex-direction: Row;
`;

export const RightSide = styled(Col)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 32px;

    @media screen and (max-width: 1200px) {
        margin-left: 0px;
    }
`;

export const BottomCard = styled(Row)`
    @media screen and (max-width: 1200px) {
        margin-top: 32px;
    }
`;
