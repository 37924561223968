/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Input } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchIcon from '../../../../../../Assets/Shared/FindRestaurant/searchIcon.svg';
import CartIcon from '../../../../../../Assets/Shared/FindRestaurant/yellowbasket.svg';
import { EnWrapper, Wrapper } from './styles';
import { CustomButton } from '../../../../../components/Layout/Header/styles';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery';
import { searchStore } from '../../../../../../services/Redux/Shared/store/api';

export default function RightSection(props) {
    const dispatch = useDispatch();
    const { keyword, setKeyword, searchQuery, query, setSearchQuery, toggleRightSectionDrawer } = props;
    const { xl } = useResponsive();

    const handleClick = () => {
        if (keyword?.length > 2 || !keyword) {
            dispatch(searchStore(query));
        }
    };

    useEffect(() => {
        setSearchQuery(searchQuery + 1);
    }, [keyword]);

    return (
        <EnWrapper>
            <Wrapper style={{ width: '100%' }}>
                <Input
                    bordered={false}
                    onChange={(e) => setKeyword(e.target.value)}
                    value={keyword}
                    placeholder="Yemek, restoran ara...(En az üç karakter giriniz)"
                    style={{ flex: 1 }}
                    onPressEnter={() => setSearchQuery(searchQuery + 1)}
                />
                <img src={SearchIcon} alt="img" onClick={handleClick} onKeyPress={handleClick} style={{ cursor: 'pointer' }} />
            </Wrapper>
            {!xl && (
                <CustomButton onClick={toggleRightSectionDrawer}>
                    <img src={CartIcon} height={48} width={48} alt="" />
                </CustomButton>
            )}
        </EnWrapper>
    );
}
