import styled from 'styled-components';
import { Tabs } from 'antd';

export const CustomTabs = styled(Tabs)`
    min-width: 566px;
    max-width: 100%;

    .ant-tabs-nav {
        background: #f5f9fc;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding-bottom: 13px;
    }

    .ant-tabs-tab-btn {
        color: black;
        font-size: 1.125rem;
    }

    & > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
        justify-content: space-around;
        width: 100%;
    }

    & > .ant-tabs,
    .ant-tabs-top,
    .ant-tabs-mobile {
        @media (max-width: 600px) {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }

    @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    & > .ant-row,
    .ant-row-middle {
        @media (max-width: 600px) {
            margin: 0;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
`;
