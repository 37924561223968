import { Col, Row, Divider } from 'antd';
import styled from 'styled-components';
import { between } from 'polished';
import { VERTICAL_PADDING } from '../../../../helpers/constants';
import Button from '../../../components/Button/index';

export const OutSide = styled(Row)`
    background-color: #f5f9fc;
    padding-bottom: 3%;
    padding-left: calc(-4px + 10.63vw);
    padding-right: calc(-4px + 10.63vw);
    padding-top: 150px; /* 
    padding-top: ${VERTICAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    padding-right: ${between('30px', '200px', '320px', '1920px')};
    padding-left: ${between('30px', '200px', '320px', '1920px')}; */
    @media (max-width: 1024px) {
        padding: 14% 3%;
        padding-top: 150px;
    }
`;

export const LeftContainer = styled(Col)`
    background-color: white;
    border-radius: 18px;
    width: 100%;
    min-height: auto;
    height: auto;
    padding-bottom: 40px;
    filter: drop-shadow(0px 0px 10px #cfcfda);
`;

export const RightContainer = styled(Col)`
    width: 334px;
    height: 390px;
    border-radius: 18px;
`;

export const TitleRow = styled(Row)`
    width: 100%;
    padding-top: 30px;
`;

export const CustomDivider = styled(Divider)`
    width: 100% !important;
    border-top: 4px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 0px;

    &.user-divider {
        margin-left: 20px;
        margin-top: 5px;
    }
`;

export const CustomRow = styled(Row)`
    width: 100%;
    &.main-row {
        margin-top: 20px;
        padding-right: 23px;
        margin-bottom: 30px;
    }
    &.user-row {
        margin-left: 30px;
    }
`;

export const CustomButtom = styled(Button)`
    width: 372px;
    height: 48px;
`;
