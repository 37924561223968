import React from 'react';
import { Col } from 'antd';
import { Text } from '../../../../../../components/Typography/styles';
import { FooterWrapper, CustomButton } from './styles';

const Footer = ({ isMinPrice, foodPrices, minPacketPrice, minOrderPrice, defined, sold, handleUpdate }) => (
    <FooterWrapper align="middle" justify="space-between" style={isMinPrice && { backgroundColor: '#F7EBE9' }}>
        <Col span={4}>
            <Text color={isMinPrice ? 'red' : 'yellow'} size="noble-regular" family="secondary" weight="bold">
                {foodPrices !== 0 ? `${foodPrices} TL` : `${minPacketPrice} TL`}
            </Text>
        </Col>
        <Col span={10}>
            {isMinPrice && (
                <Text size="smallRegular" color={isMinPrice ? 'black' : 'smoke'} fontStyle="italic">
                    Paket değerine ulaşmak için lütfen ekleme yapınız.
                </Text>
            )}
        </Col>
        <Col span={8}>
            {defined ? (
                <Text size="regular" color="green">
                    {sold ? 'Satıldı' : 'Satışta'}
                </Text>
            ) : (
                <CustomButton type="secondary" disabled={foodPrices <= minOrderPrice} onClick={() => handleUpdate()}>
                    <Text color="white" size="regular">
                        Onay
                    </Text>
                </CustomButton>
            )}
        </Col>
    </FooterWrapper>
);

export default Footer;
