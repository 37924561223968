import React from 'react';
import CardStyles, { Content, Item } from './styles';
import { Text } from '../../../../components/Typography/styles';
import informationIcon from '../../../../../Assets/Dashboard/information.png';

export const Card = () => (
    <CardStyles type="alert">
        <Content>
            <Item md={2}>
                <img src={informationIcon} alt="information-icon" />
            </Item>
            <Item md={{ span: 20 }} xl={22} style={{ marginBottom: '20px' }}>
                <Text weight="semi-bold">
                    Burada, haftanın her günü kaç tane paket satın alınabileceğini belirtebilirsiniz. Müşterilerin mağazanıza gelip çantalarını
                    alabilecekleri toplama süresini de değiştirebilirsiniz.
                </Text>
            </Item>
            <Item md={{ span: 20, offset: 2 }}>
                <Text weight="semi-bold">
                    Beklenenden daha fazla veya daha az yiyeceğin olduğunu fark ettiğin zaman gün içinde sunulan paket sayısını ayarlamak istiyorsan,
                    bunu kontrol panelinden anlık olarak yapabilirsin.
                </Text>
            </Item>
        </Content>
    </CardStyles>
);

export default Card;
