import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import alertIcon from '../../../../../Assets/Dashboard/alert.png';
import { Wrapper, Content, CardStyles, Text, Title } from './styles';
import Spinner from '../../../../components/Spinner';

const Alert = () => {
    const format = 'YYYY-MM-DD';
    const { monthlyOrders } = useSelector((state) => state.calendarPage);
    const [startDate, setStartDate] = useState('');

    useEffect(() => {
        if (!monthlyOrders) {
            setStartDate('');
        } else {
            const date = monthlyOrders.find(
                (orders) => moment(orders.start_date).isSameOrAfter(moment().format(format), 'date') && orders.is_active === true
            );
            setStartDate(moment(date.start_date.slice(0, 10)).format('D MMMM YYYY'));
        }
        return () => {
            setStartDate('');
        };
    }, [monthlyOrders]);

    return !monthlyOrders ? (
        <Spinner />
    ) : (
        <CardStyles type="alert">
            <Wrapper justify="start">
                <Col sm={2} md={2} xl={2} xxl={2}>
                    <img src={alertIcon} alt="alert-icon" />
                </Col>
                <Content sm={22} md={22} lg={22} xl={{ span: 18, offset: 4 }} xxl={{ span: 18, offset: 4 }}>
                    <Row>
                        <Text weight="semi-bold">Bu tarihten sonra,</Text>
                        <Text weight="semi-bold">takvime göre satın alım</Text>
                        <Text weight="semi-bold">gerçekleşecektir.</Text>
                    </Row>
                    <Row>
                        <Title size="title" weight="light">
                            {startDate}
                        </Title>
                    </Row>
                </Content>
            </Wrapper>
        </CardStyles>
    );
};

export default Alert;
