import styled from 'styled-components';
import { Row, Col } from 'antd';
import Button from '../../../../components/Button/index';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const RecycleStyles = styled(Row)`
    width: 100%;
    padding-left: ${VERTICAL_PADDING};
    padding-right: ${VERTICAL_PADDING};
    padding-top: ${HORIZONTAL_PADDING};
    padding-bottom: ${HORIZONTAL_PADDING};

    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: 100%;
    background-repeat: no-repeat;
`;

export const RecycleContentWrapper = styled(Row)`
    flex: 1;
    display: flex;
`;

export const RecycleTitle = styled.div`
    margin-top: 50px;
    margin-bottom: 30px;
`;

export const RecycleContent = styled.div`
    margin: 0;
    padding: 0;
`;

export const JoinUsButton = styled(Button)`
    height: 48px;
    margin-top: 60px;
`;

export const RecycleImage = styled.img`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    margin-top: 10%;
    width: 70%;
`;

export const Empty = styled(Col)`
    height: 500px;
    width: 500px;
`;
