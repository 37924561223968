import styled from 'styled-components';
import { Modal } from 'antd';

export default styled(Modal)`
    .ant-modal-header {
        text-align: center;
        border-radius: 30px 30px 0px 0px;
        border-bottom: ${({ theme }) => `3px solid ${theme.borderColor}`};

        .ant-modal-title {
            font-size: 22px;
            font-weight: 300;
        }
    }
    .ant-modal-content {
        border-radius: 30px;
    }
    .ant-modal-body {
        padding: 0px;
        text-align: center;
    }
    .ant-modal-footer {
        border-top: none;
    }
`;
