import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { CustomModal, CustomButton, CustomRow } from './styles';
import { Text } from '../../../../../../../components/Typography/styles.js';
import FloatingLabel from '../../../../../../../components/TextInput/textInput';
import { postPasswordReset } from '../../../../../../../../services/Redux/Shared/profile/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text color="#707070" size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const ForgotPasswordModal = (props) => {
    const { onCancel } = props;
    const dispatch = useDispatch();
    const [userEmail, setUserEmail] = useState('');

    const sendResetLink = () => {
        const data = {
            email: userEmail,
        };
        dispatch(postPasswordReset(data));
    };

    return (
        <>
            <CustomModal title={<Title text="Şifremi Unuttum" />} {...props}>
                <Row gutter={[0, 24]} justify="center">
                    <Col span={22} style={{ textAlign: 'center' }}>
                        <Text weight="light" color="#707070">
                            Lütfen e-posta adresinizi giriniz, size şifrenizi sıfırlamanız için bir bağlantı linki gönderelim.
                        </Text>
                    </Col>
                </Row>

                <CustomRow>
                    <FloatingLabel
                        id="number"
                        name="number"
                        placeholder="E-Mail Adresi"
                        type="text"
                        autofill={false}
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                    />
                </CustomRow>

                <CustomRow justify="center">
                    <CustomButton
                        type="tertiary"
                        onClick={() => {
                            sendResetLink();
                            onCancel();
                        }}>
                        <Text weight="semi-bold" color="white">
                            Bağlantı Linkini Gönder
                        </Text>
                    </CustomButton>
                </CustomRow>
            </CustomModal>
        </>
    );
};

export default ForgotPasswordModal;
