import * as actionTypes from './actionTypes';

const initialState = {
    store: null,
    loading: false,
};

const createStoreStartReducer = (state) => ({
    ...state,
    loading: true,
});

const createStoreFailReducer = (state) => ({
    ...state,
    loading: false,
    store: null,
});

const createStoreSuccessReducer = (state, action) => ({
    ...state,
    store: action.payload,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_STORE_START:
            return createStoreStartReducer(state);
        case actionTypes.CREATE_STORE_SUCCESS:
            return createStoreSuccessReducer(state, action);
        case actionTypes.CREATE_STORE_FAIL:
            return createStoreFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
