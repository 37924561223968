import React, { useEffect, useState } from 'react';
import { Col, Spin, Radio, message } from 'antd';
import { CustomModal, PacketWrapper, Description, Wrapper, Footer, CustomButton, TextContainer } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import xmlDelete from '../../../../../../services/Api/request';
import '../../../../../components/Swal/style.css';
import AlertModal from './AlertModal';
import soldIcon from '../../../../../../Assets/Dashboard/sell-icon.png';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);

const PacketCancelModal = ({ boxList, sold, onCancel, setIsPacketModalVisible, visible }) => {
    const [boxListToRender, setBoxListToRender] = useState(boxList);
    const [selectedPacket, setSelectedPacket] = useState(null);
    const [isAlertModal, setIsAlertModal] = useState(false);
    useEffect(() => {
        setBoxListToRender(boxList);
    }, [boxList]);

    const cancelPacket = (id) => {
        xmlDelete({
            path: `box/${id}/`,
            method: 'DELETE',
            sendToken: true,
        })
            .then(() => {
                message.success('Paket Başarı ile Silindi!');
                setSelectedPacket(null);
            })
            .catch((err) => message.error('Bir hata oluştu'));

        setBoxListToRender(boxListToRender.filter((val) => val.id !== id));

        setIsAlertModal(false);
    };

    const handleAlertCancel = () => {
        setIsAlertModal(false);
    };

    return (
        <CustomModal
            onCancel={() => {
                onCancel();
                setSelectedPacket(null);
            }}
            visible={visible}
            title={<Title text="Paket İptal Onayı" />}>
            <Col span={24}>
                <Description align="middle" justify="center">
                    <Text family="primary">Lütfen silmek istediğiniz paketi seçiniz.</Text>
                    <Text size="smallRegular" color="smoke" fontStyle="italic">
                        Aktif siparişlerinizi, alt kısımda bulunan "Siparişler" bölümünden silebilirsiniz.
                    </Text>
                </Description>
                <Wrapper>
                    <Radio.Group style={{ width: '100%' }}>
                        {boxListToRender ? (
                            boxListToRender.map((val) => (
                                <PacketWrapper align="middle" justify="space-between">
                                    <TextContainer>
                                        {/* eslint-disable-next-line no-constant-condition */}
                                        {sold ? (
                                            <>
                                                <div display="flex">
                                                    <Text family="primary" weight="medium" size="description">
                                                        {`${val.text_name}`}
                                                    </Text>
                                                    <Text size="smallRegular" color="smoke" fontStyle="italic" style={{ marginLeft: '20px' }}>
                                                        {`${val.id}`}
                                                    </Text>
                                                </div>
                                                <div>
                                                    {Array.isArray(val?.meals) &&
                                                        val?.meals?.map((meal) => (
                                                            <Text size="smallRegular" color="smoke" fontStyle="italic" style={{ marginLeft: '20px' }}>
                                                                {`${meal?.name}`}
                                                            </Text>
                                                        ))}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div display="flex">
                                                    <Text family="primary" weight="medium" size="description">
                                                        {val.text_name}
                                                    </Text>
                                                    <Text size="smallRegular" color="smoke" fontStyle="italic" style={{ marginLeft: '20px' }}>
                                                        {`${val.id}`}
                                                    </Text>
                                                </div>
                                                <div style={{ marginTop: '10px' }}>
                                                    {Array.isArray(val?.meals) &&
                                                        // eslint-disable-next-line sonarjs/no-identical-functions
                                                        val?.meals?.map((meal) => (
                                                            <Text
                                                                size="mediumRegular"
                                                                color="smoke"
                                                                fontStyle="italic"
                                                                style={{ marginRight: '20px' }}>
                                                                {`${meal?.name}`}
                                                            </Text>
                                                        ))}
                                                </div>
                                            </>
                                        )}
                                        <Text family="primary" size="description">
                                            {val.price}
                                        </Text>
                                    </TextContainer>
                                    {val?.sold ? (
                                        <Text>
                                            <img style={{ width: 24, height: 24 }} src={soldIcon} alt="sold" />
                                        </Text>
                                    ) : (
                                        <Radio value={val} onClick={() => setSelectedPacket(val)} />
                                    )}
                                </PacketWrapper>
                            ))
                        ) : (
                            <Spin />
                        )}
                    </Radio.Group>
                </Wrapper>
                <Footer
                    gutter={[
                        { xs: 8, md: 8, xl: 16 },
                        { xs: 8, sm: 8, md: 16, lg: 16, xl: 16 },
                    ]}
                    align="middle"
                    justify="space-between">
                    <Col span={12}>
                        <CustomButton
                            type="quaternary"
                            onClick={() => {
                                setSelectedPacket(null);
                                setIsPacketModalVisible(false);
                            }}>
                            <Text weight="semi-bold">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col span={12}>
                        <CustomButton
                            type="primary"
                            onClick={() => setIsAlertModal(true)}
                            disabled={!selectedPacket}
                            style={{ opacity: selectedPacket ? 1 : 0.75 }}>
                            <Text color="white" weight="semi-bold">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Footer>
            </Col>
            <AlertModal onCancel={handleAlertCancel} visible={isAlertModal} selectedPacket={selectedPacket} cancelPacket={cancelPacket} />
        </CustomModal>
    );
};

export default PacketCancelModal;
