import styled from 'styled-components';
import { Row } from 'antd';
import { HORIZONTAL_PADDING } from '../../../../../../helpers/constants';

export const CookiePolicyStyle = styled(Row)`
    margin-top: 8%;
    width: 100%;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-size: 80%;
`;

export const CookieWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CookieTitle = styled.div``;
