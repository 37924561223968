import * as actionTypes from './actionTypes';

export const getRestaurantInfoStartAction = () => ({
    type: actionTypes.GET_RESTAURANT_INFO,
});

export const getRestaurantInfoSuccessAction = (favourites) => ({
    type: actionTypes.GET_RESTAURANT_INFO_SUCCESS,
    payload: favourites,
});

export const getRestaurantInfoFailAction = () => ({
    type: actionTypes.GET_RESTAURANT_INFO_FAIL,
});
