/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import CardStyles, { CardTitle, Content, Item, Text, Input, Divider, EditButton, SendButton, Form } from './styles';
import Image from '../../../../../../Assets/Dashboard/edit-button.png';
import { getPaymentInfo, postPaymentInfo, patchPaymentInfo } from '../../../../../../services/Redux/Dashboard/payment/api';

const SalesAndBillCard = ({ info }) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [selected, setSelected] = useState(info && info.id_number ? 2 : 1);
    const { sendingRequestLoading } = useSelector((state) => state.paymentStore);

    const send = (values) => {
        const storeInfo = { ...values, payment_term: 'Monthly' };
        storeInfo.iban = values.iban.split(' ').join('');
        if (info) {
            if (selected === 1) storeInfo.tax_number = info?.tax_number;
            if (selected === 2) storeInfo.id_number = info?.id_number;
            dispatch(patchPaymentInfo(storeInfo));
        } else if (selected === 1) {
            storeInfo.subMerchantType = 'LIMITED_OR_JOINT_STOCK_COMPANY';
            storeInfo.id_number = '';
            dispatch(postPaymentInfo(storeInfo));
        } else if (selected === 2) {
            storeInfo.subMerchantType = 'PRIVATE_COMPANY';
            storeInfo.tax_number = '';
            dispatch(postPaymentInfo(storeInfo));
        }
        dispatch(getPaymentInfo());
    };

    const onFinish = (values) => {
        send(values);
        setDisabled(true);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form disabled={disabled} onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={info}>
            <CardStyles title={<CardTitle>Satış ve Fatura</CardTitle>}>
                <Content>
                    <Item span={24} style={{ marginBottom: '29px' }}>
                        <Text weight="semi-bold">Mağaza Fatura Bilgileri</Text>
                    </Item>
                    <Content>
                        <Item md={11} lg={12}>
                            <Text weight="semi-bold">Vergi Dairesi İli</Text>
                            <Form.Item name="tax_office_city" rules={[{ required: true, message: 'Şehir boş geçilemez.' }]}>
                                <Input placeholder="Vergi Dairesi İli" disabled={disabled} />
                            </Form.Item>
                        </Item>
                        <Item md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 1 }}>
                            <Text weight="semi-bold">Vergi Dairesi</Text>
                            <Form.Item name="tax_office" rules={[{ required: true, message: 'Vergi Dairesi boş geçilemez.' }]}>
                                <Input placeholder="Vergi Dairesi" disabled={disabled} />
                            </Form.Item>
                        </Item>
                    </Content>
                    <Content style={{ marginTop: '32px' }}>
                        <Item md={11} lg={12}>
                            <Text weight="semi-bold" style={{ marginBottom: '10px' }}>
                                Şirket türü
                            </Text>
                            <Radio.Group onChange={(e) => setSelected(e.target.value)} value={selected}>
                                <Radio value={1} name="Limited Şirketi" disabled={info && info.subMerchantType}>
                                    Limited Şirketi
                                </Radio>
                                <Radio value={2} name="şahıs şirketi" disabled={info && info.subMerchantType}>
                                    Şahıs şirketi
                                </Radio>
                            </Radio.Group>
                        </Item>
                        <Item md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 1 }}>
                            <Text weight="semi-bold">{selected === 1 ? 'Vergi Numarası' : 'T.C Numarası'}</Text>
                            {selected === 1 ? (
                                <Form.Item
                                    name="tax_number"
                                    rules={[
                                        {
                                            required: selected === 1,
                                            message: 'Vergi numarası 10 karakterden ve yalnızca rakamlardan oluşmalıdır.',
                                            pattern: /^[0-9]{10}$/,
                                        },
                                    ]}>
                                    <Input placeholder="Vergi Numarası" maxLength={10} disabled={info && info.tax_number ? true : disabled} />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    name="id_number"
                                    rules={[
                                        { required: selected === 2, message: 'T.C. Kimlik Numaranızı giriniz...' },
                                        {
                                            pattern: /^[1-9]{1}[0-9]{9}[02468]{1}$/,
                                            message: 'T.C. Numarası 11 haneli ve son hanesi çift sayı olmalıdır.',
                                        },
                                    ]}>
                                    <Input placeholder="T.C. Numarası" maxLength={11} disabled={info && info.id_number ? true : disabled} />
                                </Form.Item>
                            )}
                        </Item>
                    </Content>
                    <Divider />
                    <Item span={24} style={{ marginBottom: '29px' }}>
                        <Text weight="semi-bold">Banka Bilgileri</Text>
                    </Item>
                    <Content>
                        <Item md={11} lg={12}>
                            <Text weight="semi-bold">Ödeme Alacağınız Banka</Text>
                            <Form.Item name="bank" rules={[{ required: true, message: 'Ödeme alacağınız bankayı giriniz...' }]}>
                                <Input placeholder="Ödeme Alacağınız Banka" disabled={disabled} />
                            </Form.Item>
                        </Item>
                        <Item md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 1 }}>
                            <Text weight="semi-bold">Alıcı Adı Soyadı / Ünvanı</Text>
                            <Form.Item name="receiver" rules={[{ required: true, message: 'Alıcı isim ve soyisim bilgilerini giriniz...' }]}>
                                <Input placeholder="Alıcı Adı Soyadı / Ünvanı" disabled={disabled} />
                            </Form.Item>
                        </Item>
                    </Content>
                    <Content style={{ marginTop: '32px' }}>
                        <Item md={11} lg={12}>
                            <Text weight="semi-bold">IBAN Numarası</Text>
                            <Form.Item
                                name="iban"
                                getValueFromEvent={(e) =>
                                    e.target.value
                                        .replace(/[^\dA-Z]/gi, '')
                                        .toUpperCase()
                                        .replace(/(.{4})/g, '$1 ')
                                        .trim()
                                }
                                rules={[
                                    { required: true, message: 'IBAN Numaranızı giriniz...' },
                                    {
                                        pattern: /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){5}(?:[ ]?[0-9]{1,2})?$/,
                                        message: 'IBAN numarası hatalı',
                                    },
                                ]}>
                                <Input placeholder="IBAN Numarası" disabled={disabled} maxLength={32} />
                            </Form.Item>
                        </Item>
                        <Item md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 1 }}>
                            <Text weight="semi-bold">Telefon Numarası</Text>

                            <Form.Item
                                name="phone_number"
                                rules={[
                                    { required: true, message: 'Telefon Numaranızı başında 0 olmadan giriniz...' },
                                    {
                                        pattern: /^(?:\d*)$/,
                                        message: 'Telefon numarası sadece sayılardan oluşmalıdır.',
                                    },
                                ]}>
                                <Input maxLength={10} placeholder="Telefon Numarası" disabled={disabled} />
                            </Form.Item>
                        </Item>
                    </Content>
                    <Content>
                        <Item md={{ span: 5, offset: 10 }} xl={{ span: 6, offset: 8 }} xxl={{ span: 6, offset: 8 }}>
                            <Form.Item>
                                <EditButton onClick={() => setDisabled(!disabled)}>
                                    <img src={Image} alt="" />
                                    <Text size="description" weight="bold">
                                        Düzenle
                                    </Text>
                                </EditButton>
                            </Form.Item>
                        </Item>
                        <Item md={{ span: 8, offset: 1 }} xl={{ span: 9, offset: 1 }} xxl={{ span: 9, offset: 1 }}>
                            <Form.Item>
                                <SendButton loading={sendingRequestLoading} type="secondary" htmlType="submit">
                                    <Text size="description" color="white" weight="semi-bold">
                                        Onaya Gönder
                                    </Text>
                                </SendButton>
                            </Form.Item>
                        </Item>
                    </Content>
                </Content>
            </CardStyles>
        </Form>
    );
};

export default SalesAndBillCard;
