import styled from 'styled-components';
import { Row } from 'antd';
import background from '../../../../../../../Assets/Shared/CartPage/background.svg';
import Button from '../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;
    &.main-row {
        background: url(${background});
    }
    &.heading-row {
        height: 350px;
        margin-top: 100px;
    }
    &.haydi-row {
        height: 50px;
        margin-top: 60px;
    }
    &.button-row {
        height: 50px;
    }
`;

export const CustomButtom = styled(Button)`
    width: 372px;
    height: 48px;
`;
