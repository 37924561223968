/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Col } from 'antd';
import { CustomRow, CustomButton } from './styles';
import { CustomCheckbox } from '../../../CartPage/Checkout/TabsPane/PaymentPane/LastRow/styles';
import { Paragraph, Text } from '../../../../../components/Typography/styles';

const UserAgreement = () => {
    const [confirmed, setConfirmed] = useState(localStorage.getItem('confirmed'));
    const [selected, setSelected] = useState(false);

    const handleConfirm = () => {
        setConfirmed(true);
        localStorage.setItem('confirmed', !confirmed);
    };

    return (
        <>
            <CustomRow className="main-row">
                <CustomRow className="content-row">
                    <CustomRow>
                        <Text weight="bold" size="title" style={{ textAlign: 'center', width: '100%', paddingTop: '5%' }}>
                            KULLANICI SÖZLEŞMESİ
                        </Text>
                    </CustomRow>
                    <Paragraph color="#000" weight="light" size="mediumRegular" style={{ paddingTop: '2%' }}>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                1. Taraflar
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                1.1. Döngü, www.dongu.com ve mobil uygulama aracılığıyla (“Uygulama”), “Westerops Bilişim Ve Yazilim Hizmetleri
                                Limited Şirketi” (“Şirket”) tarafından sunulmaktadır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                1.2.İşbu Kullanıcı Sözleşmesi (“Sözleşme”) kapsamında Uygulama’dan faydalanacak olan kullanıcı (“Kullanıcı”), işbu
                                Sözleşme’nin kendisi tarafından onaylanması ile birlikte yürürlüğe gireceğini ve bu Sözleşme’de yer alan düzenlemelere
                                uygun davranmakla yükümlü olduğunu bilmektedir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                1.3.Sözleşme kapsamında Şirket ve Kullanıcı ayrı ayrı “Taraf” birlikte ise “Taraflar” olarak anılacaklardır.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                2. Sözleşme’nin Konusu
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Döngü, Şirket tarafından yönetilen ve Şirket ile Üye İşyeri Sözleşmesi’ni akdetmiş olan üye işyerlerine (“Üye İşyeri”)
                                gün içerisinde arz fazlalığından dolayı satamadıkları yiyecekleri, Kullanıcılar’a gıda kodeksine tam uygun olarak
                                tazeliklerini ve lezzetlerini kaybetmelerine müsade etmeden sunduğu, kullanıcıların da sevdikleri bu yiyeceklere
                                indirimli fiyatlarla ulaşabileceği satışa konu yiyecek ve içecekleri (“Ürünler”) sunma imkanı veren bir satış
                                platformudur. İşbu Sözleşme’nin konusu; Kullanıcılar’ın Uygulama’dan ve Uygulama üzerinden verilen hizmetlerden
                                faydalanmasına ilişkin hüküm ve koşulların belirlenmesi ve bu doğrultuda Taraflar’ın hak ve yükümlülüklerinin
                                düzenlenmesidir.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                3. Tarafların Hak ve Yükümlülükleri
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.1. Kullanıcı, Platform’dan faydalanmak için Şirket tarafından talep edilen bilgileri tam, doğru ve güncel bir
                                şekilde sağlayarak işbu Sözleşme’yi onaylaması gerektiğini bildiğini beyan eder. Kullanıcı statüsünün tesisi sırasında
                                sağlanan bilgilerde herhangi bir değişiklik olması halinde, söz konusu bilgiler derhal güncellenecektir. Bu bilgilerin
                                eksik veya gerçeğe aykırı olarak verilmesi ya da güncel olmaması nedeniyle Platform’a erişim sağlanamamasından ve
                                bunlardan faydalanılamamasından Şirket sorumlu değildir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.2. Kullanıcı tarafından Site’ye erişim, cep telefon numarası, e-posta adresi ve şifresi kullanılarak
                                gerçekleştirilecektir. Bu şifrenin gizliliğinin ve güvenliğinin korunmasından Kullanıcı sorumlu olacak olup, Site
                                üzerinden söz konusu bilgilerin kullanımı ile gerçekleştirilen her türlü faaliyetin Kullanıcı tarafından
                                gerçekleştirildiği kabul edilecek, bu faaliyetlerden doğan her türlü yasal ve cezai sorumluluk Kullanıcı’ya ait
                                olacaktır. Kullanıcı, şifresinin yetkisiz kullanımı veya güvenliğin başka şekilde ihlalinden haberdar olduğunda bu
                                durumu derhal Şirket’e bildirecektir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.3.Kullanıcı, işbu Sözleşme’yi akdetmek için gereken yasal ehliyete sahip bulunduğunu beyan eder. Kullanıcı,
                                oluşturulmuş hesaplarını, kullanıcı adı ve şifresi ile üyelik profillerini hiçbir şart ve koşulda başka bir
                                kullanıcıya devredemez veya üçüncü kişilerce kullanımına izin veremez.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.4.Kullanıcı Uygulama’da gerçekleştireceği tüm işlemlerde işbu Sözleşme ile Uygulama’da zaman zaman yayınlanabilecek
                                koşullar ile kanuna, ahlaka ve adaba, dürüstlük ilkelerine uyacak, herhangi bir yöntem ile Uygulama’nın işleyişini
                                engelleyebilecek davranışlarda, üçüncü kişilerin haklarına tecavüz eden veya etme tehlikesi bulunan fiillerde
                                bulunmayacaktır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.5. Uygulama’da bulunan yazılım, görsel ve tasarımların, yazıların, logoların, grafiklerin her türlü hakkı Şirket’e
                                aittir. Uygulama’nın tasarımında, içeriğinde ve veritabanı oluşturulmasında kullanılan bilgi ve/veya yazılımın
                                kopyalanması ve/veya Uygulama’dan faydalanmanın ötesinde kullanılması, Uygulama dahilinde bulunan her türlü resim,
                                metin, imge, dosya vb. veriler ile içeriklerin kopyalanması, dağıtılması, işlenmesi ve sair şekillerde kullanılması
                                kesinlikle yasaktır. Ayrıca Kullanıcılar’ın (i) Uygulama’nın güvenliğini tehdit edebilecek, Uygulama’ya ait
                                yazılımların çalışmasını veya diğer Kullanıcılar’ın Uygulama’dan faydalanmasını engelleyebilecek herhangi bir
                                girişimde bulunması, (ii) Uygulama’ya bu sonuçları verecek şekilde orantısız yük bindirmesi, Uygulama’da yayımlanmış
                                ve/veya başkaları tarafından girilmiş bilgilere ve İçerikler’e yetkisiz bir şekilde erişmesi, bu bilgi ve içerikleri
                                kopyalaması, silmesi, değiştirmesi ya da bu yönde denemeler yapması; (iii) Uygulama’nın genel güvenliğini tehdit
                                edecek ve/veya Uygulama, Şirket ve diğer Kullanıcılar’a zarar verebilecek eylemlerde bulunması; (iv) Uygulama’nın ve
                                kullanılan yazılımların çalışmasını engelleyecek yazılımları kullanması, kullanmaya çalışması veya her türlü yazılım,
                                donanım ve sunucuların çalışmasını aksatması, bozulmasına yol açması, tersine mühendislik yapması, saldırılar
                                düzenlemesi, meşgul etmesi veya sair surette müdahale etmesi, Şirket sunucularına erişim sağlamaya çalışması
                                kesinlikle yasaktır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.6. Kullanıcı, Uygulama’da yapılacak iyileştirme ve diğer değişikliklerin uygulanması için Uygulama’ya erişimin
                                geçici olarak engellenebileceğini kabul eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.7. Şirket’in herhangi bir sebep göstermeksizin ve herhangi bir ihbarda bulunmaksızın işbu Sözleşme ve eki
                                niteliğindeki koşulları dilediği zamanda tek taraflı olarak değiştirme, bunlara ilavede bulunma veya yenileme ve
                                Uygulama’yı yeniden organize etme, konu, kapsam ve içeriğini değiştirme, yayını durdurma hakkı saklıdır. Şirket
                                tarafından yapılan değişiklikler Uygulama’da yayınlandığı tarihte yürürlüğe girecek olup, Uygulama’nın kullanılması
                                ile Kullanıcı güncel koşulları kabul etmiş addedilir. Söz konusu dokümanların düzenli bir şekilde takip edilmesinden
                                Kullanıcı bizzat sorumlu olacaktır.{' '}
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.8. Uygulama’nın kullanımından ve Uygulama üzerinden ilgili işlemlerin gerçekleştirilmesinden kaynaklanan her türlü
                                yasal, idari ve cezai sorumluluk Kullanıcı’ya aittir. Şirket, Kullanıcı’nın Uygulama üzerinde ve/veya işlemler
                                sırasında gerçekleştirdiği faaliyetler ve/veya işbu Sözleşme ve yasaya aykırı eylemleri neticesinde üçüncü kişilerin
                                uğradıkları veya uğrayabilecekleri zararlardan doğrudan ve/veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.
                                Üçüncü kişilerden bu kapsamda gelecek her türlü talep ile Kullanıcı’nın Sözleşme’de veya ilgili mevzuatta belirtilen
                                yükümlülüklerini yerine getirmemesi nedeniyle Şirket’in uğrayacağı zararlar ilk talepte ferileri ile birlikte ödenmek
                                üzere Kullanıcı’ya rücu edilecektir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                3.9. Kullanıcı, Tütün Mamulleri ve Alkollü İçkilerin Satışına ve Sunumuna İlişkin Usul ve Esaslar Hakkında Yönetmelik
                                ve ilgili mevzuat uyarınca Uygulama üzerinden kesinlikle tütün mamulleri ve alkollü içecek satışı yapılmadığını, tütün
                                mamulleri ve alkollü içecek temin etmemeyi ve Üye İşyeri’ne bu konuda talepte bulunmamayı, aksi takdirde üyeliğinin
                                iptali ve Koşulların feshi ile sonuçlanabileceğini kabul, beyan ve taahhüt eder.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                4. Hizmetlere İlişkin Koşullar
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.1.Uygulama üzerinden sunulan hizmetlerin tarafı münhasıran Kullanıcı ve Üye İşyeri olup Şirket, yalnızca söz konusu
                                hizmetlerin sunulabilmesi için bir platform sunmaktadır. Kullanıcılar, Uygulama’da Üye İşyeri tarafından siparişe konu
                                edilen Ürünler’in ayıplı olup olmaması, içeriği, teslim alınma zamanları, listelenmesi yahut satışa arzı yasaklı
                                ürünlerden olup olmaması, niteliği, Üye İşyeri’nin Ürünler’e ilişkin yaptığı yazılı ve/veya görüntülü açıklamaların
                                doğruluğu ve tamlığı da dahil olmak üzere Ürünler ile ilgili hiçbir konu hakkında Şirket’in bilgi sahibi olmadığı ve
                                olması gerekmediğini ve bunları taahhüt ve garanti etmek yükümlülüğü bulunmadığını, Şirket’in herhangi bir şekilde
                                Sözleşme kapsamında sunulan hizmetlerin tarafı olmadığını kabul eder. Kullanıcı, Uygulama üzerinden sunulan
                                hizmetlerin tarafı olarak gerçekleştirdiği her türlü iş ve işlemin sorumluluğunun kendisine ait olduğunu kabul, beyan
                                ve taahhüt eder. Şirket, Kullanıcı talep ve sorunlarının iletilmesi için Uygulama üzerinden gerekli gördüğü kanalları
                                sağlayacak olup Şirket tarafından sunulan bilgilendirme ve iletişim kaynak ve kanalları sorunların çözümüne yönelik
                                bir taahhüt olarak yorumlanamayacaktır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.2.Kullanıcı, Üye İşyeri tarafından Uygulama üzerinden satışa sunulan Ürünler’in Üye İşyeri’nin gündelik iş akışına
                                göre belirlendiğini ve Kullanıcı’nın Uygulama’yı kullanımı boyunca düzenli Ürün temininin garanti edilmediğini kabul
                                ve beyan eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.3.Kullanıcı, sipariş verdiği Ürünler’i Üye İşyeri tarafından belirtilen zaman aralığında ilgili Üye İşyeri
                                tesisinden teslim almakla ve Ürün’ün teslimi esnasında sipariş numarasını Üye İşyeri’ne ibraz etmekle yükümlüdür.
                                Kullanıcı, sipariş numarasını ibraz etmekten imtina etmesi veya siparişi zamanında teslim almaması halinde Üye
                                İşyeri’nin sipariş konusu Ürün üzerinde dilediği gibi tasarrufta bulunabileceğini ve buna ilişkin olarak ücret iadesi
                                de dahil olmak üzere Üye İşyeri’ne karşı herhangi bir talep ve iddiada bulunamayacağını kabul ve taahhüt eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.4.Kullanıcı, sipariş konusu Ürün bedelini Uygulama üzerinden ödeyecektir. Şirket, ödeme hizmetinin verilmesi için
                                üçüncü kişi ödeme hizmeti sağlayıcıları ile çalışacak ve Ürün bedelini, Kullanıcı’dan Uygulama üzerinden verdiği
                                sipariş esnasında otomatik olarak tahsil edecektir. Kullanıcı, Ürün bedelini Uygulama üzerinden ödememesi halinde,
                                siparişin tamamlanmamış olarak kabul edileceğini ve sipariş konusu Ürün’ün farklı bir Kullanıcı’ya satılabileceğini
                                kabul ve beyan eder. Tamamlanan bir sipariş, değiştirilemez veya iptali talep edilemez.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.5.Ürün bedelinin ödenmesi kapsamında Kullanıcılar tarafından sağlanan banka kartı ve/veya kredi kartı bilgileri
                                kesinlikle Şirket nezdinde saklanmamaktadır. Kullanıcılar’ın Uygulama üzerinden gerçekleştirdiği ödeme işlemlerinde
                                kullandığı banka kartı ve/veya kredi kartı ve ödeme bilgileri, bu yönde onay vermeleri halinde, Şirket’in bu hususta
                                hizmet aldığı üçüncü kişi firmalarca saklanacaktır. Bu kapsamda Kullanıcı, banka kartı ve/veya kredi kartı ve ödeme
                                bilgilerinin saklanması, kullanılması ve yetkisiz kullanımı da dahil her türlü talep ve şikayetlerini doğrudan ilgili
                                üçüncü kişi kuruluşa bildirmekle yükümlüdür. Kullanıcı, bahsi geçen talep ve şikayetlere ilişkin olarak Şirket’in
                                herhangi bir sorumluluğu bulunmadığını kabul ve beyan eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.6.Kullanıcılar’a Uygulama üzerinden Üye İşyerleri’ni ve sunulan hizmetleri puanlama imkanı tanınabilecektir.
                                Kullanıcı bu yönde kendisine bilgilendirme yapılmasını ve kendisi ile iletişim kurulmasını kabul eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.7.Şirket’in tamamen kendi takdirinde olmak üzere, Kullanıcı’dan Ürün siparişi başına Ürün bedeli ile birlikte ek
                                ücret talep etme hakkı saklıdır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.8.Sözleşme konusu hizmetin tarafları münhasıran Kullanıcı ve Üye İşyeri olduğundan, Kullanıcılar Üye İşyeri’ne
                                ilişkin tüm taleplerini ve ilgili mevzuattan doğan haklarını doğrudan sunulan hizmetin karşı tarafı olan Üye İşyeri’ne
                                iletecektir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                4.9.Kullanıcı, Üye İşyerleri ile Uygulama dışındaki bir kanaldan, Şirket’i aradan çıkararak Sözleşme konusu
                                hizmetlerin sağlanmasına ilişkin herhangi bir anlaşma yapmayacağını, ödeme işlemini Şirket’i aradan çıkararak Uygulama
                                dışında tamamlamayacağını ya da Üye İşyerleri’ni bu yönde teşvik edici hareketlerde bulunmayacağını kabul, beyan ve
                                taahhüt eder. Şirket’in belirli bir Kullanıcı için birden fazla kez tekrarlanan işlem iptalleri veya sair nedenlerle
                                Şirket’i aradan çıkararak işlem gerçekleştirdiğine ilişkin şüphesi oluşması ya da herhangi bir şekilde bu durumun
                                tespit edilmesi halinde, Şirket kendi takdirine bağlı olarak ilgili Kullanıcı’nın üyeliğini askıya alabilecek ya da
                                sonlandırabilecektir.{' '}
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                5. Sorumluluğun Kısıtlandırılması
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                5.1 Platform kapsamındaki Uygulama, yazılım ve sair içerikler “OLDUĞU GİBİ” sunulmakta olup, bu kapsamda Şirket’in
                                Uygulama, yazılım ve içeriğin doğruluğu, tamlığı ve güvenilirliği ile ilgili herhangi bir sorumluluk ya da taahhüdü
                                bulunmamaktadır. Kullanıcı, Şirket’in ayrıca İçerik ve diğer Kullanıcı verilerinin birbiriyle ilişkisine dair
                                taahhütte bulunmadığını anlar ve kabul eder. Şirket, Platform’un kullanımının kesintisiz ve hatasız olduğunu taahhüt
                                etmemektedir. Şirket, Platform’un 7/24 erişilebilir ve kullanılabilir olmasını hedeflemekle birlikte Platform’a
                                erişimi sağlayan sistemlerin işlerliği ve erişilebilirliğine ilişkin bir garanti vermemektedir. Kullanıcı, Platform’a
                                erişimin muhtelif zamanlarda engellenebileceğini ya da erişimin kesilebileceğini kabul eder. Şirket, söz konusu
                                engelleme veya kesintilerden hiçbir şekilde sorumlu değildir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                5.2 Platform üzerinden Şirket’in kontrolünde olmayan başka internet sitelerine ve/veya portallara, dosyalara veya
                                içeriklere link verilebileceğini ve bu tür linklerin yöneldiği internet sitesini veya işleten kişisini desteklemek
                                amacıyla veya internet sitesi veya içerdiği bilgilere yönelik herhangi bir türde bir beyan veya garanti niteliği
                                taşımadığını, söz konusu linkler vasıtasıyla erişilen portallar, internet siteleri, dosyalar ve içerikler, hizmetler
                                veya ürünler veya bunların içeriği hakkında Şirket’in herhangi bir sorumluluğu olmadığını kabul ve beyan eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                5.3. Kullanıcı, Platform üzerinden sunulan Uygulama ve Uygulama’lara erişim ve bunların kalitesinin büyük ölçüde
                                ilgili İnternet Servis Sağlayıcısı’ndan temin edilen hizmetin kalitesine dayandığını ve söz konusu hizmet kalitesinden
                                kaynaklı sorunlarda Şirket’in herhangi bir sorumluluğunun bulunmadığını kabul eder.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                5.4. Kullanıcı, yüklediği içerikler ile Platform’un kullanımından münhasıran sorumludur. Kullanıcı, fikri mülkiyet
                                ihlalleri, İçerik, Platform’un kullanımına ilişkin olarak üçüncü kişiler tarafından iletilebilecek her türlü iddia ve
                                talepten (yargılama masrafları ve avukatlık ücretleri de dahil olmak üzere) Şirket’i beri kıldığını kabul eder.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                6. Mücbir Sebepler
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                6.1. Mücbir sebep terimi, doğal afet, isyan, savaş, grev, Döngü’nün gerekli bilgi güvenliği önlemleri almasına karşın
                                Uygulama, Portal ve sisteme yapılan saldırılar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla Döngü’nün makul
                                kontrolü haricinde gelişen ve Döngü’nün gerekli özeni göstermesine rağmen önleyemediği kaçınılamayacak olaylar olarak
                                yorumlanacaktır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                6.2. Mücbir sebep sayılan tüm durumlarda, Taraflar işbu Sözleşme ile belirlenen edinimlerinden herhangi birini geç
                                veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                7. Sözleşme’nin Askıya Alınması ve Feshi
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                7.1. Kullanıcı’nın işbu Sözleşme’de yer alan hükümlere ve Site’de beyan edilen kural ve şartlara uymaması,
                                Kullanıcı’nın Site’deki yahut Hizmetler’in verilmesi sırasındaki faaliyetlerinin hukuki, teknik veya bilgi güvenliği
                                anlamında risk oluşturması ya da üçüncü kişilerin şahsi ve ticari haklarına halel getirici mahiyette olması halinde
                                Şirket, Kullanıcı’nın Site’yi kullanımını geçici veya sürekli olarak durdurabilir yahut Sözleşme’yi feshedebilir.
                                Kullanıcı’nın bu nedenle Şirket’ten herhangi bir talebi söz konusu olamaz.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                7.2. Şirket dilediği zamanda Site’yi ve/veya işbu Sözleşme’yi süreli veya süresiz olarak askıya alabilecek, sona
                                erdirebilecektir.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                8. Uyuşmazlıkların Çözümü
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Bu Sözleşme ile ilgili olarak çıkabilecek bütün uyuşmazlıklarda öncelikle işbu metinde yer alan hükümler, hüküm
                                bulunmayan konularda ise Türkiye Cumhuriyeti Kanunları uygulanacaktır. Sözleşme’nin uygulanmasından kaynaklanan
                                ihtilafların çözümünde İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili olacaktır.
                            </span>
                        </p>
                    </Paragraph>
                    <CustomRow>
                        <Text weight="bold" size="title" style={{ textAlign: 'center', width: '100%', paddingTop: '5%' }}>
                            KİŞİSEL VERİLERİ KORUMA KANUNU
                        </Text>
                    </CustomRow>
                    <Paragraph color="#000" weight="light" size="mediumRegular" style={{ paddingTop: '2%' }}>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                A. Veri Sorumlusu ve Temsilcisi
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca, kişisel verileriniz; veri sorumlusu olarak
                                Westerops Bilişim Ve Yazilim Hizmetleri Limited Şirketi (“Şirket”) tarafından aşağıda açıklanan kapsamda
                                işlenebilecektir.
                            </span>
                            <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>&nbsp;</span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                B. Hangi Kişisel Verilerin İşleneceği
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Aksi açıkça belirtilmedikçe, işbu Aydınlatma Metni kapsamında arz edilen hüküm ve koşullar kapsamında “Kişisel Veri”
                                ifadesi aşağıda yer alan bilgileri tanımlamaktadır.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Kanun’un 3 ve 7. maddesi uyarınca, geri döndürülemeyecek şekilde anonim hale getirilen veriler, Kanun hükümleri
                                uyarınca kişisel veri olarak kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu Aydınlatma Metni
                                hükümleri ile bağlı olmaksızın gerçekleştirilecektir.
                            </span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Tarafımızca işletilmekte olan Döngü uygulaması kapsamında (“Uygulama”), Uygulama kullanıcısı (“Kullanıcı”) tarafından
                                üyelik girişi ve Uygulama’nın kullanımı esnasında elektronik ortamda sağlanan kimlik bilgisi (isim, soyisim, doğum
                                tarihi, siparişe göre TC kimlik numarası / vergi numarası) iletişim bilgisi (telefon, adres, email), kullanıcı
                                bilgisi, kullanıcı işlem bilgisi, görsel/işitsel veri, işlem bilgisi, işlem güvenliği bilgisi, risk yönetimi bilgisi,
                                lokasyon/konum bilgisi, pazarlama bilgisi, IP adresi, sipariş içeriği ve talep/şikâyet yönetimi bilgisine ilişkin
                                veriler işlenmektedir.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                C. Kişisel Verilerin Hangi Amaçla İşleneceği
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">Toplanan kişisel verileriniz;</span>
                        </p>
                        <ul>
                            <li>
                                <span data-contrast="auto">Kullanıcı’nın Uygulama’dan faydalanabilmesi,</span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Şirket tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş
                                    birimleri tarafından yapılması,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    İş ortakları ve/veya tedarikçilerin bilgiye erişim yetkilerinin planlanması ve iş ortakları ve/veya tedarikçilerle
                                    ilişkilerin yönetimi,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Uygulama’ya konu hizmetlerin sağlanması ve iyileştirilmesi için gerekli olan faaliyetlerin doğasından kaynaklanan
                                    işlemlerin yerine getirilmesi,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Şirket’in ticari ve iş stratejilerinin belirlenmesi ve uygulanması, raporlama ve iş geliştirme faaliyetlerinin
                                    yürütülmesi,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kullanıcılar’ın kişisel tercih ve beğenileri dikkate alınarak Kullanıcı’ya tercihleri doğrultusunda önerilerin
                                    sunulması ve buna ilişkin faaliyetlerin yürütülmesi,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kullanıcı’nın talebine istinaden Uygulama üzerinden sunulan özelleştirmelerin aktifleştirilmesi ve bu kapsamda
                                    kişiye özel hizmetlerin sunulması,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Şirket’in sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması
                                    ve/veya icrası kapsamında Şirket’in sunduğu hizmetlerin iyileştirilmesi, yeni hizmetlerin tanıtılması ve bu
                                    doğrultuda Kullanıcı’ya gerekli bilgilendirmelerin yapılması,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kullanıcı deneyiminin iyileştirilmesi için gerekli faaliyetlerin icrası (iletişim yönetimi süreçlerinin
                                    geliştirilmesi, memnuniyet anketlerinin yürütülmesi) ve Kullanıcı’nın talep ve/veya şikayetlerinin takibi,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Şirket’in sunduğu ürün ve hizmetlerin satış ve pazarlaması için pazar araştırması faaliyetlerinin planlanması ve
                                    icrası,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Şirket tarafından yürütülen faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli
                                    çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Şirket tarafından sunulan hizmetlerin ilgili kişilere sunulması, önerilmesi ve tanıtılması için gerekli olan
                                    aktivitelerin planlanması ve icrası,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Uygulama tarafından kullanılan yapay zekanın geliştirilmesi faaliyetlerinin yönetilmesi ve icrası
                                </span>
                            </li>
                        </ul>
                        <p>
                            <span data-contrast="auto">
                                amaçlarıyla (“Amaçlar”) Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları dahilinde
                                işlenebilecektir.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                D. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Toplanan Kişisel Verileriniz; Şirket tarafından Amaçlar’ın gerçekleştirilmesi kapsamında, yurt içinde veya yurt
                                dışındaki grup şirketlerimize, iş ortaklarımıza ve tedarikçilerimize Kanun’un 8. ve 9. maddelerinde belirtilen kişisel
                                veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.
                            </span>
                        </p>

                        <p>
                            <span data-contrast="auto">
                                Şirket, yukarıda anılan Amaçlar’ın yerine getirilmesi ile sınırlı olarak, barındırma hizmeti almak için kişisel
                                verileri Kullanıcı’nın ikamet ettiği ülke dışında dünyanın herhangi bir yerinde bulunan sunucularına (sunucular
                                kendisine, bağlı şirketlerine, alt yüklenicilerine veya dış kaynak hizmet sağlayıcılara ait olabilir) aktarma hakkına
                                sahiptir. Kişisel verilerinizin siparişiniz ile ilgili kısmı, siparişin size ulaşmasını sağlamak üzere izniniz ve
                                siparişin size sağlıklı ulaşabilmesi çerçevesinde kuryeye aktarılabilecektir.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                E. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Kişisel verileriniz, yukarıda belirtilen Amaçlar doğrultusunda elektronik ortamda Kanun’un 5. ve 6. maddelerinde
                                belirtilen kişisel veri işleme şartlarına dayalı olarak Uygulama üzerinden veya üçüncü tarafların aracılığıyla
                                toplanmaktadır.
                            </span>
                        </p>
                        <p>
                            <Text size="mediumRegular" color="black" weight="bold">
                                F. Kişisel Veri Sahibinin Kanun’un 11. maddesinde Sayılan Hakları
                            </Text>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:
                            </span>
                        </p>
                        <ul>
                            <li>
                                <span data-contrast="auto">Kişisel verilerinizin işlenip işlenmediğini öğrenme,</span>
                            </li>
                            <li>
                                <span data-contrast="auto">Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda
                                    yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                                    ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin
                                    kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun
                                    ortaya çıkması durumunda buna itiraz etme,
                                </span>
                            </li>
                            <li>
                                <span data-contrast="auto">
                                    Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini
                                    talep etme.
                                </span>
                            </li>
                        </ul>

                        <p>
                            <span data-contrast="auto">Yukarıda sıralanan haklarınıza yönelik başvurularınızı</span>
                        </p>
                        <p>
                            <span data-contrast="auto">
                                “destek@dongu.com” adresine yazılı olarak veya mevzuat/ Kişisel Verileri Koruma Kurulu tarafından öngörülen diğer
                                yöntemler ile iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en geç otuz gün içinde başvurularınız
                                ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma
                                Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep edilebilecektir.
                            </span>
                        </p>
                    </Paragraph>
                </CustomRow>
                <CustomRow className="last-row" align="middle" justify="space-between">
                    <Col span={9}>
                        <CustomRow>
                            <Col span={3}>
                                <CustomCheckbox onClick={() => setSelected(true)} checked={confirmed || selected} />
                            </Col>
                            <Col>
                                <Text size="14px" weight="semi-bold">
                                    Sözleşmeyi okudum, onaylıyorum
                                </Text>
                            </Col>
                        </CustomRow>
                    </Col>
                    <Col>
                        <CustomButton type="secondary" onClick={handleConfirm} ghost={confirmed || !selected}>
                            <Text weight="semi-bold" color="white">
                                {confirmed ? 'Onaylandı' : 'Onaylıyorum'}
                            </Text>
                        </CustomButton>
                    </Col>
                </CustomRow>
            </CustomRow>
        </>
    );
};

export default UserAgreement;
