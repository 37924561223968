/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomModal, MenuWrapper, SearchBarWrapper } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import MenuItem from './MenuItem';
import { fetchFavouriteMeals } from '../../../../../../services/Redux/Dashboard/favourites/api';
import SearchBar from '../../../components/SearchBar';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);

const MenuModal = (props) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const { setFoodPrices, foodPrices, meals, boxId, isModalVisible, selectedOption } = props;

    const [mealsToRender, setMealsToRender] = useState();
    const [searchedKey, setSearchedKey] = useState('');
    const [filteredMeal, setFilteredMeal] = useState();
    const [refreshMeals, setRefreshMeals] = useState();

    useEffect(() => {
        dispatch(fetchFavouriteMeals());
    }, [isModalVisible]);

    useEffect(() => {
        setRefreshMeals(meals);
    }, [meals]);

    const { favouriteMeals } = selector((state) => state.favMealsStore);

    useEffect(() => {
        setMealsToRender(favouriteMeals?.sort((a, b) => b.favorite - a.favorite));

        if (!isModalVisible) {
            setSearchedKey('');
        }
    }, [favouriteMeals, isModalVisible]);

    const handleChange = (event) => {
        setSearchedKey(event.target.value);
        const newFilter = mealsToRender.filter((val) => val.name.toLowerCase().includes(event.target.value.toLowerCase()));
        setFilteredMeal(newFilter);
    };

    return (
        <CustomModal title={<Title text="Menü" />} {...props}>
            <Col span={24}>
                <SearchBarWrapper>
                    <SearchBar placeholder="Ürün ara" value={searchedKey} onChange={handleChange} />
                </SearchBarWrapper>
                {!searchedKey ? (
                    <MenuWrapper>
                        {mealsToRender ? (
                            <MenuItem
                                selectedOption={selectedOption}
                                foodPrices={foodPrices}
                                meals={refreshMeals}
                                boxId={boxId}
                                mealsToRender={mealsToRender}
                                setFoodPrices={setFoodPrices}
                            />
                        ) : (
                            <Spin style={{ margin: 'auto' }} />
                        )}
                    </MenuWrapper>
                ) : (
                    <MenuWrapper>
                        {filteredMeal ? (
                            <MenuItem
                                selectedOption={selectedOption}
                                foodPrices={foodPrices}
                                meals={refreshMeals}
                                boxId={boxId}
                                mealsToRender={filteredMeal}
                                setFoodPrices={setFoodPrices}
                            />
                        ) : (
                            <Spin style={{ margin: 'auto' }} />
                        )}
                    </MenuWrapper>
                )}
            </Col>
        </CustomModal>
    );
};

export default MenuModal;
