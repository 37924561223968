/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Option } from 'antd/lib/mentions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import { useSelector } from 'react-redux';
import { Wrapper } from './styles';
import { SelectList } from '../styles';
import ListBoxSuffixIcon from '../../../../../../Assets/Shared/Mailing/listBox.svg';
import BackArrow from '../../../../../../Assets/Shared/RestaurantDetailPage/back.svg';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery';

export default function LeftSection({ setFilterCity }) {
    const temp = useSelector((state) => state.storeStore.searchData);
    const history = useHistory();
    const { xs } = useResponsive();
    const [storeData, setStoreData] = useState();

    useEffect(() => {
        setStoreData(temp);
    }, []);
    const provinceList = [...new Set(storeData?.map((item) => item?.province))];

    return (
        <>
            <Row
                onClick={() => {
                    history.goBack();
                }}
                style={{
                    width: 25,
                    height: 22,
                    display: xs ? 'none' : 'flex',
                    alignSelf: 'center',
                }}>
                <img src={BackArrow} alt="back" />
            </Row>
            <Wrapper>
                <SelectList
                    bordered={false}
                    suffixIcon={<img src={ListBoxSuffixIcon} alt="img" />}
                    defaultValue="Semtini Seç"
                    style={{ width: '100%', height: '60%', marginTop: '8px', color: '#424242', opacity: 0.5 }}
                    onChange={(value) => {
                        setFilterCity(value);
                        history.goBack();
                    }}>
                    <Option>Tüm Semtler</Option>
                </SelectList>
            </Wrapper>
        </>
    );
}
