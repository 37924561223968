import styled from 'styled-components';
import { Row, Col } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;
    margin-top: 15px;

    &.card-row {
        border: 2px solid #f2f2fb;
        border-radius: 4px;
        margin-top: 10px;
        padding: 20px 20px;

        @media (max-width: 1279px) {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    &.icon-row {
        @media (max-width: 1279px) {
            justify-content: flex-start;
        }
    }

    &.name-row {
        margin-top: 0px;
    }

    &.time-row {
        background-color: #f5f9fc;
        border-color: #f2f2fb;
        margin-top: 0;
        padding: 10px;
        border-radius: 2px;
    }
`;

export const CustomCol = styled(Col)`
    &.delivery-icon {
        width: 100%;
    }

    &.icon-col {
        align-items: center;
        display: flex;
    }

    &.left-col,
    &.right-col {
        @media (max-width: 1279px) {
            max-width: 100%;
            width: 100%;
        }
    }
`;

export const CustomImg = styled.img`
    object-fit: contain;
`;
