/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Tooltip, Popover } from 'antd';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { CustomRow, ResetCard, Title, CustomButton } from './styles';
import bg from '../../../../Assets/Shared/LoginPage/loginBackground.svg';
import FloatingLabel from '../../../components/TextInput/textInput';
import { Text } from '../../../components/Typography/styles';
import { postPasswordSet } from '../../../../services/Redux/Shared/profile/api';

const PasswordReset = () => {
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const dispatch = useDispatch();
    const params = useParams();

    const [similarVisible, setSimilarVisible] = useState(false);
    const [matchCheck, setMatchCheck] = useState(false);
    const [passwordCheck1, setPasswordCheck1] = useState(false);
    const [passwordCheck2, setPasswordCheck2] = useState(false);
    const [passwordCheck3, setPasswordCheck3] = useState(false);

    const setPassword = () => {
        if (matchCheck && passwordCheck1 && passwordCheck2 && passwordCheck3) {
            const data = {
                password: newPassword,
                uidb64: params.uidb64,
                token: params.token,
            };
            dispatch(postPasswordSet(data));
        }
    };

    const TooltipContent = () => (
        <div>
            <Row>
                {passwordCheck1 === true ? (
                    <Text color="green" size="10px">
                        <CheckOutlined /> 1 rakam
                    </Text>
                ) : (
                    <Text size="10px">
                        <InfoCircleOutlined /> 1 rakam
                    </Text>
                )}
            </Row>
            <Row>
                {passwordCheck2 === true ? (
                    <Text color="green" size="10px">
                        <CheckOutlined /> 1 büyük harf
                    </Text>
                ) : (
                    <Text size="10px">
                        <InfoCircleOutlined /> 1 büyük harf
                    </Text>
                )}
            </Row>
            <Row>
                {passwordCheck3 === true ? (
                    <Text color="green" size="10px">
                        <CheckOutlined /> 8 karakter
                    </Text>
                ) : (
                    <Text size="10px">
                        <InfoCircleOutlined /> 8 karakter
                    </Text>
                )}
            </Row>
        </div>
    );


    const checkNewPassword = (temp) => {
        const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        const numerics = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        if (temp.length >= 8) {
            setPasswordCheck3(true);
        } else {
            setPasswordCheck3(false);
        }
        if (alphabets.some((v) => temp.includes(v))) {
            setPasswordCheck2(true);
        } else {
            setPasswordCheck2(false);
        }
        if (numerics.some((v) => temp.includes(v))) {
            setPasswordCheck1(true);
        } else {
            setPasswordCheck1(false);
        }
        setNewPassword(temp);
    };

    const checkPassword = (value) => {
        if (newPassword.includes(value)) {
            setSimilarVisible(false);
            setMatchCheck(false);
        } else {
            setSimilarVisible(true);
            setMatchCheck(false);
        }
        if (newPassword === value) {
            setMatchCheck(true);

            setSimilarVisible(true);
        }
        setNewPassword2(value);
    };

    return (
        <Row justify="center" style={{ height: '650px', backgroundImage: `url(${bg})`, backgroundColor: '#F69F8E' }}>
            <CustomRow justify="center">
                <ResetCard>
                    <Title size="title" weight="normal">
                        Set New Password
                    </Title>
                    <CustomRow className="new-pass-row">
                        <Tooltip color="white" title={TooltipContent}>
                            <FloatingLabel
                                placeholder="Password"
                                type="password"
                                autofill={false}
                                name="new-password"
                                value={newPassword}
                                onChange={(e) => checkNewPassword(e.target.value)}
                            />
                        </Tooltip>
                    </CustomRow>
                    <CustomRow className="new-pass-row2">
                        <Popover
                            visible={similarVisible}
                            content={!matchCheck ? <Text color="red">Passwords do not match</Text> : <Text color="green">Passwords Match</Text>}>
                            <FloatingLabel
                                placeholder="Password Again"
                                type="password"
                                autofill={false}
                                name="new-password"
                                value={newPassword2}
                                onChange={(e) => checkPassword(e.target.value)}
                            />
                        </Popover>
                    </CustomRow>
                    <CustomRow className="new-pass-row2">
                        <CustomButton type="secondary" onClick={() => setPassword()}>
                            <Text color="white">Onayla</Text>
                        </CustomButton>
                    </CustomRow>
                </ResetCard>
            </CustomRow>
        </Row>
    );
};

export default PasswordReset;
