import React from 'react';
import { TextWrapper } from './styles';
import { Text } from '../../../../../components/Typography/styles';

export default function FilterHeader() {
    return (
        <TextWrapper>
            <Text size="description" weight="light" color="black">
                Filtrele
            </Text>
        </TextWrapper>
    );
}
