/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { Text } from '../../../../../components/Typography/styles';
import { CustomRow, CustomButtom, CustomDivider } from './styles';
import { ReactComponent as UserLogo } from '../../../../../../Assets/Shared/CartPage/user-logo.svg';
import RestaurantCard from './RestaurantCard';
import PacketCard from './PacketCard';
import { getAddressActive } from '../../../../../../services/Redux/Shared/profile/api';

const ItemCart = (props) => {
    const { setCheckout } = props;
    const { updateSummary } = props;
    const { setUpdateSummary } = props;
    const dispatch = useDispatch();
    const selector = useSelector;

    useEffect(() => {
        dispatch(getAddressActive());
    }, []);

    const activeAddress = selector((state) => state.profileStore.activeAddress);

    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <CustomRow className="main-row" justify="space-around">
            <Col span={2}>
                <CustomRow align="middle" justify="end">
                    <UserLogo />
                </CustomRow>
            </Col>
            <Col span={21}>
                <CustomRow justify="space-between">
                    <Col>
                        <Text>
                            {user.first_name} {user.last_name}
                        </Text>
                    </Col>
                    <Col>
                        <Text weight="light" color="smoke">
                            Konumum:&nbsp;
                        </Text>
                        {activeAddress && (
                            <Text weight="semi-bold" size="16px">
                                {activeAddress.address}
                            </Text>
                        )}
                    </Col>
                </CustomRow>
                <CustomDivider />
                <RestaurantCard />
                <PacketCard updateSummary={updateSummary} setUpdateSummary={setUpdateSummary} />
                {/* The following part has been removed as per requested, however, uncommenting it shall make it work directly, functionalities are already implemented. */}
                <CustomRow justify="center" className="removeSmall">
                    <CustomButtom type="secondary" onClick={() => setCheckout(true)}>
                        <Text color="white" weight="semi-bold">
                            Devam Et
                        </Text>
                    </CustomButtom>
                </CustomRow>
            </Col>
        </CustomRow>
    );
};

export default ItemCart;
