import React from 'react';
import { KvkkWrapper, KvkkTitle, KvkkPolicyStyle } from './styles';
import { Title, Paragraph, Text } from '../../../../../components/Typography/styles';
import useMediaQuery from '../../../../../../helpers/Hooks/useMediaQuery';
import bg from '../../../../../../Assets/Shared/AboutUs/aboutUs.svg';

const KvkkSection = () => {
    const { xs, sm } = useMediaQuery();

    return (
        <KvkkPolicyStyle backgroundImage={sm ? '' : bg}>
            <KvkkWrapper style={{ width: sm ? '100%' : '70%' }}>
                <KvkkTitle style={{ paddingTop: xs ? '25%' : '10%' }}>
                    <Title style={{ textAlign: sm && 'center' }} weight="bold" color="#F38262" size="mediumTitle">
                        KVKK Politikası
                    </Title>
                </KvkkTitle>
                <Paragraph style={{ textAlign: sm && 'center' }} color="#000" weight="light" size="description">
                    <p>
                        <Text size="description" color="black" weight="bold">
                            A. Veri Sorumlusu ve Temsilcisi
                        </Text>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca, kişisel verileriniz; veri sorumlusu olarak Westerops
                            Bilişim Ve Yazilim Hizmetleri Limited Şirketi (“Şirket”) tarafından aşağıda açıklanan kapsamda işlenebilecektir.
                        </span>
                        <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>&nbsp;</span>
                    </p>
                    <p>
                        <Text size="description" color="black" weight="bold">
                            B. Hangi Kişisel Verilerin İşleneceği
                        </Text>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Aksi açıkça belirtilmedikçe, işbu Aydınlatma Metni kapsamında arz edilen hüküm ve koşullar kapsamında “Kişisel Veri”
                            ifadesi aşağıda yer alan bilgileri tanımlamaktadır.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Kanun’un 3 ve 7. maddesi uyarınca, geri döndürülemeyecek şekilde anonim hale getirilen veriler, Kanun hükümleri uyarınca
                            kişisel veri olarak kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu Aydınlatma Metni hükümleri ile bağlı
                            olmaksızın gerçekleştirilecektir.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Tarafımızca işletilmekte olan Döngü uygulaması kapsamında (“Uygulama”), Uygulama kullanıcısı (“Kullanıcı”) tarafından
                            üyelik girişi ve Uygulama’nın kullanımı esnasında elektronik ortamda sağlanan kimlik bilgisi (isim, soyisim, doğum tarihi,
                            siparişe göre TC kimlik numarası / vergi numarası) iletişim bilgisi (telefon, adres, email), kullanıcı bilgisi, kullanıcı
                            işlem bilgisi, görsel/işitsel veri, işlem bilgisi, işlem güvenliği bilgisi, risk yönetimi bilgisi, lokasyon/konum bilgisi,
                            pazarlama bilgisi, IP adresi, sipariş içeriği ve talep/şikâyet yönetimi bilgisine ilişkin veriler işlenmektedir.
                        </span>
                    </p>
                    <p>
                        <Text size="description" color="black" weight="bold">
                            C. Kişisel Verilerin Hangi Amaçla İşleneceği
                        </Text>
                    </p>
                    <p>
                        <span data-contrast="auto">Toplanan kişisel verileriniz;</span>
                    </p>
                    <ul>
                        <li>
                            <span data-contrast="auto">Kullanıcı’nın Uygulama’dan faydalanabilmesi,</span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimleri
                                tarafından yapılması,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                İş ortakları ve/veya tedarikçilerin bilgiye erişim yetkilerinin planlanması ve iş ortakları ve/veya tedarikçilerle
                                ilişkilerin yönetimi,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Uygulama’ya konu hizmetlerin sağlanması ve iyileştirilmesi için gerekli olan faaliyetlerin doğasından kaynaklanan
                                işlemlerin yerine getirilmesi,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket’in ticari ve iş stratejilerinin belirlenmesi ve uygulanması, raporlama ve iş geliştirme faaliyetlerinin
                                yürütülmesi,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kullanıcılar’ın kişisel tercih ve beğenileri dikkate alınarak Kullanıcı’ya tercihleri doğrultusunda önerilerin
                                sunulması ve buna ilişkin faaliyetlerin yürütülmesi,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kullanıcı’nın talebine istinaden Uygulama üzerinden sunulan özelleştirmelerin aktifleştirilmesi ve bu kapsamda kişiye
                                özel hizmetlerin sunulması,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket’in sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması ve/veya
                                icrası kapsamında Şirket’in sunduğu hizmetlerin iyileştirilmesi, yeni hizmetlerin tanıtılması ve bu doğrultuda
                                Kullanıcı’ya gerekli bilgilendirmelerin yapılması,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kullanıcı deneyiminin iyileştirilmesi için gerekli faaliyetlerin icrası (iletişim yönetimi süreçlerinin
                                geliştirilmesi, memnuniyet anketlerinin yürütülmesi) ve Kullanıcı’nın talep ve/veya şikayetlerinin takibi,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket’in sunduğu ürün ve hizmetlerin satış ve pazarlaması için pazar araştırması faaliyetlerinin planlanması ve
                                icrası,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket tarafından yürütülen faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli
                                çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket tarafından sunulan hizmetlerin ilgili kişilere sunulması, önerilmesi ve tanıtılması için gerekli olan
                                aktivitelerin planlanması ve icrası,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Uygulama tarafından kullanılan yapay zekanın geliştirilmesi faaliyetlerinin yönetilmesi ve icrası
                            </span>
                        </li>
                    </ul>
                    <p>
                        <span data-contrast="auto">
                            amaçlarıyla (“Amaçlar”) Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları dahilinde işlenebilecektir.
                        </span>
                    </p>
                    <p>
                        <Text size="description" color="black" weight="bold">
                            D. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
                        </Text>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Toplanan Kişisel Verileriniz; Şirket tarafından Amaçlar’ın gerçekleştirilmesi kapsamında, yurt içinde veya yurt dışındaki
                            grup şirketlerimize, iş ortaklarımıza ve tedarikçilerimize Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme
                            şartları ve amaçları çerçevesinde aktarılabilecektir.
                        </span>
                    </p>

                    <p>
                        <span data-contrast="auto">
                            Şirket, yukarıda anılan Amaçlar’ın yerine getirilmesi ile sınırlı olarak, barındırma hizmeti almak için kişisel verileri
                            Kullanıcı’nın ikamet ettiği ülke dışında dünyanın herhangi bir yerinde bulunan sunucularına (sunucular kendisine, bağlı
                            şirketlerine, alt yüklenicilerine veya dış kaynak hizmet sağlayıcılara ait olabilir) aktarma hakkına sahiptir. Kişisel
                            verilerinizin siparişiniz ile ilgili kısmı, siparişin size ulaşmasını sağlamak üzere izniniz ve siparişin size sağlıklı
                            ulaşabilmesi çerçevesinde kuryeye aktarılabilecektir.
                        </span>
                    </p>
                    <p>
                        <Text size="description" color="black" weight="bold">
                            E. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
                        </Text>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Kişisel verileriniz, yukarıda belirtilen Amaçlar doğrultusunda elektronik ortamda Kanun’un 5. ve 6. maddelerinde
                            belirtilen kişisel veri işleme şartlarına dayalı olarak Uygulama üzerinden veya üçüncü tarafların aracılığıyla
                            toplanmaktadır.
                        </span>
                    </p>
                    <p>
                        <Text size="description" color="black" weight="bold">
                            F. Kişisel Veri Sahibinin Kanun’un 11. maddesinde Sayılan Hakları
                        </Text>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:
                        </span>
                    </p>
                    <ul>
                        <li>
                            <span data-contrast="auto">Kişisel verilerinizin işlenip işlenmediğini öğrenme,</span>
                        </li>
                        <li>
                            <span data-contrast="auto">Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan
                                işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                                ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin
                                kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
                                çıkması durumunda buna itiraz etme,
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep
                                etme.
                            </span>
                        </li>
                    </ul>

                    <p>
                        <span data-contrast="auto">Yukarıda sıralanan haklarınıza yönelik başvurularınızı</span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            “destek@dongu.com” adresine yazılı olarak veya mevzuat/ Kişisel Verileri Koruma Kurulu tarafından öngörülen diğer
                            yöntemler ile iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en geç otuz gün içinde başvurularınız
                            ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu
                            tarafından belirlenecek tarifeye göre tarafınızdan ücret talep edilebilecektir.
                        </span>
                    </p>
                </Paragraph>
            </KvkkWrapper>
        </KvkkPolicyStyle>
    );
};

export default KvkkSection;
