/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */

import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import { Text } from '../../../../../components/Typography/styles';
import { ReactComponent as CloseBtn } from '../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';
import {
    CartWrapper,
    TextWrapper,
    DividerStyle,
    DividerWrapper,
    ButtonWrapper,
    TextStyle,
    ItemRow,
    PriceTextStyle,
    DiscountTextStyle,
    MenuCol,
    CustomRow,
    CustomButtom,
    BottomContent,
} from './styles';
import Cart from '../../../../../../Assets/Shared/FindRestaurant/graybasket.svg';
import YellowCart from '../../../../../../Assets/Shared/FindRestaurant/yellowbasket.svg';
import Spinner from '../../../../../components/Spinner';
import { fetchBasket, postBasketRemove } from '../../../../../../services/Redux/Shared/order/api';
import { getAddressActive } from '../../../../../../services/Redux/Shared/profile/api';

const CartSection = (props) => {
    const { updateCart, setUpdateCart } = props;
    const user = JSON.parse(localStorage.getItem('user'));
    const history = useHistory();
    const [update, setUpdate] = useState(0);

    const dispatch = useDispatch();
    const selector = useSelector;

    useEffect(() => {
        if (user) {
            dispatch(fetchBasket());
            dispatch(getAddressActive());
        }
    }, [dispatch, updateCart]);

    const basket = selector((state) => state.customerOrderStore.basketList);
    const activeAddress = selector((state) => state.profileStore.activeAddress);

    let surprise = null;
    let tanim = null;
    const mealPrices = [];
    const surprisePrice = [];
    if (basket && typeof basket !== 'string') {
        surprise = basket.filter((item) => item.text_name === 'Surprise Box');
        tanim = basket.filter((item) => item.text_name !== 'Surprise Box');

        tanim.map((item) => {
            let temp = 0;
            item.meals.map((meal) => {
                temp += meal.price;
                return 0;
            });
            mealPrices.push(temp);
            return 0;
        });

        surprise.map((box) => {
            let temp = 0;
            box.meals.map((meal) => {
                temp += meal.price;
                return 0;
            });
            surprisePrice.push(temp);
            return 0;
        });
    }

    function removeFromBasket(boxId) {
        const data = {
            box_id: boxId,
        };
        dispatch(postBasketRemove(data));
        setTimeout(() => {
            setUpdateCart(!updateCart);
        }, 250);
    }

    return (
        <CartWrapper>
            <TextWrapper>
                <Text size="description" weight="light" color="black">
                    Sepetim
                </Text>
            </TextWrapper>
            {user ? (
                <BottomContent>
                    <CustomRow className="cart-row">
                        <Col span={4}>
                            <img src={YellowCart} alt="img" style={{ width: 48, height: 48 }} />
                        </Col>
                        <Col span={18} style={{ marginLeft: 10 }}>
                            <Text weight="300" color="black">
                                Hoş geldin &nbsp;
                                <Text>
                                    {user.first_name}&nbsp;
                                    {user.last_name}
                                </Text>
                                <div style={{ width: '100%', height: 2, backgroundColor: '#F2F2FC' }} />
                            </Text>
                        </Col>
                    </CustomRow>
                    <>
                        {basket ? (
                            typeof basket === 'string' || basket.length === 0 ? (
                                <>
                                    <Text weight="300" color="black" style={{ justifyContent: 'center', display: 'flex' }}>
                                        <strong>Sepetin Henüz Boş</strong>
                                    </Text>
                                    <CustomRow justify="center">
                                        <DividerStyle />
                                    </CustomRow>
                                    {user && (
                                        <TextStyle>
                                            <Text weight="300" color="black">
                                                <strong> Konumun:</strong>
                                            </Text>
                                        </TextStyle>
                                    )}
                                    <TextStyle>
                                        {activeAddress && (
                                            <Text weight="300" size="light" color="black" style={{ textDecoration: 'underline', width: '70%' }}>
                                                {activeAddress.address}
                                            </Text>
                                        )}
                                    </TextStyle>
                                </>
                            ) : (
                                <>
                                    {surprise.map((item, index) => (
                                        <Row>
                                            <ItemRow align="middle" width="100%" justify="space-between">
                                                <Col>
                                                    <Text>Sürpriz Paket</Text>
                                                </Col>
                                                <Col onClick={() => removeFromBasket(item.id)}>
                                                    <CloseBtn />
                                                </Col>
                                            </ItemRow>
                                            <ItemRow className="price-row price-row-top" justify="end" align="middle">
                                                <Col span={6}>
                                                    <DiscountTextStyle weight="semi-bold" color="#bcbcbc">
                                                        {surprisePrice[index]}&nbsp; TL
                                                    </DiscountTextStyle>
                                                </Col>
                                                <Col span={6}>
                                                    <PriceTextStyle justify="center">
                                                        <Text color="green" weight="semi-bold">
                                                            {item.package_setting.min_discounted_order_price}&nbsp; TL
                                                        </Text>
                                                    </PriceTextStyle>
                                                </Col>
                                            </ItemRow>
                                        </Row>
                                    ))}
                                    {tanim.map((item, index) => (
                                        <Row>
                                            <ItemRow align="middle" width="100%" justify="space-between">
                                                <Col>
                                                    <Text>{item.text_name}</Text>
                                                </Col>
                                                <Col onClick={() => removeFromBasket(item.id)}>
                                                    <CloseBtn />
                                                </Col>
                                            </ItemRow>
                                            <ItemRow>
                                                <MenuCol span={14}>
                                                    <Text color="#707070" weight="light" size="14px">
                                                        {item.meals.map((meal, count) => {
                                                            if (count === item.meals.length - 1) {
                                                                return <span>{meal.name}</span>;
                                                            }
                                                            return <span>{meal.name}, &nbsp;</span>;
                                                        })}
                                                    </Text>
                                                </MenuCol>
                                            </ItemRow>
                                            <ItemRow className="price-row" justify="end" align="middle">
                                                <Col span={8}>
                                                    <DiscountTextStyle weight="semi-bold" color="#bcbcbc">
                                                        {mealPrices[index]}&nbsp; TL
                                                    </DiscountTextStyle>
                                                </Col>
                                                <Col span={6}>
                                                    <PriceTextStyle justify="center">
                                                        <Text color="green" weight="semi-bold">
                                                            {item.package_setting.min_discounted_order_price}&nbsp; TL
                                                        </Text>
                                                    </PriceTextStyle>
                                                </Col>
                                            </ItemRow>
                                        </Row>
                                    ))}
                                    <CustomRow justify="center">
                                        <DividerStyle />
                                    </CustomRow>
                                    {user && (
                                        <TextStyle>
                                            <Text weight="300" color="black">
                                                <strong> Konumun:</strong>
                                            </Text>
                                        </TextStyle>
                                    )}
                                    <TextStyle>
                                        {activeAddress && (
                                            <Text weight="300" size="light" color="black" style={{ textDecoration: 'underline', width: '70%' }}>
                                                {activeAddress.address}
                                            </Text>
                                        )}
                                    </TextStyle>
                                    <CustomRow justify="center">
                                        <DividerStyle />
                                    </CustomRow>
                                    <CustomRow className="odenecek">
                                        <Text weight="semi-bold">Ödenecek Toplam Tutar</Text>
                                    </CustomRow>
                                    <CustomRow align="middle" justify="center" className="price-row">
                                        <Text weight="bold" size="28px" color="green">
                                            {basket[0].package_setting.min_discounted_order_price * basket.length} TL
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="odenecek">
                                        <CustomButtom type="secondary" onClick={() => history.push('/sepetim')}>
                                            <Text color="white">Devam Et</Text>
                                        </CustomButtom>
                                    </CustomRow>
                                </>
                            )
                        ) : (
                            <Row align="middle">
                                <CustomRow justify="center">
                                    <DividerStyle />
                                </CustomRow>
                                <TextStyle>
                                    <Text weight="300" color="black">
                                        <strong> Konumun:</strong>
                                    </Text>
                                </TextStyle>
                                <TextStyle>
                                    {activeAddress && (
                                        <Text weight="300" size="light" color="black" style={{ textDecoration: 'underline', width: '70%' }}>
                                            {activeAddress.address}
                                        </Text>
                                    )}
                                </TextStyle>
                            </Row>
                        )}
                    </>
                </BottomContent>
            ) : (
                <div>
                    <Row style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '9%' }}>
                        <img src={Cart} alt="img" style={{ width: 86, height: 86 }} />
                    </Row>
                    <DividerWrapper>
                        <DividerStyle />
                    </DividerWrapper>
                    <ButtonWrapper>
                        <Button
                            type="secondary"
                            style={{
                                height: '100%',
                                width: '61%',
                            }}
                            onClick={() => history.push('/giris')}>
                            <Text size="regular" weight="regular" color="white">
                                Giriş Yap
                            </Text>
                        </Button>
                    </ButtonWrapper>
                    <CustomRow justify="center">
                        <DividerStyle />
                    </CustomRow>
                    {user && (
                        <TextStyle>
                            <Text weight="300" color="black">
                                <strong> Konumun:</strong>
                            </Text>
                        </TextStyle>
                    )}
                    <TextStyle>
                        {activeAddress && (
                            <Text weight="300" size="light" color="black" style={{ textDecoration: 'underline', width: '70%' }}>
                                {activeAddress.address}
                            </Text>
                        )}
                    </TextStyle>
                </div>
            )}
        </CartWrapper>
    );
};

export default CartSection;
