import { Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomTabs } from './styles';
import InformationPane from './InformationPane';
import PacketPane from './PacketPane';

const TabsPane = (props) => {
    const selector = useSelector;
    const { updateCart, setUpdateCart, categories } = props;
    const storeData = selector((state) => state.storeStore.storeData);
    const { TabPane } = CustomTabs;

    if (storeData) {
        return (
            <Row styles={{ maxWidth: '100%' }}>
                <CustomTabs defaultActiveKey="1" style={{ width: '100%', minWidth: '100%' }}>
                    <TabPane tab="Paketler" key="1">
                        <PacketPane updateCart={updateCart} setUpdateCart={setUpdateCart} />
                    </TabPane>
                    <TabPane tab="Bilgiler" key="2">
                        <InformationPane categories={categories} />
                    </TabPane>
                </CustomTabs>
            </Row>
        );
    }
    return null;
};

export default TabsPane;
