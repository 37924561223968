/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FindRestaurantWrapper, CustomCol } from './styles';
import BusinessSection from '../LandingPage/BusinessSection/index';
import CartSection from './RightTop/CartSection/Cart';
import FilterSection from './RightTop/FilterSection';
import SearchBar from './SearchBar/index';
import DividerStyle from './Divider/index';
import MainContent from '../FindRestaurant/MainContentSection';
import { searchStore } from '../../../../services/Redux/Shared/store/api';
import { fetchBoxCategoriesNames } from '../../../../services/Redux/Dashboard/boxes/api';
import { Text } from '../../../components/Typography/styles';
import Maps from './Maps';

const Favourites = () => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const [updateCart, setUpdateCart] = useState(false);
    const [query, setQuery] = useState('?');
    const [keyword, setKeyword] = useState('');
    const [price, setPrice] = useState([10, 500]);
    const [kurye, setKurye] = useState(false);
    const [searchQuery, setSearchQuery] = useState(0);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [sortBy, setSortBy] = useState(1);
    const [showRightSectionDrawer, setShowRightSectionDrawer] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [showRestaurants, setShowRestaurants] = useState(true);
    const [filterCity, setFilterCity] = useState('');

    const toggleRightSectionDrawer = () => setShowRightSectionDrawer(!showRightSectionDrawer);

    const resetFilter = () => {
        setPrice([10, 500]);
        setKurye(false);
        setSortBy(1);
        setSelectedCategories([]);
    };

    useEffect(() => {
        dispatch(fetchBoxCategoriesNames());
        dispatch(searchStore(query));
        setQuery(null);
    }, [dispatch, searchQuery]);

    useEffect(() => {
        let search = '?';
        if (price) {
            search = search.concat('minp=', price[0]);
            search = search.concat('&maxp=', price[1]);
        }
        if (keyword) {
            search = search.concat('&keyword=', keyword);
        }
        if (kurye) {
            search = search.concat('&ca=', true);
        }
        if (selectedCategories) {
            selectedCategories.map((item) => {
                search = search.concat('&cat=', item.id);
                return 0;
            });
        }
        setQuery(search);
    }, [keyword, price, kurye, selectedCategories]);

    const temp = selector((state) => state.storeStore.searchData);
    const user = selector((state) => state?.profileStore?.activeAddress);

    let storeData = null;
    if (temp) {
        storeData = temp[0];
        const users = JSON.parse(localStorage.getItem('user'));
        storeData = temp[0].filter((item) => item.favorited_by.filter((fav) => fav?.id === users?.id).length > 0);
    }

    return (
        <>
            <FindRestaurantWrapper gutter={16}>
                <CustomCol className="left-col" span={17}>
                    <SearchBar
                        toggleRightSectionDrawer={toggleRightSectionDrawer}
                        keyword={keyword}
                        setKeyword={setKeyword}
                        query={query}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        storeData={storeData}
                        setFilterCity={setFilterCity}
                    />
                    <DividerStyle
                        name="Favorilerim"
                        maps={showRestaurants ? 'Haritada Göster' : 'Liste Olarak Gör'}
                        showMap={showMap}
                        setShowMap={setShowMap}
                        showRestaurants={showRestaurants}
                        setShowRestaurants={setShowRestaurants}
                    />

                    {storeData?.length && showRestaurants ? (
                        <MainContent storeData={storeData} keyword={keyword} filterCity={filterCity} />
                    ) : storeData?.length && showMap ? (
                        <Maps storeData={storeData} user={user} />
                    ) : (
                        <Text color="red">Restoran bulunamadı</Text>
                    )}
                </CustomCol>
                <CustomCol className="right-col" span={7}>
                    <CartSection updateCart={updateCart} setUpdateCart={setUpdateCart} />
                    <FilterSection
                        kurye={kurye}
                        price={price}
                        setPrice={setPrice}
                        setKurye={setKurye}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        resetFilter={resetFilter}
                    />
                </CustomCol>
            </FindRestaurantWrapper>
            <BusinessSection />
            <Drawer visible={showRightSectionDrawer} placement="right" onClose={toggleRightSectionDrawer}>
                <CartSection updateCart={updateCart} setUpdateCart={setUpdateCart} />
                <FilterSection
                    kurye={kurye}
                    price={price}
                    setPrice={setPrice}
                    setKurye={setKurye}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    resetFilter={resetFilter}
                    toggleRightSectionDrawer={toggleRightSectionDrawer}
                />
            </Drawer>
        </>
    );
};
export default Favourites;
