import React from 'react';
import { Form, Input, Col, Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { FormWrapper } from './styles';
import { Text } from '../../../../components/Typography/styles';
// eslint-disable-next-line import/no-unresolved
import { tryAuth } from '../../../../../services/Redux/auth/api';

const tailLayout = {
    wrapperCol: {
        span: 24,
    },
};

const LoginForm = () => {
    const dispatch = useDispatch();
    const selector = useSelector;
    const loading = selector((state) => state.authStore.loading);

    const validateMessages = {
        required: `This field can not be empty`,
        types: {
            email: `Not valid e-mail`,
        },
    };

    const onSubmit = (values) => {
        dispatch(tryAuth(values.userUser.email.toLowerCase(), values.userUser.password));
    };

    return (
        <FormWrapper span={20}>
            <Form layout="vertical" name="nest-messages" onFinish={(values) => onSubmit(values)} validateMessages={validateMessages}>
                <Form.Item
                    name={['userUser', 'email']}
                    label={
                        <Text fontWeight="medium" fontSize="small">
                            Email
                        </Text>
                    }
                    rules={[
                        {
                            type: 'email',
                            required: true,
                        },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['userUser', 'password']}
                    label={
                        <Text fontWeight="medium" fontSize="small">
                            Password
                        </Text>
                    }
                    rules={[
                        {
                            required: true,
                        },
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                    <Checkbox className="stick-center-row">Remember me</Checkbox>
                </Form.Item>
                <Form.Item className="stick-center-col" {...tailLayout}>
                    <Col span={24} align="middle" justify="center">
                        <Button loading={loading} className="btn-size" type="primary" htmlType="submit">
                            Login
                        </Button>
                    </Col>
                </Form.Item>
            </Form>
        </FormWrapper>
    );
};

export default LoginForm;
