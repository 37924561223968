function formatDate(unformattedDate) {
    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
    };

    const date = new Date(unformattedDate).toLocaleDateString('tr-TR', dateOptions);
    const time = new Date(unformattedDate).toLocaleTimeString('tr-TR', timeOptions);

    return { date, time };
}

export default formatDate;
