import React from 'react';
import { useRouteMatch, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { CustomRow, CustomCol, TabArrow, CustomLink } from './styles';
import { Text } from '../../../../components/Typography/styles';
import InformationPane from './InformationPane';
import AddressPane from './AddressPane';
import OrdersPane from './OrdersPane';
import PaymentPane from './PaymentPane';

const Content = () => {
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const handleClick = () => {
        // Go to address section if we are on any other section
        if (location.pathname !== '/profilim/adreslerim') history.push(`${url}/adreslerim`);
        // Refresh the page if we are at adress section
        else history.go(0);
    };

    return (
        <CustomRow>
            <CustomCol span={5}>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between">
                    <CustomLink to={`${url}/bilgilerim`} activeClassName="active">
                        <Text weight="light">Bilgilerim</Text>
                        <TabArrow />
                    </CustomLink>
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between">
                    <CustomLink to={`${url}/gecmis-siparislerim`} activeClassName="active">
                        <Text weight="light">Siparişlerim</Text>
                        <TabArrow />
                    </CustomLink>
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between">
                    <CustomLink to={`${url}/adreslerim`} activeClassName="active">
                        <Text weight="light" onClick={handleClick}>
                            Adreslerim
                        </Text>
                        <TabArrow />
                    </CustomLink>
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between">
                    <CustomLink to={`${url}/kartlarim`} activeClassName="active">
                        <Text weight="light">Kayıtlı Kartlarım</Text>
                        <TabArrow />
                    </CustomLink>
                </CustomRow>
            </CustomCol>
            <CustomCol span={19}>
                <div style={{ display: 'flex' }}>
                    <Switch>
                        <Route path={`${path}bilgilerim`}>
                            <InformationPane />
                        </Route>
                        <Route path={`${path}gecmis-siparislerim`}>
                            <OrdersPane />
                        </Route>
                        <Route path={`${path}adreslerim`}>
                            <AddressPane />
                        </Route>
                        <Route path={`${path}kartlarim`}>
                            <PaymentPane />
                        </Route>
                    </Switch>
                </div>
            </CustomCol>
        </CustomRow>
    );
};

export default Content;
