import React, { useState, useEffect } from 'react';
import { Row, message } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Text } from '../../../../components/Typography/styles';
import { PacketCountCardStyles, Content, Counter, Description, ButtonGroup, CustomButton } from './styles';
import increment from '../../../../../Assets/Dashboard/increment.png';
import decrement from '../../../../../Assets/Dashboard/decrement.png';
import xml from '../../../../../services/Api/request';
import PacketCancelModal from '../components/PacketCancelModal';
import NewBoxModal from '../components/NewBoxModal';
import { fetchBoxes } from '../../../../../services/Redux/Dashboard/boxes/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);
const PacketCountCard = ({ boxList, startDate }) => {
    const dispatch = useDispatch();

    const [isPacketModalVisible, setIsPacketModalVisible] = useState(false);
    const [isNewBoxModalVisible, setIsNewBoxModalVisible] = useState(false);
    const [orderCount, setOrderCount] = useState(0);
    const [boxCount, setBoxCount] = useState(0);
    const [tempCount, setTempCount] = useState(0);

    const handleClose = () => {
        setIsPacketModalVisible(false);
        setIsNewBoxModalVisible(false);
        dispatch(fetchBoxes());
    };

    useEffect(() => {
        if (boxList) {
            setBoxCount(boxList?.filter(({ sold }) => !sold)?.length);
            setTempCount(boxList?.length);
            const filtered = boxList?.filter(({ sold }) => sold === true);
            setOrderCount(filtered.length);
        }
    }, [boxList]);

    const decreaseValue = () => {
        if (tempCount !== 0) {
            setIsPacketModalVisible(true);
        }
    };

    const updateStockEvent = async () => {
        const now = moment(new Date()); // todays date
        const start = moment(startDate); // start date
        const duration = moment.duration(start.diff(now));

        if (duration.hours() >= 2) {
            setTempCount(tempCount + 1);
            await xml({
                path: `box/`,
                method: 'POST',
                body: {
                    count: 1,
                },
                sendToken: true,
            }).then(message.success('Suprise Paket Başarı ile Oluşturuldu.'));

            dispatch(fetchBoxes());
        } else {
            setIsNewBoxModalVisible(true);
        }
    };

    return (
        <PacketCountCardStyles title={<Title text="Paket Sayısı" />}>
            <Content span={24}>
                <Counter>
                    <ButtonGroup>
                        <CustomButton disabled={!tempCount} onClick={() => decreaseValue()}>
                            <img src={decrement} alt="" />
                        </CustomButton>
                        <Text color="red" size="44px" weight="bold">
                            {tempCount || 0}
                        </Text>
                        <CustomButton onClick={() => updateStockEvent()}>
                            <img src={increment} alt="" />
                        </CustomButton>
                    </ButtonGroup>
                </Counter>

                <Description>
                    <Text size="description" family="primary" weight="bold">
                        {`Satışa sunmuş olduğunuz, ${tempCount} paketten ${orderCount} tanesi satın alındı.`}
                    </Text>
                </Description>
            </Content>
            <PacketCancelModal
                setIsPacketModalVisible={setIsPacketModalVisible}
                boxList={boxList}
                visible={isPacketModalVisible}
                onCancel={handleClose}
            />
            <NewBoxModal onCancel={handleClose} visible={isNewBoxModalVisible} setIsNewBoxModalVisible={setIsNewBoxModalVisible} />
        </PacketCountCardStyles>
    );
};

export default PacketCountCard;
