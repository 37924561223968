/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Col, Row } from 'antd';
import { between } from 'polished';
import { HowToUseStyles, Image, Title, ContentWrapper, HowToMobileStyles } from './styles';
import { Text } from '../../../../components/Typography/styles';
import howToUse from '../../../../../Assets/Shared/ForBusiness/howToUse.svg';
import howToUseMobile from '../../../../../Assets/Shared/ForBusiness/howTouUseForBuss.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const HowToUseSection = () => {
    const data = [
        {
            title: 'Restoran paketleri hazırlar',
            detail: 'Satılamayacağını öngördüğün gıdalar, günün herhangi bir saatinde tanımlayabileceğin farklı paket içerikleri ile satışa sunulur.',
        },
        {
            title: 'Müşteriler paketleri satın alır',
            detail: 'Müşteriler, Döngü üzerinden restoranını bularak, online ödeme ile tanımlanmış paketleri satın alır.',
        },
        {
            title: 'Müşteriler paketleri teslim alır',
            detail:
                'Sipariş teslimlerini işletmenden gerçekleştirebilir veya kurye hizmeti sunuyorsan paketlerini kapıda teslim ile müşterilere ulaştırabilirsin.',
        },
    ];
    const { sm, xl } = useMediaQuery();
    return sm ? (
        <HowToMobileStyles backgroundColor="#F5F9FC" backgroundImage={howToUseMobile}>
            {data.map((item, index) => (
                <div key={index} style={{ width: '60%', height: '333px' }}>
                    <Row style={{ marginBottom: 20, marginLeft: 10 }} justify="center">
                        <Text style={{ textAlign: 'right' }} size="mediumTitle" weight="semi-bold">
                            {item.title}
                        </Text>
                    </Row>
                    <Row style={{}} justify="center">
                        <Text size="description" weight="regular" style={{ textAlign: 'right' }}>
                            {item.detail}
                        </Text>
                    </Row>
                </div>
            ))}
        </HowToMobileStyles>
    ) : (
        <HowToUseStyles span={24} backgroundColor="#F5F9FC">
            <Row>
                <Title color="red" size="mediumTitle" weight="bold">
                    Döngü, restoran iş ortakları için nasıl işler?
                </Title>
            </Row>

            <Image src={howToUse} alt="howtouse" width="100%" />
            <ContentWrapper gutter={32}>
                {data.map((item) => (
                    <Col span={8}>
                        <Row style={{ marginBottom: 50 }} justify="center">
                            <Text
                                size={between('24px', '36px', '320px', '1920px')}
                                weight="semi-bold"
                                style={{ textAlign: 'center', width: xl ? '90%' : '100%', lineHeight: between('36px', '44px', '320px', '1920px') }}>
                                {item.title}
                            </Text>
                        </Row>
                    </Col>
                ))}
                {data.map((item) => (
                    <Col span={8}>
                        <Row style={{ marginBottom: 50 }} justify="center">
                            <Text size="description" weight="regular" style={{ textAlign: 'center' }}>
                                {item.detail}
                            </Text>
                        </Row>
                    </Col>
                ))}
            </ContentWrapper>
        </HowToUseStyles>
    );
};

export default HowToUseSection;
