/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomRow, UnderlinedText, CustomButton } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import { ReactComponent as CloseBtn } from '../../../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';
import { ReactComponent as Receipt } from '../../../../../../../../Assets/Shared/Profile/receipt.svg';
import ConfirmationModal from '../../ConfirmationModal';
import { deleteOrder } from '../../../../../../../../services/Redux/Shared/profile/api';
import { isHoursPassed } from '../../../../../../../../helpers';
import openNotification from '../../../../../../../components/Notification';
import { resetDeleteOrderError } from '../../../../../../../../services/Redux/Shared/profile/actions';
import { fetchStore } from '../../../../../../../../services/Redux/Shared/store/api';

const paketIptalText = 'Paketi iptal etmek istediğinize emin misiniz?';
const siparisIptalText = 'Siparişi iptal etmek istediğinize emin misiniz?';

const initialModalState = {
    showCancelPackageModal: false,
    showCancelOrderModal: false,
};

const getDeliveryTypeById = {
    1: 'Gel-Al Paket',
    2: 'Kurye Paket',
};

const OrderDetails = (props) => {
    const { handleShowDetailToggleClick, orderDetails, buyingTimeFormatted, STATUSES } = props;
    const { boxes, ref_code, cost, address, delivery_type, buying_time, id, courier_time, status } = orderDetails;
    const dispatch = useDispatch();
    const selector = useSelector;
    const [modal, setModal] = useState(initialModalState);
    const [showNotification, setShowNotification] = useState(false);
    const [isRestaurantOpen, setIsRestaurantOpen] = useState(false);

    const { showCancelPackageModal, showCancelOrderModal } = modal;

    const openModal = {
        cancelPackageModal: () => setModal({ ...initialModalState, showCancelPackageModal: true }),
        cancelOrderModal: () => setModal({ ...initialModalState, showCancelOrderModal: true }),
        removeOrderHistoryModal: () => setModal({ ...initialModalState, showRemoveOrderHistoryModal: true }),
    };

    const dismiss = () => {
        setModal(initialModalState);
    };
    const { CANCELLED_BY_STORE, CANCELLED_BY_USER, CANCELLED_BY_DONGU, DELIVERED } = STATUSES;
    const cancelOrderHiddenStatuses = [CANCELLED_BY_STORE, CANCELLED_BY_USER, CANCELLED_BY_DONGU, DELIVERED];

    const cancelOrder = async () => {
        try {
            if (delivery_type === '2') {
                dispatch(deleteOrder(id, courier_time.id, delivery_type));
            } else {
                dispatch(deleteOrder(id, 0, '1'));
            }
        } catch (err) {
            console.log(err);
        }
        setShowNotification(true);
        dismiss();
    };

    const orderError = selector((state) => state.profileStore.deleteOrderError);

    // Line 71 -> 90 is related to checking if restaurant is open or closed in order to cancel the order
    useEffect(() => {
        dispatch(fetchStore(orderDetails?.store?.id));
    }, [orderDetails, dispatch]);

    const { storeData } = selector((state) => state?.storeStore || {});

    useEffect(() => {
        if (storeData) {
            const today = new Date();
            const todayFormat = today.toISOString();
            const RestaurantToday = storeData?.calendar?.filter((e) => e?.start_date.substring(0, 10) === todayFormat?.substring(0, 10));

            if (Math.floor((new Date(RestaurantToday[0]?.start_date) - new Date(buying_time)) / (60 * 1000)) >= 30) {
                setIsRestaurantOpen(true);
            } else {
                setIsRestaurantOpen(false);
            }
        }
    }, [storeData]);

    useEffect(() => {
        if (orderError && showNotification) {
            openNotification('Sipariş İptal Edilemez', orderError, '', true);
            dispatch(resetDeleteOrderError());
        }
    }, [orderError, showNotification]);

    const sharedModalProps = {
        dismiss,
        width: 390,
        closeIcon: <CloseBtn />,
        onCancel: dismiss,
    };

    return (
        <CustomRow className="main-row">
            <CustomRow justify="space-between">
                <Text weight="semi-bold">Sipariş Özeti</Text>
                <CloseBtn onClick={handleShowDetailToggleClick} />
            </CustomRow>
            <CustomRow className="content-row">
                <Col span={15}>
                    <CustomRow className="margin-bottom-together">
                        <Text size="14px">
                            {buyingTimeFormatted?.date} - {buyingTimeFormatted?.time}
                        </Text>
                    </CustomRow>
                    <CustomRow className="margin-bottom-together">
                        <Text size="14px" weight="light">
                            {getDeliveryTypeById[delivery_type]} - Online Ödeme (Kredi Kartı)
                        </Text>
                    </CustomRow>
                    <CustomRow className="margin-bottom-together">
                        <Text size="14px" weight="light">
                            {address?.name} - {address?.address}
                        </Text>
                    </CustomRow>
                    <br />
                    <CustomRow className="margin-bottom-together">
                        <Text size="14px">Sepetindeki ürün adedi: {boxes.length}</Text>
                    </CustomRow>
                    {boxes.map((box) => {
                        const { text_name, meals, sale_day } = box;
                        const { box_count } = sale_day || {};
                        return (
                            <>
                                <CustomRow className="margin-bottom-together">
                                    <Text size="14px" weight="light">
                                        {box_count > 1 ? `1x` : ''} {text_name} - {meals ? cost / boxes.length : ''}₺
                                    </Text>
                                </CustomRow>
                                <CustomRow className="margin-bottom-menu">
                                    <Text size="11px" weight="light">
                                        {meals?.length ? meals.map(({ name: mealName }, i) => `${i !== 0 ? ', ' : ''}${mealName}`) : null}
                                    </Text>
                                </CustomRow>
                            </>
                        );
                    })}
                    <CustomRow className="margin-bottom-together">
                        <Text size="14px">
                            Sipariş statüsü: {['4', '5'].includes(status) && 'İptal Edildi'}
                            {status === '6' && 'Tamamlandı'}
                            {['1', '2', '3', '9'].includes(status) && 'Aktif'}
                        </Text>
                    </CustomRow>
                </Col>

                <Col span={8}>
                    <CustomRow>
                        <Receipt />
                    </CustomRow>
                    <CustomRow className="order-number">
                        <Col span={12}>
                            <Text weight="14px">Sipariş Numarası:</Text>
                        </Col>
                        <Col>
                            <Text weight="18px" color="green">
                                {ref_code}
                            </Text>
                        </Col>
                    </CustomRow>
                    <CustomRow className="margin-bottom-price">
                        <Text size="14px" weight="light">
                            Sepet Tutarı: {cost}₺
                        </Text>
                    </CustomRow>
                    <CustomRow className="margin-bottom-total">
                        <Text size="14px">Ödenen Toplam Tutarı: {cost}₺</Text>
                    </CustomRow>
                    {(!isHoursPassed(0.5, new Date(buying_time)) || isRestaurantOpen) && !cancelOrderHiddenStatuses?.includes(status) && (
                        <CustomRow className="margin-bottom-total">
                            <CustomButton type="text" onClick={openModal.cancelOrderModal}>
                                <UnderlinedText size="14px" weight="light" color="yellow">
                                    Siparişimi İptal Et
                                </UnderlinedText>
                            </CustomButton>
                        </CustomRow>
                    )}
                </Col>
            </CustomRow>
            <ConfirmationModal {...sharedModalProps} onAccept={cancelOrder} visible={showCancelOrderModal} text={siparisIptalText} />
            <ConfirmationModal {...sharedModalProps} visible={showCancelPackageModal} text={paketIptalText} />
        </CustomRow>
    );
};

export default OrderDetails;
