import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const InfoSectionStyles = styled(Col)`
    //TODO SET PADDINGS PROPERLY
    min-height: 80vh;
    padding-left: ${between('0px', '120px')};
    padding-right: ${between('0px', '120px')};
    background: ${({ backgroundColor }) => {
        if (backgroundColor) {
            return `${backgroundColor}`;
        }
    }};
    display: flex;

    align-items: center;
    @media (max-width: 768px) {
        padding-top: 20px;
    }
`;

export const NearbyFoodsPreviewWrapper = styled(Row)`
    position: relative;
    z-index: 30;
    top: -280px;
`;
