import styled from 'styled-components';
import { Row } from 'antd';
import { between } from 'polished';
import { Title } from '../../../../../components/Typography/styles';

export default styled.div`
    height: 100%;
    max-height: 705px;
    overflow-y: scroll;
    /* ::-webkit-scrollbar {
        width: 10px;
    } */

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 50px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    background: ${({ theme }) => theme.lightColor};
    display: flex;
    flex-direction: column;
`;

export const Content = styled(Row)``;

export const DateContainer = styled(Row)`
    height: 60px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
`;

export const Date = styled(Title)``;

export const InfoText = styled(Title)`
    font-size: ${between('14px', '22px')};
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
`;
