import { Button } from 'antd';
import React from 'react';
import { Text } from '../../../../components/Typography/styles';
import { Wrapper } from './styles';

export default function DividerStyle({ name, maps, showMap, setShowMap, showRestaurants, setShowRestaurants }) {
    const handleClick = () => {
        if (showRestaurants) {
            setShowMap(true);
            setShowRestaurants(false);
        } else if (showMap) {
            setShowRestaurants(true);
            setShowMap(false);
        }
    };

    return (
        <Wrapper>
            <Text style={{ fontSize: 22, fontWeight: '500' }}>{name}</Text>
            <Button onClick={handleClick} style={{ fontSize: 14, fontWeight: '500', color: '#f38262', border: 'none', background: 'transparent' }}>
                {maps}
            </Button>
        </Wrapper>
    );
}
