import styled from 'styled-components';
import { Col } from 'antd';
import { between } from 'polished';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const InfoSectionStyles = styled(Col)`
    margin-top: 90px;
    //TODO SET PADDINGS PROPERLY
    min-height: 90vh;
    padding-left: ${between('0px', '120px')};
    padding-right: ${between('0px', '120px')};
    background: ${({ backgroundColor }) => {
        if (backgroundColor) {
            return `${backgroundColor}`;
        }
    }};
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        padding-top: ${between('30px', '60px')};
    }
`;
