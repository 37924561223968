/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-unused-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row, Menu, Space, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { CloseOutlined } from '@ant-design/icons';
import StyledHeader, {
    LocalizationAndProfileGroup,
    ButtonContainer,
    SignUpButton,
    SignInButton,
    CustomMenuItem,
    CustomButton,
    CustomMenuText,
} from './styles';
import { Text } from '../../Typography/styles';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import donguLogo from '../../../../Assets/Shared/Layout/donguLogo.svg';
import menu from '../../../../Assets/Shared/Layout/hamburger_menu.svg';
import BackGreenArrow from '../../../../Assets/Shared/RestaurantDetailPage/BackGreenArrow.svg';
import Button from '../../Button';
import Profile from './Profile';
import { authLogOutAction } from '../../../../services/Redux/auth/actions';

const HeaderNavItems = (type) => {
    switch (type) {
        case 'chef':
            return [
                {
                    id: 1,
                    title: 'Dongu Store',
                    path: '/nearby-foods',
                },
                {
                    id: 2,
                    title: 'Become a courrier',
                    path: '/become-courrier',
                },
            ];
        case 'customer':
            return [
                {
                    id: 1,
                    title: 'Dongu Store',
                    path: '/nearby-foods',
                },
                {
                    id: 2,
                    title: 'Become chef',
                    path: '/become-a-chef',
                },
                {
                    id: 3,
                    title: 'Become a courrier',
                    path: '/become-a-courrier',
                },
            ];
        default:
            return [
                {
                    id: 0,
                    title: 'Restoran Bul',
                    path: '/restoran-bul',
                },

                {
                    id: 1,
                    title: 'Gıda İsrafı',
                    path: '/gida-israfi',
                    hideOnSmallScreens: true,
                },
                {
                    id: 2,
                    title: 'Hakkımızda',
                    path: '/hakkimizda',
                },
                {
                    id: 3,
                    title: 'İşletmeler için Döngü',
                    path: '/isletmeler-icin',
                },
                {
                    id: 4,
                    title: 'Profilim',
                    path: '/profilim/bilgilerim',
                    hideOnLargeScreens: true,
                },
            ];
    }
};

const mobileNavigation = [
    {
        id: 0,
        title: 'Siparişlerim',
        path: '/profilim/gecmis-siparislerim',
    },
    {
        id: 1,
        title: 'Adreslerim',
        path: '/profilim/adreslerim',
    },
    {
        id: 2,
        title: 'Favorilerim',
        path: '/favorilerim',
    },
    {
        id: 3,
        title: 'Gıda İsrafı',
        path: '/gida-israfi',
    },
    {
        id: 4,
        title: 'Hakkımızda',
        path: '/hakkimizda',
    },
    {
        id: 5,
        title: 'İşletmeler için Döngü',
        path: '/isletmeler-icin',
    },
];

const mobileNavigationGuest = [
    {
        id: 0,
        title: 'Giriş Yap',
        path: '/giris',
    },
    {
        id: 1,
        title: 'Üye Ol',
        path: '/uye-ol',
    },
    {
        id: 2,
        title: 'Gıda İsrafı',
        path: '/gida-israfi',
    },
    {
        id: 3,
        title: 'Hakkımızda',
        path: '/hakkimizda',
    },
    {
        id: 4,
        title: 'İşletmeler için Döngü',
        path: '/isletmeler-icin',
    },
];

const GuestComponent = () => (
    <ButtonContainer>
        {/* <SignInButton type="border" borderColor={theme.secondaryColor} onClick={() => history.push('login')}>
            <Text size="regular" color="green" weight="semi-bold">
                Giriş Yap
            </Text>
        </SignInButton>

        <SignUpButton type="secondary">
            <Text size="regular" color="white" weight="semi-bold">
                Üye Ol
            </Text>
        </SignUpButton> */}
    </ButtonContainer>
);
const Header = ({ userData, userRole }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { basketList } = useSelector((state) => state?.customerOrderStore);

    const logout = useCallback(() => {
        dispatch(authLogOutAction());
        window.location.reload();
    }, [dispatch]);

    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const onClickLogo = () => {
        history.push('/');
        window.scrollTo(0, 0);
    };

    const { lg } = useResponsive();
    const currentURL = location.pathname;

    const openDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const menuItems = useMemo(
        () => [
            {
                name: 'Profilim',
                route: '/profilim/bilgilerim',
            },
            {
                name: 'Favorilerim',
                route: '/favorilerim',
            },
            {
                name: 'Bildirimlerim',
                route: '/bildirimlerim',
            },
            {
                name: 'Sepetim',
                route: '/sepetim',
            },
            {
                name: 'Ayarlar',
                route: '/ayarlar',
            },
            {
                name: 'Yardım',
                route: '/destek',
            },
            {
                name: 'Çıkış yap',
                handler: logout,
            },
        ],
        [logout]
    );

    return (
        <StyledHeader>
            {mobileNavigation.filter((item) => item.path === location.pathname).length !== 0 && window.innerWidth <= '576' ? (
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                    <Col span={2}>
                        <img
                            src={BackGreenArrow}
                            alt="back"
                            onClick={() => {
                                history.goBack();
                            }}
                        />
                    </Col>
                    <Col span={22} align="center">
                        <Text weight="light" style={{ textAlign: 'center', fontSize: '18px' }}>
                            {mobileNavigation.filter((item) => item.path === location.pathname)[0]?.title}
                        </Text>
                    </Col>
                </Row>
            ) : (
                <>
                    <Drawer
                        visible={visible}
                        closable
                        onClose={onClose}
                        width="100%"
                        bodyStyle={{
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        closeIcon={<CloseOutlined style={{ color: '#8FD89F', fontSize: '24px', marginTop: '20px', marginRight: '10px' }} />}>
                        <img src={donguLogo} alt="" style={{ width: '30%', marginTop: '30%', marginBottom: '40px' }} />
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }}>
                            {userData
                                ? mobileNavigation.map(({ id, title, path }) => (
                                      <Row key={id} justify="end" style={{ marginBottom: 20, minWidth: '70%' }}>
                                          <Button
                                              type="text"
                                              style={{
                                                  backgroundColor: currentURL === path ? '#F5F9FC' : '#ffffff',
                                                  boxShadow: 'none',
                                                  border: 'none',
                                                  borderRadius: '4px',
                                                  height: 48,
                                                  width: '100%',
                                              }}
                                              onClick={() => {
                                                  history.push(path);
                                                  onClose();
                                                  window.scrollTo(0, 0);
                                              }}
                                              key={id}>
                                              <Text weight="light" style={{ textAlign: 'right', fontSize: '18px' }}>
                                                  {title}
                                              </Text>
                                          </Button>
                                      </Row>
                                  ))
                                : mobileNavigationGuest.map(({ id, title, path }) => (
                                      <Row key={id} justify="end" style={{ marginBottom: 20, minWidth: '70%' }}>
                                          <Button
                                              type="text"
                                              style={{
                                                  backgroundColor: currentURL === path ? '#F5F9FC' : '#ffffff',
                                                  boxShadow: 'none',
                                                  border: 'none',
                                                  borderRadius: '4px',
                                                  height: 48,
                                                  width: '100%',
                                              }}
                                              onClick={() => {
                                                  history.push(path);
                                                  onClose();
                                                  window.scrollTo(0, 0);
                                              }}
                                              key={id}>
                                              <Text weight="light" style={{ textAlign: 'right', fontSize: '18px' }}>
                                                  {title}
                                              </Text>
                                          </Button>
                                      </Row>
                                  ))}
                        </div>
                        <GuestComponent history={history} />
                    </Drawer>
                    <Row align="middle" justify="space-between">
                        <Col span={4} onClick={onClickLogo}>
                            <img style={{ borderWidth: 1 }} src={donguLogo} alt="dongu-logo" className="header-logo" />
                        </Col>
                        <Col span={20}>
                            <Row align="middle" justify="end" gutter={([20], [32])} wrap={false}>
                                {!lg ? (
                                    <>
                                        <Col flex="auto">
                                            <Menu
                                                theme="light"
                                                mode="horizontal"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                }}>
                                                {HeaderNavItems(userRole).map(({ id, title, path, hideOnSmallScreens, hideOnLargeScreens }) => (
                                                    <CustomMenuItem
                                                        className={
                                                            // eslint-disable-next-line no-nested-ternary
                                                            hideOnSmallScreens
                                                                ? 'hideable-item'
                                                                : hideOnLargeScreens
                                                                ? 'hideable-item--large-screen'
                                                                : ''
                                                        }
                                                        style={userData ? { margin: '0 20px' } : {}}
                                                        onClick={() => {
                                                            history.push(path);
                                                            window.scrollTo(0, 0);
                                                        }}
                                                        key={id}>
                                                        <CustomMenuText
                                                            style={{
                                                                backgroundColor: currentURL === path ? '#F5F9FC' : 'transparent',
                                                            }}>
                                                            {title}
                                                        </CustomMenuText>
                                                    </CustomMenuItem>
                                                ))}
                                                {userData ? (
                                                    <Menu.Item>
                                                        <Profile menuItems={menuItems} basketList={basketList} />
                                                    </Menu.Item>
                                                ) : (
                                                    <Menu.Item>
                                                        <SignUpButton
                                                            className="btn-size"
                                                            type="secondaryReverse"
                                                            htmlType="submit"
                                                            onClick={() => history.push('/giris')}>
                                                            Giriş Yap
                                                        </SignUpButton>
                                                    </Menu.Item>
                                                )}
                                                {!userData ? (
                                                    <Menu.Item>
                                                        <SignInButton
                                                            className="btn-size"
                                                            type="secondary"
                                                            htmlType="submit"
                                                            onClick={() => history.push('/uye-ol')}>
                                                            Üye Ol
                                                        </SignInButton>
                                                    </Menu.Item>
                                                ) : (
                                                    <div />
                                                )}
                                            </Menu>
                                        </Col>

                                        <Col flex="none">
                                            <LocalizationAndProfileGroup align="middle" justify="end">
                                                <Space direction="horizontal" size="large">
                                                    <GuestComponent history={history} />
                                                </Space>
                                            </LocalizationAndProfileGroup>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        {/* The following condition will be edited according to where the user is coming from */}
                                        {!userData && window.innerWidth > '576' && (
                                            <Menu style={{ alignItems: 'center', display: 'flex', border: 'none' }} mode="horizontal">
                                                <Menu.Item style={{ height: 60 }}>
                                                    <SignUpButton
                                                        className="btn-size"
                                                        type="secondaryReverse"
                                                        htmlType="submit"
                                                        onClick={() => history.push('/giris')}>
                                                        Giriş Yap
                                                    </SignUpButton>
                                                </Menu.Item>
                                                <Menu.Item style={{ height: 60, marginLeft: 0 }}>
                                                    <SignInButton
                                                        className="btn-size"
                                                        type="secondary"
                                                        htmlType="submit"
                                                        onClick={() => history.push('/uye-ol')}>
                                                        Üye Ol
                                                    </SignInButton>
                                                </Menu.Item>
                                            </Menu>
                                        )}
                                        <CustomButton onClick={openDrawer}>
                                            <img src={menu} alt="menu" />
                                        </CustomButton>
                                    </>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </StyledHeader>
    );
};

export default Header;
