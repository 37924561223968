import { message } from 'antd';
import * as actionTypes from './actionTypes';

// PATCH USER
export const patchUserStartAction = () => ({
    type: actionTypes.PATCH_USER,
});

export const patchUserSuccessAction = (data) => {
    message.success('Bilgilerinizi başarıyla güncellediniz!');
    return {
        type: actionTypes.PATCH_USER_SUCCESS,
        payload: data,
    };
};

export const patchUserFailAction = (error) => {
    message.error('Güncelleştirme başarısız');
    return {
        type: actionTypes.PATCH_USER_FAIL,
        payload: error,
    };
};

// GET USER
export const getUserStartAction = () => ({
    type: actionTypes.GET_USER,
});

export const getUserSuccessAction = (data) => ({
    type: actionTypes.GET_USER_SUCCESS,
    payload: data,
});

export const getUserFailAction = (error) => ({
    type: actionTypes.GET_USER_FAIL,
    payload: error,
});

// PATCH USER
export const patchPasswordStartAction = () => ({
    type: actionTypes.PATCH_PASSWORD,
});

export const patchPasswordSuccessAction = (data) => {
    message.success('Şifreniz güncellendi!');
    return {
        type: actionTypes.PATCH_PASSWORD_SUCCESS,
        payload: data,
    };
};

export const patchPasswordFailAction = (error) => {
    message.error('Güncelleştirme başarısız');
    return {
        type: actionTypes.PATCH_PASSWORD_FAIL,
        payload: error,
    };
};

// RESET PASSWORD
export const postPasswordResetStartAction = () => ({
    type: actionTypes.POST_PASSWORD_RESET,
});

export const postPasswordResetSuccessAction = (data) => {
    message.success('Bağlantı linki mailinize gönderildi!');
    return {
        type: actionTypes.POST_PASSWORD_RESET_SUCCESS,
        payload: data,
    };
};

export const postPasswordResetFailAction = (error) => {
    message.error('Sıfırlama Başarısız');
    return {
        type: actionTypes.POST_PASSWORD_RESET_FAIL,
        payload: error,
    };
};

// SET PASSWORD
export const postPasswordSetStartAction = () => ({
    type: actionTypes.POST_PASSWORD_SET,
});

export const postPasswordSetSuccessAction = (data) => {
    message.success('Şifreniz değiştirildi!');
    return {
        type: actionTypes.POST_PASSWORD_SET_SUCCESS,
        payload: data,
    };
};

export const postPasswordSetFailAction = (error) => {
    message.error('Sıfırlama Başarısız');
    return {
        type: actionTypes.POST_PASSWORD_SET_FAIL,
        payload: error,
    };
};

// POST ADDRESS
export const postAddressStartAction = () => ({
    type: actionTypes.POST_ADDRESS,
});

export const postAddressSuccessAction = (data) => {
    message.success('Adresiniz eklendi!');
    return {
        type: actionTypes.POST_ADDRESS_SUCCESS,
        payload: data,
    };
};

export const postAddressFailAction = (error) => {
    message.error('Adres eklenemedi!');
    return {
        type: actionTypes.POST_ADDRESS_FAIL,
        payload: error,
    };
};

// PUT ADDRESS
export const putAddressStartAction = () => ({
    type: actionTypes.PUT_ADDRESS,
});

export const putAddressSuccessAction = (data) => {
    message.success('Adres güncellendi!');
    return {
        type: actionTypes.PUT_ADDRESS_SUCCESS,
        payload: data,
    };
};

export const putAddressFailAction = (error) => {
    message.error('Adres Güncelleme Başarısız!');
    return {
        type: actionTypes.PUT_ADDRESS_FAIL,
        payload: error,
    };
};

// GET ADDRESS
export const getAddressStartAction = () => ({
    type: actionTypes.GET_ADDRESS,
});

export const getAddressSuccessAction = (data) => ({
    type: actionTypes.GET_ADDRESS_SUCCESS,
    payload: data,
});

export const getAddressFailAction = (error) => ({
    type: actionTypes.GET_ADDRESS_FAIL,
    payload: error,
});

// GET ACTIVE ADDRESS
export const getAddressActiveStartAction = () => ({
    type: actionTypes.GET_ADDRESS_ACTIVE,
});

export const getAddressActiveSuccessAction = (data) => ({
    type: actionTypes.GET_ADDRESS_ACTIVE_SUCCESS,
    payload: data,
});

export const getAddressActiveFailAction = (error) => ({
    type: actionTypes.GET_ADDRESS_ACTIVE_FAIL,
    payload: error,
});

// POST ACTIVE ADDRESS
export const postAddressActiveStartAction = () => ({
    type: actionTypes.POST_ADDRESS_ACTIVE,
});

export const postAddressActiveSuccessAction = (data) => {
    message.success('Aktif Adres güncellendi!');
    return {
        type: actionTypes.POST_ADDRESS_ACTIVE_SUCCESS,
        payload: data,
    };
};

export const postAddressActiveFailAction = (error) => {
    message.error('Aktif Adres güncellenemedi!');
    return {
        type: actionTypes.POST_ADDRESS_ACTIVE_FAIL,
        payload: error,
    };
};

// DELETE ADDRESS
export const deleteAddressStartAction = () => ({
    type: actionTypes.DELETE_ADDRESS,
});

export const deleteAddressSuccessAction = (data) => {
    message.success('Adresiniz silindi!');
    return {
        type: actionTypes.DELETE_ADDRESS_SUCCESS,
        payload: data,
    };
};

export const deleteAddressFailAction = (error) => {
    message.error('Adres Silme Başarısız!');
    return {
        type: actionTypes.DELETE_ADDRESS_FAIL,
        payload: error,
    };
};

// GET ORDER HISTORY

export const getOrderHistoryStartAction = () => ({
    type: actionTypes.GET_ORDER_HISTORY,
});

export const getOrderHistorySuccessAction = (data) => ({
    type: actionTypes.GET_ORDER_HISTORY_SUCCESS,
    payload: data,
});

export const getOrderHistoryFailAction = (data) =>
    // message.error('Siparişler alınamadı!');
    ({ type: actionTypes.GET_ORDER_HISTORY_FAIL, payload: data });

// DELETE ORDER

export const deleteOrderStartAction = () => ({
    type: actionTypes.DELETE_ORDER,
});

export const deleteOrderSuccessAction = (data) => {
    message.success('Sipariş iptal edildi!');
    return { type: actionTypes.DELETE_ORDER_SUCCESS, payload: data };
};

export const deleteOrderFailAction = (error) => {
    message.error('Sipariş İptali başarısız oldu!');
    return { type: actionTypes.DELETE_ORDER_FAIL, payload: error };
};

// COMPLETE ORDER

export const completeOrderStartAction = () => ({
    type: actionTypes.COMPLETE_ORDER,
});

export const completeOrderSuccessAction = (data) => {
    message.success('Sipariş tamamlandı!');
    return { type: actionTypes.COMPLETE_ORDER_SUCCESS, payload: data };
};

export const completeOrderFailAction = (error) => {
    message.error('Siparişin tamamlanması başarısız oldu!');
    return { type: actionTypes.COMPLETE_ORDER_FAIL, payload: error };
};

// RESET DELETE ORDER ERROR

export const resetDeleteOrderError = () => ({
    type: actionTypes.RESET_DELETE_ORDER_ERROR,
});
