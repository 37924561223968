/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderDiv, StyledDiv } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import BackGreenArrow from '../../../../../../../Assets/Shared/RestaurantDetailPage/BackGreenArrow.svg';
import Spinner from '../../../../../../components/Spinner';

function Index() {
    const history = useHistory();
    window.scrollTo(0, 0);
    const selector = useSelector;
    const storeData = selector((state) => state?.storeStore?.storeData);

    if (!storeData) return <Spinner />;
    return (
        <>
            <HeaderDiv>
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                    <Col span={2}>
                        <img src={BackGreenArrow} alt="back" onClick={() => history.goBack()} />
                    </Col>
                    <Col span={22} align="center">
                        <Text weight="light" style={{ textAlign: 'center', fontSize: '18px' }}>
                            Mağaza Bilgisi
                        </Text>
                    </Col>
                </Row>
            </HeaderDiv>
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    marginTop: 80,
                    paddingTop: 27,
                    minHeight: '100vh',
                    backgroundColor: '#F5F9FC',
                }}>
                <div
                    style={{
                        width: '100%',
                        backgroundColor: '#FFFFFF',
                        padding: '10px 32px 10px 32px',
                    }}>
                    <StyledDiv>
                        <Row>
                            <Text weight="light" size="small">
                                İşletme Adı
                            </Text>
                        </Row>
                        <Row>
                            <Text weight="medium" size="description">
                                {storeData?.name}
                            </Text>
                        </Row>
                    </StyledDiv>
                    <StyledDiv>
                        <Row>
                            <Text weight="light" size="small">
                                Adres
                            </Text>
                        </Row>
                        <Row>
                            <Text weight="medium" size="description">
                                {storeData?.address}
                            </Text>
                        </Row>
                    </StyledDiv>
                    <StyledDiv>
                        <Row>
                            <Text weight="light" size="small">
                                Web Sitesi
                            </Text>
                        </Row>
                        <Row>
                            <Text weight="medium" size="description">
                                {storeData?.website_link}
                            </Text>
                        </Row>
                    </StyledDiv>
                    <StyledDiv>
                        <Row>
                            <Text weight="light" size="small">
                                E-mail
                            </Text>
                        </Row>
                        <Row>
                            <Text weight="medium" size="description">
                                {storeData?.email}
                            </Text>
                        </Row>
                    </StyledDiv>
                    <StyledDiv>
                        <Row>
                            <Text weight="light" size="small">
                                Telefon
                            </Text>
                        </Row>
                        <Row>
                            <Text weight="medium" size="description">
                                {storeData?.phone_number?.replace(/(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
                            </Text>
                        </Row>
                    </StyledDiv>
                    {storeData?.phone_number_2 && (
                        <div>
                            <Row>
                                <Text weight="light" size="small">
                                    Telefon 2
                                </Text>
                            </Row>
                            <Row>
                                <Text weight="medium" size="description">
                                    {storeData?.phone_number_2?.replace(/(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
                                </Text>
                            </Row>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Index;
