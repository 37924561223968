import { tryAuth } from '../../auth/api';
import * as actions from './actions';

export const postUser = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postUserStartAction(dispatch, state, service));
    return service({
        path: `user/`,
        method: 'POST',
        body: newInfo,
    })
        .then((data) => {
            dispatch(actions.postUserSuccessAction(data));
            dispatch(tryAuth(data.email.toLowerCase(), newInfo.password));
        })
        .catch((error) => {
            dispatch(actions.postUserFailAction(error));
            return Promise.reject(error);
        });
};
