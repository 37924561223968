/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Row, message } from 'antd';
import { CustomModal, TimeInputsWrapper, TextWrapper, Footer, CustomButton, CustomTimeInput, ModalDivider } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import { updateDailyPlan, getClosestSaleDate } from '../../../../../../services/Redux/Dashboard/CalendarPage/api';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);

const OrderStatusModal = (props) => {
    const {
        isPacketDeliveryLabel,
        startDate,
        endDate,
        dismiss,
        currentTimeIntervalId,
        deliveryTimeStart,
        deliveryTimeEnd,
        setDeliveryStartTime,
        setDeliveryEndTime,
    } = props;
    const startDateToRender = moment(startDate).format('HH:mm');
    const endDateToRender = moment(endDate).format('HH:mm');
    const [saleStartTime, setSaleStartTime] = useState();
    const [saleEndTime, setSaleEndTime] = useState();
    const [courierStart, setCourierStart] = useState(deliveryTimeStart || moment('00:00', 'HH:mm'));
    const [courierEnd, setCourierEnd] = useState(deliveryTimeEnd || moment('00:00', 'HH:mm'));

    const dispatch = useDispatch();
    const { submitSuccesful } = useSelector((state) => state.calendarPage);

    const success = () => {
        message.success('Değişiklikler kaydedildi');
    };
    const error = () => {
        message.error('Bir hata oluştu');
    };

    const showMessage = () => {
        if (submitSuccesful === 'false') {
            error();
        } else {
            success();
        }
    };

    useEffect(() => {
        setSaleStartTime(startDateToRender);
        setSaleEndTime(endDateToRender);
    }, [startDateToRender, endDateToRender]);

    const submitSaleTimeChanges = () => {
        const start_date = moment(startDate)
            .set({ hour: saleStartTime.split(':')[0], minute: saleStartTime.split(':')[1] })
            .format('YYYY-MM-DDTHH:mm:ssZ');
        const end_date = moment(endDate)
            .set({ hour: saleEndTime.split(':')[0], minute: saleEndTime.split(':')[1] })
            .format('YYYY-MM-DDTHH:mm:ssZ');

        dispatch(updateDailyPlan({ start_date, end_date }, currentTimeIntervalId));
        dispatch(getClosestSaleDate());
        dismiss();
        showMessage();
    };

    const submitDeliveryTimeChanges = async () => {
        setDeliveryStartTime(courierStart);
        setDeliveryEndTime(courierEnd);
        dismiss();
    };

    return isPacketDeliveryLabel ? (
        <CustomModal title={<Title text="Satış Saatlerini Ayarla" />} {...props}>
            <Col className="spacebetween" span={24}>
                <TimeInputsWrapper>
                    <Col span={12}>
                        <Row align="middle" justify="center">
                            <Text weight="semi-bold">Satış saati başlangıç</Text>
                        </Row>
                        <Row style={{ marginTop: '20px' }} align="middle" justify="center">
                            <CustomTimeInput
                                defaultValue={moment(startDateToRender, 'HH:mm')}
                                format="HH:mm"
                                onChange={(value) => setSaleStartTime(value._d?.toString().substring(16, 21))}
                                bordered
                                placeholder="HH:mm"
                            />
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row align="middle" justify="center">
                            <Text weight="semi-bold">Satış saati bitiş</Text>
                        </Row>
                        <Row style={{ marginTop: '20px' }} align="middle" justify="center">
                            <CustomTimeInput
                                defaultValue={moment(endDateToRender, 'HH:mm')}
                                format="HH:mm"
                                onChange={(value) => setSaleEndTime(value._d?.toString().substring(16, 21))}
                                bordered
                                placeholder="HH:mm"
                            />
                        </Row>
                    </Col>
                </TimeInputsWrapper>
                <TextWrapper>
                    <Text style={{ textAlign: 'center' }} family="secondary" color="smoke">
                        Satış zamanını değiştirmek istediğinizden emin misiniz? Satın alma işlemini gerçekleştiren tüm müşteriler
                        bilgilendirilecektir.
                    </Text>
                </TextWrapper>
                <Footer
                    gutter={[
                        { xs: 8, md: 8, xl: 16 },
                        { xs: 8, sm: 8, md: 16, lg: 16, xl: 16 },
                    ]}
                    align="middle"
                    justify="space-between">
                    <Col span={12}>
                        <CustomButton type="quaternary" onClick={() => dismiss()}>
                            <Text weight="semi-bold">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col span={12}>
                        <CustomButton type="secondary" onClick={() => submitSaleTimeChanges()}>
                            <Text color="white" weight="semi-bold">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Footer>
            </Col>
        </CustomModal>
    ) : (
        <CustomModal title={<Title text="Kurye Teslim Saatlerini Ayarla" />} {...props}>
            <Col className="spacebetween" span={24}>
                <Row align="middle" justify="center">
                    <Col span={12} align="center">
                        <Text weight="semi-bold">Teslim saati başlangıç</Text>
                    </Col>
                    <Col span={12} align="center">
                        <Text weight="semi-bold">Teslim saati bitiş</Text>
                    </Col>
                </Row>
                <Row align="middle" justify="center">
                    <Col span={12} align="center">
                        <CustomTimeInput
                            defaultValue={moment(deliveryTimeStart, 'HH:mm')}
                            value={moment(courierStart, 'HH:mm')}
                            format="HH:mm"
                            onChange={(value) => setCourierStart(value._d?.toString().substring(16, 21))}
                            suffixIcon={false}
                            allowClear={false}
                            bordered
                            placeholder="HH:mm"
                        />
                    </Col>
                    <Col span={12} align="center">
                        <CustomTimeInput
                            defaultValue={moment(deliveryTimeEnd, 'HH:mm')}
                            value={moment(courierEnd, 'HH:mm')}
                            format="HH:mm"
                            onChange={(value) => setCourierEnd(value._d?.toString().substring(16, 21))}
                            suffixIcon={false}
                            allowClear={false}
                            bordered
                            placeholder="HH:mm"
                        />
                    </Col>
                </Row>
                <ModalDivider />
                <TextWrapper>
                    <Text style={{ textAlign: 'center', fontSize: '16px', lineHeight: '26px' }} family="secondary" color="smoke" weight="regular">
                        Teslim alma zamanını değiştirmek istediğinizden emin misiniz? Satın alma işlemini gerçekleştiren tüm müşteriler
                        bilgilendirilecektir.
                    </Text>
                </TextWrapper>
                <Footer
                    gutter={[
                        { xs: 8, md: 8, xl: 16 },
                        { xs: 8, sm: 8, md: 16, lg: 16, xl: 16 },
                    ]}
                    align="middle"
                    justify="space-between">
                    <Col span={12}>
                        <CustomButton type="quaternary" onClick={() => dismiss()}>
                            <Text weight="semi-bold">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col span={12}>
                        <CustomButton type="secondary" onClick={() => submitDeliveryTimeChanges()}>
                            <Text color="white" weight="semi-bold">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Footer>
            </Col>
        </CustomModal>
    );
};

export default OrderStatusModal;
