import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomModal } from './styles';

const ThreeDModal = (props) => {
    const [responseThreeD, setResponseThreeD] = useState('');
    const { setComplete, onCancel, count, setCount, visible, setThreeD } = props;
    const { createOrderResp } = useSelector((state) => state.customerOrderStore);

    useEffect(() => {
        if (createOrderResp) {
            const { threeDSHtmlContent } = createOrderResp;

            if (threeDSHtmlContent) {
                const base64toString = Buffer.from(threeDSHtmlContent, 'base64').toString();
                setResponseThreeD(base64toString);
            }
        }
    }, [createOrderResp]);

    const handleLoad = () => {
        if (count < 12) {
            setCount(count + 1);
        } else {
            onCancel(true);
            setComplete(true);
        }
    };

    return (
        <>
            {responseThreeD !== '' && (
                <CustomModal closable={false} visible={visible} setThreeD={setThreeD} width="520px" height="870px">
                    <iframe
                        srcDoc={responseThreeD}
                        id="paymet-frame"
                        title="test"
                        width="470"
                        height="650"
                        frameBorder={0}
                        style={{ marginTop: '10px' }}
                        onLoad={handleLoad}
                    />
                </CustomModal>
            )}
        </>
    );
};

export default ThreeDModal;
