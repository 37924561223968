import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../components/Button';

export const CustomRow = styled(Row)`
    width: 100%;

    &.bagli-hesap {
        margin-left: 29px;
        margin-top: 50px;
    }

    &.fb-row {
        margin-left: 29px;
        margin-top: 20px;
    }
`;

export const CustomButton = styled(Button)`
    width: 178px;
    margin-left: auto;
`;

export const CustomContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 450px;
`;
