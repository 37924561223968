/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomCol, CustomRow, CustomText, Footer, NavButton } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import Spinner from '../../../../../components/Spinner';
import OrderCard from './OrderCard';
import { getOrderHistory } from '../../../../../../services/Redux/Shared/profile/api';
import leftArrow from '../../../../../../Assets/Dashboard/left-arrow.png';
import rightArrow from '../../../../../../Assets/Dashboard/right-arrow.png';

const OrdersPane = () => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pastOrders, setPastOrders] = useState([]);
    const [activeOrders, setActiveOrders] = useState([]);
    const [count] = useState(7);

    useEffect(() => {
        dispatch(getOrderHistory());
    }, []);

    const storeData = selector((state) => state?.profileStore?.orderHistory);

    const { loading } = selector((state) => state?.profileStore);

    useEffect(() => {
        if (loading === false && storeData) {
            setPastOrders(
                storeData
                    ?.filter(({ status }) => ['4', '5', '6'].includes(status))
                    ?.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time))
                    ?.slice(0, 7)
            );
            setActiveOrders(
                storeData
                    ?.filter(({ status }) => ['1', '2', '3', '9'].includes(status))
                    ?.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time))
            );
            if (storeData?.length > 7) setPageCount(Math.ceil(storeData?.length / 7));
            else setPageCount(1);
        }
    }, [storeData, loading]);

    const prevPage = () => {
        const slice = storeData.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time)).slice(count * (page - 2), count * (page - 1));

        if (slice.length !== 0) {
            setPastOrders(slice);
            setPage(page - 1);
        }
    };

    const nextPage = () => {
        const slice = storeData.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time)).slice(count * page, count * (page + 1));

        if (slice.length !== 0) {
            setPastOrders(slice);
            setPage(page + 1);
        }
    };

    if (loading) return <Spinner />;
    return storeData?.length > 0 ? (
        <CustomRow className="main-row">
            <CustomRow>
                <Text weight="light" color="#707070">
                    Siparişlerim bölümünden daha önce verdiğiniz siparişleri görebilir; servis, kalite ve lezzetine göre puanlayabilirsiniz.
                </Text>
            </CustomRow>

            <CustomRow className="bottom-row" style={{ marginTop: '2rem' }}>
                <CustomRow>
                    <Text weight="light" color="#707070">
                        Aktif Siparişlerim
                    </Text>
                </CustomRow>
                <CustomRow className="th-row">
                    <Col span={8}>
                        <Text weight="semi-bold" size="14px">
                            Restoran:
                        </Text>
                    </Col>
                    <Col span={6}>
                        <Text weight="semi-bold" size="14px">
                            Sipariş <CustomText className="no-text--long">Numarası</CustomText>
                            <CustomText className="no-text--short">No</CustomText>
                            /Detayı:
                        </Text>
                    </Col>
                    <Col span={6}>
                        <Text weight="semi-bold" size="14px">
                            Sipariş Yeri/Tarihi:
                        </Text>
                    </Col>
                    <CustomCol span={4} className="tutar-container">
                        <Text weight="semi-bold" size="14px">
                            Tutar:
                        </Text>
                    </CustomCol>
                </CustomRow>
                <CustomRow className="card-row">
                    {activeOrders && activeOrders?.length > 0 ? (
                        activeOrders?.map((orderDetails, i) => <OrderCard orderDetails={orderDetails} key={orderDetails.id} index={i} />)
                    ) : (
                        <Col
                            span={24}
                            style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0', backgroundColor: '#8fd89f', opacity: '0.2' }}>
                            <Text>Aktif Siparişiniz Bulunmamaktadır!</Text>
                        </Col>
                    )}
                </CustomRow>
            </CustomRow>

            <CustomRow className="bottom-row" style={{ marginTop: '2rem' }}>
                <CustomRow>
                    <Text weight="light" color="#707070">
                        Geçmiş Siparişlerim
                    </Text>
                </CustomRow>
                <CustomRow className="th-row">
                    <Col span={8}>
                        <Text weight="semi-bold" size="14px">
                            Restoran:
                        </Text>
                    </Col>
                    <Col span={6}>
                        <Text weight="semi-bold" size="14px">
                            Sipariş <CustomText className="no-text--long">Numarası</CustomText>
                            <CustomText className="no-text--short">No</CustomText>
                            /Detayı:
                        </Text>
                    </Col>
                    <Col span={6}>
                        <Text weight="semi-bold" size="14px">
                            Sipariş Yeri/Tarihi:
                        </Text>
                    </Col>
                    <CustomCol span={4} className="tutar-container">
                        <Text weight="semi-bold" size="14px">
                            Tutar:
                        </Text>
                    </CustomCol>
                </CustomRow>
                <CustomRow className="card-row">
                    {pastOrders && pastOrders?.length !== 0 ? (
                        pastOrders?.map((orderDetails, i) => <OrderCard orderDetails={orderDetails} key={orderDetails.id} index={i} />)
                    ) : (
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
                            <Text>Geçmiş Siparişiniz Bulunmamaktadır!</Text>
                        </Col>
                    )}
                </CustomRow>
            </CustomRow>
            <Footer align="middle" justify="center">
                {page !== 1 && (
                    <NavButton
                        src={leftArrow}
                        alt="left-arrow"
                        onClick={() => {
                            if (page !== 1) {
                                prevPage();
                            }
                        }}
                    />
                )}
                <Text>
                    {page} / {pageCount}
                </Text>
                {page !== pageCount && <NavButton src={rightArrow} alt="right-arrow" onClick={() => nextPage()} />}
            </Footer>
        </CustomRow>
    ) : (
        <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
            <Text>Siparişiniz Bulunmamaktadır!</Text>
        </Col>
    );
};

export default OrdersPane;
