/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { CustomRow, CustomCol, CustomImg } from './styles.js';
import { Text } from '../../../../../../../components/Typography/styles.js';
import PacketGreen from '../../../../../../../../Assets/Shared/CartPage/green-packet.svg';
import MotorWhite from '../../../../../../../../Assets/Shared/FindRestaurant/motorwhite.svg';
import { ReactComponent as Star } from '../../../../../../../../Assets/Shared/FindRestaurant/star.svg';
import MotorGreen from '../../../../../../../../Assets/Shared/CartPage/green-motor.svg';
import PacketWhite from '../../../../../../../../Assets/Shared/FindRestaurant/packagewhite.svg';
import { ReactComponent as ForwardBtn } from '../../../../../../../../Assets/Shared/CartPage/forward-arrow.svg';
import Maps from '../../../../../Favourites/Maps/index.jsx';

const RestaurantRow = () => {
    const [showMap, setShowMap] = React.useState();
    const history = useHistory();
    const selector = useSelector;
    const store = selector((state) => state?.storeStore);

    const { storeData } = store || {};
    const basket = selector((state) => state?.customerOrderStore?.basketList);
    const toggleMap = () => setShowMap(!showMap);
    const position = basket[0]?.store;

    return (
        <CustomRow>
            <CustomRow justify="space-between">
                <Col>
                    <Text weight="semi-bold">Teslim Alınacak Adres</Text>
                </Col>
                <Col>
                    <Button onClick={toggleMap}>
                        <Text weight="semi-bold" size="14px" color="red">
                            {showMap ? 'Haritayı gizle' : 'Haritada göster'}
                        </Text>
                    </Button>
                </Col>
            </CustomRow>
            <CustomRow>
                {showMap && (
                    <div style={{ width: '100%', height: '500px', position: 'relative' }}>
                        <Maps storeData="" user={position} />
                    </div>
                )}
            </CustomRow>
            <CustomRow align="middle" className="card-row">
                <CustomCol className="left-col" span={13}>
                    <CustomRow align="middle">
                        <Col span={5}>
                            <CustomCol span={19}>
                                <CustomImg src={basket[0]?.store?.photo} alt="Logo" width="75px" height="45px" />
                            </CustomCol>
                        </Col>
                        <Col span={15}>
                            <CustomRow align="middle" className="name-row">
                                <Col>
                                    <Text weight="semi-bold">{basket[0]?.store?.name}&nbsp;&nbsp;</Text>
                                </Col>
                                <Col>
                                    <Star />
                                </Col>
                                <Col>
                                    {basket[0].store.review && basket[0].store.review.length >= 5 ? (
                                        <Text weight="light">&nbsp;{Number(basket[0]?.store?.avg_review).toFixed(2)}</Text>
                                    ) : (
                                        <Text weight="light">&nbsp;5</Text>
                                    )}
                                </Col>
                            </CustomRow>
                            <CustomRow className="name-row">
                                <Text weight="light" color="smoke" size="14px">
                                    {basket[0]?.store?.address}
                                </Text>
                            </CustomRow>
                        </Col>
                    </CustomRow>
                </CustomCol>
                <CustomCol className="right-col" span={11}>
                    <CustomRow className="icon-row" align="end">
                        <Col span={10}>
                            {storeData?.package_settings?.delivery_type === '1' ? (
                                <Row justify="center">
                                    <img src={PacketGreen} alt="packet-icon" />
                                    &nbsp;&nbsp;
                                    <img src={MotorWhite} alt="packet-icon" />
                                </Row>
                            ) : (
                                <Row justify="center">
                                    {storeData?.package_settings?.delivery_type === '2' ? (
                                        <img src={PacketWhite} alt="packet-icon" />
                                    ) : (
                                        <img src={PacketGreen} alt="packet-icon" />
                                    )}
                                    &nbsp;&nbsp;
                                    <img src={MotorGreen} alt="packet-icon" />
                                </Row>
                            )}
                        </Col>

                        <CustomCol className="icon-col" span={4}>
                            <ForwardBtn onClick={() => history.push(`restoran-detay/${basket[0].store.id}`)} />
                        </CustomCol>
                    </CustomRow>
                </CustomCol>
            </CustomRow>
        </CustomRow>
    );
};

export default RestaurantRow;
