export const MONTHLY = [
    { name: 'Ocak', box: 0 },
    { name: 'Şubat', box: 0 },
    { name: 'Mart', box: 0 },
    { name: 'Nisan', box: 0 },
    { name: 'Mayıs', box: 0 },
    { name: 'Haziran', box: 0 },
    { name: 'Temmuz', box: 0 },
    { name: 'Ağustos', box: 0 },
    { name: 'Eylül', box: 0 },
    { name: 'Ekim', box: 0 },
    { name: 'Kasım', box: 0 },
    { name: 'Aralık', box: 0 },
];

export const WEEKLY = [
    { name: 'Pazartesi', box: 0 },
    { name: 'Salı', box: 0 },
    { name: 'Çarşamba', box: 0 },
    { name: 'Perşembe', box: 0 },
    { name: 'Cuma', box: 0 },
    { name: 'Cumartesi', box: 0 },
    { name: 'Pazar', box: 0 },
];
