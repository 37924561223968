/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Col, Row } from 'antd';
import React from 'react';
import { formatDate } from '../../../../../../../helpers';
import { Text } from '../../../../../../components/Typography/styles';
import Onemli from '../../../../../../../Assets/Shared/CartPage/onemli.png';
import ArrowRight from '../../../../../../../Assets/Shared/Profile/arrowRight.svg';
import { StyledDiv, DetailsDiv, StyledPrice, StyledWarning } from './styles';

function Index({ order, setOrderToDisplay }) {
    const activeStatus = ['1', '2', '3', '9'];
    const cancelledStatus = ['4', '5'];
    // const pastStatus = ['6'];

    const buyingTimeFormatted = formatDate(order?.buying_time);

    return (
        <StyledDiv
            style={{
                backgroundColor: activeStatus?.includes(order?.status) ? '#8FD89F' : cancelledStatus?.includes(order?.status) ? '#FEEFEF' : '#FFFFFF',
            }}>
            <Row style={{ minWidth: '100%' }}>
                <Col span={16}>
                    <Row>
                        <Text weight="light" size="small">
                            {order?.address?.name} - {buyingTimeFormatted?.date} {buyingTimeFormatted?.time}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="medium" size="description">
                            {order?.store?.name}
                        </Text>
                    </Row>
                </Col>
                <Col span={7}>
                    <DetailsDiv>
                        <StyledPrice span={16}>{order?.cost} TL</StyledPrice>
                        <Col span={8}>
                            <img src={ArrowRight} alt="details" onClick={() => setOrderToDisplay(order)} />
                        </Col>
                    </DetailsDiv>
                </Col>
                {cancelledStatus.includes(order.status) && (
                    <Col span={24}>
                        <span>
                            <img src={Onemli} alt="sup" />
                        </span>
                        <StyledWarning>Tanımlanmış Paketin İptal Edildi.</StyledWarning>
                    </Col>
                )}
            </Row>
        </StyledDiv>
    );
}

export default Index;
