import styled from 'styled-components';
import { Row } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.margin-t-35 {
        margin-top: 35px;
    }

    &.margin-t-20 {
        margin-top: 20px;
    }

    &.margin-t-10 {
        margin-top: 10px;
    }

    &.time-left-row {
        margin-top: 40px;
    }

    &.timer {
        border-top: 2px solid #f2f2fc;
        border-bottom: 2px solid #f2f2fc;
        height: 90px;
        width: 90%;
    }
`;
