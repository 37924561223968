import React from 'react';
import { MenuSettingsPageStyles, LeftSide, RightSide } from './styles';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import MenuSettingsCard from './MenuSettingsCard';
import MenuPreviewCard from './MenuPreviewCard';
import LabelsCard from './LabelsCard';

const MenuSettingsPage = () => {
    const { xs, sm, md, lg } = useResponsive();

    return (
        <MenuSettingsPageStyles>
            <LeftSide xs={24} sm={24} md={24} lg={24} xl={15}>
                <MenuSettingsCard />
            </LeftSide>
            <RightSide className={xs || sm || md || (lg && 'responsive-margin')} xs={24} sm={24} md={24} lg={24} xl={8}>
                <MenuPreviewCard />
                <LabelsCard />
            </RightSide>
        </MenuSettingsPageStyles>
    );
};

export default MenuSettingsPage;
