import styled from 'styled-components';
import { Modal, Row } from 'antd';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        height: 30px;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .ant-modal-body {
        padding: 0px !important;
        overflow-y: scroll;
        height: 500px !important;
        min-width: 370px !important;

        ::-webkit-scrollbar {
            width: 11px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
            border-bottom-right-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }
`;

export const MenuWrapper = styled(Row)``;

export const SearchBarWrapper = styled(Row)`
    padding: 15px 25px;
`;

export const Footer = styled(Row)``;
