import React, { useState } from 'react';
import moment from 'moment';
import Header, { NavButton, Content, Date, ButtonContainer, Button, LeftPart } from './styles';
import LeftArrow from '../../../../../../Assets/Dashboard/left-arrow.png';
import RightArrow from '../../../../../../Assets/Dashboard/right-arrow.png';

const CalendarHeader = ({ showMonths, setMonth, month, setWeek, week, value }) => {
    const months = moment.localeData().months();
    const [showWeek, setShowWeek] = useState(true);
    return (
        <Header>
            <Content style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Content sm={{ span: 5, offset: 1 }} md={6}>
                    <LeftPart size="title" weight="light" show={showWeek ? 1 : 0}>
                        {`${months[value.month()]} ${value.year()}`}
                    </LeftPart>
                </Content>
                <Content sm={{ span: 9 }} md={10}>
                    <NavButton
                        src={LeftArrow}
                        alt="left-arrow"
                        onClick={() => {
                            if (showWeek) {
                                setWeek(week - 1);
                            } else {
                                setMonth(month - 1);
                            }
                        }}
                    />
                    <Date size="title" weight="light">
                        {!showWeek ? `${months[value.month()]} ${value.year()}` : `${value.week()}. Hafta`}
                    </Date>
                    <NavButton
                        src={RightArrow}
                        alt="right-arrow"
                        onClick={() => {
                            if (showWeek) {
                                setWeek(week + 1);
                            } else {
                                setMonth(month + 1);
                            }
                            // changeMonth(date.add(1, 'month'));
                        }}
                    />
                </Content>
                <Content sm={{ span: 8, offset: 1 }} md={{ span: 7, offset: 1 }}>
                    <ButtonContainer>
                        <Button
                            ghost={showWeek}
                            onClick={() => {
                                setShowWeek(true);
                                showMonths(false);
                            }}>
                            Haftalık
                        </Button>
                        <Button
                            ghost={!showWeek}
                            onClick={() => {
                                setShowWeek(false);
                                showMonths(true);
                            }}>
                            Aylık
                        </Button>
                    </ButtonContainer>
                </Content>
            </Content>
        </Header>
    );
};

export default CalendarHeader;
