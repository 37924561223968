/* eslint-disable camelcase */
/* eslint-disable sonarjs/no-identical-functions */

import React, { useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PacketInfoCardStyles, Content, PacketSection, UpperContent, RightSide, LeftSide } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { fetchRestaurantInfo } from '../../../../../services/Redux/Dashboard/restaurantInfo/api';
import { fetchFavouriteMeals } from '../../../../../services/Redux/Dashboard/favourites/api';
import Favourites from './Favourites';
import MinPacketPrice from './MinPacketPrice';
import Packet from './Packet';

const { TabPane } = Tabs;

const Title = ({ text, weight }) => (
    <Row align="middle" justify="center">
        <Text size="regular" weight={weight || 'light'}>
            {text}
        </Text>
    </Row>
);

const PacketInfoCard = ({ boxList, startDate }) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const [restaurantInfoToRender, setRestaurantInfoToRender] = useState();
    const [activePackage, setActivePackage] = useState([]);

    useEffect(() => {
        dispatch(fetchRestaurantInfo());
        dispatch(fetchFavouriteMeals());
    }, [dispatch]);

    const { restaurantInfo } = selector((state) => state?.businessInfoStore);
    const { loading } = selector((state) => state?.businessInfoStore);

    useEffect(() => {
        setRestaurantInfoToRender(restaurantInfo);
        setActivePackage(boxList?.filter(({ sold }) => sold));
    }, [restaurantInfo, boxList]);

    function totalPrice(total, num) {
        return total + num.price;
    }

    return (
        <Tabs defaultActiveKey="1" centered>
            <TabPane tab={<Title text="Paket Bilgisi" weight="semi-bold" />} key="1">
                <PacketInfoCardStyles title={<Title text="Paket Bilgisi" />}>
                    <Content span={24}>
                        <UpperContent>
                            <LeftSide span={17}>
                                <Favourites />
                            </LeftSide>
                            <RightSide span={7}>
                                <MinPacketPrice loading={loading} restaurantInfo={restaurantInfoToRender} />
                            </RightSide>
                        </UpperContent>
                        <PacketSection
                            gutter={[
                                { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                                { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                            ]}>
                            {boxList && restaurantInfoToRender ? (
                                boxList
                                    ?.filter(({ sold }) => !sold)
                                    .sort((a, b) => a.id - b.id)
                                    .map(({ id, status, name, initial_stock, on_sale, meals, defined, is_surprize, sold, text_name }) => (
                                        <Col className="col-equal" xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Packet
                                                boxId={id}
                                                boxNameId={name}
                                                textName={text_name}
                                                defined={defined}
                                                initial_stock={initial_stock}
                                                on_sale={on_sale}
                                                meals={meals}
                                                sold={sold}
                                                foodPrice={meals.length !== 0 ? meals.reduce(totalPrice, 0) : 0}
                                                is_surprize={is_surprize}
                                                minPacketPrice={restaurantInfoToRender?.package_settings?.min_discounted_order_price}
                                                minOrderPrice={restaurantInfoToRender?.package_settings?.min_order_price}
                                                boxStatus={status}
                                                startDate={startDate}
                                            />
                                        </Col>
                                    ))
                            ) : (
                                <Content span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    Satışa hazır paket bulunmamaktadır.
                                </Content>
                            )}
                        </PacketSection>
                    </Content>
                </PacketInfoCardStyles>
            </TabPane>
            <TabPane tab={<Title text="Aktif Sipariş Bilgisi" weight="semi-bold" />} key="2">
                <PacketInfoCardStyles title={<Title text="Aktif Sipariş Paketleri" />}>
                    <Content span={24}>
                        <PacketSection
                            gutter={[
                                { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                                { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                            ]}>
                            {activePackage?.length > 0 ? (
                                boxList
                                    ?.filter(({ sold }) => sold)
                                    .sort((a, b) => a.id - b.id)
                                    .map(({ id, status, name, initial_stock, on_sale, meals, defined, is_surprize, sold, text_name }) => (
                                        <Col className="col-equal" xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Packet
                                                boxId={id}
                                                boxNameId={name}
                                                textName={text_name}
                                                defined={defined}
                                                initial_stock={initial_stock}
                                                on_sale={on_sale}
                                                meals={meals}
                                                sold={sold}
                                                foodPrice={meals.length !== 0 ? meals.reduce(totalPrice, 0) : 0}
                                                is_surprize={is_surprize}
                                                minPacketPrice={restaurantInfoToRender?.package_settings?.min_discounted_order_price}
                                                minOrderPrice={restaurantInfoToRender?.package_settings?.min_order_price}
                                                boxStatus={status}
                                                startDate={startDate}
                                            />
                                        </Col>
                                    ))
                            ) : (
                                <Content
                                    span={24}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '1rem',
                                    }}>
                                    Satın alınmış bir paket bulunmamaktadır.
                                </Content>
                            )}
                        </PacketSection>
                    </Content>
                </PacketInfoCardStyles>
            </TabPane>
        </Tabs>
    );
};

export default PacketInfoCard;
