import styled from 'styled-components';
import { Calendar, Row, Col, Spin as spin } from 'antd';
import { between } from 'polished';

export default styled(Calendar)`
    border-right: 2px solid ${({ theme }) => theme.borderColor};
    height: 100%;
    border-top: none;
    width: 100%;
    display: flex;
    th {
        text-align: center;
        font-size: ${between('10px', '15px')};
        font-weight: 500;
        padding: 0px !important;
    }
    tr {
        height: 60px;
        border-bottom: 2px solid ${({ theme }) => theme.borderColor};
    }
    .ant-picker-body {
        padding: 0px !important;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        tbody {
            //padding: 40px !important;
        }
    }
`;

export const Content = styled(Row)`
    display: flex;
    width: 100%;
`;

export const Item = styled(Col)`
    width: 100%;
`;

export const Spin = styled(spin)`
    margin: auto;
    min-height: 55vh;
`;
