import React from 'react';
import moment from 'moment';
import PackageStyles, { Content, Item } from './styles';
import { Text } from '../../../../../../components/Typography/styles';

const Package = ({ name, startDate, endDate }) => {
    const start = moment(endDate, 'HH-mm'); // changes endDate string to  moment object
    const [e1, e2] = start.subtract(1, 'minutes').format('HH-mm').split('-'); // subtract 1 minute and format to hh-mm

    return (
        <PackageStyles>
            <Content justify="space-between">
                <Item>
                    <Text size="noble-regular" color="green" weight="bold">
                        {name}
                    </Text>
                </Item>
                <Item>
                    <Text family="secondary">{`${startDate} - ${endDate}`}</Text>
                </Item>
            </Content>
            <Content justify="end">
                <Text fontStyle="italic" size="13px" weight="regular">
                    {`*Satışlar ${e1}:${e2}'da durur.`}
                </Text>
            </Content>
        </PackageStyles>
    );
};
export default Package;
