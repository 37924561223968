import styled from 'styled-components';
import { Row, Col } from 'antd';

export default styled(Row)`
    height: 100%;
`;

export const Item = styled(Col)``;

export const CalenderItem = styled(Col)`
    @media only screen and (max-width: 768px) {
        min-width: 800px;
    }
`;
export const Content = styled(Row)`
    width: 100%;
`;
