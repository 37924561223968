import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    userData: null,
    userError: null,
};

const postUserReducer = (state) => ({
    ...state,
    loading: true,
});

const postUserSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    userData: action.payload,
});

const postUserFailReducer = (state, action) => ({
    ...state,
    loading: false,
    userError: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_USER:
            return postUserReducer(state);
        case actionTypes.POST_USER_SUCCESS:
            return postUserSuccessReducer(state, action);
        case actionTypes.POST_USER_FAIL:
            return postUserFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
