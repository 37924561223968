/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Popover, Row, Tooltip } from 'antd';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { CustomRow, CustomButton, CustomInput, CustomCol } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { patchPassword } from '../../../../../../../services/Redux/Shared/profile/api';
import ForgotPasswordModal from './ForgotPasswordModal';
import { ReactComponent as CloseBtn } from '../../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';

const PasswordCard = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [edit, setEdit] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const dispatch = useDispatch();
    const [similarVisible, setSimilarVisible] = useState(false);
    const [matchCheck, setMatchCheck] = useState(false);
    const [passwordCheck1, setPasswordCheck1] = useState(false);
    const [passwordCheck2, setPasswordCheck2] = useState(false);
    const [passwordCheck3, setPasswordCheck3] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const updatePassword = () => {
        if (matchCheck) {
            const data = {
                old_password: currentPassword,
                new_password: newPassword,
            };
            dispatch(patchPassword(data));
            setEdit(false);
            setCurrentPassword('')
            setNewPassword('')
            setNewPassword2('')
            setMatchCheck(false)
        }
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const TooltipContent = () => (
        <div>
            <Row>
                {passwordCheck1 === true ? (
                    <Text color="green" size="10px">
                        <CheckOutlined /> 1 rakam
                    </Text>
                ) : (
                    <Text size="10px">
                        <InfoCircleOutlined /> 1 rakam
                    </Text>
                )}
            </Row>
            <Row>
                {passwordCheck2 === true ? (
                    <Text color="green" size="10px">
                        <CheckOutlined /> 1 büyük harf
                    </Text>
                ) : (
                    <Text size="10px">
                        <InfoCircleOutlined /> 1 büyük harf
                    </Text>
                )}
            </Row>
            <Row>
                {passwordCheck3 === true ? (
                    <Text color="green" size="10px">
                        <CheckOutlined /> 8 karakter
                    </Text>
                ) : (
                    <Text size="10px">
                        <InfoCircleOutlined /> 8 karakter
                    </Text>
                )}
            </Row>
        </div>
    );

    const checkPassword = (value) => {
        if (newPassword.includes(value)) {
            setSimilarVisible(false);
            setMatchCheck(false);
        } else {
            setSimilarVisible(true);
            setMatchCheck(false);
        }
        if (newPassword === value) {
            setMatchCheck(true);
            setSimilarVisible(true);
        }
        setNewPassword2(value);
    };

    const checkNewPassword = (temp) => {
        const alphabets = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ];
        const numerics = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        if (temp.length >= 8) {
            setPasswordCheck3(true);
        } else {
            setPasswordCheck3(false);
        }
        if (alphabets.some((v) => temp.includes(v))) {
            setPasswordCheck2(true);
        } else {
            setPasswordCheck2(false);
        }
        if (numerics.some((v) => temp.includes(v))) {
            setPasswordCheck1(true);
        } else {
            setPasswordCheck1(false);
        }
        setNewPassword(temp);
    };
    return (
        <CustomRow className="card-body">
            <CustomRow>
                <Text weight="semi-bold">Şifre Değişikliği</Text>
            </CustomRow>
            <CustomRow className="input-row" gutter={[40, 0]}>
                {!edit && user ? (
                    <>
                        <Col span={18}>
                            <CustomRow className="label-row">
                                <Text size="14px" weight="light">
                                    Mevcut Şifre
                                </Text>
                            </CustomRow>
                            <CustomRow className="">
                                <Text size="16px" weight="medium">
                                    *********
                                </Text>
                            </CustomRow>
                            <CustomRow>
                                <a onClick={() => setModalVisible(true)}>
                                    <Text color="red" weight="light" size="14px">
                                        <u>Sifremi Unuttum</u>
                                    </Text>
                                </a>
                            </CustomRow>
                        </Col>
                        <Col span={5}>
                            <CustomRow align="top" className="btn-row">
                                <CustomButton type="tertiaryReverse" onClick={() => setEdit(true)}>
                                    <Text color="red">Düzenle</Text>
                                </CustomButton>
                            </CustomRow>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col span={6}>
                            <CustomRow className="label-row">
                                <Text size="14px" weight="light">
                                    Mevcut Şifre
                                </Text>
                            </CustomRow>
                            <CustomInput
                                placeholder="*********"
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            <CustomRow>
                                <a onClick={() => setModalVisible(true)}>
                                    <Text color="red" weight="light" size="14px">
                                        <u>Sifremi Unuttum</u>
                                    </Text>
                                </a>
                            </CustomRow>
                        </Col>
                        <Col span={6}>
                            <CustomRow className="label-row">
                                <Text size="14px" weight="light">
                                    Yeni Şifre
                                </Text>
                            </CustomRow>
                            <Tooltip color="white" title={TooltipContent}>
                                <CustomInput placeholder="Yeni Şifre" value={newPassword} onChange={(e) => checkNewPassword(e.target.value)} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <CustomRow className="label-row">
                                <Text size="14px" weight="light">
                                    Yeni Şifre Tekrar
                                </Text>
                            </CustomRow>
                            <Popover
                                visible={similarVisible}
                                content={!matchCheck ? <Text color="red">Passwords do not match</Text> : <Text color="green">Passwords Match</Text>}>
                                <CustomInput
                                    placeholder="Yeni Şifre Tekrar"
                                    value={newPassword2}
                                    onChange={(e) => checkPassword(e.target.value)}
                                    visibilityToggle={false}
                                />
                            </Popover>
                        </Col>
                        <CustomCol span={5} className="btn-col">
                            <CustomRow align="top" className="btn-row kaydet">
                                <CustomButton type="secondary" onClick={() => updatePassword()}>
                                    <Text color="white">Kaydet</Text>
                                </CustomButton>
                            </CustomRow>
                        </CustomCol>
                    </>
                )}
            </CustomRow>
            <ForgotPasswordModal dismiss={handleModalCancel} closeIcon={<CloseBtn />} onCancel={handleModalCancel} visible={modalVisible} />
        </CustomRow>
    );
};

export default PasswordCard;
