import React from 'react';
import { Col } from 'antd';
import { CustomModal, CustomButton, CustomRow } from './styles';
import { Text } from '../../../../../../../components/Typography/styles.js';
import MarkerIcon from '../../../../../../../../Assets/Shared/Profile/mapMarker.svg';

const ConfirmationModal = (props) => {
    const { dismiss, setForm } = props;

    return (
        <>
            <CustomModal {...props}>
                <CustomRow align="middle" justify="center">
                    <img src={MarkerIcon} width="75px" alt="icon" />
                </CustomRow>
                <CustomRow>
                    <Text size="14px" weight="semi-bold" style={{ textAlign: 'center', lineHeight: '24px' }}>
                        Adresiniz haritada işaretlenen konuma göre kaydedilecektir. Konum işaretinin doğru olduğuna emin misiniz?
                    </Text>
                </CustomRow>
                <CustomRow justify="space-between">
                    <Col>
                        <CustomButton type="secondaryReverse" onClick={dismiss}>
                            <Text size="16px" weight="semi-bold" color="green">
                                Hayır
                            </Text>
                        </CustomButton>
                    </Col>
                    <Col>
                        <CustomButton type="secondary" onClick={() => setForm(true)}>
                            <Text size="16px" weight="semi-bold" color="white">
                                Evet
                            </Text>
                        </CustomButton>
                    </Col>
                </CustomRow>
            </CustomModal>
        </>
    );
};

export default ConfirmationModal;
