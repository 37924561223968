import { message } from 'antd';
import * as actionTypes from './actionTypes';

// POST BOX CATEGORIES
export const postUserStartAction = () => ({
    type: actionTypes.POST_USER,
});

export const postUserSuccessAction = (data) => {
    message.success('Kayıt olma işleminiz başarıyla gerçekleştirildi');
    return {
        type: actionTypes.POST_USER_SUCCESS,
        payload: data,
    };
};

export const postUserFailAction = (error) => {
    message.error('Kayıt başarısız!');
    return {
        type: actionTypes.POST_USER_FAIL,
        payload: error,
    };
};
