import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 96.5%;

    &.main-row {
        margin-left: 30px;
    }

    &.content-row {
        overflow: auto;
        max-height: 600px;
    }

    &.last-row {
        margin-top: 30px;
    }
`;

export const CustomButton = styled(Button)`
    width: 200px;
    height: 48px;
`;
