import FooterStyles, { CancelButton, OkButton, ButtonContainer } from './styles';

const Footer = ({ setVisible, radioValue, setTimeLabel, detail, setDetail, onCancel }) => (
    <FooterStyles>
        <ButtonContainer>
            <CancelButton type="tertiary" onClick={() => setVisible(false)}>
                İptal
            </CancelButton>
            <OkButton
                type="secondary"
                onClick={() => {
                    setTimeLabel(radioValue);
                    setDetail(detail);
                    onCancel();
                }}>
                Uygula
            </OkButton>
        </ButtonContainer>
    </FooterStyles>
);

export default Footer;
