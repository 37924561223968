import styled from 'styled-components';
import { Row, Input } from 'antd';
import { Map, Marker } from 'google-maps-react';
import Button from '../../../../../../components/Button';
import { ReactComponent as LocationIcon } from '../../../../../../../Assets/Shared/Profile/findmylocation.svg';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        border-radius: 18px;
        height: 670px;
        width: 100%;
        display: block;
        border: 1px solid #f2f2fc;
        box-shadow: 0px 0px 20px #f2f2fc;
    }

    &.map-row {
        width: 100%;
        margin-top: 40px;
        position: relative;
    }

    &.title-row {
        padding-top: 25px;
        height: 30px !important;
    }

    &.button-row {
        padding-top: 430px;
    }

    &.search-icon {
        background: #ffbc41;
        border-radius: 50%;
        height: 48px;
        width: 48px;
        z-index: 11;
        position: absolute;
        left: 20px;
        margin-top: 20px;
    }
`;

export const CustomMap = styled(Map)`
    height: 458px !important;
    display: block;
    position: relative;
`;

export const CustomMarker = styled(Marker)``;

export const CustomButtom = styled(Button)`
    width: 372px;
    height: 48px;
`;

export const Location = styled(LocationIcon)`
    bottom: 25px;
    position: absolute;
    right: 25px;
    z-index: 10;
`;

export const SearchInput = styled(Input)`
    width: 450px;
    height: 48px;
    margin-left: 40px;
    z-index: 10;
    position: absolute !important;
    padding: 11px 20px;
    padding-left: 35px;
    border: 2px solid #f2f2fc;
`;

export const Container = styled.div`
    &.map {
        left: 40px;
        max-width: 420px;
        position: absolute;
        width: 100%;

        @media (min-width: 1025px) {
            max-width: 450px;
        }
    }
`;
