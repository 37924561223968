/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { ReactComponent as Star } from '../../../../../../../Assets/Shared/FindRestaurant/star.svg';
import { ReactComponent as Time } from '../../../../../../../Assets/Shared/FindRestaurant/time.svg';
import PacketGreen from '../../../../../../../Assets/Shared/CartPage/green-packet.svg';
import MotorGreen from '../../../../../../../Assets/Shared/CartPage/green-motor.svg';
import { ReactComponent as ForwardBtn } from '../../../../../../../Assets/Shared/CartPage/forward-arrow.svg';
import MotorWhite from '../../../../../../../Assets/Shared/FindRestaurant/motorwhite.svg';
import PacketWhite from '../../../../../../../Assets/Shared/FindRestaurant/packagewhite.svg';
import Spinner from '../../../../../../components/Spinner/index';
import { fetchStore } from '../../../../../../../services/Redux/Shared/store/api';

const RestaurantCard = () => {
    const history = useHistory();
    const selector = useSelector;
    const dispatch = useDispatch();

    const basket = selector((state) => state.customerOrderStore.basketList);
    const startDate = new Date(basket[0].sale_day.start_date);
    const endDate = new Date(basket[0].sale_day.end_date);
    const { store: { id } = {} } = basket[0] || {};

    useEffect(() => {
        dispatch(fetchStore(id));
    }, [dispatch]);

    const { storeData } = selector((state) => state?.storeStore || {});

    return (
        <CustomRow>
            {basket ? (
                <>
                    <CustomRow justify="space-between">
                        <Text weight="semi-bold">Restoran</Text>
                        <Text as={Link} to={`/restoran-detay/${id}`} color="green" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            Alışverişe Devam Et
                        </Text>
                    </CustomRow>
                    <CustomRow align="middle" className="card-row" wrap={false}>
                        <CustomCol className="left-col" span={12}>
                            <CustomRow align="middle">
                                <Col span={3}>
                                    <CustomCol span={21}>
                                        <img src={basket[0].store.photo} alt="Logo" width="100%" />
                                    </CustomCol>
                                </Col>
                                <Col span={9}>
                                    <CustomRow align="middle" className="name-row">
                                        <Text weight="semi-bold">{basket[0].store.name}&nbsp;&nbsp;</Text>
                                        <Star />
                                        {basket[0].store.review && basket[0].store.review.length >= 5 ? (
                                            <Text weight="light">&nbsp;{Number(basket[0].store.avg_review).toFixed(2)}</Text>
                                        ) : (
                                            <Text weight="light">&nbsp;5</Text>
                                        )}
                                    </CustomRow>
                                    <CustomRow className="name-row">
                                        <Text weight="light" color="smoke">
                                            {basket[0].store.address}
                                        </Text>
                                    </CustomRow>
                                </Col>
                            </CustomRow>
                        </CustomCol>
                        <CustomCol className="right-col" span={12}>
                            <CustomRow align="middle">
                                <Col span={12}>
                                    <CustomRow align="middle" className="time-row">
                                        <Time />
                                        <Text color="yellow">
                                            &nbsp;
                                            {startDate.getHours()}:{startDate.getMinutes() === 0 ? <>00</> : startDate.getMinutes()} -&nbsp;
                                            {endDate.getHours()}:{endDate.getMinutes() === 0 ? <>00</> : endDate.getMinutes()}
                                        </Text>
                                    </CustomRow>
                                </Col>
                                <Col span={10}>
                                    {storeData?.package_settings?.delivery_type === '1' ? (
                                        <Row justify="center">
                                            <img src={PacketGreen} alt="packet-icon" />
                                            &nbsp;&nbsp;
                                            <img src={MotorWhite} alt="packet-icon" />
                                        </Row>
                                    ) : (
                                        <Row justify="center">
                                            {storeData?.package_settings?.delivery_type === '2' ? (
                                                <img src={PacketWhite} alt="packet-icon" />
                                            ) : (
                                                <img src={PacketGreen} alt="packet-icon" />
                                            )}
                                            &nbsp;&nbsp;
                                            <img src={MotorGreen} alt="packet-icon" />
                                        </Row>
                                    )}
                                </Col>
                                <CustomCol className="chevron-col" span={2}>
                                    <ForwardBtn onClick={() => history.push(`restoran-detay/${basket[0].store.id}`)} />
                                </CustomCol>
                            </CustomRow>
                        </CustomCol>
                    </CustomRow>
                </>
            ) : (
                <CustomRow>
                    <Spinner />
                </CustomRow>
            )}
        </CustomRow>
    );
};

export default RestaurantCard;
