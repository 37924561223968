/* eslint-disable no-unused-vars */
import React from 'react';
import { Collapse } from 'antd';
import FaqPageStyles from './styles';
import bg from '../../../../Assets/Shared/LoginPage/loginBackground.svg';
import Content from './Content/Content';

const { Panel } = Collapse;

const FaqPage = () => (
    <FaqPageStyles backgroundImage={bg}>
        <Content />
    </FaqPageStyles>
);

export default FaqPage;
