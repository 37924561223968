/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Table, TableRow, TableHead, TableDate } from './styles.js';
import { Text } from '../../../../../../components/Typography/styles.js';

const Calendar = () => {
    const selector = useSelector;
    const storeData = selector((state) => state.storeStore.storeData);
    const today = new Date();
    const days = [new Date(), new Date(), new Date(), new Date(), new Date(), new Date()];
    days[0].setDate(today.getDate() + 1);
    days[1].setDate(today.getDate() + 2);
    days[2].setDate(today.getDate() + 3);
    days[3].setDate(today.getDate() + 4);
    days[4].setDate(today.getDate() + 5);
    days[5].setDate(today.getDate() + 6);
    const dateItem = [null, null, null, null, null, null, null];
    const startDate = ['', '', '', '', '', '', ''];
    const endDate = ['', '', '', '', '', '', ''];
    storeData.calendar.map((item) => {
        if (new Date(item.start_date).toDateString() === today.toDateString()) {
            dateItem[0] = item;
            startDate[0] = new Date(item.start_date);
            endDate[0] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[0].toDateString()) {
            dateItem[1] = item;
            startDate[1] = new Date(item.start_date);
            endDate[1] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[1].toDateString()) {
            dateItem[2] = item;
            startDate[2] = new Date(item.start_date);
            endDate[2] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[2].toDateString()) {
            dateItem[3] = item;
            startDate[3] = new Date(item.start_date);
            endDate[3] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[3].toDateString()) {
            dateItem[4] = item;
            startDate[4] = new Date(item.start_date);
            endDate[4] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[4].toDateString()) {
            dateItem[5] = item;
            startDate[5] = new Date(item.start_date);
            endDate[5] = new Date(item.end_date);
        }
        if (new Date(item.start_date).toDateString() === days[5].toDateString()) {
            dateItem[6] = item;
            startDate[6] = new Date(item.start_date);
            endDate[6] = new Date(item.end_date);
        }
        return 0;
    });

    const weekdays = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'];
    const loop = [0, 1, 2, 3, 4, 5, 6];
    if (storeData) {
        return (
            <Table>
                <TableRow>
                    {loop.map((id) => (
                        <TableHead>
                            {startDate[id] !== '' ? (
                                <Text weight="semi-bold">
                                    {startDate[id].getDate()} &nbsp;
                                    {weekdays[startDate[id].getDay()]}
                                </Text>
                            ) : (
                                <Text>
                                    {days[id - 1].getDate()} &nbsp;
                                    {weekdays[days[id - 1].getDay()]}
                                </Text>
                            )}
                        </TableHead>
                    ))}
                </TableRow>
                <TableRow>
                    {loop.map((id) => (
                        <>
                            {dateItem[id] ? (
                                <TableDate>
                                    {dateItem[id].is_active && dateItem[id].box_count > 0 ? (
                                        <Text size="14px" weight="light">
                                            {startDate[id].toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })} -
                                            {endDate[id].toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })}
                                        </Text>
                                    ) : (
                                        <Text size="14px" weight="light" color="#707070">
                                            {startDate[id].toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })} -
                                            {endDate[id].toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })}
                                        </Text>
                                    )}
                                </TableDate>
                            ) : (
                                <TableDate>
                                    <Text size="11px" fontStyle="italic" weight="light" color="#707070">
                                        Bilgi yok
                                    </Text>
                                </TableDate>
                            )}
                        </>
                    ))}
                </TableRow>
                <TableRow>
                    {loop.map((id) => (
                        <>
                            {dateItem[id] ? (
                                <TableDate>
                                    {dateItem[id] && dateItem[id].is_active && dateItem[id].box_count > 0 ? (
                                        <Text fontStyle="italic" weight="light">
                                            Açık
                                        </Text>
                                    ) : (
                                        <Text fontStyle="italic" weight="light" color="#707070">
                                            Kapalı <ExclamationCircleOutlined />
                                        </Text>
                                    )}
                                </TableDate>
                            ) : (
                                <TableDate>
                                    <Text size="11px" fontStyle="italic" weight="light" color="#707070">
                                        Bilgi Yok
                                    </Text>
                                </TableDate>
                            )}
                        </>
                    ))}
                </TableRow>
                <TableRow>
                    {loop.map((id) => (
                        <>
                            {dateItem[id] ? (
                                <TableDate>
                                    {dateItem[id] && dateItem[id].box_count > 0 ? (
                                        <Text weight="light">{dateItem[id].box_count} paket</Text>
                                    ) : (
                                        <Text weight="light" color="#707070">
                                            {dateItem[id].box_count} paket
                                        </Text>
                                    )}
                                </TableDate>
                            ) : (
                                <TableDate>
                                    <Text size="11px" fontStyle="italic" weight="light" color="#707070">
                                        Bilgi Yok
                                    </Text>
                                </TableDate>
                            )}
                        </>
                    ))}
                </TableRow>
            </Table>
        );
    }
    return null;
};

export default Calendar;
