/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    favouriteMeals: null,
    getfavouriteMealsError: null,

    deleteFavouriteLoading: false,
    deleteFavouriteId: null,
    deleteFavouriteSuccessMessage: null,
    deleteFavouriteError: null,
};

const getFavouriteMealsReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getFavouriteMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    favouriteMeals: action.payload,
});

const getFavouriteMealsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    getfavouriteMealsError: action.payload,
});

// DELETE
const deleteFavouritesStartReducer = (state, action) => ({
    ...state,
    deleteFavouriteLoading: true,
    deleteFavouriteId: action.payload,
});

const deleteFavouritesSuccessReducer = (state, action) => ({
    ...state,
    deleteFavouriteLoading: false,
    deleteAppcontactSuccessMessage: action.payload,
    deleteFavouriteId: null,
});

const deleteFavouritesFailReducer = (state, action) => ({
    ...state,
    deleteFavouriteLoading: false,
    deleteAppcontactError: action.payload,
    deleteFavouriteId: null,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FAVOURITE_MEALS:
            return getFavouriteMealsReducer(state, action);
        case actionTypes.GET_FAVOURITE_MEALS_SUCCESS:
            return getFavouriteMealsSuccessReducer(state, action);
        case actionTypes.GET_FAVOURITE_MEALS_FAIL:
            return getFavouriteMealsFailReducer(state, action);
        case actionTypes.DELETE_FAVOURITE_MEALS:
            return deleteFavouritesStartReducer(state, action);
        case actionTypes.DELETE_FAVOURITE_MEALS_SUCCESS:
            return deleteFavouritesSuccessReducer(state, action);
        case actionTypes.DELETE_FAVOURITE_MEALS_FAIL:
            return deleteFavouritesFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
