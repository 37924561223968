import styled from 'styled-components';
import { Col, Row } from 'antd';
// import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../../helpers/constants';

export const TextWrapper = styled(Col)`
    border-bottom: 4px solid #f2f2fc;
    padding-top: 14%;
    padding-bottom: 7%;
    text-align: center;
    margin-bottom: 7%;
`;

export const Wrapper = styled(Col)`
    background-color: white;
    border-radius: 18px;
    width: 330px;
    margin-bottom: 10%;
`;

export const TextStyle = styled(Row)`
    margin-left: 12%;

    .ant-slider-track {
        background-color: #8fd89f !important;
    }

    .ant-slider-handle {
        border-color: black !important;
        background-color: black !important;
        height: 18px !important;
    }
`;

export const HiddenRow = styled.div`
    height: 0px;
    transition: height 0.5s;
    overflow: hidden;
    width: 100%;
`;
