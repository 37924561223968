/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Col, Row } from 'antd';
import React from 'react';
import { Text } from '../../../../../../components/Typography/styles';
import ArrowRight from '../../../../../../../Assets/Shared/Profile/arrowRight.svg';
import editIcon from '../../../../../../../Assets/Shared/Profile/editIcon.svg';
import { StyledDiv, DetailsDiv, StyledEdit } from './styles';

function Index({ address, setAddress }) {
    return (
        <StyledDiv>
            <Row style={{ minWidth: '100%' }} align="middle">
                <Col span={18}>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.name}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="medium" size="description">
                            {address?.province} ({address?.address?.substring(0, address?.address?.indexOf(','))})
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {JSON.parse(localStorage.getItem('user'))?.email}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.address}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.phone_number?.replace(/(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.description}
                        </Text>
                    </Row>
                </Col>
                <Col span={6}>
                    <DetailsDiv>
                        <StyledEdit span={16}>
                            <img src={editIcon} alt="sup" />
                        </StyledEdit>
                        <Col span={8}>
                            <img src={ArrowRight} alt="details" onClick={() => setAddress(address)} />
                        </Col>
                    </DetailsDiv>
                </Col>
            </Row>
        </StyledDiv>
    );
}

export default Index;
