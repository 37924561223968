import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import StoreStyles, { Content, Item, RightSide } from './styles';
import StoreInfoCard from './Cards/StoreInfoCard/index';
import RefNumberCard from './Cards/RefNumberCard/index';
import EmailCard from './Cards/EmailCard/index';
import ConditionCard from './Cards/ConditionsCard/index';
import { getStoreInfo, getEmailInfo } from '../../../../services/Redux/Dashboard/StorePage/api';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import Spin from '../../../components/Spinner';

const StorePage = () => {
    const dispatch = useDispatch();
    const { storeInfo, id, emailSettings } = useSelector((state) => state.storePage);

    useEffect(() => {
        dispatch(getStoreInfo());
        dispatch(getEmailInfo(id));
    }, [dispatch, id]);

    const size = useResponsive();

    return storeInfo === null || emailSettings === null ? (
        <Spin size="large" />
    ) : (
        <StoreStyles>
            <Content>
                <Item md={24} xl={14} xxl={14}>
                    <StoreInfoCard storeInfo={storeInfo} />
                    <RefNumberCard />
                </Item>
                <RightSide xl={{ span: 9, offset: 1 }} xxl={{ span: 7, offset: 1 }} size={size}>
                    <EmailCard settings={emailSettings} id={id} storeInfo={storeInfo} />
                    <ConditionCard />
                </RightSide>
            </Content>
        </StoreStyles>
    );
};

export default StorePage;
