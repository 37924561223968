import styled from 'styled-components';
import { Col, Row, Spin as spin } from 'antd';
import Card from '../../../../components/Card';

export const OrdersCardStyles = styled(Card)`
    margin-top: 25px !important;
    .ant-card-body {
        padding: 0px;
        min-height: 330px !important;
    }
    .ant-table-row-level-0 {
        color: #837f7f;
    }
    .ant-table-cell {
        text-align: center;
    }
    .ant-table-cell {
        text-align: center;
    }
`;

export const Content = styled(Col)`
    padding: 12px 24px;
    padding-top: 0px;
`;

export const Footer = styled(Row)`
    width: 100%;
    height: 75px;
    border-radius: 30px 30px 0 0;
    background: ${({ theme }) => theme.lightColor};
`;

export const NavButton = styled.img`
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
`;

export const Spin = styled(spin)`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;
