/* eslint-disable no-unused-vars */
import { message } from 'antd';
import { Wrapper, FoodsWrapper, FoodWrapper, TextContainer, CustomCheckbox } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import xmlFetch from '../../../../../../../services/Api/request';

const FavouriteItem = ({ favouriteToRender, setFavouriteToRender, isFilter, setFilteredFav }) => {
    const addFavs = (id) => {
        if (favouriteToRender.filter((val) => val.favorite === true).length >= 5) {
            message.error('5 Üründen Fala Eklenemez!');
        } else {
            const res = xmlFetch({
                path: `box/favorite-meal/`,
                method: 'POST',
                sendToken: true,
                body: {
                    meal_id: id,
                },
            }).then(message.success('Favorilere Eklendi!'));

            if (isFilter) {
                setFilteredFav(favouriteToRender.map((val) => (val.id === id ? { ...val, favorite: !val.favorite } : val)));
            }
            setFavouriteToRender(favouriteToRender.map((val) => (val.id === id ? { ...val, favorite: !val.favorite } : val)));
        }
    };

    const deleteFromFavs = (id) => {
        const res = xmlFetch({
            path: `box/favorite-meal/${id}/`,
            method: 'DELETE',
            sendToken: true,
        }).then(message.success('Favorilerden Kaldırıldı!'));

        if (isFilter) {
            setFilteredFav(favouriteToRender.map((val) => (val.id === id ? { ...val, favorite: !val.favorite } : val)));
        }
        setFavouriteToRender(favouriteToRender.map((val) => (val.id === id ? { ...val, favorite: !val.favorite } : val)));
    };

    return (
        <Wrapper span={24}>
            <FoodsWrapper>
                {favouriteToRender
                    .map(({ id, name, price, favorite }) => (
                        <FoodWrapper align="middle" justify="space-between">
                            <TextContainer>
                                <Text family="secondary" weight="medium" size="description">
                                    {name}
                                </Text>
                                <Text family="secondary" size="description">
                                    {price} TL
                                </Text>
                            </TextContainer>
                            <CustomCheckbox checked={favorite} onClick={() => (favorite ? deleteFromFavs(id) : addFavs(id))} />
                        </FoodWrapper>
                    ))
                    .sort()}
            </FoodsWrapper>
        </Wrapper>
    );
};

export default FavouriteItem;
