import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import OrdersStyles, { Content, Item, ContentMain } from './styles';
import SalesAndBillCard from './Cards/SalesAndBillCard/index';
import { getPaymentInfo } from '../../../../services/Redux/Dashboard/payment/api';
import { getStoreInfo } from '../../../../services/Redux/Dashboard/StorePage/api';
import Spin from '../../../components/Spinner';

const OrdersPage = () => {
    const dispatch = useDispatch();
    const { id } = useSelector((state) => state.storePage);
    const { loading, taxOfficeInfo } = useSelector((state) => state.paymentStore);
    const [paymentInfo, setPaymentInfo] = useState();

    useEffect(() => {
        if (id === null) {
            dispatch(getStoreInfo());
            dispatch(getPaymentInfo());
        } else {
            dispatch(getPaymentInfo());
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (taxOfficeInfo) setPaymentInfo(taxOfficeInfo[0]);
    }, [taxOfficeInfo]);

    return loading === true || id === null ? (
        <Spin size="large" />
    ) : (
        <OrdersStyles>
            <Content>
                <ContentMain>
                    <Item md={22}>
                        <SalesAndBillCard info={paymentInfo} />
                    </Item>
                    {/* <RightSide md={22} xl={8} xxl={6}>
                        <PaymentInfoCard />
                        <BalanceCard />
                    </RightSide> */}
                </ContentMain>
            </Content>
            {/* <BottomCard>
                <Item sm={11} md={10} xl={7} xxl={6}>
                    <LastPaymentCard />
                </Item>
                <Item sm={{ span: 11, offset: 2 }} md={{ span: 10, offset: 2 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 6, offset: 1 }}>
                    <LastBillCard />
                </Item>
            </BottomCard> */}
        </OrdersStyles>
    );
};

export default OrdersPage;
