import React from 'react';
import { Input } from 'antd';
import { SearchBarStyle } from '../../styles';
import SearchIcon from '../../../../../../Assets/Shared/Landing/search.svg';

const SearchBar = ({ search, setSearch }) => (
    <SearchBarStyle>
        <img src={SearchIcon} alt="img" width={17} />
        <Input
            bordered={false}
            placeholder="Ara"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: '90%', height: '40px' }}
        />
    </SearchBarStyle>
);

export default SearchBar;
