/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftContainer, TitleRow, CustomDivider, CustomRow } from './styles';
import { Text } from '../../../components/Typography/styles';
import { ReactComponent as UserLogo } from '../../../../Assets/Shared/CartPage/user-logo.svg';
import Content from './Content';
import { getAddressActive } from '../../../../services/Redux/Shared/profile/api';
import { Outside } from '../FindRestaurant/styles';

const UserSettings = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const history = useHistory();
    const dispatch = useDispatch();
    const selector = useSelector;

    useEffect(() => {
        if (user) {
            dispatch(getAddressActive());
        }
    }, []);

    const activeAddress = selector((state) => state.profileStore.activeAddress);

    if (user === null) {
        history.push('/giris');
    }

    if (user !== null) {
        return (
            <Outside justify="center">
                <Col span={24}>
                    <LeftContainer>
                        <TitleRow justify="center" align="middle">
                            <Text size="22px" weight="light" color="smoke">
                                Ayarlar
                            </Text>
                            <CustomDivider />
                        </TitleRow>
                        <CustomRow className="main-row" justify="center">
                            <Col span={1}>
                                <CustomRow align="middle" justify="end">
                                    <UserLogo />
                                </CustomRow>
                            </Col>
                            <Col span={22}>
                                <CustomRow className="user-row" justify="space-between">
                                    <Col>
                                        <Text>
                                            <Text weight="light">Hoş geldin</Text>&nbsp;
                                            {user.first_name} {user.last_name}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <Text weight="light" color="smoke">
                                            Konumum:&nbsp;
                                        </Text>
                                        {activeAddress && (
                                            <Text weight="semi-bold" size="16px">
                                                {activeAddress.address}
                                            </Text>
                                        )}
                                    </Col>
                                </CustomRow>
                                <CustomDivider className="user-divider" />
                            </Col>
                        </CustomRow>
                        <Content />
                    </LeftContainer>
                </Col>
            </Outside>
        );
    }
    return <></>;
};

export default UserSettings;
