/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DeliveryInfoStyles, { Content, Item, Text, SaveButton, CardTitle, Select, Divider, Address } from './sytles';
import BagImage from '../../../../../../Assets/Dashboard/bag.png';
import MealImage from '../../../../../../Assets/Dashboard/meal.png';
import { updatePackageInfo, getPackageInfo } from '../../../../../../services/Redux/Dashboard/package/api';

const DeliveryInfo = ({ packaging, extraAddress, id, deliveryList }) => {
    const dispatch = useDispatch();
    const [delivery, setDelivery] = useState(packaging);
    const [address, setAddress] = useState(extraAddress);
    const [deliveryToRender, setDeliveryToRender] = useState([]);
    const { Option } = Select;

    useEffect(() => {
        if (deliveryList) {
            const filtered = deliveryList.filter((item) => item.store === id);
            setDeliveryToRender(filtered);
        }
    }, [deliveryList]);

    const update = async (packaging_type, ekstra_address) => {
        await dispatch(updatePackageInfo({ packaging_type, ekstra_address }, id));
        dispatch(getPackageInfo(id));
    };

    return (
        <DeliveryInfoStyles title={<CardTitle>Teslim Alma Bilgisi</CardTitle>}>
            <Content justify="start">
                <Text weight="semi-bold">Paketleme:</Text>
                <Text color="smoke" family="secondary" style={{ marginTop: '16px' }}>
                    Müşterilere siparişlerini toplarken sunacağınız ambalaj türünü seçin
                </Text>
                <Item style={{ marginTop: '25px' }}>
                    <img src={BagImage} alt="" />
                    <img src={MealImage} alt="" />

                    <Select size="large" value={delivery} onChange={(option) => setDelivery(option)}>
                        {deliveryToRender.length > 0 &&
                            deliveryToRender.map(({ id, name }) => (
                                <Option value={id}>
                                    <Text family="secondary" color="smoke">
                                        {name}
                                    </Text>
                                </Option>
                            ))}
                    </Select>
                </Item>
                <Text size="13px" fontStyle="italic" style={{ marginTop: '25px' }}>
                    Lütfen müşteriye hem yiyecek tabldotu hem de yiyeceklerini eve güvenle taşımak için kullanabilecekleri bir poşet verin.
                </Text>
                <Divider />
                <Text weight="semi-bold">Ek adres tarifi:</Text>
                <Text color="smoke" family="secondary" style={{ marginTop: '20px' }}>
                    Müşteriler, çantalarını nerede toplayacakları hakkında adres ve toplama zamanı dışında daha fazla bilgiye ihtiyaç duyarsa, lütfen
                    aşağıya kısa bir açıklama girin ve bu bilgi uygulama üzerinde gösterilecektir.
                </Text>
                <Address
                    placeholder="Açıklama girin"
                    value={address === 'undefined' ? '' : address}
                    onChange={(event) => setAddress(event.target.value)}
                />
                <Item md={{ span: 10, offset: 14 }} xl={{ span: 10, offset: 14 }}>
                    <SaveButton type="secondary" onClick={() => update(delivery, address)}>
                        Kaydet
                    </SaveButton>
                </Item>
            </Content>
        </DeliveryInfoStyles>
    );
};

export default DeliveryInfo;
