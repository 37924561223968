/* eslint-disable no-unused-vars */
import { message } from 'antd';
import * as actions from './actions';

export const fetchBoxes = () => async (dispatch, state, service) => {
    dispatch(actions.getBoxesStartAction(dispatch, state, service));
    await service({
        path: 'box/',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getBoxesSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getBoxesFailAction(error));
        });
};

export const fetchBoxCategories = (customer) => async (dispatch, state, service) => {
    dispatch(actions.getBoxCategoriesStartAction(dispatch, state, service));
    if (!customer) {
        await service({
            path: 'box/category/',
            method: 'GET',
            sendToken: true,
        })
            .then((data) => {
                dispatch(actions.getBoxCategoriesSuccessAction(data));
            })
            .catch((error) => {
                dispatch(actions.getBoxCategoriesFailAction(error));
            });
    } else {
        await service({
            path: 'box/category',
            method: 'GET',
        })
            .then((data) => {
                dispatch(actions.getBoxCategoriesSuccessAction(data));
            })
            .catch((error) => {
                dispatch(actions.getBoxCategoriesFailAction(error));
            });
    }
};

export const postBoxCategories = (newInfo) => async (dispatch, getState, service) => {
    dispatch(actions.postBoxCategoriesStartAction(newInfo));
    await service({
        method: 'POST',
        path: 'box/category/',
        sendToken: true,
        body: newInfo,
    })
        .then(() => {
            dispatch(actions.postBoxCategoriesSuccessAction());
            message.success('Kategori Başarı ile Oluşturuldu!');
        })
        .catch((error) => {
            dispatch(actions.postBoxCategoriesFailAction());
            message.error('Kategori Oluşturulurken bir Hata Oluştu!');
        });
};

export const fetchBoxCategoriesNames = () => async (dispatch, state, service) => {
    dispatch(actions.getBoxCategoriesNamesStartAction(dispatch, state, service));
    await service({
        path: 'box/category-name/',
        method: 'GET',
    })
        .then((data) => {
            dispatch(actions.getBoxCategoriesNamesSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getBoxCategoriesNamesFailAction(error));
        });
};

export const fetchBoxMeals = () => (dispatch, state, service) => {
    dispatch(actions.getBoxMealsStartAction(dispatch, state, service));
    service({
        path: 'box/meal',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getBoxMealsSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getBoxMealsFailAction(error));
        });
};

export const fetchBoxNames = () => async (dispatch, state, service) => {
    dispatch(actions.getBoxNamesStartAction(dispatch, state, service));
    await service({
        path: 'box/box-name/',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getBoxNamesSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getBoxNamesFailAction(error));
        });
};
export const fetchBoxTags = () => async (dispatch, state, service) => {
    dispatch(actions.getBoxTagsStartAction(dispatch, state, service));
    await service({
        path: 'box/tag',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getBoxTagsSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getBoxTagsFailAction(error));
        });
};

export const postBoxTags = (newInfo) => async (dispatch, getState, service) => {
    try {
        const data = {
            name: newInfo,
        };
        dispatch(actions.postBoxTagsStartAction(newInfo));
        const result = await service({
            method: 'POST',
            path: 'box/tag/',
            sendToken: true,
            body: data,
        });
        dispatch(actions.postBoxTagsSuccessAction());
    } catch (err) {
        dispatch(actions.postBoxTagsFailAction());
    }
};

export const postBoxMeal = (newInfo) => async (dispatch, state, service) => {
    dispatch(actions.postBoxMealStartAction(newInfo));
    await service({
        method: 'POST',
        path: 'box/meal/',
        sendToken: true,
        body: newInfo,
    })
        .then(() => {
            dispatch(actions.postBoxMealSuccessAction());
            message.success('Ürün Onaylanmaya Gönderildi!');
        })
        .catch((error) => {
            dispatch(actions.postBoxMealFailAction());
            if (error.photo) message.error('Geçersiz bir dosya yüklediniz. Lütfen farklı bir dosya yüklemeyi deneyin!');
            else message.error('Ürün Oluşturulurken bir Hata Oluştu!');
        });
};

export const putBoxMeal = (newInfo, id) => async (dispatch, state, service) => {
    dispatch(actions.putBoxMealStartAction(newInfo));
    await service({
        method: 'PUT',
        path: `box/meal/${id}/`,
        sendToken: true,
        body: newInfo,
    })
        .then(() => {
            dispatch(actions.putBoxMealSuccessAction());
            message.success('Ürün Onaylanmaya Gönderildi!');
        })
        .catch((error) => {
            dispatch(actions.putBoxMealFailAction());
            message.error('Ürün Güncellenirken bir Hata Oluştu!');
        });
};

export const deleteBoxMeal = (id) => async (dispatch, state, service) => {
    dispatch(actions.deleteBoxMealStartAction());
    await service({
        method: 'DELETE',
        path: `box/meal/${id}/`,
        sendToken: true,
    })
        .then(() => {
            dispatch(actions.deleteBoxMealSuccessAction());
            message.success('Seçilen Ürün Başarı ile Silindi! ');
        })
        .catch((error) => {
            dispatch(actions.deleteBoxMealFailAction());
            message.error('Seçilen Ürün Silinirken bir Hata Oluştu!');
        });
};

export const updateBox = (boxNameId, boxId) => async (dispatch, getState, service) => {
    dispatch(actions.updateBoxesStartAction());
    await service({
        method: 'PUT',
        path: `box/define/${boxId}/`,
        sendToken: true,
        body: {
            name: boxNameId,
        },
    })
        .then((data) => {
            dispatch(actions.updateBoxesSuccessAction());
            message.success('Paket Başarı ile Tanımlandı. !');
        })
        .catch((error) => {
            dispatch(actions.updateBoxesFailAction());
            message.error('Paket Tanımlanırken bir Hata Oluştu. !');
        });
};

export const createBox = (boxNameId, mealsArr) => async (dispatch, getState, service) => {
    dispatch(actions.createBoxesStartAction());
    await service({
        method: 'POST',
        path: `box/`,
        sendToken: true,
        body: {
            name: boxNameId,
            meals: mealsArr,
            defined: true,
        },
    })
        .then((data) => {
            dispatch(actions.createBoxesSuccessAction());
            message.success('Tanımlı Paket Başarı ile Oluşturuldu. !');
        })
        .catch((error) => {
            dispatch(actions.createBoxesFailAction());
            message.error('Tanımlı Paket Oluşturulurken bir Hata Oluştu. !');
        });
};
