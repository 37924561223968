/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../components/Typography/styles';
import { getOrderHistory } from '../../../../../../services/Redux/Shared/profile/api';
import Spinner from '../../../../../components/Spinner';
import OrderCard from './OrderCard';
import OrderDetails from './OrderDetails';

function Index() {
    const selector = useSelector;
    const dispatch = useDispatch();
    const [pastOrders, setPastOrders] = useState([]);
    const [activeOrders, setActiveOrders] = useState([]);
    const [orderToDisplay, setOrderToDisplay] = useState({});

    useEffect(() => {
        dispatch(getOrderHistory());
    }, [dispatch]);

    const storeData = selector((state) => state?.profileStore?.orderHistory);
    const { loading } = selector((state) => state?.profileStore);

    useEffect(() => {
        if (loading === false) {
            setPastOrders(
                storeData?.filter(({ status }) => ['4', '5', '6'].includes(status))?.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time))
            );
            setActiveOrders(
                storeData
                    ?.filter(({ status }) => ['1', '2', '3', '9'].includes(status))
                    ?.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time))
            );
        }
    }, [storeData, loading]);

    if (loading) return <Spinner />;

    return (
        <>
            {orderToDisplay && Object.keys(orderToDisplay)?.length !== 0 ? (
                <div>
                    <OrderDetails order={orderToDisplay} setOrder={setOrderToDisplay} />
                </div>
            ) : (
                <div>
                    {activeOrders && Array.isArray(activeOrders) && activeOrders?.length !== 0 && (
                        <div style={{ marginBottom: '32px' }}>
                            <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                                Aktif Siparişlerim
                            </Text>
                            {activeOrders?.map((item, i) => (
                                <OrderCard order={item} key={item.id} index={i} setOrderToDisplay={setOrderToDisplay} />
                            ))}
                        </div>
                    )}
                    {pastOrders && Array.isArray(pastOrders) && pastOrders?.length !== 0 && (
                        <>
                            <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                                Geçmiş Siparişlerim
                            </Text>
                            {pastOrders?.map((item, i) => (
                                <OrderCard order={item} key={item.id} index={i} setOrderToDisplay={setOrderToDisplay} />
                            ))}
                        </>
                    )}
                    {!orderToDisplay && !activeOrders && activeOrders?.length === 0 && !pastOrders && pastOrders?.length === 0 && (
                        <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginTop: '32px' }} align="center">
                            Siparişiniz Bulunmamaktadır!
                        </Text>
                    )}
                </div>
            )}
        </>
    );
}

export default Index;
