/* eslint-disable sonarjs/no-identical-functions */
import { Col, Row } from 'antd';
import styled from 'styled-components';
import 'polished';
import { Text } from '../../../components/Typography/styles';
import { ReactComponent as FavIcon } from '../../../../Assets/Shared/RestaurantDetailPage/addFav.svg';

export const OutSide = styled(Row)`
    align-items: flex-start;
    background-color: #f5f9fc;
    padding-left: 0%;
    padding-top: 150px;
    margin: 0 auto;
    max-width: 1450px;

    @media (max-width: 576px) {
        padding-top: 110px;
    }
`;

export const LeftContainer = styled(Col)`
    background-color: white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    width: 97%;
    min-height: 1329px;
    height: auto;
    margin-right: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 40px;

    @media (max-width: 1024px) {
        margin: 0 auto;
        padding: 0;
    }
`;

export const RightSide = styled(Col)`
    width: 334px;
    height: 390px;
    border-radius: 18px;
    display: none;

    @media (min-width: 1025px) {
        display: block;
    }
`;

export const ImageStyles = styled(Col)`
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #ffffff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    display: flex;
    height: 200px;
    position: relative;
`;

export const DeliveryIcon = styled(Row)`
    position: absolute;
    right: 1rem;
    top: 1rem;
    & img {
        height: 3rem;
        width: 3rem;
    }
    & > * + * {
        margin-left: 16px;
    }
`;

export const CustomCol = styled(Col)`
    @media (max-width: 1024px) {
        max-width: 650px;
    }

    &.main-col {
        @media (max-width: 1024px) {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
        }
    }

    &.secondary-col {
        flex: 1;
        max-width: 100%;

        @media (min-width: 1280px) {
            max-width: 75%;
        }
    }
`;

export const LogoStyles = styled(Row)`
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #ffffff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    width: 10.5rem;
    height: 7.5rem;
    margin-left: 2rem;
    margin-top: -1.5rem;
    border: 2px solid #f2f2fb;

    @media (max-width: 600px) {
        width: 7rem;
        height: 4rem;
    }
`;

export const CustomHeaderCol = styled(Col)`
    @media (max-width: 600px) {
        min-width: 100%;
    }
`;

export const StarStyle = styled.img`
    width: 1rem;
`;

export const CustomText = styled(Text)`
    margin-left: 0.5rem;
    margin-right: 2.5rem;
    display: 'flex';
    &.name {
        margin-left: 2rem;
    }

    @media (max-width: 600px) {
        &.name {
            font-size: 1rem !important;
        }
        font-size: 0.75rem !important;
        margin-right: 1rem !important;
    }
`;

export const AddressRow = styled(Row)`
    margin-left: 30px;
    padding-top: 1.5rem;
    @media (max-width: 600px) {
        padding-top: 0;
    }
`;

export const NameRow = styled(Row)`
    margin-top: 2.5rem;

    @media (max-width: 600px) {
        display: 'flex';
        justify-content: center;
        margin-top: -1rem;
    }
`;

export const TimeRow = styled(Row)`
    margin: 2rem 0 2rem 2rem;

    @media (max-width: 600px) {
        margin: 0 0 1rem 2rem;
    }
`;

export const DividerStyle = styled(Row)`
    background-color: #f2f2fc;
    width: 100%;
    height: 4px;
    margin: 0.5rem auto;

    &.light {
        margin-top: 1rem;
        height: 2px;
        width: 96%;
        @media (max-width: 600px) {
            margin: 1rem auto;
        }
    }
`;

export const BottomContainerStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    align-items: center;
    background-color: #f5f9fc;
    width: 192px;
    height: 40px;
    text-align: center;
    @media (max-width: 600px) {
        width: 8rem;
    }
`;

export const BottomTextStyle = styled(Text)`
    margin-left: 10px;
    @media (max-width: 600px) {
        font-size: 0.75rem !important;
    }
`;

export const DiscountTextStyle = styled(Text)`
    /* margin-left: 30px; */
    text-decoration: line-through;
`;

export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    border-radius: 4px;
    width: 5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 0px;
`;

export const BoxCountRow = styled(Row)`
    align-items: center;
    background-color: #f38262;
    border-radius: 8px;
    width: 8rem;
    height: 2.5rem;
    margin-left: 0;
    justify-content: center;

    @media (max-width: 600px) {
        padding: 0;
        width: 5rem;
        height: 2rem;
    }
`;

export const FavRow = styled(Row)`
    /* margin-left: 5rem; */
`;

export const FavText = styled(Text)`
    margin-right: 0px;
    font-size: 1rem !important;
`;

export const CategoryRow = styled(Row)`
    margin-left: 3rem;
    @media (max-width: 600px) {
        margin-left: 1rem;
    }
`;

export const FavButton = styled(FavIcon)`
    color: #424242;
    @media (max-width: 600px) {
        height: 1rem;
        width: 1rem;
    }
`;

export const FavButtonRow = styled(Row)`
    border-radius: 50%;
    border: 1px solid #424242;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    transition: 0.2s;

    :hover {
        background: #8fd89f;
        border: 1px solid #8fd89f;
    }

    .fav {
        :hover {
            color: white;
        }
    }

    &.liked {
        background: #8fd89f !important;
        border: 1px solid #8fd89f;

        .fav {
            color: white;
        }
    }
    &.unliked {
        background: white !important;
        border: 1px solid #8fd89f;

        .fav {
            color: black;
        }
    }

    @media (max-width: 600px) {
        height: 2.5rem;
        width: 2.5rem;
    }
`;
