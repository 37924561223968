import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export function IsUserRedirect({ loggedInPath, userData, children, ...rest }) {
    const currentUser = userData;

    return (
        <Route
            {...rest}
            render={() => {
                if (!currentUser) {
                    return children;
                }

                if (currentUser) {
                    return (
                        <Redirect
                            to={{
                                pathname: loggedInPath,
                            }}
                        />
                    );
                }

                return null;
            }}
        />
    );
}
