import styled, { createGlobalStyle } from 'styled-components';
import { Row, Menu } from 'antd';
import { Link as ReactRouterLink } from 'react-router-dom';
import Layout from '../index';
import Button from '../../Button/index';
import { HORIZONTAL_PADDING } from '../../../../helpers/constants';
import { Text } from '../../Typography/styles';

const { Header } = Layout;

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export default styled(Header)`
    z-index: 1000;
    overflow: hidden;
    height: ${({ theme }) => theme.layoutStyles.headerHeight};
    background: ${({ theme }) => theme.layoutStyles.headerColor};
    padding: ${({ theme }) => theme.layoutStyles.headerPaddingVertical} ${HORIZONTAL_PADDING};
    position: fixed;
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    -moz-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);

    .header-logo {
        cursor: pointer;
        user-select: none;
        height: 40px;

        @media (min-width: 1024px) {
            height: 54px;
        }
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        border: none !important;
        color: black !important;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border: none !important;
        color: black !important;
    }

    .ant-menu-horizontal {
        border: none;
        color: black !important;
    }

    .dispose {
        display: none;
        color: black !important;
    }

    .guest-icon-styles {
        :hover {
            cursor: pointer;
        }
    }

    .ant-menu {
        align-items: middle !important;
        justify-content: flex-end !important;
    }
`;

export const ChefComponentStyles = styled(Row)``;

export const UserProfileComponent = styled.div`
    background: ${({ theme }) => theme.primaryColor};
    min-width: 150px;
    border-radius: 50px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 3px;

    :hover {
        cursor: pointer;
    }

    .text-padding {
        padding-left: 12px;
        line-height: 12px;
        max-width: 100px;
    }
`;

export const LocalizationAndProfileGroup = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const GlobalStyleForTooltip = createGlobalStyle`
  body {
    .ant-tooltip-inner {
      background-color: transparent;
      padding:0px;
      margin-top:-5px;
      border-radius:15px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
    .ant-tooltip-arrow{
        display:none
    }
  }
`;

export const LocalizationIconWrapper = styled.div`
    height: 33px;
    width: 33px;
    display: flex;
    align-items: middle;
    justify-content: center;
    :hover {
        cursor: pointer;
    }
`;

export const ButtonContainer = styled(Row)`
    flex-wrap: initial;
    @media (max-width: 768px) {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
    }
`;

export const SignUpButton = styled(Button)`
    width: 118px;
    height: 42px;
    border: 2px solid #7ed190 !important;
    @media (max-width: 768px) {
        width: 100%;
    }

    :hover {
        color: #ffffff !important;
    }

    :focus {
        color: #7ed190 !important;
    }
`;

export const SignInButton = styled(Button)`
    width: 118px;
    height: 42px;
    margin-right: 30px;

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;
    }
`;

export const CustomMenuItem = styled(Menu.Item)`
    @media (max-width: 1400px) {
        &.hideable-item {
            display: none;
        }
    }

    @media (min-width: 1025px) {
        &.hideable-item--large-screen {
            display: none !important;
        }
    }

    @media (max-width: 1800px) {
        &&&&& {
            margin: 0;
        }
    }
`;

export const CustomButton = styled.button`
    background-color: transparent;
    border: none;
`;

export const CustomLink = styled(ReactRouterLink)`
    display: flex;
    margin-right: 10px;
`;

export const CustomMenuText = styled(Text)`
    align-self: center;
    border-radius: 20px;
    padding: 0.7em;
    vertical-align: center;
`;
