/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomRow, CustomRadio, CustomButton } from './styles';
import { getCardList } from '../../../../../../../../services/Redux/Shared/payment/api';
import { Text } from '../../../../../../../components/Typography/styles';

const CardsRow = ({ setNewCard, setCardForm, setCardToken }) => {
    const dispatch = useDispatch();
    const [radioValue, setRadioValue] = useState(null);
    const [cardListToRender, setCardListToRender] = useState([]);

    useEffect(() => {
        dispatch(getCardList());
    }, []);

    const { cardList } = useSelector((state) => state.userPaymentStore);

    useEffect(() => {
        if (cardList) {
            setCardListToRender(cardList.cardDetails || []);

            if (cardList.cardDetails !== undefined && cardList.cardDetails.length > 0) {
                const filtered = cardList.cardDetails[0]?.cardToken;

                setRadioValue(filtered);
                setCardToken(filtered);
            }
        }
    }, [cardList]);

    const onRadioChange = (value) => {
        setRadioValue(value);
        setCardToken(value);
    };

    useEffect(() => {
        setCardForm(radioValue);
    }, [radioValue]);

    const onNewCard = () => {
        setNewCard(true);
        setCardForm(false);
    };

    return (
        <CustomRow>
            <CustomRow>
                <Text weight="semi-bold" size="16px">
                    Kayıtlı Kartlarımdan Seç
                </Text>
            </CustomRow>
            <CustomRow justify="space-between">
                {cardListToRender.length > 0 ? (
                    <Col span={12}>
                        <CustomRadio.Group style={{ color: 'green' }} size="large" onChange={(e) => onRadioChange(e.target.value)} value={radioValue}>
                            <Space direction="vertical">
                                {cardListToRender.map((item, index) => (
                                    <CustomRadio key={index} value={item.cardToken}>
                                        <CustomRow className="radio-row">
                                            <CustomRow>
                                                <Text weight="light">{item.cardAlias}</Text>
                                            </CustomRow>
                                            <CustomRow>
                                                <Text size="14px">
                                                    {item.binNumber}******{item.lastFourDigits}
                                                </Text>
                                            </CustomRow>
                                        </CustomRow>
                                    </CustomRadio>
                                ))}
                            </Space>
                        </CustomRadio.Group>
                    </Col>
                ) : (
                    <Col span={12}>
                        <CustomRow style={{ marginTop: '12px' }}>
                            <Text color="smoke" weight="light">
                                Kayıtlı kartınız bulunmamaktadır!
                            </Text>
                        </CustomRow>
                    </Col>
                )}

                <Col>
                    <CustomRow justify="center" align="middle" className="button-row">
                        <CustomButton type="secondaryReverse" onClick={() => onNewCard()}>
                            <Text weight="semi-bold" color="green">
                                {cardListToRender.length !== 0 ? 'Başka Kartla Öde' : 'Kartla Öde'}
                            </Text>
                        </CustomButton>
                    </CustomRow>
                </Col>
            </CustomRow>
        </CustomRow>
    );
};

export default CardsRow;
