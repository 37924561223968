import React from 'react';
import { HowWorksStyles, HowWorksWrapper, HowWorksTitle } from './styles';
import { Title, Paragraph } from '../../../../components/Typography/styles';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import Bg from '../../../../../Assets/Shared/AboutUs/howtowork.svg';

const HowWorksSection = () => {
    const { sm } = useMediaQuery();
    return (
        <HowWorksStyles backgroundImage={sm ? '' : Bg}>
            <HowWorksWrapper style={{ width: sm ? '100%' : '70%' }}>
                <HowWorksTitle>
                    <Title style={{ textAlign: sm && 'center' }} weight="bold" color="#F38262" size="mediumTitle">
                        Döngü Nasıl Çalışır?
                    </Title>
                </HowWorksTitle>
                <Paragraph style={{ textAlign: sm && 'center' }} color="#000" weight="light" size="description">
                    Döngü, gıda servis sektöründeki işletmelerin arz fazlasını, üyeliği tamamen ücretsiz olan web sitemiz üzerinden tüketicilerle
                    buluşturuyor. Üstelik aynı kalite standartlarını koruyarak! Restoranlar böylece, ürünlerini Döngü’ye özel sürpriz fiyatlar ve
                    sürpriz paketlerle müşterilerine ulaştırma şansı buluyor. Döngü işletmeleri, günün herhangi bir saatinde farklı paket içeriklerini
                    satışa çıkarabilir ve siparişleri için zaman aralıklarını tanımlayabilir. <br />
                    <div style={{ paddingBottom: '3%' }} />
                    İşletmeler Döngü satışları için “Gel-Al” saatlerini kendileri belirliyor. Kurye ile çalışan işletmeler, yine kendilerinin
                    belirlediği saatlerde, Döngü müşterilerine “Kapıda Teslim” seçeneği sunabiliyorlar. Ürünlerin veya paketlerin satışı için zaman
                    sınırlamasını ortadan kaldıran Döngü, işletmelere 24 saat, ileriye dönük satış şansı tanıyor.
                </Paragraph>
            </HowWorksWrapper>
            {/* <VideoSkeleton src={VideoSkeletonImage} alt="red-circles" /> */}
        </HowWorksStyles>
    );
};

export default HowWorksSection;
