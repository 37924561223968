import { Col } from 'antd';
import styled from 'styled-components';
import { between } from 'polished';
import Card from '../../../../../components/Card';
import button from '../../../../../components/Button';
import { theme as Theme } from '../../../../../themes/theme';

export const GraphCardStyles = styled(Card)`
    .ant-card-body {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const Content = styled(Col)`
    min-height: 337px;
    display: flex;
    flex-direction: column;
    align-items: middle;
    justify-content: space-between;
    .ant-progress {
        display: flex;
    }
`;

export const ButtonContainer = styled.div`
    width: 90% !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 15px;
    background: ${({ theme }) => theme.contentBackground};
    height: 60px;
    width: 100%;
`;

export const Button = styled(button)`
    color: ${(props) => (props.ghost ? 'black' : Theme.inactiveColor)} !important;
    background: ${(props) => (props.ghost ? Theme.quinaryColor : 'transparent')};
    font-weight: 600;
    font-size: ${between('8px', '14px')};

    height: 40px !important;
    width: 46%;
    border: none;
    box-shadow: none !important;

    &:hover {
        color: #000 !important;
        background: #bcbcbc;
    }
`;

export const CustomButton = styled(button)`
    height: 48px;
`;

export const GraphicWrapper = styled.div`
    height: 337px;
    width: 100%;
`;
