/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: null,

    createCard: null,
    createCardError: null,

    cardList: null,
    cardListError: null,

    deleteCard: null,
    deleteCardError: null,

    getIp: null,
    getIpError: null,

    paymentInquiry: null,
    paymentInqyiryError: null,
};

// CREATE

const createCardStartReducer = (state, action) => ({
    ...state,
    loading: true,
});

const createCardSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    createCard: action.payload,
});

const createCardFailReducer = (state, action) => ({
    ...state,
    loading: false,
    createCardError: action.payload,
});

// CARD LIST

const getCardStartReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getCardSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    cardList: action.payload,
});

const getCardFailReducer = (state, action) => ({
    ...state,
    loading: false,
    cardListError: action.payload,
});

// CARD DELETE

const deleteCardStartReducer = (state, action) => ({
    ...state,
    loading: true,
});

const deleteCardSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    deleteCard: action.payload,
});

const deleteCardFailReducer = (state, action) => ({
    ...state,
    loading: false,
    deleteCardError: action.payload,
});

// GET IP

const getIpStartReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getIpSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    getIp: action.payload.data.IPv4,
});

const getIpFailReducer = (state, action) => ({
    ...state,
    loading: false,
    getIpError: action.payload,
});

// GET IP

const getPaymentInquiryStartReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getPaymentInquirySuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    paymentInquiry: action.payload,
});

const getPaymentInquiryFailReducer = (state, action) => ({
    ...state,
    loading: false,
    paymentInqyiryError: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_CARD_REGISTER_START:
            return createCardStartReducer(state);
        case actionTypes.CREATE_CARD_REGISTER_SUCCESS:
            return createCardSuccessReducer(state, action);
        case actionTypes.CREATE_CARD_REGISTER_FAIL:
            return createCardFailReducer(state, action);
        case actionTypes.GET_CARD_LIST_START:
            return getCardStartReducer(state);
        case actionTypes.GET_CARD_LIST_SUCCESS:
            return getCardSuccessReducer(state, action);
        case actionTypes.GET_CARD_LIST_FAIL:
            return getCardFailReducer(state, action);
        case actionTypes.DELETE_CARD_START:
            return deleteCardStartReducer(state);
        case actionTypes.DELETE_CARD_SUCCESS:
            return deleteCardSuccessReducer(state, action);
        case actionTypes.DELETE_CARD_FAIL:
            return deleteCardFailReducer(state, action);
        case actionTypes.GET_IP_START:
            return getIpStartReducer(state);
        case actionTypes.GET_IP_SUCCESS:
            return getIpSuccessReducer(state, action);
        case actionTypes.GET_IP_FAIL:
            return getIpFailReducer(state, action);
        case actionTypes.GET_PAYMENT_INQUIRY_START:
            return getPaymentInquiryStartReducer(state);
        case actionTypes.GET_PAYMENT_INQUIRY_SUCCESS:
            return getPaymentInquirySuccessReducer(state, action);
        case actionTypes.GET_PAYMENT_INQUIRY_FAIL:
            return getPaymentInquiryFailReducer(state, action);
        default:
            return state;
    }
};
export default reducer;
