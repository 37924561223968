import { Row, Col } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomCard, CustomRow } from './styles.js';
import { Text } from '../../../../../../components/Typography/styles.js';

const ContactCard = () => {
    const selector = useSelector;

    const storeData = selector((state) => state.storeStore.storeData);

    if (storeData) {
        return (
            <CustomCard>
                <Row>
                    <Text weight="semi-bold">Mağaza Bilgisi:</Text>
                </Row>
                <CustomRow>
                    <Col span={3}>
                        <Text weight="semi-bold">İşletme Adı: </Text>
                    </Col>
                    <Col>
                        <Text weight="light">{storeData.name}</Text>
                    </Col>
                </CustomRow>
                <CustomRow>
                    <Col span={2}>
                        <Text weight="semi-bold">Adres: </Text>
                    </Col>
                    <Col span={11}>
                        <Text weight="light">Örnek Mahalle, Örnek Caddesi, Örnek Sokak No:123 Beşiktaş, 340000 İstanbul</Text>
                    </Col>
                    <Col span={11}>
                        <Row justify="center">
                            <Col span={7}>
                                <Text weight="semi-bold">Web Sitesi: </Text>
                            </Col>
                            <Col span={11}>
                                <Text weight="light">{storeData.website_link}</Text>
                            </Col>
                        </Row>
                        <CustomRow className="email-row" justify="center">
                            <Col span={7}>
                                <Text weight="semi-bold">E-mail: </Text>
                            </Col>
                            <Col span={11}>
                                <Text weight="light">{storeData.email}</Text>
                            </Col>
                        </CustomRow>
                    </Col>
                </CustomRow>
                <CustomRow>
                    <Col span={3}>
                        <Text weight="semi-bold">Telefon 1: </Text>
                    </Col>
                    <Col span={6}>
                        <Text weight="light">{storeData.phone_number}</Text>
                    </Col>
                    <Col span={3}>
                        <Text weight="semi-bold">Telefon 2: </Text>
                    </Col>
                    <Col span={5}>
                        <Text weight="light">{storeData.phone_number_2}</Text>
                    </Col>
                </CustomRow>
            </CustomCard>
        );
    }
    return null;
};

export default ContactCard;
