import React from 'react';
import { Row } from 'antd';
import { GeneralRatingCardStyles, CustomProgress, Content } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const GeneralRatingCard = ({ generalRating, voteCount }) => (
    <GeneralRatingCardStyles title={<Title text="Genel Puanlama" />}>
        <Content>
            <Row align="middle" justify="center">
                <CustomProgress
                    strokeWidth="13"
                    width="135px"
                    strokeColor="#F5BE5A"
                    trailColor="#E2E9EF"
                    type="circle"
                    percent={generalRating * 10}
                    format={(percent) => `${percent * 0.1}`.toString().slice(0, 3)}
                />
            </Row>
            <Row align="middle" justify="center">
                <Text size="title" weight="light" style={{ textAlign: 'center' }}>
                    {voteCount} Oy Kullanıldı
                </Text>
            </Row>
        </Content>
    </GeneralRatingCardStyles>
);

export default GeneralRatingCard;
