import styled from 'styled-components';
import { Row, Col } from 'antd';
import Button from '../../../components/Button';
import NotifyCard from '../components/NotifyCard';

export const HelpcenterPageStyles = styled(Row)``;
export const LeftWrapper = styled(Col)`
    margin-right: 32px;
`;
export const RightWrapper = styled(Col)`
    @media screen and (max-width: 1200px) {
        margin-top: 32px;
    }
`;

export const TipsContent = styled(Col)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

export const CustomBtn = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    min-width: 200px;
    border-radius: 0px !important;

    @media screen and (max-width: 1500px) and (min-width: 1200px) {
        min-width: 100%;
    }
`;

export const CustomNotifyCard = styled(NotifyCard)`
    padding-top: 20px !important;
`;
