import styled from 'styled-components';
import { Col, Row, Button as btn } from 'antd';
import Button from '../../../../../../components/Button';
import { Text } from '../../../../../../components/Typography/styles';

export const FoodItemStyles = styled(Col)`
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
export const Content = styled(Row)`
    .avatar-uploader > .ant-upload {
        width: 84px;
        height: 84px;
    }
`;
export const ContentLeft = styled(Col)``;
export const ContentRight = styled(Col)``;
export const Footer = styled(Row)`
    padding-top: 20px;
`;
export const AddLabelBtn = styled(Button)`
    border: 2px solid ${({ theme }) => theme.borderColor};
    padding: 0px 0px;
    border-radius: 3px;
    height: 30px !important;
    width: 26px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 9px;

    :hover {
        border-color: ${({ theme }) => theme.quaternaryColor};
    }
`;
export const FoodnameLabel = styled.div`
    padding: 5px 8px;
    border: 2px solid ${({ theme }) => theme.borderColor};
    margin-right: 8px;
    border-radius: 3px;
    margin-top: 4px;
`;

export const CustomBtn = styled(Button)`
    padding: 7px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 32px !important;
    :hover {
        border-color: ${({ theme }) => theme.quaternaryColor};
    }
    width: 170px;
`;

export const CustomText = styled(Text)`
    &.buttons {
        margin-left: 3px;
    }
`;

export const CancelButton = styled(btn)`
    height: 32px !important;
    width: 100%;
    border: 2px solid #f38262 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ant-btn:hover,
    &.ant-btn:focus {
        border-color: #f38262;
        transition: 0.2s;
    }
`;
