/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import controlpanel from '../../../../../../Assets/Dashboard/sidebar-icons/control-panel.svg';
import helpdesk from '../../../../../../Assets/Dashboard/sidebar-icons/helpdesk.svg';
import calendar from '../../../../../../Assets/Dashboard/sidebar-icons/calendar.svg';
import packet from '../../../../../../Assets/Dashboard/sidebar-icons/packet-details.svg';
import stats from '../../../../../../Assets/Dashboard/sidebar-icons/stats.svg';
import store from '../../../../../../Assets/Dashboard/sidebar-icons/store.svg';
import { SiderStyles, MenuWrapper, BtnContent, SiderButton, BtnText, Menu } from './styles';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Sider = () => {
    const { SubMenu } = Menu;
    const siderData = [
        {
            key: 1,
            title: 'Kontrol Paneli',
            path: '/dashboard',
            icon: controlpanel,
        },
        {
            key: 2,
            title: 'Takvim',
            path: '/dashboard/calendar',
            icon: calendar,
        },
        {
            key: 3,
            title: 'Paket Ayarları',
            icon: packet,
            subMenu: [
                {
                    key: 3.1,
                    title: 'Paket Bilgisi',
                    path: '/dashboard/packet',
                },
                {
                    key: 3.2,
                    title: 'Menü Ayarları',
                    path: '/dashboard/menusettings',
                },
            ],
        },
        {
            key: 4,
            title: 'İstatistikler',
            path: '/dashboard/stats',
            icon: stats,
        },
        {
            key: 5,
            title: 'Mağazanız',
            icon: store,
            subMenu: [
                {
                    key: 5.1,
                    title: 'Mağaza Bilgisi',
                    path: '/dashboard/store',
                },

                {
                    key: 5.2,
                    title: 'Satışlarınız',
                    path: '/dashboard/orders',
                },
            ],
        },
        {
            key: 6,
            title: 'Yardım Merkezi',
            path: '/dashboard/helpcenter',
            icon: helpdesk,
        },
    ];

    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;
    const { lg } = useResponsive();
    const [showTitle, setShowTitle] = useState(!lg);
    const [openKey, setOpenKey] = useState(['']);
    const [subKey, setSubKey] = useState(null);
    const [activeKey, setActiveKey] = useState(false);

    const Button = ({ className, icon, children, onClick }) => (
        <SiderButton className={className} onClick={onClick}>
            <BtnContent align="middle" justify="start">
                <div className="icon-container">
                    <img src={icon} alt="" />
                </div>
                {children}
            </BtnContent>
        </SiderButton>
    );

    if (lg && showTitle === true) {
        setShowTitle(false);
        setOpenKey(['']);
        setTimeout(() => {
            setActiveKey(!activeKey);
        }, 150);
    }

    const onOpenKeysChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKey.indexOf(key) === -1);
        if (siderData.indexOf(latestOpenKey) === -1) {
            setOpenKey(keys);
        } else {
            setOpenKey(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <SiderStyles width={showTitle ? 300 : 98}>
            <MenuWrapper span={24}>
                <Row>
                    {siderData.map(({ key, title, path, icon, subMenu }) =>
                        !subMenu ? (
                            <Button
                                onClick={() => {
                                    history.push(path);
                                    setSubKey(null);
                                }}
                                className={pathname === path && 'active-nav-item'}
                                icon={icon}
                                key={key}>
                                {showTitle && (
                                    <BtnText color="white" weight="semi-bold" size="noble-regular">
                                        {title}
                                    </BtnText>
                                )}
                            </Button>
                        ) : (
                            <Menu onOpenChange={onOpenKeysChange} openKeys={openKey} mode="inline" arrow={activeKey} padding={icon === packet}>
                                <SubMenu
                                    className={key === subKey && activeKey && 'active-nav-item-sub'}
                                    title={title}
                                    key={key}
                                    itemIcon={null}
                                    disabled={activeKey}
                                    icon={<img src={icon} alt="" />}>
                                    {subMenu.map((sub) => (
                                        <Button
                                            key={sub.key}
                                            onClick={() => {
                                                history.push(sub.path);
                                                setSubKey(key);
                                            }}
                                            className={pathname === sub.path && 'active-nav-item'}
                                            icon={sub.icon}>
                                            {showTitle && (
                                                <BtnText color="white" weight="semi-bold" size="noble-regular">
                                                    {sub.title}
                                                </BtnText>
                                            )}
                                        </Button>
                                    ))}
                                </SubMenu>
                            </Menu>
                        )
                    )}
                </Row>
                <Row align="middle" justify="center">
                    {!lg && (
                        <span className="circle">
                            {showTitle ? (
                                <LeftOutlined
                                    style={{ color: '#FFFFFF', fontSize: '32px', fontWeight: '900' }}
                                    onClick={() => {
                                        setShowTitle(false);
                                        setOpenKey(['']);
                                        setTimeout(() => {
                                            setActiveKey(!activeKey);
                                        }, 150);
                                    }}
                                />
                            ) : (
                                <RightOutlined
                                    style={{ color: '#FFFFFF', fontSize: '32px', fontWeight: '900' }}
                                    onClick={() => {
                                        setShowTitle(true);
                                        setActiveKey(!activeKey);
                                        if (subKey !== null) onOpenKeysChange(['', subKey.toString()]);
                                    }}
                                />
                            )}
                        </span>
                    )}
                </Row>
            </MenuWrapper>
        </SiderStyles>
    );
};

export default Sider;
