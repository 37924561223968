import { Row, Col, Modal, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../components/Button';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        display: none;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
`;

export const CustomDivider = styled(Divider)`
    width: 20px;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
`;

export const CustomButton = styled(Button)`
    width: 200px;
    height: 48px;
    border-radius: 4px;
`;

export const CategoryItem = styled(Col)`
    margin-top: 20px;
    text-align: center;
    justify-content: center;
`;

export const CategoryIcon = styled(Row)`
    fill: #bcbcbc;
    transition: 0.3s;
    width: 86px;
    height: 86px;
    &.unselected-row {
        border-radius: 50%;
        background-color: #bcbcbc;

        :hover {
            background-color: #ffbc41;
        }

        :focus {
            background-color: #ffbc41;
        }

        :active {
            background-color: #ffbc41;
        }
    }

    &.selected-row {
        border-radius: 50%;
        background-color: #ffbc41;
    }
`;
