// GET NOTIFICATIONS

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

// PATCH NOTIFICATION READ

export const PATCH_NOTIFICATIONS = 'PATCH_NOTIFICATIONS';
export const PATCH_NOTIFICATIONS_SUCCESS = 'PATCH_NOTIFICATIONS_SUCCESS';
export const PATCH_NOTIFICATIONS_FAIL = 'PATCH_NOTIFICATIONS_FAIL';
