import React, { useState } from 'react';
import { QuestionWrapper, AnswerWrapper, QuestionItemStyles } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const QuestionItem = ({ question, answer }) => {
    const [isShown, setisShown] = useState(false);

    const handleClick = () => {
        setisShown(!isShown);
    };

    return (
        <QuestionItemStyles>
            <QuestionWrapper onClick={() => handleClick()}>
                <Text weight="semi-bold">{question}</Text>
            </QuestionWrapper>
            <AnswerWrapper style={{ display: isShown ? 'block' : 'none' }}>
                <Text color="smoke" family="secondary">
                    {answer}
                </Text>
            </AnswerWrapper>
        </QuestionItemStyles>
    );
};

export default QuestionItem;
