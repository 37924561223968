import { Row, Col } from 'antd';
import React from 'react';
import { CustomCard, CustomRow } from './styles.js';
import { Text } from '../../../../../../components/Typography/styles.js';

const CategoryCard = ({ storeCategories }) => (
    <CustomCard>
        <Row>
            <Col span={5}>
                <Text weight="semi-bold">Mevcut Kategoriler: </Text>
            </Col>
            <Col>
                <Text weight="light">{storeCategories.map(({ name: { name: nameValue } }) => `${nameValue}, `)}</Text>
            </Col>
        </Row>
        <CustomRow>
            <Col span={8}>
                <Text weight="semi-bold">Açık büfe yemek gıda güvenliği: </Text>
            </Col>
            <Col>
                <Text weight="light">Bu öğe açık büfeden yiyecek içeriyor.</Text>
            </Col>
        </CustomRow>
        <CustomRow>
            <Col span={3}>
                <Text weight="semi-bold">Ek Öneriler:</Text>
            </Col>
            <Col span={21}>
                <Text weight="light">
                    İşletmeniz, yiyeceklerinizi nasıl kullanacağınıza ve depolayacağınıza ilişkin öneriler tanımladıysa, bunları aşağıdaki alanda
                    paylaşabilirsiniz.
                </Text>
            </Col>
        </CustomRow>
    </CustomCard>
);

export default CategoryCard;
