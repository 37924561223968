/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */

import React, { useEffect, useState } from 'react';
import { Dropdown, Menu, Row } from 'antd';
import { DropdownButton } from '../styles';
import { ReactComponent as DropdownArrow } from '../../../../../../../../Assets/Shared/Profile/dropdown-arrow.svg';import { Text } from '../../../../../../../components/Typography/styles';

const IlceMenu = (props) => {
    const { city, province, handleAddressClick, handleAddressChange, visible2, setFieldValue} = props;

    const ankList = [
        'Akyurt',
        'Altındağ',
        'Ayas',
        'Balâ',
        'Beypazarı',
        'Çamlıdere',
        'Çankaya',
        'Çubuk',
        'Elmadağ',
        'Etimesgut',
        'Evren',
        'Gölbaşı',
        'Güdül',
        'Haymana',
        'Kahramankazan',
        'Kalecik',
        'Keçiören',
        'Kızılcahamam',
        'Mamak',
        'Nallıhan',
        'Polatlı',
        'Pursaklar',
        'Sincan',
        'Şereflikoçhisar',
        'Yenimahalle',
    ];
    const izmList = [
        'Aliağa',
        'Balçova',
        'Bayindir',
        'Bergama',
        'Beydag',
        'Bornova',
        'Buca',
        'Cesme',
        'Dikili',
        'Foça',
        'Guzelbahce',
        'Karaburun',
        'Karşıyaka',
        'Kemalpaşa',
        'Kınık',
        'Kiraz',
        'Konak',
        'Menderes',
        'Menemen',
        'Narlıdere',
        'Ödemiş',
        'Seferihisar',
        'Selçuk',
        'Tire',
        'Torbali',
        'Urla',
    ];
    const istList = [
        'Adalar',
        'Arnavutköy',
        'Ataşehir',
        'Avcılar',
        'Bağcılar',
        'Bahçelievler',
        'Bakırköy',
        'Başakşehir',
        'Beşiktaş',
        'Beykoz',
        'Beyoğlu',
        'Büyükçekmece',
        'Çatalca',
        'Eminönü',
        'Esenler',
        'Eyüp',
        'Fatih',
        'Gaziosmanpaşa',
        'Güngören',
        'Kadıköy',
        'Kağıthane',
        'Kartal',
        'Küçükçekmece',
        'Maltepe',
        'Pendik',
        'Sarıyer',
        'Silivri',
        'Şile',
        'Şişli',
        'Sultanbeyli',
        'Tuzla',
        'Ümraniye',
        'Üsküdar',
        'Zeytinburnu',
    ];

    const [ilceList, setIlceList] = useState(istList);

    useEffect(() => {
        if (city.toLocaleLowerCase() === 'istanbul') {
            setIlceList(istList);
            setFieldValue('province', istList[0]);
        } else if (city.toLocaleLowerCase() === 'ankara') {
            setIlceList(ankList);
            setFieldValue('province', ankList[0]);
        } else if (city.toLocaleLowerCase() === 'izmir') {
            setIlceList(izmList);
            setFieldValue('province', izmList[0]);
        }
    }, [city]);
    
    const menu = (
        <Menu onClick={handleAddressClick}>
            {ilceList?.map((item, index) => (
                <Menu.Item key={index} onClick={() => setFieldValue('province', item)}>
                    <Text size="14px">{item}</Text>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            overlayStyle={{ height: 300, overflow: 'auto', border: '2px solid #F2F2FC' }}
            trigger={['click']}
            onVisibleChange={handleAddressChange}
            visible={visible2}>
            <DropdownButton>
                <Row align="middle" justify="space-between">
                    <Text size="14px">{province}</Text>
                    <DropdownArrow />
                </Row>
            </DropdownButton>
        </Dropdown>
    );
};

export default IlceMenu;
