/// //////////////////MOBILE
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const EnvironmentStyles = styled(Row)`
    width: 100%;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    padding-top: ${VERTICAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};

    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: 110%;
    background-position-y: 200%;
    background-repeat: no-repeat;
    height: 50vw;

    @media (max-width: 768px) {
        height: 100%;
    }
`;

export const RecycleImage = styled.img``;

export const EnvironmentContentWrapper = styled(Col)``;

export const EnvironmentTitle = styled(Row)`
    margin-bottom: 5%;
`;

export const EnvironmentContent = styled.div``;
