import styled from 'styled-components';
import { Select as select, Input as input, Row, Form as form, Checkbox as checkbox } from 'antd';
import { between } from 'polished';
import Modal from 'antd/lib/modal/Modal';
import { Title as title, Text as text } from '../../../../components/Typography/styles';
import Button from '../../../../components/Button/index';

export default styled.div`
    width: 100%;
    max-width: 750px;

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    background: #fff;
    margin-top: ${between('15px', '45px')};
`;

export const ModalContainer = styled.div`
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const SocialDiv = styled.div`
    width: 100%;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 28px;
`;

export const Title2 = styled(title)`
    padding-bottom: 22px;
    width: 110%;
    text-align: center;
    border-bottom: 4px solid #f2f2fc;
`;

export const Title = styled(title)`
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 4px solid #f2f2fc;
    width: 100%;
    text-align: center;
`;
export const LoginText = styled(text)`
    @media only screen and (max-width: 409px) {
        margin: auto;
    }
`;

export const SignupButton = styled(text)`
    cursor: pointer;
    margin-left: 5px;

    @media only screen and (max-width: 409px) {
        margin: auto;
    }
`;

export const Text = styled(text)``;

export const Content = styled(Row)`
    display: flex;
`;

export const Form = styled(form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
`;

export const Select = styled(select)`
    width: 100%;
    max-width: 467px;
    height: 60px;
    margin-top: ${between('15px', '20px')};

    .ant-select-arrow {
        right: 23px;
    }

    .ant-select-selector {
        height: 60px !important;
        border-radius: 5px !important;
    }

    .ant-select-selection-item {
        line-height: 60px !important;
    }
`;

export const Option = styled(Select.Option)``;

export const Input = styled(input)`
    width: 100%;
    max-width: 467px;
    height: 56px;
    border-radius: 5px;
`;

export const EmailInput = styled(Input)`
    margin-top: ${between('15px', '20px')};
`;

export const ContinueButton = styled(Button)`
    cursor: pointer;
    width: 212px;
    height: 44px;
    text-align: center;
    margin: auto;
    border: none;
    margin-top: 20px;
    margin-bottom: 30px;
    background: ${({ theme }) => theme.secondaryColor};
    border-radius: 4px;

    &:hover {
        background: ${({ theme }) => theme.secondaryColor};
    }

    &:focus {
        background: ${({ theme }) => theme.secondaryColor} !important;
    }

    img {
        margin-left: 20px;
        width: 8px;
        height: 11px;
    }
`;

export const LongButton = styled(Button)`
    width: 436px;
    height: 48px;
    text-align: center;
    border-radius: 2px;
    margin-bottom: 12px;
    justify-content: center;
    display: flex;

    align-items: center;
`;

export const ForgotPasswordButton = styled(Button)`
    &.ant-btn:hover,
    &.ant-btn:focus {
        background-color: transparent !important;
        transition: 0.2s;
    }
    cursor: pointer;
    width: 212px;
    height: 44px;
    text-align: center;
    margin: auto;
    border: none;
    margin-bottom: 30px;
    background: tansparent;
    border-radius: 4px;
`;

export const Checkbox = styled(checkbox)`
    margin-right: 5px;
`;

export const PasswordInput = styled(input.Password)`
    width: 100%;
    max-width: 467px;
    border-radius: 5px;
    height: 56px;
`;

export const ModalWrapperStlye = styled(Modal)`
    justify-content: center;
    align-items: center;
    .ant-modal-content,
    .ant-modal-header {
        border-radius: 20px !important;
    }
`;

export const Modal2WrapperStlye = styled(Modal)`
    justify-content: center;
    align-items: center;
    .ant-modal-content,
    .ant-modal-header {
        border-radius: 20px !important;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
`;
