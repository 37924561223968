import React from 'react';
import { Row, Col } from 'antd';
import { CategoriesCardStyles, CustomProgress, Content } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const CategoriesCard = ({ serviceRating, qualityRating, mealRating }) => (
    <CategoriesCardStyles title={<Title text="Kategoriler" />}>
        <Content>
            <Row align="middle" justify="center">
                <Col span={8} align="middle" justify="center">
                    <Text size="18px" weight="light">
                        Servis
                    </Text>
                    <Row align="middle" justify="center">
                        <CustomProgress
                            strokeWidth="13"
                            width="100px"
                            strokeColor="#9FD5A4"
                            trailColor="#E2E9EF"
                            type="circle"
                            percent={serviceRating}
                            format={(percent) => `${percent}`.toString().slice(0, 3)}
                        />
                    </Row>
                </Col>
                <Col span={8} align="middle" justify="center">
                    <Text size="18px" weight="light">
                        Kalite
                    </Text>
                    <Row align="middle" justify="center">
                        <CustomProgress
                            strokeWidth="13"
                            width="100px"
                            strokeColor="#E5876A"
                            trailColor="#E2E9EF"
                            type="circle"
                            percent={qualityRating}
                            format={(percent) => `${percent}`.toString().slice(0, 3)}
                        />
                    </Row>
                </Col>
                <Col span={8} align="middle" justify="center">
                    <Text size="18px" weight="light">
                        Yemek
                    </Text>
                    <Row align="middle" justify="center">
                        <CustomProgress
                            strokeWidth="13"
                            width="100px"
                            strokeColor="#827F7F"
                            trailColor="#E2E9EF"
                            type="circle"
                            percent={mealRating}
                            format={(percent) => `${percent}`.toString().slice(0, 3)}
                        />
                    </Row>
                </Col>
            </Row>
        </Content>
    </CategoriesCardStyles>
);

export default CategoriesCard;
