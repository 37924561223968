import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import { HORIZONTAL_PADDING } from '../../../../helpers/constants';
import { Text } from '../../../components/Typography/styles';

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ModalWrapperStyle = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    .ant-modal-content,
    .ant-modal-header {
        border-radius: 16px !important;
        @media (max-width: 768px) {
            width: 190% !important;
        }
    }
`;

export const ModalLogoStyle = styled(Modal)``;

export const ModalTitleStyle = styled(Modal)`
    font-size: 36px;
    font-weight: 500px;
    margin-top: 32px;
`;

export const ModalContentStyle = styled(Text)`
    border-bottom: 38px;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    margin-bottom: 32px;
    text-align: center;
`;
