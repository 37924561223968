import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col } from 'antd';
import { CustomRow, CustomButton } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import RestaurantCard from '../RestaurantCard';
import AddressRow from './AddressRow';

const SummaryRow = (props) => {
    const history = useHistory();
    const { resp } = props;

    const options = { day: 'numeric', year: 'numeric', month: 'long', weekday: 'long', hour: 'numeric', minute: 'numeric' };

    return (
        <CustomRow className="wrapper-row" align="middle" justify="space-between">
            <Col span={12}>
                <CustomRow className="margin-t-20">
                    <Text weight="semi-bold">Sipariş Özeti</Text>
                </CustomRow>
                <CustomRow>
                    <Text>{new Date(resp.buying_time).toLocaleString('tr-TR', options)}</Text>
                </CustomRow>
                {resp.delivery_type === '1' ? (
                    <CustomRow>
                        <CustomRow>
                            <Text weight="light">18:00-21:00 Restorandan teslim alabilirsiniz.</Text>
                        </CustomRow>
                        <CustomRow>
                            <Text weight="light">Gel-Al Paket - Online Ödeme (Kredi Kartı)</Text>
                        </CustomRow>
                    </CustomRow>
                ) : (
                    <CustomRow>
                        <CustomRow>
                            <Text weight="light">
                                {new Date(resp?.courier_time?.start_date).toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })} -
                                {new Date(resp?.courier_time?.end_date).toLocaleTimeString('tr-TR', { hour: 'numeric', minute: 'numeric' })} Kurye
                                Teslimatta.
                            </Text>
                        </CustomRow>
                        <CustomRow>
                            <Text weight="light">Kurye Paket - Online Ödeme (Kredi Kartı)</Text>
                        </CustomRow>
                    </CustomRow>
                )}
                <br />
                <CustomRow className="total-row">
                    <Col>
                        <CustomRow>
                            <Text weight="light">Sepetindeki ürün adedi: {resp.boxes.length}</Text>
                        </CustomRow>
                        <CustomRow>
                            <Text weight="light">Sipariş Tutarı: {resp.cost.toLocaleString('tr-TR')} TL</Text>
                        </CustomRow>
                        <CustomRow>
                            <Text>Ödenen Toplam Tutar: {resp.cost.toLocaleString('tr-TR')} TL</Text>
                        </CustomRow>
                    </Col>
                </CustomRow>
            </Col>
            <Col span={12}>
                <CustomRow>
                    <CustomRow justify="end">
                        <CustomButton type="secondaryReverse" onClick={() => history.push('/profilim/gecmis-siparislerim')}>
                            <Text weight="semi-bold" color="green">
                                Geçmiş Siparişlerim
                            </Text>
                        </CustomButton>
                    </CustomRow>
                    &nbsp;
                    <CustomRow justify="end">
                        <CustomButton type="secondary" onClick={() => history.push('/restoran-bul')}>
                            <Text weight="semi-bold" color="white">
                                Restoranları Gör
                            </Text>
                        </CustomButton>
                    </CustomRow>
                </CustomRow>
            </Col>
            <CustomRow className="teslim-row">
                <Text>Teslim Alınacak Adres</Text>
            </CustomRow>
            {resp.delivery_type === '1' ? <RestaurantCard resp={resp} /> : <AddressRow resp={resp} />}
        </CustomRow>
    );
};

export default SummaryRow;
