/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'antd';
import { CustomRow, CustomCheckbox, CustomButton, OdemeButton } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import { OnBilgi, Mesafeli } from './Info';

const LastRow = (props) => {
    const totalCost = localStorage.getItem('totalPrice');
    const { check1, check2, setCheck1, setCheck2, cardForm, newCard, order, threeD, setThreeD } = props;
    const [disable, setDisable] = useState(true);
    const [cardInfoCheck, setCardInfoCheck] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [conditionVisible, setConditionVisible] = useState(false);

    useEffect(() => {
        if (cardForm && newCard && cardForm.cvcCode && cardForm.cardName && cardForm.cardNumber && cardForm.expMonth && cardForm.expYear) {
            setCardInfoCheck(true);
        } else if (cardForm && !newCard) {
            setCardInfoCheck(true);
        } else {
            setCardInfoCheck(false);
        }
    }, [cardForm]);

    return (
        <CustomRow className="removeSmall">
            {(termsVisible || conditionVisible) && (
                <Modal
                    style={{ top: 50, bottom: 100 }}
                    scrollableBody
                    bodyStyle={{ height: 750, overflow: 'auto' }}
                    visible={termsVisible || conditionVisible}
                    closable={false}
                    okText="Tamam"
                    cancelText="Kapat"
                    onOk={() => {
                        if (termsVisible) {
                            setTermsVisible(!termsVisible);
                        } else if (conditionVisible) {
                            setConditionVisible(!conditionVisible);
                        }
                    }}
                    onCancel={() => {
                        if (termsVisible) {
                            setTermsVisible(!termsVisible);
                        } else if (conditionVisible) {
                            setConditionVisible(!conditionVisible);
                        }
                    }}>
                    {termsVisible && <OnBilgi />}
                    {conditionVisible && <Mesafeli />}
                </Modal>
            )}
            <CustomRow justify="space-between">
                <Col>
                    <CustomRow>
                        <CustomCheckbox checked={check1} onChange={() => setCheck1(!check1)}>
                            <Text size="11px" weight="semi-bold" onClick={() => setTermsVisible(!termsVisible)}>
                                <u>Ön Bilgilendirme Koşulları</u>’nı okudum, onaylıyorum.
                            </Text>
                        </CustomCheckbox>
                    </CustomRow>
                    <CustomRow>
                        <CustomCheckbox checked={check2} onChange={() => setCheck2(!check2)}>
                            <Text size="11px" weight="semi-bold" onClick={() => setConditionVisible(!conditionVisible)}>
                                <u>Mesafeli Satış Sözleşmesi</u>’ni okudum, onaylıyorum.
                            </Text>
                        </CustomCheckbox>
                    </CustomRow>
                    {newCard && (
                        <CustomRow>
                            <CustomCheckbox checked={threeD} onChange={() => setThreeD(!threeD)}>
                                <Text size="11px" weight="semi-bold">
                                    3D ile güvenli ödeme
                                </Text>
                            </CustomCheckbox>
                        </CustomRow>
                    )}
                </Col>
                <Col>
                    <CustomRow className="tutar-row" justify="center" align="center">
                        <Text size="14px">Ödenecek Tutar</Text>
                        <Text weight="semi-bold" size="18px" color="green">
                            &nbsp;&nbsp;&nbsp;&nbsp;{Number(totalCost).toLocaleString('tr-TR')}
                        </Text>
                    </CustomRow>
                </Col>
            </CustomRow>
            <CustomRow justify="center" className="button-row">
                {check1 && check2 && cardInfoCheck ? (
                    <OdemeButton type="secondary" onClick={order} style={{ width: '250px' }}>
                        <Text weight="semi-bold" color="white">
                            Ödeme Yap
                        </Text>
                    </OdemeButton>
                ) : (
                    <CustomButton type="secondary" disabled={disable}>
                        <Text weight="semi-bold" color="white">
                            Ödeme Yap
                        </Text>
                    </CustomButton>
                )}
            </CustomRow>
        </CustomRow>
    );
};

export default LastRow;
