import styled from 'styled-components';
import { Row } from 'antd';
import background from '../../../../../../Assets/Shared/CartPage/background.svg';
import Button from '../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;
    &.main-row {
        background: url(${background});
    }
    &.heading-row {
        height: 350px;
        margin-top: 120px;
    }
    &.haydi-row {
        height: 30px;
    }
    &.button-row {
        height: 50px;
        text-align: center;
    }

    &.button-row-last {
        margin-top: 1rem;
    }
`;

export const CustomButton = styled(Button)`
    width: 372px;
    height: 48px;
`;
