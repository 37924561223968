/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import { Wrapper, FoodsWrapper, FoodWrapper, TextContainer, CustomCheckbox } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import xmlFetch from '../../../../../../../services/Api/request';

const MenuItem = ({ boxId, mealsToRender, meals, foodPrices, setFoodPrices, selectedOption }) => {
    const [mealsId, setMealsId] = useState();

    useEffect(() => {
        setMealsId(meals.map((meal) => meal.id));
    }, [meals]);

    const addToBox = (id) => {
        if (selectedOption === 'Sürpriz Paket') return message.warn('Lütfen önce geçerli bir paket ismi seçiniz.');
        const res = xmlFetch({
            path: `box/add/meal/${boxId}/`,
            method: 'PUT',
            body: {
                meal: id,
            },
            sendToken: true,
        }).then(message.success('Meal Başarı ile Eklendi !'));
        setMealsId([...mealsId, id]);
        const { price } = mealsToRender.find((val) => val.id === id);
        setFoodPrices(foodPrices + price);
    };

    const deleteFromBox = (id) => {
        const res = xmlFetch({
            path: `box/add/meal/${boxId}/`,
            method: 'DELETE',
            body: {
                meal: id,
            },
            sendToken: true,
        }).then(message.success('Meal Başarı ile Silindi !'));

        setMealsId(mealsId.filter((val) => val !== id));
        const { price } = mealsToRender.find((val) => val.id === id);
        setFoodPrices(foodPrices - price);
    };

    return (
        <Wrapper span={24}>
            {mealsId !== undefined ? (
                <FoodsWrapper>
                    {mealsToRender
                        .map(({ id, name, price }) => (
                            <FoodWrapper align="middle" justify="space-between">
                                <TextContainer>
                                    <Text family="secondary" weight="medium" size="description">
                                        {name}
                                    </Text>
                                    <Text family="secondary" size="description">
                                        {price} TL
                                    </Text>
                                </TextContainer>
                                <CustomCheckbox
                                    checked={mealsId.includes(id)}
                                    onClick={() => (mealsId.includes(id) ? deleteFromBox(id) : addToBox(id))}
                                />
                            </FoodWrapper>
                        ))
                        .sort()}
                </FoodsWrapper>
            ) : (
                <Spin />
            )}
        </Wrapper>
    );
};

export default MenuItem;
