import { useState } from 'react';
import ModalStyles from './styles';
import Content from './Content/index';
import Footer from './Footer/index';

const Modal = ({ timeLabel, setTimeLabel, visible, setVisible, detail, setDetail, date, onCancel }) => {
    const [radioValue, setRadioValue] = useState(timeLabel);
    const [localDetail, setLocalDetail] = useState(detail); // store changes here before changing the parent detail
    return (
        <ModalStyles
            onCancel={onCancel}
            title="Özel Sebep Ekle"
            visible={visible}
            closable={false}
            footer={
                <Footer
                    setVisible={setVisible}
                    setTimeLabel={setTimeLabel}
                    detail={localDetail}
                    setDetail={setDetail} // change parent detail with local detail
                    radioValue={radioValue}
                    onCancel={onCancel}
                />
            }>
            <Content date={date} localDetail={localDetail} setLocalDetail={setLocalDetail} setRadioValue={setRadioValue} radioValue={radioValue} />
        </ModalStyles>
    );
};

export default Modal;
