/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Row, Modal, message, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Text } from '../../../../components/Typography/styles';
import { OrdersCardStyles, Content, Footer, NavButton, Spin } from './styles';
import orderDetailIcon from '../../../../../Assets/Dashboard/order-icon.png';
import { getOrderInfo } from '../../../../../services/Redux/Dashboard/orders/api.js';
import DetailModal from '../components/DetailModal';
import leftArrow from '../../../../../Assets/Dashboard/left-arrow.png';
import rightArrow from '../../../../../Assets/Dashboard/right-arrow.png';
import { deleteOrder, completeOrder } from '../../../../../services/Redux/Shared/profile/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);
const active = ['1', '2', '3', '9'];
const completed = ['6'];
const canceled = ['4', '5'];

const OrdersCard = ({ storeId }) => {
    const dispatch = useDispatch();
    const selector = useSelector;
    const [count] = useState(7);
    const [page, setPage] = useState(1);
    const [orderTableData, setOrderTableData] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);
    const [isCompleteVisible, setIsCompleteVisible] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState();
    const [orderToComplete, setOrderToComplete] = useState();
    const [selectedOrderDetail, setSelectedOrderDetail] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        dispatch(getOrderInfo());
    }, [dispatch]);

    // !!! Every 10 seconds, we trigger the function repeteadly. But this can be changed.
    useEffect(() => {
        setInterval(() => dispatch(getOrderInfo()), 10000);
    }, []);

    const orders = selector((state) => state.ordersStore.ordersData);
    const sortedOrders = orders?.sort(function (a, b) {
        const dateA = new Date(a.buying_time);
        const dateB = new Date(b.buying_time);
        return dateB - dateA;
    });

    useEffect(() => {
        if (sortedOrders) {
            const filtered = sortedOrders?.filter(({ boxes }) => boxes[0].store.id === storeId && boxes);
            if (filtered.length > 0) setPageCount(Math.ceil(filtered.length / 7));
            else setPageCount(1);
            setOrderTableData(filtered.slice(0, 7));
            setIsLoaded(true);
        }
    }, [sortedOrders]);

    function prevPage() {
        const filtered = sortedOrders?.filter(({ boxes }) => boxes[0].store.id === storeId && boxes);
        const slice = filtered.slice(count * (page - 2), count * (page - 1));

        if (slice.length !== 0) {
            setOrderTableData(slice);
            setPage(page - 1);
        }
    }

    function nextPage() {
        const filtered = sortedOrders?.filter(({ boxes }) => boxes[0].store.id === storeId && boxes);
        const slice = filtered.slice(count * page, count * (page + 1));

        if (slice.length !== 0) {
            setOrderTableData(slice);
            setPage(page + 1);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOpenModal = (order) => {
        setSelectedOrderDetail(order);
        setIsModalVisible(true);
    };

    const handleDelete = async () => {
        try {
            if (orderToDelete.delivery_type === '2') {
                dispatch(deleteOrder(orderToDelete.id, orderToDelete.courier_time.id, orderToDelete.delivery_type));
            } else {
                dispatch(deleteOrder(orderToDelete.id, 0, '1'));
            }
        } catch (err) {
            message.alert(err);
        }
        setIsDeleteVisible(false);
        dispatch(getOrderInfo());
    };

    const handleComplete = async () => {
        try {
            if (orderToComplete.delivery_type === '2') {
                dispatch(completeOrder(orderToComplete.id, orderToComplete.courier_time.id, orderToComplete.delivery_type));
            } else {
                dispatch(completeOrder(orderToComplete.id, 0, '1'));
            }
        } catch (err) {
            message.alert(err);
        }
        setIsCompleteVisible(false);
        dispatch(getOrderInfo());
    };

    const columns = [
        {
            title: 'Siparişler',
            dataIndex: 'status',
            width: '20%',
            key: 'status',
            render: (status) => {
                if (active.includes(status)) {
                    return (
                        <Text weight="light" color="green">
                            Aktif
                        </Text>
                    );
                }
                if (canceled.includes(status)) {
                    return (
                        <Text weight="light" color="red" style={{ textDecorationLine: 'line-through' }}>
                            Sipariş iptal edildi
                        </Text>
                    );
                }
                if (completed.includes(status)) {
                    return <Text weight="light">Tamamlandı</Text>;
                }
            },
        },
        {
            title: 'Satın Alım Tarihi',
            dataIndex: 'buying_time',
            width: '20%',
            key: 'buying_time',
            render: (time) => <Text weight="light">{moment(time).format('DD-MM-YYYY | HH:mm')}</Text>,
        },
        {
            title: 'Adet',
            dataIndex: 'box_count',
            width: '10%',
            key: 'box_count',
            render: (boxCount) => <Text weight="light">{boxCount}</Text>,
        },
        {
            title: 'Referans Kodu',
            dataIndex: 'ref_code',
            width: '20%',
            key: 'ref_code',
            render: (ref) => <Text weight="light">{ref}</Text>,
        },
        {
            title: 'Sipariş Detayı',
            width: '10%',
            key: 'id',
            render: (order) => (
                <Row align="middle" justify="space-around">
                    <img src={orderDetailIcon} alt="" srcSet="" style={{ cursor: 'pointer' }} onClick={() => handleOpenModal(order)} />
                </Row>
            ),
        },
        {
            title: 'Sipariş Durumu',
            width: '20%',
            key: 'id',
            render: (order) => (
                <Row align="middle" justify="space-around">
                    {active.includes(order.status) && (
                        <Col align="middle" justify="space-around">
                            <Text
                                weight="bold"
                                style={{ cursor: 'pointer', color: '#F38262' }}
                                onClick={() => {
                                    setIsDeleteVisible(true);
                                    setOrderToDelete(order);
                                }}>
                                ❌
                            </Text>
                            <Text
                                weight="bold"
                                style={{ cursor: 'pointer', color: '#8FD89F', marginLeft: '10px' }}
                                onClick={() => {
                                    setIsCompleteVisible(true);
                                    setOrderToComplete(order);
                                }}>
                                ✅
                            </Text>
                        </Col>
                    )}
                </Row>
            ),
        },
    ];
    return (
        <OrdersCardStyles title={<Title text="Siparişler" />}>
            <Modal
                visible={isDeleteVisible}
                closable={false}
                okText="Evet"
                cancelText="İptal"
                onOk={() => handleDelete()}
                onCancel={() => setIsDeleteVisible(false)}
                centered>
                <Col>
                    <Text
                        weight="bold"
                        style={{ cursor: 'pointer', color: '#8FD89F', display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                        ❌
                    </Text>
                    <Text weight="medium" style={{ display: 'flex', justifyContent: 'center' }}>
                        Siparişiniz iptal ediliyor... <br /> Onaylıyor musunuz?
                    </Text>
                </Col>
            </Modal>
            <Modal
                visible={isCompleteVisible}
                closable={false}
                okText="Evet"
                cancelText="İptal"
                onOk={() => handleComplete()}
                onCancel={() => setIsCompleteVisible(false)}
                centered>
                <Col>
                    <Text
                        weight="bold"
                        style={{ cursor: 'pointer', color: '#8FD89F', display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                        ✅
                    </Text>
                    <Text weight="medium" style={{ display: 'flex', justifyContent: 'center' }}>
                        Siparişiniz tamamlanacaktır... <br /> Onaylıyor musunuz?
                    </Text>
                </Col>
            </Modal>
            <Content span={24}>
                <Table
                    tableLayout="auto"
                    rowKey="id"
                    loading={!isLoaded && <Spin size="large" />}
                    columns={columns}
                    dataSource={orderTableData || ''}
                    pagination={false}
                />
                <Footer align="middle" justify="center">
                    {page !== 1 && (
                        <NavButton
                            src={leftArrow}
                            alt="left-arrow"
                            onClick={() => {
                                if (page !== 1) {
                                    prevPage();
                                }
                            }}
                        />
                    )}
                    <Text>
                        {page} / {pageCount}
                    </Text>
                    {page !== pageCount && <NavButton src={rightArrow} alt="right-arrow" onClick={() => nextPage()} />}
                </Footer>
            </Content>
            <DetailModal order={selectedOrderDetail} isModalVisible={isModalVisible} visible={isModalVisible} onCancel={handleCancel} />
        </OrdersCardStyles>
    );
};

export default OrdersCard;
