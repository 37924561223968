import React from 'react';
import { Row } from 'antd';
import { useTheme } from 'styled-components';
import { between } from 'polished';
import { useHistory } from 'react-router';
import { BusinessSectionStyles, ContentWrapper, BeABusinessButton } from './styles';
import business from '../../../../../Assets/Shared/Landing/business.svg';
import { Text } from '../../../../components/Typography/styles';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import image from '../../../../../Assets/Shared/ForBusiness/isletmeler_bg.svg';

const BusinessSection = () => {
    const theme = useTheme();
    const { xs, sm } = useMediaQuery();
    const history = useHistory();
    return (
        <BusinessSectionStyles span={24} backgroundColor={theme.secondaryLightColor} backgroundImage={sm ? image : business}>
            <Row
                style={
                    sm && {
                        height: '100%',
                        width: '100vw',
                    }
                }
                justify="end">
                <ContentWrapper span={sm ? 24 : 14}>
                    {xs ? (
                        <>
                            <Row style={{ margin: '2rem 0' }}>
                                <Text color="red" weight="bold" style={{ fontSize: '1.5rem', lineHeight: '3rem', textAlign: 'center' }}>
                                    İşletme olarak sen de Döngü’de yerini al
                                </Text>
                            </Row>
                            <Row>
                                <Text style={{ textAlign: 'center' }} size="description" color="#000" weight="bold">
                                    Artık İndirim Fiyatı Etiketi Koymanıza Gerek Yok. <br /> Fazlayı Ek Satışa Döndürün.
                                </Text>
                            </Row>
                            <Row style={{ margin: '2rem 0' }}>
                                <Text style={{ textAlign: 'center' }} size="description" color="#000">
                                    İşletmenin boyutu veya türü ne olursa olsun, Döngü işletmenin ek gelir elde etmesine, maliyetlerinizin düşürmesine
                                    ve çevre dostu bir markayı tanıtmasına yardımcı olabilir.
                                </Text>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row style={{ marginBottom: between('30px', '60px') }}>
                                <Text
                                    style={{ textAlign: sm ? 'center' : 'left' }}
                                    size={between('20px', '44px', '320px', '1920px')}
                                    color="red"
                                    weight="bold">
                                    Artık İşletmenizin Arz Fazlasını Döngü ile Ek Kazanca Çevirebilirsin! Çapraz Satışlarla İsrafını Minimuma İndirmek
                                    İçin Döngü’de Ol!
                                </Text>
                            </Row>
                        </>
                    )}
                    <Row style={{ marginBottom: between('30px', '60px'), justifyContent: sm ? 'center' : 'flex-start' }}>
                        <BeABusinessButton
                            onClick={() => {
                                history.push('isletmeler-icin');
                                window.scrollTo(0, 0);
                            }}
                            type="primary"
                            size="regular">
                            <Text weight="semi-bold" color="white">
                                İş Ortağı Ol
                            </Text>
                        </BeABusinessButton>
                    </Row>
                </ContentWrapper>
            </Row>
        </BusinessSectionStyles>
    );
};

export default BusinessSection;
