import { message } from 'antd';
import * as actionTypes from './actionTypes';

// GET
export const getBoxesStartAction = () => ({
    type: actionTypes.GET_BOXES,
});

export const getBoxesSuccessAction = (favourites) => ({
    type: actionTypes.GET_BOXES_SUCCESS,
    payload: favourites,
});

export const getBoxesFailAction = (error) => ({
    type: actionTypes.GET_BOXES_FAIL,
    payload: error,
});

// GET BOX CATEGORIES
export const getBoxCategoriesStartAction = () => ({
    type: actionTypes.GET_BOX_CATEGORIES,
});

export const getBoxCategoriesSuccessAction = (favourites) => ({
    type: actionTypes.GET_BOX_CATEGORIES_SUCCESS,
    payload: favourites,
});

export const getBoxCategoriesFailAction = (error) => ({
    type: actionTypes.GET_BOX_CATEGORIES_FAIL,
    payload: error,
});

// POST BOX CATEGORIES
export const postBoxCategoriesStartAction = () => ({
    type: actionTypes.POST_BOX_CATEGORIES,
});

export const postBoxCategoriesSuccessAction = () => ({
    type: actionTypes.POST_BOX_CATEGORIES_SUCCESS,
});

export const postBoxCategoriesFailAction = (error) => ({
    type: actionTypes.POST_BOX_CATEGORIES_FAIL,
});

// GET BOX CATEGORIES NAME
export const getBoxCategoriesNamesStartAction = () => ({
    type: actionTypes.GET_BOX_CATEGORIES_NAME,
});

export const getBoxCategoriesNamesSuccessAction = (favourites) => ({
    type: actionTypes.GET_BOX_CATEGORIES_NAME_SUCCESS,
    payload: favourites,
});

export const getBoxCategoriesNamesFailAction = (error) => ({
    type: actionTypes.GET_BOX_CATEGORIES_NAME_FAIL,
    payload: error,
});

// GET BOX TAGS
export const getBoxTagsStartAction = () => ({
    type: actionTypes.GET_BOX_TAGS,
});

export const getBoxTagsSuccessAction = (favourites) => ({
    type: actionTypes.GET_BOX_TAGS_SUCCESS,
    payload: favourites,
});

export const getBoxTagsFailAction = (error) => ({
    type: actionTypes.GET_BOX_TAGS_FAIL,
    payload: error,
});

// POST BOX TAGS

export const postBoxTagsStartAction = (data) => ({
    type: actionTypes.POST_BOX_TAGS,
    payload: data,
});

export const postBoxTagsSuccessAction = () => {
    message.success('Tag has been created!');
    return {
        type: actionTypes.POST_BOX_TAGS_SUCCESS,
    };
};

export const postBoxTagsFailAction = () => ({
    type: actionTypes.POST_BOX_TAGS_FAIL,
});

// GET BOX MEALS
export const getBoxMealsStartAction = () => ({
    type: actionTypes.GET_BOX_MEALS,
});

export const getBoxMealsSuccessAction = (favourites) => ({
    type: actionTypes.GET_BOX_MEALS_SUCCESS,
    payload: favourites,
});

export const getBoxMealsFailAction = (error) => ({
    type: actionTypes.GET_BOX_MEALS_FAIL,
    payload: error,
});

// POST BOX MEALS
export const postBoxMealStartAction = () => ({
    type: actionTypes.POST_BOX_MEAL,
});

export const postBoxMealSuccessAction = () => ({
    type: actionTypes.POST_BOX_MEAL_SUCCESS,
});

export const postBoxMealFailAction = () => ({
    type: actionTypes.POST_BOX_MEAL_FAIL,
});

// UPDATE BOX MEAL
export const putBoxMealStartAction = () => ({
    type: actionTypes.PUT_BOX_MEAL,
});

export const putBoxMealSuccessAction = () => ({
    type: actionTypes.PUT_BOX_MEAL_SUCCESS,
});

export const putBoxMealFailAction = () => ({
    type: actionTypes.PUT_BOX_MEAL_FAIL,
});

// DELETE BOX MEAL
export const deleteBoxMealStartAction = () => ({
    type: actionTypes.DELETE_BOX_MEAL,
});

export const deleteBoxMealSuccessAction = () => ({
    type: actionTypes.DELETE_BOX_MEAL_SUCCESS,
});

export const deleteBoxMealFailAction = () => ({
    type: actionTypes.DELETE_BOX_MEAL_FAIL,
});
// GET BOX NAMES
export const getBoxNamesStartAction = () => ({
    type: actionTypes.GET_BOX_NAMES,
});

export const getBoxNamesSuccessAction = (names) => ({
    type: actionTypes.GET_BOX_NAMES_SUCCESS,
    payload: names,
});

export const getBoxNamesFailAction = (error) => ({
    type: actionTypes.GET_BOX_NAMES_FAIL,
    payload: error,
});

// UPDATE - DEFINE THE BOX

export const updateBoxesStartAction = () => ({
    type: actionTypes.UPDATE_BOXES,
});

export const updateBoxesSuccessAction = (box) => ({
    type: actionTypes.UPDATE_BOXES_SUCCESS,
    payload: box,
});

export const updateBoxesFailAction = (error) => ({
    type: actionTypes.UPDATE_BOXES_FAIL,
    payload: error,
});

// CREATE - BOX CREATE
export const createBoxesStartAction = () => ({
    type: actionTypes.CREATE_BOXES,
});

export const createBoxesSuccessAction = (box) => ({
    type: actionTypes.CREATE_BOXES_SUCCESS,
    payload: box,
});

export const createBoxesFailAction = (error) => ({
    type: actionTypes.CREATE_BOXES_FAIL,
    payload: error,
});
