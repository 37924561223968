/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import moment from 'moment';
import GeneralRatingCard from './components/GeneralRatingCard';
import CategoriesCard from './components/CategoriesCard';
import ScoringsCard from './components/ScoringsCard';
import BadgesCard from './components/BadgesCard';
import { StatisticsPageStyles, Wrapper, ContentTop, ContentMiddle, ContentBottom, ContentBottomLeft, ContentBottomRight } from './styles';
import SaleStatsCard from './components/SaleStatsCard';
import ProfitCard from './components/ProfitCard';
import GraphCard from './components/GraphCard';
import xml from '../../../../services/Api/request';
import Spin from '../../../components/Spinner';
import { WEEKLY, MONTHLY } from './components/NoDataFound/mockData';

const StatisticsPage = () => {
    const [statisticsToRender, setStatisticsToRender] = useState();
    const [weeklyDataToRender, setWeeklyDataToRender] = useState();
    const [monthlyDataToRender, setMonthlyDataToRender] = useState();
    const [noData, setNoData] = useState(false);
    const [isWeekly, setIsWeekly] = useState(true);

    useEffect(() => {
        (async () => {
            const result = await xml({
                path: `store/statistics/`,
                method: 'GET',
                sendToken: true,
            }).catch((err) => err[0] === 'Restoranınıza ait herhangi bir istatistik henüz bulunamadı!' && setNoData(err[0]));

            setStatisticsToRender(result);
        })();
        (async () => {
            const result = await xml({
                path: `store/statistics/weekly/?state_date=${moment().startOf('isoWeek').format('YYYY-MM-DD')}`,
                method: 'GET',
                sendToken: true,
            });
            setWeeklyDataToRender(result);
        })();
        (async () => {
            const result = await xml({
                path: `store/statistics/monthly/?year=${moment().year()}`,
                method: 'GET',
                sendToken: true,
            });
            setMonthlyDataToRender(result);
        })();
    }, []);

    return (
        <StatisticsPageStyles>
            {noData || (statisticsToRender && weeklyDataToRender && monthlyDataToRender) ? (
                <Wrapper xxl={22} xl={24}>
                    <ContentTop
                        gutter={[
                            { xs: 8, md: 8, xl: 16 },
                            { xs: 8, sm: 8, md: 16, lg: 16, xl: 16 },
                        ]}>
                        <Col span={7}>
                            <GeneralRatingCard
                                voteCount={noData ? 0 : statisticsToRender.total_vote}
                                generalRating={noData ? 0 : statisticsToRender.avg_review}
                            />
                        </Col>
                        <Col span={9}>
                            <CategoriesCard
                                serviceRating={noData ? 0 : statisticsToRender.service_avg}
                                qualityRating={noData ? 0 : statisticsToRender.quality_avg}
                                mealRating={noData ? 0 : statisticsToRender.meal_avg}
                            />
                        </Col>
                        <Col span={8}>
                            <ScoringsCard
                                fiveStarRating={noData ? 0 : statisticsToRender.five_star_avg}
                                fourStarRating={noData ? 0 : statisticsToRender.four_star_avg}
                                threeStarRating={noData ? 0 : statisticsToRender.three_star_avg}
                                twoStarRating={noData ? 0 : statisticsToRender.two_star_avg}
                                oneStarRating={noData ? 0 : statisticsToRender.one_star_avg}
                            />
                        </Col>
                    </ContentTop>
                    <ContentMiddle>
                        <Col span={24}>
                            <GraphCard
                                setIsWeekly={setIsWeekly}
                                isWeekly={isWeekly}
                                weeklyData={noData ? WEEKLY : weeklyDataToRender.data}
                                monthlyData={noData ? MONTHLY : monthlyDataToRender.data}
                            />
                        </Col>
                    </ContentMiddle>
                    <ContentBottom>
                        <ContentBottomLeft span={18}>
                            <BadgesCard />
                        </ContentBottomLeft>
                        <ContentBottomRight span={6}>
                            <SaleStatsCard
                                monthlyTotalBoxSold={noData ? 0 : monthlyDataToRender.total_box}
                                weeklyTotalBoxSold={noData ? 0 : weeklyDataToRender.total_box}
                                isWeekly={isWeekly}
                            />
                            <ProfitCard
                                monthlyTotalProfit={noData ? 0 : monthlyDataToRender.total_gain}
                                weeklyTotalProfit={noData ? 0 : weeklyDataToRender.total_gain}
                                isWeekly={isWeekly}
                            />
                        </ContentBottomRight>
                    </ContentBottom>
                </Wrapper>
            ) : (
                <Spin size="large" />
            )}
        </StatisticsPageStyles>
    );
};

export default StatisticsPage;
