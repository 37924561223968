import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Text } from '../../../../../components/Typography/styles';
import Button from '../../../../../components/Button';

export const CartWrapper = styled(Col)`
    background-color: white;
    border-radius: 18px;
    margin-bottom: 9%;
    margin-top: 1rem;
    padding-bottom: 5%;
    width: 330px;

    @media (min-width: 1280px) {
        margin-top: 0;
    }
`;

export const OverlayDiv = styled.div`
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
`;

export const TextWrapper = styled(Col)`
    border-bottom: 4px solid #f2f2fc;
    padding-top: 5%;
    padding-bottom: 7%;
    text-align: center;
    margin-bottom: 7%;
`;

export const DividerStyle = styled(Row)`
    width: 80%;
    height: 2px;
    background-color: #f2f2fc;
    margin-left: 11%;
    margin-top: 30px;
    margin-bottom: 20px;
`;

export const WideDividerStyle = styled(Row)`
    width: 100%;
    height: 2px;
    background-color: #f2f2fc;
    margin-top: 35px;
    margin-bottom: 7%;
`;

export const DividerWrapper = styled(Row)`
    justify-content: center;
    align-items: center;
    margin-bottom: 9%;
`;

export const ButtonWrapper = styled(Row)`
    justify-content: center;
    height: 48px;
    width: 100%;
`;

export const TextStyle = styled(Row)`
    margin-left: 11%;
`;

export const ItemRow = styled(Row)`
    width: 100%;
    padding-right: 20px;

    &.price-row {
        margin-bottom: 20px;
    }
`;

export const DiscountTextStyle = styled(Text)`
    margin-left: 30px;
    text-decoration: line-through;
`;

export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    width: 69px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 0px;
`;

export const MenuCol = styled(Col)`
    overflow: hidden;
`;

export const CustomRow = styled(Row)`
    &.total-row {
        background-color: #f2f2fc;
        height: 72px;
        border-radius: 4px;
        margin-top: 10px;
    }

    &.button-row {
        margin-top: 20px;
        padding-bottom: 30px;
    }

    &.normal-row {
        width: 100%;
        margin-left: 0px;
    }

    &.checkbox {
        margin-top: 10px;
    }

    &.margin {
        margin-top: 15px;
    }
`;

export const TextThrough = styled(Text)`
    text-decoration: line-through;
`;

export const CustomButton = styled(Button)`
    width: 100%;
    height: 48px;
`;
