import styled from 'styled-components';
import { Row, Col, Switch, Divider as divider, Select as select } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../../../components/Typography/styles';
import card from '../../../../../components/Card/index';

const { Option } = select;

export default styled(card)`
    width: 100%;
    max-width: 86%;
    min-height: 775px;
    height: 100%;
    .ant-card-head-title {
        text-align: center;
    }
    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 39px;
        padding-right: 39px;
    }
    margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Text = styled(text)``;

export const Content = styled(Row)`
    display: flex;
    margin-bottom: 32px;
`;

export const Item = styled(Col)`
    display: flex;
    flex-direction: column;
`;

export const Divider = styled(divider)`
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    background: ${({ theme }) => theme.borderColor};
`;

export const Switcher = styled(Switch)``;

export const Email = styled(Option)``;

export const Select = styled(select)`
    width: 100%;
    max-width: 372px;
    margin-top: 27px;
`;
