import styled from 'styled-components';
import { Col, Row } from 'antd';

export const CategoryItemStyles = styled(Row)`
    padding-left: 40px;
    padding-right: 40px;
    transition: 0.2s;
`;
export const Header = styled(Row)`
    .title-styles {
        background-color: #fafafe;
        border-radius: 5px;
    }
`;
export const Content = styled(Col)`
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const FoodnameLabel = styled.div`
    border: 2px solid ${({ theme }) => theme.borderColor};
    margin-right: 8px;
    border-radius: 3px;
    margin-top: 4px;
`;
