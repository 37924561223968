/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { CustomRow, CustomDivider } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { ReactComponent as UserLogo } from '../../../../../Assets/Shared/CartPage/user-logo.svg';
import TabsPane from './TabsPane';
import OrderComplete from './OrderCompletePage';
import { getAddressActive } from '../../../../../services/Redux/Shared/profile/api';

const Checkout = (props) => {
    const {
        timeInt,
        setTimeInt,
        tabKey,
        setTabKey,
        check1,
        check2,
        setCheck1,
        setCheck2,
        setComplete,
        complete,
        addressInfo,
        setAddressInfo,
        order,
        packet,
        setPacket,
        setCardToken,
        cardForm,
        setCardForm,
        newCard,
        setNewCard,
        threeD,
        setThreeD,
        isPaymentDone,
        setIsPaymentDone,
        checkedSaveCard,
        setCheckedSaveCard,
    } = props;

    /* const [timeInt, setTimeInt] = useState(0); */
    const user = JSON.parse(localStorage.getItem('user'));

    const dispatch = useDispatch();
    const selector = useSelector;

    useEffect(() => {
        if (user) {
            dispatch(getAddressActive());
        }
    }, []);

    const activeAddress = selector((state) => state?.profileStore?.activeAddress);
    const packageId = selector((state) => state?.customerOrderStore?.basketList[0]?.package_setting?.delivery_type);

    if (complete) {
        return <OrderComplete timeInt={timeInt} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} />;
    }
    return (
        <CustomRow className="main-row" justify="space-around">
            <Col span={2}>
                <CustomRow align="middle" justify="end">
                    <UserLogo />
                </CustomRow>
            </Col>
            <Col span={21}>
                <CustomRow justify="space-between">
                    <Col>
                        <Text>
                            {user.first_name} {user.last_name}
                        </Text>
                    </Col>
                    <Col>
                        <Text weight="light" color="smoke">
                            Konumum:&nbsp;
                        </Text>
                        {activeAddress && (
                            <Text weight="semi-bold" size="16px">
                                {activeAddress.address}
                            </Text>
                        )}
                    </Col>
                </CustomRow>
                <CustomDivider />
                <TabsPane
                    setComplete={setComplete}
                    tabKey={tabKey}
                    setTabKey={setTabKey}
                    check1={check1}
                    check2={check2}
                    setCheck1={setCheck1}
                    setCheck2={setCheck2}
                    timeInt={timeInt}
                    setTimeInt={setTimeInt}
                    addressInfo={addressInfo}
                    setAddressInfo={setAddressInfo}
                    order={order}
                    packet={packet}
                    setPacket={setPacket}
                    setCardToken={setCardToken}
                    cardForm={cardForm}
                    setCardForm={setCardForm}
                    newCard={newCard}
                    setNewCard={setNewCard}
                    threeD={threeD}
                    setThreeD={setThreeD}
                    checkedSaveCard={checkedSaveCard}
                    setCheckedSaveCard={setCheckedSaveCard}
                    packageId={packageId}
                />
            </Col>
        </CustomRow>
    );
};

export default Checkout;
