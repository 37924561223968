import styled from 'styled-components';
import { Row, Col } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../../../components/Typography/styles';
import card from '../../../../../components/Card/index';

export default styled(card)`
    width: 100%;
    max-width: 86%; //407px;
    height: 100%;
    min-height: 407px;
    .ant-card-head-title {
        text-align: center;
    }
    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 39px;
        padding-right: 39px;
    }
    margin-bottom: 30px;
    @media only screen and (max-width: 1200px) {
        margin-left: 32px;
    }
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Text = styled(text)`
    text-align: center;
`;

export const Content = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
    }
`;

export const Item = styled(Col)`
    display: flex;
    flex-direction: column;
`;
