import { Select, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';

export const WrapperStyle = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 3;
`;
export const BackgroundWrapper = styled.div`
    padding-top: 50px;
    display: flex;

    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    background-color: #8fd89f30;
    display: -2;
`;

export const FormContainer = styled.div``;

export const FormHeader = styled.div``;

export const FormWrapperStyle = styled(Modal)`
    .ant-modal-content,
    .ant-modal-header {
        border-radius: 16px !important;
    }
`;

export const ModalTitleStyle = styled.div`
    background-color: green;
`;
export const FormIconsStyle = styled.div`
    border-radius: 50px;
    border: 4px solid yellow;
`;

export const FormContentStyle = styled.div`
    background-color: orange;
`;

export const ConfirmationText = styled.div``;

export const CheckboxStyle = styled(Checkbox)`
    .ant-checkbox-inner,
    .ant-checkbox-input {
        transform: scale(2);
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: transparent;
        border-color: #d1d0d0 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: #8fd89f !important;
    }
`;

export const SelectList = styled(Select)`
    height: 56px !important;
    border: 2px solid #f2f2fc;
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        border: none !important;
        height: 50px !important;
        transition: all 0.3s !important;
        padding: 0 !important;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
        line-height: 50px !important;
    }
`;

export const FormWrapperStlye = styled.div``;

export const HeaderWrapper = styled(Row)`
    height: 98px;
    width: 100%;
    padding-bottom: 16px;
    padding-top: 21px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
`;

export const FooterWrapper = styled(Row)`
    height: 98px;
    width: 100%;
    background-color: #f69f8e;
    justify-content: center;
    align-items: center;
`;

export const FormRow = styled(Row)`
    justify-content: space-between;
    margin-bottom: 32px;
    .ant-select {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;
