/* eslint-disable no-console */
import * as actions from './actions';

// Delivery Info

export const getOrderInfo = () => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getOrderInfoAction());
        const Info = await service({
            method: 'GET',
            path: `order/`,
            sendToken: true,
        });
        dispatch(actions.getOrderInfoSuccessAction(Info.results));
        return Info;
    } catch (err) {
        console.log(err);
        dispatch(actions.getOrderInfoFailAction());
    }
};
