/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    categoryList: null,
    categoriesError: null,
    faqsList: null,
    faqsError: null,
};

const getCategoriesReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getCategoriesSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    categoryList: action.payload.results,
});

const getCategoriesFailReducer = (state, action) => ({
    ...state,
    loading: false,
    categoriesError: action.payload,
});

const getFaqsReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getFaqsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    faqsList: action.payload.results,
});

const getFaqsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    faqsError: action.payload,
});
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORIES:
            return getCategoriesReducer(state, action);
        case actionTypes.GET_CATEGORIES_SUCCESS:
            return getCategoriesSuccessReducer(state, action);
        case actionTypes.GET_CATEGORIES_FAIL:
            return getCategoriesFailReducer(state, action);
        case actionTypes.GET_FAQS:
            return getFaqsReducer(state, action);
        case actionTypes.GET_FAQS_SUCCESS:
            return getFaqsSuccessReducer(state, action);
        case actionTypes.GET_FAQS_FAIL:
            return getFaqsFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
