/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    restaurantInfo: null,
};

const getRestaurantInfoReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getRestaurantInfoSuccessReducer = (state, action) => ({
    loading: false,
    restaurantInfo: action.payload,
});

const getRestaurantInfoFailReducer = (state, action) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_RESTAURANT_INFO:
            return getRestaurantInfoReducer(state, action);
        case actionTypes.GET_RESTAURANT_INFO_SUCCESS:
            return getRestaurantInfoSuccessReducer(state, action);
        case actionTypes.GET_RESTAURANT_INFO_FAIL:
            return getRestaurantInfoFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
