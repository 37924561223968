import * as actionTypes from './actionTypes';

const initialState = {
    user: null,
    signedIn: false,
    loading: false,
    restaurantInfo: null,
    fbConnect: null,
    fbError: null,
};

const authStartReducer = (state) => ({
    ...state,
    loading: true,
});

const authFailReducer = (state) => ({
    ...state,
    loading: false,
});

const authSuccessReducer = (state, action) => ({
    ...state,
    user: action.userData,
    restaurantInfo: action.userData.user.stores[0],
    signedIn: true,
    loading: false,
});

const authSuccessCustomerReducer = (state, action) => ({
    ...state,
    user: action.userData,
    signedIn: true,
    loading: false,
});

const authLogOutReducer = (state) => ({
    ...state,
    user: null,
    signedIn: false,
});

const facebookConnectReducer = (state) => ({
    ...state,
    loading: true,
});

const facebookConnectSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    fbConnect: action.payload,
});

const facebookConnectFailReducer = (state, action) => ({
    ...state,
    loading: false,
    fbError: action.payload,
});

const socialDisconnectReducer = (state) => ({
    ...state,
    loading: true,
});

const socialDisconnectReducerSuccess = (state, action) => ({
    ...state,
    loading: false,
    socialDisconnect: action.payload,
});

const socialDisconnectReducerFail = (state, action) => ({
    ...state,
    loading: false,
    socialDisconnectError: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStartReducer(state);
        case actionTypes.AUTH_FAIL:
            return authFailReducer(state);
        case actionTypes.AUTH_SUCCESS:
            return authSuccessReducer(state, action);
        case actionTypes.AUTH_LOG_OUT:
            return authLogOutReducer(state);
        case actionTypes.AUTH_SUCCESS_CUSTOMER:
            return authSuccessCustomerReducer(state, action);
        case actionTypes.FACEBOOK_CONNECT_START:
            return facebookConnectReducer(state);
        case actionTypes.FACEBOOK_CONNECT_SUCCESS:
            return facebookConnectSuccessReducer(state, action);
        case actionTypes.FACEBOOK_CONNECT_FAIL:
            return facebookConnectFailReducer(state, action);
        case actionTypes.SOCIAL_DISCONNECT_START:
            return socialDisconnectReducer(state);
        case actionTypes.SOCIAL_DISCONNECT_SUCCESS:
            return socialDisconnectReducerSuccess(state, action);
        case actionTypes.SOCIAL_DISCONNECT_FAIL:
            return socialDisconnectReducerFail(state, action);
        default:
            return state;
    }
};

export default reducer;
