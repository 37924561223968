import styled from 'styled-components';
import { Row, Input, Form, Col, Button as Btn, Select } from 'antd';
import Button from '../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;

    @media (min-width: 1280px) {
        &.main-row {
            flex: 0 0 80%;
        }
    }
`;

export const CustomInput = styled(Input)`
    width: 100%;
    height: 48px;
    font-size: 14px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;
`;

export const FormItem = styled(Form.Item)`
    .ant-form-item-label {
        padding: 0px;
    }
`;

export const DropdownButton = styled(Btn)`
    width: 100%;
    justify-content: space-between;
    height: 48px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;
`;

export const CustomButton = styled(Button)`
    width: 130px;
    height: 48px;
    margin-top: 40px;
    margin-left: 30px;

    @media (min-width: 1280px) {
        margin-left: 170px;
        width: 212px;
    }
`;

export const CustomSelect = styled(Select)`
    width: 100%;

    & > div {
        height: 48px !important;
        border-radius: 4px !important;

        & > span {
            line-height: 48px !important;
            font-size: 14px !important;
            font-family: Montserrat;
        }

        & > CustomOption {
            color: black;
            background-color: gray;
        }
    }
`;

export const CustomOption = styled(Select.Option)`
    bacground-color: red !important;

    .ant-select-item-option {
        width: 200px;
    }
`;

export const CustomCol = styled(Col)`
    &.left-col {
        @media (max-width: 1280px) {
            flex: 1;
        }
    }

    &.right-col {
        @media (max-width: 1280px) {
            flex: 1;
            max-width: 30%;
        }
    }
`;
