import { Modal } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../../components/Button';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        display: none;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
`;
export const CustomButton = styled(Button)`
    width: 200px;
    height: 48px;
    border-radius: 4px;
`;
