import styled from 'styled-components';
import { Button as AntDButton } from 'antd';
import Button from '../../../../../../../../components/Button/index';

export default styled.div``;

export const OkButton = styled(Button)`
    width: 212px;
    height: 48px !important;
    font-size: 16px;
    font-weight: 600;
`;

export const CancelButton = styled(AntDButton)`
    width: 212px;
    height: 48px;
    background: ${({ theme }) => theme.quaternaryColor};
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        color: #000;
        background: ${({ theme }) => theme.borderSecondaryColor};
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
`;
