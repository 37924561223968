import moment from 'moment';
import ContentStyles, { DateContainer, Date, Content, Item, RadioButton, Input } from './styles';

const ModalContent = ({ date, localDetail, setLocalDetail, setRadioValue, radioValue }) => {
    const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
    const months = moment.localeData()._months;
    const tmp = moment(date);

    return (
        <ContentStyles>
            <DateContainer justify="center">
                <Date size="title" weight="light">{`${tmp.date()} ${months[tmp.month()]} ${tmp.year()} ${days[tmp.day()]}`}</Date>
            </DateContainer>
            <RadioButton.Group defaultValue={radioValue === null ? 1 : radioValue} onChange={(event) => setRadioValue(parseInt(event.target.value))}>
                <Content justify="start">
                    <Item span={12}>
                        <RadioButton size="large" value={1}>
                            Kapalı
                        </RadioButton>
                    </Item>
                    <Item span={12}>
                        <RadioButton size="large" value={2}>
                            Resmi Tatil
                        </RadioButton>
                    </Item>
                    <Item span={12}>
                        <RadioButton size="large" value={3}>
                            Tadilatta
                        </RadioButton>
                    </Item>
                    <Item span={12}>
                        <RadioButton size="large" value={4}>
                            Diğer
                        </RadioButton>
                    </Item>
                </Content>
            </RadioButton.Group>
            <Input
                placeholder="Açıklama yazabilirsiniz"
                value={localDetail}
                onChange={(event) => setLocalDetail(event.target.value)} // Don't change parent detail unless it's specified in the footer
            />
        </ContentStyles>
    );
};

export default ModalContent;
