import styled from 'styled-components';
import { Row, Divider } from 'antd';
import Button from '../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;
    &.main-row {
        min-height: 400px;
        height: auto;
        margin-top: 20px;
        padding-right: 23px;
    }

    &.removeSmall {
        @media (min-width: 1024px) {
            display: none;
        }
    }
`;

export const CustomButtom = styled(Button)`
    width: 254px;
    height: 48px;
    margin-top: 35px;
    margin-bottom: 20px;
`;

export const CustomDivider = styled(Divider)`
    width: 20px;
    border-top: px solid rgba(0, 0, 0, 0.06);
    margin-top: 0px;
`;
