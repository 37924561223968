import React from 'react';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';
import LeftSection from './LeftSection';
import RightSection from './RightSection/index';
import { Wrapper, DividerStyle } from './styles';

export default function SearchBar(props) {
    const { keyword, setKeyword, searchQuery, query, setSearchQuery, toggleRightSectionDrawer, setFilterCity, storeData } = props;
    const { xs } = useResponsive();

    return (
        <Wrapper style={{ flexDirection: xs ? 'column' : 'row' }}>
            <LeftSection setFilterCity={setFilterCity} storeData={storeData} />
            <RightSection
                toggleRightSectionDrawer={toggleRightSectionDrawer}
                keyword={keyword}
                setKeyword={setKeyword}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                query={query}
            />
            <DividerStyle />
        </Wrapper>
    );
}
