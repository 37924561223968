import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import dashboardAuthReducer from './auth/reducer';
import calendarPageReducer from './Dashboard/CalendarPage/reducer';
import favouritesReducer from './Dashboard/favourites/reducer';
import restaurantInfoReducer from './Dashboard/restaurantInfo/reducer';
import mealsReducer from './Dashboard/meals/reducer';
import boxesReducer from './Dashboard/boxes/reducer';
import categoriesReducer from './Dashboard/helpcenter/reducer';
import storePageReducer from './Dashboard/StorePage/reducer';
import packageReducer from './Dashboard/package/reducer';
import ordersReducer from './Dashboard/orders/reducer';
import paymentReducer from './Dashboard/payment/reducer';
import landingReducer from './Landing/reducer';
import registerReducer from './Shared/register/reducer';
import storeReducer from './Shared/store/reducer';
import customerOrderReducer from './Shared/order/reducer';
import profileReducer from './Shared/profile/reducer';
import notificationReducer from './Shared/notifications/reducer';
import userPaymentReducer from './Shared/payment/reducer';
import xmlHttpRequestService from '../Api/request';

const reducers = combineReducers({
    authStore: dashboardAuthReducer,
    calendarPage: calendarPageReducer,
    favMealsStore: favouritesReducer,
    businessInfoStore: restaurantInfoReducer,
    storePage: storePageReducer,
    mealStore: mealsReducer,
    boxStore: boxesReducer,
    helpdeskStore: categoriesReducer,
    packageStore: packageReducer,
    ordersStore: ordersReducer,
    paymentStore: paymentReducer,
    landing: landingReducer,
    storeStore: storeReducer,
    registerStore: registerReducer,
    customerOrderStore: customerOrderReducer,
    profileStore: profileReducer,
    notificationStore: notificationReducer,
    userPaymentStore: userPaymentReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk.withExtraArgument(xmlHttpRequestService), logger)));

export default store;
