import React, { useState } from 'react';
import CalendarStyles, { Content, Item, PlanContainer } from './styles';
import DailyPlan from './DailyPlan/index';
import Footer from './Footer/index';
import { Text } from '../../../../../components/Typography/styles';

const WeeklyCalendar = ({ value, orders }) => {
    const [updateWeekly, setUpdateWeekly] = useState([]);

    const startOfWeek = value.startOf('week');
    const format = 'YYYY-MM-DD';
    let orderCount = 0;

    return (
        <>
            <CalendarStyles>
                <Content>
                    <Item sm={{ span: 5, offset: 2 }} md={{ span: 5, offset: 2 }} xl={{ span: 4, offset: 3 }} xxl={{ span: 4, offset: 3 }}>
                        <Text weight="semi-bold">Paket Sayısı</Text>
                    </Item>
                    <Item sm={{ span: 6, offset: 3 }} md={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }} xxl={{ span: 3, offset: 3 }}>
                        <Text weight="semi-bold">Teslim Saati</Text>
                    </Item>
                    <Item
                        sm={{ span: 5, offset: 3 }}
                        md={{ span: 5, offset: 2 }}
                        lg={{ span: 6, offset: 2 }}
                        xl={{ span: 5, offset: 3 }}
                        xxl={{ span: 4, offset: 6 }}>
                        <Text weight="semi-bold">Mağaza Durumu</Text>
                    </Item>
                </Content>
                <PlanContainer>
                    <Item span={24}>
                        {[...Array(7)].map((e, i) => {
                            const date = startOfWeek.clone().add(i, 'days');
                            const dailyOrders = orders.filter((days) => days.start_date.includes(date.format(format)));

                            if (dailyOrders.length !== 0) {
                                orderCount += dailyOrders[0].box_count;
                            }

                            return (
                                <DailyPlan
                                    date={date}
                                    orders={dailyOrders}
                                    updateWeekly={updateWeekly}
                                    setUpdateWeekly={setUpdateWeekly}
                                    key={`daily-plan-${date.format(format)}`}
                                />
                            );
                        })}
                    </Item>
                </PlanContainer>
            </CalendarStyles>
            <Footer orderCount={orderCount} updateWeekly={updateWeekly} setUpdateWeekly={setUpdateWeekly} />
        </>
    );
};

export default WeeklyCalendar;
