/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable prettier/prettier */
/* eslint-disable use-isnan */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */

import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Row, Menu } from 'antd';
import { CustomRow, CustomInput, CustomButton, FormItem } from './styles';
import { DropdownButton } from '../../../../../ProfilePage/Content/AddressPane/AddressForm/styles';
import { CustomCheckbox } from '../LastRow/styles';
import { ReactComponent as DropdownArrow } from '../../../../../../../../Assets/Shared/Profile/dropdown-arrow.svg';
import { Text } from '../../../../../../../components/Typography/styles';

const CardForm = (props) => {
    const { setNewCard, setCardForm, cardForm, checkedSaveCard, setCheckedSaveCard } = props;

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const yearsArray = [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        1,
    ];
    const years = [];
    const year = new Date().getFullYear();
    const [name, setName] = useState('');
    const [cardNo, setCardNo] = useState('');
    const [month, setMonth] = useState('Ay');
    const [yil, setYil] = useState(year);
    const [cvc, setCVC] = useState(null);
    const [kartIsim, setKartIsim] = useState('');
    const requiredText = 'This field is Required';
    const dropdownRequired = 'Required';

    const date = new Date();
    const currentMonth = date.getMonth();

    useEffect(() => {
        yearsArray.map((item, index) => {
            years.push(year + index);
            return 0;
        });
    }, [name, cardNo, month, yil, cvc, kartIsim, cardForm]);

    useEffect(() => {
        const formData = {
            cardName: name,
            cardNumber: cardNo,
            expMonth: month,
            expYear: yil,
            cvcCode: cvc,
            infoName: kartIsim,
        };
        setCardForm(formData);
    }, [name, cardNo, month, yil, cvc, kartIsim]);

    const onChecked = (checked) => {
        setCheckedSaveCard(!checkedSaveCard);
    };

    const condition = (item) => {
        if(item < currentMonth + 1) {
            if(yil === year){
                return true;
            }
        }
            else return false;
        
    }

    const menu = () => (
        <Menu style={{ height: 200, overflow: 'auto' }}>
            {months.map((item) => (
                <Menu.Item key={item} onClick={() => setMonth(item)} disabled={condition(item)}>
                    <Text size="14px">{item}</Text>
                </Menu.Item>
            ))}
        </Menu>
    );

    const yearmenu = () => (
        <Menu style={{ height: 200, overflow: 'auto' }}>
            {years.map((item, index) => (
                <Menu.Item key={index} onClick={() => setYil(item)}>
                    <Text size="14px">{item}</Text>
                </Menu.Item>
            ))}
        </Menu>
    );

    const cardNumberCheck = (value) => {
        if ((!isNaN(value) && !isNaN(parseFloat(value)) && value.length <= 16) || value === '') {
            setCardNo(value);
        }
    };

    const cvcCheck = (value) => {
        if ((!isNaN(value) && !isNaN(parseFloat(value)) && value.length <= 3) || value === '') {
            setCVC(value);
        }
    };

    const onNewCardFalse = () => {
        setCardForm(null);
        setNewCard(false);
    };
    return (
        <CustomRow>
            <CustomRow>
                <Text weight="semi-bold">Başka Kartla Öde</Text>
            </CustomRow>
            <CustomRow justify="space-between">
                <Col span={12}>
                    <CustomRow className="name-input-row">
                        <Text size="14px" weight="light">
                            Kart Üzerindeki İsim
                        </Text>
                    </CustomRow>
                    <CustomRow>
                        <FormItem validateStatus={name === '' && cardNo ? 'error' : 'success'} help={name === '' && cardNo ? requiredText : ''}>
                            <CustomInput value={name} onChange={(e) => setName(e.target.value)} />
                        </FormItem>
                    </CustomRow>
                    <CustomRow>
                        <Text size="14px" weight="light">
                            Kart Numarası
                        </Text>
                    </CustomRow>
                    <CustomRow>
                        <FormItem
                            validateStatus={cardNo === '' && month !== 'Ay' ? 'error' : 'success'}
                            help={cardNo === '' && month !== 'Ay' ? requiredText : ''}>
                            <CustomInput value={cardNo} onChange={(e) => cardNumberCheck(e.target.value)} />
                        </FormItem>
                    </CustomRow>
                    <CustomRow>
                        <Col span={7}>
                            <Text size="14px" weight="light">
                                Ay
                            </Text>
                        </Col>
                        <Col span={7}>
                            <Text size="14px" weight="light">
                                Yıl
                            </Text>
                        </Col>
                        <Col>
                            <Text size="14px" weight="light">
                                CVV/CV2
                            </Text>
                        </Col>
                    </CustomRow>
                    <CustomRow>
                        <Col span={6}>
                            <FormItem
                                validateStatus={month === 'Ay' && yil !== 'Yıl' ? 'error' : 'success'}
                                help={month === 'Ay' && yil !== 'Yıl' ? dropdownRequired : ''}>
                                <Dropdown overlay={menu} overlayStyle={{ border: '2px solid #F2F2FC' }} trigger={['click']}>
                                    <DropdownButton style={{ marginTop: 10 }}>
                                        <Row align="middle" justify="space-between">
                                            <Text size="14px">{month}</Text>
                                            <DropdownArrow />
                                        </Row>
                                    </DropdownButton>
                                </Dropdown>
                            </FormItem>
                        </Col>
                        <Col span={1} />
                        <Col span={6}>
                            <FormItem validateStatus={yil === 'Yıl' && cvc ? 'error' : 'success'} help={yil === 'Yıl' && cvc ? dropdownRequired : ''}>
                                <Dropdown overlay={yearmenu} overlayStyle={{ border: '2px solid #F2F2FC' }} trigger={['click']}>
                                    <DropdownButton style={{ marginTop: 10 }}>
                                        <Row align="middle" justify="space-between">
                                            <Text size="14px">{yil}</Text>
                                            <DropdownArrow />
                                        </Row>
                                    </DropdownButton>
                                </Dropdown>
                            </FormItem>
                        </Col>
                        <Col span={1} />
                        <Col span={10}>
                            <FormItem validateStatus={!cvc && kartIsim ? 'error' : 'success'} help={!cvc && kartIsim ? dropdownRequired : ''}>
                                <CustomInput value={cvc} onChange={(e) => cvcCheck(e.target.value)} />
                            </FormItem>
                        </Col>
                    </CustomRow>
                    <CustomRow className="number-input-row">
                        <Text size="14px" weight="light">
                            Karta İsim Ver
                        </Text>
                    </CustomRow>
                    <CustomRow className="bottom-row">
                        <CustomInput
                            disabled={!checkedSaveCard}
                            value={kartIsim}
                            onChange={(e) => {
                                setKartIsim(e.target.value);
                            }}
                        />
                    </CustomRow>
                </Col>
                <Col>
                    <CustomRow justify="end" className="button-row">
                        <CustomButton type="secondaryReverse" onClick={() => onNewCardFalse()}>
                            <Text weight="semi-bold" color="green">
                                Kayıtlı Kartlarımla Öde
                            </Text>
                        </CustomButton>
                    </CustomRow>
                    <CustomRow className="number-input-row">
                        <CustomCheckbox checked={checkedSaveCard} onChange={(e) => onChecked(e.target.checked)}>
                            <Text weight="light" size="11px">
                                Kayıtlı Kartlarıma Ekle
                            </Text>
                        </CustomCheckbox>
                    </CustomRow>
                </Col>
            </CustomRow>
        </CustomRow>
    );
};

export default CardForm;
