import { Row, Modal, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../../../components/Button';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        padding: 22px 24px;
        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        margin-top: 10px;
        border-radius: 15px;
    }

    .ant-modal-body {
        padding: 24px 30px;
    }

    .ant-modal-footer {
        display: none;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
`;

export const CustomDivider = styled(Divider)`
    width: 20px;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
`;

export const CustomButton = styled(Button)`
    width: 159px;
    height: 48px;
    margin-top: 20px;
    border-radius: 4px;
`;

export const CustomRow = styled(Row)`
    &.btn-two {
        margin-top: 15px;
    }
`;
