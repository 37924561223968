import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    storeInfo: null,
    id: null,
    ref: null,
    emailSettings: null,
};

const getStoreInfo = (state) => ({
    ...state,
    loading: true,
});

const getStoreInfoSuccess = (state, action) => ({
    ...state,
    loading: false,
    storeInfo: action.payload,
    id: action.payload.id,
});

const getStoreInfoFail = (state) => ({
    ...state,
});

const updateStoreInfo = (state) => ({
    ...state,
    loading: true,
});

const updateStoreInfoSuccess = (state) => ({
    ...state,
    loading: false,
});

const updateStoreInfoFail = (state) => ({
    ...state,
    loading: false,
});

const getEmailInfo = (state) => ({
    ...state,
    loading: true,
});

const getEmailInfoSuccess = (state, action) => ({
    ...state,
    loading: false,
    emailSettings: action.payload,
});

const getEmailInfoFail = (state) => ({
    ...state,
    loading: false,
});

const updateEmailInfo = (state) => ({
    ...state,
    loading: true,
});

const updateEmailInfoSuccess = (state) => ({
    ...state,
    loading: false,
});

const updateEmailInfoFail = (state) => ({
    ...state,
    loading: false,
});

const postEmailInfo = (state) => ({
    ...state,
    loading: true,
});

const postEmailInfoSuccess = (state) => ({
    ...state,
    loading: false,
});

const postEmailInfoFail = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STORE_INFO:
            return getStoreInfo(state);
        case actionTypes.GET_STORE_INFO_SUCCESS:
            return getStoreInfoSuccess(state, action);
        case actionTypes.GET_STORE_INFO_FAIL:
            return getStoreInfoFail(state);
        case actionTypes.UPDATE_STORE_INFO:
            return updateStoreInfo(state);
        case actionTypes.UPDATE_STORE_INFO_SUCCESS:
            return updateStoreInfoSuccess(state);
        case actionTypes.UPDATE_STORE_INFO_FAIL:
            return updateStoreInfoFail(state);
        case actionTypes.GET_EMAIL_INFO:
            return getEmailInfo(state);
        case actionTypes.GET_EMAIL_INFO_SUCCESS:
            return getEmailInfoSuccess(state, action);
        case actionTypes.GET_EMAIL_INFO_FAIL:
            return getEmailInfoFail(state);
        case actionTypes.UPDATE_EMAIL_INFO:
            return updateEmailInfo(state);
        case actionTypes.UPDATE_EMAIL_INFO_SUCCESS:
            return updateEmailInfoSuccess(state);
        case actionTypes.UPDATE_EMAIL_INFO_FAIL:
            return updateEmailInfoFail(state);
        case actionTypes.POST_EMAIL_INFO:
            return postEmailInfo(state);
        case actionTypes.POST_EMAIL_INFO_SUCCESS:
            return postEmailInfoSuccess(state);
        case actionTypes.POST_EMAIL_INFO_FAIL:
            return postEmailInfoFail(state);
        default:
            return state;
    }
};

export default reducer;
