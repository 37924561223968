import React from 'react';
import { Col, Row } from 'antd';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import { FoodWasteSectionStyles, TitleWrapper, ButtonWrapper, MoreDetailButton } from './styles';
import { Text } from '../../../../components/Typography/styles';
import foodWaste from '../../../../../Assets/Shared/Landing/foodWaste.svg';
import bg from '../../../../../Assets/Shared/FoodWaste/background_blue.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const FoodWasteSection = () => {
    const theme = useTheme();
    const { sm } = useMediaQuery();
    const history = useHistory();
    return (
        <FoodWasteSectionStyles span={24} backgroundImage={bg}>
            <Row align="middle">
                <Col span={sm ? 24 : 16}>
                    <TitleWrapper>
                        <Text style={{ textAlign: sm ? 'center' : 'left' }} weight="bold" size="mediumTitle" color="red">
                            Gıda israfı Dünyamızı tehdit eden bir gerçek!
                        </Text>
                    </TitleWrapper>
                    <Row>
                        <Text style={{ textAlign: sm ? 'center' : 'left' }} size="description" weight="medium">
                            2050 yılına gelindiğinde dünya nüfusunun yaklaşık 9,3 milyara ulaşması beklenirken, bu büyüklükte bir nüfusun
                            beslenebilmesi için bugünkü gıda üretiminin %60 daha fazlasına ihtiyaç duyulacağı öngörülüyor.
                        </Text>
                    </Row>
                    <ButtonWrapper style={{ justifyContent: sm ? 'center' : 'flex-start' }}>
                        <MoreDetailButton
                            onClick={() => {
                                history.push('gida-israfi');
                                window.scrollTo(0, 0);
                            }}
                            type="tertiaryReverse"
                            borderColor={theme.primaryColor}>
                            <Text color="red" weight="semi-bold" size="regular">
                                Daha Fazla Bilgi
                            </Text>
                        </MoreDetailButton>
                    </ButtonWrapper>
                </Col>
                {sm ? null : (
                    <Col span={8}>
                        <img src={foodWaste} alt="" width="100%" />
                    </Col>
                )}
            </Row>
            <Row />
        </FoodWasteSectionStyles>
    );
};

export default FoodWasteSection;
