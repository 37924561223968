import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import { ReactComponent as GreenPacket } from '../../../../../../../../Assets/Shared/CartPage/packet-selected.svg';
import { ReactComponent as Kurye } from '../../../../../../../../Assets/Shared/CartPage/kurye-selected.svg';
import { ReactComponent as Time } from '../../../../../../../../Assets/Shared/CartPage/time.svg';

const PacketRow = (props) => {
    const selector = useSelector;
    const basket = selector((state) => state.customerOrderStore.basketList);
    const startDate = new Date(basket[0].sale_day.start_date);
    const endDate = new Date(basket[0].sale_day.end_date);
    const { packet } = props;
    const packageType = basket[0].package_setting.delivery_type;

    const onlyPaket = (
        <Col span={11}>
            {packet ? (
                <CustomRow className="packet-row" justify="center" align="middle">
                    <GreenPacket color="#8fd89f" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Text weight="semi-bold" size="14px" color="green">
                        Gel Al Paket
                    </Text>
                </CustomRow>
            ) : (
                <CustomRow className="packet-row" justify="center" align="middle">
                    <GreenPacket color="#837f7f" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Text weight="light" size="14px" color="#424242">
                        Gel Al Paket
                    </Text>
                </CustomRow>
            )}
        </Col>
    );

    const onlyKurye = (
        <Col>
            {packet ? (
                <CustomRow className="packet-row kurye" justify="center" align="middle">
                    <Kurye color="#837f7f" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Text weight="light" size="14px" color="#424242">
                        Kurye&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Text>
                </CustomRow>
            ) : (
                <CustomRow className="packet-row kurye" justify="center" align="middle">
                    <Kurye color="#8fd89f" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Text weight="semi-bold" size="14px" color="green">
                        Kurye&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Text>
                </CustomRow>
            )}
        </Col>
    );

    return (
        <CustomRow justify="space-between" align="middle">
            <CustomCol span={14}>
                <CustomRow className="packet-container" wrap={false}>
                    {packageType === '1' && onlyPaket}
                    {packageType === '2' && onlyKurye}
                    {packageType === '3' && onlyPaket}
                    {packageType === '3' && onlyKurye}
                </CustomRow>
            </CustomCol>
            <Col>
                <CustomRow align="middle">
                    <Col>
                        <Text size="14px">Teslim Saat Aralığı:</Text>&nbsp;&nbsp;&nbsp;
                    </Col>
                    <Col>
                        <CustomRow align="middle" className="time-row" justify="center">
                            <Time />
                            <Text color="yellow">
                                &nbsp;
                                {startDate.getHours()}:{startDate.getMinutes() === 0 ? <>00</> : startDate.getMinutes()} -&nbsp;
                                {endDate.getHours()}:{endDate.getMinutes() === 0 ? <>00</> : endDate.getMinutes()}
                            </Text>
                        </CustomRow>
                    </Col>
                </CustomRow>
            </Col>
        </CustomRow>
    );
};

export default PacketRow;
