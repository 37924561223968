import styled from 'styled-components';
import { Col } from 'antd';

export const Wrapper = styled(Col)`
    background-color: white;
    width: 30%;
    height: 48px;
    margin-right: 12px;
    border: 2px solid #f2f2fc;
`;
