import * as actionTypes from './actionTypes';

// GET
export const getCategoriesStartAction = () => ({
    type: actionTypes.GET_CATEGORIES,
});

export const getCategoriesSuccessAction = (categories) => ({
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    payload: categories,
});

export const getCategoriesFailAction = (error) => ({
    type: actionTypes.GET_CATEGORIES_FAIL,
    payload: error,
});

// GET
export const getFaqsStartAction = () => ({
    type: actionTypes.GET_FAQS,
});

export const getFaqsSuccessAction = (faqs) => ({
    type: actionTypes.GET_FAQS_SUCCESS,
    payload: faqs,
});

export const getFaqsFailAction = (error) => ({
    type: actionTypes.GET_FAQS_FAIL,
    payload: error,
});
