/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
import { MenuSettingsCardStyles, Header, Content, Footer, Btn, CustomSearchBar } from './styles';
import { Text } from '../../../../components/Typography/styles';
import AddImage from '../../../../../Assets/Dashboard/add-button.png';
import CategoryItem from './CategoryItem';
import AddCategoryModal from './AddCategoryModal';
import { fetchBoxCategories } from '../../../../../services/Redux/Dashboard/boxes/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const MenuSettingsCard = () => {
    const selector = useSelector;
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [categoriesToRender, setCategoriesToRender] = useState();
    const [isButtonActive, setIsButtonActive] = useState(true);
    const showAddCategoryModal = () => {
        setShowModal(true);
    };

    const handleCancel = () => {
        setShowModal(false);
        dispatch(fetchBoxCategories(false));
    };

    useEffect(() => {
        dispatch(fetchBoxCategories(false));
    }, []);

    const categories = selector((state) => state.boxStore.categoryList);

    useEffect(() => {
        if (categories) {
            setCategoriesToRender(categories);
        }
    }, [categories]);

    return (
        <MenuSettingsCardStyles title={<Title text="Menu/Ürün Ekleme Ayarları" />}>
            <Header align="middle" justify="space-between">
                <Btn onClick={showAddCategoryModal}>
                    <Row align="middle">
                        <img className="add-img" src={AddImage} alt="" srcSet="" />
                        <Text family="secondary" weight="bold">
                            Kategori Ekle
                        </Text>
                    </Row>
                </Btn>
                <CustomSearchBar />
            </Header>
            <AddCategoryModal categoriesToRender={categoriesToRender} dismiss={handleCancel} onCancel={handleCancel} visible={showModal} />
            <Content>
                {categoriesToRender && categoriesToRender.length > 0 ? (
                    categoriesToRender.map(({ id, name, meals }) => (
                        <CategoryItem
                            catId={id}
                            categoryName={name}
                            meals={meals}
                            isButtonActive={isButtonActive}
                            setIsButtonActive={setIsButtonActive}
                        />
                    ))
                ) : (
                    <div>nodata</div>
                )}
            </Content>
            <Footer align="middle" justify="start">
                <Text family="secondary" color="smoke">
                    Menüde bulunan 450 üründen 50 tanesi Onay bekliyor.
                </Text>
            </Footer>
        </MenuSettingsCardStyles>
    );
};

export default MenuSettingsCard;
