import React from 'react';
import { Row } from 'antd';
import { CookiePolicyPageStyles } from './styles';
import CookiePolicySection from './Cookies';

const CookiePolicy = () => (
    <CookiePolicyPageStyles>
        <Row>
            <CookiePolicySection />
        </Row>
    </CookiePolicyPageStyles>
);

export default CookiePolicy;
