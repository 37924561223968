import styled from 'styled-components';
import { Row, Col } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.tab-btn-row {
        padding-left: 35px;
        padding-right: 30px;
        margin-right: 20px;
        cursor: pointer;
        height: 56px;
        transition: 0.2s;
        position: relative;

        :hover {
            background-color: #f2f2fc;
        }

        :active {
            background-color: #f2f2fc;
        }

        :focus {
            background-color: #f2f2fc;
        }
    }

    &.content-row {
        padding-right: 30px;
    }
`;

export const CustomCol = styled(Col)`
    margin-top: 0px;
`;
