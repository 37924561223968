import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';
import { Text } from '../../../../components/Typography/styles';
import { HORIZONTAL_PADDING } from '../../../../../helpers/constants';
// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const HowToUseStyles = styled(Col)`
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 125px;
    background: ${({ backgroundColor }) => {
        if (backgroundColor) {
            return `${backgroundColor}`;
        }
    }};
`;
export const HowToMobileStyles = styled(Col)`
    overflow: hidden;
    width: 100%;
    padding-top: 50px;
    padding-right: ${HORIZONTAL_PADDING};
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-size: 40% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: column;
`;

export const Image = styled.img``;

export const Title = styled(Text)`
    padding-left: ${between('0px', '120px')};
    padding-right: ${between('0px', '120px')};
    margin-bottom: 72px;
`;

export const ContentWrapper = styled(Row)`
    padding-left: ${between('0px', '120px')};
    padding-right: ${between('0px', '120px')};
`;
