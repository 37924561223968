/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable sonarjs/no-identical-functions */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Popover } from 'antd';
import { IsOutsideClick } from '../../../../../../../../helpers';
import { CustomRow, CustomButton } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import { getAddressActive, getAddress, postAddressActive } from '../../../../../../../../services/Redux/Shared/profile/api';

const AddressRow = (props) => {
    const dispatch = useDispatch();
    const { setAddressInfo, packet } = props;
    const user = JSON.parse(localStorage.getItem('user'));
    const [update, setUpdate] = useState(false);
    const [bilgiPopover, setBilgiPopover] = useState(false);
    // const [faturaPopover, setFaturaPopover] = useState(false);
    const [faturaAddress, setFaturaAddress] = useState(null);

    useEffect(() => {
        dispatch(getAddressActive());
    }, [dispatch, packet, update]);

    useEffect(() => {
        dispatch(getAddress());
    }, []);

    const selector = useSelector;
    const activeAddress = selector((state) => state.profileStore.activeAddress);
    const addresses = selector((state) => state.profileStore.addressList);

    let faturaAddresses = null;
    let bilgiAddresses = null;
    if (addresses) {
        faturaAddresses = addresses.results.filter((item) => item.type !== 2);
        bilgiAddresses = addresses.results.filter((item) => item.type !== 2);
    }

    useEffect(() => {
        if (faturaAddresses) {
            setFaturaAddress(faturaAddresses[0]);
        }
    }, [addresses]);

    const changeActiveAddress = (id, type, item) => {
        const data = {
            active_address: id,
        };
        if (type === 2) {
            setFaturaAddress(item);
            // setFaturaPopover(false);
        } else {
            dispatch(postAddressActive(data));
            setBilgiPopover(false);
            setTimeout(() => {
                setUpdate(!update);
            }, 200);
        }
    };

    useEffect(() => {
        if (activeAddress) {
            const addressInfo = {
                fatura: activeAddress,
                adres: activeAddress,
            };
            setAddressInfo(addressInfo);
            localStorage.setItem('addressInfo', JSON.stringify(addressInfo));
        }
    }, [activeAddress]);

    const bilgicontent = (
        <IsOutsideClick
            handler={() => setBilgiPopover(false)}
            Component={
                <CustomRow>
                    {addresses ? (
                        <CustomRow className="popover-card">
                            {bilgiAddresses.map((item, index) => (
                                <CustomRow key={index} className="item-row" onClick={() => changeActiveAddress(item.id, item.type, item)}>
                                    <CustomRow>
                                        <Text>{item.name}</Text>
                                    </CustomRow>
                                    <CustomRow>
                                        <Text size="11px" weight="light">
                                            {item.address}
                                        </Text>
                                    </CustomRow>
                                </CustomRow>
                            ))}
                        </CustomRow>
                    ) : (
                        <CustomRow>
                            <Text>Loading..</Text>
                        </CustomRow>
                    )}
                </CustomRow>
            }
        />
    );

    // const faturacontent = (
    //     <IsOutsideClick
    //         handler={() => setFaturaPopover(false)}
    //         Component={
    //             <CustomRow>
    //                 {addresses ? (
    //                     <CustomRow className="popover-card">
    //                         {faturaAddresses.map((item, index) => (
    //                             <CustomRow key={index} className="item-row" onClick={() => changeActiveAddress(item.id, item.type, item)}>
    //                                 <CustomRow>
    //                                     <Text>{item.name}</Text>
    //                                 </CustomRow>
    //                                 <CustomRow>
    //                                     <Text size="11px" weight="light">
    //                                         {item.address}
    //                                     </Text>
    //                                 </CustomRow>
    //                             </CustomRow>
    //                         ))}
    //                     </CustomRow>
    //                 ) : (
    //                     <CustomRow>
    //                         <Text>Loading..</Text>
    //                     </CustomRow>
    //                 )}
    //             </CustomRow>
    //         }
    //     />
    // );

    return (
        <CustomRow className="main-row">
            {packet ? (
                <></>
            ) : (
                <>
                    {activeAddress && addresses?.results && faturaAddress && (
                        <CustomRow gutter={[0, 0]} justify="space-between">
                            <Col span={11}>
                                <CustomRow justify="space-between" style={{ alignItems: 'center' }}>
                                    <Col>
                                        <Text size="16px" weight="semi-bold">
                                            Sipariş Bilgileri
                                        </Text>
                                    </Col>
                                    <Col style={{ border: '1px solid #a2a2a2', padding: '0.25rem 1rem' }}>
                                        <Popover content={bilgicontent} visible={bilgiPopover} trigger="click">
                                            <Text
                                                style={{ cursor: 'pointer' }}
                                                size="14px"
                                                weight="semi-bold"
                                                color="red"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setBilgiPopover(true);
                                                    // setFaturaPopover(false);
                                                }}>
                                                Değiştir
                                            </Text>
                                        </Popover>
                                    </Col>
                                </CustomRow>
                                <CustomRow className="card-row" style={{ minHeight: '180px' }}>
                                    <CustomRow>
                                        <Text weight="light">{activeAddress.name}</Text>
                                    </CustomRow>
                                    <CustomRow>
                                        <Text size="14px">{activeAddress.province}</Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {user.email}
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light" style={{ lineHeight: '1rem' }}>
                                            {activeAddress.address}
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {activeAddress.phone_number}
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {activeAddress.description}
                                        </Text>
                                    </CustomRow>
                                </CustomRow>
                                <CustomRow justify="center">
                                    <CustomButton className="button-link" as={Link} to="profilim/adreslerim" type="secondaryReverse">
                                        <Text color="green" weight="semi-bold">
                                            Yeni Sipariş Adresi Ekle
                                        </Text>
                                    </CustomButton>
                                </CustomRow>
                            </Col>
                            {/* <Col span={11}>
                                <CustomRow justify="space-between" style={{ alignItems: 'center' }}>
                                    <Col>
                                        <Text size="16px" weight="semi-bold">
                                            Fatura Bilgileri
                                        </Text>
                                    </Col>
                                    <Col style={{ border: '1px solid #a2a2a2', padding: '0.25rem 1rem' }}>
                                        <Popover content={faturacontent} visible={faturaPopover} trigger="click">
                                            <Text
                                                style={{ cursor: 'pointer' }}
                                                size="14px"
                                                weight="semi-bold"
                                                color="red"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setFaturaPopover(true);
                                                    setBilgiPopover(false);
                                                }}>
                                                Değiştir
                                            </Text>
                                        </Popover>
                                    </Col>
                                </CustomRow>
                                <CustomRow className="card-row" style={{ minHeight: '180px' }}>
                                    <CustomRow>
                                        <Text weight="light">{faturaAddress.name}</Text>
                                    </CustomRow>
                                    <CustomRow>
                                        <Text size="14px">{faturaAddress.province}</Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {user.email}
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {faturaAddress.address}
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {faturaAddress.phone_number}
                                        </Text>
                                    </CustomRow>
                                    <CustomRow className="text-row">
                                        <Text size="11px" weight="light">
                                            {faturaAddress.description}
                                        </Text>
                                    </CustomRow>
                                </CustomRow>
                                <CustomRow justify="center">
                                    <CustomButton className="button-link" as={Link} to="profilim/adreslerim" type="secondaryReverse">
                                        <Text color="green" weight="semi-bold">
                                            Yeni Fatura Adresi Ekle
                                        </Text>
                                    </CustomButton>
                                </CustomRow>
                            </Col> */}
                        </CustomRow>
                    )}
                </>
            )}
        </CustomRow>
    );
};

export default AddressRow;
