import styled from 'styled-components';
import { Row, Col, Button, Popover } from 'antd';

const profilePaddingHorizontal = '15px';
const profilePaddingVertical = '4px';

export const ProfileSectionStyles = styled(Row)`
    border-radius: 20px;
    background-color: ${({ theme }) => theme.contentBackground};
    width: 100%;
    height: 60px;
    padding: ${profilePaddingVertical} ${profilePaddingHorizontal};

    .cover-image {
        height: 100%;
        width: 100%;
    }
`;

export const LeftSide = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-right: 3px solid ${({ theme }) => theme.lightColor};
`;

export const RightSide = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const UserIconHolder = styled.div`
    height: 36px;
    width: 41px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    :hover {
        cursor: pointer;
    }
`;

export const RestaurantIconHolder = styled.div`
    margin-right: 15px;
    height: 48px;
    width: 48px;
`;

export const MenuStyles = styled.div`
    display: flex;
    flex-direction: column;
    height: 350px;
    width: 200px;
`;

export const CustomPopover = styled(Popover)``;

export const CustomButton = styled(Button)`
    width: 116%;
    height: 56px;
    margin-left: -8%;
    text-align: left;
    padding-left: 30px;
    border-right: 0px;
    border-left: 0px;
    border-radius: 0px;

    :hover {
        background-color: #f5f9fc;
    }

    :active {
        background-color: #f5f9fc;
    }

    :focus {
        background-color: #f5f9fc;
    }
`;
