import React from 'react';
import { Row } from 'antd';
import { ProfitCardStyles, Content, DescriptionContainer } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const ProfitCard = ({ isWeekly, monthlyTotalProfit, weeklyTotalProfit }) => (
    <ProfitCardStyles title={<Title text="Elde Edilen Kar" />}>
        <Content>
            <Row justify="center" align="middle">
                <Text color="green" size="44px" weight="bold">
                    {isWeekly ? weeklyTotalProfit : monthlyTotalProfit}
                </Text>
            </Row>
            <DescriptionContainer justify="center" align="middle">
                <Text style={{ textAlign: 'center' }} size="description" family="secondary">
                    {isWeekly ? 'Bu hafta israftan kurtardığın yemeklerin TL karşlığı' : 'Bu yıl israftan kurtardığın yemeklerin TL karşlığı'}
                </Text>
            </DescriptionContainer>
        </Content>
    </ProfitCardStyles>
);

export default ProfitCard;
