/* eslint-disable no-unused-vars */
import * as actions from './actions';

export const fetchBasket = () => (dispatch, state, service) => {
    dispatch(actions.getBasketStartAction(dispatch, state, service));
    service({
        path: 'order/basket',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getBasketSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getBasketFailAction(error));
        });
};

export const postBasketAdd = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postBasketAddStartAction(dispatch, state, service));
    service({
        path: `order/basket/add_box_to_basket/`,
        method: 'POST',
        body: newInfo,
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.postBasketAddSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postBasketAddFailAction(error));
        });
};

export const postBasketRemove = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postBasketRemoveStartAction(dispatch, state, service));
    service({
        path: `order/basket/remove_box_from_basket/`,
        method: 'POST',
        body: newInfo,
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.postBasketRemoveSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postBasketRemoveFailAction(error));
        });
};

export const postBasketRemoveAll = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postBasketRemoveAllStartAction(dispatch, state, service));
    service({
        path: `order/basket/remove_all_boxes/`,
        method: 'POST',
        sendToken: true,
        body: newInfo,
    })
        .then((data) => {
            dispatch(actions.postBasketRemoveAllSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postBasketRemoveAllFailAction(error));
        });
};

export const createOrder = (newInfo) => async (dispatch, state, service) => {
    dispatch(actions.createOrderStartAction(dispatch, state, service));
    try {
        const info = await service({
            path: `order/`,
            method: 'POST',
            body: newInfo,
            sendToken: true,
        });
        dispatch(actions.createOrderSuccessAction(info));
        return info;
    } catch (error) {
        dispatch(actions.createOrderFailAction(error));
        return error;
    }
};

export const getOrder = (id) => (dispatch, state, service) => {
    dispatch(actions.getOrderStartAction(dispatch, state, service));
    service({
        path: `order/${id}`,
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getOrderSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getOrderFailAction(error));
        });
};

export const resetAddError = () => (dispatch, state, service) => {
    dispatch(actions.resetAddError());
};
