/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomModal, Footer, CustomSelect, MenuWrapper, CustomButton, TextContainer } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import NewBoxItem from './NewBoxItem';
import { createBox, fetchBoxNames } from '../../../../../../services/Redux/Dashboard/boxes/api';
import selectArrow from '../../../../../../Assets/Dashboard/select-suffix-icon.png';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);

const NewBoxModal = (props) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const { setIsNewBoxModalVisible, visible } = props;
    const [foodPrices, setFoodPrices] = useState(0);
    const [packetNameToRender, setPacketNameToRender] = useState();
    const [mealsToRender, setMealsToRender] = useState();
    const [minOrderPrice, setMinOrderPrice] = useState();
    const [selectedMenuKey, setSelectedMenuKey] = useState();
    const [meals, setMeals] = useState([]);

    useEffect(() => {
        dispatch(fetchBoxNames());
    }, []);

    const { Option } = CustomSelect;
    const { favouriteMeals } = selector((state) => state.favMealsStore);
    const { nameList } = selector((state) => state.boxStore);
    const { restaurantInfo } = selector((state) => state.businessInfoStore);

    useEffect(() => {
        setMealsToRender(favouriteMeals);

        if (restaurantInfo) {
            setMinOrderPrice(restaurantInfo.package_settings.min_order_price);
        }
    }, [favouriteMeals, restaurantInfo]);

    useEffect(() => {
        setPacketNameToRender(nameList?.filter((item) => item.name !== 'Sürpriz Paket'));
    }, [nameList]);

    function handleChange(value) {
        setSelectedMenuKey(value);
    }

    useEffect(() => {
        if (!visible) {
            setFoodPrices(0);
            setMeals([]);
        }
    }, [visible]);

    const cancelPacket = async () => {
        if (foodPrices >= minOrderPrice && selectedMenuKey !== undefined) {
            await dispatch(createBox(selectedMenuKey, meals));
            props.onCancel();
        } else {
            message.error('Minimum sipariş ücretinde ürün eklediğinizden ve Menü seçimi yaptığınızdan emin olun !');
        }
    };

    const CustomFooter = () => (
        <Col>
            <TextContainer>
                <Text weight="semi-bold">Paket Tutarı </Text>
                <Text color="yellow" size="regular" family="secondary" weight="bold" style={{ marginLeft: '5px' }}>
                    {foodPrices} TL
                </Text>
            </TextContainer>
            <Footer
                gutter={[
                    { xs: 8, md: 8, xl: 16 },
                    { xs: 8, sm: 8, md: 16, lg: 16, xl: 16 },
                ]}
                align="middle"
                justify="space-between">
                <Col span={12}>
                    <CustomButton type="quaternary" onClick={() => setIsNewBoxModalVisible(false)}>
                        <Text weight="semi-bold">İptal</Text>
                    </CustomButton>
                </Col>
                <Col span={12}>
                    <CustomButton type="secondary" onClick={() => cancelPacket()}>
                        <Text color="white" weight="semi-bold">
                            Paketi Oluştur
                        </Text>
                    </CustomButton>
                </Col>
            </Footer>
        </Col>
    );

    return (
        <CustomModal title={<Title text="Yeni Paket" />} {...props} footer={<CustomFooter />}>
            <Col span={24}>
                {packetNameToRender ? (
                    <CustomSelect defaultValue="Default" suffixIcon={<img src={selectArrow} alt="" srcSet="" />} onChange={handleChange}>
                        {packetNameToRender && packetNameToRender.map(({ name, id }) => <Option value={id}>{name}</Option>)}
                    </CustomSelect>
                ) : (
                    <Spin style={{ margin: 'auto' }} />
                )}

                <MenuWrapper>
                    {mealsToRender ? (
                        <NewBoxItem
                            meals={meals}
                            setMeals={setMeals}
                            foodPrices={foodPrices}
                            mealsToRender={mealsToRender}
                            setFoodPrices={setFoodPrices}
                        />
                    ) : (
                        <Spin style={{ margin: 'auto' }} />
                    )}
                </MenuWrapper>
            </Col>
        </CustomModal>
    );
};

export default NewBoxModal;
