import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Text } from '../../../../../components/Typography/styles';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        padding-left: 20px;
        padding-right: 10px;
    }

    &.th-row {
        align-items: baseline;
        margin-bottom: 15px;
    }

    &.bottom-row {
        width: 94.5%;
    }

    &.card-row > * + * {
        margin-top: 10px;
    }
`;

export const CustomCol = styled(Col)`
    &.tutar-container {
        padding-right: 41px;
        text-align: right;
        white-space: nowrap;

        @media (min-width: 1024px) {
            padding-right: 50px;
        }
    }
`;

export const Footer = styled(Row)`
    width: 100%;
    height: 75px;
    border-radius: 30px 30px 0 0;
    background: ${({ theme }) => theme.lightColor};
`;

export const NavButton = styled.img`
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
`;

export const CustomText = styled(Text)`
    font-weight: 600 !important;
    font-size: 0.875rem !important;

    &.no-text--long {
        display: none;
        @media (min-width: 1024px) {
            display: inline;
        }
    }

    &.no-text--short {
        @media (min-width: 1024px) {
            display: none;
        }
    }
`;
