import styled from 'styled-components';
import { Row } from 'antd';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const HowWorksStyles = styled(Row)`
    padding-top: ${VERTICAL_PADDING};
    box-sizing: border-box;
    width: 100vw;

    background-color: #f5f9fc;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: 90%;
    background-position-x: 80%;
    background-repeat: no-repeat;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
`;

export const HowWorksWrapper = styled.div``;

export const HowWorksTitle = styled.div`
    padding-top: 7%;
    padding-bottom: 5%;
`;

export const HowWorksImage = styled.img`
    position: absolute;
    top: 20%;
    right: 8%;

    @media (max-width: 1700px) {
        width: 350px;
    }

    @media (max-width: 1500px) {
        width: 300px;
    }
`;

export const VideoSkeleton = styled.img`
    position: absolute;
    bottom: 10%;
    left: 12%;

    @media (max-width: 1700px) {
        width: 750px;
    }

    @media (max-width: 1500px) {
        width: 700px;
    }
`;
