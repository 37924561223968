/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { HeaderDiv, StyledRow, StyledInput, StyledButton, CustomSelect, CustomOption } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import IlceMenu from './Dropdown';
import BackGreenArrow from '../../../../../../../Assets/Shared/RestaurantDetailPage/BackGreenArrow.svg';
import { ReactComponent as DropdownArrow } from '../../../../../../../Assets/Shared/Profile/dropdown-arrow.svg';
import { getAddress, postAddress, putAddress } from '../../../../../../../services/Redux/Shared/profile/api';

function Index({ address, setAddress, isNew = null, setIsNew }) {
    const dispatch = useDispatch();
    const [geoAddress, setGeoAddress] = useState(address?.address);
    const [city, setCity] = useState(address ? address?.city : 'İstanbul');
    const [formInfo, setFormInfo] = useState({
        name: address ? address?.name : '',
        country: address ? address?.country : 'Turkey',
        city: address ? address?.city : 'İstanbul',
        province: address ? address?.province : 'Adalar',
        address: address ? address?.address : '',
        description: address ? address?.description : '',
        longtitude: address ? address?.longitude : 0,
        latitude: address ? address?.latitude : 0,
        type: address ? address?.type : 0,
    });

    const handleChange = (e) => {
        setFormInfo({
            ...formInfo,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'city') {
            if (e.target.value === 'İstanbul') setCity('istanbul');
            else if (e.target.value === 'İzmir') setCity('izmir');
            else if (e.target.value === 'Ankara') setCity('ankara');
        }
    };

    const handleSelect = (info) => {
        geocodeByAddress(info)
            .then((results) => {
                setGeoAddress(results[0].formatted_address);
                return getLatLng(results[0]);
            })
            .then((latLng) => {
                setFormInfo({
                    ...formInfo,
                    longtitude: latLng.lng,
                    latitude: latLng.lat,
                });
            })
            .catch((error) => console.error('Error', error));
    };

    const handleSave = () => {
        formInfo.address = geoAddress;
        if (Object.values(formInfo).includes('')) return message.warn('Please fill all fields first');
        const DATA = {
            ...formInfo,
            address: geoAddress,
            phone_number: JSON.parse(localStorage.getItem('user'))?.phone_number,
        };

        if (isNew) {
            dispatch(postAddress(DATA));
            dispatch(getAddress());
        } else {
            dispatch(putAddress(DATA, address?.id));
            dispatch(getAddress());
        }
    };

    return (
        <>
            <HeaderDiv>
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                    <Col span={2}>
                        <img
                            src={BackGreenArrow}
                            alt="back"
                            onClick={() => {
                                setAddress({});
                                if (isNew) setIsNew(false);
                            }}
                        />
                    </Col>
                    <Col span={22} align="center">
                        <Text weight="light" style={{ textAlign: 'center', fontSize: '18px' }}>
                            Adreslerim
                        </Text>
                    </Col>
                </Row>
            </HeaderDiv>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                }}>
                <div
                    style={{
                        width: '100%',
                    }}>
                    <Text size="title" weight="semibold" color="black">
                        Adres
                    </Text>
                    <StyledRow>
                        {formInfo?.name && (
                            <Row>
                                <Text weight="light" size="small">
                                    Adres İsmi
                                </Text>
                            </Row>
                        )}
                        <Row style={{ width: '100%' }}>
                            <StyledInput placeholder="Adres İsmi" value={formInfo?.name} name="name" onChange={(e) => handleChange(e)} />
                        </Row>
                    </StyledRow>
                    <StyledRow>
                        {formInfo?.city && (
                            <Row>
                                <Text weight="light" size="small">
                                    Şehir
                                </Text>
                            </Row>
                        )}
                        <Row style={{ width: '100%' }}>
                            <CustomSelect
                                name="city"
                                value={formInfo?.city}
                                onChange={(e) => handleChange({ target: { name: 'city', value: e } })}
                                suffixIcon={<DropdownArrow />}>
                                <CustomOption className="here" value="İstanbul">
                                    <Text size="16px" weight="500">
                                        İstanbul
                                    </Text>
                                </CustomOption>
                                <CustomOption value="Ankara">
                                    <Text size="16px" weight="500">
                                        Ankara
                                    </Text>
                                </CustomOption>
                                <CustomOption value="İzmir">
                                    <Text size="16px" weight="500">
                                        İzmir
                                    </Text>
                                </CustomOption>
                            </CustomSelect>
                        </Row>
                    </StyledRow>
                    <StyledRow>
                        {formInfo?.province && (
                            <Row>
                                <Text weight="light" size="small">
                                    İlçe
                                </Text>
                            </Row>
                        )}
                        <Row style={{ width: '100%' }}>
                            <IlceMenu handleChange={handleChange} city={city} province={formInfo?.province} />
                        </Row>
                    </StyledRow>
                    <PlacesAutocomplete value={geoAddress} onChange={setGeoAddress} onSelect={handleSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <StyledRow>
                                {formInfo?.address && (
                                    <Row>
                                        <Text weight="light" size="small">
                                            Adres
                                        </Text>
                                    </Row>
                                )}
                                <Row style={{ width: '100%' }}>
                                    <StyledInput
                                        {...getInputProps({
                                            placeholder: 'Mahalle, cadde, sokak ara',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    {suggestions?.length ? (
                                        <div
                                            className="autocomplete-dropdown-container"
                                            style={{
                                                padding: '10px 0px',
                                                borderRadius: 10,
                                                border: '2px solid #f2f2fc',
                                                backgroundColor: 'white',
                                                zIndex: 11,
                                                position: 'relative',
                                            }}>
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style: {
                                                                padding: '2px 12px',
                                                                zIndex: 11,
                                                                position: 'relative',
                                                            },
                                                        })}>
                                                        <Text size="16px" weight="light">
                                                            {suggestion.description}
                                                        </Text>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </Row>
                            </StyledRow>
                        )}
                    </PlacesAutocomplete>
                    {/* <StyledInput placeholder="Adres" value={formInfo?.address} name="address" onChange={(e) => handleChange(e)} /> */}
                    <StyledRow style={{ marginBottom: '32px', minHeight: '100px' }}>
                        {formInfo?.description && (
                            <Row>
                                <Text weight="light" size="small">
                                    Adres Açıklaması
                                </Text>
                            </Row>
                        )}
                        <Row style={{ width: '100%' }}>
                            <StyledInput
                                placeholder="Adres Açıklaması"
                                value={formInfo?.description}
                                name="description"
                                onChange={(e) => handleChange(e)}
                            />
                        </Row>
                    </StyledRow>
                </div>
                <div style={{ paddingBottom: '32px', minWidth: '100%' }}>
                    <StyledButton onClick={handleSave}>
                        <Text size="description" weight="semibold" color="white">
                            Kaydet
                        </Text>
                    </StyledButton>
                </div>
            </div>
        </>
    );
}

export default Index;
