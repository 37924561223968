import React from 'react';
import { Row } from 'antd';
import { SaleStatsCardStyles, Content, DescriptionContainer } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const SaleStatsCard = ({ isWeekly, weeklyTotalBoxSold, monthlyTotalBoxSold }) => (
    <SaleStatsCardStyles title={<Title text="Kurtarılan Yemek Sayısı" />}>
        <Content>
            <Row justify="center" align="middle">
                <Text color="red" size="44px" weight="bold">
                    {isWeekly ? weeklyTotalBoxSold : monthlyTotalBoxSold}
                </Text>
            </Row>
            <DescriptionContainer justify="center" align="middle">
                <Text style={{ textAlign: 'center' }} size="description" family="secondary">
                    {isWeekly ? 'Bu hafta israftan kurtardığın yemek sayısı' : 'Bu yıl israftan kurtardığın yemek sayısı'}
                </Text>
            </DescriptionContainer>
        </Content>
    </SaleStatsCardStyles>
);

export default SaleStatsCard;
