/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Row, Col, Rate } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { HeaderDiv, StyledRow, StyledPrice, StyledDiscounted, StyledButton, StyledWarning } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import BackGreenArrow from '../../../../../../../Assets/Shared/RestaurantDetailPage/BackGreenArrow.svg';
import HappyOrder from '../../../../../../../Assets/Shared/Profile/happyOrder.svg';
import ArrowRight from '../../../../../../../Assets/Shared/Profile/arrowRight.svg';
import { postStoreReview } from '../../../../../../../services/Redux/Shared/store/api';
import Onemli from '../../../../../../../Assets/Shared/CartPage/onemli.png';

function Index({ order, setOrder }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [rating, setRating] = useState({
        meal_point: null,
        service_point: null,
        quality_point: null,
    });
    const [date, setDate] = useState(order?.buying_time);

    const handleRatingChange = (value, service) => {
        setRating((prev) => ({ ...prev, [service]: value }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (Object.values(rating).every(Boolean)) {
            const reviewData = {
                ...rating,
                order: order?.id,
                user: order?.user?.id,
                store: order?.store?.id,
            };
            dispatch(postStoreReview(reviewData));
        }
    };

    useEffect(() => {
        function addDays(oldDate, days) {
            const result = new Date(oldDate);
            result.setDate(result.getDate() + days);
            return result;
        }
        setDate(addDays(order?.buying_time, 3));
    }, []);

    return (
        <>
            <HeaderDiv>
                <Row
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                    <Col span={2}>
                        <img src={BackGreenArrow} alt="back" onClick={() => setOrder({})} />
                    </Col>
                    <Col span={22} align="center">
                        <Text weight="light" style={{ textAlign: 'center', fontSize: '18px' }}>
                            Siparişlerim
                        </Text>
                    </Col>
                </Row>
            </HeaderDiv>
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    top: -27,
                    paddingTop: 27,
                    minHeight: '89vh',
                    backgroundColor: ['4', '5'].includes(order?.status) && '#FEEFEF',
                }}>
                <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                    {['1', '2', '3', '9'].includes(order?.status) ? 'Aktif Siparişlerim' : 'Geçmiş Siparişlerim'}
                </Text>
                {['4', '5'].includes(order?.status) ? (
                    <>
                        <Row style={{ minWidth: '100%', marginTop: '16px' }} align="center">
                            <Text weight="bold" size="description" color="red">
                                Tanımlanmış Paketin İptal Edildi!
                            </Text>
                        </Row>
                        <Row style={{ minWidth: '100%', marginBottom: '32px' }} align="center">
                            <Text weight="light" size="small">
                                Sipariş Numarası:{' '}
                                <Text weight="bold" size="small" color="green">
                                    {order?.ref_code}
                                </Text>
                            </Text>
                        </Row>
                        <Row style={{ minWidth: '100%', marginBottom: '32px' }} align="center">
                            <StyledWarning>
                                <Col style={{ marginRight: 15, marginTop: 8 }}>
                                    <img src={Onemli} alt="alert" />
                                </Col>
                                <Col>
                                    <Text weight="light" size="small">
                                        Siparişiniz restoran tarafından iptal edildi. {moment(date).format('DD.MM.YYYY')} tarihine kadar ücret iadeniz
                                        yapılacaktır
                                    </Text>
                                </Col>
                            </StyledWarning>
                        </Row>
                    </>
                ) : (
                    <StyledRow style={{ minWidth: '100%', marginBottom: '32px' }}>
                        <Col span={16}>
                            <Row>
                                <Text weight="bold" size="small">
                                    {order?.address?.name}:{'  '}
                                </Text>
                                <Text weight="light" size="small">
                                    {order?.address?.address}
                                </Text>
                            </Row>
                            <Row>
                                <Text weight="light" size="small">
                                    Sipariş Numarası:{'  '}
                                </Text>
                                <Text weight="bold" size="small">
                                    {order?.ref_code}
                                </Text>
                            </Row>
                        </Col>
                        <Col span={4}>
                            <img src={HappyOrder} alt="Happy" />
                        </Col>
                    </StyledRow>
                )}
                <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                    Restoran{' '}
                </Text>
                <StyledRow style={{ minWidth: '100%', marginBottom: '32px' }}>
                    <Col span={16}>
                        <Text weight="light" size="description">
                            {order?.store?.name}
                        </Text>
                    </Col>
                    <Col span={3}>
                        <img
                            src={ArrowRight}
                            alt="details"
                            onClick={() => {
                                setOrder({});
                                history.replace(`/restoran-detay/${order?.store?.id}`);
                            }}
                        />
                    </Col>
                </StyledRow>
                {['6'].includes(order?.status) && (
                    <>
                        <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                            Puanla
                        </Text>
                        <StyledRow style={{ minWidth: '100%' }}>
                            <Col span={12}>Servis:</Col>
                            <Col span={12}>
                                <Rate
                                    onChange={(value) => handleRatingChange(value, 'service_point')}
                                    value={rating.service_point || order?.review[0]?.service_point}
                                    disabled={order?.is_voted}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow style={{ minWidth: '100%' }}>
                            <Col span={12}>Kalite:</Col>
                            <Col span={12}>
                                <Rate
                                    onChange={(value) => handleRatingChange(value, 'quality_point')}
                                    value={rating.quality_point || order?.review[0]?.quality_point}
                                    disabled={order?.is_voted}
                                />
                            </Col>
                        </StyledRow>
                        <StyledRow style={{ minWidth: '100%', marginBottom: '32px' }}>
                            <Col span={12}>Lezzet:</Col>
                            <Col span={12}>
                                <Rate
                                    onChange={(value) => handleRatingChange(value, 'meal_point')}
                                    value={rating.meal_point || order?.review[0]?.meal_point}
                                    disabled={order?.is_voted}
                                />
                            </Col>
                        </StyledRow>
                        <Row style={{ minWidth: '100%', marginBottom: '32px' }} align="center">
                            <StyledButton disabled={order?.is_voted} onClick={handleFormSubmit}>
                                <Text weight="bold" size="description" color="white">
                                    Değerlendirmemi Paylaş
                                </Text>
                            </StyledButton>
                        </Row>
                    </>
                )}
                {order?.boxes?.length !== 0 && (
                    <>
                        <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                            Sepettekiler
                        </Text>
                        {order.boxes.map((box, bi) => (
                            <StyledRow style={{ minWidth: '100%', marginBottom: '32px' }} key={bi}>
                                <Col span={16}>
                                    <Row>
                                        <Text weight="bold" size="regular">
                                            {box?.text_name}
                                        </Text>
                                    </Row>
                                    <Row>
                                        <Text weight="light" size="small">
                                            {box?.meals?.length !== 0 &&
                                                box.meals.map((meal, mi) => (box.meals.length - 1 === mi ? `${meal.name}.` : `${meal.name}, `))}
                                        </Text>
                                    </Row>
                                </Col>
                                <StyledPrice span={5}>{order?.cost} TL</StyledPrice>
                            </StyledRow>
                        ))}
                    </>
                )}
                <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '16px' }}>
                    Ödeme
                </Text>
                <StyledRow style={{ minWidth: '100%' }}>
                    <Col span={16}>
                        <Text weight="light" size="description">
                            Sepete Tutarı
                        </Text>
                    </Col>
                    <StyledPrice span={5}>{order?.cost} TL</StyledPrice>
                </StyledRow>
                <StyledRow style={{ minWidth: '100%' }}>
                    <Col span={16}>
                        <Text weight="light" size="description">
                            Kurye Ücreti
                        </Text>
                    </Col>
                    <StyledDiscounted span={5}>4.5 TL</StyledDiscounted>
                </StyledRow>
                <StyledRow style={{ minWidth: '100%' }}>
                    <Col span={16}>
                        <Text weight="light" size="description">
                            Poşet Ücreti (2)*
                        </Text>
                    </Col>
                    <StyledPrice span={5}>0.50 TL</StyledPrice>
                </StyledRow>
                <StyledRow style={{ minWidth: '100%' }}>
                    <Col span={16}>
                        <Text weight="semibold" size="description">
                            Ödenen Toplam Tutar
                        </Text>
                    </Col>
                    <StyledPrice span={5}>{order?.cost} TL</StyledPrice>
                </StyledRow>
            </div>
        </>
    );
}

export default Index;
