/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { Text } from '../../../../../components/Typography/styles';
import { Wrapper, ButtonWrapper, ContentWrapper, CustomRow, CustomCol } from './styles';
import { HiddenRow } from '../SortSection/styles';
import { CustomCheckbox } from '../../../CartPage/Checkout/TabsPane/PaymentPane/LastRow/styles';
import { ReactComponent as DropDownArrow } from '../../../../../../Assets/Shared/FindRestaurant/collapsible-icon.svg';
import { ReactComponent as Packet } from '../../../../../../Assets/Shared/CartPage/packet-selected.svg';
import { ReactComponent as Kurye } from '../../../../../../Assets/Shared/CartPage/kurye-selected.svg';

export default function DeliverySection(props) {
    const { kurye, setKurye, saveHandler } = props;
    const [showRow, setShowRow] = useState(false);
    const toggleRow = () => setShowRow(!showRow);
    const [packet, setPacket] = useState(false);

    useEffect(() => {
        setKurye(kurye);
    }, [kurye]);

    useEffect(() => {
        saveHandler();
    }, [packet, kurye]);

    return (
        <Wrapper>
            <CustomRow align="middle" justify="space-between">
                <Text weight="500" color="black">
                    Paket Teslimi
                </Text>
                <DropDownArrow style={{ cursor: 'pointer' }} onClick={toggleRow} />
            </CustomRow>
            <HiddenRow className="delivery-section" active={showRow}>
                <ButtonWrapper align="middle" onClick={() => setPacket(!packet)}>
                    <Col span={3}>
                        <CustomCheckbox className="delivery-section" checked={packet} />
                    </Col>
                    <CustomCol span={21}>
                        <ContentWrapper>
                            <Packet color={packet ? '#8FD89F' : '#837F7F'} />
                            <Text weight="light" color="black">
                                Gel-Al Paket
                            </Text>
                        </ContentWrapper>
                    </CustomCol>
                </ButtonWrapper>
                <ButtonWrapper align="middle" onClick={() => setKurye(!kurye)}>
                    <Col span={3}>
                        <CustomCheckbox className="delivery-section" checked={kurye} />
                    </Col>
                    <CustomCol span={21}>
                        <ContentWrapper>
                            <Kurye color={kurye ? '#8FD89F' : '#837F7F'} />
                            <Text weight="light" color="black">
                                Motorlu Kurye
                            </Text>
                        </ContentWrapper>
                    </CustomCol>
                </ButtonWrapper>
            </HiddenRow>
        </Wrapper>
    );
}
