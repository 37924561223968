import styled from 'styled-components';
import { Col, Row, Input } from 'antd';
import ReactSlider from 'react-slider';

export const ContainerStyle = styled(Row)`
    height: 50px;
    justify-content: space-evenly;

    .ant-slider-handle:focus {
        border: solid 2px rgb(143, 216, 159) !important;
    }

    @media (max-width: 1024px) {
        & > * + * {
            margin-top: 1rem;
        }
    }
`;

export const ButtonStyle = styled(Col)`
    width: 120px;
    border: 1px solid #d1d0d1;
    border-radius: 5px;
`;

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 10px;
    margin-left: 40px;
    margin-top: 24px;
`;

export const StyledThumb = styled.div`
    width: 18px;
    height: 18px;
    text-align: center;
    background-color: #8fd89f;
    color: #8fd89f;
    border-radius: 50%;
    cursor: grab;
    top: -5px;
`;

export const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: #8fd89f;
    border-radius: 5px;
`;

export const CustomRow = styled(Row)`
    margin-left: 7%;
    margin-right: 2.5%;
    width: 100%;
`;

export const CustomCol = styled(Col)`
    &.range-col {
        margin: 0 auto;
        margin-bottom: 20px;

        @media (min-width: 1025px) {
            margin: 30px;
        }
    }
`;

export const CustomInput = styled(Input)`
    width: 30px;
    border: none;
    padding: 0;
`;
