import React from 'react';
import { Row } from 'antd';
import { AboutUsPageStyles } from './styles';
import AboutSection from './AboutSection';
import HowWorksSection from './HowWorksSection';
import ServicesCostSection from './ServicesCostSection';

const AboutUs = () => (
    <AboutUsPageStyles>
        <Row>
            <AboutSection />
        </Row>
        <Row>
            <HowWorksSection />
        </Row>
        <Row>
            <ServicesCostSection />
        </Row>
    </AboutUsPageStyles>
);

export default AboutUs;
