import { Col } from 'antd';
import styled from 'styled-components';

export const StyledDiv = styled.div`
    min-height: 56px;
    padding: 10px 0 10px 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`;

export const DetailsDiv = styled.div`
    min-height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`;

export const StyledPrice = styled(Col)`
    height: 36px;
    width: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f5f9fc;
    color: #8fd89f;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
`;

export const StyledWarning = styled.span`
    color: #f38262;
    font-family: Montserrat;
    font-weight: medium;
    font-size: 14px;
    margin-left: 16px;
`;
