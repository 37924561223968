/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { OrderStatusCardStyles, Content, SaleHours, DeliveryHours, TimeLabel, Description, Container } from './styles';
import { Text } from '../../../../components/Typography/styles';
import timeIcon from '../../../../../Assets/Dashboard/time-icon.png';
import OrderStatusModal from './OrderStatusModal';
import { getClosestSaleDate } from '../../../../../services/Redux/Dashboard/CalendarPage/api';
import { getPackageInfo } from '../../../../../services/Redux/Dashboard/package/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const OrderStatusCard = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPacketDeliveryLabel, setIsPacketDeliveryLabel] = useState(false);
    const dispatch = useDispatch();
    const packageId = useSelector((state) => state?.storePage?.storeInfo?.package_settings?.id);

    const handleCancel = () => {
        setIsPacketDeliveryLabel(false);
        setIsModalVisible(false);
    };

    const showModalPacketDeliveryModal = () => {
        setIsPacketDeliveryLabel(true);
        setIsModalVisible(true);
    };

    const showCourrierDeliveryModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        dispatch(getClosestSaleDate());
        if (packageId) {
            dispatch(getPackageInfo(packageId));
        }
    }, [dispatch, packageId]);

    const startDate = useSelector((state) => state.calendarPage.closestSaleDate.start_date);
    const currentTimeIntervalId = useSelector((state) => state.calendarPage.closestSaleDate.id);
    const endDate = useSelector((state) => state.calendarPage.closestSaleDate.end_date);
    const startDateToRender = moment(startDate).format('HH:mm');
    const endDateToRender = moment(endDate).format('HH:mm');

    const { deliveryTimeStart, deliveryTimeEnd, courierDeliveryTimeStart, courierDeliveryTimeEnd } = useSelector((state) => state.packageStore);

    return (
        <OrderStatusCardStyles title={<Title text="Satış ve Tahsilat Durumu" />}>
            <Content>
                <SaleHours>
                    <Col span={24}>
                        <Container align="middle" justify="center">
                            <Text weight="semi-bold">
                                <img src={timeIcon} alt="time-icon" /> Satış Saatleri
                            </Text>
                            <TimeLabel>
                                <Text size="17px" color="yellow" weight="bold" family="secondary">
                                    {`${startDateToRender} - ${endDateToRender}`}
                                </Text>
                            </TimeLabel>
                        </Container>
                        <Description align="middle" justify="center">
                            <Text size="regular" color="smoke">
                                Kullanıcı, paketi bu saatler aralığında teslim alabilir.
                            </Text>
                        </Description>
                    </Col>
                </SaleHours>
                <DeliveryHours align="middle" justify="center">
                    <Container>
                        <Text weight="semi-bold" style={{ textAlign: 'center' }}>
                            <img src={timeIcon} alt="time-icon" /> Kurye Teslim Saatleri
                        </Text>
                        <TimeLabel>
                            <Text size="17px" color="yellow" weight="bold" family="secondary">
                                {`${courierDeliveryTimeStart?.replace('.', ':')} - ${courierDeliveryTimeEnd?.replace('.', ':')}`}
                            </Text>
                        </TimeLabel>
                    </Container>
                </DeliveryHours>
            </Content>
            <OrderStatusModal
                deliveryTimeStart={deliveryTimeStart}
                deliveryTimeEnd={deliveryTimeEnd}
                currentTimeIntervalId={currentTimeIntervalId}
                startDate={startDate}
                endDate={endDate}
                dismiss={handleCancel}
                visible={isModalVisible}
                isPacketDeliveryLabel={isPacketDeliveryLabel}
                onCancel={handleCancel}
            />
        </OrderStatusCardStyles>
    );
};

export default OrderStatusCard;
