import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Text } from '../../../../../components/Typography/styles';
import Button from '../../../../../components/Button';

export const CartWrapper = styled(Col)`
    background-color: white;
    border-radius: 18px;
    margin-bottom: 9%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 5%;
    width: 330px;
`;

export const TextWrapper = styled(Col)`
    border-bottom: 4px solid #f2f2fc;
    padding-top: 5%;
    padding-bottom: 7%;
    text-align: center;
    margin-bottom: 7%;
`;

export const DividerStyle = styled(Row)`
    background-color: #f2f2fc;
    height: 2px;
    width: 100%;
`;

export const WideDividerStyle = styled(Row)`
    width: 100%;
    height: 2px;
    background-color: #f2f2fc;
    margin-top: 35px;
    margin-bottom: 7%;

    &.margin-fix {
        margin-top: 20px;
    }
`;

export const DividerWrapper = styled(Row)`
    justify-content: center;
    align-items: center;
    margin-bottom: 9%;
`;

export const ButtonWrapper = styled(Row)`
    justify-content: center;
    height: 48px;
    width: 100%;
`;

export const TextStyle = styled(Row)``;

export const ItemRow = styled(Row)`
    width: 100%;

    &.price-row {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &.price-row-top {
        @media (max-width: 1024px) {
            justify-content: center;
        }
    }
`;

export const DiscountTextStyle = styled(Text)`
    text-decoration: line-through;
`;

export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    width: 69px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 0px;
`;

export const MenuCol = styled(Col)`
    overflow: hidden;
`;

export const CustomRow = styled(Row)`
    margin-bottom: 20px;
    margin-top: 20px;

    &.price-row {
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        height: 72px;
        background-color: #f2f2fc;
        border-radius: 4px;
    }

    &.cart-row {
        flex-direction: column;
        margin-bottom: 9%;
        width: 100%;

        @media (min-width: 1025px) {
            flex-direction: row;
            padding-left: 0;
        }
    }
`;

export const CustomButtom = styled(Button)`
    height: 48px;
    width: 100%;
`;

export const BottomContent = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 1025px) {
        padding: 0 35px;
    }
`;
