import { Col, Row, Divider } from 'antd';
import styled from 'styled-components';

export const OutSide = styled(Row)`
    background-color: #f5f9fc;
    padding: 14% 3%;
    padding-top: 150px;
    max-width: 1430px;
    margin: 0 auto;

    @media (min-width: 1500px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const LeftContainer = styled(Col)`
    background-color: white;
    border-radius: 18px;
    width: 97%;
    margin: 0 auto;
    min-height: auto;
    height: auto;
    padding-bottom: 40px;

    @media (min-width: 1025px) {
        margin-right: 32px;
    }
`;

export const RightContainer = styled(Col)`
    width: 334px;
    height: auto;
    border-radius: 18px;
`;

export const CustomCol = styled(Col)`
    &.left-col {
        @media (max-width: 1024px) {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    &.right-col {
        display: none;

        @media (min-width: 1025px) {
            display: block;
        }
    }
`;

export const TitleRow = styled(Row)`
    width: 100%;
    padding-top: 30px;
    position: relative;
`;

export const CustomDivider = styled(Divider)`
    width: 100% !important;
    border-top: 4px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 0px;
`;
