/* eslint-disable no-console */
import * as actions from './actions';

export const getPaymentInfo = () => async (dispatch, getState, service) => {
    dispatch(actions.getPaymentInfoAction());
    await service({
        method: 'GET',
        path: `payment/`,
        sendToken: true,
    })
        .then((data) => dispatch(actions.getPaymentInfoSuccessAction(data)))
        .catch((error) => dispatch(actions.getPaymentInfoFailAction(error)));
};

export const postPaymentInfo = (newInfo) => async (dispatch, getState, service) => {
    dispatch(actions.postPaymentInfoAction());
    await service({
        method: 'POST',
        path: 'payment/',
        sendToken: true,
        body: newInfo,
    })
        .then(() => dispatch(actions.postPaymentInfoSuccessAction()))
        .catch((error) => dispatch(actions.postPaymentInfoFailAction(error)));
};

export const patchPaymentInfo = (newInfo) => async (dispatch, getState, service) => {
    dispatch(actions.patchPaymentInfoAction());
    await service({
        method: 'PATCH',
        path: 'payment/update_payment/',
        sendToken: true,
        body: newInfo,
    })
        .then(() => dispatch(actions.patchPaymentInfoSuccessAction()))
        .catch((error) => dispatch(actions.patchPaymentInfoFailAction()));
};
