/* eslint-disable no-unused-expressions */
import React from 'react';
import { Wrapper } from './styles';
import { WideDividerStyle } from '../CartSection/styles';
import SliderBar from '../../ProgressBar';
import DeliverySection from '../DeliverySection';
import CategorySection from '../CategorySection';
import ButtonSection from '../ButtonSection';
import FilterHeader from '../FilterHeader';
import SortSection from '../SortSection';

const FilterSection = (props) => {
    const {
        price,
        setPrice,
        kurye,
        setKurye,
        searchQuery,
        setSearchQuery,
        selectedCategories,
        setSelectedCategories,
        sortBy,
        setSortBy,
        resetFilter,
        toggleRightSectionDrawer,
    } = props;

    const handleFilter = () => {
        toggleRightSectionDrawer && toggleRightSectionDrawer();
        setSearchQuery(searchQuery + 1);
    };

    return (
        <Wrapper>
            <FilterHeader />
            <SortSection sortBy={sortBy} setSortBy={setSortBy} />
            <WideDividerStyle className="margin-fix" />
            <SliderBar price={price} setPrice={setPrice} saveHandler={handleFilter} />
            <WideDividerStyle className="margin-fix" />
            <DeliverySection kurye={kurye} setKurye={setKurye} saveHandler={handleFilter} />
            <WideDividerStyle className="margin-fix" />
            <CategorySection selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} saveHandler={handleFilter} />
            <WideDividerStyle className="margin-fix" />
            <ButtonSection resetFilter={resetFilter} saveHandler={handleFilter} />
        </Wrapper>
    );
};
export default FilterSection;
