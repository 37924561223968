// GET

export const GET_BOXES = 'GET_BOXES';
export const GET_BOXES_SUCCESS = 'GET_BOXES_SUCCESS';
export const GET_BOXES_FAIL = 'GET_BOXES_FAIL';

// GET BOX CATEGORIES

export const GET_BOX_CATEGORIES = 'GET_BOX_CATEGORIES';
export const GET_BOX_CATEGORIES_SUCCESS = 'GET_BOX_CATEGORIES_SUCCESS';
export const GET_BOX_CATEGORIES_FAIL = 'GET_BOX_CATEGORIES_FAIL';

// POST BOX CATEGORIES

export const POST_BOX_CATEGORIES = 'POST_BOX_CATEGORIES';
export const POST_BOX_CATEGORIES_SUCCESS = 'POST_BOX_CATEGORIES_SUCCESS';
export const POST_BOX_CATEGORIES_FAIL = 'POST_BOX_CATEGORIES_FAIL';

// GET BOX CATEGORIES NAME

export const GET_BOX_CATEGORIES_NAME = 'GET_BOX_CATEGORIES_NAME';
export const GET_BOX_CATEGORIES_NAME_SUCCESS = 'GET_BOX_CATEGORIES_NAME_SUCCESS';
export const GET_BOX_CATEGORIES_NAME_FAIL = 'GET_BOX_CATEGORIES_NAME_FAIL';

// GET BOX TAGS

export const GET_BOX_TAGS = 'GET_BOX_TAGS';
export const GET_BOX_TAGS_SUCCESS = 'GET_BOX_TAGS_SUCCESS';
export const GET_BOX_TAGS_FAIL = 'GET_BOX_TAGS_FAIL';

// POST CREATE BOX TAG

export const POST_BOX_TAGS = 'POST_BOX_TAGS';
export const POST_BOX_TAGS_SUCCESS = 'POST_BOX_TAGS_SUCCESS';
export const POST_BOX_TAGS_FAIL = 'POST_BOX_TAGS_FAIL';

// GET BOX MEALS

export const GET_BOX_MEALS = 'GET_BOX_MEALS';
export const GET_BOX_MEALS_SUCCESS = 'GET_BOX_MEALS_SUCCESS';
export const GET_BOX_MEALS_FAIL = 'GET_BOX_MEALS_FAIL';

// POST BOX MEAL

export const POST_BOX_MEAL = 'POST_BOX_MEALS';
export const POST_BOX_MEAL_SUCCESS = 'POST_BOX_MEALS_SUCCESS';
export const POST_BOX_MEAL_FAIL = 'POST_BOX_MEALS_FAIL';

// PUT BOX MEAL

export const PUT_BOX_MEAL = 'PUT_BOX_MEALS';
export const PUT_BOX_MEAL_SUCCESS = 'PUT_BOX_MEALS_SUCCESS';
export const PUT_BOX_MEAL_FAIL = 'PUT_BOX_MEALS_FAIL';

// DELETE BOX MEAL

export const DELETE_BOX_MEAL = 'DELETE_BOX_MEALS';
export const DELETE_BOX_MEAL_SUCCESS = 'DELETE_BOX_MEALS_SUCCESS';
export const DELETE_BOX_MEAL_FAIL = 'DELETE_BOX_MEALS_FAIL';
// GET BOX-NAMES

export const GET_BOX_NAMES = 'GET_BOX_NAMES';
export const GET_BOX_NAMES_SUCCESS = 'GET_BOX_NAMES_SUCCESS';
export const GET_BOX_NAMES_FAIL = 'GET_BOX_NAMES_FAIL';

// UPDATE - DEFINE BOX

export const UPDATE_BOXES = 'UPDATE_BOXES';
export const UPDATE_BOXES_SUCCESS = 'UPDATE_BOXES_SUCCESS';
export const UPDATE_BOXES_FAIL = 'UPDATE_BOXES_FAIL';

// CREATE - BOX CREATE

export const CREATE_BOXES = 'CREATE_BOXES';
export const CREATE_BOXES_SUCCESS = 'CREATE_BOXES_SUCCESS';
export const CREATE_BOXES_FAIL = 'CREATE_BOXES_FAIL';
