import styled from 'styled-components';
import { Row } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        margin: 0 30px;
        margin-top: 10px;
    }

    &.payment-row {
        margin-top: 70px;
    }

    &.main-row-sub > div {
        width: 100%;
    }
`;
