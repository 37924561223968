import styled from 'styled-components';
import { Row } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        margin-left: 30px;
    }

    &.margin-t-30 {
        margin-top: 30px;
    }

    &.margin-t-20 {
        margin-top: 15px;
    }

    &.buttons-row {
        margin-top: 20px;
        width: 80%;
    }

    &.buttons-row--social {
        justify-content: space-between;
        width: 60%;
        @media (min-width: 1024px) {
            justify-content: unset;
            width: 95%;
        }
    }
`;
