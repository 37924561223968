import { Option } from 'antd/lib/mentions';
import React from 'react';
import { Wrapper } from './styles';
import { SelectList } from '../styles';
import ListBoxSuffixIcon from '../../../../../../Assets/Shared/Mailing/listBox.svg';

export default function LeftSection({ setFilterCity, storeData }) {
    // const temp = useSelector((state) => state.storeStore.searchData);
    // const storeData = temp[0];
    const provinceList = [...new Set(storeData?.map((item) => item?.province))];
    return (
        <Wrapper>
            <SelectList
                bordered={false}
                suffixIcon={<img src={ListBoxSuffixIcon} alt="img" />}
                defaultValue="Semtini Seç"
                style={{ width: '100%', height: '60%', marginTop: '8px', color: '#424242' }}
                onChange={(value) => {
                    setFilterCity(value);
                }}>
                <Option>Tüm Semtler</Option>
                {provinceList &&
                    provinceList?.map((province, index) => (
                        <Option key={index} value={`${province}`}>
                            {province}
                        </Option>
                    ))}
            </SelectList>
        </Wrapper>
    );
}
