/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
import { HelpcenterPageStyles, LeftWrapper, RightWrapper, TipsContent, CustomBtn, CustomNotifyCard } from './styles';
import Categories from './Categories';
import QuestionCard from './QuestionCard';
import { fetchCategories, fetchFaqs } from '../../../../services/Redux/Dashboard/helpcenter/api';
import { Text } from '../../../components/Typography/styles';
import ContactModal from './ContactModal';

const HelpcenterPage = () => {
    const dispatch = useDispatch();
    const selector = useSelector;

    const [categoriesToRender, setCategoriesToRender] = useState();
    const [questionDataToRender, setQuestionDataToRender] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [questionsData, setQuestionsData] = useState();
    const [questionsTitle, setQuestionsTitle] = useState();

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        dispatch(fetchCategories());
        dispatch(fetchFaqs());
    }, [dispatch]);

    const categoryData = selector((state) => state.helpdeskStore.categoryList);
    const faqsData = selector((state) => state.helpdeskStore.faqsList);

    useEffect(() => {
        if (categoryData) {
            const filtered = categoryData.filter((item) => item.category === 0);
            setCategoriesToRender(filtered);
            setQuestionDataToRender(faqsData);
        }
    }, [categoryData, faqsData]);

    return (
        <HelpcenterPageStyles justify="start">
            <LeftWrapper xs={24} sm={24} md={24} lg={24} xl={15}>
                <Categories data={categoriesToRender} setQuestionsTitle={setQuestionsTitle} setQuestionsData={setQuestionsData} />
                <QuestionCard faqs={questionDataToRender} questionTitle={questionsTitle} questionsData={questionsData} />
            </LeftWrapper>
            <RightWrapper xs={24} sm={24} md={24} lg={24} xl={6}>
                <CustomNotifyCard
                    children={
                        <Row>
                            <Text>
                                Burada, Mağaza Panelini nasıl kullanacağınız ve sistemimizin nasıl çalıştığı hakkındaki sorularınızın çoğunun
                                yanıtlarını bulabilirsiniz. Sorunuzun cevabını bulmak için bir konu seçin.
                            </Text>
                        </Row>
                    }
                    type="notification"
                />
                <CustomNotifyCard
                    style={{ marginTop: '28px' }}
                    children={
                        <TipsContent>
                            <Row>
                                <Text>Yardıma ihtiyacınız var mı?</Text>
                            </Row>
                            <Row>
                                <CustomBtn onClick={() => setIsModalVisible(true)} type="quaternary">
                                    <Text>İletişime geç</Text>
                                </CustomBtn>
                            </Row>
                        </TipsContent>
                    }
                    type="tips"
                />
            </RightWrapper>
            <ContactModal visible={isModalVisible} onCancel={handleCancel} />
        </HelpcenterPageStyles>
    );
};

export default HelpcenterPage;
