import { Row, Col } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card';
import SearchBar from '../../components/SearchBar';
import CustomBtn from '../../../../components/Button';

export const MenuPreviewCardStyles = styled(Card)`
    .ant-card-body {
        min-height: 530px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 20px;
        padding-bottom: 33px;
    }
`;

export const Header = styled(Row)`
    /* min-height: 50px; */
    height: 100%;
`;

export const Content = styled(Col)`
    height: 428px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 14px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background: #ffffff;
    }
`;
export const Footer = styled(Row)`
    min-height: 50px;
    border: 1px solid purple;
`;

export const CustomSearchBar = styled(SearchBar)`
    width: 100%;
    margin-bottom: 20px;
`;

export const Btn = styled(CustomBtn)``;
