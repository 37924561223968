import { message } from 'antd';
import * as actionTypes from './actionTypes';

const success = () => {
    message.success('Değişiklikler başarıyla kaydedildi');
};

const error = () => {
    message.error('Bir hata oluştu.');
};

export const getStoreInfoAction = () => ({
    type: actionTypes.GET_STORE_INFO,
});

export const getStoreInfoSuccessAction = (info) => ({
    type: actionTypes.GET_STORE_INFO_SUCCESS,
    payload: info,
});

export const getStoreInfoFailAction = () => ({
    type: actionTypes.GET_STORE_INFO_FAIL,
});

export const updateStoreInfoAction = (newInfo) => ({
    type: actionTypes.UPDATE_STORE_INFO,
    payload: newInfo,
});

export const updateStoreInfoSuccessAction = () => {
    success();
    return {
        type: actionTypes.UPDATE_STORE_INFO_SUCCESS,
    };
};

export const updateStoreInfoFailAction = () => {
    error();
    return {
        type: actionTypes.UPDATE_STORE_INFO_FAIL,
    };
};

export const getReferanceNumberAction = () => ({
    type: actionTypes.GET_REF_NO,
});

export const getReferanceNumberSuccessAction = (ref) => ({
    type: actionTypes.GET_REF_NO_SUCCESS,
    payload: ref,
});

export const getReferanceNumberFailAction = () => ({
    type: actionTypes.GET_REF_NO_FAIL,
});

export const addReferanceNumberAction = (ref) => ({
    type: actionTypes.ADD_REF_NO,
    payload: ref,
});

export const addReferanceNumberSuccessAction = () => ({
    type: actionTypes.ADD_REF_NO_SUCCESS,
});

export const addReferanceNumberFailAction = () => ({
    type: actionTypes.ADD_REF_NO_FAIL,
});

export const getEmailInfoAction = () => ({
    type: actionTypes.GET_EMAIL_INFO,
});

export const getEmailInfoSuccessAction = (data) => ({
    type: actionTypes.GET_EMAIL_INFO_SUCCESS,
    payload: data,
});
export const getEmailInfoFailAction = () => ({
    type: actionTypes.GET_EMAIL_INFO_FAIL,
});

export const updateEmailInfoAction = () => ({
    type: actionTypes.UPDATE_EMAIL_INFO,
});

export const updateEmailInfoSuccessAction = () => {
    success();
    return {
        type: actionTypes.UPDATE_EMAIL_INFO_SUCCESS,
    };
};
export const updateEmailInfoFailAction = () => {
    error();
    return {
        type: actionTypes.UPDATE_EMAIL_INFO_FAIL,
    };
};

export const postEmailInfoAction = () => ({
    type: actionTypes.POST_EMAIL_INFO,
});

export const postEmailInfoSuccessAction = () => {
    success();
    return {
        type: actionTypes.POST_EMAIL_INFO_SUCCESS,
    };
};
export const postEmailInfoFailAction = () => {
    error();
    return {
        type: actionTypes.POST_EMAIL_INFO_FAIL,
    };
};
