/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */

import { Row, Col } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DividerStyle, ItemRow, InformationStyles, CustomButton, HiddenRow, CloseButton, ButtonCol } from './styles.js';
import { Text } from '../../../../../components/Typography/styles.js';
import { ReactComponent as CloseBtn } from '../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';
import Calendar from './Calendar';
import ContactCard from './ContactCard';
import CategoryCard from './CategoryCard';
import MenuModal from './MenuModal';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery.js';
import rightArrow from '../../../../../../Assets/Dashboard/right-arrow.png';

const InformationPane = ({ categories }) => {
    const selector = useSelector;
    const history = useHistory();
    const location = useLocation();
    const { xs } = useResponsive();
    const [isModalVisible, setIsModalVisbile] = useState(false);
    const modalCancel = () => {
        setIsModalVisbile(false);
    };
    const storeData = selector((state) => state.storeStore.storeData);

    const today = new Date();
    let startDate = '';
    let endDate = '';
    storeData.calendar.map((item) => {
        if (new Date(item.start_date).getDate() === today.getDate()) {
            startDate = new Date(item.start_date);
            endDate = new Date(item.end_date);
        }
        return 0;
    });

    const showHidden = (id, height) => {
        const row = document.getElementsByClassName('hidden-row')[id];
        const thisHeight = '';
        row.style.height = thisHeight.concat(height, 'px');
        document.getElementsByClassName('show-btn')[id].style.display = 'none';
        document.getElementsByClassName('close-btn')[id].style.display = 'block';
    };

    const hideHidden = (id) => {
        const row = document.getElementsByClassName('hidden-row')[id];
        row.style.height = '0px';
        document.getElementsByClassName('show-btn')[id].style.display = 'block';
        document.getElementsByClassName('close-btn')[id].style.display = 'none';
    };

    if (storeData) {
        return (
            <InformationStyles>
                <Col>
                    <ItemRow>
                        <Col span={9}>
                            <Text weight="semi-bold">Teslim Saatleri</Text>
                        </Col>
                        <Col span={12}>
                            <Text weight="light">
                                {storeData.package_settings.delivery_time_start}&nbsp;-&nbsp;{storeData.package_settings.delivery_time_end}
                            </Text>
                        </Col>
                    </ItemRow>
                    {!xs && <DividerStyle className="light" />}
                    <ItemRow>
                        <Col span={9}>
                            <Text weight="semi-bold">Paket Teslimi</Text>
                        </Col>
                        <Col span={12}>
                            {storeData.package_settings.courier_delivery_time_start ? (
                                <Text weight="light">Gel-Al Paket ve Kurye Paket</Text>
                            ) : (
                                <Text weight="light">Gel-Al Paket</Text>
                            )}
                        </Col>
                    </ItemRow>
                    {!xs && <DividerStyle className="light" />}
                    <ItemRow>
                        <Col span={9}>
                            <Text weight="semi-bold">Çalışma Saatleri</Text>
                        </Col>
                        <Col xs={24} sm={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col xs={18} span={20}>
                                {startDate ? (
                                    <Text weight="light">
                                        Bugun:&nbsp;{startDate.getHours()}:{startDate.getMinutes() === 0 ? <>00</> : startDate.getMinutes()} -
                                        {endDate.getHours()} :{endDate.getMinutes() === 0 ? <>00</> : endDate.getMinutes()}
                                    </Text>
                                ) : (
                                    <Text weight="light" size="16px">
                                        Bugun Saat Yok
                                    </Text>
                                )}
                            </Col>
                            {xs ? (
                                <ButtonCol span={5}>
                                    <Row justify="center">
                                        <img src={rightArrow} alt="" onClick={() => history.push(`${location.pathname}/calicma-saatleri`)} />
                                    </Row>
                                </ButtonCol>
                            ) : (
                                <ButtonCol span={4}>
                                    <Row justify="end">
                                        <CustomButton className="show-btn" type="tertiaryReverse" onClick={() => showHidden(0, 250)}>
                                            Detaylı Görüntüle
                                        </CustomButton>
                                        <CloseButton onClick={() => hideHidden(0)} className="close-btn" type="text">
                                            <CloseBtn />
                                        </CloseButton>
                                    </Row>
                                </ButtonCol>
                            )}
                        </Col>

                        <HiddenRow className="hidden-row">
                            {startDate ? <Calendar /> : <Text>Bu hafta için takvim bilgisi bulunmamaktadır.</Text>}
                        </HiddenRow>
                    </ItemRow>

                    {!xs && <DividerStyle className="light" />}
                    <ItemRow>
                        <Col span={9}>
                            <Text weight="semi-bold">Ödeme Şekli</Text>
                        </Col>
                        <Col span={12}>
                            <Text weight="light">Online Kredi Kartı/Banka Kartı</Text>
                        </Col>
                    </ItemRow>
                    {!xs && <DividerStyle className="light" />}
                    <ItemRow>
                        <Col span={9}>
                            <Text weight="semi-bold">İletişim</Text>
                        </Col>
                        <Col xs={24} sm={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col xs={18} span={20}>
                                <Text weight="light">İşletme Adı: {storeData.name}</Text>
                            </Col>
                            {xs ? (
                                <ButtonCol span={5}>
                                    <Row justify="center">
                                        <img src={rightArrow} alt="" onClick={() => history.push(`${location.pathname}/magaza-bilgisi`)} />
                                    </Row>
                                </ButtonCol>
                            ) : (
                                <ButtonCol span={4}>
                                    <Row justify="end">
                                        <CustomButton className="show-btn" type="tertiaryReverse" onClick={() => showHidden(1, 450)}>
                                            Detaylı Görüntüle
                                        </CustomButton>
                                        <CloseButton onClick={() => hideHidden(1)} className="close-btn" type="text">
                                            <CloseBtn />
                                        </CloseButton>
                                    </Row>
                                </ButtonCol>
                            )}
                        </Col>
                        <HiddenRow className="hidden-row">
                            <ContactCard />
                        </HiddenRow>
                    </ItemRow>
                    {!xs && <DividerStyle className="light" />}
                    {!xs && (
                        <ItemRow>
                            <Col span={9}>
                                <Text weight="semi-bold">Restoran Menüsü</Text>
                            </Col>
                            <Col span={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Col span={9}>
                                    <Text weight="light">İşletme Adı</Text>
                                </Col>

                                <ButtonCol span={6}>
                                    <Row justify="end">
                                        <CustomButton className="modal-open" type="tertiaryReverse" onClick={() => setIsModalVisbile(true)}>
                                            Detaylı Görüntüle
                                        </CustomButton>
                                    </Row>
                                </ButtonCol>
                            </Col>
                        </ItemRow>
                    )}
                    <MenuModal dismiss={modalCancel} onCancel={modalCancel} closeIcon={<CloseBtn />} visible={isModalVisible} />
                    {!xs && <DividerStyle className="light" />}
                    <ItemRow>
                        <Col span={9}>
                            <Text weight="semi-bold">Gıda Kategorileri</Text>
                        </Col>
                        <Col xs={24} sm={15} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col span={9}>
                                <Text weight="light">{categories.map((category) => `${category.name.name} `)}</Text>
                            </Col>
                            {xs ? (
                                <ButtonCol span={5}>
                                    <Row justify="center">
                                        <img src={rightArrow} alt="" onClick={() => history.push(`${location.pathname}/gida-kategorileri`)} />
                                    </Row>
                                </ButtonCol>
                            ) : (
                                <ButtonCol span={6}>
                                    <Row justify="end">
                                        <CustomButton className="show-btn" type="tertiaryReverse" onClick={() => showHidden(2, 300)}>
                                            Detaylı Görüntüle
                                        </CustomButton>
                                        <CloseButton onClick={() => hideHidden(2)} className="close-btn" type="text">
                                            <CloseBtn />
                                        </CloseButton>
                                    </Row>
                                </ButtonCol>
                            )}
                        </Col>
                        <HiddenRow className="hidden-row">
                            <CategoryCard storeCategories={categories} />
                        </HiddenRow>
                    </ItemRow>
                </Col>
            </InformationStyles>
        );
    }
    return null;
};

export default InformationPane;
