import styled from 'styled-components';
import { Col, Row } from 'antd';

export const QuestionItemStyles = styled(Col)``;

export const QuestionWrapper = styled(Row)`
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
    padding-bottom: 16px;
    padding-top: 16px;
    cursor: pointer;
`;
export const AnswerWrapper = styled(Row)`
    padding-bottom: 24px;
    padding-top: 24px;
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
`;
