import React, { useState } from 'react';
import moment from 'moment';
import SiderStyles, { Date, DateContainer, Content, InfoText } from './styles';
import Package from './Package/index';

const Sider = ({ selectedDate, localData, orders }) => {
    const orderExists = orders.length !== 0;
    const months = localData.months();
    const days = localData.weekdaysMin();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState();

    if (orderExists && startDate === '') {
        setStartDate(moment(orders[0].start_date).format('HH:mm'));
        setEndDate(moment(orders[0].end_date).format('HH:mm'));
    }
    return (
        <SiderStyles>
            <DateContainer>
                <Date size="title" weight="light">
                    {`${selectedDate.date()} ${months[selectedDate.month()]} ${days[selectedDate.day()]}`}
                </Date>
            </DateContainer>
            <Content>
                {!orderExists ? (
                    <InfoText>Satış bilgisi bulunmamaktadır</InfoText>
                ) : (
                    [...Array(orders[0].box_count)].map((e, i) => <Package key={i} name={`Paket ${i + 1}`} startDate={startDate} endDate={endDate} />)
                )}
            </Content>
        </SiderStyles>
    );
};

export default Sider;
