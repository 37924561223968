/* eslint-disable react-hooks/exhaustive-deps */
import { Row } from 'antd';
import React, { useEffect } from 'react';
import { CustomTabs } from './styles';
import AddressPane from './AddressPane';
import ReceivePane from './ReceivePane';
import PaymentPane from './PaymentPane';

const TabsPane = (props) => {
    const { TabPane } = CustomTabs;
    const {
        tabKey,
        setTabKey,
        check1,
        check2,
        setCheck1,
        setCheck2,
        setComplete,
        timeInt,
        setTimeInt,
        packet,
        setPacket,
        addressInfo,
        setAddressInfo,
        order,
        setCardToken,
        cardForm,
        setCardForm,
        newCard,
        setNewCard,
        threeD,
        setThreeD,
        checkedSaveCard,
        setCheckedSaveCard,
        packageId,
    } = props;

    useEffect(() => {
        if (packageId === '1' || packageId === '3') setPacket(true);
        else if (packageId === '2') setPacket(false);
    }, [packageId]);

    return (
        <Row>
            <CustomTabs activeKey={tabKey} centered onTabClick={(e) => e !== '3' && setTabKey(e)}>
                <TabPane className="address-tab" tab="Adres" key="1">
                    <AddressPane
                        setKey={setTabKey}
                        packet={packet}
                        setPacket={setPacket}
                        addressInfo={addressInfo}
                        setAddressInfo={setAddressInfo}
                        packageId={packageId}
                    />
                </TabPane>
                <TabPane tab="Teslimat" key="2">
                    <ReceivePane setKey={setTabKey} packet={packet} timeInt={timeInt} setTimeInt={setTimeInt} packageId={packageId} />
                </TabPane>
                <TabPane tab="Ödeme" key="3">
                    <PaymentPane
                        addressInfo={addressInfo}
                        packet={packet}
                        check1={check1}
                        check2={check2}
                        setCheck1={setCheck1}
                        setCheck2={setCheck2}
                        setComplete={setComplete}
                        timeInt={timeInt}
                        order={order}
                        setCardToken={setCardToken}
                        cardForm={cardForm}
                        setCardForm={setCardForm}
                        newCard={newCard}
                        setNewCard={setNewCard}
                        threeD={threeD}
                        setThreeD={setThreeD}
                        checkedSaveCard={checkedSaveCard}
                        setCheckedSaveCard={setCheckedSaveCard}
                    />
                </TabPane>
            </CustomTabs>
        </Row>
    );
};

export default TabsPane;
