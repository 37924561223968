/* eslint-disable no-console */
import { message } from 'antd';
import * as actions from './actions';

export const getMonthlyOrders = () => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getMonthlyOrdersAction());

        const userData = JSON.parse(localStorage.getItem('user'));
        const orders = await service({
            method: 'GET',
            path: `store/time_interval/?store=${userData.stores.id}`,
            sendToken: true,
        });
        dispatch(actions.getMonthlyOrdersSuccessAction(orders));
        return orders;
    } catch (err) {
        dispatch(actions.getMonthlyOrdersFailAction());
    }
};

export const updateDailyPlan = (plan) => async (dispatch, getState, service) => {
    try {
        dispatch(actions.updateDailyPlanAction(plan));
        await service({
            method: 'POST',
            path: `store/time_interval/bulk_update/`,
            sendToken: true,
            body: plan,
        });
        dispatch(actions.updateDailyPlanSuccessAction());
        message.success('Değişiklikler kaydedildi');
    } catch (err) {
        if (Array.isArray(err) && err[0].split(' ').slice(0, 5).join(' ') === 'Kullanıcılar tarafından satın alınmış paketleriniz') {
            message.warn(
                'Kullanıcılar tarafından satın alınmış paketleriniz olduğu için, restoran durumunu update edemezsiniz, önce siparişlerinizi teslim etmeli ya da satın alınan paketleri silmeli ve ücret iadesi yapmalısınız!'
            );
        }
        dispatch(actions.updateDailyPlanFailAction());
    }
};

export const getAlertCardText = () => async (dispatch, getState, service) => {
    try {
        service({
            method: 'GET',
            path: 'notification/',
            sendToken: true,
        }).then((result) => {
            if (result.results.length > 0) {
                dispatch(actions.getAlertCardText(result.results[0].report_text.split(':')[0]));
            }
        });
    } catch (err) {
        console.log(err);
    }
};

export const getClosestSaleDate = () => async (dispatch, getState, service) => {
    try {
        const result = await service({
            method: 'GET',
            path: 'store/closestsale/',
            sendToken: true,
        });
        dispatch(actions.getClosestSaleDate(result));
    } catch (err) {
        console.log(err);
    }
};
