/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Range } from 'rc-slider';
import { Text } from '../../../../components/Typography/styles';
import { ContainerStyle, ButtonStyle, CustomRow, CustomInput } from './styles';
import 'rc-slider/assets/index.css';
import { ReactComponent as DropDownArrow } from '../../../../../Assets/Shared/FindRestaurant/collapsible-icon.svg';
import { HiddenRow } from '../RightTop/SortSection/styles';
import { CustomCol } from '../../FindRestaurant/ProgressBar/styles';

export default function SliderBar(props) {
    const { price, setPrice, saveHandler } = props;
    const [showRow, setShowRow] = useState(false);
    const toggleRow = () => setShowRow(!showRow);

    useEffect(() => {
        saveHandler();
    }, [price]);

    return (
        <Col>
            <Row>
                <CustomRow align="middle" justify="space-between">
                    <Text weight="500" color="black">
                        Paket Fiyatı
                    </Text>
                    <DropDownArrow style={{ cursor: 'pointer' }} onClick={toggleRow} />
                </CustomRow>
            </Row>
            <HiddenRow className="progress-bar" active={showRow}>
                <CustomCol className="range-col">
                    <Range
                        min={10}
                        max={500}
                        step={10}
                        defaultValue={[10, 500]}
                        value={price}
                        onChange={setPrice}
                        trackStyle={[{ backgroundColor: '#8FD89F', height: 10, borderColor: '#8FD89F' }]}
                        handleStyle={[
                            { backgroundColor: '#8FD89F', marginTop: -2, borderColor: '#8FD89F' },
                            { backgroundColor: '#8FD89F', marginTop: -2, borderColor: '#8FD89F' },
                        ]}
                        railStyle={{ backgroundColor: '#E2E9EF', height: 10 }}
                    />
                </CustomCol>

                <ContainerStyle>
                    <ButtonStyle>
                        <Row justify="center">
                            <Text style={{ fontSize: 12 }} weight="light" color="black">
                                Minimum fiyat
                            </Text>
                            <CustomInput value={price[0]} onChange={(e) => setPrice([e.target.value, price[1]])} />
                            &nbsp;₺
                        </Row>
                    </ButtonStyle>
                    <ButtonStyle>
                        <Row justify="center">
                            <Text style={{ fontSize: 12 }} weight="light" color="black">
                                Maksimum fiyat
                            </Text>
                            <CustomInput value={price[1]} onChange={(e) => setPrice([price[0], e.target.value])} />
                            &nbsp;₺
                        </Row>
                    </ButtonStyle>
                </ContainerStyle>
            </HiddenRow>
        </Col>
    );
}
