/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Dropdown, Menu, Row, Col } from 'antd';
import { CustomRow } from './styles';
import { DropdownButton } from '../../../ProfilePage/Content/AddressPane/AddressForm/styles';
import { Text } from '../../../../../components/Typography/styles';
import { ReactComponent as DropdownArrow } from '../../../../../../Assets/Shared/Profile/dropdown-arrow.svg';

const LocalitySettings = () => {
    const [lang, setLang] = useState('Türkçe');
    const [city, setCity] = useState('Istanbul');
    const languageMenu = () => (
        <Menu style={{ height: 'auto', maxHeight: 200, overflow: 'auto' }}>
            <Menu.Item key={0}>
                <Text size="14px">Türkçe</Text>
            </Menu.Item>
            <Menu.Item key={1}>
                <Text size="14px">English</Text>
            </Menu.Item>
            <Menu.Item key={2}>
                <Text size="14px">Deutsch</Text>
            </Menu.Item>
        </Menu>
    );

    const SehirMenu = () => (
        <Menu style={{ height: 200, overflow: 'auto' }}>
            <Menu.Item key={0}>
                <Text size="14px">Istanbul</Text>
            </Menu.Item>
            <Menu.Item key={1}>
                <Text size="14px">Ankara</Text>
            </Menu.Item>
            <Menu.Item key={2}>
                <Text size="14px">Izmir</Text>
            </Menu.Item>
        </Menu>
    );

    return (
        <CustomRow className="main-row">
            <CustomRow>
                <Text weight="semi-bold">Kullanım Dili</Text>
            </CustomRow>
            <CustomRow className="dropdown-row">
                <Col span={8}>
                    <Dropdown overlay={languageMenu} overlayStyle={{ border: '2px solid #F2F2FC' }} trigger={['click']}>
                        <DropdownButton style={{ marginTop: 10 }}>
                            <Row align="middle" justify="space-between">
                                <Text size="14px">{lang}</Text>
                                <DropdownArrow />
                            </Row>
                        </DropdownButton>
                    </Dropdown>
                </Col>
            </CustomRow>
            {/* <CustomRow className="dropdown-row">
                <Text weight="semi-bold">Şehir Seç</Text>
            </CustomRow>
            <CustomRow className="dropdown-row">
                <Col span={8}>
                    <Dropdown overlay={languageMenu} overlayStyle={{ border: '2px solid #F2F2FC' }} trigger={['click']}>
                        <DropdownButton style={{ marginTop: 10 }}>
                            <Row align="middle" justify="space-between">
                                <Text size="14px">{city}</Text>
                                <DropdownArrow />
                            </Row>
                        </DropdownButton>
                    </Dropdown>
                </Col>
            </CustomRow> */}
        </CustomRow>
    );
};

export default LocalitySettings;
