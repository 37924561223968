import styled from 'styled-components';
import { Col, Row } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const CustomRow = styled(Row)`
    @media (max-width: 400px) {
        align-items: center;
        justify-content: center;
    }
`;

export const CustomCol = styled(Col)`
    @media (max-width: 400px) {
        align-items: center;
        justify-content: center;
        display: flex;
        min-width: 100%;
    }
`;

export const StyledSlider = styled(Slider)`
    & .slick-dots li button:before {
        font-size: 10px;
    }
    & .slick-dots li.slick-active button:before {
        color: #8fd89f;
        font-weight: bold;
        opacity: 100%;
    }
`;
