import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Text } from '../../../../components/Typography/styles';

export const CartWrapper = styled(Col)`
    background-color: white;
    border-radius: 18px;
    margin-bottom: 9%;
    padding-bottom: 5%;
    width: 330px;

    @media (max-width: 1162px) {
        margin-top: 2rem;
    }
`;

export const TextWrapper = styled(Col)`
    border-bottom: 4px solid #f2f2fc;
    padding-top: 5%;
    padding-bottom: 7%;
    text-align: center;
    margin-bottom: 7%;
`;

export const DividerStyle = styled(Row)`
    width: 75%;
    height: 2px;
    background-color: #f2f2fc;
`;

export const WideDividerStyle = styled(Row)`
    width: 100%;
    height: 2px;
    background-color: #f2f2fc;
    margin-top: 35px;
    margin-bottom: 7%;
`;

export const DividerWrapper = styled(Row)`
    justify-content: center;
    align-items: center;
    margin-bottom: 9%;
`;

export const ButtonWrapper = styled(Row)`
    justify-content: center;
    height: 48px;
    width: 100%;
`;

export const TextStyle = styled(Row)`
    margin-left: 11%;
`;

export const ItemRow = styled(Row)`
    width: 100%;
    padding-left: 30px;
    padding-right: 20px;

    &.price-row {
        margin-bottom: 20px;
    }
`;

export const DiscountTextStyle = styled(Text)`
    margin-left: 30px;
    text-decoration: line-through;
`;

export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    width: 69px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 0px;
`;

export const MenuCol = styled(Col)`
    overflow: hidden;
`;
