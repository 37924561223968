import styled from 'styled-components';
import { Col, Row, Checkbox } from 'antd';

export const Wrapper = styled(Col)`
    margin-bottom: 30px;
`;

export const FoodsWrapper = styled(Col)`
    padding-left: 25px;
    padding-right: 25px;
`;

export const FoodWrapper = styled(Row)`
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
    min-height: 102px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const CustomCheckbox = styled(Checkbox)``;
