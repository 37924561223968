import React from 'react';
import { Col, Row } from 'antd';
import { between } from 'polished';
import { InfoSectionStyles } from './styles';
import protectTheWorld from '../../../../../Assets/Shared/ForBusiness/protectTheWorld.svg';
import payLess from '../../../../../Assets/Shared/ForBusiness/payLess.svg';
import saveTime from '../../../../../Assets/Shared/ForBusiness/saveTime.svg';
import { Text } from '../../../../components/Typography/styles';

const items = [
    {
        img: payLess,
        title: 'Daha Az Öde',
        message:
            'Satılamayan ürünlerin çöpe atılması, gıda israfının yanında gelir kaybına da yol açar. Döngü, gelir kayıplarını önüne geçerek, işletmelerin karlılığını artırır.',
    },
    {
        img: saveTime,
        title: 'Zamandan Kazan',
        message:
            'Döngü’ye özel fiyatlarla müşteri ağını büyüt! Siparişlerini teslim almak üzere işletmeni ziyaret eden Döngü müşterilerine, diğer ürün ve hizmetlerini de tanıtabilirsin.',
    },
    {
        img: protectTheWorld,
        title: 'Gezegeni Koru',
        message: 'Döngü’nün bir parçası olmak, işletmenin çevreye duyarlılığını görünür kılacak. Gezegenin kaynaklarını birlikte koruyalım!',
    },
];

const InfoSection = () => (
    <InfoSectionStyles span={24}>
        <Row>
            {items.map((item, index) => (
                <Col key={index} xs={24} sm={24} md={24} lg={8} xl={8}>
                    <Row justify="center" style={{}}>
                        <img src={item.img} alt="" width="100%" />
                    </Row>
                    <Row justify="center" style={{ marginBottom: between('30px', '60px', '320px', '1920px') }}>
                        <Text size={between('24px', '36px', '320px', '1920px')} weight="bold" style={{ textAlign: 'center' }}>
                            {item.title}
                        </Text>
                    </Row>
                    <Row justify="center" style={{ marginBottom: between('30px', '80px', '320px', '1920px') }}>
                        <Text size="description" style={{ textAlign: 'center', width: '90%' }}>
                            {item.message}
                        </Text>
                    </Row>
                </Col>
            ))}
        </Row>
    </InfoSectionStyles>
);

export default InfoSection;
