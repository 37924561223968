import styled from 'styled-components';
import { Modal, Row, Select } from 'antd';
import Button from '../../../../../components/Button';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        height: 150px;
    }

    .ant-modal-body {
        overflow-y: scroll;
        height: 100% !important;
        min-width: 370px !important;

        ::-webkit-scrollbar {
            width: 11px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
            border-bottom-right-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }
`;

export const CustomButton = styled(Button)`
    width: 100%;
    height: 100%;
`;

export const MenuWrapper = styled(Row)``;

export const SearchBarWrapper = styled(Row)`
    padding: 15px 25px;
`;

export const Footer = styled(Row)`
    padding-top: 34px;
`;

export const CustomSelect = styled(Select)`
    width: 100% !important;

    .ant-select-selection-item {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 500;
    }

    .ant-select-selector {
        border-width: 2px !important;
        height: 48px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .ant-select-selection-placeholder {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 500;
    }
    .ant-select-selector:hover + .ant-select-arrow {
        color: ${({ theme }) => theme.primaryColor};
    }
    .ant-select-arrow {
        padding-right: 24px;
    }

    .ant-select-selection-item {
        color: #8fd89f;
    }
`;
export const TextContainer = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
`;
