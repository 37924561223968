/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../components/Typography/styles';
import AllCard from './AllCard';
import OrdersCard from './OrdersCard';
import { fetchNotifications } from '../../../../../services/Redux/Shared/notifications/api';
import { TabArrow } from '../../ProfilePage/Content/styles';
import Spinner from '../../../../components/Spinner/index';

const Content = () => {
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector;
    const tabChange = (id) => {
        const temp = document.getElementsByClassName('tab-btn-row');
        if (selected === null) {
            temp[id].style.backgroundColor = '#f2f2fc';
            setSelected(id);
        } else {
            temp[id].style.backgroundColor = '#f2f2fc';
            temp[selected].style.backgroundColor = '';
            setSelected(id);
        }
    };
    useEffect(() => {
        if (selected === null) {
            tabChange(0);
        }
    });
    useEffect(() => {
        dispatch(fetchNotifications());
    }, []);

    const notifications = selector((state) => state.notificationStore.notificationList);

    return (
        <CustomRow>
            <CustomCol span={5}>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(0)}>
                    <Text weight="light">Tümü</Text>
                    <TabArrow />
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(1)}>
                    <Text weight="light">Siparişlerim</Text>
                    <TabArrow />
                </CustomRow>
                {/* <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(2)}>
                    <Text weight="light">Bana Özel</Text>
                    <TabArrow />
                </CustomRow> */}
            </CustomCol>
            {notifications ? (
                <CustomCol span={19}>
                    <CustomRow>{selected === 0 ? <AllCard /> : selected === 1 ? <OrdersCard /> : <CustomRow />}</CustomRow>
                </CustomCol>
            ) : (
                <Spinner />
            )}
        </CustomRow>
    );
};

export default Content;
