import styled from 'styled-components';
import { Row, Input, Form } from 'antd';
import Button from '../../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;

    &.name-input-row {
        margin-top: 15px;
    }

    &.number-input-row {
        margin-top: 26px;
    }

    &.bottom-row {
        margin-bottom: 30px;
    }

    &.button-row {
        margin-top: 20px;
    }
`;

export const CustomInput = styled(Input)`
    width: 100%;
    height: 48px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px 20px;
`;

export const CustomButton = styled(Button)`
    width: 250px;
    height: 48px;
`;

export const FormItem = styled(Form.Item)`
    width: 100%;
    margin-bottom: 0px;
`;
