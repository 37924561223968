import { Row, Card } from 'antd';
import styled from 'styled-components';

export const InformationStyles = styled.div`
    margin-top: 30px;
`;

export const CustomCard = styled(Card)`
    border: 2px solid #f5f9fc;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    .ant-card-body {
        padding: 12px;
    }
`;

export const CustomRow = styled(Row)`
    margin-top: 10px;

    &.email-row {
        margin-top: 13px;
    }
`;

export const Table = styled.table`
    border: 2px solid #f5f9fc;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    margin-top: 20px;
`;

export const TableRow = styled.tr`
    border: 2px solid #f5f9fc;
`;

export const TableHead = styled.th`
    border: 2px solid #f5f9fc;
`;

export const TableDate = styled.td`
    border: 2px solid #f5f9fc;
    padding: 15px 10px;
`;
