// GET BASKET

export const GET_BASKET = 'GET_BASKET';
export const GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS';
export const GET_BASKET_FAIL = 'GET_BASKET_FAIL';

// POST ADD BASKET

export const POST_BASKET_ADD = 'POST_BASKET_ADD';
export const POST_BASKET_ADD_SUCCESS = 'POST_BASKET_ADD_SUCCESS';
export const POST_BASKET_ADD_FAIL = 'POST_BASKET_ADD_FAIL';

// REMOVE FROM BASKET

export const POST_BASKET_REMOVE = 'POST_BASKET_REMOVE';
export const POST_BASKET_REMOVE_SUCCESS = 'POST_BASKET_REMOVE_SUCCESS';
export const POST_BASKET_REMOVE_FAIL = 'POST_BASKET_REMOVE_FAIL';

// REMOVE FROM BASKET

export const POST_BASKET_REMOVE_ALL = 'POST_BASKET_REMOVE_ALL';
export const POST_BASKET_REMOVE_ALL_SUCCESS = 'POST_BASKET_REMOVE_ALL_SUCCESS';
export const POST_BASKET_REMOVE_ALL_FAIL = 'POST_BASKET_REMOVE_ALL_FAIL';

// CREATE ORDER

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

// GET ORDER

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

// RESET ADD TO BASKET

export const RESET_ADDERROR = 'RESET_ADDERROR';
