/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button from '../../../../components/Button';
import { Text } from '../../../../components/Typography/styles';
import { CartWrapper, TextWrapper, WideDividerStyle, DividerStyle, DividerWrapper, ButtonWrapper, TextStyle } from './styles';
import Cart from '../../../../../Assets/Shared/FindRestaurant/graybasket.svg';
import YellowCart from '../../../../../Assets/Shared/FindRestaurant/yellowbasket.svg';
import OrderSummary from './OrderSummary';
import { fetchBasket } from '../../../../../services/Redux/Shared/order/api';
import { getAddressActive } from '../../../../../services/Redux/Shared/profile/api';

const RightSide = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const {
        threeD,
        setThreeD,
        newCard,
        packet,
        timeInt,
        check1,
        check2,
        setCheck1,
        setCheck2,
        checkout,
        setCheckout,
        tabKey,
        setTabKey,
        updateSummary,
        setUpdateSummary,
        complete,
        order,
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            dispatch(fetchBasket());
            dispatch(getAddressActive());
        } else {
            history.push('/restoran-bul');
        }
    }, [complete]);

    const selector = useSelector;
    const activeAddress = selector((state) => state.profileStore.activeAddress);
    const basket = selector((state) => state.customerOrderStore.basketList);

    if (basket && (typeof basket === 'string' || basket.length === 0)) {
        return (
            <CartWrapper>
                <TextWrapper>
                    <Text size="description" weight="light" color="black">
                        Sepetim
                    </Text>
                </TextWrapper>
                {user ? (
                    <div>
                        <Row style={{ marginLeft: 20, alignItems: 'center', marginBottom: '9%', width: '100%' }}>
                            <Col span={4}>
                                <img src={YellowCart} alt="img" style={{ width: 48, height: 48 }} />
                            </Col>
                            <Col span={18}>
                                <Text weight="300" color="black" style={{ marginLeft: 5 }}>
                                    Hoş geldin &nbsp;
                                    <Text>
                                        {user.first_name} &nbsp;
                                        {user.last_name}
                                    </Text>
                                    <div style={{ width: '100%', height: 2, backgroundColor: '#F2F2FC' }} />
                                </Text>
                            </Col>
                        </Row>
                        <Text weight="300" color="black" style={{ marginLeft: 15, justifyContent: 'center', display: 'flex' }}>
                            <strong>Sepetin Henüz Boş</strong>
                        </Text>
                    </div>
                ) : (
                    <div>
                        <Row style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '9%' }}>
                            <img src={Cart} alt="img" style={{ width: 86, height: 86 }} />
                        </Row>
                        <DividerWrapper>
                            <DividerStyle />
                        </DividerWrapper>
                        <ButtonWrapper>
                            <Button
                                type="secondary"
                                style={{
                                    height: '100%',
                                    width: '61%',
                                }}
                                onClick={() => history.push('/giris')}>
                                <Text size="regular" weight="regular" color="white">
                                    Giriş Yap
                                </Text>
                            </Button>
                        </ButtonWrapper>
                    </div>
                )}
                <WideDividerStyle />
                {user && (
                    <TextStyle>
                        <Text weight="300" color="black">
                            <strong> Konumun:</strong>
                        </Text>
                    </TextStyle>
                )}
                <TextStyle>
                    {activeAddress && (
                        <Text weight="300" size="light" color="black" style={{ textDecoration: 'underline', width: '70%' }}>
                            {activeAddress.address}
                        </Text>
                    )}
                </TextStyle>
            </CartWrapper>
        );
    }
    return (
        <OrderSummary
            setCheckout={setCheckout}
            tabKey={tabKey}
            setTabKey={setTabKey}
            checkout={checkout}
            check1={check1}
            check2={check2}
            setCheck1={setCheck1}
            setCheck2={setCheck2}
            updateSummary={updateSummary}
            setUpdateSummary={setUpdateSummary}
            order={order}
            timeInt={timeInt}
            packet={packet}
            newCard={newCard}
            threeD={threeD}
            setThreeD={setThreeD}
        />
    );
};

export default RightSide;
