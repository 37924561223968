import { Row, Select } from 'antd';
import styled from 'styled-components';

export const SelectList = styled(Select)``;

export const Wrapper = styled(Row)`
    background-color: #fff;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    justify-content: center;
    padding-top: 1.5%;
`;

export const DividerStyle = styled(Row)`
    background-color: #f2f2fc;
    width: 100%;
    height: 4px;
    margin-bottom: 1.5%;
    margin-top: 1rem;

    @media (max-width: 576px) {
        margin-bottom: 0;
        margin-top: 0.5rem;
    }
`;
