import React from 'react';
import { Row } from 'antd';
import { UserPolicyPageStyles } from './styles';
import UserAgreement from './Agreement';

const UserPolicy = () => (
    <UserPolicyPageStyles>
        <Row>
            <UserAgreement />
        </Row>
    </UserPolicyPageStyles>
);

export default UserPolicy;
