import React from 'react';
import { Col } from 'antd';
import { CustomRow } from './styles';
import { Text } from '../../../../../../../../components/Typography/styles';

const AddressRow = (props) => {
    const { resp } = props;
    return (
        <CustomRow justify="space-between">
            <Col span={11}>
                <CustomRow className="card-row">
                    <CustomRow>
                        <Text weight="light">{resp.address.name}</Text>
                    </CustomRow>
                    <CustomRow>
                        <Text size="14px">{resp.address.province}</Text>
                    </CustomRow>
                    <CustomRow className="margin-top-5">
                        <Text size="11px" weight="light">
                            {resp.address.address}
                        </Text>
                    </CustomRow>
                    <CustomRow className="margin-top-5">
                        <Text size="11px" weight="light">
                            {resp.address.phone_number}
                        </Text>
                    </CustomRow>
                    <CustomRow className="margin-top-5">
                        <Text size="11px" weight="light">
                            {resp.address.description}
                        </Text>
                    </CustomRow>
                </CustomRow>
            </Col>
            <Col span={11}>
                {resp.billing_address ? (
                    <CustomRow className="card-row">
                        <CustomRow>
                            <Text weight="light">{resp.billing_address.name}</Text>
                        </CustomRow>
                        <CustomRow>
                            <Text size="14px">{resp.billing_address.province}</Text>
                        </CustomRow>
                        <CustomRow className="margin-top-5">
                            <Text size="11px" weight="light">
                                {resp.billing_address.address}
                            </Text>
                        </CustomRow>
                        <CustomRow className="margin-top-5">
                            <Text size="11px" weight="light">
                                {resp.billing_address.phone_number}
                            </Text>
                        </CustomRow>
                        <CustomRow className="margin-top-5">
                            <Text size="11px" weight="light">
                                {resp.billing_address.description}
                            </Text>
                        </CustomRow>
                    </CustomRow>
                ) : (
                    <></>
                )}
            </Col>
        </CustomRow>
    );
};

export default AddressRow;
