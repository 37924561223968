import styled from 'styled-components';
import Card from '../../../../components/Card';

export const CustomCard = styled(Card)`
    width: 100%;
    margin-right: 2rem;
    .ant-card-body {
        padding: 39px;
        padding-top: 15px;
    }
`;
