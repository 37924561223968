import styled from 'styled-components';
import { Tabs } from 'antd';

export const CustomTabs = styled(Tabs)`
    width: 100%;

    .ant-tabs-nav {
        background: #f5f9fc;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding-bottom: 13px;
    }

    .ant-tabs-tab-btn {
        color: black;
    }

    & > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
        justify-content: space-around;
        width: 100%;
    }
`;
