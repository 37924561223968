import React from 'react';
import { Col } from 'antd';
import { CustomRow } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import { ReactComponent as Facebook } from '../../../../../../Assets/Shared/Settings/facebook.svg';
import { ReactComponent as Twitter } from '../../../../../../Assets/Shared/Settings/twitter.svg';
import { ReactComponent as Instagram } from '../../../../../../Assets/Shared/Settings/instagram.svg';
import { ReactComponent as Youtube } from '../../../../../../Assets/Shared/Settings/youtube.svg';
import { ReactComponent as LinkedIn } from '../../../../../../Assets/Shared/Settings/linkedIn.svg';

const ContactUs = () => (
    <CustomRow className="main-row">
        <CustomRow>
            <Text weight="semi-bold">Bize Ulaşın</Text>
        </CustomRow>
        <CustomRow className="margin-t-30" align="middle">
            <Text size="14px" weight="light" color="#707070">
                Sipariş Numarası: &nbsp;&nbsp;&nbsp;
            </Text>
            <Text color="green" size="24px" weight="semi-bold">
                +90 850 123 123 23 23
            </Text>
        </CustomRow>
        <CustomRow className="margin-t-20" align="middle">
            <Text size="14px" weight="light" color="#707070">
                Mail: &nbsp;&nbsp;&nbsp;
            </Text>
            <Text color="green" size="24px" weight="semi-bold">
                info@dongu.com
            </Text>
        </CustomRow>
        <CustomRow className="margin-t-30">
            <Text weight="semi-bold">Sosyal Medya Hesapları</Text>
        </CustomRow>
        <CustomRow className="buttons-row buttons-row--social">
            <Col span={2}>
                <a href="https://www.facebook.com/dongu.tr" target="_blank" rel="noreferrer">
                    <Facebook />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://twitter.com/dongutr" target="_blank" rel="noreferrer">
                    <Twitter />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://www.instagram.com/dongu.tr/" target="_blank" rel="noreferrer">
                    <Instagram />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
                    <Youtube />
                </a>
            </Col>
            <Col span={2}>
                <a href="https://www.linkedin.com/company/dongutr/" target="_blank" rel="noreferrer">
                    <LinkedIn />
                </a>
            </Col>
        </CustomRow>
    </CustomRow>
);

export default ContactUs;
