/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { CategoryItemStyles, Header, Content } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import MenuItem from './MenuItem';

const CategoryItem = ({ categoryName, catId, meals }) => {
    const [updated, setUpdated] = useState(0);

    return (
        <CategoryItemStyles>
            <Col span={24}>
                <Header align="middle" justify="space-between">
                    <Col className="title-styles" span={24} lg={{ span: 24 }}>
                        <Text weight="bold" size="regular" family="secondary">
                            {categoryName}
                        </Text>
                    </Col>
                </Header>
                <Content>
                    {meals ? (
                        meals.map(({ id, name, description, tag, price, photo }) => (
                            <Row>
                                {id ? (
                                    <MenuItem photo={photo} price={price} id={id} name={name} description={description} labels={tag} />
                                ) : (
                                    <MenuItem photo={photo} price={price} id={id} name={name} description={description} labels={tag} edit="true" />
                                )}
                            </Row>
                        ))
                    ) : (
                        <div>nodata</div>
                    )}
                </Content>
            </Col>
        </CategoryItemStyles>
    );
};

export default CategoryItem;
