import * as actionTypes from './actionTypes';

const initialState = {
    notificationList: null,
    notificationError: null,
    patchResp: null,
    patchError: null,
};

// GET NOTIFICATIONS
const getNotificationsReducer = (state) => ({
    ...state,
    loading: true,
});

const getNotificationsSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    notificationList: action.payload,
});

const getNotificationsFailReducer = (state, action) => ({
    ...state,
    loading: true,
    notificationError: action.payload,
});

// PATCH NOTIFICATIONS
const patchNotificationsReducer = (state) => ({
    ...state,
    loading: true,
});

const patchNotificationsSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    patchResp: action.payload,
});

const patchNotificationsFailReducer = (state, action) => ({
    ...state,
    loading: true,
    patchError: action.payload,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_NOTIFICATIONS:
            return getNotificationsReducer(state);
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return getNotificationsSuccessReducer(state, action);
        case actionTypes.GET_NOTIFICATIONS_FAIL:
            return getNotificationsFailReducer(state, action);
        case actionTypes.PATCH_NOTIFICATIONS:
            return patchNotificationsReducer(state);
        case actionTypes.PATCH_NOTIFICATIONS_SUCCESS:
            return patchNotificationsSuccessReducer(state, action);
        case actionTypes.PATCH_NOTIFICATIONS_FAIL:
            return patchNotificationsFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
