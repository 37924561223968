import React from 'react';
import { Col } from 'antd';
import { InfoStyles, InfoWrapper, InfoTitle, BigEarthImage } from './styles';
import { Title, Paragraph } from '../../../../components/Typography/styles';
import BigEarth from '../../../../../Assets/Shared/FoodWaste/foodWasteBigEarth.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const InfoSection = () => {
    const { sm } = useMediaQuery();

    return (
        <InfoStyles span={24}>
            <InfoWrapper justify="space-between">
                <InfoTitle span={sm ? 24 : 14}>
                    <Title
                        size="mediumTitle"
                        weight="bold"
                        color="#F38262"
                        style={{ marginTop: sm ? '-20px' : '25px', textAlign: sm ? 'center' : 'initial' }}>
                        Gıda israfı
                    </Title>
                    <Paragraph
                        color="#000"
                        weight="medium"
                        size="description"
                        style={{ textAlign: sm ? 'center' : 'inherit', marginTop: sm ? '0px' : '25px' }}>
                        Gıda israfı; üretilen gıdaların tüketilmeden önce atığa dönüşmesi ile ortaya çıkan bir çevre sorunudur.
                        <br /> <br />
                        Gıdanın işlenmesi, korunması ve satışı gibi, üretimden tüketime uzanan süreçlerin tamamında, gıda israfı
                        gerçekleşebilmektedir.
                        <br /> <br />
                        Küresel ölçekte üretilen toplam gıdanın üçte bire yakınının, israf edilerek atığa dönüştüğü bilinmektedir.
                    </Paragraph>
                </InfoTitle>
                <Col span={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <BigEarthImage style={{ display: sm ? 'none' : 'inherit' }} src={BigEarth} alt="gida-israfi-big-earth" />
                </Col>
            </InfoWrapper>
        </InfoStyles>
    );
};

export default InfoSection;
