import React from 'react';
import { ServicesCostStyles, ServicesCostWrapper, ServicesCostTitle, ServicesImage, ImageWrapper } from './styles';
import { Title, Paragraph } from '../../../../components/Typography/styles';
import ServicesCost from '../../../../../Assets/Shared/AboutUs/servicesCost.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const ServicesCostSection = () => {
    const { sm } = useMediaQuery();
    return (
        <ServicesCostStyles span={24} justify="center">
            <ServicesCostWrapper span={sm ? 24 : 16} style={{ height: !sm && '75%' }}>
                <ServicesCostTitle style={{ paddingTop: sm ? '20%' : '10%', paddingBottom: sm ? '' : '5%', textAlign: sm && 'center' }}>
                    <Title weight="bold" color="#F38262" size="mediumTitle">
                        Döngü Hizmetlerinin Ücreti Ne Kadar?
                    </Title>
                </ServicesCostTitle>
                <Paragraph style={{ textAlign: sm && 'center' }} color="#000" weight="light" size="description">
                    Döngü, işletmelere tamamen ücretsiz bir üyelik sunuyor. <br />
                    <div style={{ paddingBottom: '3%' }} />
                    Döngü’de yalnızca, satın almak (kurtarmak) istediğin gıdalar için ödeme yaparsın. <br />
                    <div style={{ paddingBottom: '3%' }} />
                    Operasyon maliyetlerini karşılamak adına, Döngü, satışlar üzerinden yalnızca küçük bir oranda komisyon alıyor.
                </Paragraph>
            </ServicesCostWrapper>
            <ImageWrapper span={sm ? 24 : 8} style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ServicesImage style={{ width: sm ? '50%' : '100%' }} src={ServicesCost} alt="red-circles" />
            </ImageWrapper>
        </ServicesCostStyles>
    );
};

export default ServicesCostSection;
