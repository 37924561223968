/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { CustomModal, CustomButton, CustomRow, CustomBtn } from './styles';
import { Text } from '../../../../../../components/Typography/styles.js';
import { ReactComponent as PacketOpen } from '../../../../../../../Assets/Shared/NotificationsPage/packet-open.svg';
import { ReactComponent as Arrow } from '../../../../../../../Assets/Shared/NotificationsPage/arrow.svg';
import { ReactComponent as Clock } from '../../../../../../../Assets/Shared/CartPage/clock.svg';
import { getOrder } from '../../../../../../../services/Redux/Shared/order/api';
import { deleteOrder } from '../../../../../../../services/Redux/Shared/profile/api';
import Spinner from '../../../../../../components/Spinner';

const PacketAlarmModel = (props) => {
    const { dismiss } = props;
    const { id, orderDetail } = props;
    const dispatch = useDispatch();
    const selector = useSelector;
    useEffect(() => {
        if (id) {
            dispatch(getOrder(id));
        }
    }, [id]);

    const [hours, setHours] = useState(0);
    const [secs, setSecs] = useState(0);
    const [mins, setMins] = useState(0);
    const storeData = selector((state) => state?.storeStore?.storeData);

    useEffect(() => {
        const interval = setInterval(() => {
            let temp = '';
            if (orderDetail?.delivery_type === '1' && storeData) {
                const today = new Date();
                temp = today.toLocaleDateString('en-CA').concat(' ', storeData?.package_settings?.delivery_time_end);
                temp = temp.concat(':00');
            } else {
                temp = orderDetail?.courier_time?.end_date;
            }
            if (temp) {
                const timeLeft = new Date(temp).getTime() - new Date().getTime();
                const h = Math.floor(timeLeft / 1000 / 60 / 60);
                const m = Math.floor((timeLeft / 1000 / 60 / 60 - h) * 60);
                const s = Math.floor(((timeLeft / 1000 / 60 / 60 - h) * 60 - m) * 60);
                setHours(h);
                setMins(m);
                setSecs(s);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const data = selector((state) => state.customerOrderStore.orderData);

    const onCancel = () => {
        if (data) {
            dispatch(deleteOrder(data.id, data.courier_time.id, data.delivery_type));
        }
        dismiss();
    };

    return (
        <>
            {data ? (
                <CustomModal {...props}>
                    <CustomRow className="title-row" align="middle" justify="center">
                        <Text size="18px" color="red">
                            Sürpriz Paketin açıldı!
                        </Text>
                    </CustomRow>
                    <CustomRow align="middle" justify="center" className="margin-top-10">
                        <Text size="14px">Sipariş Numarası:</Text>
                        &nbsp;&nbsp;
                        <Text size="14px" color="green">
                            {data.ref_code}
                        </Text>
                    </CustomRow>
                    <CustomRow align="middle" justify="center">
                        <PacketOpen />
                    </CustomRow>
                    <CustomRow className="time-row" align="middle" justify="center">
                        <Clock />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Text size="14px" weight="semi-bold">
                            Teslim saatine kalan süre
                        </Text>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Text weight="light" size="18px">
                            {hours < 10 ? <>0{hours}</> : <>{hours}</>}:{mins < 10 ? <>0{mins}</> : <>{mins}</>}:
                            {secs < 10 ? <>0{secs}</> : <>{secs}</>}
                        </Text>
                    </CustomRow>
                    <CustomRow align="middle" justify="center" className="margin-top-25">
                        <Col span={10}>
                            <CustomRow align="middle" justify="center">
                                <Text weight="semi-bold" size="14px">
                                    Sürpriz Paket
                                </Text>
                            </CustomRow>
                        </Col>
                        <Col span={3}>
                            <Arrow />
                        </Col>
                        <Col span={10}>
                            <CustomRow justify="center">
                                {data.boxes[0].meals.map((item, index) => (
                                    <Text color="#707070" weight="light" size="13px">
                                        {item.name}
                                        <>{index < data.boxes[0].meals.length - 1 && <>,&nbsp;</>}</>
                                    </Text>
                                ))}
                            </CustomRow>
                        </Col>
                    </CustomRow>
                    <CustomRow justify="center" className="margin-top-25">
                        <Col>
                            <CustomBtn onClick={() => onCancel()}>
                                <Text size="16px" weight="semi-bold">
                                    Reddet
                                </Text>
                            </CustomBtn>
                        </Col>
                        <Col span={2} />
                        <Col>
                            <CustomButton type="secondary">
                                <Text size="16px" weight="semi-bold" color="white">
                                    Kabul Et
                                </Text>
                            </CustomButton>
                        </Col>
                    </CustomRow>
                </CustomModal>
            ) : (
                <CustomModal {...props}>
                    <Spinner />
                </CustomModal>
            )}
        </>
    );
};

export default PacketAlarmModel;
