/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Col } from 'antd';
import RefCardStyles, { CardTitle, Content, Item, Text, SaveButton, Input } from './styles';

const RefCard = () => {
    const [ref, setRef] = useState();
    return (
        <RefCardStyles title={<CardTitle>Mağaza Referans Numaraları (Opsiyonel)</CardTitle>}>
            <Content>
                <Item span={24}>
                    <Text color="smoke" family="secondary">
                        Mağazanız daha büyük bir zincirin parçasıysa ve raporlama için dahili bir mağaza referansınız varsa, buradan
                        değiştirebilirsiniz. Belirtilirse, bünyemiz tarafından gönderilen zincir raporlarında kullanılacaktır.
                    </Text>
                </Item>
                <Col span={24}>
                    <Input onChange={(event) => setRef(event.target.value)} />
                </Col>
                <Col xxl={{ span: 5, offset: 19 }} xl={{ span: 6, offset: 18 }} lg={{ span: 6, offset: 18 }} md={{ span: 6, offset: 18 }}>
                    <SaveButton type="secondary">
                        <Text color="white" weight="semi-bold">
                            Kaydet
                        </Text>
                    </SaveButton>
                </Col>
            </Content>
        </RefCardStyles>
    );
};

export default RefCard;
