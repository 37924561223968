import React from 'react';
import { AboutStyles, AboutWrapper, AboutTitle } from './styles';
import { Title, Paragraph } from '../../../../components/Typography/styles';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import bg from '../../../../../Assets/Shared/AboutUs/aboutUs.svg';

const AboutSection = () => {
    const { sm } = useMediaQuery();
    return (
        <AboutStyles backgroundImage={sm ? '' : bg}>
            <AboutWrapper style={{ width: sm ? '100%' : '70%' }}>
                <AboutTitle style={{ paddingTop: sm && '25%' }}>
                    <Title style={{ textAlign: sm && 'center' }} weight="bold" color="#F38262" size="mediumTitle">
                        Hakkımızda
                    </Title>
                </AboutTitle>
                <Paragraph style={{ textAlign: sm && 'center' }} color="#000" weight="light" size="description">
                    Önemli bir çevre sorunu olan gıda israfı, küresel kaynakların verimsiz kullanımında önemli bir paya sahip. Her yıl, üretilen
                    gıdaların üçte biri, henüz tüketilmeden çöpe atılıyor. Türkiye’de gıda israfının yılda 26 milyon tona ulaşmış olması, bu çevresel
                    sorunun ciddiyetini ortaya koyar nitelikte. Döngü, işte tam bu noktada, somut bir çözüm sunma arzusu ile yola çıkıyor.
                    <br /> <div style={{ paddingBottom: '3%' }} />
                    Gıda servis sektöründe daha verimli bir işleyiş hedefiyle yola çıkan Döngü’nün, basit bir misyonu var: “Herkes kazansın!” <br />{' '}
                    <div style={{ paddingBottom: '3%' }} />
                    Döngü, arz fazlasına dönüşen veya vaktinde satılamayacağı öngörülen yiyeceklere ikinci bir şans veriyor. Döngü kullanıcıları, tüm
                    kalite standartlarının korunduğu çok çeşitli gıda ürünlerine ve restoran servislerine, Döngü’ye özel fiyatlarla ulaşıyor.
                    Tüketicilerin sevdikleri yiyeceklere indirimli fiyatlarla ulaşabileceği bir pazar yaratırken, işletmelere minimum kayıp ve
                    maksimum kar vadediyor.
                    <br />
                    <div style={{ paddingBottom: '3%' }} /> Döngü, gıda sektöründe yüksek fiyatlardan şikayetçi tüketiciler ile, yüksek verimlilik
                    hedefleyen işletmeleri buluşturan bir ağ! Gıda israfının önüne geçerek, potansiyel kaybını kazanca dönüştürmek istiyorsan, Döngü
                    tam sana göre!
                    <br />
                    <div style={{ paddingBottom: '3%' }} />
                    Gezegeni daha yaşanabilir bir yer haline getirmek için atacağın bu adımla, satışlarını ve verimliliğini artırarak değer katmak
                    istemez misin?
                    <br />
                    <div style={{ paddingBottom: '3%' }} />
                    Daha karlı bir işletme için hemen Döngü’ye katıl ve gıda israfı ile mücadelemizde bize eşlik et!
                </Paragraph>
            </AboutWrapper>
        </AboutStyles>
    );
};

export default AboutSection;
