import styled from 'styled-components';
import { Col, Row } from 'antd';
import Button from '../../../../../../components/Button/index';
import { Text } from '../../../../../../components/Typography/styles';

export default styled.div`
    width: 100%;
    height: 75px;
    border-radius: 0 0 30px 30px;
    background: ${({ theme }) => theme.lightColor};
`;

export const Content = styled(Row)`
    width: 95%;
    border-top: 2px solid ${({ theme }) => theme.borderColor};
    margin-left: auto;
    margin-right: auto;
`;

export const Item = styled(Col)`
    display: flex;
    height: 75px;
    align-items: center;
`;

export const PacketCount = styled(Text)`
    color: ${({ theme }) => theme.modalPrimaryColor} !important;
`;

export const SaveButton = styled(Button)`
    width: 100%;
    font-weight: 500 !important;
`;
