import styled from 'styled-components';
import { Card, Col, Row } from 'antd';
import { between } from 'polished';

export const PaymentInfoNotFound = styled(Card)`
    max-height: 50%;
    width: 100%;

    .ant-card-body {
        padding: 0px;
    }
    .ant-btn-border {
        border-color: ${({ theme }) => theme.secondaryColor} !important;
    }
    .disabled-btn {
        border-color: ${({ theme }) => theme.borderColor} !important;
    }
    /* ${({ theme }) => theme.borderColor} !important */
`;

export const InnerRow = styled(Row)`
    margin-top: 5rem;
    min-height: 100%;
    min-width: 100%;
    display: felx;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const ControlPanelStyles = styled(Row)`
    height: 100%;

    .responsive-margin {
        margin-top: 25px;
    }

    .space-between {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 120px;
    }
`;

export const Content = styled(Col)`
    margin-right: ${between('0px', '8px')}; //between*/
`;

export const RightSide = styled(Col)`
    margin-left: ${between('0px', '8px')}; //between*/
`;
