/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EmptyCard from './EmptyCart';
import ItemCart from './ItemCart/index';
import { SpinnerRow } from './styles';
import { fetchBasket } from '../../../../../services/Redux/Shared/order/api';
import Spinner from '../../../../components/Spinner/index';

const LeftSide = (props) => {
    const dispatch = useDispatch();
    const selector = useSelector;

    const { setCheckout } = props;
    const { updateSummary } = props;
    const { setUpdateSummary } = props;

    useEffect(() => {
        dispatch(fetchBasket());
    }, [dispatch, updateSummary]);

    const basket = selector((state) => state.customerOrderStore.basketList);

    return (
        <Row>
            {basket ? (
                typeof basket === 'string' || basket.length <= 0 ? (
                    <EmptyCard />
                ) : (
                    <ItemCart setCheckout={setCheckout} updateSummary={updateSummary} setUpdateSummary={setUpdateSummary} />
                )
            ) : (
                <SpinnerRow>
                    <Spinner />
                </SpinnerRow>
            )}
        </Row>
    );
};

export default LeftSide;
