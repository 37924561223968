import styled from 'styled-components';
import { Row, Modal } from 'antd';
import Text from 'antd/lib/typography/Text';
import Button from '../../../../../components/Button';

export const CustomButton = styled(Button)`
    width: 100%;
    height: 100%;
`;

export const Description = styled(Text)``;
export const Title = styled(Text)`
    padding-left: 10px;
`;

export const MainWrapper = styled(Row)`
    min-height: 450px;
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    border: 2px solid ${({ theme }) => theme.borderColor};
    border-radius: 10px;
`;
export const Wrapper = styled(Row)`
    min-height: 200px;
    width: 100%;
    margin-top: 32px;
    padding: 15px;
    border: 2px solid ${({ theme }) => theme.borderColor};
    border-radius: 10px;
    margin-bottom: 32px;
`;

export const CustomModal = styled(Modal)`
    min-width: 1400px;
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        display: none;
        height: 30px;
        .ant-btn {
            display: none;
        }
        /* background: ${({ theme }) => theme.borderColor}; */
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
`;
