import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    basketList: [],
    basketError: null,

    addResponse: null,
    addError: null,

    removeResponse: null,
    removeError: null,

    removeAllResponse: null,
    removeAllError: null,

    createOrderResp: null,
    createOrderError: null,

    orderData: null,
    orderError: null,
};

// GET BASKET
const getBasketReducer = (state) => ({
    ...state,
    loading: true,
});

const getBasketSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    basketList: action.payload,
});

const getBasketFailReducer = (state, action) => ({
    ...state,
    loading: true,
    basketError: action.payload,
});

const postBasketAddReducer = (state) => ({
    ...state,
    loading: true,
});

// POST BASKET ADD
const postBasketAddSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    addResponse: action.payload,
});

const postBasketAddFailReducer = (state, action) => ({
    ...state,
    loading: false,
    addError: action.payload,
});

const postBasketRemoveReducer = (state) => ({
    ...state,
    loading: true,
});

// POST BASKET REMOVE
const postBasketRemoveSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    removeResponse: action.payload,
});

const postBasketRemoveFailReducer = (state, action) => ({
    ...state,
    loading: false,
    removeError: action.payload,
});

const postBasketRemoveAllReducer = (state) => ({
    ...state,
    loading: true,
});

// POST BASKET REMOVE ALL
const postBasketRemoveAllSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    removeAllResponse: action.payload,
});

const postBasketRemoveAllFailReducer = (state, action) => ({
    ...state,
    loading: false,
    removeAllError: action.payload,
});

// CREATE ORDER
const createOrderReducer = (state) => ({
    ...state,
    loading: true,
});

const createOrderSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    createOrderResp: action.payload,
});

const createOrderFailReducer = (state, action) => ({
    ...state,
    loading: false,
    createOrderError: action.payload,
});

// GET ORDER
const getOrderReducer = (state) => ({
    ...state,
    loading: true,
});

const getOrderSuccessReducer = (state, action) => ({
    ...state,
    loading: true,
    orderData: action.payload,
});

const getOrderFailReducer = (state, action) => ({
    ...state,
    loading: true,
    orderError: action.payload,
});

const resetAddError = (state) => ({
    ...state,
    loading: false,
    addError: null,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BASKET:
            return getBasketReducer(state);
        case actionTypes.GET_BASKET_SUCCESS:
            return getBasketSuccessReducer(state, action);
        case actionTypes.GET_BASKET_FAIL:
            return getBasketFailReducer(state, action);
        case actionTypes.POST_BASKET_ADD:
            return postBasketAddReducer(state);
        case actionTypes.POST_BASKET_ADD_SUCCESS:
            return postBasketAddSuccessReducer(state, action);
        case actionTypes.POST_BASKET_ADD_FAIL:
            return postBasketAddFailReducer(state, action);
        case actionTypes.POST_BASKET_REMOVE:
            return postBasketRemoveReducer(state);
        case actionTypes.POST_BASKET_REMOVE_SUCCESS:
            return postBasketRemoveSuccessReducer(state, action);
        case actionTypes.POST_BASKET_REMOVE_FAIL:
            return postBasketRemoveFailReducer(state, action);
        case actionTypes.POST_BASKET_REMOVE_ALL:
            return postBasketRemoveAllReducer(state);
        case actionTypes.POST_BASKET_REMOVE_ALL_SUCCESS:
            return postBasketRemoveAllSuccessReducer(state, action);
        case actionTypes.POST_BASKET_REMOVE_ALL_FAIL:
            return postBasketRemoveAllFailReducer(state, action);
        case actionTypes.CREATE_ORDER:
            return createOrderReducer(state);
        case actionTypes.CREATE_ORDER_SUCCESS:
            return createOrderSuccessReducer(state, action);
        case actionTypes.CREATE_ORDER_FAIL:
            return createOrderFailReducer(state, action);
        case actionTypes.GET_ORDER:
            return getOrderReducer(state);
        case actionTypes.GET_ORDER_SUCCESS:
            return getOrderSuccessReducer(state, action);
        case actionTypes.GET_ORDER_FAIL:
            return getOrderFailReducer(state, action);
        case actionTypes.RESET_ADDERROR:
            return resetAddError(state);
        default:
            return state;
    }
};

export default reducer;
