import { Col } from 'antd';
import React from 'react';
import { Text } from '../../../../../../components/Typography/styles';
import { CustomModal, CustomButton, CustomRow } from '../../AddressPane/LocationMap/ConfirmationModal/styles';
import HappyBagIcon from '../../../../../../../Assets/Shared/Profile/decorated-happy-bag.svg';

function ConfirmationModal(props) {
    const { text, dismiss, noIcon, onAccept: acceptHandler, ...rest } = props;
    return (
        <CustomModal {...rest}>
            <CustomRow align="middle" justify="center">
                {!noIcon && <img src={HappyBagIcon} width="140px" alt="icon" />}
            </CustomRow>
            <CustomRow justify="center">
                <Text size="14px" weight="semi-bold" style={{ textAlign: 'center', lineHeight: '24px' }}>
                    {text}
                </Text>
            </CustomRow>
            <CustomRow justify="space-around">
                <Col>
                    <CustomButton type="secondaryReverse" onClick={dismiss}>
                        <Text size="16px" weight="semi-bold" color="green">
                            Hayır
                        </Text>
                    </CustomButton>
                </Col>
                <Col>
                    <CustomButton type="secondary" onClick={acceptHandler}>
                        <Text size="16px" weight="semi-bold" color="white">
                            Evet
                        </Text>
                    </CustomButton>
                </Col>
            </CustomRow>
        </CustomModal>
    );
}

export default ConfirmationModal;
