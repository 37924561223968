import React from 'react';
import { useSelector } from 'react-redux';
import { CustomRow } from './styles';
import NotificationCard from '../NotificationCard';

const AllCard = () => {
    const selector = useSelector;
    const notifications = selector((state) => state.notificationStore.notificationList);

    return (
        <CustomRow className="main-row">
            {notifications.results.map((item) => (
                <NotificationCard content={item} />
            ))}
        </CustomRow>
    );
};

export default AllCard;
