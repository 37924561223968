/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import { Col, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Outside, CustomCol } from './styles';
import BusinessSection from '../LandingPage/BusinessSection/index';
import CartSection from '../Favourites/RightTop/CartSection/Cart';
import FilterSection from '../Favourites/RightTop/FilterSection';
import BannerSection from './BannerSection';
import SearchBar from './SearchBar/index';
import Categories from './CategorySection/Categories';
import DividerStyle from './Divider/index';
import Oppotrunityection from './OpportunitySection';
import MainContent from './MainContentSection';
import RecentlyAdded from './RecentlyAdded';
import OrderStatus from './OrderStatus';
import { searchStore } from '../../../../services/Redux/Shared/store/api';
import { fetchBoxCategoriesNames } from '../../../../services/Redux/Dashboard/boxes/api';
import { Text } from '../../../components/Typography/styles';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import cartIcon from '../../../../Assets/Shared/FindRestaurant/yellowbasket.svg';
import { CustomButton } from '../../../components/Layout/Header/styles';
import { getOrderHistory } from '../../../../services/Redux/Shared/profile/api';

const FindRestaurant = () => {
    const history = useHistory();
    const selector = useSelector;
    const dispatch = useDispatch();
    const [updateCart, setUpdateCart] = useState(false);
    const [query, setQuery] = useState('?');
    const [keyword, setKeyword] = useState('');
    const [price, setPrice] = useState([10, 500]);
    const [kurye, setKurye] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedOneCategory, setSelectedOneCategory] = useState();
    const [searchQuery, setSearchQuery] = useState(0);
    const [sortBy, setSortBy] = useState('Uzaklığa Göre Sırala');
    const [showRightSectionDrawer, setShowRightSectionDrawer] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterCity, setFilterCity] = useState('');
    const [pastOrders, setPastOrders] = useState([]);
    const [activeOrders, setActiveOrders] = useState([]);
    const { xs, sm, lg } = useResponsive();

    const toggleRightSectionDrawer = () => setShowRightSectionDrawer(!showRightSectionDrawer);

    const resetFilter = () => {
        setPrice([10, 500]);
        setKurye(false);
        setSelectedCategories([]);
        setSortBy('Uzaklığa Göre Sırala');
    };

    useEffect(() => {
        dispatch(fetchBoxCategoriesNames());
        dispatch(searchStore(query));
        setQuery(null);
    }, [dispatch, searchQuery]);

    useEffect(() => {
        dispatch(getOrderHistory());
    }, []);

    const orderDetail = selector((state) => state?.profileStore?.orderHistory);

    useEffect(async () => {
        await orderDetail?.map((item) => (['1', '2', '3', '9'].includes(item?.status) ? activeOrders.push(item) : pastOrders.push(item)));
        if (pastOrders) {
            setPastOrders(pastOrders.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time)).slice(0, 7));
        }
        if (activeOrders) {
            setActiveOrders(activeOrders.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time)));
        }
    }, [orderDetail]);

    useEffect(() => {
        if (selectedOneCategory) {
            dispatch(searchStore(`?&cat=${selectedOneCategory.id}`));
        }
    }, [selectedOneCategory]);

    useEffect(() => {
        let search = '?';
        if (price) {
            search = search.concat('minp=', price[0]);
            search = search.concat('&maxp=', price[1]);
        }
        if (kurye) {
            search = search.concat('&ca=', true);
        }
        if (keyword) {
            search = search.concat('&keyword=', keyword);
        }

        if (selectedCategories) {
            selectedCategories.map((item) => {
                search = search.concat('&cat=', item.id);
                return 0;
            });
        }
        setQuery(search);
    }, [keyword, kurye, price, selectedCategories]);

    const temp = selector((state) => state?.storeStore?.searchData);

    const SORTINGS = { DISTANCE: 1, FAVORITES: 2, USER_REVIEW: 3, NEWLY_ADDS: 4 };

    let storeData = null;
    if (temp) {
        storeData = temp[0];
        if (sortBy === SORTINGS.USER_REVIEW) {
            const sorted = storeData.sort((item1, item2) => item2.avg_review - item1.avg_review);
        }
        if (sortBy === SORTINGS.NEWLY_ADDS) {
            storeData = storeData.sort((a, b) => new Date(b.joined_time) - new Date(a.joined_time));
        }
        if (sortBy === SORTINGS.FAVORITES) {
            const currentUser = JSON.parse(localStorage.getItem('user'));
            // eslint-disable-next-line camelcase
            storeData = storeData.filter(({ favorited_by }) => favorited_by.some(({ id: storeId }) => currentUser?.id === storeId));
        }
        if (sortBy === SORTINGS.DISTANCE) {
            storeData = storeData.sort((a, b) => a.distance_from_store - b.distance_from_store);
        }
    }

    return (
        <>
            <Outside gutter={16}>
                <CustomCol className="left-col" span={17}>
                    <Col>
                        <SearchBar
                            toggleRightSectionDrawer={toggleRightSectionDrawer}
                            keyword={keyword}
                            setKeyword={setKeyword}
                            query={query}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            setFilterCity={setFilterCity}
                            storeData={storeData}
                        />
                        {!xs && <BannerSection />}

                        {/* Status Bar */}
                        {!xs && activeOrders && activeOrders.length !== 0 && <OrderStatus order={activeOrders} />}
                    </Col>

                    {/* Categories */}
                    {!xs && <DividerStyle name="Kategoriler" />}
                    <Categories setSelectedCategories={setSelectedOneCategory} />

                    {/* Yakınımdakiler */}
                    {!xs && <DividerStyle name="Yakınımdakiler" />}
                    {storeData?.length ? (
                        <MainContent storeData={storeData} keyword={keyword} selected={selected} filterCity={filterCity} />
                    ) : (
                        <Text color="red">Restoran bulunamadı</Text>
                    )}

                    {/* Fırsatlar */}
                    <DividerStyle name="Fırsatlar" />
                    {storeData?.length ? (
                        <Oppotrunityection storeData={storeData} keyword={keyword} selected={selected} filterCity={filterCity} />
                    ) : (
                        <Text color="red">Restoran bulunamadı</Text>
                    )}

                    {/* Yeni Eklenenler */}
                    {!xs && (
                        <>
                            <DividerStyle name="Yeni Eklenen Restoranlar" />
                            {storeData?.length ? (
                                <RecentlyAdded storeData={storeData} keyword={keyword} selected={selected} filterCity={filterCity} />
                            ) : (
                                <Text color="red">Restoran bulunamadı</Text>
                            )}
                        </>
                    )}
                </CustomCol>

                <CustomCol className="right-col" span={7}>
                    <CartSection updateCart={updateCart} setUpdateCart={setUpdateCart} />
                    <FilterSection
                        kurye={kurye}
                        price={price}
                        selectedCategories={selectedCategories}
                        setPrice={setPrice}
                        setKurye={setKurye}
                        setSelectedCategories={setSelectedCategories}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        resetFilter={resetFilter}
                    />
                </CustomCol>
            </Outside>

            {/* Business Section */}
            <BusinessSection />

            {/* Drawer Section */}
            <Drawer visible={showRightSectionDrawer} placement="right" onClose={toggleRightSectionDrawer}>
                <CartSection updateCart={updateCart} setUpdateCart={setUpdateCart} />
                <FilterSection
                    kurye={kurye}
                    price={price}
                    setPrice={setPrice}
                    setKurye={setKurye}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    resetFilter={resetFilter}
                    toggleRightSectionDrawer={toggleRightSectionDrawer}
                />
            </Drawer>

            {(xs || sm || lg) && (
                <CustomButton onClick={() => history.push('/sepetim')}>
                    <img
                        src={cartIcon}
                        style={{ position: 'fixed', bottom: '3rem', right: '2rem', width: xs ? '15%' : '7.5%', zIndex: 1 }}
                        alt="basket"
                    />
                </CustomButton>
            )}
        </>
    );
};
export default FindRestaurant;
