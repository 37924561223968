import styled from 'styled-components';
import { between } from 'polished';
import { Row, Col, Input as input } from 'antd';
import card from '../../../../../components/Card/index';
import { Text as text } from '../../../../../components/Typography/styles';
import button from '../../../../../components/Button/index';

export default styled(card)`
    min-height: 292px;
    width: 100%;
    max-width: 579px;

    .ant-card-head-title {
        text-align: center;
    }

    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 32px;
        padding-left: 37px;
        padding-right: 38px;
        padding-bottom: 31px;
    }

    img {
        width: 69px;
        height: 48px;
        margin-right: 10px;
    }

    @media only screen and (min-width: 1200px) {
        margin-left: 32px;
    }
`;

export const Content = styled(Row)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Item = styled(Col)`
    display: flex;
    align-items: center;
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Input = styled(input)`
    color: ${({ theme, maxAmount }) => (maxAmount ? theme.inactiveSecondaryColor : theme.quaternaryColor)};
    height: 48px;
    max-width: 101px;
    border-radius: 5px;
    border: ${({ theme }) => `2px solid ${theme.borderColor}`};
    font-size: 17px;
    font-weight: 600;
    font-family: Montserrat;
    text-align: center;

    .ant-input {
        color: ${({ theme, maxAmount }) => (maxAmount ? theme.inactiveSecondaryColor : theme.quaternaryColor)};
        font-size: 17px;
        font-weight: 600;
        font-family: Montserrat;
        text-align: center;
    }
    .ant-input-suffix {
        margin-left: 0px;
    }
`;

export const Text = styled(text)``;

export const SaveButton = styled(button)`
    height: 48px !important;
    width: 100%;
    max-width: 212px;
    font-weight: 600;
`;
