import styled from 'styled-components';
import { Row, Radio } from 'antd';
import Button from '../../../../../../../components/Button';

export const CustomRow = styled(Row)`
    width: 100%;

    &.radio-row {
        margin-top: -4px;
        margin-left: 10px;
    }

    &.button-row {
        margin-top: 25px;
    }
`;

export const CustomRadio = styled(Radio)`
    display: flex;
    margin-top: 20px;
    .ant-radio-inner {
        width: 22px;
        height: 22px;
        border-radius: 5px;

        ::after {
            background-color: #8fd89f;
            height: 14px;
            width: 14px;
        }
    }

    .ant-radio-checked::after {
        border: 1px solid #ffffff;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #d1d0d0;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #d1d0d0;
    }
`;

export const CustomButton = styled(Button)`
    width: 254px;
    height: 48px;
`;
