/* eslint-disable no-unused-vars */
import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { LoginCard, LoginStyles } from './styles';
import { Text } from '../../../components/Typography/styles';
import LoginForm from './LoginForm';

const LoginPage = () => {
    const selector = useSelector;
    const userUser = selector((state) => state.authStore.user);
    const states = selector((state) => state);

    return userUser ? (
        <Redirect to="/dashboard" />
    ) : (
        <LoginStyles justify="center" align="middle">
            <Col xs={20} sm={16} md={16} lg={14} xl={10} xxl={8}>
                <LoginCard type="tertiary">
                    <Row className="vertical-row" align="middle" justify="center">
                        <Text fontSize="extra-big" fontWeight="medium">
                            Welcome back!
                        </Text>
                        <Text fontWeight="medium">Sign in to access your account.</Text>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text fontWeight="medium">Not registered?</Text>
                        <Link>&nbsp;Create an account</Link>
                    </Row>
                    <Row justify="center" align="middle">
                        <LoginForm />
                    </Row>
                </LoginCard>
            </Col>
        </LoginStyles>
    );
};

export default LoginPage;
