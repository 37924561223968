import styled from 'styled-components';
import { between } from 'polished';
import { Col, Row } from 'antd';
import Layout from '../index';
import { HORIZONTAL_PADDING } from '../../../../helpers/constants';

const { Footer } = Layout;

export default styled(Footer)`
    min-height: ${({ theme }) => theme.layoutStyles.footerHeight};
    width: 100%;
    padding: 0px;
    overflow: hidden;
    .height-full {
        height: 100% !important;
    }

    .dispose {
        display: none;
    }
`;

export const FooterTopWrapper = styled(Row)`
    padding-top: 60px;
    padding-bottom: 30px;
    background: ${({ theme }) => theme.contentBackground};
    min-height: 338px;

    padding-left: ${HORIZONTAL_PADDING} !important;
    padding-right: ${HORIZONTAL_PADDING} !important;
    .footer-logo {
        cursor: pointer;
        user-select: none;
        width: 60%;
        @media (max-width: 1199px) {
            width: 50%;
        }
    }
    .appstore-logo {
        cursor: pointer;
        user-select: none;
        width: 100%;
        opacity: 60%;
    }
    .googleplay-logo {
        cursor: pointer;
        user-select: none;

        opacity: 60%;
        width: 100%;
    }
`;

export const FooterBottomWrapper = styled(Row)`
    padding: 20px ${between('0px', '120px')};

    padding-left: ${HORIZONTAL_PADDING} !important;
    padding-right: ${HORIZONTAL_PADDING} !important;
    background: ${({ theme }) => theme.primaryColor};
`;

export const FooterBadgeWrapper = styled.div`
    width: 330px;
    height: 100%;
    padding-bottom: 20px;
    .spacebetween {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    @media (max-width: 1199px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: unset;
        align-items: center;
    }
`;

export const IconWrapperCircle = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px;
    border-radius: 90%;
    width: 46px;
    height: 46px;
    background: ${({ theme }) => theme.primaryColor};
    .icon-group {
        font-size: 24px;
        color: #ffffff;
    }
`;
export const PaymentImg = styled.img`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    width: 360px;
`;

export const FooterNavItemStyles = styled(Col)`
    margin-bottom: 20px;
`;
