/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Input, message, Upload } from 'antd';
import {
    FoodItemStyles,
    Content,
    Footer,
    ContentRight,
    ContentLeft,
    AddLabelBtn,
    FoodnameLabel,
    CustomBtn,
    CustomText,
    CancelButton,
} from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import AddTagModal from './AddTagModal';
import RemoveMealModal from './RemoveMealModal';
import { ReactComponent as DeleteIcon } from '../../../../../../../Assets/Dashboard/delete-btn.svg';
import { ReactComponent as EditIcon } from '../../../../../../../Assets/Dashboard/edit-btn.svg';
import { fetchBoxCategories, putBoxMeal } from '../../../../../../../services/Redux/Dashboard/boxes/api';
import emptyFile from '../../../../../../../Assets/Dashboard/empty-file-img.svg';

const FoodItem = ({ id, catId, name, description, labels, price, photo }) => {
    const dispatch = useDispatch();
    const [isTagModalVisible, setIsTagModalVisible] = useState(false);
    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [itemName, setItemName] = useState(name);
    const [itemPrice, setItemPrice] = useState(price);
    const [itemDescription, setItemDescription] = useState(description);
    const [image, setImage] = useState(photo !== null && photo !== undefined ? photo : emptyFile);
    const [imageFile, setImageFile] = useState();

    useEffect(() => {
        setItemName(name);
        setItemDescription(description);
        setItemPrice(price);
        setImage(photo !== null && photo !== undefined ? photo : emptyFile);
    }, [id, name, description, price, photo]);

    const showTagModal = () => {
        setIsTagModalVisible(true);
    };

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = (info, setter, fileSetter) => {
        getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
        fileSetter(info.file.originFileObj);
    };

    const handleTagCancel = () => {
        setIsTagModalVisible(false);
    };

    const handleRemoveCancel = async () => {
        setIsRemoveModalVisible(false);
    };

    const saveEdit = async () => {
        const formData = new FormData();
        labels.map((element) => formData.append('tag', element.id));
        if (imageFile) {
            formData.append('photo', imageFile);
        }
        formData.append('name', itemName);
        formData.append('price', itemPrice);
        formData.append('description', itemDescription);
        formData.append('category', catId);

        await dispatch(putBoxMeal(formData, id));
        dispatch(fetchBoxCategories(false));
        setIsEdit(false);
    };

    return !isEdit ? (
        <FoodItemStyles span={24}>
            <Content align="top" justify="space-between">
                <ContentLeft xl={4} lg={3} md={4} sm={3}>
                    <Upload showUploadList={false} disabled={!isEdit}>
                        <img src={image} alt="avatar" style={{ width: '100%', cursor: isEdit ? 'pointer' : 'not-allowed' }} />
                    </Upload>
                </ContentLeft>
                <ContentRight xl={19} lg={20} md={20} sm={19}>
                    <Row justify="space-between" align="top">
                        <Col span={10}>
                            <Row>
                                <Text size="non-regular" color="smoke">
                                    {itemName}
                                </Text>
                            </Row>
                            <Row>
                                <Text size="non-regular" color="smoke">
                                    {itemPrice} TL
                                </Text>
                            </Row>
                            <Row>
                                <CustomText line-height="description" size="non-regular" family="secondary" color="smoke" weight="light">
                                    {itemDescription}
                                </CustomText>
                            </Row>
                        </Col>
                        <Col span={12} lg={10}>
                            <Row gutter={[0, 18]}>
                                {labels && labels.length > 0 ? (
                                    labels.map((item) => (
                                        <FoodnameLabel>
                                            <Text size="non-regular" family="secondary" color="smoke" weight="light">
                                                {item.name}
                                            </Text>
                                        </FoodnameLabel>
                                    ))
                                ) : (
                                    <div>nodata</div>
                                )}
                            </Row>
                            <Row align="middle" style={{ marginTop: '20px' }}>
                                <Col>
                                    <FoodnameLabel style={{ cursor: 'pointer' }} onClick={() => setIsRemoveModalVisible(true)}>
                                        <Row center="center">
                                            <DeleteIcon />
                                            <CustomText className="buttons" size="non-regular" family="secondary" weight="bold">
                                                Ürünü Sil
                                            </CustomText>
                                        </Row>
                                    </FoodnameLabel>
                                </Col>
                                <Col>
                                    <FoodnameLabel style={{ cursor: 'pointer' }} onClick={() => setIsEdit(true)}>
                                        <Row justify="center">
                                            <EditIcon />
                                            <CustomText className="buttons" size="non-regular" family="secondary" weight="bold">
                                                Düzenle
                                            </CustomText>
                                        </Row>
                                    </FoodnameLabel>
                                </Col>
                                <Col>
                                    <Text size="non-regular" family="secondary" color="green" weight="light">
                                        Onaylandi
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ContentRight>
            </Content>
            <RemoveMealModal id={id} dismiss={handleRemoveCancel} onCancel={handleRemoveCancel} visible={isRemoveModalVisible} />
        </FoodItemStyles>
    ) : (
        <FoodItemStyles span={24}>
            <Content align="middle" justify="space-between">
                <ContentLeft xl={4} lg={3} md={4} sm={3}>
                    <Upload
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                            handleChange(info, setImage, setImageFile);
                        }}
                        showUploadList={false}>
                        <img src={image} alt="avatar" style={{ width: '100%', cursor: 'pointer' }} />
                    </Upload>
                </ContentLeft>
                <ContentRight xl={19} lg={20} md={20} sm={19}>
                    <Row align="middle" justify="space-between">
                        <Col xl={12} lg={13} md={14} sm={14}>
                            <Input value={itemName} placeholder="name" onChange={(event) => setItemName(event.target.value)} />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={4}>
                            <Input value={itemPrice} placeholder={`${itemPrice} TL`} onChange={(event) => setItemPrice(event.target.value)} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '10px' }}>
                        <Input value={itemDescription} onChange={(event) => setItemDescription(event.target.value)} placeholder="description" />
                    </Row>
                </ContentRight>
            </Content>
            <Footer gutter={[32, 0]}>
                <Col span={14}>
                    <Row align="middle">
                        {labels &&
                            labels.length > 0 &&
                            labels.map((item) => (
                                <FoodnameLabel>
                                    <Text size="regular" family="secondary" color="smoke" weight="light">
                                        {item.name}
                                    </Text>
                                </FoodnameLabel>
                            ))}
                        <AddLabelBtn onClick={() => showTagModal()}>
                            <Text weight="bold" size="noble-regular" color="yellow">
                                +
                            </Text>
                        </AddLabelBtn>
                        {labels && labels.length > 0 ? (
                            <Text family="secondary" size="description" />
                        ) : (
                            <Text family="secondary" size="regular">
                                Etiket seç
                            </Text>
                        )}
                    </Row>
                </Col>
                <Col span={5}>
                    <CancelButton type="secondary" onClick={() => setIsEdit(false)}>
                        <Text weight="semi-bold" color="red">
                            İptal Et
                        </Text>
                    </CancelButton>
                </Col>
                <Col span={5}>
                    <CustomBtn type="secondary" onClick={() => saveEdit()}>
                        <Text weight="semi-bold" color="white">
                            Onaya Gönder
                        </Text>
                    </CustomBtn>
                </Col>
            </Footer>
            <AddTagModal label={labels} dismiss={handleTagCancel} onCancel={handleTagCancel} visible={isTagModalVisible} />
        </FoodItemStyles>
    );
};

export default FoodItem;
