/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable eqeqeq */
import { Col, Row, Progress, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from './SearchBar/index';
import MobileWorkingHours from './Tabs/MobileInformationContent/WorkingHours';
import MobileRestaurantInformation from './Tabs/MobileInformationContent/RestaurantInformation';
import MobileCategories from './Tabs/MobileInformationContent/Categories';
import {
    ImageStyles,
    OutSide,
    RightSide,
    LeftContainer,
    DeliveryIcon,
    LogoStyles,
    StarStyle,
    CustomText,
    AddressRow,
    NameRow,
    DividerStyle,
    BottomContainerStyle,
    BottomTextStyle,
    TimeRow,
    DiscountTextStyle,
    PriceTextStyle,
    BoxCountRow,
    FavRow,
    FavText,
    CategoryRow,
    FavButton,
    FavButtonRow,
    CustomCol,
    CustomHeaderCol,
} from './styles';
import TimeIcon from '../../../../Assets/Shared/RestaurantDetailPage/time.svg';
import Locate from '../../../../Assets/Shared/FindRestaurant/locate.svg';
import PackageGreen from '../../../../Assets/Shared/FindRestaurant/packagegreen.svg';
import PackageWhite from '../../../../Assets/Shared/FindRestaurant/packagewhite.svg';
import MotorWhite from '../../../../Assets/Shared/FindRestaurant/motorwhite.svg';
import MotorGreen from '../../../../Assets/Shared/FindRestaurant/motorgreen.svg';
import StarIcon from '../../../../Assets/Shared/FindRestaurant/star.svg';
import { Text } from '../../../components/Typography/styles';
import { fetchStore, putStoreFavorite, deleteStoreFavorite } from '../../../../services/Redux/Shared/store/api';
import CartSection from '../Favourites/RightTop/CartSection/Cart';
import CategoryName from './CategoryCard';
import TabPane from './Tabs';
import Spinner from '../../../components/Spinner';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import { CustomButton } from '../FindRestaurant/CategorySection/styles';
import cartIcon from '../../../../Assets/Shared/FindRestaurant/yellowbasket.svg';

const RestaurantDetail = () => {
    const dispatch = useDispatch();
    const selector = useSelector;
    const history = useHistory();
    const params = useParams();
    const [like, setLike] = useState(false);
    const [mealPoint, setMealPoint] = useState(0);
    const [servicePoint, setServicePoint] = useState(0);
    const [qualityPoint, setQualityPoint] = useState(0);
    const [updateCart, setUpdateCart] = useState(false);
    const [showRightSectionDrawer, setShowRightSectionDrawer] = useState(false);
    const userData = JSON.parse(localStorage.getItem('user'));
    const { xs, sm, lg } = useResponsive();

    const toggleRightSectionDrawer = () => setShowRightSectionDrawer(!showRightSectionDrawer);

    useEffect(() => {
        dispatch(fetchStore(params.storeId));
    }, [dispatch]);

    const { storeData } = selector((state) => state?.storeStore || {});

    const { categories } = storeData || {};
    const [dataPackage, setDataPackage] = useState('');

    useEffect(() => {
        if (storeData) {
            const today = new Date();
            const todayFormat = today.toISOString();
            setDataPackage(storeData?.calendar?.filter((e) => e?.start_date.substring(0, 10) === todayFormat.substring(0, 10)));
        }
    }, [storeData]);

    useEffect(() => {
        let meal = [];
        let quality = [];
        let service = [];
        if (storeData && storeData.id == params.storeId) {
            if (storeData.review.length >= 5) {
                service = storeData.review.map((item) => item.service_point);
                quality = storeData.review.map((item) => item.quality_point);
                meal = storeData.review.map((item) => item.meal_point);
                const reducer = (accumulator, curr) => accumulator + curr;
                const totalMeal = meal.reduce(reducer);
                const totalService = service.reduce(reducer);
                const totalQuality = service.reduce(reducer);
                setMealPoint(totalMeal / meal.length);
                setServicePoint(totalService / service.length);
                setQualityPoint(totalService / quality.length);
            } else {
                setMealPoint(5);
                setServicePoint(5);
                setQualityPoint(5);
            }
        }

        if (userData && storeData && storeData.id == params.storeId) {
            storeData.favorited_by.map((item) => {
                if (item.id === userData.id) {
                    setLike(true);
                }
                return 0;
            });
        }
    }, [storeData]);

    const likeStore = async (id) => {
        await dispatch(putStoreFavorite(id));
        setLike(true);
    };

    const unLikeStore = async (id) => {
        await dispatch(deleteStoreFavorite(id));
        setLike(false);
    };

    if (storeData && storeData.id == params.storeId && storeData.package_settings) {
        return (
            <>
                <OutSide justify="center">
                    <CustomCol span={17} className="main-col">
                        <SearchBar toggleRightSectionDrawer={toggleRightSectionDrawer} />
                        <LeftContainer>
                            <ImageStyles backgroundImage={storeData.background} style={{ zIndex: 1, position: 'relative' }}>
                                {xs && (
                                    <Col style={{ padding: '1.5rem 0.5rem' }}>
                                        <BoxCountRow>
                                            <Text color="white" weight="semi-bold">
                                                {dataPackage[0]?.box_count} paket
                                            </Text>
                                        </BoxCountRow>
                                    </Col>
                                )}
                                {storeData?.package_settings?.delivery_type === '1' ? (
                                    <DeliveryIcon>
                                        <img src={PackageGreen} alt="package" />
                                        <img src={MotorWhite} alt="motor" />
                                    </DeliveryIcon>
                                ) : (
                                    <DeliveryIcon>
                                        {storeData?.package_settings?.delivery_type === '2' ? (
                                            <img src={PackageWhite} alt="package" />
                                        ) : (
                                            <img src={PackageGreen} alt="package" />
                                        )}
                                        <img src={MotorGreen} alt="motor" />
                                    </DeliveryIcon>
                                )}
                                {xs && (
                                    <CustomCol style={{ position: 'absolute', top: '4.5rem', right: '3.5rem' }}>
                                        {userData && (
                                            <FavRow align="middle" style={{ position: 'absolute', top: 0, left: 0 }}>
                                                <Col>
                                                    {like ? (
                                                        <FavButtonRow
                                                            className="liked"
                                                            align="middle"
                                                            justify="center"
                                                            onClick={() => unLikeStore(storeData?.id)}>
                                                            <FavButton className="fav" onClick={() => setLike(!like)} />
                                                        </FavButtonRow>
                                                    ) : (
                                                        <FavButtonRow
                                                            className="unliked"
                                                            align="middle"
                                                            justify="center"
                                                            onClick={() => likeStore(storeData?.id)}>
                                                            <FavButton className="fav" onClick={() => setLike(!like)} />
                                                        </FavButtonRow>
                                                    )}
                                                </Col>
                                            </FavRow>
                                        )}
                                    </CustomCol>
                                )}
                            </ImageStyles>
                            <Row align="middle">
                                <Col>
                                    <LogoStyles backgroundImage={storeData.photo} style={{ zIndex: 2, position: 'relative' }} />
                                </Col>
                                <CustomHeaderCol span={18}>
                                    {xs ? (
                                        <>
                                            <NameRow align="middle">
                                                <CustomText className="name" size="22px" weight="semi-bold">
                                                    {storeData.name}
                                                </CustomText>
                                            </NameRow>
                                            <AddressRow justify="space-between" align="middle">
                                                <Col xs={8} lg={12}>
                                                    <StarStyle src={StarIcon} alt="star" />
                                                    {storeData.review.length >= 5 ? (
                                                        <CustomText size="22px" weight="light">
                                                            {Number(storeData.avg_review).toFixed(2)}
                                                        </CustomText>
                                                    ) : (
                                                        <CustomText size="22px" weight="light">
                                                            5
                                                        </CustomText>
                                                    )}
                                                </Col>
                                                <Col xs={8} lg={12}>
                                                    <Text color="smoke" weight="light">
                                                        {storeData.province}
                                                    </Text>
                                                </Col>
                                                <Col style={{ marginRight: '3%' }}>
                                                    <img src={Locate} alt="" />
                                                    <Text style={{ marginLeft: 10, color: '#F38262', fontSize: 14, fontWeight: '600' }}>{0} km</Text>
                                                </Col>
                                            </AddressRow>
                                        </>
                                    ) : (
                                        <>
                                            <NameRow align="middle">
                                                <CustomText className="name" size="22px" weight="semi-bold">
                                                    {storeData.name}
                                                </CustomText>
                                                <StarStyle src={StarIcon} alt="star" />
                                                {storeData.review.length >= 5 ? (
                                                    <CustomText size="22px" weight="light">
                                                        {Number(storeData.avg_review).toFixed(2)}
                                                    </CustomText>
                                                ) : (
                                                    <CustomText size="22px" weight="light">
                                                        5
                                                    </CustomText>
                                                )}
                                            </NameRow>
                                            <AddressRow justify="space-between" align="middle">
                                                <Col xs={24} lg={12}>
                                                    <Text color="smoke" weight="light">
                                                        {storeData.address}
                                                    </Text>
                                                </Col>
                                                <Col xs={24} lg={12}>
                                                    <Row justify="center" align="middle" wrap="false">
                                                        <Col span={8}>
                                                            <Text weight="light">Servis</Text> &nbsp;
                                                            <Progress
                                                                type="circle"
                                                                trailColor="#E2E9EF"
                                                                percent={(servicePoint / 5) * 100}
                                                                width={30}
                                                                strokeColor="#9FD5A4"
                                                                strokeWidth="14"
                                                                format={() => `${servicePoint.toFixed(1)}`}
                                                            />
                                                        </Col>
                                                        <Col span={8}>
                                                            <Text weight="light">Kalite</Text> &nbsp;
                                                            <Progress
                                                                type="circle"
                                                                trailColor="#E2E9EF"
                                                                percent={(qualityPoint / 5) * 100}
                                                                width={30}
                                                                strokeColor="#E5876A"
                                                                strokeWidth="14"
                                                                format={() => `${qualityPoint.toFixed(1)}`}
                                                            />
                                                        </Col>
                                                        <Col span={8}>
                                                            <Text weight="light">Lezzet</Text> &nbsp;
                                                            <Progress
                                                                type="circle"
                                                                trailColor="#E2E9EF"
                                                                percent={(mealPoint / 5) * 100}
                                                                width={30}
                                                                strokeColor="#707070"
                                                                strokeWidth="14"
                                                                format={() => `${mealPoint.toFixed(1)}`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </AddressRow>
                                        </>
                                    )}
                                </CustomHeaderCol>
                            </Row>
                            <DividerStyle className="light" />
                            <TimeRow align="middle">
                                <CustomCol className="secondary-col" sm={24} lg={14}>
                                    <Row align="middle" gutter={[20]}>
                                        <Col>
                                            <BottomContainerStyle justify="center" align="middle">
                                                <img src={TimeIcon} alt="clock" />
                                                <BottomTextStyle size="16px" color="yellow" weight="semi-bold">
                                                    {storeData.package_settings.delivery_time_start}-{storeData.package_settings.delivery_time_end}
                                                </BottomTextStyle>
                                            </BottomContainerStyle>
                                        </Col>
                                        <Col style={{ padding: '0.6rem 0.3rem' }}>
                                            <DiscountTextStyle weight="semi-bold" color="#bcbcbc">
                                                {storeData.package_settings.min_order_price}&nbsp; TL
                                            </DiscountTextStyle>
                                        </Col>
                                        <Col style={{ padding: '0.6rem 0.3rem' }}>
                                            <PriceTextStyle justify="center">
                                                <Text color="green" weight="semi-bold">
                                                    {storeData.package_settings.min_discounted_order_price}&nbsp; TL
                                                </Text>
                                            </PriceTextStyle>
                                        </Col>
                                        {!xs && (
                                            <Col style={{ padding: '0.6rem 0' }}>
                                                <BoxCountRow>
                                                    <Text color="white" weight="semi-bold">
                                                        {dataPackage[0]?.box_count} paket
                                                    </Text>
                                                </BoxCountRow>
                                            </Col>
                                        )}
                                    </Row>
                                </CustomCol>
                                {!xs && (
                                    <CustomCol sm={24} lg={9}>
                                        {userData && (
                                            <FavRow align="middle">
                                                <Col style={{ marginRight: '0.5rem' }}>
                                                    <FavText weight="light" color="smoke">
                                                        {like ? 'Restoranı Favorilerimden Çıkar' : 'Restoranı Favorilerime Ekle'}
                                                    </FavText>
                                                </Col>
                                                <Col>
                                                    {like ? (
                                                        <FavButtonRow
                                                            className="liked"
                                                            align="middle"
                                                            justify="center"
                                                            onClick={() => unLikeStore(storeData?.id)}>
                                                            <FavButton className="fav" onClick={() => setLike(!like)} />
                                                        </FavButtonRow>
                                                    ) : (
                                                        <FavButtonRow
                                                            className="unliked"
                                                            align="middle"
                                                            justify="center"
                                                            onClick={() => likeStore(storeData?.id)}>
                                                            <FavButton className="fav" onClick={() => setLike(!like)} />
                                                        </FavButtonRow>
                                                    )}
                                                </Col>
                                            </FavRow>
                                        )}
                                    </CustomCol>
                                )}
                            </TimeRow>
                            <CategoryRow align="middle">
                                <Text weight="semi-bold">Kategoriler</Text>
                                <DividerStyle />
                                <CategoryName categories={categories} />
                            </CategoryRow>
                            <TabPane categories={categories} updateCart={updateCart} setUpdateCart={setUpdateCart} />
                        </LeftContainer>
                    </CustomCol>
                    <RightSide>
                        <CartSection updateCart={updateCart} setUpdateCart={setUpdateCart} />
                    </RightSide>
                </OutSide>
                <Drawer visible={showRightSectionDrawer} placement="right" onClose={toggleRightSectionDrawer}>
                    <CartSection updateCart={updateCart} setUpdateCart={setUpdateCart} />
                </Drawer>

                {(xs || sm || lg) && (
                    <CustomButton onClick={() => history.push('/sepetim')}>
                        <img
                            src={cartIcon}
                            style={{ position: 'fixed', bottom: '3rem', right: '2rem', width: xs ? '15%' : '7.5%', zIndex: 1 }}
                            alt="basket"
                        />
                    </CustomButton>
                )}
            </>
        );
    }

    return (
        <OutSide>
            <LeftContainer>
                <Spinner />
            </LeftContainer>
        </OutSide>
    );
};

export default RestaurantDetail;
