/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { OutSide, LeftContainer, RightContainer, TitleRow, CustomDivider, CustomCol } from './styles';
import { Text } from '../../../components/Typography/styles';
import { createOrder } from '../../../../services/Redux/Shared/order/api';
import RightSide from './RightSide';
import LeftSide from './LeftSide';
import Checkout from './Checkout';
import { BackBtn } from '../Favourites/SearchBar/styles';
import ThreeDModal from './ThreeDModal';

const CartPage = () => {
    const [checkout, setCheckout] = useState(false);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [tabKey, setTabKey] = useState('1');
    const [updateSummary, setUpdateSummary] = useState(false);
    const [complete, setComplete] = useState(false);
    const [packet, setPacket] = useState(true);
    const [cardToken, setCardToken] = useState(null);
    const [cardForm, setCardForm] = useState(null);
    const [newCard, setNewCard] = useState(false);
    const [threeD, setThreeD] = useState(false);
    const [checkedSaveCard, setCheckedSaveCard] = useState(false);
    const [isThreeD, setIsThreeD] = useState(false);
    const [isPaymentDone, setIsPaymentDone] = useState(false);
    const dispatch = useDispatch();
    const [addressInfo, setAddressInfo] = useState(null);
    const history = useHistory();
    const [timeInt, setTimeInt] = useState(0);

    // onHandleThreeD count
    const [count, setCount] = useState(0);
    const scenerioOne = async (delivery) => {
        if (cardToken) {
            const dataToSend = {
                delivery_type: delivery,
                address: addressInfo.adres.id,
                billing_address: addressInfo.adres.id,
                threeD: false,
                cardToken,
                courier_slot_id: timeInt,
            };
            await dispatch(createOrder(dataToSend));
            setComplete(true);
        } else {
            message.error('Kart Seçiniz!');
        }
    };

    const scenerioTwo = async (delivery) => {
        const expYear = cardForm?.expYear.toString().slice(2, 4);

        const data = {
            delivery_type: delivery,
            address: addressInfo.adres.id,
            billing_address: addressInfo.adres.id,
            cardAlias: cardForm?.infoName,
            cardHolderName: cardForm?.cardName,
            cardNumber: cardForm.cardNumber,
            expireMonth: cardForm?.expMonth.toString(),
            expireYear: expYear,
            cvc: cardForm?.cvcCode,
            registerCard: !checkedSaveCard ? 0 : 1,
            courier_slot_id: timeInt,
            threeD,
        };

        if (threeD) {
            const reponseThreeD = await dispatch(createOrder(data));
            if (reponseThreeD) {
                const { errorMessage } = reponseThreeD;

                if (errorMessage !== undefined) {
                    message.error(`${errorMessage}`);
                } else {
                    setIsThreeD(true);
                }
            } else {
                message.error('Bilgilerinizi kontrol ediniz!');
            }
        } else {
            const reponse = await dispatch(createOrder(data));
            if (reponse.payment_status === 'Successful') {
                setComplete(true);
            } else {
                message.error(`${reponse}`);
            }
        }
    };

    const order = async () => {
        let deliveryType = 1;
        if (!packet) deliveryType = 2;

        if (!newCard) {
            scenerioOne(deliveryType);
        } else {
            scenerioTwo(deliveryType);
        }
    };

    const commonProps = {
        timeInt,
        setTimeInt,
        check1,
        check2,
        setCheck1,
        setCheck2,
        tabKey,
        setTabKey,
        complete,
        setComplete,
        packet,
        setPacket,
        addressInfo,
        setAddressInfo,
        order,
        setCardToken,
        cardForm,
        setCardForm,
        newCard,
        setNewCard,
        threeD,
        setThreeD,
        isPaymentDone,
        setIsPaymentDone,
        checkedSaveCard,
        setCheckedSaveCard,
    };

    const onBack = () => {
        if (tabKey === '3') {
            setTabKey('2');
        } else if (tabKey === '2') {
            setTabKey('1');
        } else if (tabKey === '1') {
            setCheckout(false);
            history.goBack();
        }
    };

    const handleThreeD = () => {
        setIsThreeD(false);
        setCount(0);
    };

    return (
        <OutSide justify="center">
            <CustomCol className="left-col" span={17}>
                <LeftContainer>
                    {!complete ? (
                        <TitleRow justify="center" align="middle">
                            <BackBtn onClick={onBack} />
                            <Text size="22px" weight="light" color="smoke">
                                Sepet Detayı
                            </Text>
                            <CustomDivider />
                        </TitleRow>
                    ) : (
                        <TitleRow justify="center" align="middle">
                            <Text size="22px" weight="light" color="smoke">
                                {isPaymentDone ? 'Siparişiniz Alındı' : 'Sipariş Alınıyor...'}
                            </Text>
                            <CustomDivider />
                        </TitleRow>
                    )}
                    {!checkout ? (
                        <LeftSide setCheckout={setCheckout} updateSummary={updateSummary} setUpdateSummary={setUpdateSummary} />
                    ) : (
                        <Checkout {...commonProps} />
                    )}
                </LeftContainer>
            </CustomCol>
            <CustomCol className="right-col" span={7}>
                <RightContainer>
                    <RightSide
                        setCheckout={setCheckout}
                        checkout={checkout}
                        updateSummary={updateSummary}
                        setUpdateSummary={setUpdateSummary}
                        {...commonProps}
                    />
                </RightContainer>
            </CustomCol>
            <ThreeDModal
                onCancel={handleThreeD}
                visible={isThreeD}
                setIsThreeD={setIsThreeD}
                setComplete={setComplete}
                count={count}
                setCount={setCount}
            />
        </OutSide>
    );
};

export default CartPage;
