import * as actionTypes from './actionTypes';

export const createStoreStartAction = () => ({
    type: actionTypes.CREATE_STORE_START,
});

export const createStoreSuccessAction = (_storeData) => ({ type: actionTypes.CREATE_STORE_SUCCESS, payload: _storeData });

export const createStoreFailAction = () => ({
    type: actionTypes.CREATE_STORE_FAIL,
});
