import styled from 'styled-components';
import { Row, Col, Radio } from 'antd';
import { Text } from '../../../../../../../../components/Typography/styles';

export default styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
`;

export const Content = styled(Row)`
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-top: ${({ theme }) => `2px solid ${theme.borderColor}`};
    border-bottom: ${({ theme }) => `2px solid ${theme.borderColor}`};
`;

export const Item = styled(Col)`
    display: flex;
    align-items: center;
    height: 60px;
`;

export const DateContainer = styled(Row)`
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
`;

export const Date = styled(Text)``;

export const RadioButton = styled(Radio)`
    font-size: 16px;
    color: ${({ theme }) => theme.modalPrimaryColor};
    .ant-radio-inner {
        border-radius: 5px;
    }
`;

export const Input = styled.textarea`
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: ${({ theme }) => `1px solid ${theme.borderColor}`};
    border-radius: 5px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 130px;
`;
