import styled from 'styled-components';
import { Row, Col } from 'antd';
import Card from '../../../../components/Card/index';

export default styled(Card)`
    border-left: 16px solid ${({ theme }) => theme.secondaryColor};
    height: 100%;
    @media only screen and (max-width: 768px) {
        min-width: 800px;
    }
`;

export const Content = styled(Row)``;

export const Item = styled(Col)``;
