import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { CategoryItemStyles, Header, Content, FoodnameLabel } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import AddImage from '../../../../../../Assets/Dashboard/add-button.png';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery';
import FoodItem from './FoodItem';
import NewFoodItem from './NewFoodItem';

const CategoryItem = ({ categoryName, catId, meals, isButtonActive, setIsButtonActive }) => {
    const [updated, setUpdated] = useState(0);
    const [newFood, setNewFood] = useState([]);

    const onAddItem = () => {
        const newMeal = {
            id: updated,
            name: '',
            description: '',
            tag: [],
            price: '',
        };
        setNewFood([...newFood, newMeal]);
        setUpdated(updated + 1);
    };

    const cancelNewFoodItem = (id) => {
        if (newFood.length > 0) {
            const filtered = newFood.filter((item) => item.id !== id && item);
            setNewFood(filtered);
        }
        setIsButtonActive(!isButtonActive);
    };

    const { xxl } = useResponsive();

    return (
        <CategoryItemStyles>
            <Col span={24}>
                <Header align="middle" gutter={[32, 0]}>
                    <Col className="title-styles" xxl={19} xl={xxl ? 17 : 16} lg={18} md={18}>
                        <Text weight="bold" family="secondary">
                            {categoryName}
                        </Text>
                    </Col>
                    <Col xxl={5} xl={xxl ? 7 : 8} lg={6} md={6}>
                        {isButtonActive && (
                            <>
                                <FoodnameLabel
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        onAddItem();
                                        setIsButtonActive(!isButtonActive);
                                    }}>
                                    <Row align="middle" justify="center">
                                        <img src={AddImage} alt="" srcSet="" width="30px" height="30px" stlye={{ marginRight: '5px' }} />
                                        <Text family="secondary" weight="semi-bold" style={{ marginTop: '2px' }}>
                                            Ürün Ekle
                                        </Text>
                                    </Row>
                                </FoodnameLabel>
                            </>
                        )}
                    </Col>
                </Header>
                <Content>
                    {meals ? (
                        meals.map(({ id, name, description, tag, price, photo }) => (
                            <Row>
                                <FoodItem catId={catId} photo={photo} price={price} id={id} name={name} description={description} labels={tag} />
                            </Row>
                        ))
                    ) : (
                        <div>nodata</div>
                    )}
                </Content>
                <Content>
                    {newFood ? (
                        newFood.map(({ id, name, description, tag, price, photo }) => (
                            <Row>
                                <NewFoodItem
                                    catId={catId}
                                    photo={photo}
                                    price={price}
                                    id={id}
                                    name={name}
                                    description={description}
                                    labels={tag}
                                    cancelNewFoodItem={cancelNewFoodItem}
                                />
                            </Row>
                        ))
                    ) : (
                        <div>nodata</div>
                    )}
                </Content>
            </Col>
        </CategoryItemStyles>
    );
};

export default CategoryItem;
