import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import Loading from './Loading';
import OrderDetails from './OrderDetails';

const OrderComplete = ({ timeInt, isPaymentDone, setIsPaymentDone }) => {
    const [orderDetail, setOrderDetail] = useState(null);
    const { createOrderResp } = useSelector((state) => state?.customerOrderStore);

    useEffect(() => {
        if (createOrderResp) {
            setOrderDetail(createOrderResp);
        }
    }, [createOrderResp]);

    return (
        <>
            {isPaymentDone ? (
                <OrderDetails timeInt={timeInt} resp={orderDetail} isPaymentDone={isPaymentDone} />
            ) : (
                <Row>
                    <Loading setIsPaymentDone={setIsPaymentDone} setOrderDetail={setOrderDetail} />
                </Row>
            )}
        </>
    );
};

export default OrderComplete;
