import { useState, useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import Countdown from 'react-countdown';
import { Text } from '../../../../components/Typography/styles';
import { Box, CustomText, PriceDisplay, CustomRow, MapBox } from './styles';
import Maps from '../../Favourites/Maps';

const renderer = ({ hours, minutes, seconds, completed }) => {
    let h;
    let m;
    let s;

    if (hours < 10) {
        h = 0;
    }
    if (minutes < 10) {
        m = 0;
    }
    if (seconds < 10) {
        s = 0;
    }
    if (completed) {
        return (
            <Text color="white" size="1rem">
                Tamamlandı
            </Text>
        );
    }
    return (
        <Text color="white" size="1rem">
            {h}
            {hours} : {m}
            {minutes} : {s}
            {seconds}
        </Text>
    );
};

function OrderStatus({ order }) {
    const [toRender, setToRender] = useState(null);
    const [position, setPosition] = useState(null);
    const [storeName, setStoreName] = useState('');
    const [openDate, setOpenDate] = useState('');
    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        const sortedOrders = order?.length > 1 ? order?.sort((a, b) => new Date(b.buying_time) - new Date(a.buying_time)) : order;
        if (sortedOrders) {
            setOpenDate(sortedOrders[0]?.calendar?.start_date || '');
            setStoreName(sortedOrders[0]?.store?.name || '');
            setToRender(sortedOrders[0] || '');
            setPosition(sortedOrders[0]?.store || {});
        } else {
            setToRender(null);
        }
    }, [order]);

    return (
        <>
            {order?.length > 0 && toRender && (
                <Box>
                    <Row style={{ alignItems: 'baseline', marginBottom: '1rem' }}>
                        <Col xs={24} md={8}>
                            <Text color="white" size="0.875rem">
                                Aktif Siparişin:
                            </Text>
                        </Col>
                    </Row>
                    <Row justify="space-between">
                        <Col md={12}>
                            <CustomRow className="centered">
                                <Col xs={24} md={8}>
                                    <Text color="white" weight="semi-bold" size="1rem">
                                        {storeName}
                                    </Text>
                                </Col>

                                <Col xs={24} md={16}>
                                    <CustomText color="white" className="text-ml" size="0.875rem">
                                        {moment(toRender?.buying_time).format('DD MMMM YYYY HH:mm')}
                                    </CustomText>
                                </Col>
                            </CustomRow>
                        </Col>
                        <Col md={4}>
                            {toRender?.delivery_type === '1' ? (
                                <CustomRow className="centered">
                                    <Countdown precision={3} zeroPadTime={3} date={openDate} renderer={renderer} />
                                </CustomRow>
                            ) : (
                                <CustomRow className="centered">
                                    <Countdown precision={3} zeroPadTime={3} date={toRender?.courier_time?.end_date} renderer={renderer} />
                                </CustomRow>
                            )}
                        </Col>
                        <Col md={3}>
                            <CustomRow className="centered">
                                <PriceDisplay className="centered">
                                    <Text color="green" weight="bold" size="1rem">
                                        {toRender?.cost} TL
                                    </Text>
                                </PriceDisplay>
                            </CustomRow>
                        </Col>
                        <Col md={5}>
                            <CustomRow className="centered">
                                <Button onClick={() => setShowMap(!showMap)} size="large" style={{ padding: '5px 15px' }}>
                                    <Text color="green" weight="bold" size="1rem">
                                        {showMap ? 'Haritayı Gizle' : 'Haritayı Göster'}
                                    </Text>
                                </Button>
                            </CustomRow>
                        </Col>
                    </Row>
                </Box>
            )}
            <MapBox>{showMap && <Maps storeData="" user={position} />}</MapBox>
        </>
    );
}

export default OrderStatus;
