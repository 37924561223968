/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */

import React, { useEffect, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import Geocode from 'react-geocode';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { CustomRow, CustomMap, CustomMarker, CustomButtom, Location, Container } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import MarkerIcon from '../../../../../../../Assets/Shared/Profile/mapMarker.svg';
import ConfirmationModal from './ConfirmationModal';
import { ReactComponent as CloseBtn } from '../../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../Assets/Shared/Profile/search.svg';

// eslint-disable-next-line sonarjs/cognitive-complexity
const LocationMap = (props) => {
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [homeCoords, setHomeCoords] = useState(null);
    const [address, setAddress] = useState('');
    const { setLat } = props;
    const { setLng } = props;
    const { setForm } = props;
    const { setNewInfo } = props;

    const handleChange = (input) => {
        setAddress(input);
    };

    const handleSelect = (input) => {
        geocodeByAddress(input)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                setLatitude(latLng.lat);
                setLongitude(latLng.lng);
            })
            .catch((error) => console.error('Error', error));
    };

    const findLocation = () => {
        if (latitude === 0 && longitude === 0) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                const coords = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setHomeCoords(coords);
            });
        } else {
            setLatitude(homeCoords.lat);
            setLongitude(homeCoords.lng);
        }
    };

    useEffect(() => {
        findLocation();
    }, []);

    Geocode.setApiKey('AIzaSyDlcgkT0cnXnxEg36EVpWzRv2ZZTPiXmzs');
    Geocode.setLanguage('en');
    Geocode.setRegion('tr');
    Geocode.setLocationType('ROOFTOP');
    Geocode.enableDebug();

    const { google } = props;
    const newMarkerPosition = (event, index) => {
        setLatitude(event.latLng.lat());
        setLongitude(event.latLng.lng());
    };

    const [modal, setModal] = useState(false);

    const openModal = () => {
        setLng(longitude);
        setLat(latitude);

        Geocode.fromLatLng(latitude, longitude).then(
            (response) => {
                const written = response.results[0].formatted_address;
                setNewInfo(response.results[0]);
            },
            (error) => {
                console.error(error);
            }
        );
        setModal(true);
    };

    const dismiss = () => {
        setModal(false);
    };
    return (
        <CustomRow className="main-row">
            <CustomRow justify="center" className="title-row">
                <Text weight="light" size="22px" color="#707070">
                    Yeni Adres Ekle
                </Text>
            </CustomRow>
            <CustomRow className="map-row">
                {latitude && longitude ? (
                    <CustomMap
                        fullscreenControl={false}
                        google={google}
                        zoom={17}
                        className="map"
                        zoomControl={false}
                        mapTypeControl={false}
                        streetViewControl={false}
                        initialCenter={{ lat: latitude, lng: longitude }}
                        center={{ lat: latitude, lng: longitude }}>
                        <CustomMarker
                            draggable
                            onDragend={(t, map, coord) => newMarkerPosition(coord, map)}
                            position={{ lat: latitude, lng: longitude }}
                            icon={{ url: MarkerIcon }}
                        />
                        <Location onClick={() => findLocation()} />
                    </CustomMap>
                ) : (
                    <></>
                )}

                <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <Container className="map">
                            <input
                                {...getInputProps({
                                    placeholder: 'Mahalle, cadde, sokak ara',
                                    className: 'location-search-input',
                                    style: {
                                        height: 48,
                                        border: '2px solid #f2f2fc',
                                        paddingLeft: 40,
                                        marginTop: 20,
                                        width: '100%',
                                    },
                                })}
                            />
                            {suggestions?.length ? (
                                <div
                                    className="autocomplete-dropdown-container"
                                    style={{
                                        padding: '10px 0px',
                                        borderRadius: 10,
                                        border: '2px solid #f2f2fc',
                                        backgroundColor: 'white',
                                        zIndex: 11,
                                        position: 'relative',
                                    }}>
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style: {
                                                        padding: '2px 12px',
                                                        zIndex: 11,
                                                        position: 'relative',
                                                    },
                                                })}>
                                                <Text size="16px" weight="light">
                                                    {suggestion.description}
                                                </Text>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </Container>
                    )}
                </PlacesAutocomplete>
                <CustomRow align="middle" justify="center" className="search-icon">
                    <SearchIcon placeholder="Mahalle, cadde, sokak ara" />
                </CustomRow>
            </CustomRow>
            <CustomRow justify="center" align="middle" className="button-row">
                <CustomButtom type="secondary" onClick={openModal}>
                    <Text weight="semi-bold" color="white">
                        Bu Adresi Kullan
                    </Text>
                </CustomButtom>
            </CustomRow>
            <ConfirmationModal setForm={setForm} dismiss={dismiss} width={390} closeIcon={<CloseBtn />} onCancel={dismiss} visible={modal} />
        </CustomRow>
    );
};
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDlcgkT0cnXnxEg36EVpWzRv2ZZTPiXmzs',
})(LocationMap);
