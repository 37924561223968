import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { CustomModal, CustomButton } from './styles';
import { Text } from '../../../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const AlertModal = (props) => {
    const [isSold, setIsSold] = useState(false);
    const { selectedPacket, onCancel, cancelPacket } = props;

    useEffect(() => {
        if (selectedPacket) {
            setIsSold(selectedPacket.sold);
        }
    }, [selectedPacket]);

    return (
        <>
            <CustomModal title={<Title text="Onayla" />} {...props}>
                <Row gutter={[0, 24]} justify="center" align="middle">
                    <Col span={20} style={{ display: 'flex' }}>
                        <Text weight="light" style={{ textAlign: 'center' }}>
                            Seçilen paketi silmek istediğinizden emin misiniz?
                        </Text>
                    </Col>
                </Row>
                {isSold && (
                    <Row gutter={[0, 24]} justify="center" align="middle">
                        <Col span={20} style={{ display: 'flex' }}>
                            <Text weight="light" style={{ textAlign: 'center', color: 'red' }}>
                                Satın alınmış bir paketi silmek istediğinizden emin misiniz?
                            </Text>
                        </Col>
                    </Row>
                )}
                <Row justify="center" style={{ marginTop: '32px' }}>
                    <Col span={12}>
                        <CustomButton type="quaternary" onClick={() => onCancel()}>
                            <Text weight="light">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col>
                        <CustomButton type="secondary" onClick={() => cancelPacket(selectedPacket.id)}>
                            <Text color="white" weight="light">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Row>
            </CustomModal>
        </>
    );
};

export default AlertModal;
