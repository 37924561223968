import styled from 'styled-components';
import { Col, Row } from 'antd';
import Button from '../../../../../../components/Button';
import { Text } from '../../../../../../components/Typography/styles';

export const FoodItemStyles = styled(Col)`
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`;
export const Content = styled(Row)`
    .avatar-uploader > .ant-upload {
        width: 84px;
        height: 84px;
    }
`;

export const TagRow = styled(Row)`
    margin-top: 10px;
`;

export const ContentLeft = styled(Col)``;
export const ContentRight = styled(Col)``;

export const AddLabelBtn = styled(Button)`
    border: 2px solid ${({ theme }) => theme.borderColor};
    padding: 0px 0px;
    border-radius: 3px;
    height: 30px !important;
    width: 26px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 9px;

    :hover {
        border-color: ${({ theme }) => theme.quaternaryColor};
    }
`;
export const FoodnameLabel = styled.div`
    padding: 5px 8px;
    border: 2px solid ${({ theme }) => theme.borderColor};
    margin-right: 4px;
    margin-bottom: 1px;
    border-radius: 3px;
`;

export const CustomText = styled(Text)`
    &.buttons {
        margin-left: 3px;
    }

    &.tag-text {
        @media (min-width: 1200px) {
            font-size: 11px;
        }
    }

    &.name-size {
        @media (min-width: 1200px) {
            font-size: 13px;
        }
    }

    &.price-size {
        @media (min-width: 1200px) {
            font-size: 12px;
        }
    }
`;
