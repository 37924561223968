import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';
import { HORIZONTAL_PADDING } from '../../../../../helpers/constants';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const CreateForBusinessStyles = styled(Col)`
    padding-top: ${between('120px', '160px', '320px', '1920px')};
    padding-bottom: ${between('20px', '40px', '320px', '1920px')};
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    box-sizing: border-box;
    width: 100vw;
    min-height: 100vh;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: 100%;
    background-position: 0% 100%;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-size: 90%;
        background-repeat: repeat;
    }
    background-color: ${({ backgroundColor }) => {
        if (backgroundColor) {
            return backgroundColor;
        }
    }};
`;
export const BackgroundWrapper = styled.div`
    min-height: 100vh;
    position: absolute;
    width: 100vw;
    z-index: -2;
`;
export const ContentWrapper = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;
`;

export const LeftWrapper = styled(Col)``;

export const RightWrapper = styled(Col)`
    justify-content: flex-end;
    align-items: flex-end;
`;

export const Image = styled.img`
    position: absolute;
    left: 27%;
    bottom: 12%;
    z-index: 5;
`;

export const FormContainer = styled(Row)`
    background-color: #fff;
    border-radius: 18px;
    margin-top: 15px;

    width: ${between('200px', '350px')};
    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 576px) {
        width: 100%;
    }
`;
export const FormHeader = styled(Row)`
    border-bottom: solid 4px #f2f2fc;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 75px;
    width: ${between('200px', '350px')};
    @media (max-width: 768px) {
        width: 100%;
    }

    height: 75px;
`;
export const FormContent = styled(Row)``;
