import styled from 'styled-components';
import { Col, Row } from 'antd';
import Card from '../../../../components/Card';

export const PacketInfoCardStyles = styled(Card)`
    min-height: 540px;
    .ant-card-body {
        padding: 0px;
    }
`;
export const Content = styled(Col)`
    padding: 12px 24px;
    padding-top: 0px;
`;

export const PacketSection = styled(Row)`
    padding-top: 24px;
`;

export const UpperContent = styled(Row)`
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
`;

export const LeftSide = styled(Col)`
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    padding-right: 15px;
`;

export const RightSide = styled(Col)`
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     */
    padding-left: 15px;
    border-left: 2px solid ${({ theme }) => theme.borderColor};
`;
