import React from 'react';
import CalendarPageStyles, { Item, Content, CalenderItem } from './styles';
import Alert from './AlertCard/index';
import Notification from './NotificationCard/index';
import Calendar from './Calendar/index';

const CalendarPage = () => (
    <CalendarPageStyles justify="start">
        <Content
            gutter={[
                { xs: 0, md: 0, xl: 32 },
                { xs: 8, sm: 8, md: 12, lg: 16, xl: 32 },
            ]}>
            <Item md={24} xl={16}>
                <Notification />
            </Item>
            <Item sm={24} md={24} xl={6}>
                <Alert />
            </Item>
        </Content>
        <Content>
            <CalenderItem sm={24} md={24} xl={22} xxl={22}>
                <Calendar />
            </CalenderItem>
        </Content>
    </CalendarPageStyles>
);

export default CalendarPage;
