/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import ProviderStyles, { Text, Img, Item } from './styles';
import Email from '../../../../../../Assets/Shared/common/email.svg';
import Apple from '../../../../../../Assets/Shared/common/apple.svg';
import Google from '../../../../../../Assets/Shared/common/google.svg';
import Facebook from '../../../../../../Assets/Shared/common/facebook.svg';
import { tryAuthFacebook, tryAuthGoogle } from '../../../../../../services/Redux/auth/api';

const Provider = ({ provider, onClick }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleResponse = (response) => {
        // console.log(provider, 'response: ', response);
        if (provider === 'google') {
            dispatch(tryAuthGoogle(response.tokenId));
            history.push('/restoran-bul');
        }
        if (provider === 'facebook') {
            dispatch(tryAuthFacebook(response.accessToken));
            history.push('/restoran-bul');
        }
    };

    let providerImage = null;
    let text = null;
    switch (provider) {
        case 'email':
            providerImage = Email;
            text = 'E-mail ile devam et';
            break;
        case 'apple':
            providerImage = Apple;
            text = 'Apple ile devam et';
            break;
        case 'google':
            providerImage = Google;
            text = 'Google ile devam et';
            break;
        default:
            providerImage = Facebook;
            text = 'Facebook ile devam et';
            break;
    }

    const ProviderComponent = (renderProps) => (
        <ProviderStyles onClick={renderProps.onClick}>
            <Img src={providerImage} alt="provider" />
            <Item span={20}>
                <Text size="14px" weigh="light" color="#707070">
                    {text}
                </Text>
            </Item>
        </ProviderStyles>
    );

    // Previous clientId 955174467880-fkvplmsj22h287dken516bt5sidd2fkj.apps.googleusercontent.com
    // New clientId 163234070864-afiphh53mul3il8gq98drsldoogp8v7i.apps.googleusercontent.com
    // This is from Kadir google cloud platform and this takes effect on the below "GoogleLogin" area

    if (provider === 'google')
        return (
            <GoogleLogin
                clientId="163234070864-afiphh53mul3il8gq98drsldoogp8v7i.apps.googleusercontent.com"
                buttonText="Login"
                render={ProviderComponent}
                onSuccess={handleResponse}
                onFailure={(e) => console.log('Google Provider Error: ', e)}
                cookiePolicy="single_host_origin"
            />
        );

    if (provider === 'facebook')
        return (
            <FacebookLogin
                appId="1062589017874599"
                fields="first_name,last_name,email"
                scope="email"
                autoLoad={false}
                callback={handleResponse}
                render={ProviderComponent}
            />
        );
};

export default Provider;
