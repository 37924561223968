import { Modal, Row, Input, Form, Button as Btn } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../../components/Button';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        display: none;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
`;

export const DropdownButton = styled(Btn)`
    width: 100%;
    justify-content: space-between;
    height: 48px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;
`;

export const CustomRow = styled(Row)`
    width: 100%;

    &.name-input-row {
        margin-top: 15px;
    }

    &.number-input-row {
        margin-top: 26px;
    }

    &.bottom-row {
        margin-bottom: 30px;
    }

    &.button-row {
        margin-top: 20px;
    }
`;

export const CustomInput = styled(Input)`
    width: 100%;
    height: 48px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px 20px;
`;

export const CustomButton = styled(Button)`
    width: 250px;
    height: 48px;
`;

export const FormItem = styled(Form.Item)`
    width: 100%;
    margin-bottom: 0px;
`;
