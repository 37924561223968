import styled from 'styled-components';
import { Input } from 'antd';

export const SearchInput = styled(Input)`
    padding: 8px 11px;
    border: 2px solid ${({ theme }) => theme.borderColor};
    input.ant-input {
        padding-left: 15px;
    }
`;
