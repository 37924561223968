import styled from 'styled-components';
import { Col, Row } from 'antd';
import Button from '../../../../../components/Button';

export const FavouritesStyles = styled(Row)``;
export const Wrapper = styled(Col)`
    padding-top: 5px;
    padding-bottom: 5px;
`;
export const Header = styled(Row)`
    padding-bottom: 10px;
`;
export const Content = styled(Row)``;

export const AddFoodBtn = styled(Button)`
    border: 2px solid ${({ theme }) => theme.borderColor};
    height: 48px !important;
    width: 40px !important;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :hover {
        border-color: ${({ theme }) => theme.quaternaryColor};
    }
`;
