/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../components/Typography/styles';
import GeneralSettings from './GeneralSettings';
import LocalitySettings from './LocalitySettings';
import Application from './Applications';
import UserAgreement from './UserAgreement';
import ContactUs from './ContactUs';
import { TabArrow } from '../../ProfilePage/Content/styles';

const Content = () => {
    const [selected, setSelected] = useState(null);
    const tabChange = (id) => {
        const temp = document.getElementsByClassName('tab-btn-row');
        if (selected === null) {
            temp[id].style.backgroundColor = '#f2f2fc';
            setSelected(id);
        } else {
            temp[id].style.backgroundColor = '#f2f2fc';
            temp[selected].style.backgroundColor = '';
            setSelected(id);
        }
    };
    useEffect(() => {
        if (selected === null) {
            tabChange(0);
        }
    });

    return (
        <CustomRow>
            <CustomCol span={6}>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(0)}>
                    <Text weight="light">Genel Ayarlar</Text>
                    <TabArrow />
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(1)}>
                    <Text weight="light">Dil Ayarları / Bölge Değiştir</Text>
                    <TabArrow />
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(2)}>
                    <Text weight="light">Uygulama Hakkında</Text>
                    <TabArrow />
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(3)}>
                    <Text weight="light">Kullanıcı Sözleşmeleri</Text>
                    <TabArrow />
                </CustomRow>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between" onClick={() => tabChange(4)}>
                    <Text weight="light">İletişim</Text>
                    <TabArrow />
                </CustomRow>
            </CustomCol>
            <CustomCol span={18}>
                <CustomRow className="content-row">
                    {selected === 0 ? (
                        <GeneralSettings />
                    ) : selected === 1 ? (
                        <LocalitySettings />
                    ) : selected === 2 ? (
                        <Application />
                    ) : selected === 3 ? (
                        <UserAgreement />
                    ) : (
                        <ContactUs />
                    )}
                </CustomRow>
            </CustomCol>
        </CustomRow>
    );
};

export default Content;
