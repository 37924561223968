import styled from 'styled-components';
import { Row, Divider } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;
`;

export const CustomDivider = styled(Divider)`
    border: 1px solid #f2f2fc;
`;
