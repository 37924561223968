// GET STORES

export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAIL = 'GET_STORES_FAIL';

// GET STORE

export const GET_STORE = 'GET_STORE';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAIL = 'GET_STORE_FAIL';

// GET STORE

export const SEARCH_STORE = 'SEARCH_STORE';
export const SEARCH_STORE_SUCCESS = 'SEARCH_STORE_SUCCESS';
export const SEARCH_STORE_FAIL = 'SEARCH_STORE_FAIL';

// GET STORE BOXES

export const GET_STORE_BOXES = 'GET_STORE_BOXES';
export const GET_STORE_BOXES_SUCCESS = 'GET_STORE_BOXES_SUCCESS';
export const GET_STORE_BOXES_FAIL = 'GET_STORE_BOXES_FAIL';

// GET STORE FAV

export const GET_STORE_FAV = 'GET_STORE_FAV';
export const GET_STORE_FAV_SUCCESS = 'GET_STORE_FAV_SUCCESS';
export const GET_STORE_FAV_FAIL = 'GET_STORE_FAV_FAIL';

// PUT STORE FAV

export const PUT_STORE_FAV = 'PUT_STORE_FAV';
export const PUT_STORE_FAV_SUCCESS = 'PUT_STORE_FAV_SUCCESS';
export const PUT_STORE_FAV_FAIL = 'PUT_STORE_FAV_FAIL';

// DELETE STORE FAV

export const DELETE_STORE_FAV = 'DELETE_STORE_FAV';
export const DELETE_STORE_FAV_SUCCESS = 'DELETE_STORE_FAV_SUCCESS';
export const DELETE_STORE_FAV_FAIL = 'DELETE_STORE_FAV_FAIL';

// GET STORE TIME

export const GET_STORE_TIME = 'GET_STORE_TIME';
export const GET_STORE_TIME_SUCCESS = 'GET_STORE_TIME_SUCCESS';
export const GET_STORE_TIME_FAIL = 'GET_STORE_TIME_FAIL';

// PATCH STORE TIME

export const PATCH_STORE_TIME = 'PATCH_STORE_TIME';
export const PATCH_STORE_TIME_SUCCESS = 'PATCH_STORE_TIME_SUCCESS';
export const PATCH_STORE_TIME_FAIL = 'PATCH_STORE_TIME_FAIL';

// POST STORE REVIEW

export const POST_STORE_REVIEW = 'POST_STORE_REVIEW';
export const POST_STORE_REVIEW_SUCCESS = 'POST_STORE_REVIEW_SUCCESS';
export const POST_STORE_REVIEW_FAIL = 'POST_STORE_REVIEW_FAIL';
