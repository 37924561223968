/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import TimerCard from './TimerCard';
import { PaymentInfoNotFound, ControlPanelStyles, Content, RightSide, InnerRow } from './styles';
import { Text } from '../../../components/Typography/styles';
import PacketInfoCard from './PacketInfoCard';
import PacketCountCard from './PacketCountCard';
import OrdersCard from './OrdersCard';
import AlertCard from './AlertCard';
import OrderStatusCard from './OrderStatusCard';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import { fetchBoxes } from '../../../../services/Redux/Dashboard/boxes/api';
import { getMonthlyOrders } from '../../../../services/Redux/Dashboard/CalendarPage/api';
import { getStoreInfo } from '../../../../services/Redux/Dashboard/StorePage/api';
import { getPaymentInfo } from '../../../../services/Redux/Dashboard/payment/api';
import Onemli from '../../../../Assets/Shared/CartPage/onemli.png';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const ControlPanel = () => {
    const { xs, sm, md } = useResponsive();
    const selector = useSelector;
    const dispatch = useDispatch();
    const [boxDataToRender, setBoxDataToRender] = useState();
    const format = 'YYYY-MM-DD';
    const [startDate, setStartDate] = useState('');
    const [paymentInfo, setPaymentInfo] = useState(true);

    useEffect(() => {
        (async () => {
            dispatch(getPaymentInfo());
        })();
        dispatch(fetchBoxes());
        dispatch(getMonthlyOrders());
        dispatch(getStoreInfo());
    }, [dispatch]);

    // !!! Every 10 seconds, we trigger the function repeteadly. But this can be changed.
    useEffect(() => {
        setInterval(() => dispatch(fetchBoxes()), 10000);
    }, []);

    const { boxList } = selector((state) => state?.boxStore);
    const { monthlyOrders } = selector((state) => state?.calendarPage);
    const { id } = selector((state) => state?.storePage);
    const { taxOfficeInfo } = useSelector((state) => state?.paymentStore);

    useEffect(() => {
        setBoxDataToRender(boxList);

        if (!monthlyOrders) {
            setStartDate('');
        } else {
            const date = monthlyOrders.find(
                (orders) => moment(orders.start_date).isSameOrAfter(moment().format(format), 'date') && orders.is_active === true
            );
            setStartDate(date.start_date);
        }
        return () => {
            setStartDate('');
        };
    }, [boxList, monthlyOrders]);

    useEffect(() => {
        if (taxOfficeInfo) setPaymentInfo(taxOfficeInfo[0]);
    }, [taxOfficeInfo]);

    return (
        <ControlPanelStyles justify="start">
            {paymentInfo ? (
                <>
                    <Content xs={24} sm={24} md={24} lg={24} xl={15} xxl={15}>
                        <PacketInfoCard boxList={boxDataToRender} startDate={startDate} />
                        <OrdersCard storeId={id} />
                    </Content>
                    <RightSide className={(xs || sm || md) && 'responsive-margin'} xs={24} sm={24} md={24} lg={24} xl={7} xxl={6}>
                        <PacketCountCard boxList={boxDataToRender} storeId={id} startDate={startDate} />
                        <TimerCard startDate={moment(startDate).subtract(2, 'hours')} />
                        <AlertCard startDate={startDate} />
                        <OrderStatusCard />
                    </RightSide>
                </>
            ) : (
                <PaymentInfoNotFound title={<Title text="Paket Sayısı" />}>
                    <InnerRow>
                        <img src={Onemli} alt="sup" />
                        <Col style={{ marginLeft: '2rem' }}>
                            <Text size="title" weight="semibold">
                                Öncelikle <Link to="/dashboard/orders"> Mağazanız → Satışlarınız</Link> adresinden ödeme bilgilerinizi eklemeniz
                                gerekmektedir.
                            </Text>
                        </Col>
                    </InnerRow>
                </PaymentInfoNotFound>
            )}
        </ControlPanelStyles>
    );
};

export default ControlPanel;
