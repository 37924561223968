import * as actionTypes from './actionTypes';

export const getMonthlyOrdersAction = () => ({
    type: actionTypes.GET_MONTHLY_ORDERS,
});

export const getMonthlyOrdersSuccessAction = (orders) => ({
    type: actionTypes.GET_MONTHLY_ORDERS_SUCCESS,
    payload: orders,
});

export const getMonthlyOrdersFailAction = () => ({
    type: actionTypes.GET_MONTHLY_ORDERS_FAIL,
});

export const updateDailyPlanAction = (data) => ({
    type: actionTypes.UPDATE_DAILY_PLAN,
    payload: data,
});

export const updateDailyPlanSuccessAction = () => ({
    type: actionTypes.UPDATE_DAILY_PLAN_SUCCESS,
});

export const updateDailyPlanFailAction = () => ({
    type: actionTypes.UPDATE_DAILY_PLAN_FAIL,
});

export const setSelectedDateOrders = (orders) => ({
    type: actionTypes.SET_SELECTED_DATE_ORDERS,
    payload: orders,
});

export const getAlertCardText = (text) => ({
    type: actionTypes.GET_ALERT_TEXT,
    payload: text,
});

export const getClosestSaleDate = (date) => ({
    type: actionTypes.GET_CLOSEST_SALE_DATE,
    payload: date,
});
