import styled from 'styled-components';
import { Col, Row } from 'antd';
import Card from '../../../../components/Card/index';

import { Text as text, Title as title } from '../../../../components/Typography/styles';

export const CardStyles = styled(Card)`
    height: 100%;
    width: 100%;

    @media only screen and (min-width: 1200px) {
        width: 116%;
    }
    @media only screen and (min-width: 1600px) {
        width: 110%;
    }
    @media only screen and (min-width: 1900px) {
        width: 108%;
    }
    @media only screen and (min-width: 2300px) {
        width: 106%;
    }
    @media only screen and (max-width: 768px) {
        min-width: 800px;
    }
`;

export const Wrapper = styled(Row)`
    width: 100%;
`;
export const Content = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Text = styled(text)``;

export const Title = styled(title)`
    margin-top: 32px;
`;
