import { Row, Select } from 'antd';
import styled from 'styled-components';
import { ReactComponent as BackButton } from '../../../../../Assets/Shared/CartPage/back-btn.svg';

export const SelectList = styled(Select)`
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        border: none !important;
        height: 50px !important;
        transition: all 0.3s !important;
        padding: 0 !important;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
        line-height: 50px !important;
    }
`;

export const Wrapper = styled(Row)`
    background-color: #fff;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    justify-content: center;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
`;

export const DividerStyle = styled(Row)`
    background-color: #f2f2fc;
    width: 100%;
    height: 4px;
    margin-bottom: 1.5%;
    margin-top: 13px;
`;

export const TitleRow = styled(Row)`
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
`;

export const BackBtn = styled(BackButton)`
    cursor: pointer;
    left: 30px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
`;
