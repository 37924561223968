import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';
import Button from '../../../../components/Button';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const FoodWasteSectionStyles = styled(Col)`
    box-sizing: border-box;
    padding-top: 60px;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #f5f9fc;
    background-size: 150%;
    background-position-y: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TitleWrapper = styled(Row)`
    margin-bottom: ${between('15px', '40px')};
`;
export const ButtonWrapper = styled(Row)`
    margin-top: ${between('15px', '40px')};
`;

export const MoreDetailButton = styled(Button)`
    width: 212px;
    height: 48px;
`;

export const Circle = styled.div`
    position: absolute;
    bottom: ${between('30px', '244px', '320px', '1920px')};
    left: ${between('30px', '244px', '320px', '1920px')};
    width: ${between('30px', '455px', '320px', '1920px')};
    height: ${between('30px', '455px', '320px', '1920px')};
    border-radius: 455px;
    background: white;
`;
