import styled from 'styled-components';
import { Row, Col, Switch, Input, TimePicker, Button } from 'antd';
import { Text } from '../../../../../../components/Typography/styles';

const colorPicker = (theme, isOpen, disabled) => {
    if (disabled) {
        return theme.inactiveSecondaryColor;
    }
    if (isOpen) {
        return theme.quaternaryColor;
    }
    return theme.inactiveSecondaryColor;
};

export default styled(Row)`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    height: 81px;
    border-top: ${({ theme }) => `2px solid ${theme.borderColor}`};
    background: ${({ theme, disabled }) => (disabled ? theme.contentBackground : theme.lightColor)};
    border-left: ${({ theme, open }) => (!open ? `6px solid ${theme.quaternaryColor}` : '6px solid transparent')};
`;

export const Item = styled(Col)`
    display: flex;
`;

export const Switcher = styled(Switch)``;

export const Date = styled(Text)`
    margin-left: ${({ open }) => (!open ? '15px' : '0px')};
    color: ${({ theme, open, disabled }) => {
        if (disabled) {
            return theme.inactiveSecondaryColor;
        }
        if (open) {
            return '#000';
        }
        return theme.inactiveTertiaryColor;
    }} !important;
`;

export const TimeLeft = styled(Text)`
    margin-left: 10px;
    color: ${({ theme, open, disabled }) => (!open || disabled ? theme.inactiveSecondaryColor : '#000')} !important;
    @media only screen and (max-width: 1200px) {
        display: none;
    }
`;

export const CustomInput = styled(Input)`
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    text-align: center;
    max-width: 80px;
    color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield !important;
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
    }
`;

export const Dash = styled.div`
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;
    width: 12px;
    height: 4px;
    background: ${({ theme }) => theme.inactiveSecondaryColor};
`;

export const Time = styled(TimePicker)`
    input {
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 700;
        text-align: center;
        max-width: 80px;
        color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: ${({ theme, isopen, disabled }) => colorPicker(theme, isopen, disabled)};
        }
    }
`;

export const SpecialReasonButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ theme }) => `2px solid ${theme.borderSecondaryColor}`};
    border-radius: 5px;
    color: ${({ theme }) => theme.quaternaryColor};
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    max-width: 180px;
    @media only screen and (max-width: 1600px) {
        font-size: 13px;
    }
    @media only screen and (max-width: 1500px) {
        font-size: 12px;
    }
    @media only screen and (max-width: 1400px) {
        font-size: 11px;
    }
    @media only screen and (max-width: 1300px) {
        font-size: 10px;
    }
    @media only screen and (max-width: 1100px) {
        font-size: 11px;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 10px;
    }
`;

export const IsOpenText = styled(Text)`
    margin-left: 10px;
    color: ${({ theme, open, disabled }) => (!open || disabled ? theme.inactiveSecondaryColor : '#000')} !important;
    @media only screen and (max-width: 1400px) {
    }

    @media only screen and (max-width: 1200px) {
        display: none;
    }
`;
