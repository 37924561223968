import React from 'react';
import { CurrentDataStyles, CurrentDataTitle, CurrentDataWrapper } from './styles';
import { Title } from '../../../../components/Typography/styles';
import CurrentDataComponent from './CurrentDataComponent';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const CurrentDataSection = () => {
    const { sm } = useMediaQuery();

    return (
        <CurrentDataStyles>
            <CurrentDataTitle>
                <Title style={{ textAlign: sm ? 'center' : 'left' }} weight="bold" color="red" size="bigTitle">
                    Güncel Verilerle Gıda İsrafı
                </Title>
            </CurrentDataTitle>
            <CurrentDataWrapper
                gutter={24}
                style={{
                    display: !sm && 'flex',
                    flexDirection: !sm && 'row',
                    justifyContent: sm ? 'center' : 'initial',
                    alignItems: sm ? 'center' : 'initial',
                }}>
                <CurrentDataComponent
                    titleNumber="%33"
                    titleSpan="ü"
                    titleText="İsraf"
                    contentText="Tüketiciye ulaşmadan atığa dönüşen gıdanın toplam üretimdeki oranı"
                />
                <CurrentDataComponent
                    titleNumber="750"
                    titleText="Milyar $"
                    contentText="Gıda israfının küresel ekonomide meydana getirdiği yıllık kayıp"
                />
                <CurrentDataComponent
                    titleNumber="26"
                    titleText="Milyon Ton"
                    contentText="Türkiye’nin, maliyeti 214 milyar TL olan, yıllık gıda israfı miktarı"
                />
                <CurrentDataComponent titleNumber="840" titleText="Milyon" contentText="Dünya üzerinde açlıkla mücadele eden insanların sayısı" />
            </CurrentDataWrapper>
        </CurrentDataStyles>
    );
};

export default CurrentDataSection;
