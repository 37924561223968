import { Row, Col } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card';
import CustomBtn from '../../../../components/Button';
import SearchBar from '../../components/SearchBar';

export const MenuSettingsCardStyles = styled(Card)`
    .ant-card-body {
        min-height: 886px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        padding-bottom: 33px;
    }

    .add-img {
        width: 30px;
        height: 30px;
        margin-right: 3px;
    }
`;

export const Header = styled(Row)`
    min-height: 120px;
    padding-left: 40px;
    padding-right: 40px;
`;

export const Content = styled(Col)`
    height: 690px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 14px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background: #ffffff;
    }
`;
export const Footer = styled(Row)`
    min-height: 80px;
    padding-left: 40px;
    padding-right: 40px;
`;

export const Btn = styled(CustomBtn)`
    padding: 2px 8px;
    height: 32px !important;
    background-color: #fafafe !important;
    border: none;
`;

export const CustomSearchBar = styled(SearchBar)`
    width: 320px;
`;

export const MenuCategoryWrapper = styled(Row)``;
