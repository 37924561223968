import styled from 'styled-components';
import { between } from 'polished';
import { Row, Col, Image, Input as input, Divider as divider, Form as form, Select as select } from 'antd';
import card from '../../../../../components/Card/index';
import { Text as text } from '../../../../../components/Typography/styles';
import button from '../../../../../components/Button/index';

export default styled(card)`
    width: 100%;

    .ant-card-head-title {
        text-align: center;
    }
    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 39px;
        padding-right: 41px;
    }
    margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Text = styled(text)``;

export const Content = styled(Row)`
    display: flex;
    width: 100%;
`;
export const Select = styled(select)``;

export const Item = styled(Col)`
    padding: 0;
    display: flex;
    flex-direction: column;
`;

export const Img = styled(Image)`
    max-width: 22px;
    max-height: 22px;
    margin-right: 6px;
`;

export const Input = styled(input)`
    width: 100%;

    border-radius: 5px;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    margin-top: 10px;
`;

export const Divider = styled(divider)`
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    margin-bottom: 36px;
    height: 2px;
    background: ${({ theme }) => theme.borderColor};
`;

export const EditButton = styled(button)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    width: 100%;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    border-radius: 5px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
    }

    margin-top: 32px;
`;

export const SendButton = styled(button)`
    height: 100% !important;
    width: 100%;
    margin-top: 32px;
`;

export const Form = styled(form)``;
