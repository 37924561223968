import styled from 'styled-components';
import { Row } from 'antd';

export const Label = styled.div`
    border: 2px solid #f2f2fc;
    border-radius: 3px;
    padding: 8px 12px;

    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :hover {
        cursor: grab;
    }
`;

export const FoodLabelStyles = styled(Row)``;
