import styled from 'styled-components';
import { Col } from 'antd';
import { between } from 'polished';
import Button from '../../../../components/Button';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const QuestionSectionStyles = styled(Col)`
    //TODO SET PADDINGS PROPERLY
    padding-top: ${VERTICAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    background: ${({ backgroundColor }) => {
        if (backgroundColor) {
            return `${backgroundColor}`;
        }
    }};
    background-repeat: no-repeat;
    background-size: 15%;
    background-position-x: 80vw;
    background-position-y: 10vw;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
`;

export const QuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

export const BeABusinessButton = styled(Button)`
    width: ${between('150px', '200px')};
    height: ${between('36px', '44px')};
`;
