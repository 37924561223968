/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Row } from 'antd';
import { CustomCard, CardWrapper, Content, CategoriesWrapper, Img } from './styles';
import { Text } from '../../../../components/Typography/styles';
import Spin from '../../../../components/Spinner';

const Categories = ({ data, setQuestionsTitle, setQuestionsData }) => {
    const Title = ({ text }) => (
        <Row align="middle" justify="center">
            <Text size="smallTitle" weight="light" style={{ width: '100%', textAlign: 'center' }}>
                {text}
            </Text>
        </Row>
    );
    const [isActive, setIsActive] = useState();

    const handleClick = (id, name) => {
        setIsActive(id);
        setQuestionsTitle(name);
        setQuestionsData(id);
    };

    // initial state for active element
    useEffect(() => {
        if (data) {
            setIsActive(data[0].id);
            setQuestionsData(data[0].id);
            setQuestionsTitle(data[0].name);
        }
    }, [data]);

    return (
        <CategoriesWrapper
            gutter={[
                { xs: 8, sm: 12, md: 32, lg: 32, xl: 32 },
                { xs: 8, sm: 12, md: 32, lg: 32, xl: 32 },
            ]}>
            {!data ? (
                <Content>
                    <Spin size="large" />
                </Content>
            ) : (
                data.map(({ id, name, logo, logo_inactive }) =>
                    isActive === id ? (
                        <CardWrapper style={{ display: 'flex' }} md={6} lg={6} xl={6} onClick={() => handleClick(id, name)}>
                            <CustomCard title={<Title text={name} />}>
                                <Img src={logo} alt="" />
                            </CustomCard>
                        </CardWrapper>
                    ) : (
                        <CardWrapper style={{ display: 'flex' }} md={6} lg={6} xl={6} onClick={() => handleClick(id, name)}>
                            <CustomCard title={<Title text={name} />}>
                                <Img src={logo_inactive} alt="" />
                            </CustomCard>
                        </CardWrapper>
                    )
                )
            )}
        </CategoriesWrapper>
    );
};

export default Categories;
