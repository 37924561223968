import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    ordersData: null,
};

const getOrderInfo = (state) => ({
    ...state,
    loading: true,
});

const getOrderInfoSuccess = (state, action) => ({
    ...state,
    loading: false,
    ordersData: action.payload,
});

const getOrderInfoFail = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ORDERS:
            return getOrderInfo(state);
        case actionTypes.GET_ORDERS_SUCCESS:
            return getOrderInfoSuccess(state, action);
        case actionTypes.GET_ORDERS_FAIL:
            return getOrderInfoFail(state);
        default:
            return state;
    }
};

export default reducer;
