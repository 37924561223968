import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    minOrderPrice: null,
    maxOrderPrice: null,
    deliveryType: null,
    openBuffet: null,
    extraSuggestion: '',
    extraAddress: '',
    packagingType: null,
    deliveryTimeStart: null,
    deliveryTimeEnd: null,
    belongToId: null,
    deliveryList: null,
    deliveryListError: null,
};

// Package Info
const getPackageInfo = (state) => ({
    ...state,
    loading: true,
});

const getPackageInfoSuccess = (state, action) => ({
    ...state,
    loading: false,
    packagingType: action.payload.packaging_type.id,
    deliveryType: action.payload.delivery_type,
    extraAddress: action.payload.ekstra_address,
    deliveryTimeStart: action.payload.delivery_time_start,
    deliveryTimeEnd: action.payload.delivery_time_end,
    courierDeliveryTimeStart: action.payload.courier_delivery_time_start,
    courierDeliveryTimeEnd: action.payload.courier_delivery_time_end,
    openBuffet: action.payload.open_buffet,
    extraSuggestion: action.payload.extra_suggestion,
    maxOrderPrice: action.payload.min_order_price,
    minOrderPrice: action.payload.min_discounted_order_price,
    status: action.payload.status,
});

const getPackageInfoFail = (state) => ({
    ...state,
    loading: false,
});

// draft Info
const getDraftInfo = (state) => ({
    ...state,
    loading: true,
});

const getDraftInfoSuccess = (state, action) => ({
    ...state,
    loading: false,
    belongToId: action.payload,
});

const getDraftInfoFail = (state) => ({
    ...state,
    loading: false,
});

const updatePackageInfo = (state) => ({
    ...state,
    loading: true,
});

const updatePackageInfoSuccess = (state) => ({
    ...state,
    loading: false,
});
const updatePackageInfoFail = (state) => ({
    ...state,
    loading: false,
});

const updatePackageAmount = (state) => ({
    ...state,
    loading: true,
});

const updatePackageAmountSuccess = (state) => ({
    ...state,
    loading: false,
});
const updatePackageAmountFail = (state) => ({
    ...state,
    loading: false,
});

// DELIVERY

const getDeliveryInfo = (state) => ({
    ...state,
    loading: true,
});

const getDeliveryInfoSuccess = (state, action) => ({
    ...state,
    deliveryList: action.payload.results,
});

const getDeliveryInfoFail = (state) => ({
    ...state,
    loading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PACKAGE_INFO:
            return getPackageInfo(state);
        case actionTypes.GET_PACKAGE_INFO_SUCCESS:
            return getPackageInfoSuccess(state, action);
        case actionTypes.GET_PACKAGE_INFO_FAIL:
            return getPackageInfoFail(state);
        case actionTypes.GET_DRAFT_INFO:
            return getDraftInfo(state);
        case actionTypes.GET_DRAFT_INFO_SUCCESS:
            return getDraftInfoSuccess(state, action);
        case actionTypes.GET_DRAFT_INFO_FAIL:
            return getDraftInfoFail(state);
        case actionTypes.UPDATE_PACKAGE_INFO:
            return updatePackageInfo(state);
        case actionTypes.UPDATE_PACKAGE_INFO_SUCCESS:
            return updatePackageInfoSuccess(state);
        case actionTypes.UPDATE_PACKAGE_INFO_FAIL:
            return updatePackageInfoFail(state);
        case actionTypes.UPDATE_PACKAGE_AMOUNT:
            return updatePackageAmount(state);
        case actionTypes.UPDATE_PACKAGE_AMOUNT_SUCCESS:
            return updatePackageAmountSuccess(state);
        case actionTypes.UPDATE_PACKAGE_AMOUNT_FAIL:
            return updatePackageAmountFail(state);
        case actionTypes.GET_DELIVERY_INFO:
            return getDeliveryInfo(state);
        case actionTypes.GET_DELIVERY_INFO_SUCCESS:
            return getDeliveryInfoSuccess(state, action);
        case actionTypes.GET_DELIVERY_INFO_FAIL:
            return getDeliveryInfoFail(state);
        default:
            return state;
    }
};

export default reducer;
