export const GET_STORE_INFO = 'GET_STORE_INFO';
export const GET_STORE_INFO_SUCCESS = 'GET_STORE_INFO_SUCCESS';
export const GET_STORE_INFO_FAIL = 'GET_STORE_INFO_FAIL';

export const UPDATE_STORE_INFO = 'UPDATE_STORE_INFO';
export const UPDATE_STORE_INFO_SUCCESS = 'UPDATE_STORE_INFO_SUCCESS';
export const UPDATE_STORE_INFO_FAIL = 'UPDATE_STORE_INFO_FAIL';

export const GET_REF_NO = 'GET_REF_NO';
export const GET_REF_NO_SUCCESS = 'GET_REF_NO_SUCCESS';
export const GET_REF_NO_FAIL = 'GET_REF_NO_FAIL';

export const ADD_REF_NO = 'ADD_REF_NO';
export const ADD_REF_NO_SUCCESS = 'ADD_REF_NO_SUCCESS';
export const ADD_REF_NO_FAIL = 'ADD_REF_NO_FAIL';

export const GET_EMAIL_INFO = 'GET_EMAIL_INFO';
export const GET_EMAIL_INFO_SUCCESS = 'GET_EMAIL_INFO_SUCCESS';
export const GET_EMAIL_INFO_FAIL = 'GET_EMAIL_INFO_FAIL';

export const POST_EMAIL_INFO = 'POST_EMAIL_INFO';
export const POST_EMAIL_INFO_SUCCESS = 'POST_EMAIL_INFO_SUCCESS';
export const POST_EMAIL_INFO_FAIL = 'POST_EMAIL_INFO_FAIL';

export const UPDATE_EMAIL_INFO = 'UPDATE_EMAIL_INFO';
export const UPDATE_EMAIL_INFO_SUCCESS = 'UPDATE_EMAIL_INFO_SUCCESS';
export const UPDATE_EMAIL_INFO_FAIL = 'UPDATE_EMAIL_INFO_FAIL';
