import { message } from 'antd';
import * as actions from './actions';
/* eslint-disable sonarjs/no-duplicate-string */

export const tryAuth = (_email, _password, callback) => (dispatch, getState, service) => {
    dispatch(actions.authStartAction());
    return service({
        method: 'POST',
        path: 'user/login/',
        body: {
            credential: _email,
            password: _password,
        },
    })
        .then((userData) => {
            dispatch(actions.authSuccessAction(userData));
        })
        .catch((error) => {
            callback();
            dispatch(actions.authFailAction());
            // const err = error.non_field_errors ? error.non_field_errors[0] : error.detail[0];
            message.error(error?.detail);
        });
};

export const tryAuthFacebook = (token) => (dispatch, getState, service) => {
    dispatch(actions.authStartAction());
    return service({
        method: 'POST',
        path: 'social_auth/facebook/',
        body: {
            auth_token: token,
        },
    })
        .then((userData) => {
            dispatch(actions.authSuccessAction(userData));
        })
        .catch((error) => {
            dispatch(actions.authFailAction());
        });
};

export const connectFacebook = (token) => (dispatch, getState, service) => {
    dispatch(actions.facebookConnectStartAction());
    return service({
        method: 'POST',
        path: 'social_auth/facebook/connect/',
        body: {
            auth_token: token,
        },
        sendToken: true,
    })
        .then((userData) => {
            dispatch(actions.facebookConnectSuccessAction(userData));
            message.success('Kullanıcı başarıyla güncellendi');
        })
        .catch((error) => {
            dispatch(actions.facebookConnectFailAction(error));
        });
};

export const tryAuthGoogle = (token) => (dispatch, getState, service) => {
    dispatch(actions.authStartAction());
    return service({
        method: 'POST',
        path: 'social_auth/google/',
        body: {
            auth_token: token,
        },
    })
        .then((userData) => {
            dispatch(actions.authSuccessAction(userData));
        })
        .catch((error) => {
            dispatch(actions.authFailAction());
            message.error('Bu email ile daha önce kayıt yapılmıştır.');
        });
};

export const connectGoogle = (token) => (dispatch, getState, service) => {
    dispatch(actions.facebookConnectStartAction());
    return service({
        method: 'POST',
        path: 'social_auth/google/connect/',
        body: {
            auth_token: token,
        },
        sendToken: true,
    })
        .then((userData) => {
            dispatch(actions.facebookConnectSuccessAction(userData));
            message.success('Kullanıcı başarıyla güncellendi');
        })
        .catch((error) => {
            dispatch(actions.facebookConnectFailAction(error));
            message.error('Bu email ile daha önce kayıt yapılmıştır.');
        });
};

export const disconnetGoogle = () => (dispatch, getState, service) => {
    dispatch(actions.socialDisconnectStartAction());
    return service({
        method: 'POST',
        path: 'social_auth/google/disconnect/',
        sendToken: true,
    })
        .then((userData) => {
            dispatch(actions.socialDisconnectSuccessAction(userData));
            message.success('Kullanıcı başarıyla güncellendi');
        })
        .catch((error) => {
            dispatch(actions.socialDisconnectFailAction(error));
        });
};
