import React, { useState } from 'react';
import moment from 'moment';
import CellStyles, { Date, Orders, TextStyles, SpecialDate, Circle, Logo } from './styles';
import Image from '../../../../../../Assets/Dashboard/special-day.png';
import { setSelectedDateOrders } from '../../../../../../services/Redux/Dashboard/CalendarPage/actions';

const Cell = ({ date, currentMonth, setSelectedDate, selectedDate, orders }) => {
    const orderExists = orders.length !== 0;
    const [holiday] = useState(() => {
        if (orderExists && orders[0].time_label !== null) {
            return orders[0].time_label.id === 2;
        }
        return false;
    });
    const selected = selectedDate.date() === date.date() && selectedDate.month() === date.month();

    return (
        <CellStyles
            selected={selected}
            onClick={() => {
                if (orders !== undefined) setSelectedDateOrders(orders.box_count);
                setSelectedDate(date);
            }}>
            <Circle specialday={holiday ? 1 : 0}>
                <Logo src={Image} alt="special-day" specialday={holiday ? 1 : 0} />
                <Date weight="light" samemonth={date.month() === currentMonth ? 1 : 0} specialday={holiday ? 1 : 0}>
                    {date.date()}
                </Date>
            </Circle>

            <SpecialDate specialday={holiday ? 1 : 0}>
                <TextStyles color="white" ellipsis>
                    {orderExists && orders[0].detail !== '' ? orders[0].detail : 'Resmi Tatil'}
                </TextStyles>
            </SpecialDate>
            {!orderExists ? null : (
                <Orders sameDay={moment().date() === date.date()}>
                    <TextStyles color="white">{`Paket(${orders[0].box_count})`}</TextStyles>
                </Orders>
            )}
        </CellStyles>
    );
};
export default Cell;
