import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../../components/Button';

export const FooterWrapper = styled(Row)`
    border-top: 2px solid ${({ theme }) => theme.borderColor};
    padding: 12px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 24px;
`;

export const CustomButton = styled(Button)`
    min-width: 100%;
    height: 40px !important;
    border: 0px !important;
`;
