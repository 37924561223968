import React, { useState } from 'react';
import { Row, Col, Select } from 'antd';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Text } from '../../../components/Typography/styles';
import Button from '../../../components/Button/styles';
import {
    FooterWrapper,
    HeaderWrapper,
    FormHeader,
    SelectList,
    ConfirmationText,
    CheckboxStyle,
    WrapperStyle,
    FormRow,
    BackgroundWrapper,
} from './styles';
import food from '../../../../Assets/Shared/Foods/food.svg';
import FormIcon from './FormIcon/FormIcon';
import drink from '../../../../Assets/Shared/Foods/drink.svg';
import vegan from '../../../../Assets/Shared/Foods/vegan.svg';
import hamburger from '../../../../Assets/Shared/Foods/hamburger.svg';
import desert from '../../../../Assets/Shared/Foods/desert.svg';
import pizza from '../../../../Assets/Shared/Foods/pizza.svg';
import chicken from '../../../../Assets/Shared/Foods/chicken.svg';
import coffee from '../../../../Assets/Shared/Foods/coffee.svg';
import bg from '../../../../Assets/Shared/Mailing/backgroundI.svg';
import donguLogo from '../../../../Assets/Shared/Layout/donguLogo.svg';
import FloatingLabel from '../../../components/TextInput/textInput';
import ListBoxSuffixIcon from '../../../../Assets/Shared/Mailing/listBox.svg';

const items = [
    { icon: food, text: 'Yemek' },
    { icon: drink, text: 'İçecek' },
    { icon: vegan, text: 'Vegan' },
    { icon: hamburger, text: 'Hamburger' },
    { icon: desert, text: 'Tatlı' },
    { icon: pizza, text: 'Pizza' },
    { icon: chicken, text: 'Tavuk' },
    { icon: coffee, text: 'Kahve' },
];

const BusinessRegisterForm = () => {
    const { Option } = Select;
    const [email, setEmail] = useState('');
    const [forgotPassword, setForgotPassword] = useState('');

    return (
        <>
            <HeaderWrapper>
                <img src={donguLogo} alt="" />
            </HeaderWrapper>
            <BackgroundWrapper>
                <img src={bg} alt="" width="95%" style={{ position: 'absolute', zIndex: 2 }} />
                <WrapperStyle>
                    <div
                        style={{
                            width: '100%',

                            backgroundColor: 'white',
                            paddingLeft: '50px',
                            paddingRight: '50px',
                            borderRadius: '18px',
                        }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <FormHeader style={{ marginBottom: '40px', width: '113%', marginLeft: '-49px' }}>
                                <h1
                                    style={{
                                        borderBottom: '4px solid #F2F2FC',
                                        textAlign: 'center',
                                        fontSize: 24,
                                        fontWeight: '300',
                                        paddingBottom: '22px',
                                        marginTop: '22px',
                                        width: '100%',
                                    }}>
                                    İşletme Kayıt Forum
                                </h1>
                            </FormHeader>
                            <FloatingLabel
                                style={{ marginBottom: 32 }}
                                id="forgotPassword"
                                name="forgotPassword"
                                placeholder="İşletme Adı"
                                type="text"
                                value={forgotPassword}
                                onChange={(e) => setForgotPassword(e.target.value)}
                            />
                            <FloatingLabel
                                style={{ marginBottom: 32 }}
                                id="email"
                                name="email"
                                placeholder="Email Adresi"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <FormRow>
                                <FloatingLabel
                                    style={{ flex: 0.48 }}
                                    id="forgotPassword"
                                    name="forgotPassword"
                                    placeholder="Telefon No 1"
                                    type="text"
                                    value={forgotPassword}
                                    onChange={(e) => setForgotPassword(e.target.value)}
                                />
                                <FloatingLabel
                                    style={{ flex: 0.48, marginTop: 0 }}
                                    id="forgotPassword"
                                    name="forgotPassword"
                                    placeholder="Telefon No 2"
                                    type="text"
                                    value={forgotPassword}
                                    onChange={(e) => setForgotPassword(e.target.value)}
                                />
                            </FormRow>

                            <FloatingLabel
                                style={{ marginBottom: 32 }}
                                id="forgotPassword"
                                name="forgotPassword"
                                placeholder="Restoran Adresi"
                                type="text"
                                value={forgotPassword}
                                onChange={(e) => setForgotPassword(e.target.value)}
                            />
                            <FormRow>
                                <FloatingLabel
                                    style={{ width: 168 }}
                                    id="forgotPassword"
                                    name="forgotPassword"
                                    placeholder="Posta Kodu"
                                    type="text"
                                    value={forgotPassword}
                                    onChange={(e) => setForgotPassword(e.target.value)}
                                />

                                <SelectList
                                    bordered={false}
                                    suffixIcon={<img src={ListBoxSuffixIcon} alt="img" srcSet="" />}
                                    defaultValue="İl"
                                    style={{ width: 260, marginRight: 20, marginLeft: 20 }}
                                    onChange="">
                                    <Option value="jack">Yalova</Option>
                                    <Option value="lucy">Rize</Option>
                                </SelectList>

                                <SelectList
                                    bordered={false}
                                    defaultValue="İlçe"
                                    suffixIcon={<img src={ListBoxSuffixIcon} alt="img" srcSet="" />}
                                    style={{ width: 260 }}
                                    onChange="">
                                    <Option value="jack">Merkez</Option>
                                    <Option value="lucy">Çayeli</Option>
                                </SelectList>
                            </FormRow>

                            <FloatingLabel
                                style={{ marginBottom: 32 }}
                                id="forgotPassword"
                                name="forgotPassword"
                                placeholder="Restoran Web Site"
                                type="text"
                                value={forgotPassword}
                                onChange={(e) => setForgotPassword(e.target.value)}
                            />
                            <FloatingLabel
                                style={{ marginBottom: 32 }}
                                id="forgotPassword"
                                name="forgotPassword"
                                placeholder="Restoranımda bulunan ürünlerin kategorileri"
                                type="text"
                                value={forgotPassword}
                                onChange={(e) => setForgotPassword(e.target.value)}
                            />

                            <Row style={{}}>
                                <Checkbox.Group onChange="">
                                    <Row justify="center" align="middle">
                                        {items.map((item) => (
                                            <FormIcon icon={item.icon} text={item.text} />
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            </Row>
                            <Row justify="space-between" align="middle" style={{ marginBottom: 32 }}>
                                <Col span={17}>
                                    <FloatingLabel
                                        id="forgotPassword"
                                        name="forgotPassword"
                                        placeholder="Eklemek İstediğiniz Kategori"
                                        type="text"
                                        value={forgotPassword}
                                        onChange={(e) => setForgotPassword(e.target.value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <Button type="border" borderColor="#E5876A" style={{ width: '100%', height: '48px' }}>
                                        <Text color="#E5876A" weight="semi-bold">
                                            Ekle
                                        </Text>
                                    </Button>
                                </Col>
                            </Row>

                            <Row justify="space-between" align="middle" style={{ marginBottom: 32 }}>
                                <Col span={17}>
                                    <Row justify="space-between" align="middle">
                                        <Col span={1}>
                                            <CheckboxStyle style={{ borderRadius: 5, marginLeft: 6 }} />
                                        </Col>

                                        <Col span={22}>
                                            <ConfirmationText style={{ fontSize: 11, width: '100%' }}>
                                                Önemli kampanyalardan haberdar olmak için <strong>Rıza Metni</strong> kapsamında elektronik ileti
                                                almak istiyorum.
                                            </ConfirmationText>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Button type="secondary" style={{ width: '100%', height: '48px' }}>
                                        <Text color="white" weight="semi-bold">
                                            Kaydı Tamamla
                                        </Text>
                                    </Button>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '10px',
                                    marginTop: '40px',
                                    marginLeft: '30px',
                                    marginRight: '55px',
                                    marginBottom: '40px',
                                }}>
                                <Text size="11px">
                                    Üye olmakla <a style={{ color: '#F38262' }}> Kullanım Koşullarını</a> onaylamış olursunuz. Kişisel verilerinize
                                    dair
                                    <a style={{ color: '#F38262' }}> Aydınlatma Metni </a> için tıklayınız.
                                </Text>
                            </Row>
                        </div>
                    </div>
                </WrapperStyle>
            </BackgroundWrapper>
            <FooterWrapper>
                <Text>© 2021 dongu.com &nbsp; All rights reserved · Privacy · Terms · Sitemap</Text>
            </FooterWrapper>
        </>
    );
};

export default BusinessRegisterForm;
