import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomRow } from './styles';
import RegisteredAddress from './RegisteredAddress';
import PaymentAddress from './PaymentAddress';
import LocationMap from './LocationMap';
import AddressForm from './AddressForm';
import { getAddress, getAddressActive } from '../../../../../../services/Redux/Shared/profile/api';
import Spinner from '../../../../../components/Spinner/index';

const AddressPane = () => {
    const [newAddress, setNewAddress] = useState(false);
    const [form, setForm] = useState(false);
    const [formInfo, setFormInfo] = useState(null);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [newInfo, setNewInfo] = useState(null);
    const [deleteAddress, setDeleteAddress] = useState(0);
    const [addresses, setAddresses] = useState(null);
    const [activeAddress, setActiveAddress] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector;

    useEffect(() => {
        dispatch(getAddress());
        dispatch(getAddressActive());
    }, [dispatch, deleteAddress]);

    const temp1 = selector((state) => state.profileStore.addressList);
    const temp2 = selector((state) => state.profileStore.activeAddress);

    useEffect(() => {
        setAddresses(temp1);
        setActiveAddress(temp2);
    }, [temp1, temp2, deleteAddress]);
    if (!newAddress && !form && addresses) {
        return (
            <CustomRow className="main-row">
                <CustomRow>
                    <RegisteredAddress
                        activeAddress={activeAddress}
                        addresses={addresses}
                        setNewAddress={setNewAddress}
                        setForm={setForm}
                        setFormInfo={setFormInfo}
                        setDeleteAddress={setDeleteAddress}
                        deleteAddress3={deleteAddress}
                    />
                </CustomRow>
                {/* <CustomRow className="payment-row">
                    <PaymentAddress
                        addresses={addresses}
                        setNewAddress={setNewAddress}
                        setForm={setForm}
                        setFormInfo={setFormInfo}
                        setDeleteAddress={setDeleteAddress}
                        deleteAddress3={deleteAddress}
                    />
                </CustomRow> */}
            </CustomRow>
        );
    }
    if (form) {
        return (
            <CustomRow className="main-row">
                <AddressForm
                    setNewAddress={setNewAddress}
                    latitude={lat}
                    longitude={lng}
                    formInfo={formInfo}
                    setForm={setForm}
                    setFormInfo={setFormInfo}
                    newInfo={newInfo}
                    setDeleteAddress={setDeleteAddress}
                    deleteAddress3={deleteAddress}
                />
            </CustomRow>
        );
    }
    if (newAddress) {
        return (
            <CustomRow className="main-row">
                <CustomRow className="main-row-sub">
                    <LocationMap setForm={setForm} setLat={setLat} setLng={setLng} setNewInfo={setNewInfo} />
                </CustomRow>
            </CustomRow>
        );
    }
    return (
        <CustomRow className="main-row">
            <CustomRow>
                <Spinner />
            </CustomRow>
        </CustomRow>
    );
};

export default AddressPane;
