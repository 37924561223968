/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PlanStyles, { Item, Switcher, Date, CustomInput, Dash, SpecialReasonButton, Time, TimeLeft, IsOpenText } from './styles';
import Modal from './Modal/index';

const DailyPlan = ({ date, orders, updateWeekly, setUpdateWeekly }) => {
    const orderExists = orders.length !== 0;
    const days = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'];
    const format = 'YYYY-MM-DD';
    const disabled = date.isBefore(moment().format(format)); // disabled if the date is in the past
    const isToday = date.isSameOrBefore(moment().format(format)); // if the date is today then box_count and times are disabled
    const [timeLabel, setTimeLabel] = useState(() => {
        if (orderExists && orders[0].time_label !== null) {
            return orders[0].time_label.id;
        }
        return null;
    });
    const [isOpen, setIsOpen] = useState(orderExists ? orders[0].is_active && timeLabel !== 1 : true);
    const [details, setDetails] = useState(orderExists ? orders[0].detail : ''); // further explanation if there is a special event
    const [id] = useState(orderExists ? orders[0].id : -1);
    const [boxCount, setBoxCount] = useState(orderExists ? orders[0].box_count : 0);
    const [startDate, setStartDate] = useState(orderExists ? orders[0].start_date : date.format('YYYY-MM-DDT00:00:ss'));
    const [endDate, setEndDate] = useState(orderExists ? orders[0].end_date : date.format('YYYY-MM-DDT23:59:ss'));
    const [store] = useState(orderExists ? orders[0].store : -1);
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const start = moment(endDate); // changes endDate string to  moment object
    const [e1, e2] = start.subtract(1, 'minutes').format('HH-mm').split('-'); // subtract 1 minute and format to hh-mm

    const onSubmit = (day_id, box_count, start_date, end_date, time_label, is_active, detail) => {
        if (orderExists && isEdit) {
            const newStartDateFormat = start_date.slice(0, 16);
            const newEndDateFormat = end_date.slice(0, 16);
            if (updateWeekly.filter((daily) => daily.id === day_id) < 1) {
                setUpdateWeekly([
                    ...updateWeekly,
                    { id: day_id, box_count, start_date: newStartDateFormat, end_date: newEndDateFormat, store, time_label, is_active, detail },
                ]);
            } else {
                setUpdateWeekly(
                    updateWeekly.map((daily) =>
                        daily.id === day_id
                            ? {
                                  id: day_id,
                                  box_count,
                                  start_date: newStartDateFormat,
                                  end_date: newEndDateFormat,
                                  store,
                                  time_label,
                                  is_active,
                                  detail,
                              }
                            : daily
                    )
                );
            }
        }
    };

    const getDisabledHours = (value) => {
        const tmp = [];
        for (let i = 0; i <= value; i += 1) {
            tmp.push(i);
        }
        return tmp;
    };

    const handleCancel = () => {
        setShowModal(false);
        onSubmit(id, boxCount, startDate, endDate, timeLabel, isOpen, details);
    };

    useEffect(() => {
        onSubmit(id, boxCount, startDate, endDate, timeLabel, isOpen, details);
    }, [startDate, endDate, isOpen, boxCount]);

    return (
        <PlanStyles open={isOpen} disabled={isToday}>
            <Item sm={2} md={2} lg={2} xl={2}>
                <Date open={isOpen} weight={!isOpen ? 'regular' : '600'} disabled={isToday}>
                    {`${date.date()} ${days[date.day()]}`}
                </Date>
            </Item>
            <Item
                sm={{ span: 2, offset: 1 }}
                md={{ span: 2, offset: 1 }}
                lg={{ span: 2, offset: 1 }}
                xl={{ span: 2, offset: 1 }}
                style={{ marginRight: '30px' }}>
                <CustomInput
                    isopen={isOpen ? 1 : 0}
                    type="number"
                    min={0}
                    size="large"
                    placeholder={boxCount}
                    onChange={(event) => {
                        if (!Number.isNaN(parseInt(event.target.value)) && !event.target.value.includes(',') && !event.target.value.includes('.')) {
                            setBoxCount(parseInt(event.target.value));
                            setIsEdit(true);
                        } else {
                            setBoxCount(0);
                        }
                    }}
                    disabled={isToday}
                />
            </Item>
            <Item
                sm={{ span: 7, offset: 1 }}
                md={{ span: 7, offset: 1 }}
                lg={{ span: 7, offset: 1 }}
                xl={{ span: 5, offset: 1 }}
                xxl={{ span: 4, offset: 2 }}>
                <Time
                    isopen={isOpen ? 1 : 0}
                    size="large"
                    format="HH:mm"
                    suffixIcon={null}
                    placeholder={moment(startDate).format('HH:mm')}
                    minuteStep={15}
                    onChange={(value) => {
                        if (value !== null) {
                            const tmp = `${date.format(format)}T${value.format('HH:mm:ss')}`;
                            setStartDate(tmp);
                            setIsEdit(true);
                        } else {
                            setStartDate(date.format('YYYY-MM-DDT00:00:ss'));
                        }
                    }}
                    disabled={isToday}
                />
                <Dash />
                <Time
                    isopen={isOpen ? 1 : 0}
                    size="large"
                    format="HH:mm"
                    suffixIcon={null}
                    placeholder={moment(endDate).format('HH:mm')}
                    minuteStep={15}
                    onChange={(value) => {
                        if (value !== null) {
                            const tmp = `${date.format(format)}T${value.format('HH:mm:ss')}`;
                            setEndDate(tmp);
                            setIsEdit(true);
                        } else {
                            setEndDate(date.format('YYYY-MM-DDT23:59:ss'));
                        }
                    }}
                    disabledHours={() => getDisabledHours(parseInt(moment(startDate).format('HH')))}
                    disabled={isToday}
                />
            </Item>
            <Item md={0} lg={2} xl={3} xxl={4} style={{ marginRight: '0px' }}>
                <TimeLeft size="13px" family="secondary" fontStyle="italic" open={isOpen} disabled={isToday}>
                    {`*Satışlar ${e1}:${e2}'da durur.`}
                </TimeLeft>
            </Item>
            <Item
                sm={{ span: 5, offset: 2 }}
                md={{ span: 5, offset: 2 }}
                lg={{ span: 4, offset: 1 }}
                xl={{ span: 3, offset: 2 }}
                xxl={{ span: 3, offset: 2 }}
                style={{ marginRight: '10px' }}>
                <SpecialReasonButton
                    onClick={() => {
                        setShowModal(true);
                        setIsEdit(true);
                    }}
                    disabled={disabled}>
                    Özel Sebep Ekle
                </SpecialReasonButton>
                <Modal
                    onCancel={handleCancel}
                    timeLabel={timeLabel}
                    setTimeLabel={setTimeLabel}
                    visible={showModal}
                    setVisible={setShowModal}
                    detail={details}
                    setDetail={setDetails}
                    date={date}
                />
            </Item>
            <Item sm={{ span: 1, offset: 1 }} md={{ span: 1, offset: 1 }} lg={{ span: 1, offset: 1 }} xl={{ span: 1, offset: 0 }} xxl={{ span: 1 }}>
                <Switcher
                    disabled={disabled}
                    size="large"
                    onClick={() => {
                        setIsOpen(!isOpen);
                        setIsEdit(true);
                    }}
                    checked={isOpen}
                />
            </Item>
            <Item span={2} lg={0}>
                <IsOpenText size="13px" family="secondary" fontStyle="italic" open={isOpen} disabled={disabled} style={{ marginLeft: '15px' }}>
                    {isOpen ? 'Restoran Açık' : 'Restoran Kapalı'}
                </IsOpenText>
            </Item>
        </PlanStyles>
    );
};

export default DailyPlan;
