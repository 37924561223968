import styled from 'styled-components';
import { Modal, Row, Input, TimePicker, Divider } from 'antd';
import Button from '../../../../../components/Button';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        display: none;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .ant-modal-body {
        padding: 25px !important;
        overflow-y: scroll;
        height: 430px !important;
        min-width: 520px !important;

        ::-webkit-scrollbar {
            width: 11px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 11px ${({ theme }) => theme.borderColor};
            border-bottom-right-radius: 50px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }

    .spacebetween {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
`;

export const TimeInputsWrapper = styled(Row)`
    .ant-input {
        font-size: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 180px;
        padding: 4px 4px;
    }
    padding-bottom: 20px;
    border-bottom: 3px solid ${({ theme }) => theme.borderColor};
`;
export const TextWrapper = styled(Row)`
    padding-left: 10px;
    padding-right: 10px;
`;
export const Footer = styled(Row)``;
export const TimeInput = styled(Input)``;
export const CustomTimeInput = styled(TimePicker)`
    width: 200px;
    border: none;
    input {
        width: 20px;
        font-size: 45px;
        font-family: Montserrat;
        font-weight: 600;
        color: #837f7f;
        text-align: center;
    }
`;
export const CustomButton = styled(Button)`
    width: 100%;
    height: 100%;
`;
export const ModalDivider = styled(Divider)`
    border: 2px solid #f2f2fc;
    color: #f2f2fc;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
`;
