import { Row, Card } from 'antd';
import styled from 'styled-components';

export const InformationStyles = styled.div`
    margin-top: 30px;
`;

export const CustomCard = styled(Card)`
    border: 2px solid #f5f9fc;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    .ant-card-body {
        padding: 12px;
    }
`;

export const CustomRow = styled(Row)`
    margin-top: 10px;

    &.email-row {
        margin-top: 13px;
    }
`;
