/* eslint-disable no-console */
import React from 'react';
import { Button, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const close = () => {
    console.log('Notification was closed. Either the close button was clicked or duration time elapsed.');
};

const openNotification = (description, text, directToNotifications, error, buttonText) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button
            type="primary"
            style={{ color: '#fff', backgroundColor: '#8fd98e', border: '2px solid #8fd98e' }}
            size="large"
            onClick={() => notification.close(key)}>
            {buttonText}
        </Button>
    );

    if (error) {
        notification.open({
            message: description,
            description: text,
            duration: 8,
            onClick: directToNotifications,
            btn,
            key,
            icon: <ExclamationCircleOutlined style={{ color: '#F36262' }} />,
            onClose: close,
        });
    } else {
        notification.open({
            message: description,
            description: text,
            duration: 6,
            onClick: directToNotifications,
            btn,
            key,
            onClose: close,
        });
    }
};

export default openNotification;
