import React from 'react';
import { Text } from '../../../../../../../components/Typography/styles';

export const OnBilgi = () => (
    <div>
        <Text size="regular" family="primary" weight="bold" color="black">
            • ARACI HİZMET SAĞLAYICI DÖNGÜ BİLGİLERİ
        </Text>
        <br />
        <Text size="mediumRegular" weight="bold" color="black">
            Aracı Hizmet Sağlayıcı Unvan DÖNGÜ ELEKTRONİK TİCARET ANONİM ŞİRKETİ{' '}
        </Text>
        <Text size="mediumRegular" color="black">
            (“Döngü”)'ye ait{' '}
            <a target="_blank" href="www.dongu.com">
                www.dongu.com
            </a>
        </Text>
        <br />
        <br />
        <Text size="mediumRegular" weight="bold" color="black">
            Aracı Hizmet Sağlayıcı Mersis No: 0311074871700001
        </Text>
        <br />
        <br />
        <Text size="mediumRegular" weight="bold" color="black">
            Döngü Çağrı Merkezi Numarası ve İletişim E-posta Adresi: <a href="mailto:destek@dongu.com">destek@dongu.com</a>
        </Text>
        <br />
        <br />
        <Text size="smallRegular" color="black">
            İşbu Ön Bilgilendirme Formu kapsamında Alıcı’ya siparişinin detayları hakkında bilgilendirme yapılmakta olup, Alıcı’nın Mal/Hizmet’in
            online olarak satın alınmasına ilişkin olarak 29188 Sayılı Mesafeli Sözleşmeler Yönetmeliği gereğince Mesafeli Satış Sözleşmesini
            onaylaması gerekmektedir. Alıcı, Ön Bilgilendirme Formu ve Mesafeli Satış Sözleşmesine ilişkin bilgilerini üyeliğinin bağlı olduğu
            “Hesabım” sayfasından takip edebilecek ve siparişine ilişkin verdiği bilgileri “Hesabım” sayfasından değiştirebilecektir. Ön bilgilendirme
            Formu ve Mesafeli Satış Sözleşmesi Çiçeksepeti tarafından kayıt altına alınmakta olup, Alıcı dilediği zaman “Hesabım” sayfasından
            metinlere ulaşım sağlayabilecektir. Aracı Hizmet Sağlayıcı tarafından Üyelik Sözleşmesi’nde belirtilen gizlilik kuralları uygulanmaktadır.
        </Text>
        <br />
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 3 – SÖZLEŞME KONUSU MAL VE HİZMETİN TEMEL NİTELİKLERİ ve FİYATI (KDV DAHİL)
        </Text>
        <br />
        <Text size="mediumRegular" weight="bold" color="black">
            ÜRÜN TESLİMAT BİLGİLERİ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Kurye ile gönderilen ürünlerinin teslimatı, sipariş oluşturma aşamasında seçtiğiniz tarih ve saat aralığı içerisinde
            gerçekleştirilecektir. Kurye ile yapılan gönderimlerde net bir teslim tarih bilgisi paylaşılamamaktadır. Teslimatınız, sipariş aşamasında
            tarafınıza gösterilen tahmini zaman aralığında gerçekleşmektedir.
        </Text>
        <br />
        <Text size="mediumRegular" weight="bold" color="black">
            ÜRÜN TESLİMAT BİLGİLERİ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            <ol>
                <li>
                    Sipariş teslim edilirken Alıcı (teslimat yapılacak kişi) yerinde bulunamazsa, siparişi Alıcı’ya ulaştırabilecek kişilere ismi ve
                    imzası alınarak teslimat yapılabilir. Siparişi teslim alan kişi ile ilgili bilgi, sms ve e-mail yoluyla Alıcı’ya iletilir. Resmi
                    ve özel kuruluşlara yapılan teslimatlar kuruluşların kendi teslimat prosedürleri çerçevesinde yapılır.
                </li>
                <li>
                    İşbu sözleşmenin kurulma aşamasında Alıcı tarafından … müşteri hizmetleri numarasını aradığı zaman da dahil olmak üzere işbu
                    sözleşme oluştuktan sonra siparişin durumunu bilgilendirme amaçlı gönderilen SMS'lerden Alıcı’ya herhangi bir ücret yansıtılmaz.
                </li>
                <li>
                    Ürün sayfasında sipariş sayısı bazlı sınırlama içeren kampanyalı ürünlerin satışlarında sistemsel aksaklıklar, altyapı sorunları
                    vb. nedenlerle kampanya şartlarını aşan ürün alımlarında söz konusu kampanya şartlarını aşan ürün alımları geçersiz olacak ve
                    iptal edilecektir. Bu durumda teslimat masrafları da dâhil olmak üzere tahsil edilen tüm ödemeler iptal tarihinden itibaren en geç
                    24 (yirmidört) saat içerisinde Alıcı’ya iade edilecektir.
                </li>
            </ol>
        </Text>
        <br />
        <Text size="mediumRegular" weight="bold" color="black">
            SÖZ KONUSU ÜRÜN BEDELİ, DÖNGÜ GÖNDERİCİ GÜVENCESİ KAPSAMINDA SATICI ADINA, DÖNGÜ TARAFINDAN GÖNDERİCİ’DEN TAHSİL EDİLMEKTEDİR. GÖNDERİCİ
            MALIN BEDELİNİ DÖNGÜ’E ÖDEMEKLE, ÜRÜN BEDELİNİ SATICIYA ÖDEMİŞ SAYILACAK VE BİR DAHA ÖDEME YÜKÜMLÜLÜĞÜ ALTINA GİRMEYECEKTİR. GÖNDERİCİNİN
            İLGİLİ MEVZUAT KAPSAMINDA İADE HAKLARI SAKLIDIR.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • CAYMA HAKKI
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Alıcı mal/hizmetin kendisine tesliminden itibaren, hiçbir gerekçe göstermeksizin 14 (ondört) gün içinde cayma hakkını kullanarak
            mal/hizmeti iade edebilir. Alıcı, cayma hakkı kullanım bildirimini Websitesi üzerinden Satıcı’ya ulaştırır. Satıcı, Alıcı’nın cayma
            beyanının kendisine ulaşmasından itibaren 14 (ondört) gün içinde mal/hizmet bedelini iade etmekle yükümlüdür. Alıcı, cayma hakkını
            kullanmasından itibaren, 10 (on) gün içinde malı Satıcı’ya geri göndermek zorundadır. Malın/Hizmetin iade alınamaması için haklı
            sebeplerin varlığı halinde Satıcı, sözleşmedeki ifa süresi dolmadan Alıcı’ya eşit kalite ve fiyatta mal/hizmet tedarik edebilir. Satıcı
            mal/hizmetin ifasının imkânsızlaştığını düşünüyorsa, bu durumu öğrendiği tarihten itibaren 3 (üç) gün içinde Alıcı’ya bildirir. Bu durumda
            Satıcı, ödenen bedeli ve varsa belgeleri 14 (ondört) gün içinde Alıcı’ya iade eder. Alıcı iade edeceği mal/hizmeti Ön Bilgilendirme
            Formu’nda belirtilen ve Satıcı’nın anlaşmalı kargo şirketi ile Satıcı’ya gönderdiği sürece, iade kargo bedeli ve sair giderler Satıcı’ya
            aittir. Alıcı’nın iade edeceği malı Ön Bilgilendirme Formu’nda belirtilen Satıcı’nın anlaşmalı kargo şirketi dışında bir kargo şirketi ile
            göndermesi halinde, iade kargo bedeli ve malın kargo sürecinde uğrayacağı hasardan Satıcı sorumlu değildir. Mal ile beraber fatura da,
            mevcut olması halinde, ilgili iade bölümü de doldurularak ve Alıcı tarafından imzalanarak teslim edilmelidir.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • CAYMA HAKKININ KULLANILAMAYACAĞI HALLER
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Cayma hakkı aşağıdaki hallerde kullanılamaz:
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            {`a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve Satıcı’nın kontrolünde olmayan mal veya hizmetlere ilişkin
            sözleşmelerde, (Ziynet, altın ve gümüş kategorisindeki ürünler)`}
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            {`b)Tüketicinin istekleri veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan, niteliği itibariyle geri gönderilmeye elverişli
            olmayan ve çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan malların teslimine ilişkin sözleşmelerde,`}
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            {`c) Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun
            olmayanların teslimine ilişkin sözleşmelerde,`}
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            d) Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmelerde,
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            {`e)Tüketici tarafından ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması şartıyla maddi ortamda sunulan kitap, ses veya
            görüntü kayıtlarına, yazılım programlarına ve bilgisayar sarf malzemelerine ilişkin sözleşmelerde,`}
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            f) Abonelik sözleşmesi kapsamında sağlananlar dışında gazete, dergi gibi süreli yayınların teslimine ilişkin sözleşmelerde,
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            {`g) Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek içecek tedariki ve eğlence veya
            dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmelerde,`}
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            h) Bahis ve piyangoya ilişkin hizmetlerin ifasına ilişkin sözleşmelerde,
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            ı) Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmelerde,
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            {`i) Elektronik ortamda anında ifa edilen hizmetler ile tüketiciye anında teslim edilen gayri maddi mallara ilişkin sözleşmelerde ve
            sözleşmeye konu Mal/Hizmet’in Mesafeli Sözleşmeler Yönetmeliği’nin uygulama alanı dışında bırakılmış olan (satıcının düzenli teslimatları
            ile Alıcı'nın meskenine teslim edilen gıda maddelerinin, içeceklerin ya da diğer günlük tüketim maddeleri ile seyahat, konaklama,
            lokantacılık, eğlence sektörü gibi alanlardaki hizmetler) Mal/Hizmet türlerinden müteşekkil olması halinde Alıcı ve Satıcı arasındaki
            hukuki ilişkiye Mesafeli Sözleşmeler Yönetmeliği hükümleri uygulanamaması sebebiyle`}
            <Text size="smallRegular" color="black">
                {' '}
                cayma hakkı kullanılamayacaktır.
            </Text>
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • UYUŞMAZLIKLARIN ÇÖZÜMÜ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            İşbu Ön Bilgilendirme Formu’nun uygulanmasında, Ticaret Bakanlığı’nca ilan edilen değere kadar Alıcı’nın Mal veya Hizmeti satın aldığı ve
            ikametgahının bulunduğu yerdeki Tüketici Hakem Heyetleri ile Tüketici Mahkemeleri yetkilidir. 6502 Sayılı Tüketicinin Korunması Hakkında
            Kanun’un 68’nci Maddesinin 1. fıkrasında belirtilen alt ve üst limitler doğrultusunda tüketici talepleri hakkında ilçe/il Tüketici Hakem
            Heyetleri yetkilidir.
        </Text>
        <br />
        <br />
        <Text size="smallRegular" weight="bold" color="black">
            Yukarıda yer alan bilgiler uzaktan iletişim araçlarına uygun olarak ve iyi niyet ilkeleri çerçevesinde ergin olmayanlar ile ayırtım
            gücünden yoksun veya kısıtlı erginleri koruyacak şekilde ticari amaçlarla verilmiştir.
        </Text>
    </div>
);

export const Mesafeli = () => (
    <div>
        <Text size="title" family="primary" weight="bold" color="black" style={{ textAlign: 'center' }}>
            MESAFELİ SATIŞ SÖZLEŞMESİ
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 2 : SÖZLEŞMENİN KONUSU ve KAPSAMI
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            İşbu Mesafeli Satış Sözleşmesi{' '}
            <Text size="smallRegular" weight="bold" color="black">
                (“Sözleşme”)
            </Text>{' '}
            6502 Sayılı Tüketicinin Korunması Hakkında Kanun{' '}
            <Text size="smallRegular" weight="bold" color="black">
                (“Kanun”)
            </Text>{' '}
            ve Mesafeli Sözleşmeler Yönetmeliği'ne{' '}
            <Text size="smallRegular" weight="bold" color="black">
                (“Yönetmelik”)
            </Text>{' '}
            uygun olarak düzenlenmiştir. Sözleşme'nin tarafları işbu Sözleşme tahtında Kanun’dan ve Yönetmelik’den kaynaklanan yükümlülük ve
            sorumluluklarını bildiklerini ve anladıklarını kabul ve beyan ederler.
        </Text>
        <br />
        <br />
        <Text size="smallRegular" color="black">
            İşbu Sözleşmenin konusunu; Alıcı’nın,{' '}
            <Text size="smallRegular" weight="bold" color="black">
                DÖNGÜ ELEKTRONİK TİCARET ANONİM ŞİRKETİ (“Döngü”)'
            </Text>
            ne ait www.dongu.com alan adlı web sitesinden{' '}
            <Text size="smallRegular" weight="bold" color="black">
                (“website”)
            </Text>{' '}
            ve mobil aplikasyonundan{' '}
            <Text size="smallRegular" weight="bold" color="black">
                (“Platform”)
            </Text>
            , Satıcı'ya ait mal/hizmetin satın alınmasına yönelik elektronik olarak sipariş verdiği, Sözleşmede belirtilen niteliklere sahip
            mal/hizmetin satışı ve teslimi ile ilgili olarak Kanun ve Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanması
            oluşturur. İşbu Sözleşmenin akdedilmesi tarafların ayrı ayrı Döngü ile akdetmiş oldukları websitesi Üyelik Sözleşmelerinin hükümlerinin
            ifasını engellemeyecek olup taraflar işbu Sözleşme konusu mal/hizmetin satışında Döngü’in herhangi bir şekilde taraf olmadığını ve
            Sözleşme kapsamında tarafların yükümlülüklerini yerine getirmeleri ile ilgili herhangi bir sorumluluğu ve taahhüdü bulunmadığını kabul ve
            beyan ederler.
        </Text>
        <br />
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 3 – SÖZLEŞME KONUSU MAL VE HİZMETİN TEMEL NİTELİKLERİ ve FİYATI (KDV DAHİL)
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            SÖZ KONUSU ÜRÜN BEDELİ, DÖNGÜ ALICI GÜVENCESİ KAPSAMINDA SATICI ADINA, DÖNGÜ TARAFINDAN ALICI'DAN TAHSİL EDİLMEKTEDİR. ALICI
            MALIN/HİZMETİN BEDELİNİ DÖNGÜ’E ÖDEMEKLE, ÜRÜN BEDELİNİ SATICI’YA ÖDEMİŞ SAYILACAK VE BİR DAHA ÖDEME YÜKÜMLÜLÜĞÜ ALTINA GİRMEYECEKTİR.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 4 - MALIN TESLİMİ VE TESLİM ŞEKLİ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Sözleşme, Alıcı tarafından elektronik ortamda onaylanmakla yürürlüğe girmiş olup Gönderi'nin Satıcı'dan satın almış olduğu malın/hizmetin
            Alıcı'ya teslim edilmesiyle ifa edilmiş olur. Mal/Hizmet, Alıcı’nın sipariş formunda ve işbu Sözleşmede belirtmiş olduğu adrese ve
            belirtilen yetkili kişi/kişilere teslim edilecektir.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 5 - TESLİMAT MASRAFLARI VE İFASI
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Malın/Hizmetin teslimat masrafları aksine bir hüküm yoksa Alıcı’ya aittir. Satıcı, websitesinde teslimat ücretinin kendisince
            karşılanacağını beyan etmişse teslimat masrafları Satıcı'ya ait olacaktır.
            <br />
            Malın teslimatı; Alıcı tarafından ödemenin gerçekleşmesinden sonra Satıcı tarafından taahhüt edilen sürede yapılır. Satıcı, sipariş konusu
            mal/hizmet ediminin yerine getirilmesinin imkansızlaştığı haller saklı kalmak kaydıyla, mal/hizmeti, Alıcı tarafından mal/hizmet'in
            sipariş edilmesinden itibaren 30 (otuz) gün içinde teslim eder.
            <br />
            Herhangi bir nedenle Alıcı tarafından mal/hizmet bedeli ödenmez veya yapılan ödeme banka kayıtlarında iptal edilir ise, Satıcı
            mal/hizmet'in teslimi yükümlülüğünden kurtulmuş kabul edilir Malın Satıcı tarafından kargoya verilmesinden sonra ve fakat Alıcı tarafından
            teslim alınmasından önce Alıcı tarafından yapılan sipariş iptallerinde kargo / kurye bedelinden Alıcı sorumludur.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 6 - ALICININ BEYAN VE TAAHHÜTLERİ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Alıcı, websitesinde yer alan Sözleşme konusu malın/hizmetin temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimat ve kargo / kurye
            bedeline ilişkin olarak Satıcı tarafından yüklenen ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi
            verdiğini beyan eder.
            <br />
            Alıcı, Tüketici sıfatıyla talep ve şikayetlerini yukarıda yer alan Satıcı iletişim bilgilerine ve/veya websitesinin sağladığı kanallarla
            ulaştırabilirler.
            <br />
            Alıcı, Sözleşme'yi ve Ön Bilgilendirme Formu’nu elektronik ortamda teyit etmekle, mesafeli sözleşmelerin akdinden önce Satıcı tarafından
            Alıcı’ya verilmesi gereken adres, siparişi verilen mal/hizmete ait temel özellikler, mal/hizmet'in vergiler dahil fiyatı, ödeme ve
            teslimat ile teslimat fiyatı bilgilerini de doğru ve eksiksiz olarak edindiğini teyit etmiş olur.
            <br />
            Gönderi'nin, Sözleşme konusu mal/hizmeti kontrol etmeden, tahrip olmuş, kırık, ambalajı yırtılmış vb. hasarlı ve ayıplı olduğu açıkça
            belli olan şekilde kargo / kurye şirketinden teslim alması halinde sorumluluk tamamen kendisine aittir. Alıcı tarafından kargo / kurye
            şirketi görevlisinden teslim alınan mal/hizmetin hasarsız ve sağlam olduğu kabul edilecektir.
            <br />
            Mal/Hizmet'in tesliminden sonra Alıcı’ya ait kredi kartının Alıcı'nin kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız ve
            hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşunun mal/hizmet bedelini Satıcı'ya ödememesi halinde, Alıcı
            kendisine teslim edilmiş olması kaydıyla mal/hizmeti 3 (üç) gün içinde Satıcı'ya iade etmekle yükümlüdür. Bu halde teslimat giderleri
            Alıcı’ya aittir.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 7 - SATICININ BEYAN VE TAAHHÜTLERİ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Satıcı, Sözleşme konusu mal/hizmetin Kanun’a uygun olarak, sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti
            belgeleri ve kullanım kılavuzları ile birlikte Alıcı’ya teslim edilmesinden sorumludur. Satıcı, mücbir sebepler veya nakliyeyi engelleyen
            olağanüstü durumlar nedeni ile sözleşme konusu mal/hizmeti süresi içinde teslim edemez ise, durumu öğrendiği tarihten itibaren 3 (üç) gün
            içinde Alıcı’ya bildirmekle yükümlüdür. Sözleşme konusu mal/hizmet, Alıcı’dan başka bir kişiye teslim edilecek ise, teslim edilecek
            kişinin teslimatı kabul etmemesinden Satıcı sorumlu tutulamaz.
            <br />
            Ürün sayfasında sipariş sayısı bazlı sınırlama içeren kampanyalı ürünlerin satışlarında sistemsel aksaklıklar, altyapı sorunları vb.
            nedenlerle kampanya şartlarını aşan ürün alımlarında söz konusu kampanya şartlarını aşan ürün alımları geçersiz olacak ve iptal
            edilecektir. Bu durumda teslimat masrafları da dâhil olmak üzere tahsil edilen tüm ödemeler iptal tarihinden itibaren en geç 24
            (yirmidört) saat içerisinde Alıcı’ya iade edilecektir.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 8 – CAYMA HAKKI
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Alıcı hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin satın aldığı mal/hizmeti teslim tarihinden
            itibaren 14 (on dört) gün içerisinde cayma hakkını kullanarak iade edebilir. Alıcı, sözleşmenin kurulmasından malın teslimine kadar olan
            süre içinde de cayma hakkını kullanabilir. Cayma hakkı bildirimi ve Sözleşmeye ilişkin her türlü bildirim Satıcı’ya ait ve/veya
            websitesinde belirtilen iletişim kanalları ile gösterilecektir.
            <br />
            <Text size="smallRegular" weight="bold" color="black">
                DÖNGÜ, İŞBU MESAFELİ SATIŞ SÖZLEŞMESİNİN TARAFI OLMADIĞINDAN DÖNGÜ’NE KARŞI CAYMA HAKKI KULLANILAMAZ VEYA DÖNGÜ’TEN BEDEL İADESİ TALEP
                EDİLEMEZ
            </Text>
            <br />. Yalnız ürünün Satıcı’ya iade amacıyla teslimi anında, ürün bedelinin halen Döngü’in hesabında olması durumunda, ürün bedeli
            Alıcı’ya iade edilir. Cayma hakkının kullanılması için Satıcı'ya süresi içerisinde mevzuat hükümlerine ve websitesi'ndeki cayma hakkı
            kullanım seçeneğine uygun olarak bildirimde bulunulması şarttır. Cayma hakkının kullanılması halinde:
            <br />
            a) Alıcı cayma hakkını kullanmasından itibaren 10 (on) gün içerisinde Malı Satıcı'ya anlaşmalı kargo şirketiyle geri gönderir.
            <br />
            b) Cayma hakkı kapsamında iade edilecek mal/hizmet ile birlikte kutusu, ambalajı, varsa standart aksesuarları varsa mal/hizmet ile
            birlikte hediye edilen diğer ürünlerin de eksiksiz ve hasarsız olarak iade edilmesi gerekmektedir. Cayma hakkının kullanılmasını takip
            eden 14 (ondört) gün içerisinde mal/hizmet bedeli Alıcı'ya satın alırken kullandığı ödeme yöntemine göre iade edilir. Mal/hizmet,
            Satıcı'ya iade edilirken, malın/hizmetin teslimi sırasında Alıcı'ya ibraz edilmiş olan orijinal faturanın da Alıcı tarafından iade
            edilmesi gerekmektedir.
            <br />
            Alıcı iade edeceği malı/hizmeti Ön Bilgilendirme Formu’nda belirtilen Satıcı'nın anlaşmalı kargo / kurye şirketi ile Satıcı'ya gönderdiği
            sürece iade kargo / kurye bedeli Satıcı'ya aittir. Alıcı'nın iade edeceği malı/hizmeti Ön Bilgilendirme Formu’nda belirtilen Satıcı'nın
            anlaşmalı kargo / kurye şirketi dışında bir kargo şirketi ile göndermesi halinde iade kargo / kurye bedeli ve malın/hizmetin kargo / kurye
            sürecinde uğrayacağı hasardan Satıcı sorumlu değildir.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 9 – CAYMA HAKKININ KULLANILAMAYACAĞI HALLER
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Cayma hakkı aşağıdaki hallerde kullanılamaz:
            <br />
            a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve satıcının kontrolünde olmayan mal veya hizmetlere ilişkin
            sözleşmelerde (Örn: Ziynet, altın ve gümüş kategorisindeki ürünler);
            <br />
            b)Tüketicinin istekleri veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan, niteliği itibariyle geri gönderilmeye elverişli
            olmayan ve çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan malların teslimine ilişkin sözleşmelerde;
            <br />
            c) Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan iadesi sağlık ve hijyen açısından uygun
            olmayanların teslimine ilişkin sözleşmelerde;
            <br />
            d) Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmelerde;
            <br />
            e) Tüketici tarafından ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması şartıyla maddi ortamda sunulan kitap, ses veya
            görüntü kayıtlarına, yazılım programlarına ve bilgisayar sarf malzemelerine ilişkin sözleşmelerde; <br />
            f) Abonelik sözleşmesi kapsamında sağlananlar dışında gazete, dergi gibi süreli yayınların teslimine ilişkin sözleşmelerde;
            <br />
            <Text size="smallRegular" weight="bold" color="black">
                g) Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek içecek tedariki ve eğlence veya
                dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmelerde;
            </Text>
            <br />
            h)Bahis ve piyangoya ilişkin hizmetlerin ifasına ilişkin sözleşmelerde;
            <br />
            ı) Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmelerde;
            <br />
            <Text size="smallRegular" weight="bold" color="black">
                i) Elektronik ortamda anında ifa edilen hizmetler ile tüketiciye anında teslim edilen gayrimaddi mallara ilişkin sözleşmelerde ve
                sözleşmeye konu Mal/Hizmet'in Yönetmelik’in uygulama alanı dışında bırakılmış olan (satıcının düzenli teslimatları ile Alıcı’nın
                meskenine teslim edilen gıda maddelerinin, içeceklerin ya da diğer günlük tüketim maddeleri ile seyahat, konaklama, lokantacılık,
                eğlence sektörü gibi alanlarda hizmetler); bakımından Alıcı ve Satıcı arasındaki hukuki ilişkiye Mesafeli Sözleşmeler Yönetmeliği
                hükümleri uygulanamaması sebebiyle cayma hakkı kullanılamayacaktır.
            </Text>
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 10 - UYUŞMAZLIKLARIN ÇÖZÜMÜ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Kanun ve Yönetmelik uyarınca satılan mal/hizmete ilişkin sorumluluk bizzat Satıcı’ya aittir. İşbu Sözleşme'nin uygulanmasında, Ticaret
            Bakanlığı’nca ilan edilen değere kadar Alıcı’nın mal/hizmeti satın aldığı ve ikametgahının bulunduğu yerdeki Tüketici Hakem Heyetleri ile
            Tüketici Mahkemeleri yetkilidir. Kanun’un 68'nci maddesinin 1. fıkrasında belirtilen alt ve üst limitler doğrultusunda tüketici talepleri
            hakkında ilçe/il tüketici hakem heyetleri yetkilidir.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 11 - MALIN/ HİZMETİN FİYATI
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Malın peşin veya vadeli satış fiyatı, sipariş formunda yer almakla birlikte, sipariş sonu gönderilen bilgilendirme maili ve/veya ürün ile
            birlikte müşteriye gönderilen fatura içeriğinde mevcut olan fiyattır. Satıcı veya Döngü tarafından yapılan indirimler, kuponlar, kargo /
            kurye ücreti ve sair uygulamalar satış fiyatına yansıtılır.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 12 - TEMERRÜT HALİ VE HUKUKİ SONUÇLARI
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            Alıcı'nın, kredi kartı ile yapmış olduğu işlemlerde temerrüde düşmesi halinde kart sahibi bankanın kendisi ile yapmış olduğu kredi kartı
            sözleşmesi çerçevesinde faiz ödeyecek ve bankaya karşı sorumlu olacaktır. Bu durumda ilgili banka hukuki yollara başvurabilir, doğacak
            masrafları ve vekâlet ücretini Alıcı'dan talep edebilir ve her koşulda Alıcı'nın borcundan dolayı temerrüde düşmesi halinde, Alıcı'nın
            borcu gecikmeli ifasından dolayı Satıcı'nın uğradığı zarar ve ziyandan Alıcı sorumlu olacaktır.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 13 – BİLDİRİMLER ve DELİL SÖZLEŞMESİ
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            İşbu Sözleşme tahtında Taraflar arasında yapılacak her türlü yazışma, mevzuatta sayılan zorunlu haller dışında, e-posta aracılığıyla
            yapılacaktır. Alıcı, işbu Sözleşme'den doğabilecek ihtilaflarda Satıcı'nın ve Döngü’in resmi defter ve ticari kayıtlarıyla, kendi
            veritabanında, sunucularında tuttuğu elektronik bilgilerin ve bilgisayar kayıtlarının, bağlayıcı, kesin ve münhasır delil teşkil
            edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu'nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul, beyan ve taahhüt
            eder.
        </Text>
        <br />
        <Text size="regular" family="primary" weight="bold" color="black">
            • MADDE 14 - YÜRÜRLÜK
        </Text>
        <br />
        <Text size="smallRegular" color="black">
            14 (on dört) maddeden ibaret bu Sözleşme, Taraflarca okunarak, Alıcı tarafından yapılan ödemenin onay aldığı tarihte, Alıcı tarafından
            elektronik ortamda onaylanmak suretiyle akdedilmiş ve yürürlüğe girmiştir. Sözleşmenin bir nüshası Alıcı’nın üyelik hesabında mevcuttur ve
            talep edilmesi halinde ayrıca e-posta ile de gönderilebilecektir.
        </Text>
        <br />
    </div>
);
