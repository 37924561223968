/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-unused-collection */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import { Text } from '../../../../../components/Typography/styles';
import { Wrapper, CustomRow } from './styles';
import { CustomRadio } from '../../../ProfilePage/Content/AddressPane/PaymentAddress/styles';
import { ReactComponent as DropDownArrow } from '../../../../../../Assets/Shared/FindRestaurant/collapsible-icon.svg';
import { HiddenRow } from '../SortSection/styles';

export default function CategorySection(props) {
    const { selectedCategories, setSelectedCategories, saveHandler } = props;
    const selector = useSelector;
    const categories = selector((state) => state.boxStore.categoryNamesList);
    const [displayedCategories, setDisplayedCategories] = useState([]);
    const [showRow, setShowRow] = useState(false);
    const toggleRow = () => setShowRow(!showRow);

    useEffect(() => {
        const { results } = categories || {};
        if (results) {
            setDisplayedCategories(results);
        }
    }, [categories]);

    const unSelect = (item) => {
        setSelectedCategories(selectedCategories.filter((category) => category.id !== item.id));
    };

    const selectItem = (item) => {
        setSelectedCategories([...selectedCategories, item]);
    };

    useEffect(() => {
        saveHandler();
    }, [selectedCategories]);

    return (
        <Wrapper>
            <CustomRow align="middle" justify="space-between">
                <Text weight="500" color="black">
                    Kategori Seç
                </Text>
                <DropDownArrow style={{ cursor: 'pointer' }} onClick={toggleRow} />
            </CustomRow>
            <HiddenRow style={{ height: showRow ? 55 * categories?.results?.length || 200 : 0 }}>
                {displayedCategories?.length
                    ? displayedCategories.map((item) => (
                          <Row key={item.id} style={{ marginTop: '6%' }} align="middle">
                              {selectedCategories.some((selectedItem) => selectedItem.id === item.id) ? (
                                  <CustomRadio className="delivery-section" checked onClick={() => unSelect(item)} />
                              ) : (
                                  <CustomRadio className="delivery-section" checked={false} onClick={() => selectItem(item)} />
                              )}
                              <Text weight="light" color="black" style={{ marginLeft: 10 }}>
                                  {item.name}
                              </Text>
                          </Row>
                      ))
                    : null}
            </HiddenRow>
        </Wrapper>
    );
}
