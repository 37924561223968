import { message } from 'antd';
import * as actionTypes from './actionTypes';

const success = () => {
    message.success('Değişiklikler başarıyla kaydedildi');
};

const error = () => {
    message.error('Bir hata oluştu');
};

const pending = () => {
    message.info('Değişiklikler onaya gönderildi');
};

export const getPackageInfoAction = () => ({
    type: actionTypes.GET_PACKAGE_INFO,
});

export const getPackageInfoSuccessAction = (info) => ({
    type: actionTypes.GET_PACKAGE_INFO_SUCCESS,
    payload: info,
});

export const getPackageInfoFailAction = () => ({
    type: actionTypes.GET_PACKAGE_INFO_FAIL,
});

export const getDraftInfoAction = () => ({
    type: actionTypes.GET_DRAFT_INFO,
});

export const getDraftInfoSuccessAction = (info) => ({
    type: actionTypes.GET_DRAFT_INFO_SUCCESS,
    payload: info,
});

export const getDraftInfoFailAction = () => ({
    type: actionTypes.GET_DRAFT_INFO_FAIL,
});

export const updatePackageInfoAction = (info) => ({
    type: actionTypes.UPDATE_PACKAGE_INFO,
    payload: info,
});

export const updatePackageInfoSuccessAction = () => {
    success();
    return {
        type: actionTypes.UPDATE_PACKAGE_INFO_SUCCESS,
    };
};

export const updatePackageInfoFailAction = () => {
    error();
    return {
        type: actionTypes.UPDATE_PACKAGE_INFO_FAIL,
    };
};

export const updatePackageAmountAction = (info) => ({
    type: actionTypes.UPDATE_PACKAGE_AMOUNT,
    payload: info,
});

export const updatePackageAmountSuccessAction = () => {
    pending();
    return {
        type: actionTypes.UPDATE_PACKAGE_AMOUNT_SUCCESS,
    };
};

export const updatePackageAmountFailAction = () => {
    error();
    return {
        type: actionTypes.UPDATE_PACKAGE_AMOUNT_FAIL,
    };
};

export const getDeliveryInfoAction = () => ({
    type: actionTypes.GET_DELIVERY_INFO,
});

export const getDeliveryInfoSuccessAction = (info) => ({
    type: actionTypes.GET_DELIVERY_INFO_SUCCESS,
    payload: info,
});

export const getDeliveryInfoFailAction = () => ({
    type: actionTypes.GET_DELIVERY_INFO_FAIL,
});
