import styled from 'styled-components';
import { Row, Col } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.card {
        height: 120px;
        max-width: 930px;
        padding: 15px 15px 20px 30px;
        width: 100%;
        transition: 0.2s;

        :hover {
            background: #e9f8ec;
        }
        :focus {
            background: #e9f8ec;
        }
    }

    &.second-row {
        margin-top: 10px;
    }
`;

export const CustomCol = styled(Col)`
    &.notification-col {
        max-width: 350px;
        @media (min-width: 1024px) {
            max-width: 100%;
        }
    }
`;
