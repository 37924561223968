/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import PackageStyles, { Content, Item, Spin } from './styles';
import DeliveryInfo from './Cards/DeliveryInfo/index';
import FoodSafetyAlert from './Cards/FoodSafetyAlert/index';
import DeliveryBoyInfo from './Cards/DeliveryBoyInfo/index';
import PackageAmount from './Cards/PackageAmount/index';
import { getPackageInfo, getDeliveryInfo, getDraftInfo } from '../../../../services/Redux/Dashboard/package/api';
import { getStoreInfo } from '../../../../services/Redux/Dashboard/StorePage/api';

const PackageSettings = () => {
    const dispatch = useDispatch();
    const { id } = useSelector((state) => state.storePage);
    const packageId = useSelector((state) => state?.storePage?.storeInfo?.package_settings?.id);

    useEffect(() => {
        (async () => {
            if (!id) {
                await dispatch(getStoreInfo());
                await dispatch(getDraftInfo());
            } else {
                await dispatch(getStoreInfo(id));
                await dispatch(getPackageInfo(packageId));
                await dispatch(getDeliveryInfo());
                await dispatch(getDraftInfo());
            }
        })();
    }, [id, packageId]);

    const {
        loading,
        minOrderPrice,
        maxOrderPrice,
        deliveryType,
        openBuffet,
        extraSuggestion,
        packagingType,
        extraAddress,
        deliveryList,
        courierDeliveryTimeStart,
        courierDeliveryTimeEnd,
    } = useSelector((state) => state?.packageStore);

    return loading ? (
        <Spin size="large" />
    ) : (
        <PackageStyles>
            <Content>
                <Item xl={11} xxl={12}>
                    <DeliveryInfo packaging={packagingType} extraAddress={extraAddress} id={packageId} deliveryList={deliveryList} />
                    <DeliveryBoyInfo delivery={deliveryType} start={courierDeliveryTimeStart} end={courierDeliveryTimeEnd} id={packageId} />
                </Item>
                <Item xl={11} xxl={12}>
                    <FoodSafetyAlert extraSuggestion={extraSuggestion} isOpen={openBuffet} id={packageId} />
                    <PackageAmount id={packageId} status="" min={minOrderPrice} max={maxOrderPrice} />
                </Item>
            </Content>
        </PackageStyles>
    );
};

export default PackageSettings;
