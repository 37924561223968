/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    boxList: null,
    boxesError: null,
    mealList: null,
    mealError: null,
    tagList: null,
    tagError: null,
    categoryList: null,
    categoryError: null,
    categoryNamesList: null,
    categoryNamesError: null,

    nameList: null,
    nameError: null,

    updateBox: null,
    updateBoxError: null,
    updateLoading: false,

    createBox: null,
    createBoxError: null,
    createBoxLoading: false,
};

const getBoxesReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getBoxesSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    boxList: action.payload,
});

const getBoxesFailReducer = (state, action) => ({
    ...state,
    loading: false,
    boxesError: action.payload,
});

const getBoxCategoriesReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getBoxNamesReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getBoxCategoriesSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    categoryList: action.payload,
});

const getBoxCategoriesFailReducer = (state, action) => ({
    ...state,
    loading: false,
    categoryError: action.payload,
});

const postBoxCategoriesReducer = (state) => ({
    ...state,
    loading: true,
});

const postBoxCategoriesSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const postBoxCategoriesFailReducer = (state) => ({
    ...state,
    loading: false,
});

const getBoxCategoriesNamesReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getBoxCategoriesNamesSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    categoryNamesList: action.payload,
});

const getBoxCategoriesNamesFailReducer = (state, action) => ({
    ...state,
    loading: false,
    categoryNamesError: action.payload,
});

const getTagsReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getTagsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    tagList: action.payload,
});

const getTagsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    tagError: action.payload,
});

const postBoxTagsReducer = (state) => ({
    ...state,
    loading: true,
});

const postBoxTagsSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const postBoxTagsFailReducer = (state) => ({
    ...state,
    loading: false,
});

// const getBoxCategoriesSuccessReducer = (state, action) => ({
//     ...state,
//     loading: false,
//     boxesError: action.payload,
// });

// const getBoxCategoriesFailReducer = (state, action) => ({
//     ...state,
//     loading: false,
//     boxesError: action.payload,
// });

const getBoxMealsReducer = (state, action) => ({
    ...state,
    loading: true,
});

const getBoxMealsSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    mealList: action.payload,
});

const getBoxMealsFailReducer = (state, action) => ({
    ...state,
    loading: false,
    mealError: action.payload,
});

const postBoxMealReducer = (state) => ({
    ...state,
    loading: true,
});

const postBoxMealSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const postBoxMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

const putBoxMealReducer = (state) => ({
    ...state,
    loading: true,
});

const putBoxMealSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const putBoxMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

const deleteBoxMealReducer = (state) => ({
    ...state,
    loading: true,
});

const deleteBoxMealSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const deleteBoxMealFailReducer = (state) => ({
    ...state,
    loading: false,
});

//

const getBoxNamesSuccessReducer = (state, action) => ({
    ...state,
    loading: false,
    nameList: action.payload,
});

const getBoxNamesFailReducer = (state, action) => ({
    ...state,
    loading: false,
    nameError: action.payload,
});

const updateBoxesReducer = (state, action) => ({
    ...state,
    updateLoading: true,
});

const updateBoxesSuccessReducer = (state, action) => ({
    ...state,
    updateLoading: false,
    updateBox: action.payload,
});

const updateBoxesFailReducer = (state, action) => ({
    ...state,
    updateLoading: false,
    updateBoxError: action.payload,
});

const createBoxesReducer = (state, action) => ({
    ...state,
    createBoxLoading: true,
});

const createBoxesSuccessReducer = (state, action) => ({
    ...state,
    createBoxLoading: false,
    createBox: action.payload,
});

const createBoxesFailReducer = (state, action) => ({
    ...state,
    createBoxLoading: false,
    createBoxError: action.payload,
});

const reducer = (state = initialState, action) => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (action.type) {
        case actionTypes.GET_BOXES:
            return getBoxesReducer(state, action);
        case actionTypes.GET_BOXES_SUCCESS:
            return getBoxesSuccessReducer(state, action);
        case actionTypes.GET_BOXES_FAIL:
            return getBoxesFailReducer(state, action);
        case actionTypes.GET_BOX_CATEGORIES:
            return getBoxCategoriesReducer(state, action);
        case actionTypes.GET_BOX_CATEGORIES_SUCCESS:
            return getBoxCategoriesSuccessReducer(state, action);
        case actionTypes.GET_BOX_CATEGORIES_FAIL:
            return getBoxCategoriesFailReducer(state, action);
        case actionTypes.GET_BOX_TAGS:
            return getTagsReducer(state, action);
        case actionTypes.GET_BOX_TAGS_SUCCESS:
            return getTagsSuccessReducer(state, action);
        case actionTypes.GET_BOX_TAGS_FAIL:
            return getTagsFailReducer(state, action);
        case actionTypes.POST_BOX_TAGS:
            return postBoxTagsReducer(state);
        case actionTypes.POST_BOX_TAGS_SUCCESS:
            return postBoxTagsSuccessReducer(state);
        case actionTypes.POST_BOX_TAGS_FAIL:
            return postBoxTagsFailReducer(state);
        case actionTypes.GET_BOX_MEALS:
            return getBoxMealsReducer(state, action);
        case actionTypes.GET_BOX_MEALS_SUCCESS:
            return getBoxMealsSuccessReducer(state, action);
        case actionTypes.GET_BOX_MEALS_FAIL:
            return getBoxMealsFailReducer(state, action);
        case actionTypes.POST_BOX_MEAL:
            return postBoxMealReducer(state);
        case actionTypes.POST_BOX_MEAL_SUCCESS:
            return postBoxMealSuccessReducer(state);
        case actionTypes.POST_BOX_MEAL_FAIL:
            return postBoxMealFailReducer(state);
        case actionTypes.PUT_BOX_MEAL:
            return putBoxMealReducer(state);
        case actionTypes.PUT_BOX_MEAL_SUCCESS:
            return putBoxMealSuccessReducer(state);
        case actionTypes.PUT_BOX_MEAL_FAIL:
            return putBoxMealFailReducer(state);
        case actionTypes.DELETE_BOX_MEAL:
            return deleteBoxMealReducer(state);
        case actionTypes.DELETE_BOX_MEAL_SUCCESS:
            return deleteBoxMealSuccessReducer(state);
        case actionTypes.DELETE_BOX_MEAL_FAIL:
            return deleteBoxMealFailReducer(state);
        case actionTypes.GET_BOX_CATEGORIES_NAME:
            return getBoxCategoriesNamesReducer(state, action);
        case actionTypes.GET_BOX_CATEGORIES_NAME_FAIL:
            return getBoxCategoriesNamesFailReducer(state, action);
        case actionTypes.GET_BOX_CATEGORIES_NAME_SUCCESS:
            return getBoxCategoriesNamesSuccessReducer(state, action);
        case actionTypes.POST_BOX_CATEGORIES:
            return postBoxCategoriesReducer(state);
        case actionTypes.POST_BOX_CATEGORIES_SUCCESS:
            return postBoxCategoriesSuccessReducer(state);
        case actionTypes.POST_BOX_CATEGORIES_FAIL:
            return postBoxCategoriesFailReducer(state);
        case actionTypes.GET_BOX_NAMES:
            return getBoxNamesReducer(state, action);
        case actionTypes.GET_BOX_NAMES_SUCCESS:
            return getBoxNamesSuccessReducer(state, action);
        case actionTypes.GET_BOX_NAMES_FAIL:
            return getBoxNamesFailReducer(state, action);
        case actionTypes.UPDATE_BOXES:
            return updateBoxesReducer(state, action);
        case actionTypes.UPDATE_BOXES_SUCCESS:
            return updateBoxesSuccessReducer(state, action);
        case actionTypes.UPDATE_BOXES_FAIL:
            return updateBoxesFailReducer(state, action);
        case actionTypes.CREATE_BOXES:
            return createBoxesReducer(state, action);
        case actionTypes.CREATE_BOXES_SUCCESS:
            return createBoxesSuccessReducer(state, action);
        case actionTypes.CREATE_BOXES_FAIL:
            return createBoxesFailReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
