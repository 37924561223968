import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './presentation/themes/theme';
import Routes from './services/routes';
import './App.less';

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
    );
}
