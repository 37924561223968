/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CardStyles, { Content, Item, Select, Text, CardTitle, TimeLogo, TimeLabel, SaveButton } from './styles';
import BagImageDark from '../../../../../../Assets/Dashboard/bag-dark.png';
import BagImage from '../../../../../../Assets/Dashboard/bag.png';
import MotorcycleImage from '../../../../../../Assets/Dashboard/motorcycle.png';
import TimeIcon from '../../../../../../Assets/Dashboard/time-icon.png';
import { updatePackageInfo, getPackageInfo } from '../../../../../../services/Redux/Dashboard/package/api';
import DeliveryTimeModel from '../../../ControlPanelPage/OrderStatusCard/OrderStatusModal/index';

const DeliveryBoyInfo = ({ delivery, start, end, id }) => {
    const dispatch = useDispatch();
    const [deliveryType, setDeliveryType] = useState(delivery);
    const [deliveryStartTime, setDeliveryStartTime] = useState(start);
    const [deliveryEndTime, setDeliveryEndTime] = useState(end);
    const [showModal, setShowModal] = useState(false);
    const [showStroke, setShowStroke] = useState(false);

    const deliveryTypeToRender = [
        { id: 1, name: ' Gel-Al paket' },
        { id: 2, name: 'Motorlu kurye' },
        { id: 3, name: ' Gel-Al paket ve motorlu kurye' },
    ];

    useEffect(() => {
        if (delivery) {
            const filtered = deliveryTypeToRender.find((item) => item.id === parseInt(delivery));
            setDeliveryType(filtered.id);
        }
    }, [delivery]);

    const update = async (delivery_type, courier_delivery_time_start, courier_delivery_time_end) => {
        if (delivery_type === 1) await dispatch(updatePackageInfo({ delivery_type }, id));
        else await dispatch(updatePackageInfo({ delivery_type, courier_delivery_time_start, courier_delivery_time_end }, id));
        await dispatch(getPackageInfo(id));
    };

    const showDeliveryModel = () => {
        setShowModal(true);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const { Option } = Select;

    return (
        <CardStyles title={<CardTitle>Paket Teslimi Kurye Bilgisi</CardTitle>}>
            <Content>
                <Text weight="semi-bold">Paket teslimi:</Text>
                <Text color="smoke" family="secondary" style={{ marginTop: '16px' }}>
                    Müşterilere siparişlerini teslim edeceğiniz ulaşım türünü seçin
                </Text>
                <Item style={{ marginTop: '25px' }} span={24}>
                    {deliveryType === 1 && <img src={BagImage} alt="" />}
                    {deliveryType === 1 && <img src={MotorcycleImage} alt="" style={{ filter: `grayscale(100%)` }} />}
                    {deliveryType === 2 && <img src={BagImageDark} alt="" style={{ filter: `grayscale(100%)` }} />}
                    {deliveryType === 2 && <img src={MotorcycleImage} alt="" />}
                    {deliveryType === 3 && <img src={BagImage} alt="" />}
                    {deliveryType === 3 && <img src={MotorcycleImage} alt="" />}
                    <Select
                        defaultValue={deliveryType}
                        size="large"
                        value={deliveryType}
                        onChange={(option) => {
                            if (option === 1) setDeliveryType(deliveryTypeToRender[0].id);
                            else if (option === 2) setDeliveryType(deliveryTypeToRender[1].id);
                            else if (option === 3) setDeliveryType(deliveryTypeToRender[2].id);
                        }}>
                        {deliveryTypeToRender.map((value) => (
                            <Option value={value.id}>
                                <Text family="secondary" color="smoke">
                                    {value.name}
                                </Text>
                            </Option>
                        ))}
                    </Select>
                </Item>
                {deliveryType === 1 && (
                    <Item span={10} style={{ marginTop: '28px', alignSelf: 'flex-end', width: '100%' }}>
                        <SaveButton type="secondary" onClick={() => update(deliveryType)}>
                            Kaydet
                        </SaveButton>
                    </Item>
                )}

                {deliveryType !== 1 && (
                    <>
                        <Item style={{ marginTop: '38px' }}>
                            <Item span={24}>
                                <TimeLogo src={TimeIcon} />
                                <Text>Kurye teslim saatleri:</Text>
                            </Item>
                        </Item>
                        <Item style={{ marginTop: '28px' }}>
                            <Item span={14}>
                                <TimeLabel onClick={showDeliveryModel} style={{ border: showStroke ? '2px solid #F38262' : 'none' }}>
                                    <Text size="17px" color="yellow" weight="bold" family="secondary">
                                        {deliveryStartTime && deliveryEndTime
                                            ? `${deliveryStartTime?.replace('.', ':')} - ${deliveryEndTime?.replace('.', ':')}`
                                            : '00:00 - 00:00'}
                                    </Text>
                                </TimeLabel>
                            </Item>
                            <Item span={10}>
                                <SaveButton
                                    type="secondary"
                                    onClick={() => {
                                        if (
                                            deliveryType !== 1 &&
                                            (deliveryStartTime?.replace('.', ':') === '00:00' || deliveryEndTime?.replace('.', ':') === '00:00')
                                        ) {
                                            setShowStroke(true);
                                        } else {
                                            setShowStroke(false);
                                            update(deliveryType, deliveryStartTime?.replace(':', '.'), deliveryEndTime?.replace(':', '.'));
                                        }
                                    }}>
                                    Kaydet
                                </SaveButton>
                            </Item>
                        </Item>
                        <Item>
                            <Text color="smoke" family="secondary" style={{ marginTop: '16px' }}>
                                Kurye teslim saatlerinde yapacağınız değişiklikler yarın işleme alınacaktır.
                            </Text>
                        </Item>
                    </>
                )}
            </Content>
            {deliveryType !== 1 && (
                <DeliveryTimeModel
                    dismiss={handleCancel}
                    onCancel={handleCancel}
                    deliveryTimeStart={deliveryStartTime}
                    setDeliveryStartTime={setDeliveryStartTime}
                    deliveryTimeEnd={deliveryEndTime}
                    setDeliveryEndTime={setDeliveryEndTime}
                    visible={showModal}
                    id={id}
                />
            )}
        </CardStyles>
    );
};

export default DeliveryBoyInfo;
