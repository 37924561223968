// PATCH USER

export const PATCH_USER = 'PATCH_USER';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_FAIL = 'PATCH_USER_FAIL';

// GET USER

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

// PATCH PASSWORD

export const PATCH_PASSWORD = 'PATCH_PASSWORD';
export const PATCH_PASSWORD_SUCCESS = 'PATCH_PASSWORD_SUCCESS';
export const PATCH_PASSWORD_FAIL = 'PATCH_PASSWORD_FAIL';

// RESET PASSWORD

export const POST_PASSWORD_RESET = 'POST_PASSWORD_RESET';
export const POST_PASSWORD_RESET_SUCCESS = 'POST_PASSWORD_RESET_SUCCESS';
export const POST_PASSWORD_RESET_FAIL = 'POST_PASSWORD_RESET_FAIL';

// SET PASSWORD

export const POST_PASSWORD_SET = 'POST_PASSWORD_SET';
export const POST_PASSWORD_SET_SUCCESS = 'POST_PASSWORD_SET_SUCCESS';
export const POST_PASSWORD_SET_FAIL = 'POST_PASSWORD_SET_FAIL';

// POST ADDRESS

export const POST_ADDRESS = 'POST_ADDRESS';
export const POST_ADDRESS_SUCCESS = 'POST_ADDRESS_SUCCESS';
export const POST_ADDRESS_FAIL = 'POST_ADDRESS_FAIL';

// PUT ADDRESS

export const PUT_ADDRESS = 'PUT_ADDRESS';
export const PUT_ADDRESS_SUCCESS = 'PUT_ADDRESS_SUCCESS';
export const PUT_ADDRESS_FAIL = 'PUT_ADDRESS_FAIL';

// GET ADDRESS

export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAIL = 'GET_ADDRESS_FAIL';

// GET ACTIVE ADDRESS

export const GET_ADDRESS_ACTIVE = 'GET_ADDRESS_ACTIVE';
export const GET_ADDRESS_ACTIVE_SUCCESS = 'GET_ADDRESS_ACTIVE_SUCCESS';
export const GET_ADDRESS_ACTIVE_FAIL = 'GET_ADDRESS_ACTIVE_FAIL';

// POST ACTIVE ADDRESS

export const POST_ADDRESS_ACTIVE = 'POST_ADDRESS_ACTIVE';
export const POST_ADDRESS_ACTIVE_SUCCESS = 'POST_ADDRESS_ACTIVE_SUCCESS';
export const POST_ADDRESS_ACTIVE_FAIL = 'POST_ADDRESS_ACTIVE_FAIL';

// DELETE ADDRESS

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

// GET ORDERS

export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAIL = 'GET_ORDER_HISTORY_FAIL';

// DELETE ORDER

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

// COMPLETE ORDER

export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const COMPLETE_ORDER_SUCCESS = 'COMPLETE_ORDER_SUCCESS';
export const COMPLETE_ORDER_FAIL = 'COMPLETE_ORDER_FAIL';

// RESET DELETE ORDER ERROR

export const RESET_DELETE_ORDER_ERROR = 'RESET_DELETE_ORDER_ERROR';
