import * as actionTypes from './actionTypes';

export const getOrderInfoAction = () => ({
    type: actionTypes.GET_ORDERS,
});

export const getOrderInfoSuccessAction = (info) => ({
    type: actionTypes.GET_ORDERS_SUCCESS,
    payload: info,
});

export const getOrderInfoFailAction = () => ({
    type: actionTypes.GET_ORDERS_FAIL,
});
