import styled from 'styled-components';
import { Row } from 'antd';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const AboutStyles = styled(Row)`
    margin-top: 5%;
    width: 100%;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    padding-bottom: ${VERTICAL_PADDING};
    padding-top: ${VERTICAL_PADDING};

    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 80%;
`;

export const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AboutTitle = styled.div``;
