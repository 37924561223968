import React from 'react';
import { CookieWrapper, CookieTitle, CookiePolicyStyle } from './styles';
import { Title, Paragraph } from '../../../../../components/Typography/styles';
import useMediaQuery from '../../../../../../helpers/Hooks/useMediaQuery';
import bg from '../../../../../../Assets/Shared/AboutUs/aboutUs.svg';

const CookiesSection = () => {
    const { xs, sm } = useMediaQuery();

    return (
        <CookiePolicyStyle backgroundImage={sm ? '' : bg}>
            <CookieWrapper style={{ width: sm ? '100%' : '70%' }}>
                <CookieTitle style={{ paddingTop: xs ? '25%' : '10%' }}>
                    <Title style={{ textAlign: sm && 'center' }} weight="bold" color="#F38262" size="mediumTitle">
                        Çerez Politikası
                    </Title>
                </CookieTitle>
                <Paragraph style={{ textAlign: sm && 'center' }} color="#000" weight="light" size="description">
                    <p>
                        <span data-contrast="auto">
                            Çerezler (cookies) ziyaret ettiğiniz internet sitesinin adresini, çerezin yetkilerinin ve etkilerinin süresini ve rasgele
                            bir sayıyı içerirler, internet sitelerimizi ziyaret ettiğinizde cihazınıza indirilirler. Sizlere daha iyi, hızlı ve
                            güvenli bir deneyim sağlamak için, çoğu internet sitesi gibi Şirketimiz de çerez kullanmaktadır. Bu teknolojilerin
                            kullanımı başta 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) olmak üzere tabi olduğumuz mevzuata uygun şekilde
                            gerçekleştirilmektedir.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Çerez politikamız Döngü Elektronik İletişim Perakende Gıda Lojistik Anonim Şirketi’nin içerik sağlayıcısı olduğu internet
                            siteleri için geçerlidir.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Çerezlerimiz, internet sitelerimizi kullanmanız sırasında size kişiselleştirilmiş bir deneyim sunmak, hizmetlerimizi
                            geliştirmek ve deneyiminizi iyileştirmek için kullanılır.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">Çerez türleri şu şekildedir:</span>
                    </p>
                    <ul>
                        <li>
                            <span data-contrast="auto">
                                Oturum çerezleri: Geçiçi çerezlerdir, tarayıcınızı kapattığınızda silinirler, yani siz tarayıcınızı kapatıp sitemize
                                tekrar geldiğinizde silinir, kalıcı değillerdir. Sitelerimizin ve sizin, ziyaretinizde güvenliğini, sürekliliğini
                                sağlamak gibi amaçlarla kullanılırlar.
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Kalıcı çerezler: Sizin bir hareketinizle ya da çerezin içerisindeki süre aralığı sona erdiği için silinene kadar
                                tarayıcınızın alt klasörlerinde tutulurlar. Bu çerezler sizin bilgilerinizi ve seçimlerinizi bir sonraki ziyaretinizde
                                internet sitemizin hatırlamasına yardımcı olurlar.
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Birinci ve üçüncü kişi çerezler: Birinci kişi çerezler sitemiz tarafından kullanılan çerezlerdir. Üçüncü kişi çerezler
                                ise sitemiz haricinde bilgisayarınıza kurulan çerezlerdir.
                            </span>
                        </li>
                    </ul>
                    <p>
                        <span data-contrast="auto">Bu ana çerez türleri içerisinde ise şu çerez türleri bulunmaktadır:</span>
                    </p>
                    <ul>
                        <li>
                            <span data-contrast="auto">
                                Zorunlu çerezler: Şirket internet sitelerinin düzgün şekilde çalışabilmesi ve özelliklerinin düzgün şekilde
                                sunulabilmesi amacıyla kurulan anonim nitelikte çerezlerdir.
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                İşlevsel ve analitik çerezler: Tercihlerinizin hatırlanması için ve gezinme deneyiminizi geliştirmemize yarayan
                                işlevsel çerezler ve hangi sayfalarımızın daha fazla ilgi çektiğini, hangi kaynakların daha çok görüntülendiğini
                                görmemize yarayan, sitelerimizdeki trafiği görerek bu trafiğe uygun hizmet sağlamamızı sağlayan analitik çerezler
                                kullanılmaktadır. Bu nitelikte çerezler de anonimdir.
                            </span>
                        </li>
                    </ul>
                    <p>
                        <span data-contrast="auto">Çerez toplamamızdaki temel amaçlar şu şekildedir: </span>
                    </p>
                    <ul>
                        <li>
                            <span data-contrast="auto">
                                Platform’un çalışması için gerekli temel fonksiyonları gerçekleştirmek: Örneğin, Şirket internet sitesi üyelerinin,
                                ürün sepetindeki ürünlerin ziyaretleri süresince kaybolmaması. Oturum açan üyelerin Şirket internet sitesinde farklı
                                sayfaları ziyaret ederken tekrar şifre girmelerine gerek kalmaması.
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket internet sitesini analiz etmek ve Şirket internet sitesinin performansını arttırmak: Örneğin, Şirket internet
                                sitesinin üzerinde çalıştığı farklı sunucuların entegrasyonu, Şirket internet sitesini ziyaret edenlerin sayısının
                                tespit edilmesi ve buna göre performans ayarlarının yapılması ya da ziyaretçilerin aradıklarını bulmalarının
                                kolaylaştırılması.
                            </span>
                        </li>
                        <li>
                            <span data-contrast="auto">
                                Şirket internet sitesinin işlevselliğini arttırmak ve kullanım kolaylığı sağlamak: Örneğin, Platform üzerinden üçüncü
                                taraf sosyal medya mecralarına paylaşımda bulunmak, Platform’u ziyaret eden ziyaretçinin daha sonraki ziyaretinde
                                kullanıcı adı bilgisinin ya da arama sorgularının hatırlanması. • Kişiselleştirme, hedefleme ve reklamcılık faaliyeti
                                gerçekleştirmek: Örneğin, ziyaretçilerin görüntüledikleri sayfa ve ürünler üzerinden ziyaretçilerin ilgi alanlarıyla
                                bağlantılı reklam gösterilmesi.
                            </span>
                        </li>
                    </ul>

                    <p>
                        <span data-contrast="auto">
                            Çerezler konusunda daha detaylı bilgi için www.aboutcookies.org ve www.allaboutcookies.org adreslerini ziyaret
                            edebilirsiniz.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Çoğu tarayıcı, çerezleri otomatik olarak kabul eder. Fakat isterseniz çerezleri tarayıcınızın ayarlarını değiştirerek
                            reddedebilirsiniz. Çerezleri reddettiğiniz takdirde sitelerimizdeki bazı özelliklerin ve hizmetlerin düzgün
                            çalışamayabileceğini, sitelerimizin kişiselleştirilemeyebileceğini ve sizin deneyiminize göre özelleştirilemeyeceğini
                            lütfen unutmayınız.
                        </span>
                    </p>
                    <p>
                        <span data-contrast="auto">
                            Tarayıcı üreticileri, kendi ürünlerinde çerezlerin yönetimi ile ilgili yardım sayfaları sunmaktadır. Daha fazla bilgi
                            için, tarayıcınıza göre aşağıdaki linklerden yararlanabilirsiniz.
                        </span>
                    </p>
                    <p>
                        <a href="https://support.google.com/chrome/answer/95647">Google Chrome</a>
                    </p>
                    <p>
                        <a href="https://support.microsoft.com/tr-tr/microsoft-edge/microsoft-edge-de-%C3%A7erezler-ve-site-verilerine-ge%C3%A7ici-olarak-izin-verme-597f04f2-c0ce-f08c-7c2b-541086362bd2">
                            Microsoft Edge
                        </a>
                    </p>
                    <p>
                        <a href="https://support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma">Mozilla Firefox</a>
                    </p>
                    <p>
                        <a href="https://support.apple.com/tr-tr/guide/safari/sfri11471/mac">Safari</a>
                    </p>
                    <p>
                        <a href="https://www.operaturkiye.net/cerezleri-nasil-silebilirim/index.html">Opera</a>
                    </p>
                </Paragraph>
            </CookieWrapper>
        </CookiePolicyStyle>
    );
};

export default CookiesSection;
