import * as actions from './actions';

export const fetchCategories = () => (dispatch, state, service) => {
    dispatch(actions.getCategoriesStartAction(dispatch, state, service));
    service({
        path: 'helpcenter/sub-category/',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getCategoriesSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getCategoriesFailAction(error));
        });
};

export const fetchFaqs = () => (dispatch, state, service) => {
    dispatch(actions.getFaqsStartAction(dispatch, state, service));
    service({
        path: 'helpcenter/faq/',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getFaqsSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getFaqsFailAction(error));
        });
};
