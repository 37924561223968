import { Row, Select } from 'antd';
import styled from 'styled-components';
import { HORIZONTAL_PADDING } from '../../../../../../../helpers/constants';

export const HeaderDiv = styled.div`
    z-index: 1000;
    overflow: hidden;
    height: ${({ theme }) => theme.layoutStyles.headerHeight};
    background: ${({ theme }) => theme.layoutStyles.headerColor};
    padding: ${({ theme }) => theme.layoutStyles.headerPaddingVertical} ${HORIZONTAL_PADDING};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    -moz-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        border: none !important;
        color: black !important;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border: none !important;
        color: black !important;
    }

    .ant-menu-horizontal {
        border: none;
        color: black !important;
    }

    .dispose {
        display: none;
        color: black !important;
    }

    .guest-icon-styles {
        :hover {
            cursor: pointer;
        }
    }

    .ant-menu {
        align-items: middle !important;
        justify-content: flex-end !important;
    }
`;

export const StyledRow = styled(Row)`
    min-height: 56px;
    padding: 10px 16px;
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    background-color: #ffffff;
    border: 2px solid #f2f2fc;
`;

export const StyledButton = styled.button`
    background-color: #8fd89f;
    width: 100%;
    height: 48px;
    text-align: center;
    border-radius: 8px;
    border: none;
`;

export const StyledInput = styled.input`
    background-color: transparent;
    border: none;
    width: 100%;
    overflow: hidden;
    color: #424242;
    font-size: 16px;
    font-weight: 500;
`;

export const CustomSelect = styled(Select)`
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none !important;

    & > .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        color: #424242;
        font-size: 16px;
        font-weight: 500;
    }

    & > div {
        height: 48px !important;
        border-radius: 4px !important;

        & > span {
            line-height: 48px !important;
            font-size: 14px !important;
            font-family: Montserrat;
        }

        & > CustomOption {
            color: black;
            background-color: gray;
        }
    }
`;

export const CustomOption = styled(Select.Option)`
    bacground-color: transparent !important;
    border: none !important;

    .ant-select-item-option {
        border: none !important;
        width: 100%;
    }
`;
