import styled from 'styled-components';
import { Row } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.time-row {
        margin-top: 10px;
        height: 48px;
        background: #d2efd8;
        border-radius: 5px;
        cursor: pointer;
    }
    &.selected {
        background: #7dd190;
    }
    &.unselected {
        background: #f5f9fc;
        transition: 0.3s;
        :hover {
            background: #d2efd8;
        }
    }

    &.onemli-row {
        background: #f5f9fc;
        height: 69px;
        margin-top: 50px;
        padding-left: 20px;
        border-radius: 4px;
    }

    &.unavailable {
        cursor: not-allowed;
        background: #dddddd;
    }
`;
