import { Col } from 'antd';
import styled from 'styled-components';

export const CurrentDataCompWrapper = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const CurrentDataCircle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #f69f8e;

    width: 240px;
    height: 240px;
    border-radius: 120px;
    padding: 20px;
    margin-bottom: 30px;
`;

export const CurrentDataCompTitle = styled.div``;

export const CurrentDataCompText = styled.div``;

export const CurrentDataCompContent = styled.div`
    max-width: 320px;
    height: 150px;
    text-align: center;
`;
