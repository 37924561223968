/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../../../../components/Typography/styles';
import { ReactComponent as Star } from '../../../../../../../../Assets/Shared/FindRestaurant/star.svg';
import { ReactComponent as Time } from '../../../../../../../../Assets/Shared/FindRestaurant/time.svg';
import PacketGreen from '../../../../../../../../Assets/Shared/CartPage/green-packet.svg';
import MotorGreen from '../../../../../../../../Assets/Shared/CartPage/green-motor.svg';
import MotorWhite from '../../../../../../../../Assets/Shared/FindRestaurant/motorwhite.svg';
import { ReactComponent as ForwardBtn } from '../../../../../../../../Assets/Shared/CartPage/forward-arrow.svg';
import Spinner from '../../../../../../../components/Spinner/index';
import useResponsive from '../../../../../../../../helpers/Hooks/useMediaQuery';

const RestaurantCard = ({ resp }) => {
    const history = useHistory();
    const basket = resp.boxes;

    const { storeData } = useSelector((state) => state.storeStore || {});

    const startDate = new Date(basket[0].sale_day.start_date);
    const endDate = new Date(basket[0].sale_day.end_date);
    const { lg } = useResponsive();

    return (
        <CustomRow>
            {basket ? (
                <>
                    <CustomRow align="middle" className="card-row">
                        <Col span={3}>
                            <CustomCol span={21}>
                                <img src={basket[0].store.photo} alt="Logo" width="100%" />
                            </CustomCol>
                        </Col>
                        <Col span={10}>
                            <CustomRow align="middle" className="name-row">
                                <Text weight="semi-bold">{basket[0].store.name}&nbsp;&nbsp;</Text>
                                <Star />
                                {basket[0].store.review && basket[0].store.review.length >= 5 ? (
                                    <Text weight="light">&nbsp;{Number(basket[0].store.avg_review).toFixed(2)}</Text>
                                ) : (
                                    <Text weight="light">&nbsp;5</Text>
                                )}
                            </CustomRow>
                            <CustomRow className="name-row">
                                <Text weight="light" color="smoke">
                                    {basket[0].store.address}
                                </Text>
                            </CustomRow>
                        </Col>
                        <Col span={5}>
                            <CustomRow align="middle" className="time-row">
                                <Time />
                                <Text color="yellow">
                                    &nbsp;
                                    {startDate.getHours()}:{startDate.getMinutes() === 0 ? <>00</> : startDate.getMinutes()} -&nbsp;
                                    {endDate.getHours()}:{endDate.getMinutes() === 0 ? <>00</> : endDate.getMinutes()}
                                </Text>
                            </CustomRow>
                        </Col>
                        <Col span={5}>
                            {storeData?.package_settings?.delivery_type === '1' ? (
                                <Row justify="center">
                                    <img src={PacketGreen} alt="packet-icon" width={lg ? '50px' : '58px'} />
                                    &nbsp;&nbsp;
                                    <img src={MotorWhite} alt="packet-icon" width={lg ? '50px' : '58px'} />
                                </Row>
                            ) : (
                                <Row justify="center">
                                    <img src={PacketGreen} alt="packet-icon" width={lg ? '50px' : '58px'} />
                                    &nbsp;&nbsp;
                                    <img src={MotorGreen} alt="packet-icon" width={lg ? '50px' : '58px'} />
                                </Row>
                            )}
                        </Col>
                        <Col span={1}>
                            <Row>
                                <ForwardBtn onClick={() => history.push(`restoran-detay/${basket[0].store.id}`)} />
                            </Row>
                        </Col>
                    </CustomRow>
                </>
            ) : (
                <CustomRow>
                    <Spinner />
                </CustomRow>
            )}
        </CustomRow>
    );
};

export default RestaurantCard;
