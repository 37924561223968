/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useMemo, useEffect, useCallback, useState } from 'react';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ProfileSectionStyles, LeftSide, RightSide, UserIconHolder, RestaurantIconHolder, MenuStyles, CustomButton } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import profileLogo from '../../../../../../../Assets/Dashboard/profile-logo.png';
import restaurantLogo from '../../../../../../../Assets/Dashboard/restaurant-logo.png';
import { authLogOutAction } from '../../../../../../../services/Redux/auth/actions';
import openNotification from '../../../../../../components/Notification';
import notificationAudio from '../../../../../../../Assets/Dashboard/notificationAudio/notification.mp3';

const Menu = () => {
    const dispatch = useDispatch();
    const logout = useCallback(() => {
        dispatch(authLogOutAction());
        window.location.reload();
    }, [dispatch]);

    const history = useHistory();

    const directToNotifications = () => {
        history.push('/bildirimlerim');
    };

    const WEBSOCKET_ENDPOINT = 'wss://dongu.api.westerops.com/websocket';
    const socketConnection = new WebSocket(WEBSOCKET_ENDPOINT);
    const TOKEN = localStorage.getItem('token');

    socketConnection.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data.object) {
            let temp = data.object;
            temp = temp?.replaceAll("'", '"');
            temp = temp?.replaceAll('False', 'false');
            temp = JSON.parse(temp);
            if (temp.type === 7) {
                openNotification(temp.description, temp.message.split('order_id')[0], directToNotifications, false);
                new Audio(notificationAudio).play();
            } else {
                openNotification(temp.description, temp.message, directToNotifications, false);
                new Audio(notificationAudio).play();
            }
        }
    };

    socketConnection.onopen = function () {
        console.log('Socket connection is established');
    };

    socketConnection.onclose = function () {
        console.error('Socket connection is closed unexpectedly');
    };

    function subscribe(group, thisToken = null) {
        socketConnection.send(
            JSON.stringify({
                type: 'subscribe',
                token: thisToken,
                content: group,
            })
        );
    }

    function unsubscribe(group) {
        socketConnection.send(
            JSON.stringify({
                type: 'unsubscribe',
                content: group,
            })
        );
    }

    function waitConnection() {
        // if (socketConnection.readyState === WebSocket.OPEN && localStorage.getItem('notifications') === 'true') {
        if (socketConnection.readyState === WebSocket.OPEN) {
            subscribe('notification', TOKEN);
            subscribe('store_orders', TOKEN);
            subscribe('store_notifications', TOKEN);
        } else if (socketConnection.readyState !== WebSocket.CLOSED && socketConnection.readyState !== WebSocket.CLOSING) {
            setTimeout(waitConnection, 500);
            console.log(socketConnection.readyState === WebSocket.OPEN);
        }
    }

    const menuItems = useMemo(
        () => [
            {
                name: 'Bildirimler',
                route: '/dashboard/notification',
            },
            {
                name: 'Çıkış yap',
                handler: logout,
            },
        ],
        [logout]
    );

    useEffect(() => {
        waitConnection();
        return () => unsubscribe();
    }, []);

    return (
        <MenuStyles>
            {menuItems.map(({ name, route, handler }) => (
                <CustomButton key={name} type="text" onClick={() => (route ? history.push(route) : handler && handler())}>
                    <Text color="smoke" weight="light" size="16px">
                        {name}
                    </Text>
                </CustomButton>
            ))}
        </MenuStyles>
    );
};

const Profile = () => {
    const USER = JSON.parse(localStorage.getItem('user'));

    const [showPopover, setShowPopover] = useState(false);
    const togglePopover = () => setShowPopover(!showPopover);
    return (
        <ProfileSectionStyles align="middle">
            <LeftSide span={19}>
                <RestaurantIconHolder>
                    <img className="cover-image" src={restaurantLogo} alt="" />
                </RestaurantIconHolder>
                <Text family="primary" color="green" size="noble-regular" weight="bold">
                    {USER.stores.name || ''}
                </Text>
            </LeftSide>
            <RightSide span={5} align="middle" onClick={togglePopover}>
                <Popover
                    overlayStyle={{ position: 'fixed' }}
                    overlayInnerStyle={{ borderRadius: 18 }}
                    placement="bottom"
                    visible={showPopover}
                    content={Menu}
                    trigger="click">
                    <UserIconHolder>
                        <img className="cover-image" src={profileLogo} alt="" />
                    </UserIconHolder>
                </Popover>
            </RightSide>
        </ProfileSectionStyles>
    );
};

export default Profile;
