/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../components/Typography/styles';
import AllCard from './AllCard';
import { fetchNotifications } from '../../../../../services/Redux/Shared/notifications/api';
import { TabArrow } from '../../../Shared/ProfilePage/Content/styles';
import Spinner from '../../../../components/Spinner/index';

const Content = () => {
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector;

    useEffect(() => {
        dispatch(fetchNotifications());
    }, []);

    const notifications = selector((state) => state.notificationStore.notificationList);

    return (
        <CustomRow>
            <CustomCol span={5}>
                <CustomRow className="tab-btn-row" align="middle" justify="space-between">
                    <Text weight="light">Tümü</Text>
                    <TabArrow />
                </CustomRow>
            </CustomCol>

            {notifications ? (
                <CustomCol span={19}>
                    <CustomRow>
                        <AllCard />
                    </CustomRow>
                </CustomCol>
            ) : (
                <Spinner />
            )}
        </CustomRow>
    );
};

export default Content;
