/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col } from 'antd';
import { BadgesCardStyles, Content, BadgeStyles, ShareSection, CustomButton, ButtonWrapper } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import oneMonthBadge from '../../../../../../Assets/Dashboard/1month-badge.png';
import threeMonthBadge from '../../../../../../Assets/Dashboard/3month-badge.png';
import sixMonthBadge from '../../../../../../Assets/Dashboard/6month-badge.png';
import oneYearBadge from '../../../../../../Assets/Dashboard/1year-badge.png';
import twoYearBadge from '../../../../../../Assets/Dashboard/2year-badge.png';
import facebookIcon from '../../../../../../Assets/Dashboard/facebook-icon.png';
import twitterIcon from '../../../../../../Assets/Dashboard/twitter-icon.png';
import linkedinIcon from '../../../../../../Assets/Dashboard/linkedin-icon.png';
import instagramIcon from '../../../../../../Assets/Dashboard/instagram-icon.png';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const Badge = ({ icon, id, soldFoods }) => (
    <BadgeStyles span={4}>
        <Row justify="center">
            <img src={icon} alt="" srcSet="" />
        </Row>
        <Row justify="center">
            <Text color="green" size="30px" weight="bold" style={{ marginTop: '5px' }}>
                {soldFoods}
            </Text>
        </Row>
        <Row justify="center">
            <Text size="14px" family="secondary" style={{ textAlign: 'center', marginTop: '5px' }}>
                Kurtarılan Yemek
            </Text>
        </Row>
    </BadgeStyles>
);

const ShareButton = ({ id, icon }) => (
    <CustomButton type="secondary">
        <Text>
            <img src={icon} alt="" srcSet="" />
        </Text>
        <Text color="#ffffff" weight="semi-bold" style={{ marginLeft: '12px' }}>
            Paylaş
        </Text>
    </CustomButton>
);
const BadgesCard = () => {
    const dummyDataBadges = [
        {
            id: 1,
            icon: oneMonthBadge,
            soldFoods: 300,
        },
        {
            id: 1,
            icon: threeMonthBadge,
            soldFoods: 900,
        },
        {
            id: 1,
            icon: sixMonthBadge,
            soldFoods: 1800,
        },
        {
            id: 1,
            icon: oneYearBadge,
            soldFoods: 3600,
        },
        {
            id: 1,
            icon: twoYearBadge,
            soldFoods: 7200,
        },
    ];

    const dummyDataShare = [
        {
            id: 1,
            icon: facebookIcon,
            href: 'https://www.facebook.com/dongu.tr',
        },
        {
            id: 1,
            icon: twitterIcon,
            href: 'https://twitter.com/dongutr',
        },
        {
            id: 1,
            icon: instagramIcon,
            href: 'https://www.instagram.com/dongu.tr/',
        },
        {
            id: 1,
            icon: linkedinIcon,
            href: 'https://www.linkedin.com/company/dongutr/',
        },
    ];

    return (
        <BadgesCardStyles title={<Title text="Birlikteliğimiz" />}>
            <Content>
                <Row align="middle" justify="space-between">
                    {dummyDataBadges.map(({ id, icon, soldFoods }) => (
                        <Badge icon={icon} id={id} soldFoods={soldFoods} />
                    ))}
                </Row>
                <Row style={{ marginTop: '24px' }} align="middle" justify="center">
                    <Text style={{ textAlign: 'center' }} family="secondary" color="smoke">
                        Bu sayfada ulaştığınız tüm kilometre taşlarını topladık. Onları sosyal medyada paylaşarak kutlayın ve dünyaya bir fark
                        yaratmaya başladığınızı söyleyin.
                    </Text>
                </Row>
                <ShareSection align="middle" justify="center">
                    <Col span={24}>
                        <Row align="middle" justify="center">
                            <Text size="title" weight="semi-bold">
                                Birlikteliğimizi Paylaş!
                            </Text>
                        </Row>
                        <Row align="middle" justify="center" style={{ marginTop: '14px' }}>
                            <Text family="secondary">Birlikteliğimizi Paylaş, herkese gıda israfına karşı olduğunu göster.</Text>
                        </Row>
                        <ButtonWrapper align="middle" justify="space-between">
                            {dummyDataShare.map(({ id, icon, href }) => (
                                <a href={href} target="_blank" rel="noreferrer">
                                    <ShareButton icon={icon} id={id} />
                                </a>
                            ))}
                        </ButtonWrapper>
                    </Col>
                </ShareSection>
            </Content>
        </BadgesCardStyles>
    );
};

export default BadgesCard;
