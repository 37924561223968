/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import * as actions from './actions';
import { patchStoreTime } from '../store/api';

export const getUser = (id) => (dispatch, state, service) => {
    dispatch(actions.getUserStartAction(dispatch, state, service));
    service({
        path: `user/${id}/`,
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getUserSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getUserFailAction(error));
        });
};

export const patchUser = (newInfo, id) => (dispatch, state, service) => {
    dispatch(actions.patchUserStartAction(dispatch, state, service));
    service({
        path: `user/${id}/`,
        method: 'PATCH',
        body: newInfo,
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.patchUserSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.patchUserFailAction(error));
        });
};

export const patchPassword = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.patchPasswordStartAction(dispatch, state, service));
    service({
        path: `user/change-password/`,
        method: 'PATCH',
        body: newInfo,
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.patchPasswordSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.patchPasswordFailAction(error));
        });
};

export const postPasswordReset = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postPasswordResetStartAction(dispatch, state, service));
    service({
        path: `user/reset-password/`,
        method: 'POST',
        body: newInfo,
    })
        .then((data) => {
            dispatch(actions.postPasswordResetSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postPasswordResetFailAction(error));
        });
};

export const postPasswordSet = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postPasswordSetStartAction(dispatch, state, service));
    service({
        path: `user/reset-password-complete/`,
        method: 'PATCH',
        body: newInfo,
    })
        .then((data) => {
            dispatch(actions.postPasswordSetSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postPasswordSetFailAction(error));
        });
};

export const postAddress = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postAddressStartAction(dispatch, state, service));
    service({
        path: `user/address/`,
        method: 'POST',
        body: newInfo,
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.postAddressSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postAddressFailAction(error));
        });
};

export const putAddress = (newInfo, id) => (dispatch, state, service) => {
    dispatch(actions.putAddressStartAction(dispatch, state, service));
    service({
        path: `user/address/${id}/`,
        method: 'PUT',
        body: newInfo,
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.putAddressSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.putAddressFailAction(error));
        });
};

export const getAddress = () => (dispatch, state, service) => {
    dispatch(actions.getAddressStartAction(dispatch, state, service));
    service({
        path: `user/address/`,
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getAddressSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getAddressFailAction(error));
        });
};

export const getAddressActive = () => (dispatch, state, service) => {
    dispatch(actions.getAddressActiveStartAction(dispatch, state, service));
    service({
        path: `user/address/active_address/`,
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getAddressActiveSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getAddressActiveFailAction(error));
        });
};

export const postAddressActive = (newInfo) => (dispatch, state, service) => {
    dispatch(actions.postAddressActiveStartAction(dispatch, state, service));
    service({
        path: `user/address/change_address/`,
        method: 'POST',
        sendToken: true,
        body: newInfo,
    })
        .then((data) => {
            dispatch(actions.postAddressActiveSuccessAction({ ...data, id: newInfo.active_address }));
        })
        .catch((error) => {
            dispatch(actions.postAddressActiveFailAction(error));
        });
};

export const deleteAddress = (id) => async (dispatch, state, service) => {
    dispatch(actions.deleteAddressStartAction(dispatch, state, service));
    await service({
        path: `user/address/${id}/`,
        method: 'DELETE',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.deleteAddressSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.deleteAddressFailAction(error));
        });
};

export const getOrderHistory = () => async (dispatch, state, service) => {
    dispatch(actions.getOrderHistoryStartAction(dispatch, state, service));
    await service({
        path: `order/`,
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getOrderHistorySuccessAction(data.results));
        })
        .catch((error) => {
            dispatch(actions.getOrderHistoryFailAction(error));
        });
};

export const deleteOrder = (id, timeInt, delivery) => (dispatch, state, service) => {
    dispatch(actions.deleteOrderStartAction(dispatch, state, service));
    return service({
        path: `order/${id}/`,
        method: 'DELETE',
        sendToken: true,
    })
        .then((data) => {
            if (delivery === '2') {
                const info = {
                    is_available: true,
                };
                dispatch(patchStoreTime(info, timeInt));
            }
            dispatch(actions.deleteOrderSuccessAction(data));
        })
        .then(() => dispatch(getOrderHistory()))
        .catch((error) => {
            dispatch(actions.deleteOrderFailAction(error));
            return Promise.reject(error);
        });
};

export const completeOrder = (id, timeInt, delivery) => (dispatch, state, service) => {
    dispatch(actions.completeOrderStartAction(dispatch, state, service));
    return service({
        path: `order/${id}/`,
        method: 'PATCH',
        sendToken: true,
    })
        .then((data) => {
            if (delivery === '2') {
                const info = {
                    is_available: true,
                    delivery: '6',
                };
                dispatch(patchStoreTime(info, timeInt));
            }
            dispatch(actions.completeOrderSuccessAction(data));
        })
        .then(() => dispatch(getOrderHistory()))
        .catch((error) => {
            dispatch(actions.completeOrderFailAction(error));
            return Promise.reject(error);
        });
};

// RESET DELETE ORDER

export const resetDeleteOrderError = () => (dispatch, state, service) => {
    dispatch(actions.resetDeleteOrderError());
};
