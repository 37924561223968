/* eslint-disable no-console */
/* eslint-disable sonarjs/no-identical-functions */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { CustomRow, CustomButton, CustomContainer } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import InformationCard from './InformationCard';
import PasswordCard from './PasswordCard';
import Spinner from '../../../../../components/Spinner';
import { ReactComponent as Facebook } from '../../../../../../Assets/Shared/Profile/facebook-icon.svg';
import { connectFacebook, connectGoogle, disconnetGoogle } from '../../../../../../services/Redux/auth/api';
import { ReactComponent as Google } from '../../../../../../Assets/Shared/common/google.svg';
import { getUser } from '../../../../../../services/Redux/Shared/profile/api';

const InformationPane = () => {
    const dispatch = useDispatch();
    const selector = useSelector;

    const userData = selector((state) => state.profileStore.userData);

    const respFb = (response) => {
        if (response.accessToken) {
            dispatch(connectFacebook(response.accessToken));
        }
        dispatch(getUser(userData.id));
    };

    const disconnectGoogle = () => {
        dispatch(disconnetGoogle());
        dispatch(getUser(userData.id));
    };

    const responseGoogle = (response) => {
        if (response.accessToken) {
            dispatch(connectGoogle(response.tokenId));
        }
        dispatch(getUser(userData.id));
    };

    const GoogleProvider = (renderProps) => (
        <CustomRow className="fb-row" align="middle">
            <Google />
            &nbsp;&nbsp;&nbsp;
            <Text>Google</Text>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <CustomButton type="quaternaryReverse" onClick={renderProps.onClick}>
                Bağlan
            </CustomButton>
        </CustomRow>
    );

    const FacebookProvider = (renderProps) => (
        <CustomRow className="fb-row" align="middle">
            <Facebook />
            &nbsp;&nbsp;&nbsp;
            <Text>Facebook</Text>
            <CustomButton type="quaternaryReverse" onClick={renderProps.onClick}>
                Bağlan
            </CustomButton>
        </CustomRow>
    );

    return (
        <CustomRow>
            <InformationCard />
            <PasswordCard />
            <CustomRow className="bagli-hesap">
                <Text weight="semi-bold" size="16px">
                    Bağlı Hesaplar
                </Text>
            </CustomRow>
            {userData ? (
                <CustomContainer>
                    {userData.facebook_email ? (
                        <CustomRow className="fb-row" align="middle">
                            <Facebook />
                            <CustomButton type="quaternaryReverse">Bağlantıyı Kaldır</CustomButton>
                        </CustomRow>
                    ) : (
                        <FacebookLogin
                            appId="1062589017874599"
                            fields="first_name,last_name,email"
                            scope="email"
                            autoLoad={false}
                            callback={respFb}
                            render={FacebookProvider}
                        />
                    )}
                    {userData.google_email ? (
                        <CustomRow className="fb-row" align="middle">
                            <Google />
                            &nbsp;&nbsp;&nbsp;
                            <Text>Google</Text>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <CustomButton type="quaternaryReverse" onClick={disconnectGoogle}>
                                Bağlantıyı Kaldır
                            </CustomButton>
                        </CustomRow>
                    ) : (
                        <GoogleLogin
                            clientId="163234070864-afiphh53mul3il8gq98drsldoogp8v7i.apps.googleusercontent.com"
                            buttonText="Login"
                            render={GoogleProvider}
                            onSuccess={responseGoogle}
                            onFailure={(e) => console.log('Google Provider Error: ', e)}
                            cookiePolicy="single_host_origin"
                        />
                    )}
                </CustomContainer>
            ) : (
                <Spinner />
            )}
        </CustomRow>
    );
};

export default InformationPane;
