import styled from 'styled-components';
import { Row } from 'antd';
import { between } from 'polished';
import { Title as title } from '../../../components/Typography/styles';
import Button from '../../../components/Button';

export const CustomRow = styled(Row)`
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;

    &.new-pass-row {
        width: 80%;
        margin-bottom: 0px;
    }

    &.new-pass-row2 {
        margin-top: 20px;
        width: 80%;
    }
`;

export const ResetCard = styled.div`
    width: 40%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    background: #fff;
    margin-top: ${between('-50px', '70px')};
`;

export const Title = styled(title)`
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 4px solid #f2f2fc;
    width: 100%;
    text-align: center;
`;

export const CustomButton = styled(Button)`
    width: 100%;
    height: 48px;
`;
