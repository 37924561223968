import styled from 'styled-components';
import { Col } from 'antd';
import { between } from 'polished';
import Button from '../../../../components/Button';
import { HORIZONTAL_PADDING } from '../../../../../helpers/constants';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const BusinessSectionStyles = styled(Col)`
    //TODO SET PADDINGS PROPERLY
    background: ${({ backgroundColor }) => {
        if (backgroundColor) {
            return `${backgroundColor}`;
        }
    }};
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: 50% 100%;
    background-repeat: no-repeat;
    object-fit: fill;
    width: 100%;
    @media (max-width: 768px) {
        background-position: top;
        background-size: 100% 100%;
        min-height: ${between('500px', '800px')};
    }
`;

export const ContentWrapper = styled(Col)`
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    padding-top: ${between('10px', '100px')};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const BeABusinessButton = styled(Button)`
    width: 212px;
    height: 48px;
`;
