/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Wrapper, CountryCode, CountryListWrapper, CountryListItem } from './styles';
import ListBoxSuffixIcon from '../../../../../../Assets/Shared/Mailing/listBox.svg';

const SelectCountry = ({ country, setCountry }) => {
    const [isOpen, setOpen] = useState(false);

    const countryList = [{ alpha2: 'TR', code: '+90', name: 'Türkiye' }];
    return (
        <Wrapper onClick={() => setOpen(!isOpen)}>
            <CountryCode>
                {country && <ReactCountryFlag countryCode={country.alpha2} />}
                <div style={{ margin: '0px 6px' }}>{country ? country.code : 'Ülke'}</div>
                <img src={ListBoxSuffixIcon} alt="img" />
            </CountryCode>
            {isOpen && (
                <CountryListWrapper>
                    {countryList.map((item) => (
                        <CountryListItem onClick={() => setCountry(item)}>{`${item.name} ${item.code}`}</CountryListItem>
                    ))}
                </CountryListWrapper>
            )}
        </Wrapper>
    );
};

export default SelectCountry;
