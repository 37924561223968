import styled from 'styled-components';
import { Row, Input, DatePicker, Col } from 'antd';
import Button from '../../../../../../components/Button';

export const CustomRow = styled(Row)`
    width: 100%;

    &.card-body {
        margin-left: 30px;
        padding-right: 60px;
        margin-top: 65px;
    }

    &.label-row {
        margin-top: 20px;
        margin-bottom: 15px;

        &.two {
            margin-top: 40px;
        }
    }

    &.email-row {
        width: 210px;
        overflow: auto;
    }

    &.telefon-row {
        margin-top: 85px;
        margin-bottom: 15px;
    }

    &.btn-row {
        margin-top: 48px;
    }

    &.input-row {
        flex-direction: column;

        & > * + * {
            margin-top: 16px;
        }

        @media (min-width: 1280px) {
            flex-direction: row;

            & > * + * {
                margin-top: 0;
            }
        }
    }
`;

export const CustomCol = styled(Col)`
    &.btn-col {
        margin-top: 0;
    }
`;

export const CustomButton = styled(Button)`
    width: 212px;
    height: 48px;
`;

export const CustomInput = styled(Input.Password)`
    width: 210px;
    height: 48px;
    border: 2px solid #f2f2fc;
    border-radius: 4px;
`;

export const CustomPicker = styled(DatePicker)`
    border: 2px solid #f2f2fc;
    border-radius: 4px;
    height: 48px;
    width: 210px;
`;
