import * as actionTypes from './actionTypes';

// GET NOTIFICATIONS
export const getNotificationsStartAction = () => ({
    type: actionTypes.GET_NOTIFICATIONS,
});

export const getNotificationsSuccessAction = (data) => ({
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: data,
});

export const getNotificationsFailAction = (error) => ({
    type: actionTypes.GET_NOTIFICATIONS_FAIL,
    payload: error,
});

// PATCH NOTIFICATIONS
// PATCH USER
export const patchNotificationsStartAction = () => ({
    type: actionTypes.PATCH_NOTIFICATIONS,
});

export const patchNotificationsSuccessAction = (data) => ({
    type: actionTypes.PATCH_NOTIFICATIONS_SUCCESS,
    payload: data,
});

export const patchNotificationsFailAction = (error) => ({
    type: actionTypes.PATCH_NOTIFICATIONS_FAIL,
    payload: error,
});
