import React from 'react';
import { Col, Row } from 'antd';
import {
    JoinUsSectionStyles,
    TopWrapper,
    BottomWrapper,
    JoinUsSectionMobileStyles,
    ContentWrapper,
    JoinUsBottomSection,
    JoinUsTopSection,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import joinUs from '../../../../../Assets/Shared/ForBusiness/joinUs.svg';
import joinUsMobile from '../../../../../Assets/Shared/ForBusiness/joinUsMobile.svg';
import step from '../../../../../Assets/Shared/ForBusiness/step.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import { HORIZONTAL_PADDING } from '../../../../../helpers/constants';

const joinUsData = [
    'İş ortağı formunu doldur.',
    'Döngü, seninle iletişime geçsin.',
    'Menünü yükle.',
    'Restoran paneline giriş yaparak satışa başla.',
];

const JoinUsSection = () => {
    const { sm } = useMediaQuery();
    return sm ? (
        <JoinUsSectionMobileStyles>
            <JoinUsTopSection backgroundImage={joinUsMobile}>
                <img src={joinUs} alt="" style={{ width: '60%' }} />
                <Text weight="semi-bold" size="description" style={{ textAlign: 'center', marginTop: 20 }}>
                    Döngü, marka bilinirliğinizi artırıyor, hizmetleriniz hakkında bilgi sahibi olmayan çevrelere ulaşarak, yeni müşteriler elde
                    edinmenize yardımcı oluyor.
                </Text>
            </JoinUsTopSection>
            <JoinUsBottomSection backgroundImage={joinUsMobile}>
                {joinUsData.map((text, index) => (
                    <div key={index} style={{ width: '60%' }}>
                        <Row style={{ marginBottom: 15 }} justify="end">
                            <Text style={{ textAlign: 'right', lineHeight: '32px' }} size="24px" weight="semi-bold">
                                {text}
                            </Text>
                        </Row>
                    </div>
                ))}
            </JoinUsBottomSection>
        </JoinUsSectionMobileStyles>
    ) : (
        <JoinUsSectionStyles span={24} gutter={24}>
            <TopWrapper justify="space-between" span={24} gutter={24}>
                <Col span={15} style={{ marginTop: '5%' }}>
                    <Text weight="semi-bold" size="description">
                        Döngü, marka bilinirliğinizi artırıyor, hizmetleriniz hakkında bilgi sahibi olmayan çevrelere ulaşarak, yeni müşteriler elde
                        edinmenize yardımcı oluyor.
                    </Text>
                </Col>
                <Col span={8} style={{}}>
                    <img src={joinUs} alt="" style={{ width: '100%' }} />
                </Col>
            </TopWrapper>
            <BottomWrapper backgroundImage={step}>
                <Text
                    style={{
                        paddingLeft: HORIZONTAL_PADDING,
                        paddingRight: HORIZONTAL_PADDING,
                        paddingTop: '2%',
                    }}
                    color="red"
                    weight="bold"
                    size="mediumTitle">
                    Bize Katıl
                </Text>
                <ContentWrapper>
                    <Col style={{ alignSelf: 'flex-end' }} span={4}>
                        <div style={{ width: '100%', alignContent: 'center', display: 'flex', flex: 1 }}>
                            <Text size="description" style={{ textAlign: 'center' }}>
                                {joinUsData[0]}
                            </Text>
                        </div>
                    </Col>
                    <Col style={{ alignSelf: 'flex-end' }} span={5}>
                        <div style={{ width: '100%', alignContent: 'center', display: 'flex', flex: 1 }}>
                            <Text size="description" style={{ textAlign: 'center' }}>
                                {joinUsData[1]}
                            </Text>
                        </div>
                    </Col>
                    <Col style={{ alignSelf: 'flex-end' }} span={3}>
                        <div style={{ width: '100%', alignContent: 'center', display: 'flex', flex: 1 }}>
                            <Text size="description" style={{ textAlign: 'center' }}>
                                {joinUsData[2]}
                            </Text>
                        </div>
                    </Col>
                    <Col style={{ alignSelf: 'flex-end' }} span={6}>
                        <div style={{ width: '100%', alignContent: 'center', display: 'flex', flex: 1 }}>
                            <Text size="description" style={{ textAlign: 'center' }}>
                                {joinUsData[3]}
                            </Text>
                        </div>
                    </Col>
                </ContentWrapper>
            </BottomWrapper>
        </JoinUsSectionStyles>
    );
};

export default JoinUsSection;
