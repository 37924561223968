/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CreateForBusinessStyles, LeftWrapper, RightWrapper, FormContainer, FormHeader, ContentWrapper } from './styles';
import { Title, Text } from '../../../../components/Typography/styles';
import image from '../../../../../Assets/Shared/ForBusiness/isletmeler_bg.svg';
import imageWeb from '../../../../../Assets/Shared/ForBusiness/isletmelericin_web.svg';
import Button from '../../../../components/Button/index';
import { createStore } from '../../../../../services/Redux/Landing/api';
import { ModalContainer, ModalWrapperStyle, ModalContentStyle } from '../../SuccessModal/styles';
import DonguLogo from '../../../../../Assets/Shared/Layout/donguLogo.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const CreateForBusinessSection = () => {
    const [form] = Form.useForm();
    const [businessName, setBusinessName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const dispatch = useDispatch();
    const { xs, sm } = useMediaQuery();
    const store = useSelector((state) => state.landing?.store);

    const onFinish = (values) => {
        dispatch(createStore({ name: values?.businessName, email: values?.email, phoneNumber: values?.phone }));
    };

    const showModal = () => {
        setIsModalVisible(true);
        form.resetFields();
    };

    useEffect(() => {
        if (store) {
            showModal();
        }
    }, [store]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // modal

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <CreateForBusinessStyles backgroundColor="#DDF3E2" span={24} backgroundImage={sm ? image : imageWeb}>
                {sm ? (
                    <>
                        <Title
                            style={{ textAlign: sm ? 'center' : 'left', lineHeight: xs && '34px' }}
                            size={xs ? '22px' : 'mediumTitle'}
                            color="red"
                            weight="bold">
                            Gün içerisinde satamadığınız gıdaları satın, ek gelir yaratın ve müşteri ağınızı genişletin.
                        </Title>
                        <FormContainer>
                            <FormHeader>
                                <Text color="#424242" size="description" weight="light">
                                    İşletmeni Kaydet
                                </Text>
                            </FormHeader>
                            <Form
                                layout="vertical"
                                form={form}
                                style={{ margin: 35, flex: 1, marginBottom: 0 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}>
                                <Form.Item name="businessName" rules={[{ required: true, message: 'Lütfen işletme adınızı giriniz.' }]}>
                                    <Input
                                        onChange={(e) => setBusinessName(e.target.value)}
                                        value={businessName}
                                        name="businessName"
                                        id="businessName"
                                        size="large"
                                        placeholder="İşletmenizin Adı"
                                    />
                                </Form.Item>

                                <Form.Item name="email" rules={[{ required: true, message: 'Lütfen mail adresinizi giriniz.' }]}>
                                    <Input
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        name="email"
                                        id="email"
                                        size="large"
                                        placeholder="Email Adresi"
                                    />
                                </Form.Item>

                                <Form.Item name="phone" rules={[{ required: true, message: 'Lütfen telefon numaranızı giriniz.' }]}>
                                    <Input
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                        name="phone"
                                        id="phone"
                                        size="large"
                                        placeholder="Telefon Numarası"
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Row justify="center">
                                        <Button style={{ width: 200, height: 48 }} type="primary" htmlType="submit">
                                            Kaydol
                                        </Button>
                                    </Row>
                                </Form.Item>

                                {/* modal */}
                                <ModalWrapperStyle width="50%" footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                    <ModalContainer>
                                        <div style={{ marginTop: '53px' }}>
                                            <img src={DonguLogo} alt="img" width={130} />
                                        </div>

                                        <Row style={{ paddingTop: '37px', paddingBottom: '20px' }}>
                                            <Text size="mediumTitle" style={{ fontWeight: '500px' }}>
                                                Tebrikler
                                            </Text>
                                        </Row>
                                        <ModalContentStyle size="description">
                                            Döngü’ye katılma talebin için öncelikle teşekkür ederiz. Talebini aldık, en kısa sürede iletişime
                                            geçeceğiz.
                                        </ModalContentStyle>

                                        <Button
                                            onClick={handleOk}
                                            type="success"
                                            style={{
                                                borderRadius: '4px',
                                                backgroundColor: '#8FD89F',
                                                color: 'white',
                                                fontSize: '16px',
                                                width: '212px',
                                                height: '48px',
                                                marginBottom: '48px',
                                                marginLeft: '294px',
                                                marginRight: '294px',
                                            }}>
                                            Tamam
                                        </Button>
                                    </ModalContainer>
                                </ModalWrapperStyle>
                            </Form>
                        </FormContainer>
                        <Row style={{ marginTop: 50 }}>
                            <Text style={{ textAlign: sm ? 'center' : 'left' }} size="description" weight="medium">
                                Ne kadar yiyeceğinizin israf olduğunu dert etmeyin, Döngü sizin için halletti.
                                <br />
                                <br /> Döngü’ye Katılın ve Gıda İsrafına Karşı Beraber Mücadele Edelim. <br /> <br />{' '}
                                <Text size="description" weight="bold">
                                    EK MALİYET YOK, ABONELİK YOK <br />
                                </Text>
                                İşletmeniz için Maksimum Esneklik ve Kolay Kullanım.
                            </Text>
                        </Row>
                    </>
                ) : (
                    <ContentWrapper>
                        <LeftWrapper span={12}>
                            <Title size="mediumTitle" color="red" weight="bold">
                                Üretim veya arz fazlasını ek gelire dönüştür, müşteri ağını genişlet!
                            </Title>
                            <Row style={{ marginTop: 50, width: '62%' }}>
                                <Text size="description" weight="medium">
                                    İşletmenin Gıda İsrafını Dert Etme, Senin Yerine Döngü Düşünsün
                                    <br />
                                    <div style={{ paddingBottom: '3%' }} />
                                    Döngü’ye Katılın ve Gıda İsrafına Karşı Beraber Mücadele Edelim. <br />
                                    <div style={{ paddingBottom: '3%' }} />
                                    <Text size="description" weight="bold">
                                        Döngü için Herhangi Bir Abonelik ve Ek Maliyet Gerekmiyor. <br />
                                        <div style={{ paddingBottom: '3%' }} />
                                    </Text>
                                    İşletmen için Maksimum Esneklik ve Kolay Kullanım.
                                </Text>
                            </Row>
                        </LeftWrapper>
                        <RightWrapper>
                            <FormContainer>
                                <FormHeader>
                                    <Text color="#424242" size="title" weight="light">
                                        İşletmeni Kaydet
                                    </Text>
                                </FormHeader>
                                <FormContainer>
                                    <Form
                                        layout="vertical"
                                        form={form}
                                        style={{ margin: 35, flex: 1, marginBottom: 0 }}
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}>
                                        <Form.Item name="businessName" rules={[{ required: true, message: 'Lütfen işletme adınızı giriniz.' }]}>
                                            <Input
                                                onChange={(e) => setBusinessName(e.target.value)}
                                                value={businessName}
                                                name="businessName"
                                                id="businessName"
                                                size="large"
                                                placeholder="İşletmenizin Adı"
                                            />
                                        </Form.Item>

                                        <Form.Item name="email" rules={[{ required: true, message: 'Lütfen mail adresinizi giriniz.' }]}>
                                            <Input
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                name="email"
                                                id="email"
                                                size="large"
                                                placeholder="Email Adresi"
                                            />
                                        </Form.Item>

                                        <Form.Item name="phone" rules={[{ required: true, message: 'Lütfen telefon numaranızı giriniz.' }]}>
                                            <Input
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone}
                                                name="phone"
                                                id="phone"
                                                size="large"
                                                placeholder="Telefon Numarası"
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            <Row justify="center">
                                                <Button style={{ width: 200, height: 48 }} type="primary" htmlType="submit">
                                                    Kaydol
                                                </Button>
                                            </Row>
                                        </Form.Item>

                                        {/* modal */}
                                        <ModalWrapperStyle
                                            width="50%"
                                            footer={false}
                                            visible={isModalVisible}
                                            onOk={handleOk}
                                            onCancel={handleCancel}>
                                            <ModalContainer>
                                                <div style={{ marginTop: '53px' }}>
                                                    <img src={DonguLogo} alt="img" width={130} />
                                                </div>

                                                <Row style={{ paddingTop: '37px', paddingBottom: '20px' }}>
                                                    <Text size="mediumTitle" style={{ fontWeight: '500px' }}>
                                                        Tebrikler
                                                    </Text>
                                                </Row>
                                                <ModalContentStyle size="description">
                                                    Döngü’ye katılma talebin için öncelikle teşekkür ederiz. Talebini aldık, en kısa sürede iletişime
                                                    geçeceğiz.
                                                </ModalContentStyle>

                                                <Button
                                                    onClick={handleOk}
                                                    type="success"
                                                    style={{
                                                        borderRadius: '4px',
                                                        backgroundColor: '#8FD89F',
                                                        color: 'white',
                                                        fontSize: '16px',
                                                        width: '212px',
                                                        height: '48px',
                                                        marginBottom: '48px',
                                                        marginLeft: '294px',
                                                        marginRight: '294px',
                                                    }}>
                                                    Tamam
                                                </Button>
                                            </ModalContainer>
                                        </ModalWrapperStyle>
                                    </Form>
                                </FormContainer>
                            </FormContainer>
                        </RightWrapper>
                    </ContentWrapper>
                )}
            </CreateForBusinessStyles>
        </>
    );
};

export default CreateForBusinessSection;
