import styled from 'styled-components';
import { Col, Row } from 'antd';

export const FindRestaurantWrapper = styled(Row)`
    background-color: #f5f9fc;
    margin: 0 auto !important;
    padding: 14% 3%;
    padding-top: 150px;
    max-width: 1430px;

    @media (min-width: 1500px) {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const CustomCol = styled(Col)`
    &.left-col {
        @media (max-width: 1024px) {
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    &.right-col {
        display: none;

        @media (min-width: 1025px) {
            display: block;
        }
    }
`;
