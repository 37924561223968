import styled from 'styled-components';
import { Row, Radio } from 'antd';
import Button from '../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;

    &.address-info {
        margin-top: 10px;
    }

    &.item-row {
        margin-top: 8px;
    }

    &.radio-row {
        @media (min-width: 1280px) {
            width: 500px;
        }
    }

    &.duzenle-row {
        margin-top: 40px;
    }

    &.yeni-row {
        margin-top: 20px;
    }
`;

export const CustomRadio = styled(Radio)`
    display: flex;
    margin-top: 20px;

    .ant-radio-inner {
        width: 22px;
        height: 22px;
        border-radius: 5px;

        ::after {
            background-color: #8fd89f;
            height: 14px;
            width: 14px;
        }
    }

    .ant-radio-checked::after {
        border: 1px solid #ffffff;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #d1d0d0;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #d1d0d0;
    }

    &.sort-section {
        align-items: center;
        margin-top: 8px;
    }

    &.delivery-section {
        margin-top: 0px;
    }

    &.radio-wrapper {
        flex-wrap: wrap;
    }
`;

export const CustomButton = styled(Button)`
    width: 212px;
    height: 48px;
`;
