import React from 'react';
import { useSelector } from 'react-redux';
import { CustomRow } from './styles';
import NotificationCard from '../NotificationCard';

const OrdersCard = () => {
    const selector = useSelector;
    const notifications = selector((state) => state.notificationStore.notificationList);

    return (
        <CustomRow className="main-row">
            {notifications.results.map((item) => {
                if (item.type === 8 || item.type === 16) {
                    return <NotificationCard content={item} />;
                }
                return <></>;
            })}
        </CustomRow>
    );
};

export default OrdersCard;
