/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Col, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { PacketStyles, Wrapper, Header, Content, AddFoodBtn, CustomSelect, WrapperTop } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import FoodLabel from './FoodLabel';
import MenuModal from '../../components/MenuModal';
import selectArrow from '../../../../../../Assets/Dashboard/select-suffix-icon.png';
import { fetchBoxes, fetchBoxNames, updateBox } from '../../../../../../services/Redux/Dashboard/boxes/api';
import Footer from './Footer';
import soldIcon from '../../../../../../Assets/Dashboard/sell-icon.png';

const Packet = ({ meals, packetId, boxId, foodPrice, sold, minPacketPrice, defined, boxNameId, startDate, minOrderPrice, textName }) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const now = moment(new Date());
    const start = moment(startDate);
    const duration = moment.duration(start.diff(now));

    const { Option } = CustomSelect;
    const [boxName, setBoxName] = useState(textName);
    const [foodPrices, setFoodPrices] = useState(foodPrice);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [packetNameToRender, setPacketNameToRender] = useState(0);
    const [selectedKey, setSelectedKey] = useState(boxNameId);
    const [selectedOption, setSelectedOption] = useState('Sürpriz Paket');

    const { nameList } = selector((state) => state.boxStore);
    useEffect(() => {
        dispatch(fetchBoxNames());
        if (!isModalVisible) {
            dispatch(fetchBoxes());
        }
    }, [isModalVisible]);

    useEffect(() => {
        if (nameList && textName) {
            setPacketNameToRender(nameList);
        }
    }, [nameList, textName]);

    useEffect(() => {
        if (meals.length === 0) {
            setFoodPrices(foodPrice);
            setBoxName(textName);
        }
    }, [meals]);

    function handleChange(value, option) {
        setSelectedOption(option.children);
        setSelectedKey(value);
        setBoxName(option.children);
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleUpdate = async () => {
        if (meals.length !== 0 && selectedOption === 'Sürpriz Paket') return message.warn('Lütfen geçerli bir paket ismi seçiniz.');
        if (duration.hours() >= 2) {
            await dispatch(updateBox(selectedKey, boxId));
            dispatch(fetchBoxes());
        }
    };

    return (
        <PacketStyles>
            <Wrapper span={24}>
                <WrapperTop>
                    <Header display="flex" flexDirection="column">
                        <Header style={{ width: '91%' }}>
                            {packetNameToRender && (
                                <CustomSelect
                                    value={boxName}
                                    suffixIcon={<img src={selectArrow} alt="" srcSet="" />}
                                    disabled={defined}
                                    onChange={handleChange}>
                                    {packetNameToRender.map(({ name, id }) => (
                                        <Option key={id} value={id}>
                                            {name}
                                        </Option>
                                    ))}
                                </CustomSelect>
                            )}
                        </Header>
                        <Header style={{ width: '5%' }}>
                            {/* eslint-disable-next-line sonarjs/no-redundant-boolean */}
                            {sold && <img src={soldIcon} style={{ width: 32, height: 32 }} alt="sold" />}
                        </Header>
                    </Header>
                    <Content
                        align="middle"
                        justify="start"
                        gutter={[
                            { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                            { xs: 8, sm: 8, md: 12, lg: 16, xl: 16 },
                        ]}>
                        {meals &&
                            meals.map(({ name, id, price }) => (
                                <Col>
                                    <FoodLabel price={price} foodId={id} packetId={packetId} name={name} />
                                </Col>
                            ))}
                        <Col>
                            <AddFoodBtn disabled={defined} onClick={() => showModal()}>
                                <Text weight="bold" size="noble-regular" color="yellow">
                                    +
                                </Text>
                            </AddFoodBtn>
                        </Col>
                        {meals.length === 0 && (
                            <Col span={15}>
                                <Text size="smallRegular" color="smoke" fontStyle="italic">
                                    Paketiniz Satıştadır! Paket içeriğinin görüntülenmesi için paket tanımlaması yapınız.
                                </Text>
                            </Col>
                        )}
                    </Content>
                    <Footer
                        isMinPrice={foodPrices < minOrderPrice}
                        foodPrices={foodPrices}
                        minPacketPrice={minPacketPrice}
                        minOrderPrice={minOrderPrice}
                        defined={defined}
                        sold={sold}
                        handleUpdate={handleUpdate}
                    />
                </WrapperTop>
            </Wrapper>
            {meals && (
                <MenuModal
                    selectedOption={selectedOption}
                    meals={meals}
                    boxId={boxId}
                    foodPrices={foodPrices}
                    setFoodPrices={setFoodPrices}
                    packetId={packetId}
                    isModalVisible={isModalVisible}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                />
            )}
        </PacketStyles>
    );
};

export default Packet;
