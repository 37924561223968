/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardStyles, { Content, Item, Text, CardTitle, SaveButton, Input } from './styles';
import { getDraftInfo, updatePackageInfo } from '../../../../../../services/Redux/Dashboard/package/api';

const PackageAmount = ({ min, max, id }) => {
    const dispatch = useDispatch();
    const [minAmount, setMinAmount] = useState(min);
    const [maxAmount, setMaxAmount] = useState(max);

    const update = (min_discounted_order_price, min_order_price) => {
        dispatch(updatePackageInfo({ min_order_price, min_discounted_order_price }, id));
        dispatch(getDraftInfo());
    };

    const { belongToId } = useSelector((state) => state?.packageStore);
    return (
        <CardStyles title={<CardTitle>Paket Tutarı</CardTitle>}>
            <Content>
                <Text weight="semi-bold">Paket tutarı:</Text>
                <Text color="smoke" family="secondary" style={{ marginTop: '16px' }}>
                    Müşterilere sunacağınız paket tutarını minimum normal satış fiyatı ve indirimli satış fiyatı olarak giriniz. Bu alan mağazanızda
                    görüntülecektir.
                </Text>
                <Item style={{ marginTop: '24px' }}>
                    <Item xl={14} span={15}>
                        <Input
                            suffix="TL"
                            value={maxAmount}
                            onChange={(event) => {
                                const tmp = parseInt(event.target.value);
                                if (Number.isNaN(tmp)) {
                                    setMaxAmount(0);
                                } else {
                                    setMaxAmount(parseInt(tmp));
                                }
                            }}
                            maxAmount
                        />
                        <Text weight="bold" style={{ marginLeft: '10px', marginRight: '10px' }}>
                            -
                        </Text>
                        <Input
                            value={minAmount}
                            suffix="TL"
                            onChange={(event) => {
                                const tmp = parseInt(event.target.value);
                                if (Number.isNaN(tmp)) {
                                    setMinAmount(0);
                                } else {
                                    setMinAmount(parseInt(tmp));
                                }
                            }}
                        />
                    </Item>
                    <Item xl={{ span: 9, offset: 1 }} span={9}>
                        <SaveButton type="secondary" onClick={() => update(minAmount, maxAmount)}>
                            {Array.isArray(belongToId) ? 'Onay Bekleniyor' : 'Kaydet'}
                        </SaveButton>
                    </Item>
                </Item>
            </Content>
        </CardStyles>
    );
};

export default PackageAmount;
