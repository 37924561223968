import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';
import { HORIZONTAL_PADDING } from '../../../../../helpers/constants';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const JoinUsSectionStyles = styled(Col)`
    box-sizing: border-box;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: -4vw;
`;
export const JoinUsSectionMobileStyles = styled(Col)`
    box-sizing: border-box;
    min-height: 90vh;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    flex: 1;
    display: flex;
    flex-direction: column;

    margin-top: ${between('30px', '60px')};
`;

export const JoinUsBottomSection = styled(Row)`
    width: 100%;
    height: 90vh;
    background-size: 30% 100%;
    background-repeat: no-repeat;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: column;
    background-color: #ddf3e2;
    padding-top: 25px;
    margin-top: 40px;
    padding-right: ${HORIZONTAL_PADDING};
`;
export const JoinUsTopSection = styled(Row)`
    align-items: center;
    flex-direction: column;

    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
`;
export const BottomWrapper = styled(Row)`
    height: 30vw;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage}) !important`;
        }
    }};
    background-size: 110%;
    background-position: 30%;
    background-repeat: no-repeat;
`;
export const TopWrapper = styled(Row)`
    padding-top: 80px;
    margin-bottom: 80px;
    padding-left: ${between('0px', '120px')};
    padding-right: ${between('0px', '120px')};
`;

export const ContentWrapper = styled(Row)`
    width: 100%;
    position: absolute;
    bottom: ${between('5px', '60px', '780px', '1920px')};
    justify-content: space-around;
    padding-left: ${HORIZONTAL_PADDING};

    padding-right: ${between('30px', '30px', '780px', '1920px')};
`;
