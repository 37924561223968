import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        margin-top: 20px;
    }

    &.card-row {
        margin-top: 10px;
        border: 2px solid #f2f2fc;
        border-radius: 4px;
        padding: 20px 14px;
    }

    &.text-row {
        margin-top: 7px;
    }

    &.popover-card {
        width: 400px;
    }

    &.item-row {
        padding: 5px 2px;
        cursor: pointer;
        border-radius: 5px;

        :hover {
            color: white !important;
            background: #f2f2fc;
        }
    }
`;

export const CustomButton = styled(Button)`
    margin-top: 1rem;
    width: 25rem;
    height: 3rem;

    &.button-link {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.borderless-link {
        min-width: 3rem;
        border: 0 !important;
        background-color: transparent !important;
    }
`;
