/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../components/Typography/styles';
import { getAddress } from '../../../../../../services/Redux/Shared/profile/api';
import Spinner from '../../../../../components/Spinner';
import AddressCard from './AddressCard';
import AddressDetails from './AddressDetails';

function Index() {
    const selector = useSelector;
    const dispatch = useDispatch();
    const [addressList, setAddressList] = useState([]);
    const [addressToDisplay, setAddressToDisplay] = useState({});
    const [isNew, setIsNew] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getAddress());
    }, [dispatch]);

    const storeData = selector((state) => state?.profileStore?.addressList);

    useEffect(() => {
        if (storeData) {
            setLoading(false);
            setAddressList(storeData?.results);
        }
    }, [storeData]);

    if (loading) return <Spinner />;
    if (isNew) return <AddressDetails address={null} setAddress={setAddressToDisplay} isNew={isNew} setIsNew={setIsNew} />;
    return (
        <>
            {addressToDisplay && Object.keys(addressToDisplay)?.length !== 0 ? (
                <div style={{ height: '100%', width: '100%' }}>
                    <AddressDetails address={addressToDisplay} setAddress={setAddressToDisplay} />
                </div>
            ) : (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}>
                    {addressList && Array.isArray(addressList) && addressList.length !== 0 && (
                        <div>
                            <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginBottom: '32px' }}>
                                Kayıtlı Adreslerim
                            </Text>
                            {addressList.map((item, i) => (
                                <AddressCard address={item} key={item.id} index={i} setAddress={setAddressToDisplay} />
                            ))}
                        </div>
                    )}
                    {!addressList && !addressToDisplay && addressList?.length === 0 && (
                        <Text size="title" weight="semibold" color="black" style={{ paddingLeft: '32px', marginTop: '32px' }} align="center">
                            Adresleriniz Bulunmamaktadır!
                        </Text>
                    )}
                    <div style={{ paddingBottom: '32px', minWidth: '85%' }}>
                        <button
                            style={{
                                backgroundColor: '#8FD89F',
                                width: '100%',
                                height: '48px',
                                textAlign: 'center',
                                borderRadius: '8px',
                                border: 'none',
                            }}
                            onClick={() => setIsNew(true)}>
                            <Text size="description" weight="semibold" color="white">
                                Yeni Adres Ekle
                            </Text>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Index;
