import * as actionTypes from './actionTypes';

export const createCardStartAction = () => ({
    type: actionTypes.CREATE_CARD_REGISTER_START,
});

export const createCardSuccessAction = (permission) => ({
    type: actionTypes.CREATE_CARD_REGISTER_SUCCESS,
    payload: permission,
});

export const createCardFailAction = (error) => ({
    type: actionTypes.CREATE_CARD_REGISTER_FAIL,
    payload: error,
});

// CARD LIST

export const getCardStartAction = () => ({
    type: actionTypes.GET_CARD_LIST_START,
});

export const getCardSuccessAction = (permission) => ({
    type: actionTypes.GET_CARD_LIST_SUCCESS,
    payload: permission,
});

export const getCardFailAction = (error) => ({
    type: actionTypes.GET_CARD_LIST_FAIL,
    payload: error,
});

// CARD DELETE

export const deleteCardStartAction = () => ({
    type: actionTypes.DELETE_CARD_START,
});

export const deleteCardSuccessAction = (permission) => ({
    type: actionTypes.DELETE_CARD_SUCCESS,
    payload: permission,
});

export const deleteCardFailAction = (error) => ({
    type: actionTypes.DELETE_CARD_FAIL,
    payload: error,
});

// GET IP

export const getIpStartAction = () => ({
    type: actionTypes.GET_IP_START,
});

export const getIpSuccessAction = (data) => ({
    type: actionTypes.GET_IP_SUCCESS,
    payload: data,
});

export const getIpFailAction = (error) => ({
    type: actionTypes.GET_IP_FAIL,
    payload: error,
});

// GET PAYMENT INQUIRY

export const getPaymentInquiryStartAction = () => ({
    type: actionTypes.GET_PAYMENT_INQUIRY_START,
});

export const getPaymentInquirySuccessAction = (data) => ({
    type: actionTypes.GET_PAYMENT_INQUIRY_SUCCESS,
    payload: data,
});

export const getPaymentInquiryFailAction = (error) => ({
    type: actionTypes.GET_PAYMENT_INQUIRY_FAIL,
    payload: error,
});
