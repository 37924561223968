/* eslint-disable camelcase */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import EmailCardStyles, { Content, Item, Text, Switcher, Divider, CardTitle, Select, Email } from './styles';
import { updateEmailInfo, postEmailInfo } from '../../../../../../services/Redux/Dashboard/StorePage/api';

const EmailCard = ({ settings, id, storeInfo }) => {
    const dispatch = useDispatch();
    const infoExists = settings?.results[0];
    const [sendConfirmation, setSendConfirmation] = useState(infoExists ? settings?.results[0].order_confirm : false);
    const [sendCancel, setSendCancel] = useState(infoExists ? settings?.results[0].order_cancel : false);
    const [sendMonthlyReport, setSendMonthlyReport] = useState(infoExists ? settings?.results[0].monthly_report : false);
    const [email, setEmail] = useState(storeInfo.email || 'ornek@ornek.com');

    const send = (order_confirm, order_cancel, monthly_report) => {
        if (infoExists) {
            dispatch(updateEmailInfo({ order_confirm, order_cancel, monthly_report }, infoExists.id));
        } else {
            dispatch(postEmailInfo({ order_confirm, order_cancel, monthly_report, store: id }));
            window.location.reload();
        }
    };
    return (
        <EmailCardStyles title={<CardTitle>E-mail Ayarları</CardTitle>}>
            <Content>
                <Item>
                    <Text color="smoke" family="secondary">
                        Aldığınız siparişler hakkında size farklı türlerde e-postalar gönderiyoruz. Burada almak istediklerinizi seçebilir ve onları
                        hangi e-posta adreslerine göndereceğimizi görebilirsiniz.
                    </Text>
                    <Text weight="semi-bold" style={{ marginTop: '32px' }}>
                        E-mail yönlendirmeleri:
                    </Text>
                    <Content style={{ marginTop: '20px' }}>
                        <Item span={21}>
                            <Text weight="semi-bold">Sipariş Onay Emaili</Text>
                        </Item>
                        <Item span={1}>
                            <Switcher
                                checked={sendConfirmation}
                                onClick={() => {
                                    setSendConfirmation(!sendConfirmation);
                                    send(sendConfirmation, sendCancel, sendMonthlyReport);
                                }}
                            />
                        </Item>
                    </Content>
                    <Text color="smoke" family="secondary" style={{ marginTop: '-21px' }}>
                        Verilen her sipariş için gönderilen e-posta
                    </Text>
                    <Divider />
                    <Content>
                        <Item span={21}>
                            <Text weight="semi-bold">Sipariş İptali Emaili</Text>
                        </Item>
                        <Item span={1}>
                            <Switcher
                                checked={sendCancel}
                                onClick={() => {
                                    setSendCancel(!sendCancel);
                                    send(sendConfirmation, !sendCancel, sendMonthlyReport);
                                }}
                            />
                        </Item>
                    </Content>
                    <Text color="smoke" family="secondary" style={{ marginTop: '-21px' }}>
                        Bir sipariş alınmadan önce iptal edilmişse e-posta gönderilir.
                    </Text>
                    <Divider />
                    <Content>
                        <Item span={21}>
                            <Text weight="semi-bold">Aylık Rapor Emaili</Text>
                        </Item>
                        <Item span={1}>
                            <Switcher
                                checked={sendMonthlyReport}
                                onClick={() => {
                                    setSendMonthlyReport(!sendMonthlyReport);
                                    send(sendConfirmation, sendCancel, !sendMonthlyReport);
                                }}
                            />
                        </Item>
                    </Content>
                    <Text color="smoke" family="secondary" span={24} style={{ marginTop: '-21px' }}>
                        Performansınızı ayrıntılarıyla anlatan aylık e-posta.
                    </Text>
                    <Divider />
                    <Item span={24}>
                        <Text weight="semi-bold">Bu emailler aşağıdaki adrese gönderilir:</Text>
                        <Text color="smoke" family="secondary" style={{ marginTop: '1rem' }}>
                            {email}
                        </Text>
                    </Item>
                </Item>
            </Content>
        </EmailCardStyles>
    );
};

export default EmailCard;
