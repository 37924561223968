/* eslint-disable sonarjs/no-identical-functions */
import React, { useState } from 'react';
import { Collapse, Menu, Row } from 'antd';
import { Container, ContainerContent, TitleContainer, ContainerHeader, TextStyle } from '../styles';
import SearchBar from './Search/Search';
import { Title, Text } from '../../../../components/Typography/styles';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

const Content = () => {
    const [index, setIndex] = useState('app');
    const { sm } = useMediaQuery();
    const [search, setSearch] = useState('');

    const handleClick = (e) => {
        setIndex(e.key);
    };

    const dataList = [
        {
            header: 'Neden Döngü’de yer almalıyım?',
            desc:
                'İşletmendeki arz fazlalığını, mevcut kalitesini ve tazeliğini yitirmeden satışını gerçekleştirmek istediğin gıda ürünlerini müşterilerinle buluşturmak için. Döngü; işletmeni çok geniş bir müşteri ağına tanıtırken, gıda israfının önüne geçerek karlılığını artırma fırsatı sunuyor.',
        },
        {
            header: 'Döngü’ye nasıl üye olabilirim?',
            desc:
                ' Önemli bir çevre sorumluluğu olan gıda israfını önlemek, bunu yaparken ek gelir elde etmek için Döngü başvuru formunu doldurman yeterli. Sonraki 24 saat içerisinde başvurunu değerlendirerek sana dönüş yapacağız.',
        },
        {
            header: 'Döngü üyesi olmak için ücret ödemem gerekiyor mu?',
            desc:
                'Döngü üyeliğinin işletmeler için herhangi bir üyelik ücreti veya ek maliyeti bulunmaz. Döngü, operasyon maliyetlerini karşılamak için, satışlar            üzerinden yalnızca küçük bir komisyon alır.',
        },
        {
            header: 'Döngü aracılığıyla ne tür ve kalitede yiyecekler sunuluyor?',
            desc:
                ' İşletme türün ne olursa olsun, normal bir günde servis ettiğin veya satışını yaptığın gıda ürünlerinin tamamını Döngü’ye dahil edebilirsin. Bunun için gerekli tek koşul, kalite standartlarını karşılaman ve ilgili gıda mevzuatlarına uygun hizmet sağlaman.',
        },
        {
            header: 'Müşteriler işletmemi nasıl bulabilir? ',
            desc:
                'Döngü müşterileri, uygulamayı/web arayüzünü kullanarak harita üzerinde gezinebilir ve ilgilerini çeken işletmelerden ürün rezervasyonu gerçekleştirebilir. Ayrıca, işletme seçim kriterleri ile yapacakları filtreleme ile de işletmene ulaşmaları mümkün.',
        },
        {
            header: 'Sipariş teslimini nasıl gerçekleştirebilirim?',
            desc:
                ' Döngü satışı gerçekleştiğinde, daha önceden belirlemiş olduğun zaman aralığında siparişini teslim edebilirsin. Müşteriler satın aldıkları ürünleri işletmeni ziyaret ederek teslim alırlar.',
        },
        {
            header: 'Döngü’de kurye ile teslim mevcut mudur? ',
            desc:
                'Döngü’nün sipariş teslimi için kurye servisi bulunmuyor. Ancak, kendi bünyesinde kurye hizmeti sağlayan işletmeler için, Döngü’de “kapıda teslim” seçeneği de mevcuttur.',
        },
        {
            header: 'Döngü’de ürünlerim nasıl yer alacak?',
            desc:
                'Döngü’de ürünler farklı içeriklerdeki paketler halinde satışa sunulacak. İşletmeler içeriklerini belirledikleri paketleri, belirledikleri zaman aralığında teslim etmek üzere, günün herhangi bir saatinde sisteme tanımlayabilir. Müşteriler bu paketleri, işletmenin belirleyeceği saatlerde teslim almak üzere, günün herhangi bir saatinde satın alabilir.',
        },
        {
            header: 'Sürpriz Paket Nedir?',
            desc:
                'İçeriği “Sürpriz” olan bu paket, işletmen tarafından günün herhangi bir saatinde sisteme tanımlanabilir. Teslim saatlerini senin belirleyeceğin paketin içeriğini, kullanıcı satın alım gerçekleştirdikten sonra görebilir ve içerikten memnun kaldığı takdirde siparişi iptal edebilir.',
        },
        {
            header: 'Tanımlı Paket Nedir?',
            desc:
                'Kullanıcılar, işletmen tarafından sunulan ürünleri listelediğinde, bu paketin içeriğiyle ilgili detaylı bilgiyi görüntüleyebilir. İşletmenin içeriğini tanımladığı sürpriz paketler de, tanımlı pakete dönüşür.',
        },
        {
            header: 'Döngü son tüketiciye ne vadediyor?',
            desc:
                'Gıda israfı önemli bir çevre sorunu ve gıda servis sektöründe her gün çok yüksek miktarlarda ürün, hiç tüketilmeden çöpe dönüşüyor. Döngü kullanıcıları, özel fiyatlarla kaliteli ürünlere rahatlıkla ulaşabilirken, çevreyi ve doğal kaynakları koruma yolunda önemli adımlar atıyor.' +
                ' Döngü, bütçeni ve gezegenin kaynaklarını, kalite standartlarından ödün vermeden koruyor.',
        },
    ];
    const { Panel } = Collapse;

    return (
        <Row style={{ marginTop: sm ? '20%' : '8%' }}>
            <TitleContainer>
                <Title weight="bold" color="white" size="mediumTitle">
                    Sıkça Sorulan Sorular
                </Title>
            </TitleContainer>
            <Container>
                <ContainerHeader>
                    <Menu
                        style={{
                            justifyContent: 'center',
                            flex: 1,
                            display: 'flex',
                            borderRadius: '30%',
                        }}
                        onClick={handleClick}
                        selectedKeys={index}
                        mode="horizontal">
                        {/* <Menu.Item style={{}} key="mail">
                            <TextStyle style={{ size: 'mediumTitle', position: 'relative' }} isSelected={index === 'mail'}>
                                Kullanıcılar için Sorular ve Cevaplar
                                <hr />
                            </TextStyle>
                        </Menu.Item> */}

                        <Menu.Item key="app">
                            <TextStyle style={{ size: 'mediumTitle' }} isSelected={index === 'app'}>
                                İşletmeler için Sorular ve Cevaplar
                                <hr />
                            </TextStyle>
                        </Menu.Item>
                    </Menu>
                </ContainerHeader>
                <ContainerContent>
                    <SearchBar search={search} setSearch={setSearch} />

                    <Collapse style={{ width: '100%' }} accordion defaultActiveKey={['1']} ghost>
                        {dataList
                            .filter((data) => data.header.toLocaleLowerCase().includes(search.toLowerCase()))
                            .map((item, key) => (
                                <>
                                    <Panel
                                        style={{ size: 'mediumTitle', borderBottom: '2px solid #F2F2FC' }}
                                        showArrow={false}
                                        header={item.header}
                                        key={key}>
                                        <Row style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Text style={{ fontWeight: '350' }} color="#837f7f">
                                                • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.desc}
                                            </Text>
                                        </Row>
                                    </Panel>
                                </>
                            ))}
                    </Collapse>
                </ContainerContent>
            </Container>
        </Row>
    );
};

export default Content;
