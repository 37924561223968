import React from 'react';
import { CustomRow, CustomDivider } from './styles';
import PacketRow from './PacketRow';
import CardsRow from './CardsRow';
import LastRow from './LastRow';
import CardForm from './CardForm';

const PaymentPane = (props) => {
    const {
        packet,
        check1,
        check2,
        addressInfo,
        setComplete,
        timeInt,
        setCheck1,
        setCheck2,
        order,
        setCardToken,
        cardForm,
        setCardForm,
        newCard,
        setNewCard,
        threeD,
        setThreeD,
        checkedSaveCard,
        setCheckedSaveCard,
    } = props;

    return (
        <CustomRow>
            <PacketRow packet={packet} />
            <CustomDivider />
            {!newCard ? (
                <CardsRow setNewCard={setNewCard} cardForm={cardForm} setCardForm={setCardForm} setCardToken={setCardToken} />
            ) : (
                <CardForm
                    setNewCard={setNewCard}
                    setCardForm={setCardForm}
                    cardForm={cardForm}
                    checkedSaveCard={checkedSaveCard}
                    setCheckedSaveCard={setCheckedSaveCard}
                />
            )}
            {/* The following part has been removed as per requested, however, uncommenting it shall make it work directly, functionalities are already implemented. */}
            <CustomDivider />
            <LastRow
                addressInfo={addressInfo}
                packet={packet}
                check1={check1}
                check2={check2}
                setCheck1={setCheck1}
                setCheck2={setCheck2}
                cardForm={cardForm}
                newCard={newCard}
                setComplete={setComplete}
                timeInt={timeInt}
                order={order}
                setCardToken={setCardToken}
                threeD={threeD}
                setThreeD={setThreeD}
                checkedSaveCard={checkedSaveCard}
                setCheckedSaveCard={setCheckedSaveCard}
            />
        </CustomRow>
    );
};

export default PaymentPane;
