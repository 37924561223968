import CardStyles, { Content, Item, Text, CardTitle } from './styles';
import PhoneImage from '../../../../../../Assets/Dashboard/phone-image.png';
import ConditionImage from '../../../../../../Assets/Dashboard/condition-image.png';

const ConditionCard = () => (
    <CardStyles title={<CardTitle>Hükümler ve Koşullar</CardTitle>}>
        <Content justify="center">
            <Item span={24}>
                <Text color="smoke" family="secondary">
                    Hüküm ve koşullarla ilgili herhangi bir sorunuz varsa, lütfen bizi aramaktan çekinmeyin.
                </Text>
                <Content style={{ marginTop: '35px' }}>
                    <img src={PhoneImage} alt="" />
                    <Text weight="semi-bold">Telefon</Text>
                </Content>
                <Text color="smoke" family="secondary" style={{ marginTop: '10px' }}>
                    +90 500 111 00 00
                </Text>
                <Content style={{ marginTop: '41px' }}>
                    <img src={ConditionImage} alt="" />
                    <Text weight="semi-bold">Hükümler ve Koşullar:</Text>
                </Content>
                <Text color="smoke" family="secondary" style={{ marginTop: '10px' }}>
                    +90 500 111 00 00
                </Text>
                <Text
                    color="smoke"
                    family="secondary"
                    size="13px"
                    fontStyle="italic"
                    style={{ marginTop: '11px', marginLeft: 'auto', marginRight: 'auto' }}>
                    Güncelleme Tarihi: 01.01.2020
                </Text>
            </Item>
        </Content>
    </CardStyles>
);

export default ConditionCard;
