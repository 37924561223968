import React from 'react';
import { Col, Row } from 'antd';
import { PhoneTwoTone, MailTwoTone, HomeTwoTone } from '@ant-design/icons';
import { CustomModal, MenuWrapper, ImageContainer, MapContainer } from './styles';
import { Text } from '../../../../components/Typography/styles';
import logo from '../../../../../Assets/Dashboard/dongu-logo.png';
import map from '../../../../../Assets/Dashboard/deletethislater/location-map.png';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);

const ContactModal = (props) => (
    <CustomModal title={<Title text="İletişim" />} {...props}>
        <Col className="padding-body" span={24}>
            <MenuWrapper align="middle" justify="center">
                <ImageContainer>
                    <img className="img-covered" src={logo} alt="logo" />
                </ImageContainer>
            </MenuWrapper>
            <Row align="middle" justify="start">
                <Col span={4}>
                    <PhoneTwoTone twoToneColor="#8FD89F" className="icon-size " />
                </Col>
                <Col span={20}>
                    <Text weight="semi-bold" color="smoke">
                        0850-532-3600
                    </Text>
                </Col>
            </Row>
            <Row align="middle" justify="start">
                <Col span={4}>
                    <MailTwoTone twoToneColor="#ffbc41" className="icon-size " />
                </Col>
                <Col span={20}>
                    <Text weight="semi-bold" color="smoke">
                        tr.isletme@dongu.com
                    </Text>
                </Col>
            </Row>
            <Row align="middle" justify="start">
                <Col span={4}>
                    <HomeTwoTone twoToneColor="#F69F8E" className="icon-size " />
                </Col>
                <Col span={20}>
                    <Text weight="semi-bold" color="smoke">
                        Bebek, Çatalhöyük Sokaği No:10/1, 34342 Beşiktaş/İstanbul
                    </Text>
                </Col>
            </Row>
            <MapContainer align="middle" justify="center">
                <img className="img-map" src={map} alt="logo" />
            </MapContainer>
        </Col>
    </CustomModal>
);

export default ContactModal;
