/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomRow, CustomDivider, CustomButton, CustomDisabledButton } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import PacketRow from './PacketRow';
import SelectionRow from './SelectionRow';

const useUnload = (fn) => {
    const cb = useRef(fn);
    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args) => cb.current?.(...args);

        window.addEventListener('beforeunload', onUnload);

        return () => window.removeEventListener('beforeunload', onUnload);
    }, []);
};

const ReceivePane = ({ setKey, packet, timeInt, setTimeInt }) => {
    const [finishStatus, setfinishStatus] = useState(false);

    const history = useHistory();

    const removeSelected = () => {
        console.log('remove çalıştı');
    };

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {
            if (window.confirm('Do you want to go back ?')) {
                removeSelected();
                setfinishStatus(true);
                history.push('/restoran-bul');
            } else {
                window.history.pushState(null, null, window.location.pathname);
                setfinishStatus(false);
            }
        }
    };

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        if (timeInt !== 0) {
            window.addEventListener('popstate', onBackButtonEvent);
            return () => {
                window.removeEventListener('popstate', onBackButtonEvent);
            };
        }
    }, [timeInt]);

    const devamEt = () => {
        setKey('3');
        /* const info = {
            is_available: false,
        };
        dispatch(patchStoreTime(info, timeInt)); */
    };

    useUnload((e) => {
        if (timeInt !== 0) {
            removeSelected();
        }
        e.preventDefault();
        e.returnValue = false;
        if (e.returnValue) {
            console.log();
        }
    });

    return (
        <CustomRow>
            <PacketRow packet={packet} />
            <CustomDivider />
            <SelectionRow packet={packet} timeInt={timeInt} setTimeInt={setTimeInt} />
            {/* The following part has been removed as per requested, however, uncommenting it shall make it work directly, functionalities are already implemented. */}
            <CustomRow className="button-row removeSmall" justify="center">
                {timeInt || packet ? (
                    <CustomButton type="secondary" onClick={() => devamEt()}>
                        <Text weight="semi-bold" size="16px" color="white">
                            Devam Et
                        </Text>
                    </CustomButton>
                ) : (
                    <CustomDisabledButton type="secondary" disabled>
                        <Text weight="semi-bold" size="16px" color="white">
                            Devam Et
                        </Text>
                    </CustomDisabledButton>
                )}
            </CustomRow>
        </CustomRow>
    );
};

export default ReceivePane;
