/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Spin, Row, Col, Input, Divider } from 'antd';
import { CustomCard } from './styles';
import { Text } from '../../../../components/Typography/styles';
import QuestionItem from './QuestionItem';
import { Content } from '../Categories/styles';

const QuestionCard = ({ faqs, questionTitle, questionsData }) => {
    const [filteredFaqs, setFilteredFaqs] = useState();
    const [search, setSearch] = useState();

    useEffect(() => {
        if (search !== '') {
            const searchedName = faqs?.filter((faq) => faq?.question?.toLocaleLowerCase().includes(search?.toLocaleLowerCase()));
            setFilteredFaqs(searchedName);
        } else if (faqs) {
            setFilteredFaqs(faqs);
        }
    }, [search]);

    const Title = ({ text }) => (
        <Row align="middle" justify="end">
            <Col md={8} lg={8} xl={8} xxl={7}>
                <Text size="smallTitle" weight="light" style={{ paddingLeft: '12px' }}>
                    {text}
                </Text>
            </Col>
        </Row>
    );

    return faqs ? (
        <CustomCard>
            <Row style={{ justifyContent: 'center' }}>
                <Col style={{ textAlign: 'center', fontWeight: 'normal', fontSize: '1.5rem' }} md={14}>
                    {questionTitle}
                </Col>
                <Col md={10}>
                    <Input size="large" placeholder="Ara" onChange={(e) => setSearch(e.target.value)} />
                </Col>
            </Row>
            <Divider />
            <Content style={{ height: '100%' }}>
                {filteredFaqs
                    ? filteredFaqs.map(
                          (faq) => faq.sub_category === questionsData && <QuestionItem id={faq.id} question={faq.question} answer={faq.answer} />
                      )
                    : faqs.map(
                          (faq) => faq.sub_category === questionsData && <QuestionItem id={faq.id} question={faq.question} answer={faq.answer} />
                      )}
            </Content>
        </CustomCard>
    ) : (
        <CustomCard title={<Title text={questionTitle} />}>
            <Row align="middle" justify="center" style={{ marginTop: '32px' }}>
                <Spin size="large" />
            </Row>
        </CustomCard>
    );
};

export default QuestionCard;
