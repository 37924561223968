import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Wrapper = styled(Col)`
    height: auto;
    margin-left: 7%;
    margin-right: 2.5%;
`;

export const TextStyle = styled(Row)`
    margin-top: 6%;
`;

export const ButtonWrapper = styled(Row)`
    margin-top: 8%;
    justify-content: space-around;
    min-height: 48px;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const ContentWrapper = styled(Row)`
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    border: 1px solid #d1d0d0;
    min-height: 48px;
    border-radius: 5px;
    padding: 0.3em;

    @media (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const CustomRow = styled(Row)`
    width: 100%;
    margin-right: 3%;
`;

export const CustomCol = styled(Col)`
    @media (max-width: 1024px) {
        margin-top: 0.5rem;
        max-width: 100%;
        width: 100%;
    }
`;
