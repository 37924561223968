import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../../components/Button';

export const Label = styled.div`
    border: 2px solid #f2f2fc;
    border-radius: 3px;
    padding: 8px 12px;

    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :hover {
        cursor: grab;
    }
`;

export const FoodLabelStyles = styled(Row)`
    .ant-ribbon {
        border-radius: 90% !important;
        top: -8px !important;
        padding: 0px;
    }
    .ant-ribbon-placement-end {
        border-bottom-right-radius: 90% !important;
    }

    .ant-ribbon-corner {
        border: none !important;
    }
`;

export const CustomButton = styled(Button)`
    padding: 0px !important;
    width: 20px;
    height: initial !important;
    border: none !important;

    :hover {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;

        background: ${({ theme }) => theme.primaryColor} !important;
        border: none !important;
        border-radius: 90% !important;
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
`;
