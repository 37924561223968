import React from 'react';
import './styles.css';

const FloatingLabel = ({ component = 'input', style, placeholder, className = '', ...rest }) => (
    <label className={`floating-label ${className} ${rest.value ? 'floating' : ''}`} style={style} htmlFor={rest.id}>
        {React.createElement(component, rest)}
        <span>{placeholder}</span>
    </label>
);

export default FloatingLabel;
