import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Wrapper = styled(Row)`
    min-height: 100px;
`;
export const Content = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;
