import styled from 'styled-components';
import { Row } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.card-row {
        margin-top: 10px;
        border: 2px solid #f2f2fc;
        height: auto;
        padding: 20px 10px;
        border-radius: 4px;
    }

    &.margin-top-5 {
        margin-top: 5px;
    }
`;
