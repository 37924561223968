/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { CustomRow, CustomCol, StyledSlider } from './styles';
import Customers from '../Card/Card';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';

const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
};

export default function MainContent({ storeData, filterCity, keyword }) {
    const [coords, setCoords] = useState(null);
    const { xs } = useResponsive();

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            const tempcoords = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            setCoords(tempcoords);
        });
    }, []);
    return (
        <CustomRow gutter={[10, 0]}>
            {xs ? (
                <Col>
                    <StyledSlider {...settings}>
                        {storeData &&
                            storeData.length > 0 &&
                            storeData
                                .filter((item) => (keyword ? item?.name?.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase()) : true))
                                .filter((item) => (filterCity ? item.province === filterCity : true))
                                .map((element, i) => {
                                    if (element.categories.length > 0) {
                                        return (
                                            <CustomCol key={i} xxl={{ span: 8 }} span={24}>
                                                <Customers data={element} coordinates={coords} />
                                            </CustomCol>
                                        );
                                    }
                                    return <></>;
                                })}
                    </StyledSlider>
                </Col>
            ) : (
                storeData &&
                storeData.length > 0 &&
                storeData
                    .filter((item) => (keyword ? item?.name?.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase()) : true))
                    .filter((item) => (filterCity ? item.province === filterCity : true))
                    .map((element, i) => {
                        if (element.categories.length > 0) {
                            return (
                                <CustomCol key={i} xxl={{ span: 8 }} span={11}>
                                    <Customers data={element} coordinates={coords} />
                                </CustomCol>
                            );
                        }
                        return <></>;
                    })
            )}
        </CustomRow>
    );
}
