import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../components/Button';

export const Wrapper = styled(Row)`
    justify-content: space-evenly;
    width: 100%;
    padding-bottom: 10%;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const ButtonStyle = styled(Button)`
    height: 48px;
    margin-top: 5%;
    width: 37%;
    border-radius: 5px;
    font-size: 16px;
    color: #8fd89f;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;
