export const GET_PACKAGE_INFO = 'GET_PACKAGE_INFO';
export const GET_PACKAGE_INFO_SUCCESS = 'GET_PACKAGE_INFO_SUCCESS';
export const GET_PACKAGE_INFO_FAIL = 'GET_PACKAGE_INFO_FAIL';

export const GET_DRAFT_INFO = 'GET_DRAFT_INFO';
export const GET_DRAFT_INFO_SUCCESS = 'GET_DRAFT_INFO_SUCCESS';
export const GET_DRAFT_INFO_FAIL = 'GET_DRAFT_INFO_FAIL';

export const UPDATE_PACKAGE_INFO = 'UPDATE_PACKAGE_INFO';
export const UPDATE_PACKAGE_INFO_SUCCESS = 'UPDATE_PACKAGE_INFO_SUCCESS';
export const UPDATE_PACKAGE_INFO_FAIL = 'UPDATE_PACKAGE_INFO_FAIL';

export const UPDATE_PACKAGE_AMOUNT = 'UPDATE_PACKAGE_AMOUNT';
export const UPDATE_PACKAGE_AMOUNT_SUCCESS = 'UPDATE_PACKAGE_AMOUNT_SUCCESS';
export const UPDATE_PACKAGE_AMOUNT_FAIL = 'UPDATE_PACKAGE_AMOUNT_FAIL';

export const GET_DELIVERY_INFO = 'GET_DELIVERY_INFO';
export const GET_DELIVERY_INFO_SUCCESS = 'GET_DELIVERY_INFO_SUCCESS';
export const GET_DELIVERY_INFO_FAIL = 'GET_DELIVERY_INFO_FAIL';
