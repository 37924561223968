/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CalendarMonthly, { Content, Item, Spin } from './styles';
import CalendarWeekly from './WeeklyCalendar/index';
import Cell from './Cell/index';
import Sider from './CalendarSider/index';
import Header from './CalendarHeader/index';
import { getMonthlyOrders } from '../../../../../services/Redux/Dashboard/CalendarPage/api';

moment.updateLocale('en-gb', {
    weekdaysMin: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
    months: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
});

const Calendar = () => {
    const format = 'YYYY-MM-DD';
    const dispatch = useDispatch();
    const [ordersToRender, setOrdersToRender] = useState([]);
    const [showMonths, setShowMonths] = useState(false);
    const [month, setMonth] = useState(0);
    const [week, setWeek] = useState(0);
    const [selectedDate, setSelectedDate] = useState(moment());
    const value = moment().add(month, 'month');
    value.add(week, 'week');

    useEffect(() => {
        dispatch(getMonthlyOrders());
        setSelectedDate(moment().add(month, 'months'));
    }, [showMonths, week, month]);

    const { monthlyOrders } = useSelector((state) => state.calendarPage);

    useEffect(() => {
        if (monthlyOrders) {
            setOrdersToRender(monthlyOrders);
        }
    }, [monthlyOrders]);

    return (
        <Content>
            <Item xs={24} xl={24} xxl={24}>
                <Header showMonths={setShowMonths} setMonth={setMonth} month={month} setWeek={setWeek} week={week} value={value} />
            </Item>
            {showMonths ? (
                <Content>
                    {!ordersToRender ? (
                        <Spin size="large" />
                    ) : (
                        <>
                            <Item xl={18}>
                                <CalendarMonthly
                                    value={value}
                                    fullscreen
                                    dateFullCellRender={(date) => (
                                        <Cell
                                            date={date}
                                            currentMonth={value.month()}
                                            setSelectedDate={setSelectedDate}
                                            selectedDate={selectedDate}
                                            orders={ordersToRender.filter((days) => days.start_date.includes(date.format(format)))}
                                        />
                                    )}
                                    headerRender={() => null}
                                />
                            </Item>
                            <Item xl={6}>
                                <Sider
                                    selectedDate={selectedDate}
                                    localData={moment.localeData()} // sends months and weekdays as strings
                                    orders={ordersToRender.filter((days) => days.start_date.includes(selectedDate.format(format)))}
                                />
                            </Item>
                        </>
                    )}
                </Content>
            ) : (
                <Content>
                    {ordersToRender.length > 0 ? (
                        <Item xxl={24}>
                            <CalendarWeekly value={value} orders={ordersToRender} />
                        </Item>
                    ) : (
                        <Spin size="large" />
                    )}
                </Content>
            )}
        </Content>
    );
};

export default Calendar;
