/* eslint-disable no-console */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import { useState } from 'react';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import StoreCard, { Content, Item, CardTitle, Input, Divider, EditButton, SendButton, Text, Upload, Logo, Button, Address, Form } from './styles';
import AddImage from '../../../../../../Assets/Dashboard/add-button.png';
import EditImage from '../../../../../../Assets/Dashboard/edit-button.png';
import { updateStoreInfo } from '../../../../../../services/Redux/Dashboard/StorePage/api';

const StoreInfoCard = ({ storeInfo }) => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [logo, setLogo] = useState(storeInfo.photo);
    const [logoFile, setLogoFile] = useState();
    const [background, setBackground] = useState(storeInfo.background);
    const [backgroundFile, setBackgroundFile] = useState();

    const { id, name, address, post_code, city, phone_number, phone_number_2, email, website_link, store_owner } = storeInfo;

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = (info, setter, fileSetter) => {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => setter(imageUrl));
        fileSetter(info.file.originFileObj);
    };

    const updateStoreInformation = (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('address', values.address);
        formData.append('post_code', values.post_code);
        formData.append('city', values.city);
        formData.append('phone_number', values.phone_number);
        formData.append('phone_number_2', values.phone_number_2);
        formData.append('email', values.email);
        formData.append('website_link', values.website_link);
        formData.append('favorited_by', 1); // it will be deleted when backend api changed because we dont need it
        formData.append('store_owner', store_owner.id); // it will be deleted when backend api changed because we dont need it
        if (logoFile) {
            formData.append('photo', logoFile);
        }
        if (backgroundFile) {
            formData.append('background', backgroundFile);
        }

        dispatch(updateStoreInfo(formData, id));
    };

    const onFinish = (values) => {
        updateStoreInformation(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            disabled={disabled}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ name, address, post_code, city, phone_number, phone_number_2, email, website_link }}>
            <StoreCard title={<CardTitle>Mağaza Bilgisi</CardTitle>}>
                <Content>
                    <Item span={24}>
                        <Text color="smoke" family="secondary">
                            Mağazanız hakkında kaydettiğimiz bilgileri burada görebilirsiniz.
                        </Text>
                        <Text color="smoke" family="secondary">
                            Bu bilgilerden herhangi biri yanlışsa ve değiştirilmesi gerekiyorsa, lütfen bizimle iletişime geçin.
                        </Text>
                        <Item span={24} style={{ marginTop: '32px' }}>
                            <Text weight="semi-bold">Mağaza Detayı:</Text>
                        </Item>
                    </Item>
                </Content>
                <Content justify="start">
                    <Item span={6} md={4} xl={5} xxl={5} style={{ marginTop: '10px' }}>
                        <Text weight="semi-bold">Logo</Text>
                        <Item>
                            <Logo src={logo} alt="logo" />
                            <Upload
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    handleChange(info, setLogo, setLogoFile);
                                }}
                                showUploadList={false}>
                                <Button disabled={disabled}>
                                    <img src={AddImage} alt="" />
                                    <Text size="description" family="secondary" weight="bold">
                                        Ekle
                                    </Text>
                                </Button>
                            </Upload>
                        </Item>
                        <Text weight="semi-bold" style={{ marginTop: '203px' }}>
                            Arka Plan
                        </Text>
                        <Item>
                            <Logo src={background} alt="background-image" />
                            <Upload
                                onChange={(info) => {
                                    handleChange(info, setBackground, setBackgroundFile);
                                }}
                                showUploadList={false}>
                                <Button disabled={disabled}>
                                    <img src={AddImage} alt="" />
                                    <Text size="description" family="secondary" weight="bold">
                                        Ekle
                                    </Text>
                                </Button>
                            </Upload>
                        </Item>
                    </Item>
                    <Item md={2} xl={2} xxl={2}>
                        <Divider type="vertical" />
                    </Item>
                    <Item md={17} xl={16} xxl={16} style={{ marginTop: '10px' }}>
                        <Text weight="semi-bold">İsim</Text>

                        <Form.Item name="name" rules={[{ required: true, message: 'İsim boş geçilemez.' }]}>
                            <Input placeholder="İsim" disabled={disabled} />
                        </Form.Item>

                        <Text weight="semi-bold" style={{ marginTop: '32px' }}>
                            Adres
                        </Text>
                        <Form.Item name="address" rules={[{ required: true, message: 'Adres boş geçilemez.' }]}>
                            <Address placeholder="Adres" disabled={disabled} />
                        </Form.Item>

                        <Content style={{ marginTop: '32px' }}>
                            <Item md={11} xxl={11}>
                                <Text weight="semi-bold">Posta Kodu</Text>
                                <Form.Item name="post_code" rules={[{ required: true, message: 'Posta Kodu boş geçilemez.' }]}>
                                    <Input placeholder="Posta Kodu" disabled={disabled} />
                                </Form.Item>
                                <Text weight="semi-bold" style={{ marginTop: '32px' }}>
                                    Telefon 1
                                </Text>
                                <Form.Item name="phone_number" rules={[{ required: true, message: 'Telefon boş geçilemez.' }]}>
                                    <Input placeholder="Telefon 1" disabled={disabled} />
                                </Form.Item>

                                <Text weight="semi-bold" style={{ marginTop: '32px' }}>
                                    E-mail
                                </Text>
                                <Form.Item name="email" rules={[{ required: true, message: 'Email boş geçilemez.' }]}>
                                    <Input type="email" placeholder="ornek@ornek.com" disabled={disabled} />
                                </Form.Item>
                            </Item>
                            <Item md={{ span: 12, offset: 1 }} xxl={{ span: 11, offset: 2 }}>
                                <Text weight="semi-bold">Şehir</Text>
                                <Form.Item name="city" rules={[{ required: true, message: 'Şehir boş geçilemez.' }]}>
                                    <Input placeholder="Şehir" disabled={disabled} />
                                </Form.Item>

                                <Text weight="semi-bold" style={{ marginTop: '32px' }}>
                                    Telefon 2
                                </Text>
                                <Form.Item name="phone_number_2">
                                    <Input placeholder="Telefon 2" disabled={disabled} />
                                </Form.Item>

                                <Text weight="semi-bold" style={{ marginTop: '32px' }}>
                                    Web Sitesi
                                </Text>
                                <Form.Item name="website_link">
                                    <Input placeholder="ornek.com.tr" disabled={disabled} />
                                </Form.Item>
                            </Item>
                        </Content>
                    </Item>
                    <Content gutter={[32, 0]}>
                        <Item xxl={{ span: 5, offset: 13 }} xl={{ span: 6, offset: 11 }} lg={{ span: 5, offset: 12 }} md={{ span: 6, offset: 10 }}>
                            <EditButton onClick={() => setDisabled(!disabled)}>
                                <img src={EditImage} alt="" />
                                <Text weight="semi-bold">Düzenle</Text>
                            </EditButton>
                        </Item>
                        <Item xxl={6} xl={7} lg={7} md={8}>
                            <Form.Item style={{ margin: 0 }}>
                                <SendButton type="secondary" htmlType="submit">
                                    <Text color="white" weight="semi-bold">
                                        Onaya Gönder
                                    </Text>
                                </SendButton>
                            </Form.Item>
                        </Item>
                    </Content>
                </Content>
            </StoreCard>
        </Form>
    );
};

export default StoreInfoCard;
