import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { MenuPreviewCardStyles, Header, Content, CustomSearchBar } from './styles';
import CategoryItem from './CategoryItem';
import { Text } from '../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const MenuPreviewCard = ({ mealCategories }) => {
    let categories = null;
    if (!mealCategories) {
        const selector = useSelector;
        categories = selector((state) => state.boxStore.categoryList);
    } else {
        categories = mealCategories;
    }

    if (!mealCategories) {
        return (
            <MenuPreviewCardStyles title={<Title text="Örnek Restoran Menü" />}>
                <Header align="middle" justify="center">
                    <Col span={22}>
                        <CustomSearchBar />
                    </Col>
                </Header>
                <Content>
                    {categories && categories.length > 0 ? (
                        categories.map(({ id, name, meals }) => <CategoryItem catId={id} categoryName={name} meals={meals} />)
                    ) : (
                        <div>nodata</div>
                    )}
                </Content>
            </MenuPreviewCardStyles>
        );
    }
    return (
        <>
            <Header align="middle" justify="center">
                <Col span={23}>
                    <CustomSearchBar />
                </Col>
            </Header>
            <Content>
                {categories && categories.length > 0 ? (
                    categories.map(({ id, name, meals }) => <CategoryItem catId={id} categoryName={name} meals={meals} />)
                ) : (
                    <div>nodata</div>
                )}
            </Content>
        </>
    );
};

export default MenuPreviewCard;
