import { Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { OutSide, LeftContainer, TitleRow, CustomDivider, CustomRow } from './styles';
import { Text } from '../../../components/Typography/styles.js';
import { ReactComponent as UserLogo } from '../../../../Assets/Shared/CartPage/user-logo.svg';
import Content from './Content';

const NotificationPage = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const history = useHistory();

    if (user === null) {
        history.push('/business');
    }

    if (user !== null) {
        return (
            <OutSide>
                <Col span={22}>
                    <LeftContainer>
                        <TitleRow justify="center" align="middle">
                            <Text size="22px" weight="light" color="smoke">
                                Bildirimlerim
                            </Text>
                            <CustomDivider />
                        </TitleRow>
                        <CustomRow className="main-row" justify="center">
                            <Col span={1}>
                                <CustomRow align="middle" justify="end">
                                    <UserLogo />
                                </CustomRow>
                            </Col>
                            <Col span={22}>
                                <CustomRow className="user-row" justify="space-between">
                                    <Col>
                                        <Text>
                                            <Text weight="light">Hoş geldin</Text>&nbsp;
                                            {user.first_name} {user.last_name}
                                        </Text>
                                    </Col>
                                </CustomRow>
                                <CustomDivider className="user-divider" />
                            </Col>
                        </CustomRow>
                        <Content />
                    </LeftContainer>
                </Col>
            </OutSide>
        );
    }
    return <></>;
};

export default NotificationPage;
