/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import moment from 'moment';
import { CustomModal, Wrapper, MainWrapper } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);
const active = ['1', '2', '3', '9'];
const completed = ['6'];
const canceled = ['4', '5'];

const DetailModal = ({ onCancel, order, visible }) => {
    const [store, setStore] = useState();
    const [orderStatus, setOrderStatus] = useState();

    useEffect(() => {
        if (order) {
            setStore(order.boxes[0].store);
        }

        if (order) {
            if (active.includes(order.status)) {
                setOrderStatus('Aktif');
            }
            if (canceled.includes(order.status)) {
                setOrderStatus('Sipariş iptal edildi');
            }
            if (completed.includes(order.status)) {
                setOrderStatus('Tamamlandı');
            }
        }
    }, [order]);

    return (
        <CustomModal onCancel={onCancel} visible={visible} title={<Title text="Sipariş Detayı" />}>
            {store !== undefined ? (
                <Row>
                    <MainWrapper>
                        <Col span={16}>
                            <Text weight="semi-bold">Siparişin Gönderildiği Kullanıcı Bilgisi:</Text>
                            <Row style={{ marginTop: '20px' }}>
                                <Col span={12}>
                                    <Text weight="semi-bold">Gönderilen: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {order?.user?.first_name} {order?.user?.last_name}
                                    </Text>
                                </Col>
                                <Col span={12} justify="end">
                                    <Text weight="semi-bold">Referans Kodu: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {order?.ref_code}
                                    </Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col span={12}>
                                    <Text weight="semi-bold">Gönderim Tarihi: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {moment(order?.buying_time).format('LL')}
                                    </Text>
                                </Col>
                                <Col span={12} justify="end">
                                    <Text weight="semi-bold">Gönderim Zamanı: </Text>
                                    {order?.courier_time ? (
                                        <Text weight="light" style={{ marginLeft: '10px' }}>
                                            {moment(order?.courier_time?.start_date).format('HH:mm')}
                                            {' - '}
                                            {moment(order?.courier_time?.end_date).format('HH:mm')}
                                        </Text>
                                    ) : null}
                                </Col>
                            </Row>
                            <Col style={{ marginTop: '20px' }}>
                                <Text weight="semi-bold">Alıcı Adresi: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {order?.address?.address} {order?.address?.street} {order?.address?.city}/{order?.address?.country}
                                </Text>
                            </Col>
                            <Col style={{ marginTop: '20px' }}>
                                <Text weight="semi-bold">Paket: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {order?.boxes?.length} Paket
                                </Text>
                            </Col>

                            {order?.boxes &&
                                order?.boxes?.map((value) => (
                                    <Col style={{ marginTop: '5px' }}>
                                        <Text weight="light" style={{ marginLeft: '75px' }}>
                                            {value.text_name} ( {value.meals.map((meal) => `${meal.name} ,`)})
                                        </Text>
                                    </Col>
                                ))}
                            <Row style={{ marginTop: '20px' }}>
                                <Col span={12}>
                                    <Text weight="semi-bold">Sipariş Tutarı: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {order?.cost} TL
                                    </Text>
                                </Col>
                                <Col span={12} justify="end">
                                    <Text weight="semi-bold">Teslimat Türü: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {order?.delivery_type === '1'
                                            ? 'Gel-Al paket'
                                            : order?.delivery_type === '2'
                                            ? 'Motorlu kurye'
                                            : 'Gel-Al paket ve motorlu kurye'}
                                    </Text>
                                </Col>
                            </Row>
                            <Col style={{ marginTop: '20px' }}>
                                <Text weight="semi-bold">Ödeme Şekli: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    Online Kredi/Kredi Kartı - Garanti Bankası
                                </Text>
                            </Col>
                        </Col>

                        <Col span={8}>
                            <Row style={{ marginTop: '55px' }}>
                                <Text weight="semi-bold">Sipariş Durumu: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {orderStatus}
                                </Text>
                            </Row>
                            <Row style={{ marginTop: '75px' }}>
                                <Text weight="semi-bold">Telefon:</Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {order?.user?.phone_number}
                                </Text>
                            </Row>
                            <Row style={{ marginTop: '28px' }}>
                                <Text weight="semi-bold">E-mail: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {order?.user.email}
                                </Text>
                            </Row>
                            <Row style={{ marginTop: '50px' }}>
                                <Text weight="semi-bold">Ödenen Toplam Tutar: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {order?.cost} TL
                                </Text>
                            </Row>
                        </Col>
                    </MainWrapper>
                    <Wrapper>
                        <Col span={16}>
                            <Text weight="semi-bold">Siparişin Çıktığı Restorant Bilgisi:</Text>
                            <Row style={{ marginTop: '2 0px' }}>
                                <Col span={12}>
                                    <Text weight="semi-bold">ID: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {store?.id}
                                    </Text>
                                </Col>
                                <Col span={12} justify="end">
                                    <Text weight="semi-bold">İşletme Adı:</Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {store?.name}
                                    </Text>
                                </Col>
                            </Row>
                            <Col style={{ marginTop: '20px' }}>
                                <Text weight="semi-bold">Adres: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {store?.address} - {store.city}
                                </Text>
                            </Col>
                            <Row style={{ marginTop: '20px' }}>
                                <Col span={12}>
                                    <Text weight="semi-bold">Telefon 1: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {store?.phone_number}
                                    </Text>
                                </Col>
                                <Col span={12} justify="end">
                                    <Text weight="semi-bold">Telefon 2: </Text>
                                    <Text weight="light" style={{ marginLeft: '10px' }}>
                                        {store?.phone_number_2}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row style={{ marginTop: '90px' }}>
                                <Text weight="semi-bold">Web Sitesi: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {store?.website_link}
                                </Text>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Text weight="semi-bold">E-mail: </Text>
                                <Text weight="light" style={{ marginLeft: '10px' }}>
                                    {store?.email}
                                </Text>
                            </Row>
                        </Col>
                    </Wrapper>
                </Row>
            ) : (
                <Row>
                    <Spin />
                </Row>
            )}
        </CustomModal>
    );
};

export default DetailModal;
