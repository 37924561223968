import React, { useState } from 'react';
import { Badge, Row, message } from 'antd';
import { useDispatch } from 'react-redux';
import { Label, FoodLabelStyles, CustomButton } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import xIcon from '../../../../../../../Assets/Dashboard/x-marks.png';
import { fetchFavouriteMeals } from '../../../../../../../services/Redux/Dashboard/favourites/api';
import xmlDelete from '../../../../../../../services/Api/request';

const Title = ({ foodId }) => {
    const dispatch = useDispatch();

    const RemoveItem = async (id) => {
        await xmlDelete({
            path: `box/favorite-meal/${id}/`,
            method: 'DELETE',
            sendToken: true,
        })
            .then(() => {
                dispatch(fetchFavouriteMeals());
            })
            .catch((error) => {
                message.error('Favoriden Kaldırılırken  bir Hata Oluştu!');
            });
    };

    return (
        <Row align="middle" justify="center">
            <CustomButton onClick={() => RemoveItem(foodId)} type="link">
                <img src={xIcon} alt="x" />
            </CustomButton>
        </Row>
    );
};

const FoodLabel = ({ foodId, name, favourite }) => {
    const [isShown, setIsShown] = useState(false);
    return (
        <FoodLabelStyles onMouseLeave={() => setIsShown(false)} onMouseEnter={() => setIsShown(true)}>
            <Badge.Ribbon placement="end" text={isShown && <Title isFavouriteItem={favourite} foodId={foodId} text="X" />}>
                <Label>
                    <Text family="secondary">{name}</Text>
                </Label>
            </Badge.Ribbon>
        </FoodLabelStyles>
    );
};

export default FoodLabel;
