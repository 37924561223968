import { Row } from 'antd';
import styled from 'styled-components';
import Button from '../../../../../components/Button/index';
import { Text } from '../../../../../components/Typography/styles';

export const DividerStyle = styled(Row)`
    background-color: #f2f2fc;
    width: 100%;
    height: 4px;
    margin-bottom: 1.5%;
    margin-top: 10px;

    &.light {
        margin-top: 10px;
        height: 2px;
        margin-left: 30px;
        width: 96%;
    }
`;

export const ItemRow = styled(Row)`
    display: block-inline;
    margin-left: 30px;
`;

export const PacketRow = styled(Row)`
    margin-top: 30px;
    margin-left: 50px;
    margin-bottom: 30px;
    width: 100%;
`;

export const DiscountTextStyle = styled(Text)`
    margin-left: -5px;

    @media (min-width: 1281px) {
        margin-left: 30px;
    }
    text-decoration: line-through;
`;

export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    width: 80px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 0px;
`;

export const InformationStyles = styled.div`
    margin-top: 15px;
`;

export const CustomBtn = styled(Button)`
    width: 120px;
    height: 40px;
`;

export const TopRow = styled(Row)`
    width: 100%;
`;

export const BottomRow = styled(Row)`
    margin-top: -5px;
`;
