import { Row, Col } from 'antd';
import styled from 'styled-components';
import { HORIZONTAL_PADDING } from '../../../../../../../helpers/constants';

export const HeaderDiv = styled.div`
    z-index: 1000;
    overflow: hidden;
    height: ${({ theme }) => theme.layoutStyles.headerHeight};
    background: ${({ theme }) => theme.layoutStyles.headerColor};
    padding: ${({ theme }) => theme.layoutStyles.headerPaddingVertical} ${HORIZONTAL_PADDING};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    -moz-box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    box-shadow: 0 3px 6px rgba(112, 112, 112, 0.23);
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
        border: none !important;
        color: black !important;
    }
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
        border: none !important;
        color: black !important;
    }

    .ant-menu-horizontal {
        border: none;
        color: black !important;
    }

    .dispose {
        display: none;
        color: black !important;
    }

    .guest-icon-styles {
        :hover {
            cursor: pointer;
        }
    }

    .ant-menu {
        align-items: middle !important;
        justify-content: flex-end !important;
    }
`;

export const StyledRow = styled(Row)`
    min-height: 56px;
    padding: 10px 0 10px 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #ffffff;
`;

export const StyledPrice = styled(Col)`
    height: 36px;
    width: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f5f9fc;
    color: #8fd89f;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
`;

export const StyledDiscounted = styled(Col)`
    text-decoration: line-through;
    text-decoration-style: solid;
    height: 36px;
    width: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #f5f9fc;
    color: #8fd89f;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;
`;

export const StyledButton = styled.button`
    background-color: #8fd89f;
    border: none;
    border-radius: 8px;
    width: 90%;
    height: 48px;
`;

export const StyledWarning = styled.div`
    width: 90%;
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
`;
