import styled from 'styled-components';
import { between } from 'polished';
import { Row, Col, Select as select, Divider as divider, Input, Switch as switcher } from 'antd';
import card from '../../../../../components/Card/index';
import { Text as text } from '../../../../../components/Typography/styles';
import button from '../../../../../components/Button/index';

const { TextArea } = Input;

export default styled(card)`
    min-height: 671px;
    width: 100%;
    max-width: 579px;
    margin-bottom: 32px;

    .ant-card-head-title {
        text-align: center;
    }

    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 31px;
    }

    img {
        width: 69px;
        height: 48px;
        margin-right: 10px;
    }

    @media only screen and (min-width: 1200px) {
        margin-left: 32px;
    }
`;
export const Content = styled(Row)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Switch = styled(switcher)``;

export const Item = styled(Col)`
    display: flex;
    align-items: center;
`;
export const Divider = styled(divider)`
    margin-top: 35px;
    margin-bottom: 35px;

    height: 2px;
    width: 100%;
    background: ${({ theme }) => theme.borderColor};
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Text = styled(text)``;

export const Suggestion = styled(TextArea)`
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    margin-top: 40px;
    height: 100px !important;
    resize: none;
`;

export const SaveButton = styled(button)`
    height: 48px !important;
    width: 100%;
    max-width: 212px;
    margin-top: 28px;
    font-weight: 600;
`;

export const Select = styled(select)`
    width: 100%;
    .ant-select-selector {
        height: 48px !important;
    }
    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }
    max-width: 342px;
`;
