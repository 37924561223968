import { message } from 'antd';
import * as actionTypes from './actionTypes';

// GET BASKET
export const getBasketStartAction = () => ({
    type: actionTypes.GET_BASKET,
});

export const getBasketSuccessAction = (data) => ({
    type: actionTypes.GET_BASKET_SUCCESS,
    payload: data,
});

export const getBasketFailAction = (error) => ({
    type: actionTypes.GET_BASKET_FAIL,
    payload: error,
});

// POST ADD BASKET
export const postBasketAddStartAction = () => ({
    type: actionTypes.POST_BASKET_ADD,
});

export const postBasketAddSuccessAction = (data) => {
    message.success('Sepete Eklendi');
    return {
        type: actionTypes.POST_BASKET_ADD_SUCCESS,
        payload: data,
    };
};

export const postBasketAddFailAction = (error) => ({
    type: actionTypes.POST_BASKET_ADD_FAIL,
    payload: error,
});

// POST REMOVE BASKET
export const postBasketRemoveStartAction = () => ({
    type: actionTypes.POST_BASKET_REMOVE,
});

export const postBasketRemoveSuccessAction = (data) => {
    message.success('Sepetten Silindi');
    return {
        type: actionTypes.POST_BASKET_REMOVE_SUCCESS,
        payload: data,
    };
};

export const postBasketRemoveFailAction = (error) => {
    message.error('Sepetten Silinemedi');
    return {
        type: actionTypes.POST_BASKET_REMOVE_FAIL,
        payload: error,
    };
};

// POST REMOVE ALL BASKET
export const postBasketRemoveAllStartAction = () => ({
    type: actionTypes.POST_BASKET_REMOVE_ALL,
});

export const postBasketRemoveAllSuccessAction = (data) => {
    message.success('Sepetten Silindi');
    return {
        type: actionTypes.POST_BASKET_REMOVE_ALL_SUCCESS,
        payload: typeof data === 'string' ? [] : data,
    };
};

export const postBasketRemoveAllFailAction = (error) => {
    message.error('Unable to delete from Basket');
    return {
        type: actionTypes.POST_BASKET_REMOVE_ALL_FAIL,
        payload: error,
    };
};

// CREATE ORDER
export const createOrderStartAction = () => ({
    type: actionTypes.CREATE_ORDER,
});

export const createOrderSuccessAction = (data) => ({
    type: actionTypes.CREATE_ORDER_SUCCESS,
    payload: data,
});

export const createOrderFailAction = (error) => ({
    type: actionTypes.CREATE_ORDER_FAIL,
    payload: error,
});

// GET ORDER
export const getOrderStartAction = () => ({
    type: actionTypes.GET_ORDER,
});

export const getOrderSuccessAction = (data) => ({
    type: actionTypes.GET_ORDER_SUCCESS,
    payload: data,
});

export const getOrderFailAction = (error) => ({
    type: actionTypes.GET_ORDER_FAIL,
    payload: error,
});

// RESET ADD ERROR
export const resetAddError = () => ({
    type: actionTypes.RESET_ADDERROR,
});
