import styled from 'styled-components';
import { Row, Col } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.packet-container {
        flex-direction: column;

        @media (min-width: 1024px) {
            flex-direction: row !important;
        }
    }

    &.packet-row {
        border: 1px solid #d1d0d0;
        width: 200px;
        border-radius: 5px;
        height: 48px;
        cursor: pointer;
    }

    &.kurye {
        margin-top: 10px;

        @media (min-width: 1024px) {
            margin-left: 20px;
            margin-top: 0;
        }
    }

    &.time-row {
        width: 150px;
        height: 48px;
        background: #f2f2fc;
        border-radius: 4px;

        @media (max-width: 1238px) {
            margin-top: 10px;
        }
    }
`;

export const CustomCol = styled(Col)`
    @media (max-width: 1279px) {
        max-width: 100% !important;
    }
`;
