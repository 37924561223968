export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
export const GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS';
export const GET_PAYMENT_INFO_FAIL = 'GET_PAYMENT_INFO_FAIL';

export const POST_PAYMENT_INFO = 'POST_PAYMENT_INFO';
export const POST_PAYMENT_INFO_SUCCESS = 'POST_PAYMENT_INFO_SUCCESS';
export const POST_PAYMENT_INFO_FAIL = 'POST_PAYMENT_INFO_FAIL';

export const PATCH_PAYMENT_INFO = 'PATCH_PAYMENT_INFO';
export const PATCH_PAYMENT_INFO_SUCCESS = 'PATCH_PAYMENT_INFO_SUCCESS';
export const PATCH_PAYMENT_INFO_FAIL = 'PATCH_PAYMENT_INFO_FAIL';
