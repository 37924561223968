import styled from 'styled-components';
import { Button, Row } from 'antd';
import { Text } from '../../../../../../../components/Typography/styles';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        padding: 20px 20px;
    }

    &.content-row {
        margin-top: 25px;
    }

    &.margin-bottom-together {
        margin-bottom: 10px;
    }

    &.margin-bottom-menu {
        margin-bottom: 25px;
    }

    &.order-number {
        margin-top: 30px;
        margin-bottom: 25px;
    }

    &.margin-bottom-price {
        margin-bottom: 14px;
    }

    &.margin-bottom-bag {
        margin-bottom: 30px;
    }
    &.margin-bottom-total {
        margin-bottom: 35px;
    }
`;

export const UnderlinedText = styled(Text)`
    text-decoration: underline;
`;

export const CustomButton = styled(Button)`
    padding: 0;

    &:hover {
        background: none;
    }
`;
