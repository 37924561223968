import React from 'react';
import { ErrorMessage } from './styles';

function CustomErrorMessage({ message }) {
    return (
        <div style={{ marginTop: '-20px' }}>
            <ErrorMessage size="13px" weight="bold" color="red">
                {message}
            </ErrorMessage>
        </div>
    );
}

export default CustomErrorMessage;
