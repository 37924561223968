import styled from 'styled-components';
import { Col, Row } from 'antd';
import CustomBtn from '../../../../../components/Button';

export const CategoryItemStyles = styled(Row)`
    transition: 0.2s;
`;
export const Header = styled(Row)`
    .title-styles {
        background-color: #fafafe;
        border-radius: 5px;
        padding: 5px 30px;
    }
`;
export const Content = styled(Col)`
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
`;

export const Btn = styled(CustomBtn)`
    padding: 2px 8px;
    height: 32px !important;
    background-color: #fafafe !important;
    border: none;
    width: 100%;
`;
