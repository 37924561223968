import styled from 'styled-components';
import { between } from 'polished';
import { Col, Row } from 'antd';
import { HORIZONTAL_PADDING } from '../../../../../helpers/constants';

export const ExplanationWrapper = styled.div`
    width: 100%;
    height: ${between('500px', '1080px', '780px', '1920px')};
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: 100%;
    background-repeat: no-repeat;
`;
export const UpWrapper = styled.div`
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: 35%;
    padding-top: ${between('15px', '230px', '780px', '1920px')};
    height: 50%;
`;

export const DownWrapper = styled.div`
    padding-left: 37%;
    padding-right: ${HORIZONTAL_PADDING};
    padding-top: ${between('15px', '230px', '780px', '1920px')};
    height: 50%;
`;
export const ExplanationMobileWrapper = styled(Col)`
    width: 100%;
`;
export const UpMobileWrapper = styled(Row)`
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    display: flex;
    justify-content: center;
    padding-bottom: ${between('10px', '30px')};
    padding-top: ${between('30px', '70px')};
    background-color: #f6f9fc;
`;

export const DownMobileWrapper = styled(Row)`
    padding-bottom: ${between('10px', '30px')};
    padding-top: ${between('30px', '70px')};
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};
    display: flex;
    background-color: #ddf3e2;
    justify-content: center;
`;
