/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */

import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { CustomRow, CustomCol } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import PacketAlarmModel from './PacketAlarmModel';
import { ReactComponent as CloseBtn } from '../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';

const NotificationCard = (props) => {
    const { content } = props;
    const [description, setDescription] = useState('');
    const [id, setId] = useState(0);
    const [mess, setMess] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [orderDetail, setOrderDetail] = useState(null);
    const { createOrderResp } = useSelector((state) => state?.customerOrderStore);

    useEffect(() => {
        if (createOrderResp) {
            setOrderDetail(createOrderResp);
        }
    }, [createOrderResp]);

    useEffect(() => {
        if (content.type === 8) {
            setDescription('🛒 Siparişiniz iptal edildi ❌');
        } else if (content.type === 16) {
            setDescription('🛒 Siparişiniz teslim edildi ✅');
        } else if (content.type === 7) {
            setDescription('🔔 Sürpriz Paket Alarmı');
            const message = content.message.split('order_id:');
            setMess(message[0]);
            setId(message[1]);
        }
    }, []);

    const openModal = () => {
        if (content.type === 7 && !modalVisible) {
            setModalVisible(true);
        }
    };

    const dismiss = () => {
        setModalVisible(false);
    };

    return (
        <CustomRow className="card" onClick={() => openModal()}>
            <CustomRow justify="space-between">
                <Col>
                    <Text size="14px" weight="light">
                        {description}
                    </Text>
                </Col>
                <Col>
                    <Text size="14px" weight="light">
                        {content.time_difference}
                    </Text>
                </Col>
            </CustomRow>
            <CustomRow justify="space-between" className="second-row" align="middle">
                <CustomCol className="notification-col" span={24}>
                    {content.type !== 7 ? <Text weight="semi-bold">{content.message}</Text> : <Text>{mess}</Text>}
                </CustomCol>
            </CustomRow>
            <PacketAlarmModel
                id={id}
                visible={modalVisible}
                dismiss={dismiss}
                orderDetail={orderDetail}
                closeIcon={<CloseBtn />}
                onCancel={dismiss}
            />
        </CustomRow>
    );
};

export default NotificationCard;
