/* eslint-disable no-console */
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import moment from 'moment';
import FooterStyles, { SaveButton, PacketCount, Item, Content } from './styles';
import { updateDailyPlan, getMonthlyOrders } from '../../../../../../../services/Redux/Dashboard/CalendarPage/api';

const Footer = ({ orderCount, updateWeekly }) => {
    const format = 'YYYY-MM-DD';
    const dispatch = useDispatch();

    const showMessage = () => {
        if (updateWeekly.length === 0) {
            message.error('Önce değişiklik yapmalısınız.');
        }
    };

    const updateWeeklyFormat = async () => {
        if (updateWeekly.length === 0) {
            console.log('empty');
        } else {
            const updateToday = updateWeekly.find((daily) => moment(daily.start_date).isSame(moment().format(format), 'date'));

            if (updateToday !== undefined) {
                const newObjectToday = {
                    id: updateToday.id,
                    time_label: updateToday.time_label,
                    is_active: updateToday.is_active,
                    detail: updateToday.detail,
                };

                const updateWeeklyArr = updateWeekly.map((daily) => (daily.id === updateToday.id ? newObjectToday : daily));

                await dispatch(updateDailyPlan(updateWeeklyArr));
                await dispatch(getMonthlyOrders());
            } else {
                await dispatch(updateDailyPlan(updateWeekly));
                await dispatch(getMonthlyOrders());
            }
        }
    };

    return (
        <FooterStyles>
            <Content align="middle">
                <Item sm={10} md={10} lg={10} xl={9} xxl={10}>
                    <PacketCount>{`Bu hafta toplam Paket Sayısı: ${orderCount}`}</PacketCount>
                </Item>
                <Item
                    sm={{ span: 8, offset: 6 }}
                    md={{ span: 7, offset: 7 }}
                    lg={{ span: 7, offset: 7 }}
                    xl={{ span: 6, offset: 9 }}
                    xxl={{ span: 4, offset: 10 }}>
                    <SaveButton
                        type="secondary"
                        family="secondary"
                        onClick={() => {
                            showMessage();
                            updateWeeklyFormat();
                        }}>
                        Değişiklikleri Kaydet
                    </SaveButton>
                </Item>
            </Content>
        </FooterStyles>
    );
};

export default Footer;
