import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { FoodItemStyles, Content, ContentRight, ContentLeft, FoodnameLabel, CustomText, TagRow } from './styles.js';
import emptyFile from '../../../../../../../Assets/Dashboard/empty-img.png';

const MenuItem = ({ name, labels, price, photo }) => {
    const [image] = useState(photo !== null && photo !== undefined ? photo : emptyFile);

    return (
        <FoodItemStyles span={24}>
            <Content align="top" justify="space-between">
                <ContentLeft xl={6} lg={3} md={4} sm={3}>
                    <img alt="fooditem" src={image} width="80px" />
                </ContentLeft>
                <ContentRight xl={16} lg={20} md={20} sm={19}>
                    <TagRow gutter={[0, 0]} justify="center">
                        <Col span={18} lg={24} md={24}>
                            <Row>
                                <CustomText className="name-size" size="non-regular" color="smoke">
                                    {name}
                                </CustomText>
                            </Row>
                            <Row>
                                <CustomText className="price-size" size="non-regular" color="smoke">
                                    {price}
                                </CustomText>
                            </Row>
                        </Col>
                    </TagRow>
                    <TagRow gutter={[0, 0]}>
                        {labels ? (
                            labels.map((item) => (
                                <FoodnameLabel className="foodlabel">
                                    <CustomText className="tag-text" size="non-regular" family="secondary" color="smoke" weight="light">
                                        {item.name}
                                    </CustomText>
                                </FoodnameLabel>
                            ))
                        ) : (
                            <div>nodata</div>
                        )}
                    </TagRow>
                </ContentRight>
            </Content>
        </FoodItemStyles>
    );
};

export default MenuItem;
