import styled from 'styled-components';
import { Row } from 'antd';
import Button from '../../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    &.wrapper-row {
        width: 90%;
        margin-left: 5%;
    }
    width: 100%;
    &.margin-t-20 {
        margin-top: 20px;
    }

    &.teslim-row {
        margin-top: 50px;
    }
`;

export const CustomButton = styled(Button)`
    width: 250px;
    height: 48px;
`;
