import * as actionTypes from './actionTypes';

const initialState = {
    loading: false,
    taxOfficeInfo: null,
    sendingRequestLoading: false,
};
// Get Payment Info
const getPaymentInfoReducer = (state) => ({
    ...state,
    loading: true,
});

const getPaymentInfoSuccessReducer = (state, action) => ({
    ...state,
    taxOfficeInfo: action.payload,
    loading: false,
});

const getPaymentInfoFailReducer = (state) => ({
    ...state,
    loading: false,
});

// Post Payment Info

const postPaymentInfoReducer = (state) => ({
    ...state,
    loading: true,
});

const postPaymentInfoSuccessReducer = (state) => ({
    ...state,
    loading: false,
});

const postPaymentInfoFailReducer = (state) => ({
    ...state,
    loading: false,
});

// Patch Payment Info

const patchPaymentInfoReducer = (state) => ({
    ...state,
    sendingRequestLoading: true,
});

const patchPaymentInfoSuccessReducer = (state) => ({
    ...state,
    sendingRequestLoading: false,
});

const patchPaymentInfoFailReducer = (state) => ({
    ...state,
    sendingRequestLoading: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PAYMENT_INFO:
            return getPaymentInfoReducer(state);
        case actionTypes.GET_PAYMENT_INFO_SUCCESS:
            return getPaymentInfoSuccessReducer(state, action);
        case actionTypes.GET_PAYMENT_INFO_FAIL:
            return getPaymentInfoFailReducer(state);
        case actionTypes.POST_PAYMENT_INFO:
            return postPaymentInfoReducer(state);
        case actionTypes.POST_PAYMENT_INFO_SUCCESS:
            return postPaymentInfoSuccessReducer(state);
        case actionTypes.POST_PAYMENT_INFO_FAIL:
            return postPaymentInfoFailReducer(state);
        case actionTypes.PATCH_PAYMENT_INFO:
            return patchPaymentInfoReducer(state);
        case actionTypes.PATCH_PAYMENT_INFO_SUCCESS:
            return patchPaymentInfoSuccessReducer(state);
        case actionTypes.PATCH_PAYMENT_INFO_FAIL:
            return patchPaymentInfoFailReducer(state);
        default:
            return state;
    }
};

export default reducer;
