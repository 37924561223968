import React, { useState } from 'react';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';
import LeftSection from './LeftSection';
import RightSection from './RightSection/index';
import { Wrapper, DividerStyle } from './styles';

export default function SearcBar(props) {
    const { toggleRightSectionDrawer } = props;
    const [filterCity, setFilterCity] = useState('');
    const { xs } = useResponsive();

    return (
        <Wrapper style={{ flexDirection: xs ? 'column' : 'row' }}>
            <LeftSection filterCity={filterCity} setFilterCity={setFilterCity} />
            <RightSection toggleRightSectionDrawer={toggleRightSectionDrawer} />
            {!xs && <DividerStyle />}
        </Wrapper>
    );
}
