import styled from 'styled-components';
import { Row, Divider } from 'antd';
import Button from '../../../../../../components/Button';

export const CustomRow = styled(Row)`
    width: 100%;

    &.removeSmall {
        @media (min-width: 1024px) {
            display: none;
        }
    }

    &.button-row {
        margin-top: 60px;
    }
`;

export const CustomDivider = styled(Divider)`
    border-top: 2px solid #f2f2fc;
`;

export const CustomButton = styled(Button)`
    width: 240px;
    height: 48px;
`;
