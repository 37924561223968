import styled from 'styled-components';
import { Col, Row, Modal } from 'antd';
import Button from '../../../../../components/Button';

export const PacketWrapper = styled(Row)`
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
    min-height: 102px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const CustomButton = styled(Button)`
    width: 100%;
    height: 100%;
`;

export const Description = styled(Row)`
    padding-bottom: 34px;
`;

export const Wrapper = styled(Col)`
    min-height: 400px;
`;
export const Footer = styled(Row)`
    padding-top: 34px;
`;

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        display: none;
        height: 30px;
        .ant-btn {
            display: none;
        }
        /* background: ${({ theme }) => theme.borderColor}; */
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;
