import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useCustomerUser() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const authStore = useSelector((state) => state.authStore);

    useEffect(() => {
        const { user: userData } = authStore;
        if (userData) return setUser(userData);
    }, [authStore]);

    return { user };
}

export default useCustomerUser;
