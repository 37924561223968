import styled from 'styled-components';
import { Row } from 'antd';

export const TextStyle = styled(Row)``;

export const HiddenRow = styled.div`
    height: 0px;
    transition: height 0.5s;
    overflow: hidden;
    width: 100%;
    margin-top: 10px;

    &.delivery-section {
        height: ${({ active }) => (active ? '250px' : '0')};

        @media (min-width: 1025px) {
            height: ${({ active }) => (active ? '150px' : '0')};
        }
    }
    &.progress-bar {
        height: ${({ active }) => (active ? '200px' : '0')};

        @media (min-width: 1025px) {
            height: ${({ active }) => (active ? '150px' : '0')};
        }
    }
    &.sort-section {
        align-items: center;
        height: ${({ active }) => (active ? '200px' : '0')};
    }
`;

export const CustomRow = styled(Row)`
    margin-top: 10px;
`;
