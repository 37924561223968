import { Row } from 'antd';
import styled from 'styled-components';
import Card from '../../../../components/Card';
import Btn from '../../../../components/Button';

export const LabelsCardStyles = styled(Card)`
    margin-top: 20px;
    .ant-card-body {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
`;

export const Content = styled(Row)`
    min-height: 170px;
`;
export const Footer = styled(Row)``;

export const CustomButton = styled(Btn)`
    height: 48px !important;
    width: 100%;
`;
