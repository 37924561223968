import styled from 'styled-components';
import { Col, Row } from 'antd';

export const Wrapper = styled(Col)`
    margin-left: 7%;
`;

export const CustomRow = styled(Row)`
    margin-right: 2.5%;
`;
