import moment from 'moment';
import { Row } from 'antd';
import { NotifyCardStyles } from './styles';
import { Text } from '../../../../components/Typography/styles';

const AlertCard = ({ startDate }) => (
    <NotifyCardStyles
        style={{ marginTop: '28px' }}
        type="alert"
        // eslint-disable-next-line react/no-children-prop
        children={
            <Row className="space-between">
                <Text weight="semi-bold">Bu tarihten sonra,takvime göre satın alım gerçekleşecektir.</Text>
                <Text size="title" weight="light" style={{ marginTop: '28px' }}>
                    {moment(startDate.slice(0, 10)).format('D MMMM YYYY')}
                </Text>
            </Row>
        }
    />
);

export default AlertCard;
