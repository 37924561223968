/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable prettier/prettier */
/* eslint-disable use-isnan */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-restricted-globals */

import { useState } from 'react';
import { Col, Dropdown, Row, Menu, message } from 'antd';
import { useDispatch } from 'react-redux';
import { CustomModal, DropdownButton, CustomRow, CustomInput, CustomButton, FormItem } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { createCard, getCardList } from '../../../../../../../services/Redux/Shared/payment/api';
import { ReactComponent as DropdownArrow } from '../../../../../../../Assets/Shared/Profile/dropdown-arrow.svg';

const NewCardModal = (props) => {
    const dispatch = useDispatch();
    const { onCancel } = props;
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const [name, setName] = useState('');
    const [cardNo, setCardNo] = useState('');
    const [month, setMonth] = useState('Ay');
    const [yil, setYil] = useState('Yıl');
    const [cvc, setCVC] = useState(null);
    const [kartIsim, setKartIsim] = useState('');
    const year = new Date().getFullYear();
    const requiredText = 'This field is Required';
    const dropdownRequired = 'Required';

    // for the next 40 years generate yearsArray with IIFE
    const years = [];
    (() => {
        for (let i = 0; i <= 40; i++) {
            years.push(year + i)
        }
        return years
    })()

    const setEmpty = () => {
        setCardNo('');
        setYil('Yıl');
        setCVC(null);
        setMonth('Ay');
        setKartIsim('');
        setName('');
    };

    const newCard = async () => {
        const formData = new FormData();
        if ((name !== '' && cardNo !== '' && month !== '', yil !== '' && cvc !== null && kartIsim !== '')) {
            const smallYear = yil.toString().slice(2, 4);
            formData.append('cardAlias', kartIsim);
            formData.append('cardHolderName', name);
            formData.append('cardNumber', cardNo);
            formData.append('expireMonth', month);
            formData.append('expireYear', smallYear);

            await dispatch(createCard(formData));
            onCancel();
            setEmpty();
            await dispatch(getCardList());
        } else {
            message.error('Lütfen boş alan bırakmayınız!');
        }
    };

    const menu = () => (
        <Menu style={{ height: 200, overflow: 'auto' }}>
            {months.map((item) => (
                <Menu.Item key={item} onClick={() => setMonth(item)}>
                    <Text size="14px">{item}</Text>
                </Menu.Item>
            ))}
        </Menu>
    );

    const yearmenu = () => (
        <Menu style={{ height: 200, overflow: 'auto' }}>
            {years.map((item, index) => (
                <Menu.Item key={index} onClick={() => setYil(item)}>
                    <Text size="14px">{item}</Text>
                </Menu.Item>
            ))}
        </Menu>
    );

    const cardNumberCheck = (value) => {
        if ((!isNaN(value) && !isNaN(parseFloat(value)) && value.length <= 16) || value === '') {
            setCardNo(value);
        }
    };

    const cvcCheck = (value) => {
        if ((!isNaN(value) && !isNaN(parseFloat(value)) && value.length <= 3) || value === '') {
            setCVC(value);
        }
    };

    return (
        <CustomModal {...props}>
            <CustomRow>
                <CustomRow>
                    <Text weight="semi-bold">Yeni Kart Kaydı</Text>
                </CustomRow>
                <CustomRow justify="space-between">
                    <Col span={24}>
                        <CustomRow className="name-input-row">
                            <Text size="14px" weight="light">
                                Kart Üzerindeki İsim
                            </Text>
                        </CustomRow>
                        <CustomRow>
                            <FormItem validateStatus={name === '' && cardNo ? 'error' : 'success'} help={name === '' && cardNo ? requiredText : ''}>
                                <CustomInput value={name} onChange={(e) => setName(e.target.value)} />
                            </FormItem>
                        </CustomRow>
                        <CustomRow>
                            <Text size="14px" weight="light">
                                Kart Numarası
                            </Text>
                        </CustomRow>
                        <CustomRow>
                            <FormItem
                                validateStatus={cardNo === '' && month !== 'Ay' ? 'error' : 'success'}
                                help={cardNo === '' && month !== 'Ay' ? requiredText : ''}>
                                <CustomInput value={cardNo} onChange={(e) => cardNumberCheck(e.target.value)} />
                            </FormItem>
                        </CustomRow>
                        <CustomRow>
                            <Col span={7}>
                                <Text size="14px" weight="light">
                                    Ay
                                </Text>
                            </Col>
                            <Col span={7}>
                                <Text size="14px" weight="light">
                                    Yıl
                                </Text>
                            </Col>
                            <Col>
                                <Text size="14px" weight="light">
                                    CVV/CV2
                                </Text>
                            </Col>
                        </CustomRow>
                        <CustomRow>
                            <Col span={6}>
                                <FormItem
                                    validateStatus={month === 'Ay' && yil !== 'Yıl' ? 'error' : 'success'}
                                    help={month === 'Ay' && yil !== 'Yıl' ? dropdownRequired : ''}>
                                    <Dropdown overlay={menu} overlayStyle={{ border: '2px solid #F2F2FC' }} trigger={['click']}>
                                        <DropdownButton style={{ marginTop: 10 }}>
                                            <Row align="middle" justify="space-between">
                                                <Text size="14px">{month}</Text>
                                                <DropdownArrow />
                                            </Row>
                                        </DropdownButton>
                                    </Dropdown>
                                </FormItem>
                            </Col>
                            <Col span={1} />
                            <Col span={6}>
                                <FormItem
                                    validateStatus={yil === 'Yıl' && cvc ? 'error' : 'success'}
                                    help={yil === 'Yıl' && cvc ? dropdownRequired : ''}>
                                    <Dropdown overlay={yearmenu} overlayStyle={{ border: '2px solid #F2F2FC' }} trigger={['click']}>
                                        <DropdownButton style={{ marginTop: 10 }}>
                                            <Row align="middle" justify="space-between">
                                                <Text size="14px">{yil}</Text>
                                                <DropdownArrow />
                                            </Row>
                                        </DropdownButton>
                                    </Dropdown>
                                </FormItem>
                            </Col>
                            <Col span={1} />
                            <Col span={10}>
                                <FormItem validateStatus={!cvc && kartIsim ? 'error' : 'success'} help={!cvc && kartIsim ? dropdownRequired : ''}>
                                    <CustomInput value={cvc} onChange={(e) => cvcCheck(e.target.value)} />
                                </FormItem>
                            </Col>
                        </CustomRow>
                        <CustomRow className="number-input-row">
                            <Text size="14px" weight="light">
                                Karta İsim Ver
                            </Text>
                        </CustomRow>
                        <CustomRow className="bottom-row">
                            <CustomInput
                                value={kartIsim}
                                onChange={(e) => {
                                    setKartIsim(e.target.value);
                                }}
                            />
                        </CustomRow>
                    </Col>
                    <Col>
                        <CustomRow justify="end" className="button-row">
                            <CustomButton type="secondaryReverse" onClick={() => newCard()}>
                                <Text weight="semi-bold" color="green">
                                    Kayıtlı Kartlarıma Ekle
                                </Text>
                            </CustomButton>
                        </CustomRow>
                    </Col>
                </CustomRow>
            </CustomRow>
        </CustomModal>
    );
};

export default NewCardModal;
