import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, message, Row } from 'antd';
import { CustomModal, CustomDivider, CustomButton, CategoryItem, CategoryIcon } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import { fetchBoxCategoriesNames, postBoxCategories } from '../../../../../../services/Redux/Dashboard/boxes/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const AddCategoryModal = (props) => {
    const [selected, setSelected] = useState();
    const [beforeCategoryNames, setBeforeCategoryNames] = useState();
    const [selectedId, setSelectedId] = useState(0);
    const { dismiss, categoriesToRender } = props;

    const onSelect = (name, id) => {
        setSelected(name);
        setSelectedId(id);
    };

    const selector = useSelector;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBoxCategoriesNames());
    }, [dispatch]);

    const setNew = () => {
        const newinfo = {
            name: selectedId,
            store: JSON.parse(localStorage.getItem('user')).stores.id,
        };
        dispatch(postBoxCategories(newinfo));
    };

    const addCategory = () => {
        if (beforeCategoryNames !== undefined) {
            if (beforeCategoryNames.includes(selected)) {
                message.error('Seçtiğiniz Kategori Halihazırda Mevcut!');
            } else {
                setNew();
            }
        } else {
            setNew();
        }
    };

    const categories = selector((state) => state.boxStore.categoryNamesList);

    useEffect(() => {
        if (categoriesToRender) {
            setBeforeCategoryNames(categoriesToRender.map((item) => item.name));
        }
    }, [categoriesToRender]);

    return (
        <>
            <CustomModal title={<Title text="Kategoriler" />} {...props}>
                <Row justify="center">
                    <Text>Lütfen eklemek istediğiniz kategoriyi seçiniz.</Text>
                </Row>
                <CustomDivider />
                <Row justify="center">
                    {categories && categories.results.length > 0 ? (
                        categories.results.map(({ id, name, photo }) => (
                            <CategoryItem span={8} onClick={() => onSelect(name, id)}>
                                <Row justify="center">
                                    {selected === name ? (
                                        <CategoryIcon className="selected-row" align="middle" justify="center">
                                            <img src={photo} width="100%" alt="cat-item" />
                                        </CategoryIcon>
                                    ) : (
                                        <CategoryIcon className="unselected-row" align="middle" justify="center">
                                            <img src={photo} alt="cat-item" />
                                        </CategoryIcon>
                                    )}
                                </Row>
                                <Row justify="center">
                                    <Text weight="light">{name}</Text>
                                </Row>
                            </CategoryItem>
                        ))
                    ) : (
                        <div>nodata</div>
                    )}
                </Row>
                <CustomDivider />
                <Row justify="center">
                    <Col span={12}>
                        <CustomButton type="quaternary" onClick={() => dismiss()}>
                            <Text weight="light">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col>
                        <CustomButton type="secondary" onClick={() => addCategory()}>
                            <Text color="white" weight="light">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Row>
            </CustomModal>
        </>
    );
};

export default AddCategoryModal;
