/* eslint-disable no-unused-vars */
import * as actions from './actions';

export const getStoreInfo = () => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getStoreInfoAction());
        const Info = await service({
            method: 'GET',
            path: 'store/userstore/',
            sendToken: true,
        });
        dispatch(actions.getStoreInfoSuccessAction(Info));
        return Info;
    } catch (err) {
        dispatch(actions.getStoreInfoFailAction());
    }
};

export const updateStoreInfo = (newInfo, id) => (dispatch, getState, service) => {
    try {
        dispatch(actions.updateStoreInfoAction());
        const result = service({
            method: 'PATCH',
            path: `store/${id}/`,
            sendToken: true,
            body: newInfo,
        });
        dispatch(actions.updateStoreInfoSuccessAction());
    } catch (err) {
        dispatch(actions.updateStoreInfoFailAction());
    }
};

export const getEmailInfo = (id) => async (dispatch, getState, service) => {
    try {
        dispatch(actions.getEmailInfoAction());
        const Info = await service({
            method: 'GET',
            path: `store/email_settings/?store=${id}`,
            sendToken: true,
        });
        dispatch(actions.getEmailInfoSuccessAction(Info));
        return Info;
    } catch (err) {
        dispatch(actions.getEmailInfoFailAction());
    }
};

export const postEmailInfo = (newInfo) => (dispatch, getState, service) => {
    try {
        dispatch(actions.postEmailInfoAction());
        const result = service({
            method: 'POST',
            path: 'store/email_settings/',
            sendToken: true,
            body: newInfo,
        });
        dispatch(actions.postEmailInfoSuccessAction());
    } catch (err) {
        dispatch(actions.postEmailInfoFailAction());
    }
};

export const updateEmailInfo = (newInfo, id) => (dispatch, getState, service) => {
    try {
        dispatch(actions.updateEmailInfoAction());
        const result = service({
            method: 'PATCH',
            path: `store/email_settings/${id}/`,
            sendToken: true,
            body: newInfo,
        });
        dispatch(actions.updateEmailInfoSuccessAction());
    } catch (err) {
        dispatch(actions.updateEmailInfoFailAction());
    }
};
