/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Input } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '../../../../../../Assets/Shared/FindRestaurant/searchIcon.svg';
import CartIcon from '../../../../../../Assets/Shared/FindRestaurant/filter.svg';

import { EnWrapper, Wrapper, IconButton } from './styles';
import useMediaQuery from '../../../../../../helpers/Hooks/useMediaQuery.js';

export default function RightSection() {
    const { xl } = useMediaQuery();
    const history = useHistory();

    const handleClick = () => {
        history.go(-1);
    };

    return (
        <EnWrapper>
            <Wrapper style={{ width: '100%' }}>
                <Input bordered={false} onChange={handleClick} placeholder="Yemek, restoran ara" style={{ flex: 1 }} />
                <img src={SearchIcon} alt="img" onClick={handleClick} onKeyPress={handleClick} style={{ cursor: 'pointer' }} />
            </Wrapper>
            {!xl && (
                <IconButton onClick={handleClick}>
                    <img src={CartIcon} alt="img" width="48px" />
                </IconButton>
            )}
        </EnWrapper>
    );
}
