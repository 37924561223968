import React from 'react';
import { Spin } from 'antd';
import { MinPacketPriceStyles, Wrapper, Header, Content, Label } from './styles';
import { Text } from '../../../../../components/Typography/styles';

const MinPacketPrice = ({ restaurantInfo }) => (
    <MinPacketPriceStyles>
        <Wrapper span={24}>
            <Header align="middle" justify="center">
                <Text weight="semi-bold">Minimum Paket Tutarı</Text>
            </Header>
            <Content align="middle" justify="center">
                {restaurantInfo ? (
                    <Label>
                        <Text color="yellow" size="noble-regular" family="secondary" weight="bold">
                            {`${restaurantInfo.package_settings.min_order_price} TL`}
                        </Text>
                    </Label>
                ) : (
                    <Spin />
                )}
            </Content>
        </Wrapper>
    </MinPacketPriceStyles>
);

export default MinPacketPrice;
