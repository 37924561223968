import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { CustomModal, CustomDivider, CustomButton, FoodnameLabel } from './styles';
import { Text } from '../../../../../../../components/Typography/styles.js';
import { fetchBoxTags } from '../../../../../../../../services/Redux/Dashboard/boxes/api';
import { ReactComponent as CloseBtn } from '../../../../../../../../Assets/Shared/RestaurantDetailPage/closebtn.svg';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const AddTagModal = (props) => {
    const [count, setCount] = useState(0);
    const { label, dismiss } = props;

    const selector = useSelector;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchBoxTags());
    }, [dispatch]);

    const tags = selector((state) => state.boxStore.tagList);

    const [selected, setSelected] = useState([].concat(label));

    const onSelect = (index) => {
        const item = {
            id: index.id,
            name: index.name,
            confirmed: index.confirmed,
        };
        const found = selected.find((element) => element.name === index.name);
        if (!found) {
            selected.push(item);
            setCount(count + 1);
        }
    };

    const onDeselect = (index) => {
        const position = selected.findIndex(function (element) {
            return element.name === index.name;
        });
        if (position) {
            selected.splice(position, 1);
            setCount(count - 1);
        }
    };

    const onDismiss = () => {
        setSelected([].concat(label));
        dismiss();
    };

    const onOnay = () => {
        label.splice(0, label.length);
        label.push(...selected);
        dismiss();
    };
    return (
        <>
            <CustomModal title={<Title text="Etiketler" />} {...props} closeIcon={<CloseBtn />}>
                <Row justify="center">
                    <Text>Lütfen eklemek istediğiniz etiketi seçiniz.</Text>
                </Row>
                <CustomDivider />
                <Row gutter={[0, 18]} justify="center">
                    {tags && tags.results.length > 0 ? (
                        tags.results.map((item) => (
                            <Col span={5}>
                                {selected.find((element) => element.name === item.name) ? (
                                    <FoodnameLabel className="selected-tag" onClick={() => onDeselect(item)}>
                                        <Text size="non-regular" family="secondary" color="smoke" weight="light">
                                            {item.name}
                                        </Text>
                                    </FoodnameLabel>
                                ) : (
                                    <FoodnameLabel className="unselected-tag" onClick={() => onSelect(item)}>
                                        <Text size="non-regular" family="secondary" color="smoke" weight="light">
                                            {item.name}
                                        </Text>
                                    </FoodnameLabel>
                                )}
                            </Col>
                        ))
                    ) : (
                        <div>nodata</div>
                    )}
                </Row>
                <CustomDivider />
                <Row justify="center">
                    <Col span={12}>
                        <CustomButton type="quaternary" onClick={() => onDismiss()}>
                            <Text weight="light">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col>
                        <CustomButton type="secondary" onClick={() => onOnay()}>
                            <Text color="white" weight="light">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Row>
            </CustomModal>
        </>
    );
};

export default AddTagModal;
