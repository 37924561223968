import styled from 'styled-components';
import { Col } from 'antd';
import { Text } from '../../../../components/Typography/styles';

export const ContentStyle = styled(Col)`
    background-color: #bcbcbc;
    padding: 8%;
    position: relative;

    @media (max-width: 1024px) {
        display: none;
        padding: 0;
    }
`;

export const UpTextWrapper = styled(Text)``;

export const DownTextWrapper = styled(Text)``;
