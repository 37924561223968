import { message } from 'antd';
import * as actionTypes from './actionTypes';

const success = () => {
    message.success('Değişiklikler başarıyla kaydedildi.');
};

export const getPaymentInfoAction = () => ({
    type: actionTypes.GET_PAYMENT_INFO,
});

export const getPaymentInfoSuccessAction = (data) => ({
    type: actionTypes.GET_PAYMENT_INFO_SUCCESS,
    payload: data,
});
export const getPaymentInfoFailAction = () => ({
    type: actionTypes.GET_PAYMENT_INFO_FAIL,
});

export const postPaymentInfoAction = () => ({
    type: actionTypes.POST_PAYMENT_INFO,
});

export const postPaymentInfoSuccessAction = () => {
    success();
    return {
        type: actionTypes.POST_PAYMENT_INFO_SUCCESS,
    };
};
export const postPaymentInfoFailAction = (error) => {
    message.error(error);
    return {
        type: actionTypes.POST_PAYMENT_INFO_FAIL,
    };
};

export const patchPaymentInfoAction = () => ({
    type: actionTypes.PATCH_PAYMENT_INFO,
});

export const patchPaymentInfoSuccessAction = () => {
    success();
    return {
        type: actionTypes.PATCH_PAYMENT_INFO_SUCCESS,
    };
};
export const patchPaymentInfoFailAction = () => ({
    type: actionTypes.PATCH_PAYMENT_INFO_FAIL,
});
