// GET

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

// GET

export const GET_FAQS = 'GET_FAQS';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAIL = 'GET_FAQS_FAIL';
