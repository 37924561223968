import { Col, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Text } from '../../../../../components/Typography/styles';
import { TextStyle, HiddenRow } from './styles';
import { CustomRadio } from '../../../ProfilePage/Content/AddressPane/PaymentAddress/styles';
import { ReactComponent as DropDownArrow } from '../../../../../../Assets/Shared/FindRestaurant/collapsible-icon.svg';

const data = ['Uzaklığa Göre Sırala', 'Favorilerin', 'Kullanıcı Puanı', 'Yeni Katılanlar'];

export default function SortSection(props) {
    const [showRow, setShowRow] = useState(false);
    const toggleRow = () => setShowRow(!showRow);
    const { sortBy, setSortBy } = props;
    const onRadioChange = (value) => {
        setSortBy(value);
    };

    return (
        <Col style={{ marginLeft: '7%', marginRight: '3%' }}>
            <Row align="middle" justify="space-between">
                <TextStyle>
                    <Text weight="500" color="black">
                        Sırala
                    </Text>
                </TextStyle>
                <DropDownArrow style={{ cursor: 'pointer' }} onClick={toggleRow} />
            </Row>
            <HiddenRow className="sort-section" active={showRow}>
                <CustomRadio.Group style={{ color: 'green' }} size="large" onChange={(e) => onRadioChange(e.target.value)} value={sortBy}>
                    <Space direction="vertical">
                        {data.map((item, index) => (
                            <CustomRadio key={index} className="sort-section" value={index + 1}>
                                <Text weight="light">{item}</Text>
                            </CustomRadio>
                        ))}
                    </Space>
                </CustomRadio.Group>
            </HiddenRow>
        </Col>
    );
}
