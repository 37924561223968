import { React, useState } from 'react';
import { Row, Col, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { LabelsCardStyles, Content, Footer, CustomButton } from './styles';
import { Text } from '../../../../components/Typography/styles';
import { postBoxTags } from '../../../../../services/Redux/Dashboard/boxes/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const LabelsCard = () => {
    const dispatch = useDispatch();
    const [tag, setTag] = useState('');

    const createTag = (tagName) => {
        dispatch(postBoxTags(tagName));
        setTag('');
    };

    return (
        <LabelsCardStyles title={<Title text="Etiketler" />}>
            <Content>
                <Col>
                    <Row align="middle" justify="start">
                        <Text weight="semi-bold">Etiket Öner</Text>
                    </Row>
                    <Row style={{ marginTop: '15px' }} align="middle" justify="start">
                        <Text family="secondary" color="smoke">
                            Aradığın etiketi seçeneklerde bulamadıysan bize yeni etiketin oluşturulmasını talep edebilirsin.
                        </Text>
                    </Row>
                </Col>
            </Content>
            <Footer align="middle" gutter={[24, 0]}>
                <Col span={12}>
                    <Input style={{ height: '48px' }} value={tag} placeholder="Öneride bulun..." onChange={(event) => setTag(event.target.value)} />
                </Col>
                <Col span={12}>
                    <CustomButton type="secondary" onClick={() => createTag(tag)}>
                        <Text color="white" weight="semi-bold">
                            Onaya Gönder
                        </Text>
                    </CustomButton>
                </Col>
            </Footer>
        </LabelsCardStyles>
    );
};

export default LabelsCard;
