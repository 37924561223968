import React from 'react';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import { UpWrapper, ExplanationWrapper, DownWrapper, ExplanationMobileWrapper, UpMobileWrapper, DownMobileWrapper } from './styles';
import { Paragraph } from '../../../../components/Typography/styles';
import Device from '../../../../../Assets/Shared/FoodWaste/device.svg';
import SmallEarth from '../../../../../Assets/Shared/FoodWaste/smallEarth.svg';
import background from '../../../../../Assets/Shared/FoodWaste/background.svg';

const ExplanationSection = () => {
    const { sm } = useMediaQuery();
    const content = [
        'Birleşmiş Milletler Gıda ve Tarım Örgütü (FAO) verileri; bir öğün yemeğin üretimi için ortalama 2.5 kg CO² salınımı gerçekleştiğini ortaya koyuyor. Bu veri; israf edilen bir öğünün, bir akıllı telefonun 300 milyon defa şarj edilmesine ve 1130 ton kömürün yakılmasına bedel olduğunu söylüyor.',
        '2050 yılında dünya nüfusunun 9,3 milyara yaklaşması bekleniyor. Bu ölçekte bir küresel nüfus ile, gıda üretimi ihtiyacının %60 oranında artacağı öngörülmekte.',
    ];
    return sm ? (
        <ExplanationMobileWrapper>
            <UpMobileWrapper>
                <img style={{ marginBottom: '40px', marginRight: '10%' }} width="50%" src={Device} alt="" />
                <Paragraph style={{ textAlign: 'center' }} color="#000" weight="medium" size="description">
                    {content[0]}
                </Paragraph>
            </UpMobileWrapper>
            <DownMobileWrapper>
                <img style={{ marginBottom: '40px', marginRight: '10%' }} width="50%" src={SmallEarth} alt="" />
                <Paragraph style={{ textAlign: 'center' }} color="#000" weight="medium" size="description">
                    {content[1]}
                </Paragraph>
            </DownMobileWrapper>
        </ExplanationMobileWrapper>
    ) : (
        <ExplanationWrapper backgroundImage={background}>
            <UpWrapper>
                <Paragraph color="#000" weight="medium" size="description">
                    {content[0]}
                </Paragraph>
            </UpWrapper>
            <DownWrapper>
                <Paragraph color="#000" weight="medium" size="description">
                    {content[1]}
                </Paragraph>
            </DownWrapper>
        </ExplanationWrapper>
    );
};

export default ExplanationSection;
