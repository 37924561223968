import styled from 'styled-components';
import { Col, Row } from 'antd';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const InfoStyles = styled(Row)`
    background-repeat: no-repeat;
    padding-top: 170px;
    background-position: 85% 80%;
    background-size: 34%;
    padding-bottom: ${VERTICAL_PADDING};
    box-sizing: border-box;
    width: 100vw;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};

    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (min-width: 769px) {
        background-image: ${({ backgroundImage }) => {
            if (backgroundImage) {
                return `url(${backgroundImage})!important`;
            }
        }};
    }
`;

export const InfoWrapper = styled(Row)`
    display: flex;
`;

export const InfoTitle = styled(Col)`
    padding-bottom: 60px;
`;

export const BigEarthImage = styled.img`
    width: 85%;
`;
