import styled from 'styled-components';
import { Row } from 'antd';
import { between } from 'polished';

const headerHeight = '98px';
const headerPaddingRight = '140px';
const headerPaddingVertical = '10px';

export const HeaderStyles = styled(Row)`
    height: ${headerHeight};
    background-color: ${({ theme }) => theme.lightColor};
    padding-right: ${headerPaddingRight};
    padding: ${headerPaddingVertical} ${between('0px', headerPaddingRight)} ${headerPaddingVertical} 0px;
`;

export const ImageContainer = styled.div`
    width: 334px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .img-covered {
        height: 60px;
        width: 107px;
    }
`;

export const ProfileContainer = styled.div`
    min-width: 355px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
