import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text } from '../../../../../components/Typography/styles';
import { CustomRow, CustomButton } from './styles';
import { ReactComponent as PacketIcon } from '../../../../../../Assets/Shared/CartPage/packet-icon.svg';

const EmptyCart = () => {
    const history = useHistory();
    return (
        <CustomRow className="main-row">
            <CustomRow justify="center" className="heading-row">
                <PacketIcon />
            </CustomRow>
            <CustomRow justify="center" className="haydi-row">
                <Text size="24px" weight="bold" color="red">
                    Haydi!
                </Text>
            </CustomRow>
            <CustomRow justify="center" className="button-row">
                <Text size="24px" weight="bold" color="red">
                    Sana yakın restoran ve kafelerden birini seç.
                </Text>
            </CustomRow>
            <CustomRow justify="center" className="button-row button-row-last">
                <CustomButton type="secondary" onClick={() => history.push('/restoran-bul')}>
                    <Text size="16px" weight="semi-bold" color="white">
                        Restoranları Gör
                    </Text>
                </CustomButton>
            </CustomRow>
            <CustomRow className="button-row" />
            <CustomRow className="button-row" />
        </CustomRow>
    );
};

export default EmptyCart;
