/* eslint-disable no-console */
import * as actions from './actions';

export const fetchRestaurantInfo = () => async (dispatch, state, service) => {
    dispatch(actions.getRestaurantInfoStartAction());
    try {
        const data = await service({
            path: 'store/userstore/',
            method: 'GET',
            sendToken: true,
        });

        dispatch(actions.getRestaurantInfoSuccessAction(data));
    } catch (error) {
        dispatch(actions.getRestaurantInfoFailAction(error));
        console.log(error);
    }
};
