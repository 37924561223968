import styled from 'styled-components';
import { Row } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        margin-left: 30px;
    }

    &.title-text-row {
        margin-top: 20px;
    }

    &.margin-40 {
        margin-top: 40px;
    }

    &.buttons-row {
        margin-top: 20px;
    }

    &.buttons-row--social {
        justify-content: space-between;
        width: 60%;
        @media (min-width: 1024px) {
            justify-content: unset;
            width: 100%;
        }
    }
    @media (min-width: 1024px) {
        width: 95%;
    }
`;

export const Image = styled.img`
    &.android {
        margin-left: 10px;
    }

    &.logo {
        margin-left: 20px;
        margin-right: 10px;
    }
`;
