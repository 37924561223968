import styled from 'styled-components';
import { Row, Col } from 'antd';

export default styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    border-radius: 0 0 2px 2px;
    background: ${({ theme }) => theme.lightColor};
`;

export const Content = styled(Row)`
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    align-content: center;
    height: 60px;
`;

export const PlanContainer = styled(Row)`
    padding-left: 25px;
    padding-right: 25px;
    background: ${({ theme }) => theme.lightColor};
`;

export const Item = styled(Col)``;

export const Footer = styled.div``;
