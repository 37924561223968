import styled from 'styled-components';
import { Col } from 'antd';

export const Wrapper = styled(Col)`
    background-color: white;
    width: 30%;
    height: 48px;
    margin-right: 12px;
    border: 2px solid #f2f2fc;

    @media (max-width: 400px) {
        min-width: 90%;
        margin-right: 0;
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;
