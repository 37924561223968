import React from 'react';
import LoginPageStyles, { Content, Item } from './styles';
import SignUpCard from './SignUpCard';
import bg from '../../../../Assets/Shared/LoginPage/loginBackground.svg';

const SignUp = () => (
    <LoginPageStyles backgroundImage={bg}>
        <Content justify="center" align="middle">
            <Item xs={22}>
                <SignUpCard />
            </Item>
        </Content>
    </LoginPageStyles>
);

export default SignUp;
