import * as actions from './actions';

export const fetchNotifications = () => (dispatch, state, service) => {
    dispatch(actions.getNotificationsStartAction(dispatch, state, service));
    service({
        path: 'notification/',
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getNotificationsSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getNotificationsFailAction(error));
        });
};

export const patchNotifications = (newInfo, id) => (dispatch, state, service) => {
    dispatch(actions.patchNotificationsStartAction(dispatch, state, service));
    service({
        path: `notification/${id}`,
        method: 'PATCH',
        sendToken: newInfo,
    })
        .then((data) => {
            dispatch(actions.patchNotificationsSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.patchNotificationsFailAction(error));
        });
};
