import styled from 'styled-components';
import { Row, Switch } from 'antd';

export const CustomRow = styled(Row)`
    width: 100%;

    &.main-row {
        margin-left: 30px;
    }

    &.title-text {
        margin-top: 10px;
    }

    &.settings-row .settings-col {
        margin-top: 30px;

        @media (max-width: 1024px) {
            flex: unset !important;
            max-width: 100% !important;
            width: 100% !important;
        }
    }

    &.settings-row .settings-col > * + * {
        margin-top: 1rem;
    }

    &.bildirim-row {
        margin-top: 15px;
    }
`;

export const CustomSwitch = styled(Switch)`
    color: green;
`;
