import styled from 'styled-components';
import { Text } from '../Typography/styles';

export const ErrorMessage = styled(Text)`
    color: red !important;
    font-size: 0.8rem;
    font-weight: 600;
    margin: auto;
    margin-bottom: 12px;
    text-align: left;
`;
