import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { CustomModal, CustomButton } from './styles';
import { Text } from '../../../../../../../components/Typography/styles.js';
import { deleteBoxMeal, fetchBoxCategories } from '../../../../../../../../services/Redux/Dashboard/boxes/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const RemoveMealModal = (props) => {
    const { dismiss, onCancel } = props;
    const { id } = props;

    const dispatch = useDispatch();

    const onOnay = async () => {
        await dispatch(deleteBoxMeal(id));
        await dispatch(fetchBoxCategories(false));
        onCancel();
    };
    return (
        <>
            <CustomModal title={<Title text="Onayla" />} {...props}>
                <Row gutter={[0, 24]} justify="center">
                    <Col span={20}>
                        <Text weight="light">Bu yemeği silmek istediğinizden emin misiniz?</Text>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={12}>
                        <CustomButton type="quaternary" onClick={() => dismiss()}>
                            <Text weight="light">İptal</Text>
                        </CustomButton>
                    </Col>
                    <Col>
                        <CustomButton type="secondary" onClick={() => onOnay()}>
                            <Text color="white" weight="light">
                                Onay
                            </Text>
                        </CustomButton>
                    </Col>
                </Row>
            </CustomModal>
        </>
    );
};

export default RemoveMealModal;
