import { Row, Col } from 'antd';
import styled from 'styled-components';
import { between } from 'polished';
import Card from '../../../../../components/Card';

export const ProfitCardStyles = styled(Card)`
    margin-top: 24px;
    .ant-card-body {
        min-height: 180px;
        padding-left: 30px;
        padding-right: 30px;
    }
`;

export const Content = styled(Col)`
    min-height: 180px;

    display: flex;
    flex-direction: column;
    align-items: middle;
    justify-content: space-between;
    padding-bottom: 45px;
    .ant-progress {
        display: flex;
    }
`;

export const DescriptionContainer = styled(Row)`
    padding-left: ${between('0px', '20px')};
    padding-right: ${between('0px', '20px')};
`;
