import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { CustomRow, CustomButton, CustomInput, CustomPicker } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { patchUser } from '../../../../../../../services/Redux/Shared/profile/api';

const InformationCard = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [edit, setEdit] = useState(false);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [dob, setDOB] = useState(user.birthdate);
    const [mail, setMail] = useState(user.email);
    const dispatch = useDispatch();
    const selector = useSelector;

    function onChange(date, dateString) {
        setDOB(dateString);
    }

    const updateUser = () => {
        const data = {
            first_name: firstName,
            last_name: lastName,
            birthdate: dob,
            email: mail,
        };
        dispatch(patchUser(data, user.id));
        setEdit(false);
    };

    const newData = selector((state) => state.profileStore.userUpdateList);
    if (newData) {
        user.first_name = newData.first_name;
        user.last_name = newData.last_name;
        user.birthdate = newData.birthdate;
        user.email = newData.email;
        localStorage.setItem('user', JSON.stringify(user));
    }
    return (
        <CustomRow className="card-body">
            <CustomRow>
                <Text weight="semi-bold">Kişisel Bilgiler</Text>
            </CustomRow>
            <CustomRow gutter={[40, 0]}>
                <Col span={18}>
                    <CustomRow gutter={[20, 10]}>
                        <Col>
                            <div>
                                <CustomRow className="label-row">
                                    <Text weight="light" size="14px">
                                        Ad
                                    </Text>
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput disabled={!edit} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </CustomRow>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <CustomRow className="label-row">
                                    <Text weight="light" size="14px">
                                        Soyad
                                    </Text>
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput disabled={!edit} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </CustomRow>
                            </div>
                        </Col>
                    </CustomRow>
                    <CustomRow gutter={[20, 10]}>
                        <Col>
                            <div>
                                <CustomRow className="label-row">
                                    <Text weight="light" size="14px">
                                        Doğum Tarihi
                                    </Text>
                                </CustomRow>
                                {!edit ? (
                                    <CustomRow>
                                        <CustomInput disabled={!edit} value={dob || 'Belirtilmedi'} />
                                    </CustomRow>
                                ) : (
                                    <CustomRow>
                                        <CustomPicker
                                            /* value={moment(dob)} */
                                            disabledDate={(currentDate) => currentDate.year() > 2012}
                                            onChange={onChange}
                                        />
                                    </CustomRow>
                                )}
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <CustomRow className="label-row">
                                    <Text weight="light" size="14px">
                                        E-posta
                                    </Text>
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput disabled={!edit} value={mail} onChange={(e) => setMail(e.target.value)} />
                                </CustomRow>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <CustomRow className="label-row">
                                    <Text weight="light" size="14px">
                                        Telefon
                                    </Text>
                                </CustomRow>
                                <CustomRow>
                                    <CustomInput disabled value={user.phone_number} />
                                </CustomRow>
                            </div>
                        </Col>
                    </CustomRow>
                </Col>
                <Col span={5}>
                    <CustomRow align="top" className="btn-row">
                        {!edit ? (
                            <CustomButton type="tertiaryReverse" onClick={() => setEdit(true)}>
                                <Text color="red">Düzenle</Text>
                            </CustomButton>
                        ) : (
                            <CustomButton type="secondary" onClick={() => updateUser()}>
                                <Text color="white">Kaydet</Text>
                            </CustomButton>
                        )}
                    </CustomRow>
                </Col>
            </CustomRow>
        </CustomRow>
    );
};

export default InformationCard;
