import { Row } from 'antd';
import styled from 'styled-components/macro';
import { Text } from '../../../../components/Typography/styles';

export const Box = styled.div`
    background: #8fd89f;
    left: 0;
    padding: 18px 32px;
    width: 100%;
`;

export const MapBox = styled.div`
    left: 0;
    padding: 18px 32px;
    width: 100%;
`;

export const PriceDisplay = styled(Row)`
    align-items: center;
    background: white;
    border-radius: 4px;
    padding: 10px 15px;
`;

export const CustomText = styled(Text)``;

export const CustomRow = styled(Row)`
    &.c-row-1 {
        margin-top: 20px;
    }

    &.centered {
        align-items: center;
        height: 100%;
    }
`;
