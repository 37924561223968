/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Spin } from 'antd';
import { CustomModal, MenuWrapper, SearchBarWrapper } from './styles';
import { Text } from '../../../../../components/Typography/styles';
import SearchBar from '../../../components/SearchBar';
import FavouriteItem from './FavouriteItem';

const Title = ({ text }) => (
    <Text weight="light" size="title">
        {text}
    </Text>
);

const FavouriteModal = (props) => {
    const { setFavouriteToRender, favouriteToRender, isModalVisible } = props;
    const [filteredFav, setFilteredFav] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (search !== '') {
            const newFilter = favouriteToRender.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
            setFilteredFav(newFilter);
        } else {
            setFilteredFav([]);
        }
    }, [search]);

    useEffect(() => {
        if (!isModalVisible) {
            setSearch('');
            setFavouriteToRender(favouriteToRender);
            setFilteredFav([]);
        }
    }, [isModalVisible]);

    return (
        <CustomModal title={<Title text="Favourite Menü" />} {...props}>
            <Col span={24}>
                <SearchBarWrapper>
                    <SearchBar placeholder="Ürün ara" value={search} onChange={(e) => setSearch(e.target.value)} />
                </SearchBarWrapper>
                {favouriteToRender ? (
                    <MenuWrapper>
                        {filteredFav.length !== 0 ? (
                            <FavouriteItem
                                favouriteToRender={filteredFav}
                                setFavouriteToRender={setFavouriteToRender}
                                isFilter
                                setFilteredFav={setFilteredFav}
                            />
                        ) : (
                            <FavouriteItem favouriteToRender={favouriteToRender} setFavouriteToRender={setFavouriteToRender} />
                        )}
                    </MenuWrapper>
                ) : (
                    <MenuWrapper>
                        <Spin style={{ margin: 'auto' }} />
                    </MenuWrapper>
                )}
            </Col>
        </CustomModal>
    );
};

export default FavouriteModal;
