import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Table = styled.table`
    border-radius: 4px;
    border-spacing: 0px;
    border-collapse: separate;
    border: 2px solid #f2f2fb;
    border-bottom: 2px solid transparent;
    width: 100%;
`;

export const Td = styled.td`
    padding: 10px 15px;
    border-bottom: 2px solid #f2f2fb;
`;

export const CustomRow = styled(Row)`
    width: 100%;

    &.top-row {
        margin-top: 25px;
    }

    &.packet-row {
        height: 110px;
        padding: 20px 20px;

        @media (max-width: 1280px) {
            flex-direction: column;
        }
    }
`;

export const CustomCol = styled(Col)`
    &.min-price {
        background-color: #f2f2fc;
        justify-content: center;
        text-align: center;
    }

    &.right-col {
        @media (max-width: 1280px) {
            max-width: 100%;
            width: 100%;
        }
    }
`;
