import * as actions from './actions';

// export const fetchFavouriteMeals = () => async (dispatch, state, service) => {
//     dispatch(actions.getFavouritesStartAction());
//     try {
//         const data = await service({
//             path: 'box/list_fav_meals/',
//             method: 'GET',
//             sendToken: true,
//         });

//         dispatch(actions.getFavouritesSuccessAction(data));
//     } catch (error) {
//         dispatch(actions.getFavouritesFailAction(error));
//         console.log(error);
//     }
// };
// export const deleteFavouriteMeal = (appcontactId) => (dispatch, state, service) => {
//     dispatch(actions.deleteFavouritesStartAction(appcontactId));
//     service({
//         path: `box/unfav_meal/${appcontactId}/`,
//         method: 'POST',
//         sendToken: true,
//     })
//         .then((response) => {
//             console.log(response, 'bunedir');
//             dispatch(actions.deleteFavouritesSuccessAction('Seçtiğiniz favori ürün başarıyla silindi!'));
//             // dispatch(fetchFavouriteMeals());
//         })
//         .catch((error) => {
//             dispatch(actions.deleteFavouritesFailAction(error));
//             console.log(error);
//         });
// };
// export const deleteFavouriteMeal = (appcontactId) => async (dispatch, state, service) => {
//     dispatch(actions.deleteFavouritesStartAction(appcontactId));
//     try {
//         const data = await service({
//             path: `box/unfav_meal/${appcontactId}/`,
//             method: 'POST',
//             sendToken: true,
//         });

//         dispatch(actions.deleteFavouritesSuccessAction('Seçtiğiniz favori ürün başarıyla silindi!'));
//     } catch (error) {
//         dispatch(actions.deleteFavouritesFailAction(error));
//         console.log(error);
//     }
// };

// export const deleteFavouriteMeal = (appcontactId) => (dispatch, state, service) => {
//     dispatch(actions.deleteFavouritesStartAction(appcontactId));
//     service({
//         path: `box/asdsada/${appcontactId}/`,
//         method: 'DELETE',
//         sendToken: true,
//     })
//         .then((response) => {
//             dispatch(actions.deleteFavouritesSuccessAction('Seçtiğiniz favori ürün başarıyla silindi!'));
//             dispatch(fetchFavouriteMeals());
//         })
//         .catch((error) => {
//             dispatch(actions.deleteFavouritesFailAction(error));
//         });
// };

export const fetchFavouriteMeals = () => (dispatch, state, service) => {
    dispatch(actions.getFavouritesStartAction(dispatch, state, service));
    service({
        path: 'box/favorite-meal/',
        method: 'GET',
        sendToken: true,
    })
        .then((appcontactsList) => {
            dispatch(actions.getFavouritesSuccessAction(appcontactsList));
        })
        .catch((error) => {
            dispatch(actions.getFavouritesFailAction(error));
        });
};
