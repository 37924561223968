import { message } from 'antd';
import * as actionTypes from './actionTypes';

// GET STORES
export const getStoresStartAction = () => ({
    type: actionTypes.GET_STORES,
});

export const getStoresSuccessAction = (data) => ({
    type: actionTypes.GET_STORES_SUCCESS,
    payload: data,
});

export const getStoresFailAction = (error) => ({
    type: actionTypes.GET_STORES_FAIL,
    payload: error,
});

// GET STORE
export const getStoreStartAction = () => ({
    type: actionTypes.GET_STORE,
});

export const getStoreSuccessAction = (data) => ({
    type: actionTypes.GET_STORE_SUCCESS,
    payload: data,
});

export const getStoreFailAction = (error) => ({
    type: actionTypes.GET_STORE_FAIL,
    payload: error,
});

// SEARCH STORE
export const searchStoreStartAction = () => ({
    type: actionTypes.SEARCH_STORE,
});

export const searchStoreSuccessAction = (data) => ({
    type: actionTypes.SEARCH_STORE_SUCCESS,
    payload: data,
});

export const searchStoreFailAction = (error) => ({
    type: actionTypes.SEARCH_STORE_FAIL,
    payload: error,
});

// GET STORE FAV
export const getStoreFavStartAction = () => ({
    type: actionTypes.GET_STORE_FAV,
});

export const getStoreFavSuccessAction = (data) => ({
    type: actionTypes.GET_STORE_FAV_SUCCESS,
    payload: data,
});

export const getStoreFavFailAction = (error) => ({
    type: actionTypes.GET_STORE_FAV_FAIL,
    payload: error,
});

// GET STORE BOXES
export const getStoreBoxesStartAction = () => ({
    type: actionTypes.GET_STORE_BOXES,
});

export const getStoreBoxesSuccessAction = (data) => ({
    type: actionTypes.GET_STORE_BOXES_SUCCESS,
    payload: data,
});

export const getStoreBoxesFailAction = (error) => ({
    type: actionTypes.GET_STORE_BOXES_FAIL,
    payload: error,
});

// PUT STORE FAV
export const putStoreFavStartAction = () => ({
    type: actionTypes.PUT_STORE_FAV,
});

export const putStoreFavSuccessAction = (data) => {
    message.success('Mağaza favorilere eklendi');
    return {
        type: actionTypes.PUT_STORE_FAV_SUCCESS,
        payload: data,
    };
};

export const putStoreFavFailAction = (error) => {
    message.error('Eylem başarısız');
    return {
        type: actionTypes.PUT_STORE_FAV_FAIL,
        payload: error,
    };
};

// DELETE STORE FAV
export const deleteStoreFavStartAction = () => ({
    type: actionTypes.DELETE_STORE_FAV,
});

export const deleteStoreFavSuccessAction = (data) => {
    message.success('Mağaza favorilerden kaldırıldı');
    return {
        type: actionTypes.DELETE_STORE_FAV_SUCCESS,
        payload: data,
    };
};

export const deleteStoreFavFailAction = (error) => {
    message.error('Eylem başarısız');
    return {
        type: actionTypes.DELETE_STORE_FAV_FAIL,
        payload: error,
    };
};

// GET STORE TIME
export const getStoreTimeStartAction = () => ({
    type: actionTypes.GET_STORE_TIME,
});

export const getStoreTimeSuccessAction = (data) => ({
    type: actionTypes.GET_STORE_TIME_SUCCESS,
    payload: data,
});

export const getStoreTimeFailAction = (error) => ({
    type: actionTypes.GET_STORE_TIME_FAIL,
    payload: error,
});

// PATCH STORE TIME
export const patchStoreTimeStartAction = () => ({
    type: actionTypes.PATCH_STORE_TIME,
});

export const patchStoreTimeSuccessAction = (data) => ({
    type: actionTypes.PATCH_STORE_TIME_SUCCESS,
    payload: data,
});

export const patchStoreTimeFailAction = (error) => ({
    type: actionTypes.PATCH_STORE_TIME_FAIL,
    payload: error,
});

// POST STORE REVIEW

export const postStoreReviewAction = () => ({
    type: actionTypes.POST_STORE_REVIEW,
});

export const postStoreReviewSuccessAction = (data) => ({
    type: actionTypes.POST_STORE_REVIEW_SUCCESS,
    payload: data,
});

export const postStoreReviewFailAction = (error) => ({
    type: actionTypes.POST_STORE_REVIEW_FAIL,
    payload: error,
});
