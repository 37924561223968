import styled from 'styled-components';
import { Row } from 'antd';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

export const CurrentDataStyles = styled(Row)`
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};

    background-color: #f6f9fc;
`;

export const CurrentDataTitle = styled.div`
    margin-top: 128px;
`;

export const CurrentDataWrapper = styled(Row)`
    margin-bottom: 100px;
    justify-content: space-between;
    align-items: center;
    margin-top: ${VERTICAL_PADDING};
`;
