import styled from 'styled-components';
import { Row, Col, Input as input } from 'antd';
import { between } from 'polished';
import { Text as text } from '../../../../../components/Typography/styles';
import button from '../../../../../components/Button/index';
import card from '../../../../../components/Card/index';

export default styled(card)`
    width: 100%;

    .ant-card-head-title {
        text-align: center;
    }
    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 39px;
        padding-right: 41px;
    }
    margin-bottom: 30px;
`;

export const CardTitle = styled(text)`
    font-size: ${between('14px', '22px')};
`;

export const Text = styled(text)``;

export const Content = styled(Row)`
    display: flex;
    margin-bottom: 32px;
`;

export const Item = styled(Col)`
    display: flex;
    flex-direction: column;
`;

export const Input = styled(input)`
    width: 100%;
    border-radius: 5px;
    height: 48px;
    border: ${({ theme }) => ` 2px solid ${theme.borderColor}`};
    margin-top: 55px;
`;

export const SaveButton = styled(button)`
    height: 48px !important;
    width: 100%;
    margin-top: 32px;
`;
