import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Table = styled.table`
    border: 2px solid #fcfcfc;
    border-collapse: collapse;
`;

export const Td = styled.td`
    border: 2px solid #fcfcfc;
    border-collapse: collapse;
`;

export const CustomRow = styled(Row)`
    width: 100%;
    margin-top: 15px;

    &.card-row {
        align-items: flex-start;
        border: 2px solid #f2f2fb;
        border-radius: 4px;
        flex-direction: column;
        margin-top: 10px;
        padding: 30px 20px;

        @media (min-width: 1180px) {
            align-items: center;
            flex-direction: row;
        }
    }

    &.name-row {
        margin-top: 2px;
    }

    &.time-row {
        background-color: #f5f9fc;
        border-color: #f2f2fb;
        border-radius: 4px;
        margin-top: 0;
        padding: 10px;
    }
`;

export const CustomCol = styled(Col)`
    &.delivery-icon {
        width: 100%;
    }

    &.left-col {
        @media (max-width: 1180px) {
            max-width: 100%;
            width: 100%;
        }
    }

    &.right-col {
        @media (max-width: 1180px) {
            margin-top: 1rem;
            max-width: 100%;
            width: 100%;
        }
    }

    &.chevron-col {
        align-items: center;
        display: flex;
        justify-content: center;
    }
`;
