/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Space } from 'antd';
import { CustomRow, CustomRadio, CustomButton } from './styles';
import { ReactComponent as Dustbin } from '../../../../../../Assets/Shared/CartPage/dustbin.svg';
import { getCardList, deleteCard } from '../../../../../../services/Redux/Shared/payment/api';
import { Text } from '../../../../../components/Typography/styles';
import NewCardModal from './NewCardModal';

const PaymentPane = () => {
    const dispatch = useDispatch();
    const [isCardModal, setIsCardModal] = useState(false);
    const [cardListToRender, setCardListToRender] = useState([]);
    const [radioValue, setRadioValue] = useState(null);

    useEffect(() => {
        dispatch(getCardList());
    }, []);

    const { cardList } = useSelector((state) => state.userPaymentStore);

    useEffect(() => {
        if (cardList) {
            setCardListToRender(cardList.cardDetails || []);

            if (cardList.cardDetails !== undefined && cardList.cardDetails.length > 0) {
                const filtered = cardList.cardDetails[0]?.cardToken;

                setRadioValue(filtered);
            }
        }
    }, [cardList]);

    const removeCard = async (cardToken) => {
        if (cardToken !== null && cardToken !== undefined) {
            await dispatch(deleteCard(cardToken));
            await dispatch(getCardList());
        }
    };

    const onRadioChange = (value) => {
        setRadioValue(value);
    };

    const handleModal = () => {
        setIsCardModal(false);
    };

    return (
        <>
            <CustomRow className="main-row">
                <CustomRow className="heading-row">
                    <Text weight="semi-bold" size="16px">
                        Kayıtlı Kartlarım
                    </Text>
                </CustomRow>
                <CustomRow className="address-info">
                    <Col span={16}>
                        {cardListToRender.length > 0 ? (
                            <CustomRadio.Group
                                style={{ color: 'green' }}
                                size="large"
                                value={radioValue}
                                onChange={(e) => onRadioChange(e.target.value)}>
                                <Space direction="vertical">
                                    {cardListToRender.map((item, index) => (
                                        <CustomRadio key={index} value={item.cardToken} className="radio-wrapper">
                                            <CustomRow className="radio-row">
                                                <Col span={22} style={{ display: 'inline-block' }}>
                                                    <CustomRow>
                                                        <Text weight="light" color="#707070">
                                                            {item.cardAlias} - {item.cardBankName}
                                                        </Text>
                                                    </CustomRow>

                                                    <CustomRow>
                                                        <Text size="14px">
                                                            {item.binNumber} {'********'} {item.lastFourDigits}
                                                        </Text>
                                                    </CustomRow>
                                                    <CustomRow className="item-row">
                                                        <Text color="#707070" weight="light" size="11px">
                                                            {item.cardAssociation}
                                                        </Text>
                                                    </CustomRow>
                                                </Col>
                                                <Col style={{ display: 'inline-block' }}>
                                                    <Button
                                                        style={{ border: 0 }}
                                                        icon={<Dustbin />}
                                                        type="default"
                                                        onClick={() => removeCard(item.cardToken)}
                                                    />
                                                </Col>
                                            </CustomRow>
                                        </CustomRadio>
                                    ))}
                                </Space>
                            </CustomRadio.Group>
                        ) : (
                            <CustomRow>
                                <Text>Kayıtlı kartınız bulunmamaktadır</Text>
                            </CustomRow>
                        )}
                    </Col>
                    <Col>
                        <CustomRow className="yeni-row">
                            <CustomButton type="secondary" onClick={() => setIsCardModal(true)}>
                                Yeni Kart Ekle
                            </CustomButton>
                        </CustomRow>
                    </Col>
                </CustomRow>
                <NewCardModal onCancel={handleModal} visible={isCardModal} />
            </CustomRow>
        </>
    );
};

export default PaymentPane;
