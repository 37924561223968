import styled from 'styled-components';
import { Col, Row } from 'antd';
import Card from '../../../../components/Card';

export const OrderStatusCardStyles = styled(Card)`
    margin-top: 25px !important;
    .ant-card-body {
        padding: 0px;
    }
`;

export const Content = styled(Col)`
    padding-bottom: 42px;
    padding-left: 24px;
    padding-right: 24px;
`;

export const SaleHours = styled(Row)`
    min-height: 150px;
    padding: 20px;
    border-bottom: 2px solid ${({ theme }) => theme.borderColor};
`;

export const DeliveryHours = styled(Row)`
    min-height: 150px;
`;

export const TimeLabel = styled.div`
    padding: 12px 16px;
    border: 2px solid ${({ theme }) => theme.borderColor};
`;

export const Container = styled(Row)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
`;

export const Description = styled(Row)`
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    margin-top: 24px;
`;
