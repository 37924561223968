import React from 'react';
import { Row } from 'antd';
import { KvkkPolicyPageStyles } from './styles';
import KvkkPolicySection from './Kvkk';

const KvkkPolicy = () => (
    <KvkkPolicyPageStyles>
        <Row>
            <KvkkPolicySection />
        </Row>
    </KvkkPolicyPageStyles>
);

export default KvkkPolicy;
