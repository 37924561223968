import * as actions from './actions';

export const fetchStores = () => (dispatch, state, service) => {
    dispatch(actions.getStoresStartAction(dispatch, state, service));
    service({
        path: 'store/',
        method: 'GET',
    })
        .then((data) => {
            dispatch(actions.getStoresSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getStoresFailAction(error));
        });
};

export const fetchStore = (id) => (dispatch, state, service) => {
    dispatch(actions.getStoreStartAction(dispatch, state, service));
    service({
        path: `store/${id}/`,
        method: 'GET',
    })
        .then((data) => {
            dispatch(actions.getStoreSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getStoreFailAction(error));
        });
};

export const searchStore = (query) => (dispatch, state, service) => {
    dispatch(actions.searchStoreStartAction(dispatch, state, service));

    const token = window.localStorage.getItem('token');

    service({
        path: `store/searchstore/${query}`,
        method: 'GET',
        sendToken: token,
    })
        .then((data) => {
            dispatch(actions.searchStoreSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.searchStoreFailAction(error));
        });
};

export const fetchStoreBoxes = (id) => (dispatch, state, service) => {
    dispatch(actions.getStoreBoxesStartAction(dispatch, state, service));
    service({
        path: `store/${id}/store_boxes/`,
        method: 'GET',
    })
        .then((data) => {
            dispatch(actions.getStoreBoxesSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getStoreBoxesFailAction(error));
        });
};

export const fetchStoreFav = () => (dispatch, state, service) => {
    dispatch(actions.getStoreFavStartAction(dispatch, state, service));
    service({
        path: `store/favourite_store/`,
        method: 'GET',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getStoreFavSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getStoreFavFailAction(error));
        });
};

export const putStoreFavorite = (id) => (dispatch, state, service) => {
    dispatch(actions.putStoreFavStartAction(dispatch, state, service));
    service({
        path: `store/favourite_store/${id}/`,
        method: 'PUT',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.putStoreFavSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.putStoreFavFailAction(error));
        });
};

export const deleteStoreFavorite = (id) => (dispatch, state, service) => {
    dispatch(actions.deleteStoreFavStartAction(dispatch, state, service));
    service({
        path: `store/favourite_store/${id}/`,
        method: 'DELETE',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.deleteStoreFavSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.deleteStoreFavFailAction(error));
        });
};

export const fetchStoreTime = (info) => (dispatch, state, service) => {
    dispatch(actions.getStoreTimeStartAction(dispatch, state, service));
    service({
        path: `store/courier_time_interval/store_today_couriers/`,
        method: 'POST',
        sendToken: true,
        body: info,
    })
        .then((data) => {
            dispatch(actions.getStoreTimeSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getStoreTimeFailAction(error));
        });
};

export const patchStoreTime = (info, id) => (dispatch, state, service) => {
    dispatch(actions.patchStoreTimeStartAction(dispatch, state, service));
    service({
        path: `store/courier_time_interval/${id}/`,
        method: 'PATCH',
        sendToken: true,
        body: info,
    })
        .then((data) => {
            dispatch(actions.patchStoreTimeSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.patchStoreTimeFailAction(error));
        });
};

export const postStoreReview = (reviewData) => (dispatch, state, service) => {
    dispatch(actions.postStoreReviewAction(dispatch, state, service));
    service({
        path: 'store/review/',
        method: 'POST',
        sendToken: true,
        body: reviewData,
    })
        .then((data) => {
            dispatch(actions.postStoreReviewSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.postStoreReviewFailAction(error));
        });
};
