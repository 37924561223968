/* eslint-disable prefer-template */
/* eslint-disable camelcase */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { CustomRow, CustomCol, CustomDivider, Rating, CustomButton, HiddenRow, InnerRow, CustomForm } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import { ReactComponent as StarIcon } from '../../../../../../../Assets/Shared/FindRestaurant/star.svg';
import { ReactComponent as EmptyStar } from '../../../../../../../Assets/Shared/Profile/empty-star.svg';
import OrderDetails from './OrderDetails';
import { formatDate, isHoursPassed } from '../../../../../../../helpers';
import { postStoreReview } from '../../../../../../../services/Redux/Shared/store/api';

const defaultRating = {
    meal_point: null,
    service_point: null,
    quality_point: null,
};

const STATUSES = {
    CREATE_ORDER_FOR_BASKET: '1',
    WAITING_FOR_USER_APPROVAL: '2',
    APPROVED_BY_CUSTOMER: '3',
    CANCELLED_BY_STORE: '4',
    CANCELLED_BY_USER: '5',
    DELIVERED: '6',
    OTHER_STATUS: '7',
    MODIFIED_BY_STORE: '8',
    PURCHASED_BY_USER: '9',
    CANCELLED_BY_DONGU: '0',
};

const OrderCard = (props) => {
    const [rating, setRating] = useState(defaultRating);
    const [isRatingPosted, setIsRatingPosted] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const dispatch = useDispatch();
    const { orderDetails } = props;
    const { cost, ref_code, buying_time, address, id: orderId, user, is_voted, review, status } = orderDetails;
    const { name = '', address: store_address = '', avg_review = '', photo, id: storeId } = orderDetails?.store || {};
    const { meal_point, service_point, quality_point } = review[0] || {};
    const {
        CANCELLED_BY_DONGU,
        CANCELLED_BY_STORE,
        CANCELLED_BY_USER,
        CREATE_ORDER_FOR_BASKET,
        WAITING_FOR_USER_APPROVAL,
        APPROVED_BY_CUSTOMER,
        PURCHASED_BY_USER,
    } = STATUSES;
    const CANCELLATION_STATUSES = [CANCELLED_BY_DONGU, CANCELLED_BY_STORE, CANCELLED_BY_USER];
    const ACTIVE_STATUSES = [CREATE_ORDER_FOR_BASKET, WAITING_FOR_USER_APPROVAL, APPROVED_BY_CUSTOMER, PURCHASED_BY_USER];

    const handleShowDetailToggleClick = () => {
        setShowDetail(!showDetail);
    };

    const handleRatingChange = (value, service) => {
        setRating((prev) => ({ ...prev, [service]: value }));
    };

    const buyingTimeFormatted = formatDate(buying_time);
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (Object.values(rating).every(Boolean)) {
            const reviewData = {
                ...rating,
                order: orderId,
                user: user.id,
                store: storeId,
            };
            dispatch(postStoreReview(reviewData));
            setIsRatingPosted(true);
        }
    };

    const getInfoRow = () => {
        if (is_voted || (!isHoursPassed(72, new Date(buying_time)) && status === STATUSES.DELIVERED)) {
            return (
                <>
                    <CustomDivider />
                    <CustomForm onSubmit={handleFormSubmit}>
                        <CustomRow className="top-row">
                            <InnerRow align="middle" wrap={false} gutter={[0, 10]}>
                                <CustomCol span={3}>
                                    <Text size="14px" weight="semi-bold" style={{ whiteSpace: 'nowrap' }}>
                                        Puanla:
                                    </Text>
                                </CustomCol>
                                <CustomCol>
                                    <Text size="14px">Servis:&nbsp;&nbsp;</Text>
                                </CustomCol>
                                <CustomCol span={4} className="star-col">
                                    <Rating
                                        style={{ color: '#FFBC41', padding: 0 }}
                                        character={<EmptyStar width={20} height={20} />}
                                        onChange={(value) => handleRatingChange(value, 'service_point')}
                                        value={rating.service_point || service_point}
                                        disabled={is_voted}
                                    />
                                </CustomCol>
                                <CustomCol>
                                    <Text size="14px">Kalite:&nbsp;&nbsp;</Text>
                                </CustomCol>
                                <CustomCol span={4} className="star-col">
                                    <Rating
                                        style={{ color: '#FFBC41', padding: 0 }}
                                        character={<EmptyStar width={20} height={20} />}
                                        onChange={(value) => handleRatingChange(value, 'quality_point')}
                                        value={rating.quality_point || quality_point}
                                        disabled={is_voted}
                                    />
                                </CustomCol>
                                <CustomCol>
                                    <Text size="14px">Lezzet:&nbsp;&nbsp;</Text>
                                </CustomCol>
                                <CustomCol span={4} className="star-col">
                                    <Rating
                                        style={{ color: '#FFBC41', padding: 0 }}
                                        character={<EmptyStar width={20} height={20} />}
                                        onChange={(value) => handleRatingChange(value, 'meal_point')}
                                        value={rating.meal_point || meal_point}
                                        disabled={is_voted}
                                    />
                                </CustomCol>
                                <CustomRow span={6} className="share-row">
                                    {is_voted || isRatingPosted ? (
                                        <Text color="#F38262">Teşekkürler</Text>
                                    ) : (
                                        <CustomButton htmlType="submit" type="secondary">
                                            <Text weight="semi-bold" color="white">
                                                Paylaş
                                            </Text>
                                        </CustomButton>
                                    )}
                                </CustomRow>
                            </InnerRow>
                        </CustomRow>
                    </CustomForm>
                </>
            );
        }
        if (CANCELLATION_STATUSES.includes(status)) {
            return (
                <>
                    <CustomDivider />
                    <CustomRow className="top-row">
                        <InnerRow align="middle" wrap={false} gutter={[0, 10]}>
                            <Text weight="semi-bold" color="#F38262">
                                Tanımlanmış paketin iptal edildi
                            </Text>
                        </InnerRow>
                    </CustomRow>
                </>
            );
        }
    };

    return (
        <CustomRow className="card-row">
            <CustomRow align="middle" className="top-row" style={ACTIVE_STATUSES.includes(status) && { backgroundColor: 'rgba(143,216,159,0.2)' }}>
                <Col span={8}>
                    <CustomRow className="restaurant-row">
                        <CustomCol span={7} className="img-col">
                            <img src={photo} alt={name} width="75" height="45" />
                        </CustomCol>
                        <Col span={12}>
                            <CustomRow align="bottom">
                                <Col style={{ paddingTop: 4 }}>
                                    <Text weight="semi-bold">{name}</Text>
                                </Col>
                            </CustomRow>
                            <CustomRow align="middle" style={{ paddingBottom: 3 }} wrap={false}>
                                <Col>
                                    <StarIcon width="16" height="16px" />
                                </Col>
                                {review.length >= 5 ? (
                                    <Text weight="light" size="14px" style={{ whiteSpace: 'nowrap' }}>
                                        &nbsp; {Number(avg_review).toFixed(2)} &nbsp;&nbsp;&nbsp;&nbsp;{store_address}
                                    </Text>
                                ) : (
                                    <Text weight="light" size="14px" style={{ whiteSpace: 'nowrap' }}>
                                        &nbsp; 5 &nbsp;&nbsp;&nbsp;&nbsp;{store_address.split(' ')[0]}
                                    </Text>
                                )}
                            </CustomRow>
                        </Col>
                    </CustomRow>
                </Col>
                <CustomCol span={6} className="detail-col">
                    <CustomRow>
                        <Text weight="semi-bold" size="14px">
                            {ref_code}
                        </Text>
                    </CustomRow>
                    <CustomRow className="padding-top">
                        <Text
                            weight="light"
                            size="14px"
                            color="red"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={handleShowDetailToggleClick}>
                            Sipariş Detayı
                        </Text>
                    </CustomRow>
                </CustomCol>
                <CustomCol span={6} className="time-col">
                    <CustomRow>
                        <Text weight="light" size="14px">
                            {address?.name}
                        </Text>
                    </CustomRow>
                    <CustomRow className="padding-top">
                        <Text weight="light" size="14px">
                            {`${buyingTimeFormatted.date} ${buyingTimeFormatted.time}`}
                        </Text>
                    </CustomRow>
                </CustomCol>
                <CustomCol span={4} className="total-col">
                    <Row justify="end">
                        <CustomRow className="price-row" align="middle" justify="center">
                            <Text color="green" weight="bold">
                                {cost}₺
                            </Text>
                        </CustomRow>
                    </Row>
                </CustomCol>
            </CustomRow>
            {getInfoRow()}
            <HiddenRow className="hidden-row" active={showDetail}>
                <CustomDivider />
                <OrderDetails
                    orderDetails={orderDetails}
                    buyingTimeFormatted={buyingTimeFormatted}
                    handleShowDetailToggleClick={handleShowDetailToggleClick}
                    STATUSES={STATUSES}
                />
            </HiddenRow>
        </CustomRow>
    );
};

export default OrderCard;
