import styled from 'styled-components';
import { Modal, Row } from 'antd';

export const CustomModal = styled(Modal)`
    .ant-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-bottom: 3px solid ${({ theme }) => theme.borderColor};
    }

    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-footer {
        height: 30px;
        .ant-btn {
            display: none;
        }
        background: ${({ theme }) => theme.borderColor};
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .ant-modal-body {
        padding: 20px 30px !important;
        padding-bottom: 0px !important;
        overflow-y: scroll;
        height: 650px !important;
        min-width: 370px !important;
    }
    .icon-size {
        font-size: 28px;
        margin-right: 15px;
    }

    .padding-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;

export const MenuWrapper = styled(Row)``;

export const Footer = styled(Row)``;

export const ImageContainer = styled.div`
    width: 334px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .img-covered {
        height: 60px;
        width: 107px;
    }
`;

export const MapContainer = styled(Row)`
    .img-map {
        height: 100%;
        width: 550px;
    }
`;
