import React from 'react';
import { HeaderStyles, ImageContainer, ProfileContainer } from './styles';
import logo from '../../../../../../Assets/Dashboard/dongu-logo.png';
import Profile from './Profile';

const Header = () => (
    <HeaderStyles align="middle" justify="space-between">
        <ImageContainer>
            <img className="img-covered" src={logo} alt="logo" />
        </ImageContainer>
        <ProfileContainer>
            <Profile />
        </ProfileContainer>
    </HeaderStyles>
);
export default Header;
