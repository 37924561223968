import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { between } from 'polished';
import { Row } from 'antd';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import { BannerStyles, TitleWrapper, MottoWrapper, DownloadAppButton, FindRestorantButton, ButtonWrapper } from './styles';
import { Text } from '../../../../components/Typography/styles';
import banner from '../../../../../Assets/Shared/Layout/banner.svg';
import top from '../../../../../Assets/Shared/Landing/mobil_head.svg';
import bottom from '../../../../../Assets/Shared/Landing/mobil_foot.svg';

const BannerSection = () => {
    const history = useHistory();
    const theme = useTheme();
    const data = {
        title: ' En lezzetli yemekleri yiyerek dünyayı koruyabileceğini biliyor musun?',
        description: 'Çok yakında, Döngü’ye özel fiyatlarla burada olacağız ',
    };
    const { xs, sm } = useMediaQuery();

    return (
        <BannerStyles span={24} backgroundImage={sm ? null : banner}>
            <Row>
                {sm ? <img src={top} alt="" width="100%" style={{ marginTop: xs ? '18%' : '5%' }} /> : null}
                <MottoWrapper>
                    <TitleWrapper width={sm ? '100%' : '60%'}>
                        <Text style={{ textAlign: sm ? 'center' : 'left' }} disableSelect size="bigTitle" weight="bold" color="red">
                            {data.title}
                        </Text>
                    </TitleWrapper>
                    <div
                        style={{
                            width: sm ? '100%' : '50%',
                            marginTop: between('22px', '60px', '320px', '1920px'),
                            paddingLeft: xs ? '30px' : '0px',
                            paddingRight: xs ? '30px' : '0px',
                            justifyContent: xs ? 'center' : 'left',
                            flexDirection: 'column',
                            display: 'flex',
                        }}>
                        <Text
                            style={{
                                textAlign: sm ? 'center' : 'left',
                                paddingHorizontal: sm ? '30px' : '0px',
                            }}
                            disableSelect
                            size="description"
                            weight="bold"
                            color="green">
                            {data.description}
                        </Text>
                    </div>
                    <ButtonWrapper>
                        <FindRestorantButton
                            onClick={() => history.push('/restoran-bul')}
                            width={sm ? '100%' : '212px'}
                            type="tertiaryReverse"
                            size="regular"
                            borderColor={theme.primaryColor}
                            text="asd">
                            <Text weight="semi-bold" color={theme.primaryColor}>
                                Restoran Bul
                            </Text>
                        </FindRestorantButton>
                        <DownloadAppButton width={sm ? '100%' : '212px'} type="secondary">
                            <Text size="regular" color="white" weight="semi-bold">
                                Uygulamayı İndir
                            </Text>
                        </DownloadAppButton>
                    </ButtonWrapper>
                </MottoWrapper>
                {sm ? <img src={bottom} alt="" width="100%" style={{ backgroundColor: '#F5F9FC' }} /> : null}
            </Row>
        </BannerStyles>
    );
};

export default BannerSection;
