import styled from 'styled-components';
import { Row, Col } from 'antd';

export const Table = styled.table`
    border: 2px solid #fcfcfc;
    border-collapse: collapse;
`;

export const Td = styled.td`
    border: 2px solid #fcfcfc;
    border-collapse: collapse;
`;

export const CustomRow = styled(Row)`
    width: 100%;

    &.card-row {
        border: 2px solid #f2f2fb;
        padding: 20px 15px;
        margin-top: 5px;
    }

    &.name-row {
        margin-top: 2px;
    }

    &.time-row {
        background-color: #f5f9fc;
        border-color: #f2f2fb;
        margin-top: 0;
        padding: 10px;
        border-radius: 5px;

        @media (max-width: 1024px) {
            justify-content: center;
            padding: 5px 10px;
        }
    }
`;

export const CustomCol = styled(Col)`
    border: 2px solid #f2f2fb;
    border-radius: 4px;

    &.delivery-icon {
        width: 100%;
    }
`;
