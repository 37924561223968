import styled from 'styled-components';
import { Row, Col } from 'antd';

export default styled.div`
    background-color: #f69f8e;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-size: cover;
`;

export const Content = styled(Row)`
    width: 522px;
`;

export const Item = styled(Col)``;
