import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
import { CustomModal } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import MenuPreviewCard from '../../../../../Dashboard/MenuSettingsPage/MenuPreviewCard';
import { fetchBoxCategories } from '../../../../../../../services/Redux/Dashboard/boxes/api';

const Title = ({ text }) => (
    <Row align="middle" justify="center">
        <Text size="title" weight="light">
            {text}
        </Text>
    </Row>
);

const MenuModal = (props) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchBoxCategories(true));
    }, [dispatch]);

    const storeData = selector((state) => state.storeStore.storeData);
    let categories = selector((state) => state.boxStore.categoryList);

    if (categories) {
        categories = categories.filter((item) => item.store === storeData.name);
    }
    return (
        <>
            <CustomModal title={<Title text="Restoran Menüsü" />} {...props}>
                <MenuPreviewCard mealCategories={categories} />
            </CustomModal>
        </>
    );
};

export default MenuModal;
