import React from 'react';
import { CurrentDataCompWrapper, CurrentDataCircle, CurrentDataCompTitle, CurrentDataCompText, CurrentDataCompContent } from './styles';
import { Paragraph, Text } from '../../../../../components/Typography/styles';

const CurrentDataComponent = ({ titleNumber, titleText, contentText, titleSpan }) => (
    <CurrentDataCompWrapper xl={6} lg={12} md={12}>
        <CurrentDataCircle>
            <CurrentDataCompTitle>
                <Text style={{ textAlign: 'center' }} weight="bold" color="#000" size="70px">
                    {titleNumber}
                    {titleSpan ? (
                        <>
                            <Text color="#000" size="70px">
                                ’
                            </Text>
                            <Text style={{ textAlign: 'center' }} color="#000" size="30px">
                                {titleSpan}
                            </Text>
                        </>
                    ) : null}
                </Text>
            </CurrentDataCompTitle>
            <CurrentDataCompText>
                <Text style={{ display: 'flex', textAlign: 'center' }} color="#000" size="mediumTitle">
                    {titleText}
                </Text>
            </CurrentDataCompText>
        </CurrentDataCircle>
        <CurrentDataCompContent>
            <Paragraph color="#000" weight="medium" size="description">
                {contentText}
            </Paragraph>
        </CurrentDataCompContent>
    </CurrentDataCompWrapper>
);

export default CurrentDataComponent;
