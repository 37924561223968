import styled from 'styled-components';
import { Row } from 'antd';

export const IconWrapper = styled(Row)`
    margin: 1rem auto 2rem;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        align-items: center;
        justify-content: center;
    }

    & > .ant-col > .ant-row {
        @media only screen and (max-width: 1024px) {
            max-height: 70px;
            max-width: 70px;
        }
    }
`;
