import { Col } from 'antd';
import React from 'react';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery.js';
import CategoryIcon from '../../FindRestaurant/CategorySection/CategoryIcon.jsx';
import { StyledSlider } from '../../FindRestaurant/CategorySection/styles.js';
import { IconWrapper } from './styles';

const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 4,
    arrows: false,
};

const Categories = ({ categories }) => {
    const { xs } = useResponsive();

    return (
        <IconWrapper>
            {xs ? (
                <Col>
                    <StyledSlider {...settings}>
                        {categories?.map(({ name }) => (
                            <CategoryIcon key={name.id} icon={name.photo} text={name.name} color={name.color} />
                        ))}
                    </StyledSlider>
                </Col>
            ) : (
                categories?.map(({ name }) => <CategoryIcon key={name.id} icon={name.photo} text={name.name} color={name.color} />)
            )}
        </IconWrapper>
    );
};

export default Categories;
