import styled from 'styled-components';
import { Col, Row } from 'antd';

export const MinPacketPriceStyles = styled(Row)``;
export const Wrapper = styled(Col)`
    padding-top: 5px;
    padding-bottom: 5px;
`;
export const Header = styled(Col)`
    padding-bottom: 10px;
`;
export const Content = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const Label = styled.div`
    padding: 12px 16px;
    min-width: 80px;
    background: ${({ theme }) => theme.labelBackground};
`;
