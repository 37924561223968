import styled from 'styled-components';
import { between } from 'polished';
import { Text as text } from '../../../../../components/Typography/styles';

export default styled.div`
    display: flex;
    flex-direction: column;
    height: 102px;
    border: ${({ theme, selected }) => (selected ? `2px solid ${theme.secondaryColor}` : ` 1px solid ${theme.borderColor}`)};
    text-align: start;

    background: ${({ theme }) => theme.lightColor};

    &:hover {
        border: ${({ theme }) => `2px solid ${theme.secondaryColor}`};
    }
`;

export const Logo = styled.img`
    display: ${({ specialday }) => (specialday ? 'block' : 'none')};
    width: 19px;
    height: 19px;
    position: absolute;
    left: 38px;
    bottom: 80px;
`;

export const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin-top: 9px;
    margin-left: 7px;
    border: ${({ theme, specialday }) => (specialday ? `2px solid ${theme.quaternaryColor}` : 'none')};
    margin-bottom: 7px;
    border-radius: 50%;
`;

export const Date = styled(text)`
    font-size: ${between('10px', '16px')} !important;
    color: ${({ theme, samemonth }) => (samemonth ? '#000' : theme.inactiveSecondaryColor)} !important;
`;

export const Orders = styled.div`
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 2px;
    text-align: start;
    background: ${({ theme, sameDay }) => (!sameDay ? theme.inactiveSecondaryColor : theme.secondaryColor)};
    height: 18px;
    width: 90%;
`;

export const TextStyles = styled(text)`
    font-size: ${between('8px', '12px')} !important;
    margin-left: 7px;
`;

export const SpecialDate = styled.div`
    display: ${({ specialday }) => (specialday ? 'flex' : 'none')};
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 3px;
    border-radius: 2px;
    text-align: start;
    background: ${({ theme }) => theme.quaternaryColor};
    height: 18px;
    width: 90%;
`;
