import React from 'react';
import { Row } from 'antd';
import { FoodWastePageStyles } from './styles';
import InfoSection from './InfoSection';
import ExplanationSection from './ExplanationSection';
import RecycleSection from './RecycleSection';
import CurrentDataSection from './CurrentDataSection';
import EnvironmentSection from './EnvironmentSection';

const FoodWaste = () => (
    <FoodWastePageStyles>
        <Row>
            <InfoSection />
        </Row>
        <Row>
            <ExplanationSection />
        </Row>

        <Row>
            <RecycleSection />
        </Row>

        <Row>
            <CurrentDataSection />
        </Row>
        <Row>
            <EnvironmentSection />
        </Row>
    </FoodWastePageStyles>
);

export default FoodWaste;
