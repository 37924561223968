import React from 'react';
import { Row } from 'antd';
import { LandingPageStyles } from './styles';
import HowToUseSection from './HowToUseSection';
import InfoSection from './InfoSection'; /* 
import infoMenuIcon from '../../../../assets/shared/landingPage/infoMenuIcon.svg';
import infoWorldIcon from '../../../../assets/shared/landingPage/infoWorldIcon.svg';
import infoChefsIcon from '../../../../assets/shared/landingPage/infoChefsIcon.svg';
import infoCourrierIcon from '../../../../assets/shared/landingPage/infoCourrierIcon.svg';
import infoMeat from '../../../../assets/shared/landingPage/infoMeat.svg';
import infoSeaFood from '../../../../assets/shared/landingPage/infoSeaFood.svg';
import infoVegan from '../../../../assets/shared/landingPage/infoVegan.svg';
import infoDessert from '../../../../assets/shared/landingPage/infoDessert.svg';
import infoCake from '../../../../assets/shared/landingPage/infoCake.svg';
import infoSpecial from '../../../../assets/shared/landingPage/infoSpecial.svg'; */
import CreateForBusinessSection from './CreateForBusinessSection';
import JoinUsSection from './JoinUsSection';
import QuestionSection from './QuestionSection';

const ForBusinessPage = () => (
    <LandingPageStyles>
        <Row>
            <CreateForBusinessSection />
        </Row>
        <Row>
            <InfoSection />
        </Row>
        <Row>
            <HowToUseSection />
        </Row>
        <Row>
            <JoinUsSection />
        </Row>
        <Row>
            <QuestionSection />
        </Row>
    </LandingPageStyles>
);

export default ForBusinessPage;
